import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/PageHeader';
import { useCurrentUser } from '@/hooks';
import { CalendarActivitiesStaticBlock } from '@/views/CalendarActivities';
import { RecentAccountsStaticBlock } from '@/views/Homepage';

export const Homepage = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const showRecentHistory = currentUser?.permissions?.common?.canReadHistory;

  return (
    <StyledGrid container spacing={{ xs: 2, md: 3 }}>
      <Grid item md={showRecentHistory ? 12 : 8} xs={12}>
        <PageHeader title={t('pages.homepage.dashboard')} />
      </Grid>

      <Grid item md={8} xs={12}>
        <CalendarActivitiesStaticBlock />
      </Grid>

      {showRecentHistory && (
        <Grid item container md={4} xs={12} direction="column" spacing={3}>
          <Grid item>
            <RecentAccountsStaticBlock />
          </Grid>
        </Grid>
      )}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});
