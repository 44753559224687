import { useKeycloak } from '@/auth';

type LoginOptions = {
  idpHint?: 'ysuraHub' | 'local';
};

export type UserInfo = {
  id?: string | undefined;
  name?: string | undefined;
  givenName?: string | undefined;
  familyName?: string | undefined;
  locale?: string | undefined;
  // This attribute does currently not exist in the keycloak token.
  avatarUrl?: string | undefined;
  isYsuraUser: boolean;
};

type UseAuth = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  login: ({ idpHint }?: LoginOptions) => void;
  logout: VoidFunction;
  getToken: () => string | undefined;
  user?: UserInfo | undefined;
};

const YSURA_KEYCLOAK_ROLE = 'ysura';

export const useAuth = (): UseAuth => {
  const { keycloak, initialized } = useKeycloak();

  const getToken = () => {
    if (keycloak) {
      return keycloak.token;
    }
  };

  const login = (loginOptions?: LoginOptions) => {
    if (keycloak) {
      if (loginOptions) {
        const { idpHint } = loginOptions;

        let keycloakLoginOptions;
        if (idpHint) {
          keycloakLoginOptions = {
            idpHint,
          };
        }

        keycloak.login(keycloakLoginOptions);
      } else {
        keycloak.login();
      }
    }
  };

  const logout = () => {
    if (keycloak) {
      keycloak.logout();
    }
  };

  const isYsuraUser =
    !!keycloak?.tokenParsed?.realm_access?.roles.includes(YSURA_KEYCLOAK_ROLE);

  return {
    isAuthenticated: !!keycloak?.authenticated,
    isInitialized: initialized,
    login,
    logout,
    getToken,
    user: {
      id: keycloak?.tokenParsed?.ysura_userId,
      name: keycloak?.tokenParsed?.name,
      givenName: keycloak?.tokenParsed?.given_name,
      familyName: keycloak?.tokenParsed?.family_name,
      locale: keycloak?.tokenParsed?.locale,
      isYsuraUser,
    },
  };
};
