import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconWrapper, OrganizationData, TextHighlighter } from '@ysura/common';
import { useNavigate } from 'react-router-dom';

import { getOrganizationAddress } from '@/services/helper';

type OrganizationSearchResultCardProps = {
  organization: OrganizationData;
  searchQuery: string;
  closeDialog: VoidFunction;
};

export const OrganizationSearchResultCard = ({
  organization,
  searchQuery,
  closeDialog,
}: OrganizationSearchResultCardProps) => {
  const navigate = useNavigate();

  const searchQueryArray = searchQuery.split(' ');

  const organizationName = organization?.name;
  const rootOrganizationName = organization?.rootOrganization?.name;
  const firstOrganizationTypeName =
    organization?.organizationTypes &&
    organization?.organizationTypes?.length > 0
      ? organization?.organizationTypes[0].name
      : undefined;
  const address = organization.addresses
    ? organization.addresses[0]
    : undefined;

  const navigateToOrganizationPage = () => {
    closeDialog();
    const urlToOrganizationPage = organization?.id
      ? `/organization/${organization?.id}`
      : '';
    navigate(urlToOrganizationPage);
  };

  return (
    <Wrapper
      data-testid="organization-search-result-card"
      onClick={navigateToOrganizationPage}
    >
      <Row>
        <StyledIconWrapper size={20}>
          <IconCompany />
        </StyledIconWrapper>
        <Name noWrap variant="subtitle2">
          {organizationName ? (
            <TextHighlighter
              testId="organization-name-text-highlighter"
              searchQueryArray={searchQueryArray}
              text={organizationName}
            />
          ) : (
            'N/A'
          )}
        </Name>
      </Row>

      <RowWrapper>
        {rootOrganizationName && (
          <Row>
            <Empty />
            <Name noWrap variant="caption">
              <TextHighlighter
                testId="root-organization-name-text-highlighter"
                searchQueryArray={searchQueryArray}
                text={rootOrganizationName}
              />
            </Name>
          </Row>
        )}

        {!rootOrganizationName && firstOrganizationTypeName && (
          <Row>
            <Empty />
            <Name noWrap variant="caption">
              <TextHighlighter
                testId="first-organization-type-name-text-highlighter"
                searchQueryArray={searchQueryArray}
                text={firstOrganizationTypeName}
              />
            </Name>
          </Row>
        )}

        {!rootOrganizationName && !firstOrganizationTypeName && (
          <Row>
            <Empty />
          </Row>
        )}

        <Row>
          {address && (
            <>
              <IconWrapper size={20}>
                <IconAddress />
              </IconWrapper>
              <Address key={address.id} noWrap variant="caption">
                {address && (
                  <TextHighlighter
                    testId="organization-address-text-highlighter"
                    searchQueryArray={searchQueryArray}
                    text={getOrganizationAddress(address)}
                  />
                )}
              </Address>
            </>
          )}
        </Row>
      </RowWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),

  padding: theme.spacing(1),
  width: '100%',

  cursor: 'pointer',
}));

const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.25),
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const Address = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const IconCompany = styled(LocalHospitalIcon)(({ theme }) => ({
  svg: {
    fill: theme.palette.common.white,
    width: 16,
    height: 16,
  },
}));

const IconAddress = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  height: 16,
  width: 16,
}));

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.chart.violet[0],
  svg: {
    fill: theme.palette.common.white,
    width: 12,
    height: 12,
  },
}));

const Empty = styled(Box)({
  width: 20,
});
