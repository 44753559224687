import { ActivityTypeData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';

import { BaseFormikProps } from '../types';

interface TouchpointTypeConfirmationDialogProps extends BaseFormikProps {
  isOpen: boolean;
  incomingTouchpointType: ActivityTypeData | undefined;
  closeDialog: VoidFunction;
}

export const TouchpointTypeConfirmationDialog = ({
  isOpen,
  closeDialog,
  setFieldValue,
  incomingTouchpointType,
}: TouchpointTypeConfirmationDialogProps) => {
  const { t } = useTranslation();

  const handleClickContinue = () => {
    setFieldValue?.('touchpointType', incomingTouchpointType);
    closeDialog();
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t('components.common.warning')}
      content={t('components.touchpointManagement.warning.touchpointType')}
      primaryButton={{
        text: t('components.common.continue'),
        onClickHandler: handleClickContinue,
      }}
      secondaryButton={{
        text: t('components.common.cancel'),
        onClickHandler: closeDialog,
      }}
    />
  );
};
