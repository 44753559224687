import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DoubleListItem,
  DoubleListItemSkeleton,
  DoubleListItemType,
  NoItemPlaceholder,
} from '@ysura/common';
import cloneDeep from 'lodash/cloneDeep';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';

type DoubleListDialogProps<T> = {
  isOpen: boolean;
  isLoading?: boolean;
  title: string;
  //The translation must contain {{count}}, check translationKey_one and translationKey_other
  titleForSelectedCount: string;
  titleForAvailableCount: string;
  emptySelectedInfo: string;
  testId?: string;
  handleSave: (items: T[]) => void;
  handleCancel: VoidFunction;
  onSearch?: (query: string, item: T) => boolean | undefined;
  renderSelectedItem: (item: T) => ReactNode;
  preSelectedItems?: DoubleListItemType<T>[];
  initialAvailableItems: DoubleListItemType<T>[];
  renderAvailableItem: (item: T, query?: string) => ReactNode;
};
export const DoubleListDialog = <T,>({
  isOpen,
  isLoading,
  title,
  titleForSelectedCount,
  titleForAvailableCount,
  emptySelectedInfo,
  testId,
  handleSave,
  handleCancel,
  onSearch,
  renderSelectedItem,
  preSelectedItems,
  initialAvailableItems,
  renderAvailableItem,
}: DoubleListDialogProps<T>) => {
  const { t } = useTranslation();

  const [selectedItems, setSelectedItems] = useState<DoubleListItemType<T>[]>(
    preSelectedItems ?? []
  );
  const [availableItems, setAvailableItems] = useState<DoubleListItemType<T>[]>(
    []
  );
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!isLoading) {
      setAvailableItems(() => [...initialAvailableItems]);
    }
  }, [isLoading, initialAvailableItems]);

  const handleClearSearchQuery = () => {
    setQuery(() => '');
    setAvailableItems(() => [...initialAvailableItems]);
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(() => event.target.value);
    if (event.target.value && event.target.value.length > 2) {
      setAvailableItems((previous) => {
        return previous.filter(
          (it) => !!onSearch?.(event.target.value, it.target)
        );
      });
    } else {
      setAvailableItems(() => [...initialAvailableItems]);
    }
  };

  const handleClose = () => {
    const target = selectedItems.map((it) => it.target);
    handleSave(target);
  };

  const onSelectedItemAdd = (item: DoubleListItemType<T>) => {
    const newSelected = cloneDeep(item);
    setSelectedItems((previous) => [...previous, newSelected]);
  };

  const onSelectedItemRemove = (item: DoubleListItemType<T>) => {
    setSelectedItems((previous) => [
      ...previous.filter((it) => it.id !== item.id),
    ]);
  };

  return (
    <ActionDialog
      isOpen={isOpen}
      title={t(title)}
      isLoading={isLoading}
      testId={testId}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      onClickPrimaryButton={handleClose}
      onClickSecondaryButton={handleCancel}
    >
      <ContentWrapper>
        <ColumnWrapper isFullHeight={!availableItems.length} flexNumber={1}>
          <Title variant="subtitle2">
            {`${t(titleForAvailableCount, {
              count: availableItems.length,
            })}`}
          </Title>
          {onSearch && (
            <TextField
              fullWidth
              name="search"
              placeholder={t('pages.interaction.common.search') + '...'}
              size="small"
              value={query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: query && (
                  <InputAdornment position="end">
                    <ClearButton
                      variant="text"
                      onClick={handleClearSearchQuery}
                    >
                      {t('components.common.clear')}
                    </ClearButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeSearchQuery}
            />
          )}
          <ItemList>
            {isLoading ? (
              <>
                <DoubleListItemSkeleton />
                <DoubleListItemSkeleton />
              </>
            ) : (
              availableItems.map((item: DoubleListItemType<T>) => {
                const isSelected = selectedItems.find(
                  (it) => it.id === item.id
                );

                return (
                  <DoubleListItem
                    key={item.id}
                    renderItem={renderAvailableItem(item.target, query)}
                    operation={isSelected ? 'selected' : 'add'}
                    onClick={() => onSelectedItemAdd(item)}
                  />
                );
              })
            )}
            <MobileSpacer />
          </ItemList>
        </ColumnWrapper>

        <ColumnWrapper isFullHeight={!selectedItems.length} flexNumber={2}>
          <Title variant="subtitle2">
            {`${t(titleForSelectedCount, {
              count: selectedItems.length,
            })}`}
          </Title>
          <ItemList>
            {!selectedItems.length && (
              <NoItemPlaceholder>{emptySelectedInfo}</NoItemPlaceholder>
            )}
            {selectedItems.map((item: DoubleListItemType<T>) => {
              return (
                <DoubleListItem
                  key={item.id}
                  renderItem={renderSelectedItem(item.target)}
                  operation={'remove'}
                  onClick={() => onSelectedItemRemove(item)}
                />
              );
            })}
          </ItemList>
        </ColumnWrapper>
      </ContentWrapper>
    </ActionDialog>
  );
};

const ClearButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',

  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: theme.spacing(2),
  },
}));

type WrapperStyleProps = {
  isFullHeight?: boolean;
  flexNumber: number;
};

const ColumnWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullHeight' && prop !== 'flexNumber',
})<WrapperStyleProps>(({ theme, isFullHeight, flexNumber }) => ({
  width: '100%',
  height: isFullHeight ? '100%' : 'auto',

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  // Make sure the shadow is not cut off
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),

  overflowY: 'auto',

  flex: flexNumber,

  [theme.breakpoints.down('md')]: {
    height: 'auto',
    overflowY: 'initial',
  },
}));

const ItemList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MobileSpacer = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    height: theme.spacing(5),
  },
}));
