import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  EmploymentData,
  Loader,
  SampleRequestCollectState,
  SampleRequestHTMLPreviewState,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction, useNotification } from '@/hooks';
import {
  useAddSampleRequestFormMutation,
  useGetDeliveryTimeFrameListQuery,
  useGetSystemConfigurationQuery,
} from '@/services/graphql/hooks';
import {
  parseDeliveryTimeFrameConnection,
  parseSystemConfigurationData,
} from '@/services/graphql/parsers';
import {
  compileSampleRequestFormTemplate,
  useGetSampleRequestTemplate,
} from '@/services/templates';
import {
  buildFilename,
  extractSampleRequestFormItems,
} from '@/views/Interaction/ExpandView/PromotionalMaterial/utils';
import {
  ProMatCollectionCollect,
  ProMatCollectionPreview,
} from '@/views/Interaction/Remote/Room/ProMatCollection';

type ProMatCollectionProps = {
  activity: ActivityData;
};

export const ProMatCollection = ({ activity }: ProMatCollectionProps) => {
  const {
    currentSampleRequest,
    startSampleRequestCollection,
    changeSampleRequestCollection,
    stopSampleRequestCollection,
    onSampleRequestValueChanged,
    onSampleRequestAttendeeSubmitted,
    promotionalMaterialGroups,
    setPromotionalMaterialGroups,
    setSignedSampleRequestForm,
  } = useInteraction();

  const [step, setSteps] = useState<'collect' | 'preview'>(
    currentSampleRequest?.step ?? 'collect'
  );
  const [addressValue, setAddressValue] = useState<string>(() => {
    if (currentSampleRequest?.state?.address) {
      return currentSampleRequest?.state.address;
    }

    const addresses =
      activity?.attendees?.[0]?.person?.employments?.flatMap(
        (employment: EmploymentData) => {
          const organizationAddresses =
            employment?.organization?.addresses ?? [];
          const employmentAddresses = employment?.addresses ?? [];

          return organizationAddresses.concat(employmentAddresses);
        }
      ) ?? [];
    if (addresses.length === 1) {
      return addresses[0].oid ?? '';
    }

    return '';
  });
  const [addressExtension, setAddressExtension] = useState(
    currentSampleRequest?.state?.addressExtension ?? ''
  );
  const [signature, setSignature] = useState<string>(
    currentSampleRequest?.state?.signature ?? ''
  );
  const [deliveryTimeFrame, setDeliveryTimeFrame] = useState<string | null>(
    currentSampleRequest?.state?.deliveryTimeFrame ?? null
  );
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(
    currentSampleRequest?.state?.deliveryDate ?? null
  );
  const { t } = useTranslation();
  const { toast } = useNotification();

  const { isLoading: isTemplateLoading, data: templateData } =
    useGetSampleRequestTemplate();

  const {
    loading: isSystemConfigurationLoading,
    data: systemConfigurationData,
  } = useGetSystemConfigurationQuery();
  const systemConfiguration = systemConfigurationData?.systemConfiguration
    ? parseSystemConfigurationData(systemConfigurationData.systemConfiguration)
    : null;

  const { data: deliveryTimeFrameData, loading: isDeliveryTimeFrameLoading } =
    useGetDeliveryTimeFrameListQuery();
  const deliveryTimeFrames = parseDeliveryTimeFrameConnection(
    deliveryTimeFrameData?.deliveryTimeFrames
  );

  const [addSampleRequestMutation, { loading: isSubmitting }] =
    useAddSampleRequestFormMutation();

  const isDeliveryInformationEnabled =
    promotionalMaterialGroups.flatMap(
      (each) =>
        each.batches?.filter(
          (batch) => batch.virtualStock.requestedQuantity > 0
        )
    ).length > 0 &&
    !!systemConfiguration?.sampleRequestAdditionalDeliveryInformationEnabled;

  const isDeliveryDateEnabled =
    isDeliveryInformationEnabled &&
    !!systemConfiguration?.sampleRequestDeliveryDateEnabled;

  const deliveryDateMinimumDays =
    systemConfiguration?.sampleRequestDeliveryDateMinimumDaysInFuture ?? 0;

  const attendee = activity?.attendees?.[0]?.person;

  useEffect(() => {
    if (
      step == 'collect' &&
      !isSystemConfigurationLoading &&
      !isDeliveryTimeFrameLoading
    ) {
      const state: SampleRequestCollectState = {
        step: 'collect',
        title: `${t('pages.interaction.common.requestForm')}`,
        attendee,
        requestedSamples: promotionalMaterialGroups,
        address: addressValue,
        addressExtension,
        isDeliveryInformationEnabled,
        isDeliveryDateEnabled,
        deliveryTimeFrame,
        deliveryTimeFrames,
        deliveryDate,
        deliveryDateMinimumDays,
        isSampleRequestSigned: !!activity.sampleRequestFormDocument,
        signature,
      };

      const payload: SampleRequestViewerPayload = {
        id: attendee?.id ?? '',
        step: 'collect',
        state: state,
      };
      startSampleRequestCollection?.(payload);
    }
    // Called only once when the component is initialised
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSystemConfigurationLoading, isDeliveryTimeFrameLoading]);

  const handleAttendeeSubmittedSampleRequest = (
    event: SampleRequestViewerPayload
  ) => {
    const formContent =
      event.state.step === 'preview' ? event.state.content : '';
    if (!attendee || !formContent) {
      return;
    }

    const addressValue =
      event.state.step === 'preview' ? event.state.address : '';

    const deliveryTimeFrame =
      event.state.step === 'preview' ? event.state.deliveryTimeFrame : null;

    const deliveryDate =
      event.state.step === 'preview' && event.state.deliveryDate
        ? new Date(event.state.deliveryDate)
        : null;

    addSampleRequestMutation({
      variables: {
        signerId: attendee?.oid ?? '',
        addressId: addressValue,
        activityTypeId: activity?.activityType?.oid ?? '',
        body: formContent,
        filename: buildFilename(attendee),
        items: extractSampleRequestFormItems(promotionalMaterialGroups),
        deliveryTimeFrameId: deliveryTimeFrame,
        deliveryDate: deliveryDate?.toISOString()?.slice(0, 10),
      },
      onCompleted: ({ addSampleRequestForm }) => {
        activity.sampleRequestFormDocument = {
          id: addSampleRequestForm?.document?.id,
        };

        const updatedPromats = promotionalMaterialGroups.map((promat) => {
          if (promat.promotionalMaterial?.signatureRequired) {
            return { ...promat, isSigned: true };
          }

          return { ...promat };
        });

        setPromotionalMaterialGroups?.(updatedPromats);

        const sampleRequestForm = {
          id: addSampleRequestForm?.id,
          oid: addSampleRequestForm?.oid,
          document: {
            id: addSampleRequestForm?.document?.id,
            oid: addSampleRequestForm?.document?.oid,
            name: addSampleRequestForm?.document?.name ?? '',
          },
        };

        setSignedSampleRequestForm?.(sampleRequestForm);
        handleCloseForm();

        toast?.({
          message: t('pages.interaction.toasts.sampleRequestFormSubmitted'),
          type: 'success',
        });
      },
      onError: () => {
        toast?.({
          message: t('pages.interaction.toasts.sampleRequestFormError'),
          type: 'error',
        });
      },
    });
  };

  useEffect(() => {
    const handleSampleRequestValueChange = (
      data: SampleRequestViewerPayload
    ) => {
      setSteps(data.step);
      if (data.state.step == 'collect') {
        setSignature(data.state.signature);
        setAddressValue(data.state.address);
        setAddressExtension(data.state.addressExtension);
        setDeliveryTimeFrame(data.state.deliveryTimeFrame);
        setDeliveryDate(data.state.deliveryDate);

        const sampleRequestCollectionEl = document.getElementById(
          'sampleRequestCollection'
        );
        if (sampleRequestCollectionEl) {
          sampleRequestCollectionEl.scrollTop =
            sampleRequestCollectionEl.scrollHeight;
        }
      }
    };

    const unsubscribeOnSampleRequestValueChanged =
      onSampleRequestValueChanged?.(handleSampleRequestValueChange);

    const unsubscribeOnSampleRequestSubmitted =
      onSampleRequestAttendeeSubmitted?.(handleAttendeeSubmittedSampleRequest);

    return () => {
      if (unsubscribeOnSampleRequestValueChanged) {
        unsubscribeOnSampleRequestValueChanged();
      }
      if (unsubscribeOnSampleRequestSubmitted) {
        unsubscribeOnSampleRequestSubmitted();
      }
    };
    // Only listened to changed events to update the component's current state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSampleRequestValueChanged, onSampleRequestAttendeeSubmitted]);

  const handleDataChange = (
    currentStep: 'collect' | 'preview',
    currentAddressExtension: string,
    currentAddressValue: string,
    currentDeliveryDate: Date | null,
    currentDeliveryTimeFrame: string | null
  ) => {
    let state: SampleRequestCollectState | SampleRequestHTMLPreviewState;
    if (currentStep === 'collect') {
      state = {
        step: currentStep,
        title: `${t('pages.interaction.common.requestForm')}`,
        attendee,
        requestedSamples: promotionalMaterialGroups,
        address: currentAddressValue,
        addressExtension: currentAddressExtension,
        isDeliveryInformationEnabled,
        isDeliveryDateEnabled,
        deliveryTimeFrame: currentDeliveryTimeFrame,
        deliveryTimeFrames,
        deliveryDate: currentDeliveryDate,
        deliveryDateMinimumDays,
        isSampleRequestSigned: !!activity.sampleRequestFormDocument,
        signature,
      };
    } else {
      const content = compileSampleRequestFormTemplate({
        template: templateData,
        samples: promotionalMaterialGroups,
        receiver: attendee,
        receiverAddress: addressValue ?? '',
        receiverAddressExtension: addressExtension,
        signature,
      });
      state = {
        title: `${t('pages.interaction.common.requestForm')}`,
        step: currentStep,
        content: content,
        address: currentAddressValue,
        addressExtension,
        deliveryTimeFrame: currentDeliveryTimeFrame,
        deliveryDate: currentDeliveryDate,
        signature,
      };
    }

    const payload: SampleRequestViewerPayload = {
      id: attendee?.id ?? '',
      step: currentStep,
      state: state,
    };
    changeSampleRequestCollection?.(payload);
  };

  const handleStepChange = () => {
    const nextStep = step === 'collect' ? 'preview' : 'collect';
    setSteps(nextStep);
    handleDataChange(
      nextStep,
      addressExtension,
      addressValue,
      deliveryDate,
      deliveryTimeFrame
    );
  };

  const handleCloseForm = () => {
    stopSampleRequestCollection?.(attendee?.id ?? '');
  };

  const handleCancelForm = () => {
    handleCloseForm();

    toast?.({
      message: t('pages.interaction.toasts.sampleRequestFormCanceled'),
      type: 'warning',
    });
  };

  const handleAddressExtension = (value: string) => {
    setAddressExtension(value);
    handleDataChange(
      step,
      value,
      addressValue,
      deliveryDate,
      deliveryTimeFrame
    );
  };
  const handleAddressValue = (value: string) => {
    setAddressValue(value);
    handleDataChange(
      step,
      addressExtension,
      value,
      deliveryDate,
      deliveryTimeFrame
    );
  };
  const handleDeliveryDate = (value: Date | null) => {
    setDeliveryDate(value);
    handleDataChange(
      step,
      addressExtension,
      addressValue,
      value,
      deliveryTimeFrame
    );
  };
  const handleDeliveryTimeFrame = (value: string) => {
    setDeliveryTimeFrame(value);
    handleDataChange(step, addressExtension, addressValue, deliveryDate, value);
  };

  const loading =
    isTemplateLoading || isSystemConfigurationLoading || isSubmitting;
  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  const isDisabled = !addressValue || !signature;

  return (
    <Wrapper>
      <Header>
        <Typography variant="h5">
          {t('pages.interaction.common.requestForm')}
        </Typography>
        <Container>
          <Button
            variant="contained"
            disabled={isDisabled}
            onClick={handleStepChange}
          >
            {step === 'collect'
              ? t('components.common.next')
              : t('components.common.back')}
          </Button>
          <Button variant="outlined" onClick={handleCancelForm}>
            {t('components.common.cancel')}
          </Button>
        </Container>
      </Header>
      <Content id={'sampleRequestCollection'}>
        {step === 'collect' && (
          <ProMatCollectionCollect
            activity={activity}
            addressValue={addressValue}
            addressExtension={addressExtension}
            attendee={attendee}
            deliveryDate={deliveryDate}
            deliveryTimeFrame={deliveryTimeFrame}
            deliveryTimeFrames={deliveryTimeFrames}
            deliveryDateMinimumDays={deliveryDateMinimumDays}
            isDeliveryDateEnabled={isDeliveryDateEnabled}
            isDeliveryInformationEnabled={isDeliveryInformationEnabled}
            promotionalMaterialGroups={promotionalMaterialGroups}
            setAddressExtension={handleAddressExtension}
            setAddressValue={handleAddressValue}
            setDeliveryDate={handleDeliveryDate}
            setDeliveryTimeFrame={handleDeliveryTimeFrame}
            signature={signature}
          />
        )}
        {step === 'preview' && (
          <ProMatCollectionPreview
            promotionalMaterialGroups={promotionalMaterialGroups}
            receiver={attendee}
            receiverAddress={addressValue}
            receiverAddressExtension={addressExtension}
            signature={signature}
            template={templateData}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',

  height: '100%',
  maxHeight: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: theme.spacing(2),
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  height: '100%',
  overflow: 'auto',
});

const LoaderWrapper = styled(Box)({
  height: '100%',
  display: 'grid',
  placeContent: 'center',
});
