import { useMediaQuery, useTheme } from '@mui/material';
import {
  ActivityData,
  PersonConsentData,
  RoomContentWrapper,
} from '@ysura/common';
import { ReactNode, useEffect, useState } from 'react';

import { useInteraction } from '@/hooks';
import { InteractionItems } from '@/views/Interaction/Common/Room';

import { VideoAndControls } from './VideoAndControls';

type RoomContentProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  roomId: string;
  renderVideoControlsMobile: () => ReactNode;
  canEditActivity?: boolean;
  openSettingsDialog: VoidFunction;
  openCallInfoDialog: VoidFunction;
};

export const RemoteRoomContent = ({
  activity,
  isOrganizer,
  consentList,
  roomId,
  renderVideoControlsMobile,
  openSettingsDialog,
  openCallInfoDialog,
}: RoomContentProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { isAnyContentShared } = useInteraction();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    /**
     * close the drawer when sharing stops (feature requirement) or when
     * going from small to large screen (ensuring the UI doesn't break)
     */
    if (!isAnyContentShared || !isMobile) {
      setIsDrawerOpen(false);
    }
  }, [isAnyContentShared, isMobile]);

  return (
    <RoomContentWrapper>
      <VideoAndControls
        activity={activity}
        isDrawerOpen={isDrawerOpen}
        isOrganizer={isOrganizer}
        renderVideoControlsMobile={renderVideoControlsMobile}
        openCallInfoDialog={openCallInfoDialog}
        openSettingsDialog={openSettingsDialog}
      />

      <InteractionItems
        canEditActivity
        isOrganizer={isOrganizer}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        activity={activity}
        consentList={consentList}
        roomId={roomId}
      />
    </RoomContentWrapper>
  );
};
