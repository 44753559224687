import {
  LocalHospital,
  LocationOn,
  MedicalServices,
} from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, TextWithEllipsis } from '@ysura/common';
import { useTranslation } from 'react-i18next';

type TouchpointOrganizationProps = {
  activity: ActivityData;
};

export const ActivityOrganization = ({
  activity,
}: TouchpointOrganizationProps) => {
  const { t } = useTranslation();
  const activityAddress = activity.organization?.addresses?.[0];
  const completeAddress = [
    ...(activityAddress?.addressLines ?? []),
    [activityAddress?.postalCode, activityAddress?.city]
      .filter(Boolean)
      .join(', '),
  ]
    .filter(Boolean)
    .join('\n');

  return (
    <WrapperStack>
      <Typography variant="caption">
        {t('pages.personView.organization')}
      </Typography>
      <WrapperStack>
        <ContentStack>
          {activity.organization?.rootOrganization?.name && (
            <ParentOrganizationStack>
              <StyledLocalHospital />
              <TextWithEllipsis color="text.primary" variant="caption">
                {activity.organization.rootOrganization.name}
              </TextWithEllipsis>
            </ParentOrganizationStack>
          )}

          {activity.organization?.name && (
            <OrganizationStack>
              <StyledMedicalServices />
              <TextWithEllipsis variant="caption">
                {activity.organization.name}
              </TextWithEllipsis>
            </OrganizationStack>
          )}
        </ContentStack>

        {completeAddress && (
          <CompleteAddressStack>
            <StyledLocationOn />
            <StyledTypography variant="caption">
              {completeAddress}
            </StyledTypography>
          </CompleteAddressStack>
        )}
      </WrapperStack>
    </WrapperStack>
  );
};

const WrapperStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const ContentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

const ParentOrganizationStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
}));

const StyledLocalHospital = styled(LocalHospital)({
  height: '1.125rem',
  width: '1.125rem',
});

const OrganizationStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.secondary,
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
}));

const StyledMedicalServices = styled(MedicalServices)(({ theme }) => ({
  fill: theme.palette.text.disabled,
  height: '1.125rem',
  width: '1.125rem',
}));

const CompleteAddressStack = styled(Stack)(({ theme }) => ({
  color: theme.palette.text.primary,
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
}));

const StyledLocationOn = styled(LocationOn)(({ theme }) => ({
  color: theme.palette.text.disabled,
  height: '1.125rem',
  width: '1.125rem',
}));

const StyledTypography = styled(Typography)({
  whiteSpace: 'pre-line',
});
