import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';

type ConsentCollectionPreviewProps = {
  consentPreview: string;
};

export const ConsentCollectionPreview = ({
  consentPreview,
}: ConsentCollectionPreviewProps) => {
  const frame = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = frame.current;

    if (iframe) {
      const doc = iframe.contentWindow?.document;

      if (consentPreview) {
        doc?.open();
        doc?.write(consentPreview);
        doc?.close();
      }
    }
  }, [consentPreview]);

  return (
    <CardContent data-testid="consent-collection-preview">
      <FormFrame ref={frame} data-testid="ConsentFormPreview" id="form-frame" />
    </CardContent>
  );
};

const CardContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
}));

const FormFrame = styled('iframe')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: 'none',
  height: '100%',
  width: '100%',
}));
