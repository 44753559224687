import { TopicData } from '@ysura/common';

export const findMatchingTopics = (
  selectedTopics: Array<TopicData>,
  topicOptions: Array<TopicData>
): Array<TopicData> => {
  return selectedTopics.filter((selectedTopic: TopicData) => {
    return topicOptions.find(
      (topicOption: TopicData) => topicOption?.oid === selectedTopic?.oid
    );
  });
};
