import { gql } from '@apollo/client';

export const collectPaperPersonConsent = gql`
  mutation collectPaperPersonConsent(
    $oid: PersonConsentId!
    $consentTypeId: ConsentTypeId!
    $issuerPersonId: PersonId!
    $signerPersonId: PersonId!
    $actionAt: DateTime!
    $consentItemsValues: [PersonConsentItemValueInput!]!
    $formIdentifier: String!
  ) {
    collectPaperPersonConsent(
      input: {
        oid: $oid
        consentTypeId: $consentTypeId
        issuerPersonId: $issuerPersonId
        signerPersonId: $signerPersonId
        actionAt: $actionAt
        consentItemsValues: $consentItemsValues
        formIdentifier: $formIdentifier
      }
    ) {
      id
      oid
    }
  }
`;
