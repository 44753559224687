import { gql } from '@apollo/client';

export const updatePreference = gql`
  mutation updatePreference(
    $oid: PreferenceId!
    $actions: [UpdatePreferenceAction!]!
  ) {
    updatePreference(input: { oid: $oid, actions: $actions }) {
      oid
    }
  }
`;
