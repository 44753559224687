import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

import { BackToTopButton } from '@/components/Button';

export const InteractionBackTopLayout: FC = ({ children }) => {
  return (
    <Wrapper>
      <Main data-testid="interaction-back-top">{children}</Main>
      <BackToTopButton />
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  // TODO: designers will update the background color. We will use this one for now.
  background: 'hsla(219, 34%, 75%)',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  minHeight: '100%',
});

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',

  width: '100%',
  height: '100%',
  maxWidth: theme.breakpoints.values.lg,

  padding: `${theme.spacing(5)} ${theme.spacing(3)}`,

  transition: theme.transitions.create('margin-left', {
    duration: theme.transitions.duration.shorter,
  }),

  [theme.breakpoints.down('md')]: {
    width: 'unset',
    maxWidth: 'unset',

    padding: theme.spacing(2),

    transition: 'unset',
  },
}));
