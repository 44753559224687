import {
  CommunicationDataBaseTypeDataEnum,
  CommunicationDataData,
  EmploymentData,
  getPersonFullNameWithTitle,
  PersonConsentData,
  PersonData,
} from '@ysura/common';
import { v4 as uuidv4 } from 'uuid';

import {
  getAddress,
  getPostalCodeCityAddress,
  getStreetAddress,
} from '@/services/employment';

export const getPersonCommunicationData = (
  person?: PersonData
): Array<CommunicationDataData> => {
  if (person?.communicationData?.length) {
    return person.communicationData;
  }
  if (
    person?.employments?.some((it) => it.communicationData) ||
    person?.employments?.some((it) => it.organization?.communicationData)
  ) {
    let communicationData: Array<CommunicationDataData> = [];
    person?.employments?.forEach((employment) => {
      if (employment?.communicationData?.length) {
        communicationData = [
          ...communicationData,
          ...buildCommunicationData(employment.communicationData),
        ];
      } else if (employment?.organization?.communicationData?.length) {
        communicationData = [
          ...communicationData,
          ...buildCommunicationData(employment.organization.communicationData),
        ];
      }
    });

    return communicationData;
  }

  return [];
};

const buildCommunicationData = (
  communications: Array<CommunicationDataData>
) => {
  return communications.map((communication) => {
    return {
      oid: communication.oid ?? uuidv4(),
      value: communication.value ?? undefined,
      communicationDataType: {
        baseType:
          (communication.communicationDataType
            ?.baseType as unknown as CommunicationDataBaseTypeDataEnum) ??
          undefined,
        name: communication.communicationDataType?.name ?? undefined,
      },
    };
  });
};

export const getPersonDetails = (person: PersonData) => {
  const name = getPersonFullNameWithTitle(person);
  const firstEmployment = person.employments?.[0];
  const organizationName = firstEmployment?.organization?.name ?? '';
  const addressName = firstEmployment
    ? getPersonEmploymentAddress(firstEmployment)
    : '';

  return {
    id: person.id,
    personName: name,
    organizationName: organizationName,
    addressName: addressName,
  };
};

export const getPersonFullName = (person?: PersonData): string => {
  if (!person) {
    return '';
  }

  const { firstName, lastName } = person;

  const divider = firstName && lastName ? ',' : '';

  return `${lastName ?? ''}${divider} ${firstName ?? ''}`
    .trim()
    .replace('  ', ' ');
};

export const getPersonFullNameForInteraction = (
  person?: PersonData
): string => {
  if (!person) {
    return '';
  }

  const { firstName, lastName } = person;

  return `${firstName ?? ''} ${lastName ?? ''}`.trim().replace('  ', ' ');
};

export const getPersonEmploymentAddress = (employment: EmploymentData) => {
  const address = getAddress(employment);
  const street = getStreetAddress(address);
  const city = getPostalCodeCityAddress(address);

  const divider = street ? ', ' : '';

  return `${street}${divider}${city}`;
};

export const getTelephoneCommunicationData = (
  person: PersonData,
  consents: PersonConsentData[]
): CommunicationDataData[] => {
  if (!person) {
    return [];
  }

  // Person Consents Communication
  const commConsents = consents?.flatMap(
    (consent) =>
      consent.consentItemValues?.flatMap(
        (item) =>
          item.communicationDataValues
            ?.filter(
              (comm) =>
                comm.value &&
                comm.communicationDataType?.baseType ===
                  CommunicationDataBaseTypeDataEnum.PHONE
            )
            .flatMap((comm) => comm) ?? []
      ) ?? []
  );

  // Person Communication
  const commPerson =
    person.communicationData
      ?.filter(
        (comm) =>
          comm.value &&
          comm.communicationDataType?.baseType ===
            CommunicationDataBaseTypeDataEnum.PHONE
      )
      .map((comm) => comm) ?? [];

  // Person Employment Communication
  const commEmployments =
    person.employments?.flatMap(
      (employment) =>
        employment.communicationData
          ?.filter(
            (comm) =>
              comm.value &&
              comm.communicationDataType?.baseType ===
                CommunicationDataBaseTypeDataEnum.PHONE
          )
          .map((comm) => comm) ?? []
    ) ?? [];

  return [...commConsents, ...commPerson, ...commEmployments];
};
