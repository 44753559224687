import { useMediaQuery, useTheme } from '@mui/material';
import { PersonConsentData, PersonData } from '@ysura/common';

import { ConsentAccordionBlock } from './ConsentAccordionBlock';
import { ConsentSkeletonStaticBlock } from './ConsentSkeletonStaticBlock';
import { ConsentStaticBlock } from './ConsentStaticBlock';

type ConsentWrapperProps = {
  consentList: PersonConsentData[];
  person: PersonData;
  isInRemoteRoom?: boolean;
  isDigitalConsentActionsEnable?: boolean;
  isLoading?: boolean;
};

export const ConsentWrapper = ({
  consentList,
  person,
  isInRemoteRoom = false,
  isDigitalConsentActionsEnable,
  isLoading = false,
}: ConsentWrapperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isLoading) {
    return <ConsentSkeletonStaticBlock isInRemoteRoom={isInRemoteRoom} />;
  }

  if (!isMobile && isInRemoteRoom) {
    return (
      <ConsentStaticBlock
        consentList={consentList}
        isInRemoteRoom={isInRemoteRoom}
        person={person}
      />
    );
  }

  return (
    <ConsentAccordionBlock
      isInRemoteRoom={isInRemoteRoom}
      isDigitalConsentActionsEnable={isDigitalConsentActionsEnable}
      consentList={consentList}
      person={person}
    />
  );
};
