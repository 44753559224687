import { PromotionalMaterialGroupData, TopicData } from '@ysura/common';
import { unionBy } from 'lodash';

import { ViewedMedia } from '@/hooks/useInteraction';
import { intersectionTopics } from '@/views/Activity/TouchpointManagement/helpers';
import { getIntersectionTopicsForPromotionalMaterialsAndActivityType } from '@/views/Activity/Update/PromotionalMaterials';
import { getTopicsFromMedia } from '@/views/Interaction/helpers/mediaHelper';

const addIntersectTopicsActivityTypeAndViewedMedia = (
  topicsFromActivityType: Array<TopicData>,
  media: ViewedMedia
): Array<TopicData> => {
  if (!topicsFromActivityType.length && !media) {
    return [];
  }

  const topicsFromMedia: Array<TopicData> = getTopicsFromMedia(media);

  return intersectionTopics(topicsFromActivityType, topicsFromMedia);
};

export const addingTopics = (
  topics: Array<TopicData>,
  topicsFromActivityType: Array<TopicData>,
  media: ViewedMedia,
  promotionalMaterials: Array<PromotionalMaterialGroupData>
): Array<TopicData> => {
  const topicsActivityTypeAndMedia =
    addIntersectTopicsActivityTypeAndViewedMedia(topicsFromActivityType, media);
  const topicsActivityTypeAndPromotionalMaterials =
    getIntersectionTopicsForPromotionalMaterialsAndActivityType(
      topicsFromActivityType,
      promotionalMaterials
    );

  return unionBy(
    topics,
    topicsActivityTypeAndMedia,
    topicsActivityTypeAndPromotionalMaterials,
    'oid'
  );
};
