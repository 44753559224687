import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Card, IconButton, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const MediaSearchCardSkeleton = () => {
  return (
    <CardWrapper data-testid="media-search-card-skeleton">
      <Skeleton variant="rounded" width={64} height={64} />
      <Stack flex={1} gap={1}>
        <Skeleton width={50} height={22} />
        <Skeleton width={150} height={22} />
      </Stack>
      <IconButton disabled={true} color="primary">
        <AddCircleIcon />
      </IconButton>
    </CardWrapper>
  );
};

export const MediaSearchSkeleton = () => {
  return (
    <>
      <MediaSearchCardSkeleton />
      <MediaSearchCardSkeleton />
      <MediaSearchCardSkeleton />
    </>
  );
};

const CardWrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: theme.spacing(2),
}));
