import { PersonCard, PersonData } from '@ysura/common';

import { getAttendeeData } from '@/components/Attendee';
import { LinkNoDecoration } from '@/components/LinkTo';

type AttendeeCardProps = {
  attendee?: PersonData | undefined;
  pathToNavigate?: string | undefined;
};

export const AttendeeCard = ({
  attendee,
  pathToNavigate,
}: AttendeeCardProps) => {
  const { person, organization, address } = getAttendeeData(attendee);

  return (
    <LinkNoDecoration to={pathToNavigate ?? ''} data-testid="attendee-card">
      <PersonCard
        personName={person}
        organizationName={organization}
        addressName={address}
      />
    </LinkNoDecoration>
  );
};
