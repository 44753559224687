import { Person } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  IconWrapper,
  OrganizationalUnitData,
  PersonData,
  TextHighlighter,
} from '@ysura/common';

import { getCoOrganizerInfo } from '@/components/CoOrganizer';

type CoOrganizerCardProps = {
  person?: PersonData;
  organizationalUnit?: OrganizationalUnitData;
  query?: string;
};
export const CoOrganizerCard = ({
  person,
  organizationalUnit,
  query,
}: CoOrganizerCardProps) => {
  const theme = useTheme();
  const { name, unit } = getCoOrganizerInfo(person, organizationalUnit);

  return (
    <Row data-testid="coOrganizer-person-card">
      <IconWrapper backgroundColor={theme.palette.text.disabled} size={20}>
        <PersonIcon />
      </IconWrapper>
      <Stack>
        <Typography
          variant="subtitle2"
          color={theme.palette.text.primary}
          data-testid="coOrganizer-person-name"
        >
          <TextHighlighter
            searchQueryArray={query?.split(' ') ?? []}
            text={name}
          />
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.text.primary}
          data-testid="coOrganizer-organizationUnit-name"
        >
          {unit}
        </Typography>
      </Stack>
    </Row>
  );
};

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const PersonIcon = styled(Person)(({ theme }) => ({
  fontSize: 12,
  fill: theme.palette.common.white,
}));
