import { gql } from '@apollo/client';

export const getPersonAvailableCommunicationData = gql`
  query getPersonAvailableCommunicationData(
    $id: ID!
    $baseType: CommunicationDataBaseType
  ) {
    person(id: $id) {
      id
      communicationData(
        filter: { communicationDataType: { baseType: { _eq: $baseType } } }
      ) {
        edges {
          node {
            communicationDataType {
              name
              baseType
            }
            oid
            value
          }
        }
      }
      employments(filter: { state: { _eq: ACTIVE } }) {
        filteredCount
        edges {
          node {
            id
            oid
            communicationData(
              filter: {
                communicationDataType: { baseType: { _eq: $baseType } }
              }
            ) {
              edges {
                node {
                  communicationDataType {
                    name
                    baseType
                  }
                  oid
                  value
                }
              }
            }
          }
        }
      }
      consents(filter: { consentType: { state: { _eq: ACTIVE } } }) {
        edges {
          node {
            id
            oid
            consentItemValues {
              communicationDataValues {
                value
                consentCommunicationDataType {
                  oid
                  communicationDataType {
                    id
                    baseType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
