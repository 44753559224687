import {
  ActivityData,
  CommentData,
  MediaData,
  PromotionalMaterialGroupData,
  SampleRequestFormData,
  TopicData,
} from '@ysura/common';
import { formatISO } from 'date-fns';

import {
  prepareCommentsForMutation,
  prepareDiscussedTopicsForMutation,
  prepareMediaForMutation,
  prepareRequestedPromotionalMaterials,
} from '@/services/graphql/hooks';
import { ActivityStatus, UpdateActivityAction } from '@/services/graphql/types';

export const prepareMutationForEndingInteraction = ({
  originalActivity,
  startDateTime,
  viewedMedias,
  sampleRequestForm,
  promotionalMaterialGroups,
  topics,
  comments,
}: {
  originalActivity: ActivityData | null;
  startDateTime?: string;
  viewedMedias?: Array<MediaData>;
  sampleRequestForm?: SampleRequestFormData | null;
  promotionalMaterialGroups?: Array<PromotionalMaterialGroupData>;
  topics?: Array<TopicData>;
  comments?: Array<CommentData>;
}): UpdateActivityAction => {
  let actions: UpdateActivityAction = {};

  // Start date
  actions = {
    ...actions,
    startDate: {
      value: startDateTime ?? formatISO(new Date()),
    },
  };

  // End date
  actions = {
    ...actions,
    endDate: {
      value: formatISO(new Date()),
    },
  };

  // Viewed medias
  if (viewedMedias && viewedMedias.length > 0) {
    actions = {
      ...actions,
      media: {
        value: prepareMediaForMutation(viewedMedias),
      },
    };
  }

  // Topics
  if (topics && topics.length > 0) {
    actions = {
      ...actions,
      discussedTopics: {
        value: prepareDiscussedTopicsForMutation(topics),
      },
    };
  }

  // Promotional material groups
  // When the activity comes from CRM with some promats, the organizer may delete them and want to update the activity without promats
  // For this reason, in this case it is acceptable to send an empty promats array
  if (promotionalMaterialGroups) {
    actions = {
      ...actions,
      requestedPromotionalMaterials: {
        value: prepareRequestedPromotionalMaterials(promotionalMaterialGroups),
      },
    };
  }

  // Sample Requests
  if (sampleRequestForm) {
    actions = {
      ...actions,
      sampleRequestFormId: {
        value: sampleRequestForm.oid,
      },
    };
  }

  // Comments
  if (comments && comments.length > 0)
    actions = {
      ...actions,
      comments: {
        value: prepareCommentsForMutation(comments, originalActivity?.oid),
      },
    };

  // Status
  actions = {
    ...actions,
    status: {
      value: ActivityStatus.CLOSED,
    },
  };

  // Confirmed
  actions = {
    ...actions,
    confirmed: {
      value: false,
    },
  };

  return actions;
};
