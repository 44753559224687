import { Box, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverviewCard } from '@ysura/common';

import { ContentWrapper, TextWrapper } from './MediaLayoutCard';

export const MediaSkeletonCard = () => {
  return (
    <OverviewCard testId="media-skeleton-card">
      <ContentWrapper>
        <Skeleton height={120}></Skeleton>

        <TextWrapper>
          <Typography variant="subtitle2">
            <Skeleton width={200} height={22}></Skeleton>
          </Typography>
          <Typography variant="caption">
            <Skeleton width={160} height={18}></Skeleton>
          </Typography>
        </TextWrapper>
        <BoxLine>
          <Skeleton width={64} height={22}></Skeleton>
          <Skeleton width={120} height={18} />
        </BoxLine>
      </ContentWrapper>
    </OverviewCard>
  );
};

const BoxLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));
