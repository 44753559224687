import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

type FilterResetButtonProps = {
  disableButton?: boolean;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  onClick?: VoidFunction;
  testId?: string;
};
export const FilterResetButton = ({
  disableButton = false,
  color,
  onClick,
  testId = 'filter-reset-button',
}: FilterResetButtonProps) => {
  return (
    <ResetButton
      data-testid={testId}
      disabled={disableButton}
      variant="outlined"
      size="medium"
      color={color}
      onClick={onClick}
    >
      <RestartAltIcon />
    </ResetButton>
  );
};

const ResetButton = styled(Button)(() => ({
  padding: 5,
  minWidth: 36,
}));
