import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentInputFieldData,
  ConsentItemData,
  ConsentTypeManualProcessReasonData,
  ConsentValue,
  CountryData,
  MultiLineSelectOption,
  MultiLineSingleSelect,
  PersonConsentData,
  PersonData,
} from '@ysura/common';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ActionDialog } from '@/components/ActionDialog';
import { ConsentCollectionContent } from '@/components/Consent/ConsentCollectionContent';
import { useInteraction } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { useCollectManualPersonConsentMutation } from '@/services/graphql/hooks';
import {
  ConsentItemStateChange,
  PersonConsentItemValueInput,
} from '@/services/graphql/types/types';

type ManualConsentCollectionDialogProps = {
  isOpen: boolean;
  consent: PersonConsentData;
  person: PersonData;
  defaultCountry?: CountryData;
  handleClose: VoidFunction;
  handleConfirm: (message: string) => void;
};

export const ManualConsentCollectionDialog = ({
  isOpen,
  consent,
  person,
  defaultCountry,
  handleClose,
  handleConfirm,
}: ManualConsentCollectionDialogProps) => {
  const { t } = useTranslation();

  const { setConsentAsCollected } = useInteraction();

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [consentValues, setConsentValues] = useState<ConsentValue | null>(null);
  const [selectManualProcessReason, setSelectManualProcessReason] = useState<
    MultiLineSelectOption | undefined
  >();

  const [
    collectManualPersonConsentMutation,
    { loading: isCollectConsentLoading },
  ] = useCollectManualPersonConsentMutation();

  const handleSave = () => {
    const consentItemsValues: Array<PersonConsentItemValueInput> =
      consent.consentType?.consentItems?.map((it: ConsentItemData) => {
        const collectedCommunicationData =
          it.consentInputFields?.map((input: ConsentInputFieldData) => {
            return {
              oid: input.consentCommunicationDataTypeId,
              value: consentValues?.[input.inputId ?? ''] as string,
            };
          }) ?? [];

        return {
          collectedCommunicationData: collectedCommunicationData,
          itemState: consentValues?.[it.inputId ?? '']
            ? ConsentItemStateChange.TURN_ON
            : ConsentItemStateChange.TURN_OFF,
          oid: it.oid,
        };
      }) ?? [];
    handleSubmitForm(consentItemsValues);
  };

  const handleManualReasonSelect = (
    e: SyntheticEvent<Element, Event>,
    value: MultiLineSelectOption
  ) => {
    setSelectManualProcessReason(value);
  };

  const handleSubmitForm = (
    consentItemsValues: Array<PersonConsentItemValueInput>
  ) => {
    if (!person?.oid || !consent?.consentType?.oid) {
      return;
    }

    collectManualPersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent.oid ?? uuidv4(),
        consentTypeId: consent.consentType?.oid,
        signerPersonId: person?.oid,
        consentItemsValues: consentItemsValues,
        manualProcessReason: selectManualProcessReason?.oid ?? '',
      },
      onCompleted: (data) => {
        if (data?.collectManualPersonConsent?.oid) {
          setConsentAsCollected?.(data.collectManualPersonConsent.oid);
        }
        handleConfirm(t('pages.interaction.toasts.consentFormSubmitted'));
      },
    });
  };

  if (isOpen && !person) {
    console.log('No attendee in activity');

    return null;
  }

  const manualReasons: Array<MultiLineSelectOption> =
    consent.consentType?.manualProcessReasons?.map(
      (it: ConsentTypeManualProcessReasonData) => {
        return {
          oid: it.oid ?? '',
          data: [
            {
              content: it.reason ?? '',
            },
          ],
        };
      }
    ) ?? [];

  return (
    <ActionDialog
      isOpen={isOpen}
      maxWidth={'lg'}
      title={consent?.consentType?.name ?? ''}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      isPrimaryButtonDisabled={!isFormValid || !selectManualProcessReason}
      isLoading={isCollectConsentLoading}
      testId={'manual-consent-collection-dialog'}
      onClickPrimaryButton={handleSave}
      onClickSecondaryButton={handleClose}
    >
      <ConsentCollectionContent
        consentType={consent.consentType}
        person={person}
        defaultCountry={defaultCountry}
        additionalInputComponent={
          <Container>
            <Typography variant="caption">
              {t('components.consentDialog.collectManual.reasons')}
            </Typography>
            <MultiLineSingleSelect
              availableOptions={manualReasons}
              selectedOption={selectManualProcessReason}
              label={t('components.consentDialog.collectManual.selectReason')}
              onSelect={handleManualReasonSelect}
            ></MultiLineSingleSelect>
            <HintTypography variant="caption">
              {t('components.consentDialog.collectManual.reasonHints')}
            </HintTypography>
          </Container>
        }
        setIsFormValid={setIsFormValid}
        setConsentValues={setConsentValues}
      />
    </ActionDialog>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));

const HintTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
