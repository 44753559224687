import { Box, Skeleton, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export const SelectedItemSkeleton = () => {
  const { t } = useTranslation();

  return (
    <Wrapper data-testid="selected-item-skeleton">
      <SmallBox>
        <Skeleton variant="circular" width={20} height={20} />
        <Skeleton width={200} height={22} />
      </SmallBox>

      <Row>
        <Stack gap={1}>
          <Skeleton width={200} height={22} />
          <Skeleton width={200} height={22} />
        </Stack>
        <SmallBox>
          <SelectsWrapper>
            <Select
              select
              label={t('pages.interaction.common.physical')}
              disabled={true}
              size="small"
              value={0}
            >
              <option value={0}>{0}</option>
            </Select>
            <Select
              select
              label={t('pages.interaction.common.virtual')}
              disabled={true}
              size="small"
              value={0}
            >
              <option value={0}>{0}</option>
            </Select>
          </SelectsWrapper>
        </SmallBox>
      </Row>

      <Row>
        <Skeleton width={80} height={22} />
        <Skeleton width={80} height={22} />
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const SelectsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Select = styled(TextField)({
  width: 88,
});
