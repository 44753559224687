import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetDeliveryTimeFrameListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetDeliveryTimeFrameListQuery = {
  __typename?: 'Query';
  deliveryTimeFrames: {
    __typename?: 'DeliveryTimeFrameConnection';
    edges?: Array<{
      __typename?: 'DeliveryTimeFrameEdge';
      node?: {
        __typename?: 'DeliveryTimeFrame';
        id: string;
        oid: string;
        name: string;
      } | null;
    }> | null;
  };
};

/**
 * __useGetDeliveryTimeFrameListQuery__
 *
 * To run a query within a React component, call `useGetDeliveryTimeFrameListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryTimeFrameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryTimeFrameListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveryTimeFrameListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDeliveryTimeFrameListQuery,
    GetDeliveryTimeFrameListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetDeliveryTimeFrameListQuery,
    GetDeliveryTimeFrameListQueryVariables
  >(Operations.getDeliveryTimeFrameList, options);
}
export function useGetDeliveryTimeFrameListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeliveryTimeFrameListQuery,
    GetDeliveryTimeFrameListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetDeliveryTimeFrameListQuery,
    GetDeliveryTimeFrameListQueryVariables
  >(Operations.getDeliveryTimeFrameList, options);
}
export function useGetDeliveryTimeFrameListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDeliveryTimeFrameListQuery,
    GetDeliveryTimeFrameListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetDeliveryTimeFrameListQuery,
    GetDeliveryTimeFrameListQueryVariables
  >(Operations.getDeliveryTimeFrameList, options);
}
export type GetDeliveryTimeFrameListQueryHookResult = ReturnType<
  typeof useGetDeliveryTimeFrameListQuery
>;
export type GetDeliveryTimeFrameListLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryTimeFrameListLazyQuery
>;
export type GetDeliveryTimeFrameListSuspenseQueryHookResult = ReturnType<
  typeof useGetDeliveryTimeFrameListSuspenseQuery
>;
export type GetDeliveryTimeFrameListQueryResult = Apollo.QueryResult<
  GetDeliveryTimeFrameListQuery,
  GetDeliveryTimeFrameListQueryVariables
>;
