import { DataPermissionData } from '@ysura/common';

import { convertedDataPermissionType } from '@/services/graphql/parsers/utils';
import { DataPermission } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseDataPermissions = (
  dataPermissions: DeepPartial<DataPermission[]>
): Array<DataPermissionData> => {
  const permissions = dataPermissions.map((each) => {
    return parseDataPermission(each);
  });

  return filterNonNull(permissions);
};

export const parseDataPermission = (
  dataPermission?: DeepPartial<DataPermission>
): DataPermissionData | undefined => {
  return dataPermission
    ? {
        type: convertedDataPermissionType(dataPermission.type),
        scoped: dataPermission.scoped,
      }
    : undefined;
};
