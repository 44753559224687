import { NetworkStatus } from '@apollo/client';
import { useMediaQuery, useTheme } from '@mui/material';
import { OrganizationData } from '@ysura/common';
import { useEffect, useState } from 'react';

import { buildPersonFilterQuery } from '@/services/graphql/helpers';
import { useGetSearchedPersonsLazyQuery } from '@/services/graphql/hooks';
import { parsePersonConnection } from '@/services/graphql/parsers';
import { PersonFilterInput, Scope } from '@/services/graphql/types';
import { filterNonNull } from '@/utils/dataHelpers/filters';

import { DesktopOrganizationHierarchyDialog } from './DesktopOrganizationHierarchyDialog';
import { MobileOrganizationHierarchyDialog } from './MobileOrganizationHierarchyDialog';

const NUMBER_OF_PERSONS_TO_SEARCH = 20;
interface OrganizationHierarchyDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  organization: OrganizationData;
}
export const OrganizationHierarchyDialog = ({
  isOpen,
  onClose,
  organization,
}: OrganizationHierarchyDialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationData>(organization);

  const [
    searchEmployments,
    { data, fetchMore, called, refetch, networkStatus },
  ] = useGetSearchedPersonsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isOpen && selectedOrganization) {
      const personFilterQuery: PersonFilterInput = buildPersonFilterQuery(
        [],
        Scope.TERRITORY
      );
      personFilterQuery.employments = {};
      personFilterQuery.employments.organization = {
        oid: { _in: filterNonNull([selectedOrganization.oid]) },
      };

      if (called) {
        refetch({
          first: NUMBER_OF_PERSONS_TO_SEARCH,
          filter: personFilterQuery,
        });
      } else {
        searchEmployments({
          variables: {
            first: NUMBER_OF_PERSONS_TO_SEARCH,
            filter: personFilterQuery,
          },
        });
      }
    }
  }, [
    organization,
    searchEmployments,
    isOpen,
    called,
    selectedOrganization,
    refetch,
  ]);

  const handleFetchMorePersons = () => {
    const endCursor = data?.persons?.pageInfo?.endCursor ?? '';

    fetchMore({
      variables: {
        after: endCursor,
      },
    });
  };

  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const persons = data?.persons
    ? parsePersonConnection(data.persons)
    : undefined;

  const hasMore = data?.persons?.pageInfo?.hasNextPage ?? false;

  if (isMobile) {
    return (
      <MobileOrganizationHierarchyDialog
        isOpen={isOpen}
        isLoading={isLoading}
        hasMore={hasMore}
        total={data?.persons.filteredCount ?? 0}
        handleFetchMorePersons={handleFetchMorePersons}
        persons={persons}
        organization={organization}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        onClose={onClose}
      />
    );
  }

  return (
    <DesktopOrganizationHierarchyDialog
      isOpen={isOpen}
      hasMore={hasMore}
      total={data?.persons.filteredCount ?? 0}
      handleFetchMorePersons={handleFetchMorePersons}
      persons={persons}
      organization={organization}
      selectedOrganization={selectedOrganization}
      setSelectedOrganization={setSelectedOrganization}
      onClose={onClose}
    />
  );
};
