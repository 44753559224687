import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StockTypeDataEnum } from '@ysura/common';
import { useTranslation } from 'react-i18next';

type PromotionalMaterialStockItemProps = {
  type: StockTypeDataEnum;
  isDoneTouchpoint: boolean;
  quantity: number;
  requestedQuantity: number;
};

export const PromotionalMaterialStockItem = ({
  type,
  quantity,
  requestedQuantity,
  isDoneTouchpoint,
}: PromotionalMaterialStockItemProps) => {
  const { t } = useTranslation();

  const stockType = (type: StockTypeDataEnum) =>
    type === StockTypeDataEnum.PHYSICAL
      ? 'pages.interaction.common.physical'
      : 'pages.interaction.common.virtual';

  return (
    <StockLine data-testid="promat-batch-stock">
      <Typography variant="caption">{t(stockType(type))}</Typography>

      <MediumBox>
        <SmallBox data-testid="requested-quantity">
          <QuantityLabelTypography variant="caption">
            {t('pages.interaction.common.requested')}
          </QuantityLabelTypography>
          <Typography variant="caption">{requestedQuantity}</Typography>
        </SmallBox>

        {isDoneTouchpoint && (
          <SmallBox data-testid="delivered-quantity">
            <QuantityLabelTypography variant="caption">
              {t('pages.interaction.common.delivered')}
            </QuantityLabelTypography>
            <Typography variant="caption">{quantity}</Typography>
          </SmallBox>
        )}
      </MediumBox>
    </StockLine>
  );
};

const StockLine = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const MediumBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const QuantityLabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
