import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DocumentData, Loader } from '@ysura/common';

import {
  DocumentPreviewContent,
  DocumentPreviewHeader,
} from '@/components/DocumentPreview';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useGetDocument } from '@/services/api';
import { useGetDocumentByIdQuery } from '@/services/graphql/hooks';
import { parseDocumentData } from '@/services/graphql/parsers';

type FilePreviewDialogProps = {
  document: DocumentData;
  title: string;
  maxWidth?: 'xl' | 'lg';
  isOpen: boolean;
  isDownloadButtonShown?: boolean;
  isInInteraction?: boolean;
  onClose: VoidFunction;
};

export const DocumentPreviewDialog = ({
  document,
  title,
  isOpen,
  maxWidth = 'lg',
  isDownloadButtonShown = true,
  isInInteraction = false,
  onClose,
}: FilePreviewDialogProps) => {
  const { data: documentData } = useGetDocumentByIdQuery({
    variables: {
      documentId: document?.id ?? '',
    },
    fetchPolicy: 'network-only',
  });
  const parsedDocument = documentData?.document
    ? parseDocumentData(documentData.document)
    : null;

  const { data, isLoading } = useGetDocument(parsedDocument);

  return (
    <StyledDialog
      fullWidth
      data-testid="document-preview-dialog"
      maxWidth={maxWidth}
      open={isOpen}
    >
      <DocumentPreviewHeader
        data-testid="document-preview-header-dialog"
        title={title}
        onClose={onClose}
      />
      {isLoading || !data ? (
        <Loader />
      ) : (
        <DocumentPreviewContent
          data-testid="document-preview-content-dialog"
          document={data}
          documentName={title}
          isDownloadButtonShown={isDownloadButtonShown}
          isInInteraction={isInInteraction}
          onClose={onClose}
        />
      )}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    height: `calc(100% - ${theme.spacing(8)})`,

    [theme.breakpoints.down('md')]: {
      height: '100%',
    },

    [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
      {
        margin: theme.spacing(2),
        maxHeight: `calc(100% - ${theme.spacing(4)})`,
      },
  },
}));
