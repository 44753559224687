// Needs to be disabled to do treeshaking properly here
/* eslint-disable import/no-duplicates */
import { Locale } from 'date-fns';
import { de, enUS } from 'date-fns/locale';

import { useLocale } from './state';

type MappingObject = {
  [key: string]: Locale;
};

const LOCALE_MAPPING: MappingObject = {
  de: de,
  en: enUS,
  dev: enUS,
  default: enUS,
};

export const useDateLocale = () => {
  const { locale } = useLocale();

  let dateLocale: Locale;
  if (locale) {
    const firstPart = locale.split('-')[0];
    dateLocale = LOCALE_MAPPING[firstPart] || LOCALE_MAPPING.default;
  } else {
    dateLocale = LOCALE_MAPPING.default;
  }

  return { dateLocale };
};
