import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetConsentFormIdentifiersQueryVariables = Types.Exact<{
  consentTypeId: Types.Scalars['ID']['input'];
}>;

export type GetConsentFormIdentifiersQuery = {
  __typename?: 'Query';
  consentFormIdentifiers: Array<string>;
};

/**
 * __useGetConsentFormIdentifiersQuery__
 *
 * To run a query within a React component, call `useGetConsentFormIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsentFormIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsentFormIdentifiersQuery({
 *   variables: {
 *      consentTypeId: // value for 'consentTypeId'
 *   },
 * });
 */
export function useGetConsentFormIdentifiersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConsentFormIdentifiersQuery,
    GetConsentFormIdentifiersQueryVariables
  > &
    (
      | { variables: GetConsentFormIdentifiersQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetConsentFormIdentifiersQuery,
    GetConsentFormIdentifiersQueryVariables
  >(Operations.getConsentFormIdentifiers, options);
}
export function useGetConsentFormIdentifiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConsentFormIdentifiersQuery,
    GetConsentFormIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetConsentFormIdentifiersQuery,
    GetConsentFormIdentifiersQueryVariables
  >(Operations.getConsentFormIdentifiers, options);
}
export function useGetConsentFormIdentifiersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetConsentFormIdentifiersQuery,
    GetConsentFormIdentifiersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetConsentFormIdentifiersQuery,
    GetConsentFormIdentifiersQueryVariables
  >(Operations.getConsentFormIdentifiers, options);
}
export type GetConsentFormIdentifiersQueryHookResult = ReturnType<
  typeof useGetConsentFormIdentifiersQuery
>;
export type GetConsentFormIdentifiersLazyQueryHookResult = ReturnType<
  typeof useGetConsentFormIdentifiersLazyQuery
>;
export type GetConsentFormIdentifiersSuspenseQueryHookResult = ReturnType<
  typeof useGetConsentFormIdentifiersSuspenseQuery
>;
export type GetConsentFormIdentifiersQueryResult = Apollo.QueryResult<
  GetConsentFormIdentifiersQuery,
  GetConsentFormIdentifiersQueryVariables
>;
