import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverviewCard } from '@ysura/common';

export const ConsentSkeletonCard = () => {
  return (
    <OverviewCard testId="consent-skeleton-card">
      <Wrapper>
        <Typography variant="subtitle2">
          <Skeleton width={200} height={22} />
        </Typography>
        <Skeleton width={64} height={22} />
        <SmallBox>
          <Skeleton width={64} height={22} />
          <Skeleton width={64} height={22} />
        </SmallBox>
        <Skeleton width={160} />
      </Wrapper>
    </OverviewCard>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const SmallBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
}));
