import { Email, Phone } from '@mui/icons-material';
import {
  Box,
  List as MuiList,
  ListItem as MuiListItem,
  Stack,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  CommunicationDataBaseTypeDataEnum,
  CommunicationDataData,
  IconWrapper,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@/hooks';

interface CommunicationDataStaticBlockProps {
  title: string;
  communicationData: CommunicationDataData[];
}

export const CommunicationDataStaticBlock = ({
  title,
  communicationData,
}: CommunicationDataStaticBlockProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  return (
    <StyleBox>
      <DisableTypography variant="caption">{t(title)}</DisableTypography>
      <MuiList>
        {communicationData.map((contact, idx) => (
          <ListItem
            key={`contact-info-${idx}`}
            divider={idx < communicationData.length - 1}
            data-testid="contact-list-item"
          >
            <Stack>
              <Typography variant="caption">
                {contact?.communicationDataType?.name}
              </Typography>
              <DisableTypography variant="caption">
                {contact?.value}
              </DisableTypography>
            </Stack>
            <IconsContainer>
              {contact?.communicationDataType?.baseType ===
                CommunicationDataBaseTypeDataEnum.EMAIL && (
                <StyledIconWrapper size={36}>
                  <Email
                    color="primary"
                    fontSize="small"
                    data-testid="icon-email"
                  />
                </StyledIconWrapper>
              )}
              {currentUser?.permissions?.common?.canMakePhoneCall &&
                contact?.communicationDataType?.baseType ===
                  CommunicationDataBaseTypeDataEnum.PHONE && (
                  <a href={'tel:' + contact?.value}>
                    <StyledIconWrapper size={36}>
                      <Phone
                        color="primary"
                        fontSize="small"
                        data-testid="icon-phone"
                      />
                    </StyledIconWrapper>
                  </a>
                )}
            </IconsContainer>
          </ListItem>
        ))}
      </MuiList>
    </StyleBox>
  );
};

const StyleBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.neutral,
  borderRadius: theme.shape.borderRadius,
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  border: 'none',

  ':last-child': {
    paddingBottom: 0,
  },
}));

const IconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
  cursor: 'pointer',
  display: 'flex',
}));
