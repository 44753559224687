import { Grid, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { OverviewSkeletonStaticBlock } from '@/components/StaticBlock/OverviewSkeletonStaticBlock';

export const PersonSettingComponentSkeleton = () => {
  return (
    <StyledGrid
      container
      spacing={{ xs: 2, md: 3 }}
      data-testid="person-setting-component-skeleton"
    >
      <StyledGridItem item md={4} xs={12}>
        <OverviewSkeletonStaticBlock>
          <StackStyles>
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </StackStyles>
        </OverviewSkeletonStaticBlock>

        <OverviewSkeletonStaticBlock>
          <StackStyles>
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </StackStyles>
        </OverviewSkeletonStaticBlock>
      </StyledGridItem>

      <StyledGridItem item md={4} xs={12}>
        <OverviewSkeletonStaticBlock>
          <StackStyles>
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </StackStyles>
        </OverviewSkeletonStaticBlock>
        <OverviewSkeletonStaticBlock>
          <StackStyles>
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </StackStyles>
        </OverviewSkeletonStaticBlock>
      </StyledGridItem>

      <StyledGridItem item md={4} xs={12}>
        <OverviewSkeletonStaticBlock>
          <StackStyles>
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </StackStyles>
        </OverviewSkeletonStaticBlock>
        <OverviewSkeletonStaticBlock>
          <StackStyles>
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </StackStyles>
        </OverviewSkeletonStaticBlock>
      </StyledGridItem>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StackStyles = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
