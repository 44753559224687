import { ApolloProvider } from '@apollo/client';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Loader } from '@ysura/common';
import { find, flatten, map } from 'lodash';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { KEYCLOAK, SYSTEM } from '@/config';
import { useAuth, useDateLocale, useNotification } from '@/hooks';
import { CipStateProvider } from '@/hooks/state';
import { ErrorTypes } from '@/hooks/useNotification';
import { UnknownError } from '@/pages/Error';
import { Login } from '@/pages/Login';
import { Routes } from '@/Routes';
import { errorsObserver, useWebApiClient } from '@/services/graphql';

import { ScrollToTop } from './components/ScrollToTop';

export const App = () => {
  const { dateLocale } = useDateLocale();
  const { webApiClient } = useWebApiClient();
  const queryClient = new QueryClient();

  return (
    <CipStateProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateLocale}
      >
        <ApolloProvider client={webApiClient}>
          <QueryClientProvider client={queryClient}>
            <AuthenticationComponent />
            <ScrollToTop />
          </QueryClientProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </CipStateProvider>
  );
};

const AuthenticationComponent = () => {
  const { isInitialized, isAuthenticated } = useAuth();
  const { popup, closePopup, banner, closeAllBanners, bannerOptionsList } =
    useNotification();
  const { t } = useTranslation();
  const location = useLocation();
  const onErrorRef = useRef({ hasError: false, location });

  useEffect(() => {
    if (
      onErrorRef.current.hasError &&
      onErrorRef.current.location !== location
    ) {
      onErrorRef.current.hasError = false;
      onErrorRef.current.location = location;
      closePopup && closePopup();
      closeAllBanners && closeAllBanners();
    }
  }, [closeAllBanners, closePopup, location]);

  useEffect(() => {
    const handleError = (event: MessageEvent) => {
      const { errorConfig } = event.data;
      const closeExistingPopup = () => closePopup && closePopup();
      const { networkError, graphQLErrors } = event.data.error ?? {};
      onErrorRef.current.hasError = !!networkError || !!graphQLErrors;

      const isInternalError = find(graphQLErrors, (error) => {
        const classification = error?.extensions?.classification;

        return (
          classification === 'INTERNAL_ERROR' ||
          classification === 'NullValueInNonNullableField'
        );
      });

      const backendErrors: string[] = flatten(
        map(graphQLErrors, (error) => {
          return map(
            error.extensions?.error?.objectErrors,
            (objError) => objError.code
          );
        })
      );

      const isMaintenance = find(backendErrors, (err) => err === 'E503');

      if (networkError || isInternalError || isMaintenance) {
        closeExistingPopup();
        popup?.(
          networkError
            ? ErrorTypes.networkError
            : isMaintenance
            ? ErrorTypes.maintenanceError
            : ErrorTypes.internalError
        );
      } else if (backendErrors.length) {
        backendErrors.forEach((message: string) => {
          const errorAlreadyShown = !!find(
            bannerOptionsList,
            (it) => it.message === message
          );
          if (!errorAlreadyShown && message) {
            banner?.({
              options: {
                message: t(`components.error.errorCode.${message}`),
                displayLocation: 'main',
                type: 'error',
                ...errorConfig.bannerOptions,
              },
            });
          }
        });
      } else if (graphQLErrors) {
        closeExistingPopup();

        if (errorConfig.bannerOptions) {
          banner?.({
            options: {
              contentComponent: (
                <Trans
                  i18nKey="components.error.unknownErrorMessage"
                  values={{
                    service: t('components.error.customerService'),
                  }}
                  components={{
                    Link: (
                      <Link href="mailto:service@ysura.com" target="_blank" />
                    ),
                  }}
                />
              ),
              displayLocation: 'main',
              type: 'error',
              ...errorConfig.bannerOptions,
            },
          });

          return;
        }

        popup?.(undefined, {
          type: 'pageOverlay',
          contentComponent: <UnknownError />,
        });
      }
    };

    errorsObserver.subscribe(handleError);

    return () => {
      errorsObserver.unsubscribe(handleError);
    };
  }, [banner, bannerOptionsList, closePopup, popup, t]);

  // Only check for disabled auth on DEV systems, never on PROD
  if (SYSTEM.IS_DEV && KEYCLOAK.DANGEROUSLY_DISABLE_AUTH_LOCALLY) {
    return <Routes />;
  }

  if (!isInitialized) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  } else if (isAuthenticated) {
    return <Routes />;
  } else {
    return <Login />;
  }
};

const LoaderWrapper = styled(Box)({
  display: 'grid',
  height: '100%',
});
