import { gql } from '@apollo/client';

export const getAttributes = gql`
  query getAttributes($usageType: AttributeUsageType!) {
    attributes(
      filter: {
        state: { _eq: ACTIVE }
        usageType: { _eq: $usageType }
        type: { _in: [BOOLEAN, SINGLE_SELECTION, MULTI_SELECTION] }
      }
    ) {
      totalCount
      edges {
        node {
          id
          oid
          name
        }
      }
    }
  }
`;
