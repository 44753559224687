import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type MoreItemsButtonProps = {
  onClick: VoidFunction;
  showMore: boolean;
};
export const MoreItemsButton = ({
  onClick,
  showMore = true,
}: MoreItemsButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button variant="outlined" onClick={onClick}>
      <Typography variant="button" textTransform={'uppercase'}>
        {showMore
          ? t('components.common.showLess')
          : t('components.common.showMore')}
      </Typography>
    </Button>
  );
};
