import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { useCurrentUser } from '@/hooks';
import { State } from '@/services/graphql/types';

import {
  AddMediaTrackingInfoMutation,
  AddMediaTrackingInfoMutationVariables,
  useAddMediaTrackingInfoMutation as useAddMediaTrackingInfoMutationApollo,
} from '../generated';

type AddMediaTrackingInfoVariables = Pick<
  AddMediaTrackingInfoMutationVariables,
  'mediaId' | 'mediaVersionId' | 'audiencePersonId' | 'data'
> &
  Partial<
    Pick<
      AddMediaTrackingInfoMutationVariables,
      'oid' | 'shownTime' | 'presenterPersonId' | 'state'
    >
  >;

/**
 * __useAddMediaTrackingInfoMutationMutation__
 *
 * To run a mutation, you first call `useAddMediaTrackingInfoMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSampleRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaTrackingInfoMutationMutation, { data, loading, error }] = useAddActivityMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      mediaId: // oid of the presented media
 *      mediaVersionId: // oid of the presented media version
 *      shownTime: // time when the media has been shown
 *      presenterPersonId: // oid of the person who presented the media
 *      audiencePersonId: // oid of the person to whom the media is shown
 *      data: // list of tracking data to the presentation
 *
 *      // Optional
 *      state: // value of the state of the activity
 *   },
 * });
 */
export function useAddMediaTrackingInfoMutation(
  baseOptions?: MutationHookOptions<
    AddMediaTrackingInfoMutation,
    AddMediaTrackingInfoMutationVariables
  >
) {
  const { currentUser } = useCurrentUser();

  const [addMediaTrackingInfo, status] = useAddMediaTrackingInfoMutationApollo({
    // We invalidate all queries related to activity to refresh the data
    refetchQueries: ['getActivityTypeList', 'getTouchPointGroupList'],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<
      AddMediaTrackingInfoMutation,
      AddMediaTrackingInfoVariables
    >
  ) => {
    const defaultVariables = {
      oid: uuidv4(),
      presenterPersonId: currentUser?.person?.oid ?? '',
      state: State.ACTIVE,

      // placeholders, have to be overwritten when calling the mutate function
      shownTime: '',
      mediaId: uuidv4(),
      mediaVersionId: '',
      audiencePersonId: undefined,
      data: [],
    };

    return addMediaTrackingInfo({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
}
