import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const TopicsStaticBlockSkeleton = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.activity.topics')}
      testId="topics-skeleton-static-block"
    >
      <TopicLine>
        <Skeleton width={64} height={22} />
        <Skeleton width={64} height={22} />
        <Skeleton width={64} height={22} />
      </TopicLine>
    </OverviewStaticBlock>
  );
};

const TopicLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
