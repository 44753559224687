import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';

import { CUSTOM_BREAKPOINTS, TELEPHONE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';
import {
  CallStatusContentActions,
  CallStatusContentInfoBlock,
  ControlsWrapper,
} from '@/views/Interaction/Telephone';

type CallInfoAndControlsProps = {
  activity: ActivityData;
  isDrawerOpen: boolean;
  isInteraction?: boolean;
  onEndInteraction?: VoidFunction;
  onCallInfoDialog?: VoidFunction;
};

export const CallInfoAndControls = ({
  activity,
  onEndInteraction,
  onCallInfoDialog,
  isDrawerOpen,
  isInteraction = false,
}: CallInfoAndControlsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAnyContentShared, isSharingInFullScreen } = useInteraction();

  // if isAnyContentShared & isMobile are both true, we should always open the drawer
  const shouldOpenDrawer = isDrawerOpen || (isMobile && isAnyContentShared);

  return (
    <ControlsWrapper
      isDrawerOpen={shouldOpenDrawer}
      drawerBleeding={TELEPHONE_INTERACTION.MOBILE.DRAWER_HEIGHT_PORTRAIT}
      flex={1}
    >
      {!isSharingInFullScreen ? (
        <>
          <CallWrapper
            isDrawerOpen={shouldOpenDrawer}
            data-testid="collapsable-call-wrapper"
          >
            <CallStatusContentInfoBlock activity={activity} />
          </CallWrapper>

          <CallInformationWrapper data-testid="call-info-wrapper">
            <CallStatusContentActions
              isMobile={isMobile}
              isInteraction={isInteraction}
              onEndInteraction={() => onEndInteraction?.()}
              onCallInfoDialog={() => onCallInfoDialog?.()}
            />
          </CallInformationWrapper>
        </>
      ) : (
        <CallStatusContentActions
          isFullScreen
          attendees={activity.attendees}
          isMobile={isMobile}
          isInteraction={isInteraction}
          onCallInfoDialog={() => onCallInfoDialog?.()}
        />
      )}
    </ControlsWrapper>
  );
};

type CallWrapperStyleProps = {
  isDrawerOpen: boolean;
};

const CallWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<CallWrapperStyleProps>(({ isDrawerOpen }) => ({
  height: isDrawerOpen ? 0 : '100%',
  visibility: isDrawerOpen ? 'hidden' : 'visible',
  overflow: 'hidden',
}));

const CallInformationWrapper = styled(Box)({
  color: 'white',
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
});
