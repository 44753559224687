import { UserData } from '@ysura/common';

import { PersonClientPermissions } from '@/services/user/permissions/ClientPermissions';
import { hasClientPermission } from '@/services/user/permissions/helper';

export const getPersonPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): PersonClientPermissions => {
  return {
    canReadFacets: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-facets-read'
    ),
    canReadCommunicationData: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-communication-data-read'
    ),
    canReadTouchpointStatistics: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-touchPointPlan-read'
    ),
    canReadSampleDrop: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-sampleDrop-read'
    ),
    canReadTypeFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-type-filter'
    ),
    canReadRoleFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-role-filter'
    ),
    canReadSpecialityFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-speciality-filter'
    ),
    canReadSpecialityGroupFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-speciality-groups-filter'
    ),
    canReadConsentFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-consent-filter'
    ),
    canReadAttributeFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-attributes-filter'
    ),
  };
};
