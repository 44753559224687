import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetMediaTopicsQueryVariables = Types.Exact<{
  oids?: Types.InputMaybe<
    Array<Types.Scalars['MediaId']['input']> | Types.Scalars['MediaId']['input']
  >;
}>;

export type GetMediaTopicsQuery = {
  __typename?: 'Query';
  medias: {
    __typename?: 'MediaConnection';
    edges?: Array<{
      __typename?: 'MediaEdge';
      node?: {
        __typename?: 'Media';
        id: string;
        oid: string;
        name: string;
        topics: {
          __typename?: 'TopicConnection';
          edges?: Array<{
            __typename?: 'TopicEdge';
            node?: { __typename?: 'Topic'; id: string; oid: string } | null;
          }> | null;
        };
        keyMessages: {
          __typename?: 'KeyMessageConnection';
          edges?: Array<{
            __typename?: 'KeyMessageEdge';
            node?: {
              __typename?: 'KeyMessage';
              id: string;
              oid: string;
              topics: {
                __typename?: 'TopicConnection';
                edges?: Array<{
                  __typename?: 'TopicEdge';
                  node?: {
                    __typename?: 'Topic';
                    id: string;
                    oid: string;
                  } | null;
                }> | null;
              };
            } | null;
          }> | null;
        };
      } | null;
    }> | null;
  };
};

/**
 * __useGetMediaTopicsQuery__
 *
 * To run a query within a React component, call `useGetMediaTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaTopicsQuery({
 *   variables: {
 *      oids: // value for 'oids'
 *   },
 * });
 */
export function useGetMediaTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMediaTopicsQuery,
    GetMediaTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetMediaTopicsQuery, GetMediaTopicsQueryVariables>(
    Operations.getMediaTopics,
    options
  );
}
export function useGetMediaTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaTopicsQuery,
    GetMediaTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetMediaTopicsQuery, GetMediaTopicsQueryVariables>(
    Operations.getMediaTopics,
    options
  );
}
export function useGetMediaTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMediaTopicsQuery,
    GetMediaTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetMediaTopicsQuery,
    GetMediaTopicsQueryVariables
  >(Operations.getMediaTopics, options);
}
export type GetMediaTopicsQueryHookResult = ReturnType<
  typeof useGetMediaTopicsQuery
>;
export type GetMediaTopicsLazyQueryHookResult = ReturnType<
  typeof useGetMediaTopicsLazyQuery
>;
export type GetMediaTopicsSuspenseQueryHookResult = ReturnType<
  typeof useGetMediaTopicsSuspenseQuery
>;
export type GetMediaTopicsQueryResult = Apollo.QueryResult<
  GetMediaTopicsQuery,
  GetMediaTopicsQueryVariables
>;
