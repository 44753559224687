import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Scope } from '@/services/graphql/types';

type FilterScopeGroupProps = {
  scope: Scope;
  onChange?: ReactEventVoid;
};

type ReactEventVoid = (event: ChangeEvent<HTMLInputElement>) => void;

//TODO https://youtrack.ysura.com/issue/CIP-1134/Moving-new-common-component-to-common-library
export const FilterScopeGroup = ({
  scope,
  onChange,
}: FilterScopeGroupProps) => {
  const { t } = useTranslation();

  return (
    <ScopeButtonGroup
      aria-labelledby="scope-radio-buttons-group-label"
      name="scope-radio-buttons-group"
      row={true}
      value={scope}
      onChange={onChange}
    >
      <FormControlLabel
        value={Scope.TERRITORY}
        control={<Radio size="small" />}
        label={t('components.globalSearch.filter.region')}
      />
      <FormControlLabel
        value={Scope.ALL}
        control={<Radio size="small" />}
        label={t('components.globalSearch.filter.all')}
      />
    </ScopeButtonGroup>
  );
};

const ScopeButtonGroup = styled(RadioGroup)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));
