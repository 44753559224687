import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MedicationIcon from '@mui/icons-material/Medication';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  IconWrapper,
  Label,
  PromotionalMaterialGroupData,
  PromotionalMaterialTypeDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PromotionalMaterialBatchItem } from './PromotionalMaterialBatchItem';

type PromotionalMaterialCardProps = {
  promotionalMaterial: PromotionalMaterialGroupData;
  isSigned: boolean;
  isDoneTouchpoint: boolean;
  isLastItem?: boolean;
};

export const PromotionalMaterialCard = ({
  promotionalMaterial,
  isSigned,
  isDoneTouchpoint,
  isLastItem,
}: PromotionalMaterialCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const type = promotionalMaterial.promotionalMaterial?.type;
  const signatureRequired =
    promotionalMaterial.promotionalMaterial?.signatureRequired;

  const totalRequestedQuantity = promotionalMaterial.requestedQuantity;

  return (
    <>
      <PromatContentWrapper data-testid="promotional-material-card">
        <TitleLine>
          <IconWrapper
            size={20}
            backgroundColor={
              type === PromotionalMaterialTypeDataEnum.PRODUCT
                ? theme.palette.info.dark
                : theme.palette.error.light
            }
          >
            <IconContainer>
              {type === PromotionalMaterialTypeDataEnum.PRODUCT ? (
                <MedicationIcon />
              ) : (
                <LibraryBooksIcon />
              )}
            </IconContainer>
          </IconWrapper>
          <Typography variant="subtitle2">
            {promotionalMaterial.promotionalMaterial?.name}
          </Typography>
        </TitleLine>

        {signatureRequired &&
          !isDoneTouchpoint &&
          type === PromotionalMaterialTypeDataEnum.PRODUCT && (
            <Box>
              <Label color={isSigned ? 'success' : 'warning'}>
                {isSigned
                  ? t('pages.interaction.common.signatureCollected')
                  : t('pages.interaction.common.signatureRequired')}
              </Label>
            </Box>
          )}
        {type === PromotionalMaterialTypeDataEnum.PRODUCT &&
          promotionalMaterial.batches?.length && (
            <StyledStack>
              {promotionalMaterial.batches
                .filter(
                  (each) =>
                    each.physicalStock.requestedQuantity ||
                    each.virtualStock.requestedQuantity
                )
                .map((batch, index) => (
                  <PromotionalMaterialBatchItem
                    key={batch?.oid ?? index}
                    batch={batch}
                    isDoneTouchpoint={isDoneTouchpoint}
                  />
                ))}
            </StyledStack>
          )}

        <StyledBox>
          <Typography variant="overline">{`${t(
            'pages.interaction.common.total'
          )}: ${totalRequestedQuantity}`}</Typography>
        </StyledBox>
      </PromatContentWrapper>

      {!isLastItem && <Divider />}
    </>
  );
};

const PromatContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const TitleLine = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  svg: {
    color: theme.palette.common.white,
    fontSize: '12px',
  },
}));

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
