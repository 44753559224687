import { Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentInputFieldData,
  ConsentItemData,
  ConsentValue,
  CountryData,
  PersonConsentData,
  PersonData,
} from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ActionDialog } from '@/components/ActionDialog';
import { ConsentCollectionContent } from '@/components/Consent/ConsentCollectionContent';
import { useInteraction } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import {
  useCollectPaperPersonConsentMutation,
  useGetConsentFormIdentifiersQuery,
} from '@/services/graphql/hooks';
import {
  ConsentItemStateChange,
  PersonConsentItemValueInput,
} from '@/services/graphql/types/types';

type PaperConsentCollectionDialogProps = {
  isOpen: boolean;
  consent: PersonConsentData;
  person: PersonData;
  defaultCountry?: CountryData;
  handleClose: VoidFunction;
  handleConfirm: (message: string) => void;
};

export const PaperConsentCollectionDialog = ({
  isOpen,
  consent,
  person,
  defaultCountry,
  handleClose,
  handleConfirm,
}: PaperConsentCollectionDialogProps) => {
  const { t } = useTranslation();

  const { setConsentAsCollected } = useInteraction();

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [consentValues, setConsentValues] = useState<ConsentValue | null>(null);
  const [formIdentifier, setFormIdentifier] = useState<string>();

  const [
    collectPaperPersonConsentMutation,
    { loading: isCollectConsentLoading },
  ] = useCollectPaperPersonConsentMutation();
  const { data: consentFormIdentifierData } = useGetConsentFormIdentifiersQuery(
    {
      variables: {
        consentTypeId: consent.consentType?.id ?? '',
      },
      skip: !consent.consentType?.id,
    }
  );

  const handleSave = () => {
    const consentItemsValues: Array<PersonConsentItemValueInput> =
      consent.consentType?.consentItems?.map((it: ConsentItemData) => {
        const collectedCommunicationData =
          it.consentInputFields?.map((input: ConsentInputFieldData) => {
            return {
              oid: input.consentCommunicationDataTypeId,
              value: consentValues?.[input.inputId ?? ''] as string,
            };
          }) ?? [];

        return {
          collectedCommunicationData: collectedCommunicationData,
          itemState: consentValues?.[it.inputId ?? '']
            ? ConsentItemStateChange.TURN_ON
            : ConsentItemStateChange.TURN_OFF,
          oid: it.oid,
        };
      }) ?? [];
    handleSubmitForm(consentItemsValues);
  };

  /*************************************************************************
   * When the user types in the form identifier input, we try to match it  *
   * with the list of possible identifiers.                                *
   * If there is only one match, we set the input value to that.           *
   *                                                                       *
   * If the user deletes a character and we have autocomleted before,      *
   * we clear the input instead of deleting the last character.            *
   * ***********************************************************************/
  const handlePaperFormIdentifier = (event: ChangeEvent<HTMLInputElement>) => {
    let isDeleteEvent = false;
    if (event.nativeEvent instanceof InputEvent) {
      isDeleteEvent = event.nativeEvent.inputType.startsWith('delete');
    }

    const possibleFormIdentifiers =
      consentFormIdentifierData?.consentFormIdentifiers ?? [];

    let currentFormIdentifier = event.target.value;
    if (possibleFormIdentifiers.length > 0) {
      const matches = possibleFormIdentifiers.filter((possibleIdentifier) =>
        possibleIdentifier.startsWith(currentFormIdentifier)
      );

      if (matches.length === 1) {
        if (isDeleteEvent) {
          currentFormIdentifier = '';
        } else {
          currentFormIdentifier = matches[0];
        }
      }
    }
    setFormIdentifier(currentFormIdentifier);
  };

  const handleSubmitForm = (
    consentItemsValues: Array<PersonConsentItemValueInput>
  ) => {
    if (!person?.oid || !consent?.consentType?.oid) {
      return;
    }

    collectPaperPersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent.oid ?? uuidv4(),
        consentTypeId: consent.consentType?.oid,
        signerPersonId: person?.oid,
        consentItemsValues: consentItemsValues,
        formIdentifier: formIdentifier ?? '',
      },
      onCompleted: (data) => {
        if (data?.collectPaperPersonConsent?.oid) {
          setConsentAsCollected?.(data.collectPaperPersonConsent.oid);
        }
        handleConfirm(t('pages.interaction.toasts.consentFormSubmitted'));
      },
    });
  };

  if (isOpen && !person) {
    console.log('No attendee in activity');

    return null;
  }

  return (
    <ActionDialog
      isOpen={isOpen}
      maxWidth={'lg'}
      title={consent?.consentType?.name ?? ''}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      isPrimaryButtonDisabled={!isFormValid || !formIdentifier}
      isLoading={isCollectConsentLoading}
      testId={'paper-consent-collection-dialog'}
      onClickPrimaryButton={handleSave}
      onClickSecondaryButton={handleClose}
    >
      <ConsentCollectionContent
        consentType={consent.consentType}
        person={person}
        defaultCountry={defaultCountry}
        additionalInputComponent={
          <Container>
            <Typography variant="caption">
              {t('components.consentDialog.collectPaper.title')}
            </Typography>
            <TextField
              fullWidth
              label={t('components.consentDialog.collectPaper.inputHint')}
              value={formIdentifier ?? ''}
              inputProps={{ maxLength: 6 }}
              onChange={handlePaperFormIdentifier}
            ></TextField>
            <HintTypography variant="caption">
              {t('components.consentDialog.collectPaper.hint')}
            </HintTypography>
          </Container>
        }
        setIsFormValid={setIsFormValid}
        setConsentValues={setConsentValues}
      />
    </ActionDialog>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  width: '50%',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HintTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
