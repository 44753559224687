import { PersonConsentData } from '@ysura/common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import {
  errorConfigBannerDialog,
  useInteraction,
  useNotification,
} from '@/hooks';
import { useRevokePersonConsentMutation } from '@/services/graphql/hooks';

type RevokeConsentComponentProps = {
  isInRemoteRoom?: boolean;
  consent: PersonConsentData;
  onCloseDialog: VoidFunction;
  onConfirmAction: VoidFunction;
};

export const RevokeConsentDialog = ({
  isInRemoteRoom = false,
  consent,
  onCloseDialog,
  onConfirmAction,
}: RevokeConsentComponentProps) => {
  const { t } = useTranslation();
  const { toast } = useNotification();

  const {
    revokeSharedConsent,
    onRevokeConsentAttendeeConfirmed,
    onConsentClosed,
    broadcastConsentClose,
  } = useInteraction();

  // Revoke button should be disabled while attendee has not confirmed the action
  const [isRevokeButtonEnable, setIsRevokeButtonEnable] =
    useState<boolean>(true);

  // Organizer has already clicked to revoke the consent, so to cancel it is mandatory to notify the attendee to close consent
  const [isRevokeButtonClicked, setIsRevokeButtonClicked] =
    useState<boolean>(false);

  const [revokePersonConsentMutation, { loading }] =
    useRevokePersonConsentMutation();

  const handleRevokeConsentMutation = useCallback(() => {
    revokePersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent?.oid ?? uuidv4(),
      },
      onCompleted: () => {
        onConfirmAction();
      },
      onError: () => {
        onCloseDialog();
      },
    });
  }, [
    consent?.oid,
    onCloseDialog,
    onConfirmAction,
    revokePersonConsentMutation,
  ]);

  const handleClickOnRevokeConsent = () => {
    if (isInRemoteRoom && consent.consentType) {
      setIsRevokeButtonEnable(false);
      setIsRevokeButtonClicked(true);
      revokeSharedConsent({
        id: consent.oid ?? '',
        consentType: {
          id: consent.consentType.oid ?? '',
          name: consent.consentType.name ?? '',
        },
      });
    } else {
      // Not remote interaction
      handleRevokeConsentMutation();
    }
  };

  const handleCancelRevokeConsent = () => {
    // If revoke has been clicked, attendee should be notified ¿?
    if (
      isInRemoteRoom &&
      isRevokeButtonClicked &&
      consent &&
      consent.consentType
    ) {
      broadcastConsentClose?.({
        id: consent.oid ?? '',
      });
    }
    onCloseDialog();
  };

  useEffect(() => {
    const handleAttendeeRevokeConsentConfirmation = () => {
      handleRevokeConsentMutation();
    };

    const handleCloseConsent = () => {
      onCloseDialog?.();
    };

    const unsubscribeOnRevokeConsentAttendeeConfirmed =
      onRevokeConsentAttendeeConfirmed?.(
        handleAttendeeRevokeConsentConfirmation
      );

    const unsubscribeOnConsentClosed = onConsentClosed?.(handleCloseConsent);

    return () => {
      if (unsubscribeOnRevokeConsentAttendeeConfirmed) {
        unsubscribeOnRevokeConsentAttendeeConfirmed();
      }

      if (unsubscribeOnConsentClosed) {
        unsubscribeOnConsentClosed();
      }
    };
  }, [
    handleRevokeConsentMutation,
    onRevokeConsentAttendeeConfirmed,
    onConsentClosed,
    toast,
    t,
    onCloseDialog,
  ]);

  return (
    <ConfirmationDialog
      testId="revoke-consent"
      isOpen={true}
      title={t('components.consentDialog.revokeConsent.title')}
      content={
        isInRemoteRoom
          ? t('components.consentDialog.revokeConsent.textForRemote')
          : t('components.consentDialog.revokeConsent.textForNonRemote')
      }
      primaryButton={{
        text: t('components.consentDialog.action.revoke'),
        color: 'error',
        onClickHandler: handleClickOnRevokeConsent,
        isButtonDisabled: !isRevokeButtonEnable,
        isButtonLoading: loading,
      }}
      secondaryButton={{
        text: t('components.common.cancel'),
        onClickHandler: handleCancelRevokeConsent,
      }}
    />
  );
};
