import {
  DataPermissionData,
  DataPermissionTypeDataEnum,
  PersonAssignmentTypeData,
} from '@ysura/common';

import {
  convertedPersonAssignmentBaseType,
  convertedState,
} from '@/services/graphql/parsers/utils';
import { DataPermission, PersonAssignmentType } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parsePersonAssignmentTypeData = (
  personAssignmentType?: DeepPartial<PersonAssignmentType>
): PersonAssignmentTypeData => {
  return {
    id: personAssignmentType?.id,
    oid: personAssignmentType?.oid,
    name: personAssignmentType?.name ?? undefined,
    customerReference: personAssignmentType?.customerReference ?? undefined,
    baseType: convertedPersonAssignmentBaseType(personAssignmentType?.baseType),
    state: convertedState(personAssignmentType?.state),
    dataPermissions: parseDataPermissions(
      personAssignmentType?.dataPermissions ?? []
    ),
  };
};

export const parseDataPermissions = (
  dataPermissions: DeepPartial<DataPermission[]>
): Array<DataPermissionData> => {
  const permissions = dataPermissions.map((each) => {
    return parseDataPermission(each);
  });

  return filterNonNull(permissions);
};

export const parseDataPermission = (
  dataPermission?: DeepPartial<DataPermission>
): DataPermissionData | undefined => {
  return dataPermission
    ? {
        type: dataPermission.type as unknown as DataPermissionTypeDataEnum,
        scoped: dataPermission.scoped,
      }
    : undefined;
};
