import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetTouchpointsByOrgIdQueryVariables = Types.Exact<{
  organizationId?: Types.InputMaybe<Types.Scalars['OrganizationId']['input']>;
}>;

export type GetTouchpointsByOrgIdQuery = {
  __typename?: 'Query';
  touchpoints: {
    __typename?: 'TouchpointConnection';
    totalCount: number;
    filteredCount: number;
    edges?: Array<{
      __typename?: 'TouchpointEdge';
      node?: {
        __typename?: 'Touchpoint';
        id: string;
        oid: string;
        startDate?: string | null;
        startDateTime?: string | null;
        endDate?: string | null;
        endDateTime?: string | null;
        state: Types.State;
        status: Types.ActivityStatus;
        activityType: {
          __typename?: 'ActivityType';
          id: string;
          oid: string;
          name: string;
          baseType: Types.ActivityTypeBaseType;
        };
        organizer: {
          __typename?: 'Person';
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          prefixAcademicTitle?: {
            __typename?: 'AcademicTitle';
            name?: string | null;
            state: Types.State;
          } | null;
          postfixAcademicTitle?: {
            __typename?: 'AcademicTitle';
            name?: string | null;
            state: Types.State;
          } | null;
        };
        organization: {
          __typename?: 'Organization';
          id: string;
          name?: string | null;
          organizationTypes: {
            __typename?: 'OrganizationTypeConnection';
            edges?: Array<{
              __typename?: 'OrganizationTypeEdge';
              node?: {
                __typename?: 'OrganizationType';
                name?: string | null;
              } | null;
            }> | null;
          };
          rootOrganization?: {
            __typename?: 'Organization';
            id: string;
            name?: string | null;
          } | null;
        };
        attendees: Array<{
          __typename?: 'TouchpointAttendee';
          guestName?: string | null;
          person?: {
            __typename?: 'Person';
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            employments: {
              __typename?: 'EmploymentConnection';
              edges?: Array<{
                __typename?: 'EmploymentEdge';
                node?: {
                  __typename?: 'Employment';
                  organization: {
                    __typename?: 'Organization';
                    name?: string | null;
                    addresses: {
                      __typename?: 'EntityAddressConnection';
                      edges?: Array<{
                        __typename?: 'EntityAddressEdge';
                        node?: {
                          __typename?: 'EntityAddress';
                          address: {
                            __typename?: 'Address';
                            id: string;
                            oid: string;
                            addressLine1?: string | null;
                            addressLine2?: string | null;
                            addressLine3?: string | null;
                            city: string;
                            postalCode: string;
                          };
                        } | null;
                      }> | null;
                    };
                  };
                } | null;
              }> | null;
            };
          } | null;
        }>;
      } | null;
    }> | null;
  };
};

/**
 * __useGetTouchpointsByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetTouchpointsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTouchpointsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTouchpointsByOrgIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetTouchpointsByOrgIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTouchpointsByOrgIdQuery,
    GetTouchpointsByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetTouchpointsByOrgIdQuery,
    GetTouchpointsByOrgIdQueryVariables
  >(Operations.getTouchpointsByOrgId, options);
}
export function useGetTouchpointsByOrgIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTouchpointsByOrgIdQuery,
    GetTouchpointsByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetTouchpointsByOrgIdQuery,
    GetTouchpointsByOrgIdQueryVariables
  >(Operations.getTouchpointsByOrgId, options);
}
export function useGetTouchpointsByOrgIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTouchpointsByOrgIdQuery,
    GetTouchpointsByOrgIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetTouchpointsByOrgIdQuery,
    GetTouchpointsByOrgIdQueryVariables
  >(Operations.getTouchpointsByOrgId, options);
}
export type GetTouchpointsByOrgIdQueryHookResult = ReturnType<
  typeof useGetTouchpointsByOrgIdQuery
>;
export type GetTouchpointsByOrgIdLazyQueryHookResult = ReturnType<
  typeof useGetTouchpointsByOrgIdLazyQuery
>;
export type GetTouchpointsByOrgIdSuspenseQueryHookResult = ReturnType<
  typeof useGetTouchpointsByOrgIdSuspenseQuery
>;
export type GetTouchpointsByOrgIdQueryResult = Apollo.QueryResult<
  GetTouchpointsByOrgIdQuery,
  GetTouchpointsByOrgIdQueryVariables
>;
