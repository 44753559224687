import { OrganizationData } from '@ysura/common';

export interface OrganizationHierarchyData {
  node: OrganizationData;
  children: Array<OrganizationHierarchyData>;
}
export const getHierarchyTree = (
  organizations: Array<OrganizationData>
): OrganizationHierarchyData | undefined => {
  const root = organizations.find((org) => !org.parentOrganization);
  if (!root) {
    return undefined;
  }

  return getHierarchyTreeByNode(root, organizations);
};

export const getHierarchyTreeByNode = (
  node: OrganizationData,
  organizations: Array<OrganizationData>
): OrganizationHierarchyData => {
  const childOrgs = getChildrenOrganizations(node, organizations);
  let children: Array<OrganizationHierarchyData> = [];
  if (childOrgs.length) {
    children = childOrgs.map((org: OrganizationData) => {
      return getHierarchyTreeByNode(org, organizations);
    });
  }

  return {
    node,
    children,
  };
};

export const getChildrenOrganizations = (
  parent: OrganizationData,
  organizations: Array<OrganizationData>
): Array<OrganizationData> => {
  return organizations.filter(
    (org: OrganizationData) =>
      org.parentOrganization && org.parentOrganization.id === parent.id
  );
};

export const getExpandNodeIds = (
  node: OrganizationHierarchyData,
  search: string
): (string | undefined)[] => {
  let ids: (string | undefined)[] = [];
  if (
    node.node.name?.toLowerCase().includes(search.toLowerCase()) &&
    node.children.length
  ) {
    ids = [node.node.id];
  }

  node.children.forEach((child) => {
    const childIds = getExpandNodeIds(child, search);
    ids = [...ids, ...childIds];
  });

  return ids;
};
