import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDateTime } from '@/services/graphql/hooks/mutations/helpers/helper';

import {
  CollectPaperPersonConsentMutation,
  CollectPaperPersonConsentMutationVariables,
  useCollectPaperPersonConsentMutation as useCollectPaperPersonConsentMutationApollo,
} from '../generated';

type CollectPaperPersonConsentVariables =
  // oid, consentTypeId, signerPersonId, consentItemsValues, formIdentifier are required
  Pick<
    CollectPaperPersonConsentMutationVariables,
    | 'oid'
    | 'consentTypeId'
    | 'signerPersonId'
    | 'consentItemsValues'
    | 'formIdentifier'
  > &
    // issuerPersonId, actionAt are optional
    Partial<
      Pick<
        CollectPaperPersonConsentMutationVariables,
        'issuerPersonId' | 'actionAt'
      >
    >;

/**
 * __useCollectPaperPersonConsentMutation__
 *
 * To run a mutation, you first call `useCollectPaperPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectPaperPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectPaperPersonConsent, { data, loading, error }] = useCollectPaperPersonConsentMutation();
 *
 * collectPaperPersonConsent({
 *   variables: {
 *      oid: // defaults to a new uuid
 *      consentTypeId: // Oid of the consent type
 *      signerPersonId: // Oid of the person signing the consent
 *      consentItemsValues: [], consent item values
 *      formIdentifier: // form identifier for the consent
 *
 *      // Optional
 *      issuerPersonId: // defaults to the current user
 *      actionAt: // defaults to the current timestamp
 *   },
 * });
 */
export const useCollectPaperPersonConsentMutation = (
  baseOptions?: MutationHookOptions<
    CollectPaperPersonConsentMutation,
    CollectPaperPersonConsentMutationVariables
  >
) => {
  const { currentUser } = useCurrentUser();

  const [collectPaperPersonConsent, status] =
    useCollectPaperPersonConsentMutationApollo({
      // We invalidate all queries related to consent to refresh the data
      refetchQueries: ['getPersonConsentList'],

      ...baseOptions,
    });

  const doMutate = async (
    options: MutationFunctionOptions<
      CollectPaperPersonConsentMutation,
      CollectPaperPersonConsentVariables
    >
  ) => {
    const defaultVariables = {
      issuerPersonId: currentUser?.person?.oid ?? '',
      actionAt: buildCurrentDateTime(),

      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      consentTypeId: '',
      signerPersonId: '',
      consentItemsValues: [],
      formIdentifier: '',
    };

    return collectPaperPersonConsent({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
