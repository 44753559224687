import { ActivityData, MediaData, NoItemPlaceholder } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIconButton } from '@/components/Button';
import { MediaCardList, MediaPresentationDialog } from '@/components/Media';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useCurrentUser } from '@/hooks';
import { EditActivityDialog } from '@/views/Activity/Update';

type MediaStaticBlockProps = {
  activity: ActivityData;
  canEditActivity?: boolean;
};

export const MediaStaticBlock = ({
  activity,
  canEditActivity,
}: MediaStaticBlockProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const [isMediaPresentationDialogOpen, setIsMediaPresentationDialogOpen] =
    useState(false);
  const [isEditMediaDialogOpen, setIsEditMediaDialogOpen] = useState(false);
  const [mediaWithDocument, setMediaWithDocument] = useState<MediaData | null>(
    null
  );

  const handleOpenEditMediaDialog = () => {
    setIsEditMediaDialogOpen(true);
  };

  const handleCloseEditMediaDialog = () => {
    setIsEditMediaDialogOpen(false);
  };

  const handleChangePresentationDialogState = (val: boolean) => {
    setIsMediaPresentationDialogOpen(val);
  };

  const handleChangeMediaWithDocument = (media: MediaData) => {
    setMediaWithDocument(media);
  };

  const mediaList = activity?.media;

  return (
    <>
      <OverviewStaticBlock
        title={t('components.mediaCard.media')}
        subtitle={
          canEditActivity &&
          currentUser?.permissions?.activity?.canEditActivityMedia && (
            <EditIconButton
              testId="media-edit-button"
              onClick={handleOpenEditMediaDialog}
            />
          )
        }
      >
        {!mediaList?.length ? (
          <NoItemPlaceholder>
            {t('components.mediaCard.noMedia')}
          </NoItemPlaceholder>
        ) : (
          <MediaCardList
            activity={activity}
            onChangeMediaWithDocument={handleChangeMediaWithDocument}
            onChangePresentationDialogState={
              handleChangePresentationDialogState
            }
          />
        )}
      </OverviewStaticBlock>

      {isMediaPresentationDialogOpen && (
        <MediaPresentationDialog
          isOpen={isMediaPresentationDialogOpen}
          media={mediaWithDocument}
          onClose={() => handleChangePresentationDialogState(false)}
        />
      )}

      <EditActivityDialog
        type="media"
        activity={activity}
        isOpen={isEditMediaDialogOpen}
        onClose={handleCloseEditMediaDialog}
      />
    </>
  );
};
