import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type UpdateActivityMutationVariables = Types.Exact<{
  oid: Types.Scalars['ActivityId']['input'];
  actions: Array<Types.UpdateActivityAction> | Types.UpdateActivityAction;
}>;

export type UpdateActivityMutation = {
  __typename?: 'Mutation';
  updateActivity?: { __typename?: 'Activity'; id: string; oid: string } | null;
};

export type UpdateActivityMutationFn = Apollo.MutationFunction<
  UpdateActivityMutation,
  UpdateActivityMutationVariables
>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useUpdateActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActivityMutation,
    UpdateActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateActivityMutation,
    UpdateActivityMutationVariables
  >(Operations.updateActivity, options);
}
export type UpdateActivityMutationHookResult = ReturnType<
  typeof useUpdateActivityMutation
>;
export type UpdateActivityMutationResult =
  Apollo.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateActivityMutation,
  UpdateActivityMutationVariables
>;
