import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useNotification } from '@/hooks';
import { BannerOptions } from '@/hooks/useNotification';

import { NotificationBanner } from './NotificationBanner';

type NotificationBannerListProps = Pick<BannerOptions, 'displayLocation'>;

export const NotificationBannerList = ({
  displayLocation,
}: NotificationBannerListProps) => {
  const { bannerOptionsList, closeBanner } = useNotification();

  if (bannerOptionsList.length === 0) {
    return null;
  }

  const handleCloseBanner = (id: string | undefined) => {
    if (id) {
      closeBanner?.(id);
    }
  };

  return (
    <Wrapper data-testid="notification-banner-list">
      {bannerOptionsList.map((banner) => {
        if (banner.displayLocation === displayLocation) {
          return (
            <NotificationBanner
              key={banner.id}
              {...banner}
              onClose={() => {
                handleCloseBanner(banner.id);
              }}
            />
          );
        }
      })}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
