import { Label, TopicData } from '@ysura/common';

import { LabelsContainer } from '@/components/LabelsContainer';

type TopicsListProps = {
  topics: Array<TopicData>;
};

export const TopicsList = ({ topics }: TopicsListProps) => {
  return (
    <LabelsContainer>
      {topics.map((topic, idx) => {
        const topicName = topic?.name;

        return (
          topicName && (
            <Label key={`topic-${idx}`} variant="filled">
              {topicName}
            </Label>
          )
        );
      })}
    </LabelsContainer>
  );
};
