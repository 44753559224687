import { AccessAlarm } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';

import { useFormatActivityDate } from './hooks';
import { RowWrapper } from './RowWrapper';

type ActivityDateTimeProps = {
  activity: ActivityData;
};

export const ActivityDate = ({ activity }: ActivityDateTimeProps) => {
  const value = useFormatActivityDate(
    activity.startDate,
    activity.startDateTime,
    activity.endDate,
    activity.endDateTime,
    activity.status,
    activity.activityType?.schedulingType,
    activity.activityType?.hideTimeForClosedActivity
  );

  return value ? (
    <RowWrapper>
      <AlarmIcon />
      <Typography variant="subtitle2">{value}</Typography>
    </RowWrapper>
  ) : null;
};

const AlarmIcon = styled(AccessAlarm)({
  marginTop: 2,
});
