import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ColumnContentWrapper,
  RequiredField,
  SectionLabel,
  SectionWrapper,
} from '../SharedStyledComponents';

export const TouchpointTypeColumnSkeleton = () => {
  const { t } = useTranslation();

  return (
    <ColumnContentWrapper data-testid="touchpoint-type-skeleton">
      <SectionWrapper>
        <SectionLabel variant="subtitle2">
          {t('components.touchpointManagement.touchpointType')}
          <RequiredField>*</RequiredField>
        </SectionLabel>
        <Skeleton height={56} />
      </SectionWrapper>

      <SectionWrapper>
        <Skeleton height={22} width={70} />
        <Skeleton height={56} />
      </SectionWrapper>

      <SectionWrapper>
        <Skeleton height={22} width={70} />
        <Skeleton height={40} width={100} />
        <Skeleton height={40} width={120} />
        <Skeleton height={40} width={80} />
      </SectionWrapper>
    </ColumnContentWrapper>
  );
};
