import { gql } from '@apollo/client';

export const getPostalCodeCities = gql`
  query getPostalCodeCities {
    postalCodeCities(filter: { state: { _eq: ACTIVE } }) {
      totalCount
      edges {
        node {
          id
          oid
          postalCode
          city
        }
      }
    }
  }
`;
