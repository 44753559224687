import { Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const KeyMessagesSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.activity.keyMessages')}
      testId="key-messages-skeleton-static-block"
    >
      <SmallStack>
        <Skeleton width={200} height={18} />
        <TinyStack>
          <Skeleton width={166} height={22} />
          <Skeleton width={166} height={22} />
          <Skeleton width={166} height={22} />
        </TinyStack>
      </SmallStack>
    </OverviewStaticBlock>
  );
};

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const TinyStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));
