import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { alpha, Box, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type DocumentDownloadLinkProps = {
  contentLink?: string;
  fileName: string;
  displayText?: string;
  variant?: 'link' | 'secondaryButton' | 'iconButton';
};

export const DocumentDownloadLink = ({
  contentLink,
  fileName,
  displayText,
  variant = 'link',
}: DocumentDownloadLinkProps) => {
  const isDisplayAsButton = variant !== 'link';

  if (contentLink) {
    return (
      <Wrapper
        data-testid="document-download"
        isDisplayAsButton={isDisplayAsButton}
        isIconButton={variant === 'iconButton'}
      >
        <a href={contentLink} download={fileName}>
          <DownloadRoundedIcon />
          {variant !== 'iconButton' && (
            <Typography variant="button">{displayText ?? fileName}</Typography>
          )}
        </a>
      </Wrapper>
    );
  } else {
    return <Skeleton width={240} height={24} />;
  }
};

type WrapperProps = {
  isDisplayAsButton: boolean;
  isIconButton: boolean;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isDisplayAsButton' && prop !== 'isIconButton',
})<WrapperProps>(({ theme, isDisplayAsButton, isIconButton }) => ({
  display: 'grid',
  placeContent: 'center',

  a: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
    alignItems: 'center',
    textDecoration: isDisplayAsButton ? 'none' : 'underline',
    border: isDisplayAsButton
      ? `1px solid ${alpha(theme.palette.primary.main, 0.5)}`
      : 'none',
    borderRadius: theme.shape.borderRadius,
    padding: isIconButton ? theme.spacing(1) : '5px 15px',

    fontWeight: 700,
    minWidth: isIconButton ? 'unset' : '64px',
    width: isIconButton ? '36px' : 'auto',
    height: isIconButton ? '36px' : '100%',

    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  span: {
    textTransform: isDisplayAsButton ? 'uppercase' : 'none',
  },
}));
