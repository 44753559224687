import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetDocumentByIdQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
}>;

export type GetDocumentByIdQuery = {
  __typename?: 'Query';
  document?: {
    __typename?: 'Document';
    id: string;
    oid: string;
    name?: string | null;
    contentLink: string;
    mediaType: string;
  } | null;
};

/**
 * __useGetDocumentByIdQuery__
 *
 * To run a query within a React component, call `useGetDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentByIdQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables
  > &
    (
      | { variables: GetDocumentByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetDocumentByIdQuery, GetDocumentByIdQueryVariables>(
    Operations.getDocumentById,
    options
  );
}
export function useGetDocumentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables
  >(Operations.getDocumentById, options);
}
export function useGetDocumentByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetDocumentByIdQuery,
    GetDocumentByIdQueryVariables
  >(Operations.getDocumentById, options);
}
export type GetDocumentByIdQueryHookResult = ReturnType<
  typeof useGetDocumentByIdQuery
>;
export type GetDocumentByIdLazyQueryHookResult = ReturnType<
  typeof useGetDocumentByIdLazyQuery
>;
export type GetDocumentByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetDocumentByIdSuspenseQuery
>;
export type GetDocumentByIdQueryResult = Apollo.QueryResult<
  GetDocumentByIdQuery,
  GetDocumentByIdQueryVariables
>;
