import { gql } from '@apollo/client';

export const revokePersonConsent = gql`
  mutation revokePersonConsent(
    $oid: PersonConsentId!
    $issuerPersonId: PersonId!
    $actionAt: DateTime!
  ) {
    revokePersonConsent(
      input: { oid: $oid, issuerPersonId: $issuerPersonId, actionAt: $actionAt }
    ) {
      id
      oid
    }
  }
`;
