import { API, INTERACT, KEYCLOAK, SYSTEM, VIDEO } from '@/config';

const DEV_REALM = SYSTEM.REALM || 'isarlife-de';
const KEYCLOAK_DEVELOP_PREFIX = 'develop-';
const KEYCLOAK_RELEASE_PREFIX = 'release-';
const DEVELOP_SUBDOMAIN = 'dev';
const RELEASE_SUBDOMAIN = 'rel';
const LOCAL_HCP_URL = 'http://localhost:3071';

export const isLocalHost = (): boolean => {
  const { hostname, href } = window.location;

  const host = hostname.split('.')?.[0] || hostname;
  const hasLocalHost = !!['localhost', '10', '127', '172', '192'].find(
    (it) => it === host
  );

  const ngrokMatcher = new RegExp(/^https:\/\/ngrok\.dev\.ysura\.com\//);
  const hasNgrokHostname = ngrokMatcher.test(href);

  return hasLocalHost || (SYSTEM.IS_DEV && hasNgrokHostname);
};

/**
 * In case of local development retrieve the realm from the environment
 *
 * In production setup, we retrieve it from the current location
 * (e.g. https://isarlife-de-cip.dev.ysura.com/ => "isarlife-de")
 */
export const getRealm = (): string => {
  const { hostname } = window.location;

  const host = hostname.split('.')?.[0] || hostname;
  const realm = host.split('-cip')?.[0] || host;

  return isLocalHost() ? DEV_REALM : realm;
};

/* Check if the current system is a DEV system
 * (e.g. https://isarlife-de-cip.dev.ysura.com/ => true)
 * (e.g. https://isarlife-de-cip.ysura.com/ => false)
 * (e.g. https://isarlife-de-cip.rel.ysura.com/ => false)
 */
const isDevelopSystem = (): boolean => {
  if (isLocalHost()) return true;

  const { hostname } = window.location;

  const domain = hostname.split('-cip')?.[1];

  return domain?.startsWith(`.${DEVELOP_SUBDOMAIN}.`);
};

/* Check if the current system is a release system
 * (e.g. https://isarlife-cip.rel.ysura.com/ => true)
 * (e.g. https://isarlife-cip.ysura.com/ => false)
 * (e.g. https://isarlife-cip.dev.ysura.com/ => false)
 */
const isReleaseSystem = (): boolean => {
  const { hostname } = window.location;

  const domain = hostname.split('-cip')?.[1];

  return domain?.startsWith(`.${RELEASE_SUBDOMAIN}.`);
};

/**
 * The Keycloak realm usually matches the CRM realm but some cases are different.
 */
export const getKeycloakRealm = (): string => {
  const realm = getRealm();

  // Release realms in Keycloak have a different naming convention
  // (e.g. isarlife-de.rel.ysura.com => release-isarlife-de)
  if (isDevelopSystem()) {
    return `${KEYCLOAK_DEVELOP_PREFIX}${realm}`;
  }
  if (isReleaseSystem()) {
    return `${KEYCLOAK_RELEASE_PREFIX}${realm}`;
  }

  return realm;
};

/**
 * Gets the Keycloak URL depending on the realm and the environment.
 */
export const getKeycloakUrl = (): string => {
  return KEYCLOAK.URL;
};

export const getCrmUiUrl = (): string => {
  if (API?.CRM_UI_URL?.length > 0) {
    return `${API.CRM_UI_URL}/#!`;
  }

  const realm = getRealm();

  return `https://${realm}.${API.HOST}/#!`;
};

export const getApiUrl = () => {
  if (API?.URL?.length > 0) {
    return API.URL;
  }

  const realm = getRealm();

  return `https://${realm}-api.${API.HOST}/v1/graphql`;
};

/**
 * Gets the URL of the Interact HCP depending on the realm and the environment.
 * Example: https://isarlife-de-cip.dev.ysura.com => https://isarlife-de-hcp.dev.ysura.com
 */
export const getHcpUrl = (): string => {
  const realm = getRealm();

  return isLocalHost()
    ? LOCAL_HCP_URL
    : `https://${realm}-hcp.${INTERACT.HCP_HOST}`;
};

// Gets the URL of the state-server from the environment variables
export const getStateServerUrl = (): string => {
  return VIDEO.STATE_SERVER_URL;
};
