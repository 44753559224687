import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  PromotionalMaterialGroupBatchData,
  PromotionalMaterialGroupData,
} from '@ysura/common';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '@/hooks';

type SelectQuantityProps = {
  isDisabled?: boolean;
  materialOid: string;
  batch: PromotionalMaterialGroupBatchData;
  type: 'virtual' | 'physical';
  availableQuantity?: number;
  totalQuantity: number;
  selectedGroupedPromotionalMaterials: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const SelectQuantity = ({
  isDisabled = false,
  materialOid,
  batch,
  type,
  availableQuantity = 500,
  totalQuantity,
  selectedGroupedPromotionalMaterials,
  onChangePromats,
}: SelectQuantityProps) => {
  const { t } = useTranslation();
  const { toast } = useNotification();

  const isVirtual = type === 'virtual';
  const label = isVirtual
    ? t('pages.interaction.common.virtual')
    : t('pages.interaction.common.physical');

  const stock = isVirtual ? batch.virtualStock : batch.physicalStock;
  const requestedQuantity = stock.requestedQuantity;
  const stockQuantity = stock.stock?.quantity ?? 0;

  const allowedStockQuantity = Math.min(availableQuantity, stockQuantity);

  // we just do a simple math to see how much can be selected from this stock,
  // never less than 0
  const allowedQuantity =
    allowedStockQuantity === 0 || totalQuantity === 0
      ? allowedStockQuantity
      : Math.min(
          Math.max(availableQuantity - totalQuantity + requestedQuantity, 0),
          allowedStockQuantity
        );

  const isQuantityUnavailable = allowedQuantity === 0;

  const doChangeQuantity = useCallback(
    (quantity: number) => {
      selectedGroupedPromotionalMaterials.forEach((promotionalMaterial) => {
        if (promotionalMaterial.promotionalMaterial?.oid === materialOid) {
          if (!promotionalMaterial.batches?.length) {
            promotionalMaterial.requestedQuantity = quantity;
          } else {
            promotionalMaterial.batches.forEach((item) => {
              if (item.oid === batch.oid) {
                if (isVirtual) {
                  item.virtualStock.requestedQuantity = quantity;
                } else {
                  item.physicalStock.requestedQuantity = quantity;
                }
              }
            });
          }
        }
      });

      onChangePromats([...selectedGroupedPromotionalMaterials]);
    },
    [
      batch.oid,
      isVirtual,
      materialOid,
      onChangePromats,
      selectedGroupedPromotionalMaterials,
    ]
  );

  // To prevent oversampling, if the requested quantity is greater than the allowed quantity,
  // we set the requested quantity to the allowed quantity
  useEffect(() => {
    if (requestedQuantity > allowedQuantity) {
      const promatName =
        selectedGroupedPromotionalMaterials.find(
          (promat) => promat.promotionalMaterial?.oid === materialOid
        )?.promotionalMaterial?.name ?? '';
      toast?.({
        message: t('pages.interaction.toasts.reducedPromatQuantity', {
          promatName,
        }),
        type: 'info',
      });

      doChangeQuantity(allowedQuantity);
    }
  }, [
    allowedQuantity,
    doChangeQuantity,
    isQuantityUnavailable,
    materialOid,
    requestedQuantity,
    selectedGroupedPromotionalMaterials,
    t,
    toast,
  ]);

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    doChangeQuantity(Number(event.target.value));
  };

  return (
    <Select
      select
      label={label}
      value={requestedQuantity}
      disabled={isDisabled || isQuantityUnavailable}
      size="small"
      SelectProps={{
        native: true,
      }}
      onChange={handleQuantityChange}
    >
      {[...Array(allowedQuantity + 1).keys()].map((quantity) => (
        <option key={quantity} value={quantity}>
          {quantity}
        </option>
      ))}
    </Select>
  );
};

const Select = styled(TextField)({
  width: 88,
});
