import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getPersonFullNameWithTitle, Label, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CommunicationDataStaticBlock } from '@/components/CommunicationData';
import { LabelsContainer } from '@/components/LabelsContainer';
import { useCurrentUser } from '@/hooks';
import { getPersonCommunicationData } from '@/services/helper';

interface PersonDataContentProps {
  person: PersonData;
  isShowPersonTitle?: boolean;
}

export const PersonDataContent = ({
  person,
  isShowPersonTitle,
}: PersonDataContentProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const { specialities, specialityGroup, types } = person;
  const communicationData = getPersonCommunicationData(person);

  return (
    <ParentStack>
      {isShowPersonTitle && (
        <ChildStack>
          <DisableTypography variant="caption">
            {t('components.personDataCard.name')}
          </DisableTypography>
          <PersonTitleTypography variant="subtitle1">
            {getPersonFullNameWithTitle(person)}
          </PersonTitleTypography>
        </ChildStack>
      )}

      {types && types.length > 0 && (
        <ChildStack>
          <DisableTypography variant="caption">
            {t('components.personDataCard.type')}
          </DisableTypography>
          <LabelsContainer>
            {types.map((type, idx) => (
              <Label
                key={`type-${idx}`}
                data-testid="person-type-label"
                variant="filled"
              >
                {type}
              </Label>
            ))}
          </LabelsContainer>
        </ChildStack>
      )}

      {specialities && specialities.length > 0 && (
        <ChildStack>
          <DisableTypography variant="caption">
            {t('components.personDataCard.specialities')}
          </DisableTypography>
          <LabelsContainer>
            {specialities.map((speciality, idx) => (
              <Label
                key={`speciality-${idx}`}
                variant="filled"
                data-testid="person-specialty-label"
              >
                {speciality}
              </Label>
            ))}
          </LabelsContainer>
        </ChildStack>
      )}

      {specialityGroup?.name && (
        <ChildStack>
          <DisableTypography variant="caption">
            {t('components.personDataCard.specialityGroup')}
          </DisableTypography>
          <LabelsContainer>
            <Label data-testid="person-specialty-group-label" variant="filled">
              {specialityGroup?.name}
            </Label>
          </LabelsContainer>
        </ChildStack>
      )}

      {communicationData &&
        communicationData.length > 0 &&
        currentUser?.permissions?.person?.canReadCommunicationData && (
          <CommunicationDataStaticBlock
            title={'components.personDataCard.contactInformation'}
            communicationData={communicationData}
          />
        )}
    </ParentStack>
  );
};

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const PersonTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const ParentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const ChildStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
