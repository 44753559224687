import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { FilterResetButton } from '@/components/GlobalSearch/components/FilterResetButton';

export const FilterCardSkeleton = () => {
  const { t } = useTranslation();

  return (
    <>
      <FilterHeader>
        <Text variant="h5">{t('components.globalSearch.filter.filter')}</Text>
        <ButtonsWrapper>
          {/*<FilterSettingButton disableButton></FilterSettingButton>*/}
          <FilterResetButton disableButton></FilterResetButton>
        </ButtonsWrapper>
      </FilterHeader>

      <MediumStack>
        <MediumBox>
          <Skeleton width={80} height={22} />
          <Skeleton width={80} height={22} />
        </MediumBox>

        {[...Array(3)].map((stack, idx) => (
          <SmallStack key={`stack-${idx}`}>
            <Skeleton
              width={80}
              height={22}
              data-testid="filter-card-skeleton"
            />
            {[...Array(4)].map((item, i) => (
              <Skeleton key={`item-${i}`} width={200} height={22} />
            ))}
          </SmallStack>
        ))}
      </MediumStack>
    </>
  );
};

const FilterHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const MediumBox = styled(Box)(({ theme }) => ({
  display: 'flex',

  gap: theme.spacing(2),
}));
