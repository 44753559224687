import { Divider, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder, PersonData } from '@ysura/common';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';
import { errorConfigQueryLoadingError } from '@/hooks/useNotification';
import { useGetPersonPromotionalMaterialsQuery } from '@/services/graphql/hooks';
import { parsePersonData } from '@/services/graphql/parsers';

import { getSortedSamples } from './helper';
import { SampleList } from './SampleList';

type SamplesStaticBlockProps = {
  person: PersonData;
};

export const SamplesStaticBlock = ({ person }: SamplesStaticBlockProps) => {
  const { t } = useTranslation();
  const errorCfg = useRef(
    errorConfigQueryLoadingError(t('components.error.samples'))
  );

  const { loading, data, error, refetch } =
    useGetPersonPromotionalMaterialsQuery({
      ...errorCfg.current.context,
      variables: {
        personId: person?.id ?? '',
      },
    });

  errorCfg.current.bannerOptions.button = {
    label: t('components.error.reload'),
    action: refetch,
  };

  const renderEmptyList = () => {
    return (
      <OverviewStaticBlock
        title={t('pages.personView.samples')}
        testId="samples-static-block"
      >
        <NoItemPlaceholder>{t('pages.personView.noSamples')}</NoItemPlaceholder>
      </OverviewStaticBlock>
    );
  };

  if (loading || error) {
    return (
      <OverviewStaticBlock
        title={t('pages.personView.samples')}
        testId="samples-static-block"
      >
        <Wrapper data-testid="samples-skeleton">
          <SmallStack>
            <Skeleton width={200} height={22} />
            <Skeleton width={64} height={22} />
          </SmallStack>
          <Divider />
          <SmallStack>
            <Skeleton width={200} height={22} />
            <Skeleton width={64} height={22} />
          </SmallStack>
        </Wrapper>
      </OverviewStaticBlock>
    );
  }

  const sortedSamples = getSortedSamples(
    parsePersonData(data?.person)?.promotionalMaterials ?? []
  );

  if (!sortedSamples.length) {
    return renderEmptyList();
  }

  return (
    <OverviewStaticBlock title={t('pages.personView.samples')}>
      <SampleList samples={sortedSamples} />
    </OverviewStaticBlock>
  );
};

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
