import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDateTime } from '@/services/graphql/hooks/mutations/helpers/helper';

import {
  CollectDigitalPersonConsentMutation,
  CollectDigitalPersonConsentMutationVariables,
  useCollectDigitalPersonConsentMutation as useCollectDigitalPersonConsentMutationApollo,
} from '../generated';

type CollectDigitalPersonConsentVariables =
  // oid, consentTypeId, signerPersonId, content are required
  Pick<
    CollectDigitalPersonConsentMutationVariables,
    'oid' | 'consentTypeId' | 'signerPersonId' | 'content'
  > &
    // issuerPersonId, actionAt are optional
    Partial<
      Pick<
        CollectDigitalPersonConsentMutationVariables,
        'issuerPersonId' | 'actionAt'
      >
    >;

/**
 * __useCollectDigitalPersonConsentMutation__
 *
 * To run a mutation, you first call `useCollectDigitalPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectDigitalPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectDigitalPersonConsent, { data, loading, error }] = useCollectDigitalPersonConsentMutation();
 *
 * collectDigitalPersonConsent({
 *   variables: {
 *      oid: // defaults to a new uuid
 *      consentTypeId: // Oid of the consent type
 *      signerPersonId: // Oid of the person signing the consent
 *      content: // HTML content of the consent
 *
 *      // Optional
 *      issuerPersonId: // defaults to the current user
 *      actionAt: // defaults to the current timestamp
 *   },
 * });
 */
export const useCollectDigitalPersonConsentMutation = (
  baseOptions?: MutationHookOptions<
    CollectDigitalPersonConsentMutation,
    CollectDigitalPersonConsentMutationVariables
  >
) => {
  const { currentUser } = useCurrentUser();

  const [collectDigitalPersonConsent, status] =
    useCollectDigitalPersonConsentMutationApollo({
      // We invalidate all queries related to consent to refresh the data
      refetchQueries: ['getPersonConsentList'],

      ...baseOptions,
    });

  const doMutate = async (
    options: MutationFunctionOptions<
      CollectDigitalPersonConsentMutation,
      CollectDigitalPersonConsentVariables
    >
  ) => {
    const defaultVariables = {
      issuerPersonId: currentUser?.person?.oid ?? '',
      actionAt: buildCurrentDateTime(),

      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      consentTypeId: '',
      signerPersonId: '',
      content: '',
    };

    return collectDigitalPersonConsent({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
