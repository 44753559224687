import { DataPermissionTypeDataEnum, MediaData } from '@ysura/common';

import {
  parseDocumentData,
  parseKeyMessageConnection,
  parseShownMediaKeyMessages,
  parseTopicConnection,
} from '@/services/graphql/parsers';
import {
  convertedReaction,
  convertedSupportedMediaType,
} from '@/services/graphql/parsers/utils';
import {
  ActivityShownMedia,
  ActivityShownMediaConnection,
  Media,
  MediaConnection,
} from '@/services/graphql/types';
import { getFileName } from '@/services/media/helper';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

import { parseMediaVersionData } from './mediaVersionParser';

export const parseMediaData = (media?: DeepPartial<Media>): MediaData => {
  const fileName = getFileName(media);
  const latestVersion = media?.latestVersion
    ? parseMediaVersionData(media.latestVersion)
    : undefined;

  return {
    id: media?.id ?? undefined,
    oid: media?.oid ?? undefined,
    description: media?.description ?? undefined,
    mediaType: convertedSupportedMediaType(media?.mediaType),
    name: media?.name ?? undefined,
    fileName,
    latestVersion,
    thumbnailDocument: media?.thumbnailDocument
      ? parseDocumentData(media?.thumbnailDocument)
      : undefined,
    topics: media?.topics ? parseTopicConnection(media?.topics) : undefined,
    keyMessages: media?.keyMessages
      ? parseKeyMessageConnection(media?.keyMessages)
      : undefined,
    permissions:
      media?.dataPermissions?.map(
        (p) => p?.type as unknown as DataPermissionTypeDataEnum
      ) ?? [],
  };
};

export const parseMediaConnection = (
  mediaConnection?: DeepPartial<MediaConnection>
) => {
  const mediaEdges = mediaConnection?.edges ?? [];
  const medias = filterNonNull(
    mediaEdges.map((mediaEdge) => parseMediaData(mediaEdge?.node ?? undefined))
  );

  return {
    filteredCount: mediaConnection?.filteredCount ?? 0,
    medias,
  };
};

export const parseShownMediaData = (
  shownMedia?: DeepPartial<ActivityShownMedia>
): MediaData => {
  const media = parseMediaData(shownMedia?.media);
  const keyMessages = parseShownMediaKeyMessages(shownMedia?.keyMessages);
  const shownVersion = shownMedia?.shownVersion
    ? parseMediaVersionData(shownMedia.shownVersion)
    : undefined;

  return {
    ...media,
    reaction: convertedReaction(shownMedia?.reaction),
    keyMessages,
    shownMediaId: shownMedia?.oid ?? undefined,
    shownVersion,
  };
};

export const parseShownMediaConnection = (
  mediaList?: DeepPartial<ActivityShownMediaConnection>
) => {
  const medias = (mediaList?.edges ?? []).map((mediaEdge) =>
    parseShownMediaData(mediaEdge?.node ?? undefined)
  );

  return filterNonNull(medias);
};
