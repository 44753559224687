import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  ActivityStatusDataEnum,
  PromotionalMaterialGroupData,
  SampleRequestCollectState,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction, usePersonPromotionalMaterials } from '@/hooks';
import { getRequestedQuantity } from '@/views/Activity/Update/PromotionalMaterials/helper';
import {
  PromotionalMaterialCollectionDialog,
  PromotionalMaterialExpandDialog,
  PromotionalMaterialSignedFormDialog,
} from '@/views/Interaction/ExpandView/PromotionalMaterial';

type PromatButtonsProps = {
  activity: ActivityData;
  isAllowedToSign: boolean;
  isAllowToViewSignedForm: boolean;
  isRemoteInteraction?: boolean;
  promats: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    promotionalMaterialGroups: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const PromatButtons = ({
  activity,
  isAllowedToSign,
  isAllowToViewSignedForm,
  isRemoteInteraction,
  promats,
  onChangePromats,
}: PromatButtonsProps) => {
  const { t } = useTranslation();
  const { interactionStep, signedSampleRequestForm, setCurrentSampleRequest } =
    useInteraction();

  const firstAttendee = activity.attendees?.[0]?.person;

  // Don't allow to edit signatureRequired promat
  const isRestrictedEdit =
    activity.status === ActivityStatusDataEnum.CLOSED ||
    interactionStep === 'summary';

  const [
    isPromotionalMaterialCollectionDialogOpen,
    setIsPromotionalMaterialCollectionDialogOpen,
  ] = useState(false);
  const [
    isPromotionalMaterialExpandDialogOpen,
    setIsPromotionalMaterialExpandDialogOpen,
  ] = useState(false);
  const [
    isPromotionalMaterialSignedFormDialogOpen,
    setIsPromotionalMaterialSignedFormDialogOpen,
  ] = useState(false);

  const { selectedPromotionalMaterials } = usePersonPromotionalMaterials(
    activity,
    promats
  );

  const shouldShowSignFormButton =
    isAllowedToSign &&
    selectedPromotionalMaterials &&
    selectedPromotionalMaterials.find(
      (promat) =>
        promat.promotionalMaterial?.signatureRequired &&
        (promat.requestedQuantity ?? 0) <=
          (promat.availableQuantityInCurrentActivity ?? 0) &&
        !signedSampleRequestForm &&
        !activity.sampleRequestFormDocument
    );

  const shouldShowViewSignedButton =
    signedSampleRequestForm ||
    (isAllowToViewSignedForm &&
      promats.find(
        (promat) =>
          promat.promotionalMaterial?.signatureRequired &&
          activity.sampleRequestFormDocument
      ));

  const openCollectionDialog = () => {
    setIsPromotionalMaterialCollectionDialogOpen(true);
  };

  const handleAddEditPromat = () => {
    setIsPromotionalMaterialExpandDialogOpen(true);
  };

  const onClosePromotionalMaterialExpandDialog = () => {
    setIsPromotionalMaterialExpandDialogOpen(false);
  };

  const handleViewSignedPromat = () => {
    setIsPromotionalMaterialSignedFormDialogOpen(true);
  };

  const onClosePromotionalMaterialSignedFormDialog = () => {
    setIsPromotionalMaterialSignedFormDialogOpen(false);
  };

  const handleSignRequestForm = () => {
    if (isRemoteInteraction) {
      // This will render ProMatCollection component inside InteractionItems.tsx
      const state: SampleRequestCollectState = {
        step: 'collect',
        title: `${t('pages.interaction.common.requestForm')}`,
        attendee: undefined,
        requestedSamples: [],
        address: '',
        addressExtension: '',
        isDeliveryInformationEnabled: false,
        isDeliveryDateEnabled: false,
        deliveryTimeFrame: null,
        deliveryTimeFrames: [],
        deliveryDate: null,
        deliveryDateMinimumDays: 0,
        isSampleRequestSigned: !!activity.sampleRequestFormDocument,
        signature: '',
      };

      const payload: SampleRequestViewerPayload = {
        id: '',
        step: 'collect',
        state: state,
      };
      setCurrentSampleRequest?.(payload);
    } else {
      openCollectionDialog();
    }
  };

  const onClosePromotionalMaterialCollectionDialog = () => {
    setIsPromotionalMaterialCollectionDialogOpen(false);
  };

  const handleUpdatePromotionalMaterial = (
    updatedGroupPromats: PromotionalMaterialGroupData[]
  ) => {
    updatedGroupPromats.forEach((each: PromotionalMaterialGroupData) => {
      each.requestedQuantity = getRequestedQuantity(each);
      each.selected = true;
    });
    if (updatedGroupPromats?.length) {
      onChangePromats?.(updatedGroupPromats);
    } else {
      onChangePromats?.([]);
    }
  };

  if (!firstAttendee) {
    return null;
  }

  return (
    <>
      {shouldShowSignFormButton && (
        <StyledButton variant="contained" onClick={handleSignRequestForm}>
          {t('pages.interaction.common.signRequestForm')}
        </StyledButton>
      )}

      {shouldShowViewSignedButton && (
        <StyledButton variant="outlined" onClick={handleViewSignedPromat}>
          {t('pages.interaction.common.viewSignedRequestForm')}
        </StyledButton>
      )}

      <StyledButton variant="outlined" onClick={handleAddEditPromat}>
        {t('pages.interaction.common.addEdit')}
      </StyledButton>

      <PromotionalMaterialCollectionDialog
        activity={activity}
        promats={promats}
        isOpen={isPromotionalMaterialCollectionDialogOpen}
        onChangePromats={handleUpdatePromotionalMaterial}
        onClose={onClosePromotionalMaterialCollectionDialog}
      />

      {isPromotionalMaterialSignedFormDialogOpen && (
        <PromotionalMaterialSignedFormDialog
          isOpen={isPromotionalMaterialSignedFormDialogOpen}
          activity={activity}
          onClose={onClosePromotionalMaterialSignedFormDialog}
        />
      )}

      {isPromotionalMaterialExpandDialogOpen && (
        <PromotionalMaterialExpandDialog
          activity={activity}
          isRemoteInteraction={isRemoteInteraction}
          isRestrictedEdit={isRestrictedEdit}
          isAllowedToSign={isAllowedToSign}
          isOpen={isPromotionalMaterialExpandDialogOpen}
          givenPromotionalMaterialGroups={promats}
          openCollectionDialog={openCollectionDialog}
          handleSignFormInRemote={handleSignRequestForm}
          onChangePromats={handleUpdatePromotionalMaterial}
          onClose={onClosePromotionalMaterialExpandDialog}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;
