import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ParticipantBox } from '@ysura/common';
import { useEffect, useLayoutEffect, useRef } from 'react';

import { useInteraction } from '@/hooks';

import { NoHardwareAccess } from './NoHardwareAccess';

export const PreviewVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    isCameraActive,
    isCameraAccessDisabled,
    isMicAccessDisabled,
    selectedVideoInput,
  } = useInteraction();

  const shouldRenderVideo = isCameraActive && !isCameraAccessDisabled;
  const shouldRenderErrorMessage =
    isCameraAccessDisabled && isMicAccessDisabled;

  const stopStream = (stream: MediaStream) => {
    const tracks = stream?.getTracks?.() ?? [];

    tracks.forEach((track) => {
      track?.stop();
    });
  };

  // Get the currently selected camera & start the stream
  useEffect(() => {
    if (selectedVideoInput && isCameraActive) {
      /**
       * If a stream exists, and the selectedVideoInput has been changed, stop the stream coming
       * from the previously selectedVideoInput. This will ensure that the light turns
       * off for any camera that is not currently being used.
       */
      if (videoRef?.current?.srcObject) {
        const stream = videoRef.current.srcObject as MediaStream;
        stopStream(stream);
      }

      // get the latest selected video device
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: {
              exact: selectedVideoInput,
            },
          },
        })
        .then((stream) => {
          // pass the stream to the video element & play
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            const playPromise = videoRef.current.play();

            if (playPromise !== undefined) {
              /**
               * Catching the error for cypress test only. I have not noticed
               * that we have any issues when using the app ourselves.
               * https://developer.chrome.com/blog/play-request-was-interrupted/
               */
              playPromise.catch((error) => console.log(error));
            }
          }
        })
        .catch(() => {
          /**
           * on some Android phones the newly selected video doesn't play
           * and we end up in this catch block
           */
          // TODO: this issue will be addressed in the future
          videoRef.current?.pause();
        });
    }
  }, [selectedVideoInput, isCameraActive]);

  // Stop the stream if camera is inactive
  useEffect(() => {
    if (!isCameraActive && videoRef.current?.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;

      stopStream(stream);
    }
  }, [isCameraActive]);

  /**
   * Cleanup with layoutEffect. layoutEffect is needed here as we need
   * to read the videoRef value before it's destroyed
   */
  useLayoutEffect(() => {
    /**
     * Copying the ref to a local variable and use it inside the cleanup function.
     * This is done as videoRef will likely have changed by the time this effect cleanup function runs.
     */
    const cleanupVideoRef = videoRef;

    return () => {
      if (cleanupVideoRef.current?.srcObject) {
        const stream = cleanupVideoRef.current.srcObject as MediaStream;
        stopStream(stream);
      }
    };
  }, []);

  if (shouldRenderErrorMessage) {
    return <NoHardwareAccess />;
  }

  return (
    <>
      <Container>
        {/* playsInline is necessary for iOS devices, so the video doesn't play in fullscreen */}
        {shouldRenderVideo ? (
          <OwnVideo ref={videoRef} playsInline data-testid="own-video">
            <track kind="captions" />
          </OwnVideo>
        ) : (
          <VideoOffBox>
            <ParticipantBox size={'large'} />
          </VideoOffBox>
        )}
      </Container>
    </>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'shouldRenderVideo',
})(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.grey[700],

  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const OwnVideo = styled('video')(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  transform: 'scaleX(-1)',
}));

const VideoOffBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius * 2,
  aspectRatio: '1/1',
  height: '33%',
}));
