import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

type RoomHeaderWrapperProps = {
  children: ReactNode;
};

export const RoomHeaderWrapper = ({ children }: RoomHeaderWrapperProps) => {
  return <Wrapper data-testid="room-header-wrapper">{children}</Wrapper>;
};

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));
