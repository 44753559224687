import { gql } from '@apollo/client';

export const getSearchedPersons = gql`
  query getSearchedPersons(
    $search: String
    $first: Int
    $after: String
    $filter: PersonFilterInput
  ) {
    persons(
      search: $search
      first: $first
      after: $after
      orderBy: [{ lastName: ASC }]
      filter: $filter
    ) {
      filteredCount
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          oid
          firstName
          lastName
          prefixAcademicTitle {
            name
            state
          }
          employments(filter: { state: { _eq: ACTIVE } }) {
            edges {
              node {
                id
                oid
                organization {
                  id
                  oid
                  name
                  addresses(filter: { address: { state: { _eq: ACTIVE } } }) {
                    edges {
                      node {
                        addressType
                        address {
                          addressLine1
                          addressLine2
                          addressLine3
                          city
                          postalCode
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
