import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, NoItemPlaceholder } from '@ysura/common';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResponsiveCardList } from './ResponsiveCardList';

type ScrollableCardListProps = {
  activityList: Array<ActivityData>;
};

export const ScrollableCardList = ({
  activityList,
}: ScrollableCardListProps) => {
  const { t } = useTranslation();
  const [isWindowScrollVisible, setIsWindowScrollVisible] = useState(false);

  const scrollStatusHandler = useCallback(() => {
    if (document.body.scrollHeight > document.body.clientHeight) {
      setIsWindowScrollVisible(true);
    } else {
      setIsWindowScrollVisible(false);
    }
  }, []);

  useLayoutEffect(() => {
    scrollStatusHandler();
    window.addEventListener('resize', scrollStatusHandler);

    return () => {
      window.removeEventListener('resize', scrollStatusHandler);
    };
  }, [scrollStatusHandler]);

  return (
    <ActivityContent isWindowScrollVisible={isWindowScrollVisible}>
      {activityList.length ? (
        <ListWrapper>
          <ResponsiveCardList activityList={activityList} />
        </ListWrapper>
      ) : (
        <NoItemPlaceholder>
          {t('pages.homepage.touchpointList.noTouchpoint')}
        </NoItemPlaceholder>
      )}
    </ActivityContent>
  );
};

const ActivityContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isWindowScrollVisible',
})<{ isWindowScrollVisible: boolean }>(({ theme, isWindowScrollVisible }) =>
  isWindowScrollVisible
    ? {}
    : {
        maxHeight: 'calc(100vh - 318px)', // 318px is the number consists multiple headers' height and all the padding top and down of the ActivityContent itself
        overflowY: 'auto',
        margin: theme.spacing(-1),
        padding: theme.spacing(1),
      }
);

const ListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    rowGap: theme.spacing(1),
  },
}));
