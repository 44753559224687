import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';

export const ControlsSkeleton = () => {
  const isInLandscapeScreen = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  return (
    <Container data-testid="controls-skeleton">
      <ControlBox>
        <ControlItem />
        {!isInLandscapeScreen && <ControlItem />}
      </ControlBox>

      <ControlBox>
        <ControlItem />
        <ControlItem />
        {isInLandscapeScreen && <ControlItem />}
      </ControlBox>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
      alignItems: 'center',

      height: '100%',
      width: REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_WIDTH_LANDSCAPE,
      padding: `${theme.spacing(2)} 0`,
    },
}));

const ControlBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
    },
}));

const ControlItem = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '100%',
  background: theme.palette.grey[700],
}));
