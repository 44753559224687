import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button as MuiButton, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// We skip the unit test for this component but we already have e2e test in person page
export const BackToTopButton = () => {
  const { t } = useTranslation();
  // The back-to-top button is hidden at the beginning
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  useEffect(() => {
    let lastScrollTop = 0;
    const onScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      //only visible when the page is longer than 4 times the screen size.
      //Because the pageYOffset initial value is 0, so we have to multiple innerHeight by 3
      if (
        window.pageYOffset > 0 &&
        window.pageYOffset > window.innerHeight &&
        currentScrollTop < lastScrollTop
      ) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  };

  const isMatchedMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      {showBackToTopButton && isMatchedMediaQuery && (
        <Button onClick={scrollToTop}>
          <ArrowUpwardIcon />
          {t('components.backToTopButton.backToTop')}
        </Button>
      )}
    </>
  );
};

const Button = styled(MuiButton)(({ theme }) => ({
  textTransform: 'uppercase',
  position: 'fixed',
  display: 'flex',
  bottom: theme.spacing(21),
  right: theme.spacing(2),
  backgroundColor: theme.palette.grey[300],
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  gap: theme.spacing(1),
  boxShadow: theme.customShadows.z8,
  borderRadius: 8,
  color: theme.palette.text.primary,
  [':hover']: {
    backgroundColor: theme.palette.grey[300],
  },
}));
