import { CommentData, DataPermissionTypeDataEnum } from '@ysura/common';

export const hasEditCommentPermission = (comment: CommentData): boolean => {
  return (
    (comment?.permissions?.some(
      (item) => item === DataPermissionTypeDataEnum.EDIT_COMMENT
    ) &&
      comment?.commentType?.permissions?.some(
        (item) => item === DataPermissionTypeDataEnum.ASSIGN_COMMENT_TYPE
      )) ??
    false
  );
};
