import { gql } from '@apollo/client';

export const addSampleRequestForm = gql`
  mutation addSampleRequestForm(
    $oid: SampleRequestFormId!
    $signerId: PersonId!
    $issuerId: PersonId!
    $addressId: AddressId!
    $addressExtension: String
    $createdAt: Date!
    $activityTypeId: ActivityTypeId!
    $deliveryTimeFrameId: DeliveryTimeFrameId
    $deliveryDate: Date
    $body: String!
    $filename: String!
    $skipToDo: Boolean
    $items: [SampleRequestFormItemInput!]!
    $state: State!
  ) {
    addSampleRequestForm(
      input: {
        oid: $oid
        signerId: $signerId
        issuerId: $issuerId
        addressId: $addressId
        addressExtension: $addressExtension
        createdAt: $createdAt
        activityTypeId: $activityTypeId
        deliveryTimeFrameId: $deliveryTimeFrameId
        deliveryDate: $deliveryDate
        body: $body
        filename: $filename
        skipToDo: $skipToDo
        items: $items
        state: $state
      }
    ) {
      id
      oid
      document {
        id
        oid
        name
      }
    }
  }
`;
