import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetSearchedOrganizationsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OrganizationFilterInput>;
}>;

export type GetSearchedOrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationConnection';
    filteredCount: number;
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
    };
    edges?: Array<{
      __typename?: 'OrganizationEdge';
      node?: {
        __typename?: 'Organization';
        id: string;
        oid: string;
        name?: string | null;
        customerReference?: string | null;
        organizationTypes: {
          __typename?: 'OrganizationTypeConnection';
          edges?: Array<{
            __typename?: 'OrganizationTypeEdge';
            node?: {
              __typename?: 'OrganizationType';
              id: string;
              oid: string;
              baseType?: Types.OrganizationBaseType | null;
            } | null;
          }> | null;
        };
        rootOrganization?: {
          __typename?: 'Organization';
          id: string;
          oid: string;
          name?: string | null;
        } | null;
        addresses: {
          __typename?: 'EntityAddressConnection';
          edges?: Array<{
            __typename?: 'EntityAddressEdge';
            node?: {
              __typename?: 'EntityAddress';
              addressType: Types.AddressType;
              address: {
                __typename?: 'Address';
                id: string;
                oid: string;
                addressLine1?: string | null;
                addressLine2?: string | null;
                addressLine3?: string | null;
                city: string;
                postalCode: string;
              };
            } | null;
          }> | null;
        };
      } | null;
    }> | null;
  };
};

/**
 * __useGetSearchedOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetSearchedOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchedOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchedOrganizationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSearchedOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchedOrganizationsQuery,
    GetSearchedOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetSearchedOrganizationsQuery,
    GetSearchedOrganizationsQueryVariables
  >(Operations.getSearchedOrganizations, options);
}
export function useGetSearchedOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchedOrganizationsQuery,
    GetSearchedOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetSearchedOrganizationsQuery,
    GetSearchedOrganizationsQueryVariables
  >(Operations.getSearchedOrganizations, options);
}
export function useGetSearchedOrganizationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSearchedOrganizationsQuery,
    GetSearchedOrganizationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetSearchedOrganizationsQuery,
    GetSearchedOrganizationsQueryVariables
  >(Operations.getSearchedOrganizations, options);
}
export type GetSearchedOrganizationsQueryHookResult = ReturnType<
  typeof useGetSearchedOrganizationsQuery
>;
export type GetSearchedOrganizationsLazyQueryHookResult = ReturnType<
  typeof useGetSearchedOrganizationsLazyQuery
>;
export type GetSearchedOrganizationsSuspenseQueryHookResult = ReturnType<
  typeof useGetSearchedOrganizationsSuspenseQuery
>;
export type GetSearchedOrganizationsQueryResult = Apollo.QueryResult<
  GetSearchedOrganizationsQuery,
  GetSearchedOrganizationsQueryVariables
>;
