import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader, PromotionalMaterialGroupData } from '@ysura/common';

import { PromotionalMaterialListItem } from './PromotionalMaterialListItem';

type PromotionalMaterialListProps = {
  isLoading: boolean;
  isRestrictedEdit?: boolean;
  isSigned: boolean;
  promotionalMaterialList: Array<PromotionalMaterialGroupData>;
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const PromotionalMaterialList = ({
  isLoading,
  isRestrictedEdit = false,
  isSigned,
  promotionalMaterialList,
  promotionalMaterialGroups,
  onChangePromats,
}: PromotionalMaterialListProps) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      {promotionalMaterialList.map((material) => (
        <PromotionalMaterialListItem
          key={material.promotionalMaterial?.id}
          isRestrictedEdit={isRestrictedEdit}
          promotionalMaterialListItem={material}
          isSigned={isSigned}
          promotionalMaterialGroups={promotionalMaterialGroups}
          onChangePromats={onChangePromats}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
