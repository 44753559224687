import { gql } from '@apollo/client';

export const getPersonAssignmentList = gql`
  query getPersonAssignmentList($filter: PersonAssignmentFilterInput) {
    personAssignments(filter: $filter) {
      edges {
        node {
          primary
          person {
            id
            oid
            firstName
            middleName
            lastName
            customerReference
            gender
          }
          organizationalUnit {
            id
            oid
            name
            state
          }
        }
      }
    }
  }
`;
