import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetEmployeeFunctionsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetEmployeeFunctionsQuery = {
  __typename?: 'Query';
  employeeFunctions: {
    __typename?: 'EmployeeFunctionConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'EmployeeFunctionEdge';
      node?: {
        __typename?: 'EmployeeFunction';
        id: string;
        oid: string;
        name?: string | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetEmployeeFunctionsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeFunctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeFunctionsQuery,
    GetEmployeeFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetEmployeeFunctionsQuery,
    GetEmployeeFunctionsQueryVariables
  >(Operations.getEmployeeFunctions, options);
}
export function useGetEmployeeFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeFunctionsQuery,
    GetEmployeeFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetEmployeeFunctionsQuery,
    GetEmployeeFunctionsQueryVariables
  >(Operations.getEmployeeFunctions, options);
}
export function useGetEmployeeFunctionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetEmployeeFunctionsQuery,
    GetEmployeeFunctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetEmployeeFunctionsQuery,
    GetEmployeeFunctionsQueryVariables
  >(Operations.getEmployeeFunctions, options);
}
export type GetEmployeeFunctionsQueryHookResult = ReturnType<
  typeof useGetEmployeeFunctionsQuery
>;
export type GetEmployeeFunctionsLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeFunctionsLazyQuery
>;
export type GetEmployeeFunctionsSuspenseQueryHookResult = ReturnType<
  typeof useGetEmployeeFunctionsSuspenseQuery
>;
export type GetEmployeeFunctionsQueryResult = Apollo.QueryResult<
  GetEmployeeFunctionsQuery,
  GetEmployeeFunctionsQueryVariables
>;
