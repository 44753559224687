import { ActivityTypeData, TopicData } from '@ysura/common';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TopicsSelect } from '@/views/Activity/Shared/Topics';
import {
  ColumnContentWrapper,
  RequiredField,
  SectionLabel,
  SectionWrapper,
} from '@/views/Activity/TouchpointManagement/SharedStyledComponents';
import { BaseFormikProps } from '@/views/Activity/TouchpointManagement/types';

import { findMatchingTopics } from './helper';

interface TopicsCardProps extends BaseFormikProps {
  activityTypes: Array<ActivityTypeData>;
  isSubmitting?: boolean;
}

export const TopicsCard = ({
  setFieldValue,
  activityTypes,
  touchpointValues,
  isSubmitting = false,
}: TopicsCardProps) => {
  const [currentTouchpointType, setCurrentTouchpointType] = useState('');
  const [topicOptions, setTopicOptions] = useState<Array<TopicData>>([]);

  const { t } = useTranslation();

  const selectedTopics = useMemo(
    () => touchpointValues?.topics ?? [],
    [touchpointValues?.topics]
  );

  const isDisabled = !currentTouchpointType || isSubmitting;

  const handleChangeTopics = (
    e: SyntheticEvent<Element, Event>,
    values: Array<TopicData>
  ) => {
    setFieldValue?.('topics', values);
  };

  useEffect(() => {
    const incomingTouchpointType = touchpointValues?.touchpointType?.oid ?? '';

    // If the currently selected touchpointType doesn't match the newly selected one:
    // 1: update topicOptions passed to the dropdown.
    // 2: only if any of the selected topics are part of the incoming touchpointType, keep them.
    if (currentTouchpointType !== incomingTouchpointType) {
      const incomingTouchpointTypeTopicOptions =
        activityTypes?.filter(
          (activityType) => activityType?.oid === incomingTouchpointType
        )[0]?.topics ?? [];

      const matchingTopics = findMatchingTopics(
        selectedTopics,
        incomingTouchpointTypeTopicOptions
      );

      setCurrentTouchpointType(incomingTouchpointType);
      setTopicOptions(incomingTouchpointTypeTopicOptions);
      setFieldValue?.('topics', matchingTopics);
    }
  }, [
    activityTypes,
    currentTouchpointType,
    selectedTopics,
    setFieldValue,
    topicOptions,
    touchpointValues?.touchpointType,
  ]);

  return (
    <ColumnContentWrapper>
      <SectionWrapper>
        <SectionLabel variant="subtitle2">
          {t('components.touchpointManagement.topics')} (
          {touchpointValues?.topics?.length ?? 0})
          <RequiredField>*</RequiredField>
        </SectionLabel>
        <TopicsSelect
          available={topicOptions}
          selected={selectedTopics}
          isDisabled={isDisabled}
          onChange={handleChangeTopics}
        />
      </SectionWrapper>
    </ColumnContentWrapper>
  );
};
