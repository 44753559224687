import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import {
  UpdateActivityMutation,
  UpdateActivityMutationVariables,
  useUpdateActivityMutation as useUpdateActivityMutationApollo,
} from '../generated';

type UpdateActivityVariables = Pick<
  UpdateActivityMutationVariables,
  'oid' | 'actions'
>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivity, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      actions: // values of the actions for each field of an activity.
 *   },
 * });
 */
export function useUpdateActivityMutation(
  baseOptions?: MutationHookOptions<
    UpdateActivityMutation,
    UpdateActivityMutationVariables
  >
) {
  const [updateActivity, status] = useUpdateActivityMutationApollo({
    // We invalidate all queries related to activity to refresh the data
    refetchQueries: [
      'getActivityTypeList',
      'getActivityById',
      'getTouchPointGroupList',
      'getPersonPromotionalMaterials',
    ],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<
      UpdateActivityMutation,
      UpdateActivityVariables
    >
  ) => {
    const defaultVariables = {
      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      actions: [],
    };

    return updateActivity({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
}
