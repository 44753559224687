import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

import { TouchpointHistorySkeletonCard } from './TouchpointHistorySkeletonCard';

export const TouchpointsHistorySkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.touchpointHistory')}
      testId="touchpoint-history-skeleton-static-block"
    >
      <TouchpointHistorySkeletonCard />
    </OverviewStaticBlock>
  );
};
