import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ConsentSkeletonCard } from '@/components/Consent';
import { OverviewStaticBlock } from '@/components/StaticBlock';

type ConsentSkeletonStaticBlockProps = {
  isInRemoteRoom?: boolean;
};
export const ConsentSkeletonStaticBlock = ({
  isInRemoteRoom = false,
}: ConsentSkeletonStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      testId="consent-skeleton-static-block"
      title={t('pages.interaction.common.consentColumnTitle')}
      spacing={isInRemoteRoom ? 'dense' : 'spacious'}
    >
      <MediumStack>
        <ConsentSkeletonCard />
        <ConsentSkeletonCard />
      </MediumStack>
    </OverviewStaticBlock>
  );
};

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
