import { CancelPresentation, PresentToAll } from '@mui/icons-material';
import { Button as MuiButton, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';
import { isMobileDevice } from '@/utils/browser';

type ScreenShareButtonProps = {
  variant: 'round' | 'list';
  handleClickScreenShare?: VoidFunction;
  testId?: string;
  shouldBroadcast?: boolean;
  participantSharingId?: string;
};

export const ScreenShareButton = ({
  variant,
  handleClickScreenShare,
  testId,
  shouldBroadcast,
  participantSharingId,
}: ScreenShareButtonProps) => {
  const { t } = useTranslation();
  const {
    isAnyContentShared,
    isOwnScreenShared,
    startScreenSharing,
    stopScreenSharing,
  } = useInteraction();

  const isSmallScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });
  const buttonSize = isSmallScreen ? 'medium' : 'large';

  // Mobile devices don't support screen sharing
  if (isMobileDevice()) {
    return null;
  }

  const roundButtonVariant = isOwnScreenShared ? 'primary' : 'secondary';

  const handleToggleScreenShare = () => {
    if (isOwnScreenShared) {
      stopScreenSharing?.();
    } else {
      startScreenSharing?.(shouldBroadcast, participantSharingId);
    }
  };

  const handleSharingButtonClick = () => {
    handleToggleScreenShare();
    handleClickScreenShare?.();
  };

  if (variant === 'list') {
    return (
      <ListButton
        disabled={isAnyContentShared}
        startIcon={<PresentToAll />}
        data-testid={testId}
        onClick={handleSharingButtonClick}
      >
        {isOwnScreenShared
          ? t('pages.interaction.remote.stopSharingScreen')
          : t('pages.interaction.remote.shareScreen')}
      </ListButton>
    );
  }

  return (
    <RoundButton
      variant={roundButtonVariant}
      size={buttonSize}
      disabled={isAnyContentShared}
      testId={testId}
      onClick={handleSharingButtonClick}
    >
      {isOwnScreenShared ? <CancelPresentation /> : <PresentToAll />}
    </RoundButton>
  );
};

const ListButton = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'none',
}));
