import { Box, Button as MuiButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

type FilePreviewHeaderProps = {
  onClose: VoidFunction;
  title: string | undefined;
};

export const DocumentPreviewHeader = ({
  onClose,
  title,
}: FilePreviewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <DialogHeader>
      <Typography noWrap variant="h5">
        {title}
      </Typography>

      <Button variant="contained" onClick={onClose}>
        {t('components.common.close')}
      </Button>
    </DialogHeader>
  );
};

const DialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: '0 0 auto',
  padding: `${theme.spacing(3)} ${theme.spacing(3)} 0px`,

  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px`,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
