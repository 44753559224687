import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetActivityTypeTopicsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetActivityTypeTopicsQuery = {
  __typename?: 'Query';
  activityType?: {
    __typename?: 'ActivityType';
    id: string;
    oid: string;
    topics: {
      __typename?: 'TopicConnection';
      edges?: Array<{
        __typename?: 'TopicEdge';
        node?: {
          __typename?: 'Topic';
          id: string;
          oid: string;
          name: string;
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetActivityTypeTopicsQuery__
 *
 * To run a query within a React component, call `useGetActivityTypeTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityTypeTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityTypeTopicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityTypeTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityTypeTopicsQuery,
    GetActivityTypeTopicsQueryVariables
  > &
    (
      | { variables: GetActivityTypeTopicsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetActivityTypeTopicsQuery,
    GetActivityTypeTopicsQueryVariables
  >(Operations.getActivityTypeTopics, options);
}
export function useGetActivityTypeTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityTypeTopicsQuery,
    GetActivityTypeTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetActivityTypeTopicsQuery,
    GetActivityTypeTopicsQueryVariables
  >(Operations.getActivityTypeTopics, options);
}
export function useGetActivityTypeTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivityTypeTopicsQuery,
    GetActivityTypeTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetActivityTypeTopicsQuery,
    GetActivityTypeTopicsQueryVariables
  >(Operations.getActivityTypeTopics, options);
}
export type GetActivityTypeTopicsQueryHookResult = ReturnType<
  typeof useGetActivityTypeTopicsQuery
>;
export type GetActivityTypeTopicsLazyQueryHookResult = ReturnType<
  typeof useGetActivityTypeTopicsLazyQuery
>;
export type GetActivityTypeTopicsSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityTypeTopicsSuspenseQuery
>;
export type GetActivityTypeTopicsQueryResult = Apollo.QueryResult<
  GetActivityTypeTopicsQuery,
  GetActivityTypeTopicsQueryVariables
>;
