export const createPersonInitials = (name: string | undefined): string => {
  return name ? getTwoCharacterFromFullName(name) : '';
};

const getFirstCharacter = (name: string) => {
  return name && name.charAt(0).toUpperCase();
};

const getTwoCharacterFromFullName = (fullName: string) => {
  if (!fullName.split(' ')[1]?.[0]) {
    return getFirstCharacter(fullName);
  }

  return `${fullName.split(' ')[0][0]}${
    fullName.split(' ')[1][0]
  }`.toUpperCase();
};
