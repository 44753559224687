import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetSpecialitiesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSpecialitiesQuery = {
  __typename?: 'Query';
  specialities: {
    __typename?: 'SpecialityConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SpecialityEdge';
      node?: {
        __typename?: 'Speciality';
        id: string;
        oid: string;
        name?: string | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetSpecialitiesQuery__
 *
 * To run a query within a React component, call `useGetSpecialitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecialitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecialitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpecialitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSpecialitiesQuery,
    GetSpecialitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetSpecialitiesQuery, GetSpecialitiesQueryVariables>(
    Operations.getSpecialities,
    options
  );
}
export function useGetSpecialitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpecialitiesQuery,
    GetSpecialitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetSpecialitiesQuery,
    GetSpecialitiesQueryVariables
  >(Operations.getSpecialities, options);
}
export function useGetSpecialitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSpecialitiesQuery,
    GetSpecialitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetSpecialitiesQuery,
    GetSpecialitiesQueryVariables
  >(Operations.getSpecialities, options);
}
export type GetSpecialitiesQueryHookResult = ReturnType<
  typeof useGetSpecialitiesQuery
>;
export type GetSpecialitiesLazyQueryHookResult = ReturnType<
  typeof useGetSpecialitiesLazyQuery
>;
export type GetSpecialitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetSpecialitiesSuspenseQuery
>;
export type GetSpecialitiesQueryResult = Apollo.QueryResult<
  GetSpecialitiesQuery,
  GetSpecialitiesQueryVariables
>;
