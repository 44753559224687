import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { SquareButton } from '@ysura/common';
import { useState } from 'react';

type FitScreenButtonProps = {
  onChangeAspect: VoidFunction;
};

export const FitScreenButton = ({ onChangeAspect }: FitScreenButtonProps) => {
  const [clicked, setClicked] = useState(false);

  const handleClickFitScreen = () => {
    setClicked(!clicked);
    onChangeAspect();
  };

  return (
    <SquareButton
      variant="secondary"
      testId={'fitscreen-button'}
      buttonSize={36}
      onClick={handleClickFitScreen}
    >
      {clicked ? <ClickedFitScreenButton /> : <UnClickFitScreenButton />}
    </SquareButton>
  );
};

const UnClickFitScreenButton = styled(Fullscreen)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const ClickedFitScreenButton = styled(FullscreenExit)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
