import {
  ActivityData,
  ActivityTypeBaseTypeDataEnum,
  PersonData,
  StateDataEnum,
} from '@ysura/common';
import { differenceInDays } from 'date-fns';

import { CurrentUserState } from '@/hooks/state/useCipState';
import { ActivityAttendee } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

type ValidateForInteractionParams = {
  activity: ActivityData | null;
  currentUser: CurrentUserState | null;
};

type ValidateForInteractionReturn = {
  isValidForInteraction: boolean;
  reason?:
    | 'noActivity'
    | 'impersonated'
    | 'userNotOrganizerAndCoOrganizer'
    | 'activityNotActive'
    | 'activitySealed'
    | 'activityNotDirectContactOrRemote';
};

export const isUserOrganizer = (
  activity: ActivityData,
  person: PersonData | undefined | null
) => activity?.organizer?.oid === person?.oid;

export const validateForInteraction = ({
  activity,
  currentUser,
}: ValidateForInteractionParams): ValidateForInteractionReturn => {
  if (!activity) {
    return { isValidForInteraction: false, reason: 'noActivity' };
  }

  const { state, status, activityType } = activity;

  if (!state || !status || !activityType?.baseType) {
    return { isValidForInteraction: false, reason: 'noActivity' };
  }

  // Users impersonated in the CRM cannot start interactions
  if (!currentUser?.person?.oid) {
    return { isValidForInteraction: false, reason: 'impersonated' };
  }

  // Only the organizer or co-organizer can join interactions
  if (
    currentUser?.person?.oid &&
    currentUser.person.oid !== activity.organizer?.oid &&
    (activity.coOrganizers ?? [])
      .map((it) => it.person?.oid ?? '')
      .indexOf(currentUser.person.oid) === -1
  ) {
    return {
      isValidForInteraction: false,
      reason: 'userNotOrganizerAndCoOrganizer',
    };
  }

  // Sealed activities cannot be used for interactions
  if (activity.sealed) {
    return { isValidForInteraction: false, reason: 'activitySealed' };
  }

  // Only active activities can be used for interactions
  if (state !== StateDataEnum.ACTIVE) {
    return { isValidForInteraction: false, reason: 'activityNotActive' };
  }

  // Only base types direct contact, telephone and remote activities can be used for interactions
  if (
    activity?.activityType?.baseType &&
    ![
      ActivityTypeBaseTypeDataEnum.REMOTE,
      ActivityTypeBaseTypeDataEnum.DIRECT_CONTACT,
      ActivityTypeBaseTypeDataEnum.TELEPHONE,
    ].includes(activity?.activityType?.baseType)
  ) {
    // If not, return an error
    return {
      isValidForInteraction: false,
      reason: 'activityNotDirectContactOrRemote',
    };
  }

  return { isValidForInteraction: true };
};

export const isAttendeeGuest = (attendee: DeepPartial<ActivityAttendee>) => {
  return attendee.isGuest || (!attendee?.isGuest && !attendee?.person);
};

export const getAvailableAmount = (
  givenQuantity = 0,
  maxQuantity = 0
): number => {
  return maxQuantity - givenQuantity;
};

export const isActivityEditable = (
  activity: ActivityData | null,
  currentUser: CurrentUserState | null
): boolean => {
  if (!activity || !currentUser) {
    return false;
  }

  const activityOrganizer = activity?.organizer?.oid;
  const currentlyLoggedInUser = currentUser?.person?.oid;

  const touchpointStartDate = activity?.startDate;
  const daysInPastForModification =
    activity?.activityType?.daysInPastForModification;

  if (
    !activityOrganizer ||
    !currentlyLoggedInUser ||
    activityOrganizer !== currentlyLoggedInUser
  ) {
    return false;
  }

  if (activity?.sealed) {
    return false;
  }

  if (daysInPastForModification && touchpointStartDate) {
    const daysSinceTouchpointDate = differenceInDays(
      new Date(),
      new Date(touchpointStartDate)
    );

    if (daysSinceTouchpointDate > daysInPastForModification) {
      return false;
    }
  }

  return !!currentUser.permissions?.activity.canEditActivity;
};
