import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConsentHistoryData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useFormatDate } from '@/hooks';
import {
  getHistoryTitle,
  handleActionContent,
} from '@/utils/getPersonConsentDetails';

type ConsentHistoryProps = {
  consentHistory: ConsentHistoryData[];
};

export const ConsentHistory = ({ consentHistory }: ConsentHistoryProps) => {
  const { t } = useTranslation();
  const { formatDate } = useFormatDate();

  const historyContent =
    consentHistory.map((history: ConsentHistoryData, key: number) => {
      const historyTitle = getHistoryTitle(history, formatDate);

      return (
        <TimelineItem key={key}>
          <TimelineSeparator>
            <StyleTimelineDot />
            {key !== consentHistory.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Stack data-testid="consent-history-content">
              <Typography variant="subtitle2">{historyTitle}</Typography>
              <CaptionTypography variant="caption">
                {t(
                  'components.consentDialog.personConsent.action.' +
                    history.action
                )}
              </CaptionTypography>
              {history.handledAt && (
                <CaptionTypography variant="caption">
                  {handleActionContent(history.handledAt, t, formatDate)}
                </CaptionTypography>
              )}
            </Stack>
          </TimelineContent>
        </TimelineItem>
      );
    }) || [];

  if (!historyContent.length) {
    return (
      <>
        <SubTitleTypography variant="subtitle1">
          {t('components.consentDialog.history')}
        </SubTitleTypography>
        <TimelineWrapper>
          <TimelineItem key={0}>
            <TimelineSeparator>
              <StyleTimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              <Stack>
                <Typography variant="subtitle2">
                  {t('pages.interaction.common.noDataAvailable')}
                </Typography>
              </Stack>
            </TimelineContent>
          </TimelineItem>
        </TimelineWrapper>
      </>
    );
  }

  return (
    <>
      <SubTitleTypography variant="subtitle1">
        {t('components.consentDialog.history')}
      </SubTitleTypography>
      <TimelineWrapper>{historyContent}</TimelineWrapper>
    </>
  );
};

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CaptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const TimelineWrapper = styled(Box)(() => ({
  paddingLeft: 0,
  paddingRight: 0,

  '.MuiTimelineItem-root': {
    '&:before': {
      display: 'none',
    },
  },
}));

const StyleTimelineDot = styled(TimelineDot)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.main,
}));
