import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentFormPreview,
  ConsentViewerPayload,
  Loader,
  SignatureBox,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

import { CoOrganizerConsentCommunicationCollection } from './CoOrganizerConsentCommunicationCollection';

interface CoOrganizerConsentCollectionProps {
  consent: ConsentViewerPayload;
}

export const CoOrganizerConsentCollection = ({
  consent,
}: CoOrganizerConsentCollectionProps) => {
  const { t } = useTranslation();

  const [payload, setPayload] = useState<ConsentViewerPayload | null>(consent);

  const { onConsentFormValueChanged } = useInteraction();

  useEffect(() => {
    const handleConsentFormValueChange = (event: ConsentViewerPayload) => {
      setPayload(event);
    };

    const unsubscribeOnConsentFormValueChanged = onConsentFormValueChanged?.(
      handleConsentFormValueChange
    );

    return () => {
      if (unsubscribeOnConsentFormValueChanged) {
        unsubscribeOnConsentFormValueChanged();
      }
    };
  }, [onConsentFormValueChanged]);

  if (payload) {
    return (
      <Wrapper data-testid="consent-collection-card">
        <Header>
          <Typography variant="h6">{payload?.state?.title ?? ''}</Typography>
        </Header>

        {payload.state?.step === 'collect' && (
          <Content data-testid="ConsentForm">
            <FormWrapper>
              <StyleTypography variant="body1">
                {payload.state.staticText ?? ''}
              </StyleTypography>
              <StyleGrid container>
                <ConsentItemGrid item xs={12}>
                  <CoOrganizerConsentCommunicationCollection
                    consentItemsCommunicationData={
                      payload.state.consentCommunicationData
                    }
                    consentValues={payload.state.consentItems}
                    defaultCountry={payload.validation?.defaultCountryPhone}
                    disable={true}
                  />
                </ConsentItemGrid>
              </StyleGrid>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <SignatureBox
                    isClearable={true}
                    disable={true}
                    signature={payload.state.signature}
                    signatureLabel={t('pages.interaction.expand.signature')}
                    clearLabel={t('components.signatureBox.clear')}
                    errorLabel={t('components.signatureBox.signatureNotValid')}
                  />
                </Grid>
              </Grid>
            </FormWrapper>
          </Content>
        )}

        {payload.state?.step === 'preview' && (
          <ConsentFormPreview state={payload.state} />
        )}
      </Wrapper>
    );
  }

  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflowY: 'auto',
}));

const LoaderWrapper = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'grid',
  placeContent: 'center',
});

const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

const StyleGrid = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.only('xs')]: {
    gap: theme.spacing(2),
  },
}));

const ConsentItemGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyleTypography = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));
