import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EmploymentData, PersonCard } from '@ysura/common';

import { LinkNoDecoration } from '@/components/LinkTo';
import { getEmploymentDetails } from '@/services/employment';

interface EmploymentCardStaticBlockProps {
  employments: Array<EmploymentData>;
}
export const EmploymentCardStaticBlock = ({
  employments,
}: EmploymentCardStaticBlockProps) => {
  return (
    <Wrapper data-testid="employment-person-card">
      {employments.map((employment: EmploymentData) => {
        const { id, person, organization, address } =
          getEmploymentDetails(employment);

        return (
          <LinkNoDecoration
            key={employment.oid}
            to={`/person/${id}`}
            data-testid="person-card"
          >
            <PersonCard
              personName={person}
              organizationName={organization}
              addressName={address}
            />
          </LinkNoDecoration>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));
