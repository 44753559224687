import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  InfiniteScrollingPersonCardSkeleton,
  PersonCard,
  PersonData,
} from '@ysura/common';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LinkNoDecoration } from '@/components/LinkTo';
import { getPersonDetails } from '@/services/helper';

interface OrganizationPersonCardListProps {
  persons?: Array<PersonData>;
  fetchMorePersons: VoidFunction;
  hasMore: boolean;
}
export const OrganizationHierarchyPersonCardList = ({
  persons,
  fetchMorePersons,
  hasMore,
}: OrganizationPersonCardListProps) => {
  return (
    <ScrollableStack id="person-search-result">
      <InfiniteScroll
        dataLength={persons?.length ?? 0}
        hasMore={hasMore}
        next={fetchMorePersons}
        scrollThreshold={0.7}
        scrollableTarget="person-search-result"
        loader={<InfiniteScrollingPersonCardSkeleton />}
      >
        <SearchResultsWrapper>
          {persons?.map((person: PersonData) => {
            const { id, personName, organizationName, addressName } =
              getPersonDetails(person);

            return (
              <LinkNoDecoration
                key={id}
                to={`/person/${id}`}
                data-testid="person-card"
              >
                <PersonCard
                  personName={personName}
                  organizationName={organizationName}
                  addressName={addressName}
                />
              </LinkNoDecoration>
            );
          })}
        </SearchResultsWrapper>
      </InfiniteScroll>
    </ScrollableStack>
  );
};

const SearchResultsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
  height: '100%',
}));

const ScrollableStack = styled(Stack)(() => ({
  height: '100%',
  overflowY: 'auto',
}));
