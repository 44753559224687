import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

type InteractionItemsColumnProps = {
  flex: number;
  isSharing?: boolean;
  isHidden?: boolean;
  testId?: string;
  children: ReactNode;
};

export const InteractionItemsColumn = ({
  children,
  testId,
  isSharing = true,
  isHidden = false,
  flex,
}: InteractionItemsColumnProps) => {
  return (
    <Wrapper
      isSharing={isSharing}
      isHidden={isHidden}
      flexValue={flex}
      data-testid={testId ?? 'interaction-items-column'}
    >
      {children}
    </Wrapper>
  );
};

type WrapperStyleProps = {
  flexValue: number;
  isSharing?: boolean;
  isHidden: boolean;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isHidden' && prop !== 'isSharing' && prop !== 'flexValue',
})<WrapperStyleProps>(({ theme, flexValue, isSharing, isHidden }) => ({
  display: isHidden ? 'none' : 'flex',

  /**
   * For waiting room: Video "column" will have flex 2, Content "column" flex 1
   * For remote room: Video "column" will have 1, Content "column" flex 2
   */
  flex: flexValue,

  height: '100%',
  overflow: 'hidden',

  background: theme.palette.common.white,

  padding: theme.spacing(2),
  borderRadius: Number(theme.shape.borderRadius) * 2,

  [theme.breakpoints.down('md')]: {
    flex: isHidden ? 'none' : 1,
    height: isSharing ? '100%' : 'fit-content',
    overflow: 'initial',

    borderRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      borderRadius: 0,
      borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
      borderBottomLeftRadius: isSharing
        ? Number(theme.shape.borderRadius) * 2
        : 0,
      borderTopRightRadius: isSharing
        ? 0
        : Number(theme.shape.borderRadius) * 2,
      overflow: 'initial',
      maxHeight: '100%',
    },
}));
