import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '../StaticBlock';
import { PersonDataContent } from './PersonDataContent';

interface PersonDataStaticBlockProps {
  person: PersonData;
  cardTitle?: string;
  isShowPersonTitle?: boolean;
}

export const PersonDataStaticBlock = ({
  person,
  cardTitle,
  isShowPersonTitle,
}: PersonDataStaticBlockProps) => {
  const { t } = useTranslation();
  const { customerReference } = person;

  return (
    <OverviewStaticBlock
      title={cardTitle ?? t('components.personDataCard.personData')}
      subtitle={<Subtitle variant="overline">{customerReference}</Subtitle>}
      testId="person-data-static-block"
    >
      <PersonDataContent
        person={person}
        isShowPersonTitle={isShowPersonTitle}
      />
    </OverviewStaticBlock>
  );
};

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
