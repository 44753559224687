import { Stack, Typography } from '@mui/material';
import { CommentData } from '@ysura/common';

type CommentTypeProps = {
  comment: CommentData;
};

const PARAGRAPH_SEPARATOR = '\n';

export const CommentParagraph = ({ comment }: CommentTypeProps) => {
  // Split the comment by paragraphs
  const commentParagraphs = comment.content?.split(PARAGRAPH_SEPARATOR);

  if (commentParagraphs?.length === 0) {
    return null;
  }

  return (
    <Stack>
      {commentParagraphs?.map((paragraph, index) => (
        <Typography key={index} variant="body2">
          {paragraph}
        </Typography>
      ))}
    </Stack>
  );
};
