import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

interface OrganizationDataStaticBlockProps {
  title: string;
  subTitle?: ReactNode;
  children: ReactNode;
}

export const OrganizationDataStaticBlock = ({
  title,
  subTitle,
  children,
}: OrganizationDataStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t(title)}
      subtitle={subTitle}
      testId="organization-data-static-block"
    >
      {children}
    </OverviewStaticBlock>
  );
};
