import {
  ActivityStatusDataEnum,
  CommentData,
  CommentTypeData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIconButton } from '@/components/Button';
import { CommentEditedDialog, CommentsBlock } from '@/components/Comment';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useCurrentUser } from '@/hooks';
import { hasAssignCommentTypePermission } from '@/services/permission';

type CommentsStaticBlockProps = {
  sourceComments: CommentData[];
  commentTypes?: CommentTypeData[];
  status?: ActivityStatusDataEnum;
  save: (comments: CommentData[], closeDialog: VoidFunction) => void;
  isLoading?: boolean;
  canEditActivity?: boolean;
};

export const CommentsStaticBlock = ({
  sourceComments,
  commentTypes,
  status,
  save,
  isLoading,
  canEditActivity = false,
}: CommentsStaticBlockProps) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { currentUser } = useCurrentUser();

  const handleClose = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  const handleSave = (comments: CommentData[]) => {
    save(comments, handleClose);
  };

  return (
    <OverviewStaticBlock
      title={t('pages.activity.comments')}
      subtitle={
        canEditActivity &&
        currentUser?.permissions?.activity?.canEditActivityComment &&
        hasAssignCommentTypePermission(commentTypes) && (
          <EditIconButton testId="comments-edit-button" onClick={handleClose} />
        )
      }
    >
      <CommentsBlock comments={sourceComments} status={status} />

      {isDialogOpen && (
        <CommentEditedDialog
          isOpen={isDialogOpen}
          closeDialog={handleClose}
          save={handleSave}
          sourceComments={sourceComments}
          commentTypes={commentTypes}
          isLoading={isLoading}
        />
      )}
    </OverviewStaticBlock>
  );
};
