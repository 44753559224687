import PersonIcon from '@mui/icons-material/Person';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconWrapper } from '@ysura/common';
import { useTranslation } from 'react-i18next';

export const GuestItem = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Wrapper>
      <Row>
        <IconWrapper backgroundColor={theme.palette.grey[400]} size={20}>
          <IconPerson />
        </IconWrapper>
        <Name noWrap variant="subtitle2">
          {t('pages.personView.guest')}
        </Name>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.customShadows.z8,
}));

const IconPerson = styled(PersonIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  height: 12,
  width: 12,
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));
