import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type AddSampleRequestFormMutationVariables = Types.Exact<{
  oid: Types.Scalars['SampleRequestFormId']['input'];
  signerId: Types.Scalars['PersonId']['input'];
  issuerId: Types.Scalars['PersonId']['input'];
  addressId: Types.Scalars['AddressId']['input'];
  addressExtension?: Types.InputMaybe<Types.Scalars['String']['input']>;
  createdAt: Types.Scalars['Date']['input'];
  activityTypeId: Types.Scalars['ActivityTypeId']['input'];
  deliveryTimeFrameId?: Types.InputMaybe<
    Types.Scalars['DeliveryTimeFrameId']['input']
  >;
  deliveryDate?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  body: Types.Scalars['String']['input'];
  filename: Types.Scalars['String']['input'];
  skipToDo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  items:
    | Array<Types.SampleRequestFormItemInput>
    | Types.SampleRequestFormItemInput;
  state: Types.State;
}>;

export type AddSampleRequestFormMutation = {
  __typename?: 'Mutation';
  addSampleRequestForm?: {
    __typename?: 'SampleRequestForm';
    id: string;
    oid: string;
    document?: {
      __typename?: 'Document';
      id: string;
      oid: string;
      name?: string | null;
    } | null;
  } | null;
};

export type AddSampleRequestFormMutationFn = Apollo.MutationFunction<
  AddSampleRequestFormMutation,
  AddSampleRequestFormMutationVariables
>;

/**
 * __useAddSampleRequestFormMutation__
 *
 * To run a mutation, you first call `useAddSampleRequestFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSampleRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSampleRequestFormMutation, { data, loading, error }] = useAddSampleRequestFormMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      signerId: // value for 'signerId'
 *      issuerId: // value for 'issuerId'
 *      addressId: // value for 'addressId'
 *      addressExtension: // value for 'addressExtension'
 *      createdAt: // value for 'createdAt'
 *      activityTypeId: // value for 'activityTypeId'
 *      deliveryTimeFrameId: // value for 'deliveryTimeFrameId'
 *      deliveryDate: // value for 'deliveryDate'
 *      body: // value for 'body'
 *      filename: // value for 'filename'
 *      skipToDo: // value for 'skipToDo'
 *      items: // value for 'items'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAddSampleRequestFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSampleRequestFormMutation,
    AddSampleRequestFormMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddSampleRequestFormMutation,
    AddSampleRequestFormMutationVariables
  >(Operations.addSampleRequestForm, options);
}
export type AddSampleRequestFormMutationHookResult = ReturnType<
  typeof useAddSampleRequestFormMutation
>;
export type AddSampleRequestFormMutationResult =
  Apollo.MutationResult<AddSampleRequestFormMutation>;
export type AddSampleRequestFormMutationOptions = Apollo.BaseMutationOptions<
  AddSampleRequestFormMutation,
  AddSampleRequestFormMutationVariables
>;
