import {
  AttributeTypeDataEnum,
  FacetFilterData,
  FilterItemData,
  FilterItemsData,
  OptionFilterData,
  SubFacetFilterData,
} from '@ysura/common';

import {
  ConsentItemStateChange,
  PersonBaseType,
  PersonConsentLifecycleState,
  PersonFilterInput,
  Scope,
  State,
} from '@/services/graphql/types';

export const buildPersonFilterQuery = (
  filters: FilterItemsData,
  scope: Scope
): PersonFilterInput => {
  const filter: PersonFilterInput = {
    scope: { _eq: scope },
    state: { _eq: State.ACTIVE },
  };

  // calculate PersonType filter
  const personTypes =
    filters
      .find((item: FilterItemData) => item.filterType === 'personTypes')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (personTypes.length > 0) {
    filter.personTypes = {
      _and: [
        { baseType: { _eq: PersonBaseType.CUSTOMER } },
        {
          _or: personTypes.map(({ oid }) => {
            return {
              oid: { _eq: oid },
            };
          }),
        },
      ],
    };
  } else {
    filter.personTypes = { baseType: { _eq: PersonBaseType.CUSTOMER } };
  }

  // calculate Speciality filter
  const specialities =
    filters
      .find((item: FilterItemData) => item.filterType === 'specialities')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (specialities.length > 0) {
    filter.specialities = {
      speciality: {
        _or: specialities.map(({ oid }) => {
          return {
            oid: {
              _eq: oid,
            },
          };
        }),
      },
    };
  }

  // calculate Speciality Group filter
  const specialityGroup =
    filters
      .find((item: FilterItemData) => item.filterType === 'specialityGroups')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (specialityGroup.length > 0) {
    filter.specialityGroup = {
      _or: specialityGroup.map(({ oid }) => {
        return {
          oid: {
            _eq: oid,
          },
        };
      }),
    };
  }

  // calculate Employee Function filter
  const employeeFunction =
    filters
      .find((item: FilterItemData) => item.filterType === 'employeeFunctions')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (employeeFunction.length > 0) {
    filter.employments = {
      function: {
        _or: employeeFunction.map(({ oid }) => {
          return {
            oid: {
              _eq: oid,
            },
          };
        }),
      },
    };
  }

  // calculate Attributes filter
  const attributes =
    filters
      .find((item: FilterItemData) => item.filterType === 'attributes')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (attributes.length > 0) {
    filter.attributes = {
      _and: attributes.map((attribute: FacetFilterData) => {
        return {
          attributeOid: { _eq: attribute.oid },
          value: {
            _or: attribute?.options
              ? attribute?.options
                  .filter((option: OptionFilterData) => option.isSelected)
                  .map((option: OptionFilterData) => {
                    return {
                      value: {
                        _eq:
                          attribute.type === AttributeTypeDataEnum.BOOLEAN
                            ? option?.id
                            : option.oid,
                      },
                    };
                  })
              : [],
          },
        };
      }),
    };
  }

  // calculate Consent Type filter
  const consentTypes =
    filters
      .find((item: FilterItemData) => item.filterType === 'consentTypes')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (consentTypes.length > 0) {
    const hasSelectedParentOption = consentTypes.some(
      (consentType: FacetFilterData) => {
        return consentType.options?.some(
          (option: OptionFilterData) => option.isSelected
        );
      }
    );

    const hasSelectedSubFacetOption = consentTypes.some(
      (consentType: FacetFilterData) => {
        return consentType.subFacets?.some((subFacet: SubFacetFilterData) => {
          return subFacet.options?.some(
            (option: OptionFilterData) => option.isSelected
          );
        });
      }
    );

    filter.consents = {};
    if (hasSelectedParentOption && hasSelectedSubFacetOption) {
      filter.consents['_and'] = [
        { _and: buildConsentTypeSelection(consentTypes) },
        { consentItemValue: buildConsentItemValueSelection(consentTypes) },
      ];
    } else if (hasSelectedParentOption) {
      filter.consents['_and'] = buildConsentTypeSelection(consentTypes);
    } else if (hasSelectedSubFacetOption) {
      filter.consents['consentItemValue'] =
        buildConsentItemValueSelection(consentTypes);
    }
  }

  return filter;
};

const buildConsentTypeSelection = (consentTypes: FacetFilterData[]) => {
  return consentTypes.map((consentType: FacetFilterData) => {
    return {
      consentType: {
        oid: { _eq: consentType.oid },
      },
      lifecycleState: {
        _in: consentType?.options
          ? consentType?.options
              .filter(
                (option: OptionFilterData) =>
                  option.oid &&
                  option.isSelected &&
                  option.name !== PersonConsentLifecycleState.MERGED
              )
              .flatMap(
                (option: OptionFilterData) =>
                  option.id as PersonConsentLifecycleState
              )
          : [],
      },
    };
  });
};

const buildConsentItemValueSelection = (consentTypes: FacetFilterData[]) => {
  return {
    _and:
      consentTypes.flatMap((consentType: FacetFilterData) => {
        return (
          consentType.subFacets?.flatMap((subFacet: SubFacetFilterData) => {
            return {
              _and: [
                {
                  consentItem: {
                    oid: { _eq: subFacet.oid },
                  },
                  consentItemState: {
                    _in: subFacet?.options
                      ? subFacet?.options
                          .filter(
                            (option: OptionFilterData) =>
                              option.id && option.isSelected
                          )
                          .flatMap(
                            (option: OptionFilterData) =>
                              option.id as ConsentItemStateChange
                          )
                      : [],
                  },
                },
              ],
            };
          }) ?? []
        );
      }) ?? [],
  };
};
