import { gql } from '@apollo/client';

export const getSystemConfiguration = gql`
  query getSystemConfiguration {
    systemConfiguration {
      id
      defaultCountry {
        id
        oid
        alpha2Code
      }
      sampleRequestAdditionalDeliveryInformationEnabled
      sampleRequestDeliveryDateEnabled
      sampleRequestDeliveryDateMinimumDaysInFuture
    }
  }
`;
