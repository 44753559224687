import { useMediaQuery, useTheme } from '@mui/material';
import {
  ActivityData,
  PersonConsentData,
  RoomContentWrapper,
} from '@ysura/common';
import { useEffect, useState } from 'react';

import { useInteraction } from '@/hooks';
import { InteractionItems } from '@/views/Interaction/Common';
import { CallInfoAndControls } from '@/views/Interaction/Telephone';

type RoomContentProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  roomId: string;
  canEditActivity?: boolean;
  onCallInfoDialog: VoidFunction;
  onEndInteraction: VoidFunction;
};

export const TelephoneRoomContent = ({
  activity,
  isOrganizer,
  consentList,
  roomId,
  onCallInfoDialog,
  onEndInteraction,
}: RoomContentProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { isAnyContentShared } = useInteraction();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    /**
     * close the drawer when sharing stops (feature requriement) or when
     * going from small to large screen (ensuring the UI doesn't break)
     */
    if (!isAnyContentShared || !isMobile) {
      setIsDrawerOpen(false);
    }
  }, [isMobile, isAnyContentShared]);

  return (
    <RoomContentWrapper data-testid="room-content-wrapper">
      <CallInfoAndControls
        isInteraction
        isDrawerOpen={isDrawerOpen}
        activity={activity}
        onCallInfoDialog={() => onCallInfoDialog()}
        onEndInteraction={() => onEndInteraction()}
      />

      <InteractionItems
        canEditActivity
        isOrganizer={isOrganizer}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        activity={activity}
        consentList={consentList}
        roomId={roomId}
      />
    </RoomContentWrapper>
  );
};
