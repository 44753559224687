import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetCurrentUserInventoryQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserInventoryQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    id: string;
    oid: string;
    inventory?: {
      __typename?: 'Inventory';
      id: string;
      oid: string;
      stocks: {
        __typename?: 'StockConnection';
        edges?: Array<{
          __typename?: 'StockEdge';
          node?: {
            __typename?: 'Stock';
            oid: string;
            type?: Types.StockType | null;
            quantity?: number | null;
            promotionalMaterial: {
              __typename?: 'PromotionalMaterial';
              id: string;
              oid: string;
              name: string;
              type: Types.PromotionalMaterialType;
              signatureRequired?: boolean | null;
            };
            promotionalMaterialBatch?: {
              __typename?: 'PromotionalMaterialBatch';
              oid: string;
              expiryDate?: string | null;
              name?: string | null;
            } | null;
          } | null;
        }> | null;
      };
    } | null;
  } | null;
};

/**
 * __useGetCurrentUserInventoryQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserInventoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserInventoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserInventoryQuery,
    GetCurrentUserInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetCurrentUserInventoryQuery,
    GetCurrentUserInventoryQueryVariables
  >(Operations.getCurrentUserInventory, options);
}
export function useGetCurrentUserInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserInventoryQuery,
    GetCurrentUserInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetCurrentUserInventoryQuery,
    GetCurrentUserInventoryQueryVariables
  >(Operations.getCurrentUserInventory, options);
}
export function useGetCurrentUserInventorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentUserInventoryQuery,
    GetCurrentUserInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetCurrentUserInventoryQuery,
    GetCurrentUserInventoryQueryVariables
  >(Operations.getCurrentUserInventory, options);
}
export type GetCurrentUserInventoryQueryHookResult = ReturnType<
  typeof useGetCurrentUserInventoryQuery
>;
export type GetCurrentUserInventoryLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserInventoryLazyQuery
>;
export type GetCurrentUserInventorySuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserInventorySuspenseQuery
>;
export type GetCurrentUserInventoryQueryResult = Apollo.QueryResult<
  GetCurrentUserInventoryQuery,
  GetCurrentUserInventoryQueryVariables
>;
