import {
  Box,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  ActivityTypeData,
  TouchpointManagementModeData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityIcon } from '@/components/Icon/ActivityIcon';

import {
  ColumnContentWrapper,
  RequiredField,
  SectionLabel,
  SectionWrapper,
} from '../SharedStyledComponents';
import { BaseFormikProps } from '../types';
import { TouchpointDateAndTime } from './TouchpointDateAndTime';
import { TouchpointStatus } from './TouchpointStatus';
import { TouchpointTypeConfirmationDialog } from './TouchpointTypeConfirmationDialog';

interface TouchpointTypeColumnProps extends BaseFormikProps {
  mode: TouchpointManagementModeData;
  isSubmitting?: boolean;
  // activityTypes are needed when variant === create
  activityTypes?: Array<ActivityTypeData>;
  // activity is needed when variant === edit
  activity?: ActivityData;
}

export const TouchpointTypeColumn = ({
  setFieldValue,
  mode,
  activityTypes,
  activity,
  touchpointValues,
  isSubmitting = false,
}: TouchpointTypeColumnProps) => {
  const [incomingTouchpointType, setIncomingTouchpointType] = useState<
    ActivityTypeData | undefined
  >(undefined);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const { t } = useTranslation();

  const selectedTouchpointType =
    mode === 'edit'
      ? activity?.activityType?.oid ?? ''
      : touchpointValues?.touchpointType?.oid ?? '';

  const touchpointTypeOptions = activityTypes?.map((activityType) => ({
    name: activityType.name,
    value: activityType.oid,
    baseType: activityType.baseType,
  }));

  const selectedTouchpointTypeData =
    mode === 'edit'
      ? activity?.activityType
      : activityTypes?.filter(
          (activityType) => activityType?.oid === selectedTouchpointType
        )[0] ?? {};

  const isPromatSignatureCollected =
    mode === 'edit' && !!activity?.sampleRequestFormDocument?.oid;

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleChangeTouchpointType = (event: SelectChangeEvent) => {
    const { value } = event.target;

    const newTouchpointTypeData = activityTypes?.find(
      (activityType) => activityType.oid === value
    );

    // if there's no touchpointType, or the touchpointType exists, but
    // there are no values for attendees and topics, set the touchpointType
    if (
      !touchpointValues?.touchpointType ||
      (!touchpointValues?.attendees?.length &&
        !touchpointValues?.topics?.length)
    ) {
      setFieldValue?.('touchpointType', newTouchpointTypeData);
    } else {
      // otherwise, show the warning dialog
      setIncomingTouchpointType(newTouchpointTypeData);
      setIsConfirmationDialogOpen(true);
    }
  };

  return (
    <>
      <ColumnContentWrapper>
        {mode === 'create' && (
          <SectionWrapper>
            <SectionLabel variant="subtitle2">
              {t('components.touchpointManagement.touchpointType')}
              <RequiredField>*</RequiredField>
            </SectionLabel>
            <Select
              fullWidth
              data-testid="touchpoint-type-select"
              disabled={isSubmitting}
              value={selectedTouchpointType}
              onChange={(e) =>
                handleChangeTouchpointType(e as SelectChangeEvent)
              }
            >
              {touchpointTypeOptions?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ActivityIcon baseType={option.baseType} />
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </SectionWrapper>
        )}

        {mode === 'edit' && (
          <SectionWrapper>
            <SectionLabel variant="subtitle2">
              {t('components.touchpointManagement.touchpointType')}
            </SectionLabel>
            <LineBox>
              <ActivityIcon baseType={selectedTouchpointTypeData?.baseType} />
              <Typography variant="subtitle2">
                {selectedTouchpointTypeData?.name}
              </Typography>
            </LineBox>
          </SectionWrapper>
        )}

        <SectionWrapper>
          <TouchpointDateAndTime
            touchpointValues={touchpointValues}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            isSelectionDisabled={isPromatSignatureCollected}
            activity={activity}
            mode={mode}
          />
        </SectionWrapper>

        <SectionWrapper>
          <TouchpointStatus
            activity={activity}
            mode={mode}
            touchpointValues={touchpointValues}
            selectedTouchpointType={selectedTouchpointType}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
        </SectionWrapper>
      </ColumnContentWrapper>

      <TouchpointTypeConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        incomingTouchpointType={incomingTouchpointType}
        closeDialog={handleCloseConfirmationDialog}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

const Select = styled(MuiSelect)(({ theme }) => ({
  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const LineBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  gap: theme.spacing(1),
}));
