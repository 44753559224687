import {
  GivenPromotionalMaterialData,
  InventoryData,
  PersonPromotionalMaterialData,
  PromotionalMaterialBatchData,
  PromotionalMaterialData,
  PromotionalMaterialGroupBatchData,
  PromotionalMaterialGroupData,
  PromotionalMaterialGroupStockData,
  PromotionalMaterialTypeDataEnum,
  StockData,
  StockTypeDataEnum,
  TopicData,
} from '@ysura/common';

import { intersectionTopics } from '@/views/Activity/TouchpointManagement/helpers/touchpointTopicHelper';

export const groupGivenPromotionalMaterials = (
  givenPromotionalMaterials: Array<GivenPromotionalMaterialData>
): Array<PromotionalMaterialGroupData> => {
  const promotionalMaterialGroups: Array<PromotionalMaterialGroupData> = [];

  givenPromotionalMaterials.forEach((each: GivenPromotionalMaterialData) => {
    // Check if the material already existing in the promotionalMaterialGroup above
    const existingGroup = promotionalMaterialGroups.find((element) => {
      return element.promotionalMaterial?.oid === each.promotionalMaterial?.oid;
    });

    if (!existingGroup) {
      promotionalMaterialGroups.push({
        promotionalMaterial: each.promotionalMaterial,
        givenQuantityInCurrentPeriod: undefined,
        maximumQuantityInCurrentPeriod: undefined,
        quantity: each.quantity ?? 0,
        requestedQuantity: each.requestedQuantity ?? 0,
        selected: true,
        batches: each.stock
          ? [
              {
                oid: each.stock?.promotionalMaterialBatch?.oid,
                name: each.stock?.promotionalMaterialBatch?.name,
                expiryDate: each.stock?.promotionalMaterialBatch?.expiryDate,
                physicalStock:
                  each.stock?.type === StockTypeDataEnum.PHYSICAL
                    ? {
                        stock: each.stock,
                        quantity: each.quantity ?? 0,
                        requestedQuantity: each.requestedQuantity ?? 0,
                      }
                    : {
                        stock: undefined,
                        quantity: 0,
                        requestedQuantity: 0,
                      },
                virtualStock:
                  each.stock?.type === StockTypeDataEnum.VIRTUAL
                    ? {
                        stock: each.stock,
                        quantity: each.quantity ?? 0,
                        requestedQuantity: each.requestedQuantity ?? 0,
                      }
                    : {
                        stock: undefined,
                        quantity: 0,
                        requestedQuantity: 0,
                      },
              },
            ]
          : [],
      });
    } else {
      existingGroup.requestedQuantity =
        (existingGroup.requestedQuantity ?? 0) + (each.requestedQuantity ?? 0);
      existingGroup.quantity =
        (existingGroup.quantity ?? 0) + (each.quantity ?? 0);
      const foundBatch = existingGroup.batches.find((element) => {
        return element?.oid === each.stock?.promotionalMaterialBatch?.oid;
      });
      if (foundBatch) {
        if (each.stock?.type === StockTypeDataEnum.PHYSICAL) {
          foundBatch.physicalStock = {
            stock: each.stock,
            quantity: each.quantity ?? 0,
            requestedQuantity: each.requestedQuantity ?? 0,
          };
        } else if (each.stock?.type === StockTypeDataEnum.VIRTUAL) {
          foundBatch.virtualStock = {
            stock: each.stock,
            quantity: each.quantity ?? 0,
            requestedQuantity: each.requestedQuantity ?? 0,
          };
        }
      } else {
        existingGroup.batches.push({
          oid: each.stock?.promotionalMaterialBatch?.oid,
          name: each.stock?.promotionalMaterialBatch?.name,
          expiryDate: each.stock?.promotionalMaterialBatch?.expiryDate,
          physicalStock:
            each.stock?.type === StockTypeDataEnum.PHYSICAL
              ? {
                  stock: each.stock,
                  quantity: each.quantity ?? 0,
                  requestedQuantity: each.requestedQuantity ?? 0,
                }
              : {
                  stock: undefined,
                  quantity: 0,
                  requestedQuantity: 0,
                },
          virtualStock:
            each.stock?.type === StockTypeDataEnum.VIRTUAL
              ? {
                  stock: each.stock,
                  quantity: each.quantity ?? 0,
                  requestedQuantity: each.requestedQuantity ?? 0,
                }
              : {
                  stock: undefined,
                  quantity: 0,
                  requestedQuantity: 0,
                },
        });
      }
    }
  });

  return promotionalMaterialGroups;
};

export const getIntersectionTopicsForPromotionalMaterialsAndActivityType = (
  activityTypeTopics: Array<TopicData>,
  promotionalMaterials: Array<PromotionalMaterialGroupData> | undefined
): Array<TopicData> => {
  if (!activityTypeTopics.length || !promotionalMaterials) {
    return [];
  }

  const allPromotionalMaterialsTopics: Array<TopicData> =
    getTopicsFromPromotionalMaterials(promotionalMaterials);

  return intersectionTopics(activityTypeTopics, allPromotionalMaterialsTopics);
};

const getTopicsFromPromotionalMaterials = (
  promotionalMaterials: Array<PromotionalMaterialGroupData>
): Array<TopicData> => {
  return promotionalMaterials.flatMap(
    (p) => p.promotionalMaterial?.topics ?? []
  );
};

export const convertPromotionalMaterials = (
  personPromotionalMaterialsData: Array<PersonPromotionalMaterialData>,
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>,
  promotionalMaterialsWithDistributionType: Array<PromotionalMaterialData>,
  inventory?: InventoryData
): Array<PromotionalMaterialGroupData> => {
  const promotionalMaterialGroups: Array<PromotionalMaterialGroupData> = [];
  if (!personPromotionalMaterialsData) {
    return promotionalMaterialGroups;
  }

  personPromotionalMaterialsData.forEach((each) => {
    const givenItem = selectedPromotionalMaterials.find(
      (item) => item.promotionalMaterial?.oid === each.promotionalMaterial.oid
    );
    const activityTypes = promotionalMaterialsWithDistributionType.find(
      (item) => item.oid === each.promotionalMaterial.oid
    )?.activityTypes;
    const availableQuantity = getAvailableQuantity(
      each,
      givenItem?.quantity ?? 0,
      inventory?.stocks
    );
    promotionalMaterialGroups.push({
      promotionalMaterial: each.promotionalMaterial,
      givenQuantityInCurrentPeriod: each.givenQuantityInCurrentPeriod,
      maximumQuantityInCurrentPeriod: each.maximumQuantityInCurrentPeriod,
      availableQuantityInCurrentActivity: availableQuantity,
      availabilityReason: getAvailabilityReason(each, availableQuantity),
      quantity: givenItem?.quantity ?? 0,
      requestedQuantity: givenItem?.requestedQuantity ?? 0,
      selected: !!givenItem,
      batches: getBatches(
        each,
        selectedPromotionalMaterials,
        activityTypes ? activityTypes[0].stockTypes ?? [] : [],
        inventory?.stocks
      ),
    });
  });

  return promotionalMaterialGroups.filter(
    (each) =>
      each.selected ||
      each.promotionalMaterial?.type ==
        PromotionalMaterialTypeDataEnum.MARKETING_MATERIAL ||
      hasAvailableStocks(each.promotionalMaterial?.oid, inventory?.stocks)
  );
};

const getBatches = (
  item: PersonPromotionalMaterialData,
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>,
  allowedStockTypes: Array<StockTypeDataEnum>,
  stocks?: Array<StockData>
): Array<PromotionalMaterialGroupBatchData> => {
  const promotionalMaterialBatches: Array<PromotionalMaterialGroupBatchData> =
    [];
  if (
    item.promotionalMaterial.type !== PromotionalMaterialTypeDataEnum.PRODUCT
  ) {
    return promotionalMaterialBatches;
  }
  // add the null batch
  const nullBatch = constructBatchData(
    item.promotionalMaterial.oid,
    { oid: undefined, name: '-', expiryDate: undefined },
    selectedPromotionalMaterials,
    allowedStockTypes,
    stocks
  );
  if (nullBatch) {
    promotionalMaterialBatches.push(nullBatch);
  }

  item.promotionalMaterial.batches?.forEach((batch) => {
    const batchData = constructBatchData(
      item.promotionalMaterial.oid,
      batch,
      selectedPromotionalMaterials,
      allowedStockTypes,
      stocks
    );
    if (batchData) {
      promotionalMaterialBatches.push(batchData);
    }
  });

  return promotionalMaterialBatches;
};

const constructBatchData = (
  promotionalMaterialId: string | undefined,
  batch: PromotionalMaterialBatchData,
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>,
  allowedStockTypes: Array<StockTypeDataEnum>,
  stocks?: Array<StockData>
): PromotionalMaterialGroupBatchData | null => {
  const physicalStock = stocks?.find(
    (item) =>
      item.promotionalMaterial?.oid === promotionalMaterialId &&
      item.promotionalMaterialBatch?.oid === batch.oid &&
      item.type === StockTypeDataEnum.PHYSICAL
  );
  if (physicalStock) {
    physicalStock.quantity = allowedStockTypes.find(
      (type) => type === StockTypeDataEnum.PHYSICAL
    )
      ? physicalStock?.quantity
      : 0;
  }
  const virtualStock = stocks?.find(
    (item) =>
      item.promotionalMaterial?.oid === promotionalMaterialId &&
      item.promotionalMaterialBatch?.oid === batch.oid &&
      item.type === StockTypeDataEnum.VIRTUAL
  );
  if (virtualStock) {
    virtualStock.quantity = allowedStockTypes.find(
      (type) => type === StockTypeDataEnum.VIRTUAL
    )
      ? virtualStock?.quantity
      : 0;
  }

  // this batch has no stocks, it's not relevant for the sample drop
  if (!physicalStock?.quantity && !virtualStock?.quantity) {
    return null;
  }

  return {
    oid: batch.oid,
    name: batch.name,
    expiryDate: batch.expiryDate,
    physicalStock: {
      stock: physicalStock,
      quantity:
        getGivenStock(
          promotionalMaterialId,
          batch.oid,
          selectedPromotionalMaterials,
          StockTypeDataEnum.PHYSICAL
        )?.quantity ?? 0,
      requestedQuantity:
        getGivenStock(
          promotionalMaterialId,
          batch.oid,
          selectedPromotionalMaterials,
          StockTypeDataEnum.PHYSICAL
        )?.requestedQuantity ?? 0,
    },
    virtualStock: {
      stock: virtualStock,
      quantity:
        getGivenStock(
          promotionalMaterialId,
          batch.oid,
          selectedPromotionalMaterials,
          StockTypeDataEnum.VIRTUAL
        )?.quantity ?? 0,
      requestedQuantity:
        getGivenStock(
          promotionalMaterialId,
          batch.oid,
          selectedPromotionalMaterials,
          StockTypeDataEnum.VIRTUAL
        )?.requestedQuantity ?? 0,
    },
  };
};

const getGivenStock = (
  promotionalMaterialId: string | undefined,
  batchOid: string | undefined,
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>,
  stockType: StockTypeDataEnum
): PromotionalMaterialGroupStockData | undefined => {
  const found = selectedPromotionalMaterials
    .find((item) =>
      item.batches.find(
        (b) =>
          promotionalMaterialId === item.promotionalMaterial?.oid &&
          b.oid == batchOid
      )
    )
    ?.batches.find((b) => b.oid == batchOid);

  return stockType == StockTypeDataEnum.PHYSICAL
    ? found?.physicalStock
    : found?.virtualStock;
};

const hasAvailableStocks = (
  promotionalMaterialId: string | undefined,
  stocks: Array<StockData> | undefined
): boolean => {
  return !!stocks
    ?.filter((item) => item.promotionalMaterial?.oid === promotionalMaterialId)
    .some((item) => !!item.quantity);
};

const getAvailableQuantity = (
  item: PersonPromotionalMaterialData,
  quantityInThisActivity: number,
  stocks?: Array<StockData>
): number | undefined => {
  const endDateRule = item.promotionalMaterial.rules?.find(
    (each) => each.type === 'endDate'
  );
  if (
    endDateRule &&
    endDateRule.endDate &&
    new Date() < new Date(endDateRule.endDate)
  ) {
    return 0;
  }
  const allStocksForPromotional = stocks
    ?.filter(
      (each) => each.promotionalMaterial?.oid === item.promotionalMaterial.oid
    )
    .map((each) => each.quantity);
  const availableStockQuantity = allStocksForPromotional
    ? allStocksForPromotional?.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) ?? 0
    : undefined;

  const maximumInActivityRule = item.promotionalMaterial.rules?.find(
    (each) => each.type === 'maximumInActivity'
  );
  // From the maximum allowed in the current period subtract the given in any other activity
  const remainingQuantityForPeriod = item.maximumQuantityInCurrentPeriod
    ? item.maximumQuantityInCurrentPeriod +
      quantityInThisActivity -
      (item.givenQuantityInCurrentPeriod ?? 0)
    : item.maximumQuantityInCurrentPeriod;

  const remainingAvailableQuantityInPeriod = maximumInActivityRule
    ? item.maximumQuantityInCurrentPeriod
      ? Math.min(
          remainingQuantityForPeriod ?? 0,
          maximumInActivityRule.maximumQuantity ?? 0
        )
      : maximumInActivityRule.maximumQuantity
    : remainingQuantityForPeriod;

  if (!availableStockQuantity) {
    return remainingAvailableQuantityInPeriod;
  }

  if (remainingAvailableQuantityInPeriod === 0) {
    return remainingAvailableQuantityInPeriod;
  }

  return remainingAvailableQuantityInPeriod
    ? Math.min(remainingAvailableQuantityInPeriod, availableStockQuantity)
    : availableStockQuantity;
};

const getAvailabilityReason = (
  item: PersonPromotionalMaterialData,
  availableQuantity?: number
): string | undefined => {
  const endDateRule = item?.promotionalMaterial?.rules?.find(
    (each) => each.type === 'endDate'
  );
  if (
    endDateRule &&
    endDateRule.endDate &&
    new Date() > new Date(endDateRule.endDate)
  ) {
    return 'endDateReached';
  }

  return availableQuantity === 0 ? 'maximumQuantityReached' : undefined;
};

export const getRequestedQuantity = (
  promat: PromotionalMaterialGroupData
): number | undefined => {
  if (promat.batches.length) {
    return promat.batches
      .map(
        (each) =>
          (each.physicalStock?.requestedQuantity ?? 0) +
          (each.virtualStock?.requestedQuantity ?? 0)
      )
      .reduce(function (a, b) {
        return a + b;
      }, 0);
  } else {
    return promat.requestedQuantity;
  }
};
