import { Box, Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

export const CardWrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: theme.spacing(2),
}));

export const TextWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const MediaDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
