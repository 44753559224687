import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonPromotionalMaterialData } from '@ysura/common';

import { PromotionalMaterial } from '@/components/PromotionalMaterial';

type SampleCardListProps = {
  samples: Array<PersonPromotionalMaterialData>;
};

export const SampleList = ({ samples }: SampleCardListProps) => {
  return (
    <Wrapper>
      {samples.map((sample, index) => (
        <PromotionalMaterial
          key={sample?.promotionalMaterial.oid}
          personPromotionalMaterial={sample}
          isLastSample={index === samples.length - 1}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
