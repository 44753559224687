import { gql } from '@apollo/client';

export const getCurrentUser = gql`
  query getCurrentUser {
    currentUser {
      id
      oid
      username
      email
      person {
        id
        oid
        firstName
        lastName
      }
      preference {
        oid
      }
      allClientPermissions {
        permission
        offline
      }
      allDataPermissions {
        type
      }
    }
  }
`;
