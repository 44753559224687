import { Tab as MuiTab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

type AttendeeScopeTabsProps = {
  tabIndex: number;
  handleTabChange: (event: SyntheticEvent, newValue: number) => void;
};

export const AttendeeScopeTabs = ({
  tabIndex,
  handleTabChange,
}: AttendeeScopeTabsProps) => {
  const { t } = useTranslation();

  return (
    <Tabs
      value={tabIndex}
      data-testid="attendee-search-tabs"
      onChange={handleTabChange}
    >
      <Tab
        label={
          <Typography variant="subtitle2">
            {t('components.touchpointManagement.region')}
          </Typography>
        }
      />
      <Tab
        label={
          <Typography variant="subtitle2">
            {t('components.touchpointManagement.all')}
          </Typography>
        }
      />
    </Tabs>
  );
};

const Tab = styled(MuiTab)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(2),
  },
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));
