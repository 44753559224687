import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { AudioVideoControls } from './AudioVideoControls';
import { AudioVideoSourceSelect } from './AudioVideoSourceSelect';
import { PreviewVideo } from './PreviewVideo';

type AudioVideoSettingsProps = {
  isMobileDevice?: boolean;
  isInDialog?: boolean;
};

export const AudioVideoSettings = ({
  isMobileDevice = false,
  isInDialog = false,
}: AudioVideoSettingsProps) => {
  return (
    <Container data-testid="audio-video-settings">
      {!isMobileDevice && (
        <Wrapper>
          <PreviewVideo />
          <AudioVideoControls />
        </Wrapper>
      )}

      <AudioVideoSourceSelect isInDialog={isInDialog} />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: theme.spacing(2),
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(2),

  background: theme.palette.grey[800],
  borderRadius: Number(theme.shape.borderRadius) * 2,

  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
  },
}));
