import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetMediaByIdQueryVariables = Types.Exact<{
  mediaId: Types.Scalars['ID']['input'];
}>;

export type GetMediaByIdQuery = {
  __typename?: 'Query';
  media?: {
    __typename?: 'Media';
    id: string;
    oid: string;
    name: string;
    description?: string | null;
    mediaType?: Types.SupportedMediaType | null;
    thumbnailDocument?: {
      __typename?: 'Document';
      id: string;
      oid: string;
      name?: string | null;
      contentLink: string;
      mediaType: string;
    } | null;
    latestVersion: {
      __typename?: 'MediaVersion';
      oid: string;
      version?: number | null;
      document?: {
        __typename?: 'Document';
        id: string;
        oid: string;
        name?: string | null;
        contentLink: string;
        mediaType: string;
      } | null;
    };
    topics: {
      __typename?: 'TopicConnection';
      edges?: Array<{
        __typename?: 'TopicEdge';
        node?: {
          __typename?: 'Topic';
          id: string;
          oid: string;
          name: string;
          state?: Types.State | null;
        } | null;
      }> | null;
    };
    keyMessages: {
      __typename?: 'KeyMessageConnection';
      edges?: Array<{
        __typename?: 'KeyMessageEdge';
        node?: {
          __typename?: 'KeyMessage';
          id: string;
          oid: string;
          name?: string | null;
          state: Types.State;
          topics: {
            __typename?: 'TopicConnection';
            edges?: Array<{
              __typename?: 'TopicEdge';
              node?: {
                __typename?: 'Topic';
                id: string;
                oid: string;
                name: string;
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetMediaByIdQuery__
 *
 * To run a query within a React component, call `useGetMediaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaByIdQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetMediaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaByIdQuery,
    GetMediaByIdQueryVariables
  > &
    (
      | { variables: GetMediaByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetMediaByIdQuery, GetMediaByIdQueryVariables>(
    Operations.getMediaById,
    options
  );
}
export function useGetMediaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaByIdQuery,
    GetMediaByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetMediaByIdQuery, GetMediaByIdQueryVariables>(
    Operations.getMediaById,
    options
  );
}
export function useGetMediaByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMediaByIdQuery,
    GetMediaByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<GetMediaByIdQuery, GetMediaByIdQueryVariables>(
    Operations.getMediaById,
    options
  );
}
export type GetMediaByIdQueryHookResult = ReturnType<
  typeof useGetMediaByIdQuery
>;
export type GetMediaByIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaByIdLazyQuery
>;
export type GetMediaByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetMediaByIdSuspenseQuery
>;
export type GetMediaByIdQueryResult = Apollo.QueryResult<
  GetMediaByIdQuery,
  GetMediaByIdQueryVariables
>;
