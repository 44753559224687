import { GivenPromotionalMaterialChangeReasonDataEnum } from '@ysura/common';

import { createEnumMapperFunction } from '@/services/graphql/mappers/utils';
import { GivenPromotionalMaterialChangeReason } from '@/services/graphql/types';

export const mapperToGivenPromotionalMaterialChangeReasonDataEnum =
  createEnumMapperFunction(
    GivenPromotionalMaterialChangeReason,
    GivenPromotionalMaterialChangeReasonDataEnum
  );
