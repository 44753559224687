import {
  AddressData,
  AddressTypeDataEnum,
  OrganizationData,
  OrganizationTypeData,
} from '@ysura/common';

export const getStreetAddress = (address?: AddressData) => {
  return address?.addressLines?.filter(Boolean).join('\n') ?? '';
};

export const getOrganizationAddress = (address?: AddressData) => {
  const street = getStreetAddress(address);
  const city = getPostalCodeCityAddress(address);

  const divider = street ? ', ' : '';

  return `${street}${divider}${city}`;
};

export const getPostalCodeCityAddress = (address?: AddressData): string => {
  if (!address) {
    return '';
  }

  const { postalCode, city } = address;

  if (!postalCode && !city) {
    return '';
  }

  if (postalCode && !city) {
    return postalCode;
  }
  if (city && !postalCode) {
    return city;
  }

  return `${postalCode}, ${city}`;
};

export const getOrganizationDetails = (organization: OrganizationData) => {
  return {
    id: organization.id,
    name: organization.name ?? '',
    type:
      organization.organizationTypes?.find((t: OrganizationTypeData) => t.name)
        ?.name ?? '',
    rootOrganizationName: organization.rootOrganization?.name ?? '',
    address:
      getOrganizationAddress(
        organization.addresses?.find(
          (a: AddressData) => a.addressType === AddressTypeDataEnum.STANDARD
        )
      ) ?? '',
  };
};
