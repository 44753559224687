import { Box, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentActionDataEnum,
  DataPermissionData,
  DataPermissionTypeDataEnum,
  KebabMenuOption,
  LifecycleStatusDataEnum,
  PersonConsentData,
  PersonConsentLifecycleStateDataEnum,
  PersonData,
} from '@ysura/common';
import { includes } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { ConsentActionManager } from '@/components/Consent/ConsentActionManager';
import { useCurrentUser, useInteraction } from '@/hooks';
import { useGetSystemConfigurationQuery } from '@/services/graphql/hooks';
import { parseSystemConfigurationData } from '@/services/graphql/parsers';

import { ConsentHistory } from './ConsentHistory';
import { ConsentInfo } from './ConsentInfo';

type ConsentDialogProps = {
  isOpen: boolean;
  consent: PersonConsentData;
  person: PersonData;
  isInRemoteRoom: boolean;
  isDigitalConsentActionsEnable?: boolean;
  handleClose: () => void;
};

export const ConsentDialog = ({
  isOpen,
  consent,
  person,
  isInRemoteRoom = false,
  isDigitalConsentActionsEnable = true,
  handleClose,
}: ConsentDialogProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { startSharingConsent } = useInteraction();

  const [isConsentActionsOpen, setIsConsentActionsOpen] = useState(false);
  const [isConsentActionType, setIsConsentActionType] =
    useState<ConsentActionDataEnum | null>(null);

  const { data: systemConfigurationData } = useGetSystemConfigurationQuery();
  const systemConfiguration = systemConfigurationData?.systemConfiguration
    ? parseSystemConfigurationData(systemConfigurationData.systemConfiguration)
    : null;

  const hasAssignPermission = includes(
    consent?.consentType?.permissions?.map((it: DataPermissionData) => it.type),
    DataPermissionTypeDataEnum.ASSIGN_CONSENT_TYPE
  );

  const canCollectConsent =
    currentUser?.permissions?.consent?.canCollectConsent &&
    hasAssignPermission &&
    consent.consentType?.lifecycleStatus === LifecycleStatusDataEnum.ACTIVE &&
    (consent?.lifecycleState === PersonConsentLifecycleStateDataEnum.DEFAULT ||
      consent?.consentType?.allowRecollection);

  const canCollectDigitalConsent =
    isDigitalConsentActionsEnable &&
    consent?.consentType?.digitalSignatureProcessEnabled &&
    canCollectConsent;

  const canRejectConsent =
    currentUser?.permissions?.consent?.canCollectConsent &&
    hasAssignPermission &&
    consent.consentType?.lifecycleStatus === LifecycleStatusDataEnum.ACTIVE &&
    consent?.lifecycleState === PersonConsentLifecycleStateDataEnum.DEFAULT;

  const canRevokeConsent =
    currentUser?.permissions?.consent?.canCollectConsent &&
    hasAssignPermission &&
    consent.consentType?.lifecycleStatus === LifecycleStatusDataEnum.ACTIVE &&
    consent?.lifecycleState === PersonConsentLifecycleStateDataEnum.ACCEPTED;

  const canResetConsent =
    currentUser?.permissions?.consent?.canResetConsent &&
    currentUser?.dataPermissions?.consent?.canResetConsent &&
    consent?.lifecycleState === PersonConsentLifecycleStateDataEnum.REJECTED;

  const canResendDoubleOptIn =
    currentUser?.permissions?.consent?.canResendDoubleOptIn &&
    consent.consentType?.lifecycleStatus === LifecycleStatusDataEnum.ACTIVE &&
    currentUser?.dataPermissions?.consent?.canEditConsent &&
    consent?.lifecycleState ===
      PersonConsentLifecycleStateDataEnum.PENDING_DOUBLE_OPT_IN;

  const canCollectManualConsent =
    consent?.consentType?.manualProcessEnabled && canCollectConsent;

  const canEditConsentItems =
    currentUser?.dataPermissions?.consent?.canEditConsent &&
    consent?.consentType?.lifecycleStatus === LifecycleStatusDataEnum.ACTIVE &&
    (consent?.lifecycleState === PersonConsentLifecycleStateDataEnum.ACCEPTED ||
      consent?.lifecycleState ===
        PersonConsentLifecycleStateDataEnum.PENDING_PAPER ||
      consent?.lifecycleState ===
        PersonConsentLifecycleStateDataEnum.PENDING_DOUBLE_OPT_IN);

  const canCollectPaperConsent =
    !isInRemoteRoom &&
    consent?.consentType?.paperProcessEnabled &&
    canCollectConsent;

  const handleOpenCollectDigital = () => {
    if (isInRemoteRoom) {
      // Case 1: Collect consent for Remote interaction
      startSharingConsent({
        consent,
        validation: {
          defaultCountryPhone: systemConfiguration?.defaultCountry,
        },
      });
    } else {
      // Case 2: Collect consent for F2F interaction
      handleClickedConsentAction(ConsentActionDataEnum.COLLECT_DIGITAL_CONSENT);
    }
  };

  const handleClickedConsentAction = (action: ConsentActionDataEnum) => {
    setIsConsentActionsOpen(true);
    setIsConsentActionType(action);
  };

  const handleCloseConsentActionManagerDialog = () => {
    setIsConsentActionsOpen(false);
    handleClose();
  };

  const handleOpenCollectManual = () => {
    handleClickedConsentAction(ConsentActionDataEnum.COLLECT_MANUAL_CONSENT);
  };

  const handleOpenEditConsentItems = () => {
    handleClickedConsentAction(ConsentActionDataEnum.SET_CONSENT_ITEMS);
  };

  const handleOpenCollectPaperConsent = () => {
    handleClickedConsentAction(ConsentActionDataEnum.COLLECT_PAPER_CONSENT);
  };

  const kebabEntries: Array<KebabMenuOption> = [];

  if (canCollectDigitalConsent) {
    kebabEntries.push({
      entry:
        PersonConsentLifecycleStateDataEnum.DEFAULT === consent.lifecycleState
          ? t('components.consentDialog.action.collectDigital')
          : t('components.consentDialog.action.reCollectDigital'),
      colorType: 'inherit',
      onClick: handleOpenCollectDigital,
    });
  }

  if (canCollectManualConsent) {
    kebabEntries.push({
      entry:
        PersonConsentLifecycleStateDataEnum.DEFAULT === consent.lifecycleState
          ? t('components.consentDialog.action.collectManual')
          : t('components.consentDialog.action.reCollectManual'),
      colorType: 'inherit',
      onClick: handleOpenCollectManual,
    });
  }

  if (canCollectPaperConsent) {
    kebabEntries.push({
      entry:
        PersonConsentLifecycleStateDataEnum.DEFAULT === consent.lifecycleState
          ? t('components.consentDialog.action.collectPaper')
          : t('components.consentDialog.action.reCollectPaper'),
      colorType: 'inherit',
      onClick: handleOpenCollectPaperConsent,
    });
  }

  if (canEditConsentItems) {
    kebabEntries.push({
      entry: t('components.consentDialog.action.editConsent'),
      colorType: 'inherit',
      onClick: handleOpenEditConsentItems,
    });
  }

  if (canRejectConsent) {
    kebabEntries.push({
      entry: t('components.consentDialog.action.reject'),
      colorType: 'inherit',
      onClick: () =>
        handleClickedConsentAction(ConsentActionDataEnum.REJECT_CONSENT),
    });
  }

  if (canRevokeConsent) {
    kebabEntries.push({
      entry: t('components.consentDialog.action.revoke'),
      colorType: 'inherit',
      onClick: () =>
        handleClickedConsentAction(ConsentActionDataEnum.REVOKE_CONSENT),
    });
  }

  if (canResetConsent) {
    kebabEntries.push({
      entry: t('components.consentDialog.action.reset'),
      colorType: 'inherit',
      onClick: () =>
        handleClickedConsentAction(ConsentActionDataEnum.RESET_CONSENT),
    });
  }

  if (canResendDoubleOptIn) {
    kebabEntries.push({
      entry: t('components.consentDialog.action.resendDoubleOptIn'),
      colorType: 'inherit',
      onClick: () =>
        handleClickedConsentAction(ConsentActionDataEnum.RESEND_DOUBLE_OPT_IN),
    });
  }

  return (
    <>
      <ActionDialog
        isOpen={isOpen}
        maxWidth="lg"
        testId="consent-dialog"
        title={consent?.consentType?.name ?? '-'}
        primaryButtonText={t('components.common.close')}
        kebabMenuEntries={kebabEntries.length ? kebabEntries : undefined}
        onClickPrimaryButton={handleClose}
      >
        <DialogContent>
          <Grid container spacing={{ md: 3 }} direction="row">
            <StyledGrid item xs={12} md={8}>
              <ConsentInfo consent={consent} />
            </StyledGrid>

            <Grid item xs={12} md={4}>
              <BigStack>
                <ConsentHistory consentHistory={consent?.consentHistory} />
              </BigStack>
            </Grid>
          </Grid>
        </DialogContent>
      </ActionDialog>

      {isConsentActionsOpen && (
        <ConsentActionManager
          isInRemoteRoom={isInRemoteRoom}
          person={person}
          consent={consent}
          consentActionType={isConsentActionType}
          defaultCountry={systemConfiguration?.defaultCountry}
          onConfirmAction={handleCloseConsentActionManagerDialog}
          onCloseDialog={() => setIsConsentActionsOpen(false)}
        />
      )}
    </>
  );
};

const DialogContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
  overflowY: 'auto',
  height: '100%',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    height: '67vh',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    borderRight: '1px solid',
    borderColor: theme.palette.divider,
    paddingBottom: theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    borderBottom: '1px solid',
    borderColor: theme.palette.divider,
    marginBottom: theme.spacing(2),
  },
}));

const BigStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
