import Keycloak from 'keycloak-js';

import { KEYCLOAK } from '@/config';
import { getKeycloakRealm, getKeycloakUrl } from '@/utils/hostUtils';

export const keycloak = new Keycloak({
  realm: getKeycloakRealm(),
  url: getKeycloakUrl(),
  clientId: KEYCLOAK.CLIENT_ID,
});
