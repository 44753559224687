import { SettingsButton } from '@ysura/common';
import { useNavigate } from 'react-router-dom';

import {
  checkPersonFilterPermission,
  FilterPermissionData,
} from '@/components/Settings/helper';
import { useCurrentUser } from '@/hooks';

type PersonSettingsButtonProps = {
  onClick: VoidFunction;
};
export const PersonSettingsButton = ({
  onClick,
}: PersonSettingsButtonProps) => {
  const navigate = useNavigate();

  const handleClickSettingButton = () => {
    navigate(`/settings/person`);
    onClick?.();
  };

  // Check permission to visualize settings for persons
  const { currentUser } = useCurrentUser();
  const personPermission: FilterPermissionData = checkPersonFilterPermission(
    currentUser?.permissions?.person
  );

  if (personPermission?.numAvailablePermission === 0) {
    return <></>;
  }

  return (
    <SettingsButton
      color="inherit"
      testId="person-filter-setting-button"
      onClick={handleClickSettingButton}
    />
  );
};
