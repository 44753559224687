import type Keycloak from 'keycloak-js';
import { PureComponent } from 'react';
import isEqual from 'react-fast-compare';

import { KeycloakContext } from './keycloakContext';
import {
  AuthClientError,
  AuthClientEvent,
  AuthClientInitOptions,
  AuthProviderProps,
} from './types';

type AuthProviderState = {
  initialized: boolean;

  isAuthenticated: boolean;

  isLoading: boolean;
};

const defaultInitOptions: AuthClientInitOptions = {
  onLoad: 'check-sso',
};

const initialState: AuthProviderState = {
  initialized: false,
  isAuthenticated: false,
  isLoading: true,
};

export class KeycloakProvider extends PureComponent<
  AuthProviderProps,
  AuthProviderState
> {
  state = {
    ...initialState,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate({
    authClient: prevAuthClient,
    initOptions: prevInitOptions,
  }: AuthProviderProps) {
    const { initOptions, authClient } = this.props;
    if (
      authClient !== prevAuthClient ||
      !isEqual(initOptions, prevInitOptions)
    ) {
      // De-init previous AuthClient instance
      prevAuthClient.onReady = undefined;
      prevAuthClient.onAuthSuccess = undefined;
      prevAuthClient.onAuthError = undefined;
      prevAuthClient.onAuthRefreshSuccess = undefined;
      prevAuthClient.onAuthRefreshError = undefined;
      prevAuthClient.onAuthLogout = undefined;
      prevAuthClient.onTokenExpired = undefined;

      // Reset state
      this.setState({ ...initialState });
      // Init new AuthClient instance
      this.init();
    }
  }

  init() {
    const { initOptions, authClient } = this.props;

    // Attach Keycloak listeners
    authClient.onReady = this.updateState('onReady');
    authClient.onAuthSuccess = this.updateState('onAuthSuccess');
    authClient.onAuthError = this.onError('onAuthError');
    authClient.onAuthRefreshSuccess = this.updateState('onAuthRefreshSuccess');
    authClient.onAuthRefreshError = this.onError('onAuthRefreshError');
    authClient.onAuthLogout = this.updateState('onAuthLogout');
    authClient.onTokenExpired = this.refreshToken('onTokenExpired');

    authClient
      .init({ ...defaultInitOptions, ...initOptions })
      .catch(this.onError('onInitError'));
  }

  onError = (event: AuthClientEvent) => (error?: AuthClientError) => {
    const { onEvent } = this.props;
    // Notify Events listener
    onEvent && onEvent(event, error);
  };

  updateState = (event: AuthClientEvent) => () => {
    const { authClient, onEvent, onTokens, isLoadingCheck } = this.props;
    const {
      initialized: prevInitialized,
      isAuthenticated: prevAuthenticated,
      isLoading: prevLoading,
    } = this.state;

    // Notify Events listener
    onEvent && onEvent(event);

    // Check Loading state
    const isLoading = isLoadingCheck ? isLoadingCheck(authClient) : false;

    // Check if user is authenticated
    const isAuthenticated = isUserAuthenticated(authClient);

    // Avoid double-refresh if state hasn't changed
    if (
      !prevInitialized ||
      isAuthenticated !== prevAuthenticated ||
      isLoading !== prevLoading
    ) {
      this.setState({
        initialized: true,
        isAuthenticated,
        isLoading,
      });
    }

    // Notify token listener, if any
    const { idToken, refreshToken, token } = authClient;
    onTokens &&
      onTokens({
        idToken,
        refreshToken,
        token,
      });
  };

  refreshToken = (event: AuthClientEvent) => () => {
    const { autoRefreshToken, authClient, onEvent } = this.props;
    // Notify Events listener
    onEvent && onEvent(event);

    if (autoRefreshToken !== false) {
      // Refresh Keycloak token
      authClient.updateToken(5);
    }
  };

  render() {
    const { children, authClient, LoadingComponent } = this.props;
    const { initialized, isLoading } = this.state;

    if (!!LoadingComponent && (!initialized || isLoading)) {
      return LoadingComponent;
    }

    return (
      <KeycloakContext.Provider value={{ initialized, authClient }}>
        {children}
      </KeycloakContext.Provider>
    );
  }
}

function isUserAuthenticated(authClient: Keycloak) {
  return !!authClient.idToken && !!authClient.token;
}
