import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';
import { ReactNode } from 'react';

import { VideoAndControlsWrapper } from '@/components/Interaction';
import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';

import { Video, VideoControls } from '../Video';
import { ControlsSkeleton } from '../Video/ControlsSkeleton';
import { VideoSkeleton } from '../Video/VideoSkeleton';

type VideoAndControlsProps = {
  isDrawerOpen: boolean;
  isOrganizer: boolean;
  renderVideoControlsMobile: () => ReactNode;
  activity?: ActivityData | null;
  isLoading?: boolean;
  openSettingsDialog: VoidFunction;
  openCallInfoDialog: VoidFunction;
};

export const VideoAndControls = ({
  isDrawerOpen,
  isOrganizer,
  activity,
  renderVideoControlsMobile,
  isLoading = false,
  openSettingsDialog,
  openCallInfoDialog,
}: VideoAndControlsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAnyContentShared } = useInteraction();

  const firstAttendee = activity?.attendees?.[0];

  // if isSharing & isMobile are both true, we should always open the drawer
  const shouldOpenDrawer = isDrawerOpen || (isMobile && isAnyContentShared);

  const controls = () => {
    if (isMobile) {
      return renderVideoControlsMobile();
    }

    if (isLoading) {
      return <ControlsSkeleton />;
    }

    return (
      <VideoControls
        isOrganizer={isOrganizer}
        openSettingsDialog={openSettingsDialog}
        openCallInfoDialog={openCallInfoDialog}
      />
    );
  };

  return (
    <VideoAndControlsWrapper
      isDrawerOpen={shouldOpenDrawer}
      drawerBleeding={REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_PORTRAIT}
      flex={1}
    >
      <VideoWrapper
        isDrawerOpen={shouldOpenDrawer}
        data-testid="collapsable-video-wrapper"
      >
        {isLoading ? (
          <VideoSkeleton />
        ) : (
          <Video
            attendee={firstAttendee}
            organizer={activity?.organizer}
            coOrganizers={activity?.coOrganizers ?? []}
          />
        )}
      </VideoWrapper>

      {/* These controls will not be visible for mobile landscape */}
      <ControlsWrapper data-testid="non-mobile-landscape-controls">
        {controls()}
      </ControlsWrapper>
    </VideoAndControlsWrapper>
  );
};

type VideoWrapperStyleProps = {
  isDrawerOpen: boolean;
};

const VideoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<VideoWrapperStyleProps>(({ isDrawerOpen }) => ({
  height: isDrawerOpen ? 0 : '100%',
  visibility: isDrawerOpen ? 'hidden' : 'visible',
  overflow: 'hidden',
}));

const ControlsWrapper = styled(Box)({
  width: '100%',
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
});
