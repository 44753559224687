import { Card, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  ActivityTypeStack,
  CardHeaderStack,
  StyledCardContent,
  StyledCardHeader,
} from './ActivityDetailStyles';

export const ActivityDetailSkeletonCard = () => {
  return (
    <Card data-testid="touchpoint-detail-skeleton-card">
      <StyledCardHeader
        title={
          <CardHeaderStack>
            <ActivityTypeStack>
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton width={64} height={22} />
            </ActivityTypeStack>

            <Skeleton width={64} height={22} />
          </CardHeaderStack>
        }
        titleTypographyProps={{
          color: 'text.primary',
          variant: 'subtitle2',
        }}
        padding={3}
      />

      <StyledCardContent padding={3}>
        <Skeleton width={200} height={22} />

        <SmallStack>
          <Skeleton width={80} height={18} />
          <Skeleton width={160} height={22} />
        </SmallStack>

        <SmallStack>
          <Skeleton width={80} height={18} />
          <Skeleton width={160} height={22} />
        </SmallStack>

        <SmallStack>
          <Skeleton width={80} height={18} />
          <Skeleton width={160} height={18} />
          <Skeleton width={160} height={18} />
        </SmallStack>

        <SmallStack>
          <Skeleton width={160} height={18} />
          <Skeleton width={120} height={18} />
        </SmallStack>
      </StyledCardContent>
    </Card>
  );
};

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));
