import { Card, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useState } from 'react';

import { AttendeeCard } from '@/components/Attendee/AttendeeCard';
import { AttendeesListProps } from '@/components/Attendee/AttendeeType';
import { MoreItemsButton } from '@/components/Button/MoreItemsButton/MoreItemsButton';
import { GuestItem } from '@/components/PersonData';
import { OverviewStaticBlock } from '@/components/StaticBlock';

//TODO check and move this
const MAX_SHOWN_ATTENDEES = 2;
const PATH_TO_PERSON = '/person/';

export const AttendeesCardList = ({ title, attendees }: AttendeesListProps) => {
  const [isShowAllAttendees, setIsShowAllAttendees] = useState(false);

  const isMediaQueryMatched = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const handleClickMoreOrLessItemsButton = () => {
    setIsShowAllAttendees((prev) => !prev);
  };

  const displayAttendees =
    !isShowAllAttendees && isMediaQueryMatched
      ? attendees?.slice(0, MAX_SHOWN_ATTENDEES)
      : attendees;

  const activityAttendees = displayAttendees?.filter(
    (attendee) => !attendee.isGuest
  );

  const guests = displayAttendees?.filter((attendee) => attendee.isGuest);

  const isMoreButtonDisplayed =
    isMediaQueryMatched && (attendees ?? []).length > MAX_SHOWN_ATTENDEES;

  return (
    <OverviewStaticBlock title={title} testId="attendees-list">
      <Wrapper>
        {activityAttendees?.map(
          ({ oid, person }) =>
            person && (
              <AttendeeCard
                key={oid}
                attendee={person}
                pathToNavigate={`${PATH_TO_PERSON}${person.id}`}
              />
            )
        )}
        {guests?.map((guest) => <GuestItem key={guest.oid}></GuestItem>)}
        {isMoreButtonDisplayed && (
          <MoreItemsButton
            showMore={isShowAllAttendees}
            onClick={handleClickMoreOrLessItemsButton}
          />
        )}
      </Wrapper>
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));
