import { Box, Card, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const PromatCardSkeleton = () => {
  return (
    <PromatCard data-testid="promat-card-skeleton">
      <SmallBox>
        <Skeleton variant="circular" width={20} height={20} />
        <Skeleton width={200} height={22} />
      </SmallBox>
      <Skeleton width={80} height={22} />
    </PromatCard>
  );
};

export const PromotionalMaterialListSkeleton = () => {
  return (
    <MediumStack data-testid="promat-list-skeleton">
      <PromatCardSkeleton />
      <PromatCardSkeleton />
      <PromatCardSkeleton />
      <PromatCardSkeleton />
      <PromatCardSkeleton />
    </MediumStack>
  );
};

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const PromatCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'column',
  padding: theme.spacing(2),
}));
