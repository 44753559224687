import { OrganizationData } from '@ysura/common';

import {
  DesktopOrganizationEmploymentDialog,
  MobileOrganizationEmploymentDialog,
} from '@/components/Organization/Employment';

interface OrganizationEmploymentDialogProps {
  isOpen: boolean;
  isMobile?: boolean;
  onClose: VoidFunction;
  organization: OrganizationData;
}
export const OrganizationEmploymentDialog = ({
  isOpen,
  isMobile,
  onClose,
  organization,
}: OrganizationEmploymentDialogProps) => {
  return (
    <>
      {isMobile ? (
        <MobileOrganizationEmploymentDialog
          isOpen={isOpen}
          organization={organization}
          onClose={onClose}
        />
      ) : (
        <DesktopOrganizationEmploymentDialog
          isOpen={isOpen}
          organization={organization}
          onClose={onClose}
        />
      )}
    </>
  );
};
