import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonByIdQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID']['input'];
  employeesLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetPersonByIdQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    oid: string;
    firstName?: string | null;
    lastName?: string | null;
    customerReference?: string | null;
    gender?: Types.Gender | null;
    birthday?: string | null;
    state: Types.State;
    prefixAcademicTitle?: {
      __typename?: 'AcademicTitle';
      name?: string | null;
      state: Types.State;
    } | null;
    postfixAcademicTitle?: {
      __typename?: 'AcademicTitle';
      name?: string | null;
      state: Types.State;
    } | null;
    employments: {
      __typename?: 'EmploymentConnection';
      filteredCount: number;
      edges?: Array<{
        __typename?: 'EmploymentEdge';
        node?: {
          __typename?: 'Employment';
          id: string;
          oid: string;
          sortCode?: number | null;
          function?: {
            __typename?: 'EmployeeFunction';
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'EmployeeDepartment';
            id: string;
            name?: string | null;
          } | null;
          communicationData: {
            __typename?: 'CommunicationDataConnection';
            edges?: Array<{
              __typename?: 'CommunicationDataEdge';
              node?: {
                __typename?: 'CommunicationData';
                value?: string | null;
                communicationDataType: {
                  __typename?: 'CommunicationDataType';
                  baseType?: Types.CommunicationDataBaseType | null;
                  name?: string | null;
                };
              } | null;
            }> | null;
          };
          addresses: {
            __typename?: 'EntityAddressConnection';
            edges?: Array<{
              __typename?: 'EntityAddressEdge';
              node?: {
                __typename?: 'EntityAddress';
                addressType: Types.AddressType;
                address: {
                  __typename?: 'Address';
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  city: string;
                  postalCode: string;
                  state: Types.State;
                };
              } | null;
            }> | null;
          };
          organization: {
            __typename?: 'Organization';
            id: string;
            oid: string;
            name?: string | null;
            addresses: {
              __typename?: 'EntityAddressConnection';
              edges?: Array<{
                __typename?: 'EntityAddressEdge';
                node?: {
                  __typename?: 'EntityAddress';
                  addressType: Types.AddressType;
                  address: {
                    __typename?: 'Address';
                    addressLine1?: string | null;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    city: string;
                    postalCode: string;
                    state: Types.State;
                  };
                } | null;
              }> | null;
            };
            communicationData: {
              __typename?: 'CommunicationDataConnection';
              edges?: Array<{
                __typename?: 'CommunicationDataEdge';
                node?: {
                  __typename?: 'CommunicationData';
                  value?: string | null;
                  communicationDataType: {
                    __typename?: 'CommunicationDataType';
                    baseType?: Types.CommunicationDataBaseType | null;
                    name?: string | null;
                  };
                } | null;
              }> | null;
            };
            rootOrganization?: {
              __typename?: 'Organization';
              id: string;
              oid: string;
              name?: string | null;
            } | null;
          };
        } | null;
      }> | null;
    };
    personTypes: {
      __typename?: 'PersonTypeConnection';
      edges?: Array<{
        __typename?: 'PersonTypeEdge';
        node?: { __typename?: 'PersonType'; name?: string | null } | null;
      }> | null;
    };
    specialities: {
      __typename?: 'PersonSpecialityConnection';
      edges?: Array<{
        __typename?: 'PersonSpecialityEdge';
        node?: {
          __typename?: 'PersonSpeciality';
          speciality?: {
            __typename?: 'Speciality';
            name?: string | null;
          } | null;
        } | null;
      }> | null;
    };
    specialityGroup?: {
      __typename?: 'SpecialityGroup';
      id: string;
      oid: string;
      name?: string | null;
    } | null;
    promotionalMaterials: {
      __typename?: 'PersonPromotionalMaterialConnection';
      edges?: Array<{
        __typename?: 'PersonPromotionalMaterialEdge';
        node?: {
          __typename?: 'PersonPromotionalMaterial';
          givenQuantityInCurrentPeriod: number;
          maximumQuantityInCurrentPeriod?: number | null;
          promotionalMaterial: {
            __typename?: 'PromotionalMaterial';
            id: string;
            oid: string;
          };
        } | null;
      }> | null;
    };
    touchPointStatistics: Array<{
      __typename?: 'PersonTouchPointStatistics';
      currentPlan?: number | null;
      currentPlanExecution?: number | null;
      touchPointGroup: {
        __typename?: 'TouchPointGroup';
        id: string;
        oid: string;
        name: string;
        state: Types.State;
      };
    } | null>;
    communicationData: {
      __typename?: 'CommunicationDataConnection';
      edges?: Array<{
        __typename?: 'CommunicationDataEdge';
        node?: {
          __typename?: 'CommunicationData';
          value?: string | null;
          communicationDataType: {
            __typename?: 'CommunicationDataType';
            baseType?: Types.CommunicationDataBaseType | null;
            name?: string | null;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetPersonByIdQuery__
 *
 * To run a query within a React component, call `useGetPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonByIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      employeesLimit: // value for 'employeesLimit'
 *   },
 * });
 */
export function useGetPersonByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonByIdQuery,
    GetPersonByIdQueryVariables
  > &
    (
      | { variables: GetPersonByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(
    Operations.getPersonById,
    options
  );
}
export function useGetPersonByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonByIdQuery,
    GetPersonByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(
    Operations.getPersonById,
    options
  );
}
export function useGetPersonByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonByIdQuery,
    GetPersonByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonByIdQuery,
    GetPersonByIdQueryVariables
  >(Operations.getPersonById, options);
}
export type GetPersonByIdQueryHookResult = ReturnType<
  typeof useGetPersonByIdQuery
>;
export type GetPersonByIdLazyQueryHookResult = ReturnType<
  typeof useGetPersonByIdLazyQuery
>;
export type GetPersonByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonByIdSuspenseQuery
>;
export type GetPersonByIdQueryResult = Apollo.QueryResult<
  GetPersonByIdQuery,
  GetPersonByIdQueryVariables
>;
