import {
  ActivityData,
  ActivityStatusDataEnum,
  NoItemPlaceholder,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { KeyMessagesList } from '@/components/KeyMessagesList';
import { OverviewStaticBlock } from '@/components/StaticBlock';

type KeyMessagesStaticBlockProps = {
  activity: ActivityData;
};

export const KeyMessagesStaticBlock = ({
  activity,
}: KeyMessagesStaticBlockProps) => {
  const { t } = useTranslation();

  const isPlannedTouchpoint =
    activity?.status === ActivityStatusDataEnum.PLANNED;

  return (
    <OverviewStaticBlock title={t('pages.activity.keyMessages')}>
      {activity?.media?.length ? (
        <KeyMessagesList
          media={activity?.media}
          isDisplayReactions={!isPlannedTouchpoint}
        />
      ) : (
        <NoItemPlaceholder>
          {t('pages.activity.noKeyMessage')}
        </NoItemPlaceholder>
      )}
    </OverviewStaticBlock>
  );
};
