import { Button, Link } from '@mui/material';
import { ErrorPage } from '@ysura/common';
import { Trans, useTranslation } from 'react-i18next';

import UnknownErrorImage from '@/assets/unknownError.svg';

export const UnknownError = () => {
  const { t } = useTranslation();

  const subtitle = (
    <Trans
      i18nKey="components.error.unknownErrorMessage"
      values={{
        service: t('components.error.customerService'),
      }}
      components={{
        Link: <Link href="mailto:service@ysura.com" target="_blank" />,
      }}
    />
  );
  const actionButton = (
    <Button variant="contained" onClick={() => window.location.reload()}>
      {t('components.error.reload')}
    </Button>
  );

  return (
    <ErrorPage
      title={t('components.error.unknownError')}
      subtitleLine1={subtitle}
      imageFile={UnknownErrorImage}
      actionButton={actionButton}
    />
  );
};
