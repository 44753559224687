import { useQuery } from '@tanstack/react-query';
import { DocumentData } from '@ysura/common';
import axios from 'axios';

export enum DocumentType {
  PDF = 'application/pdf',
  HTML = 'text/html',
  IMAGE_PNG = 'image/png',
  IMAGE_JPG = 'image/jpeg',
}

export type DownloadedDocument =
  | {
      data: ArrayBuffer;
      mediaType:
        | DocumentType.PDF
        | DocumentType.IMAGE_JPG
        | DocumentType.IMAGE_PNG;
    }
  | {
      data: string;
      mediaType: DocumentType.HTML;
    };

const getDocument = async (
  document: DocumentData | null
): Promise<DownloadedDocument> => {
  if (document?.mediaType === DocumentType.HTML) {
    const { data } = await axios.get<string>(`${document?.contentLink}`, {
      responseType: 'text',
    });

    return { data, mediaType: DocumentType.HTML };
  } else if (
    document?.mediaType === DocumentType.IMAGE_PNG ||
    document?.mediaType === DocumentType.IMAGE_JPG ||
    document?.mediaType === DocumentType.PDF
  ) {
    const { data } = await axios.get<ArrayBuffer>(`${document?.contentLink}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: document.mediaType,
      },
    });

    return { data, mediaType: document?.mediaType };
  } else {
    throw new Error('Unknown document type');
  }
};

/**
 * __useGetDocument__
 *
 * To run a query within a React component, call `useGetDocument` and pass it any options that fit your needs.
 *
 * When your component renders, `useGetDocument` returns an object from React Query that contains isLoading, error, and data properties
 * you can use to render your UI.
 *
 * @param document the document content link that should be downloaded.
 *
 * @returns The data blob of the requested document.
 *
 * @example
 * const { data, isLoading, error } = useGetDocument('5c47c494-9360-44e4-9a4a-145a740b1278');
 */
export function useGetDocument(document: DocumentData | null) {
  return useQuery(['document', document], () => getDocument(document), {
    enabled: !!document?.contentLink,
    refetchOnWindowFocus: false,
  });
}
