import { AttendeeData } from '@ysura/common';

import { isAttendeeGuest } from '@/services/activities';
import { parsePersonData } from '@/services/graphql/parsers';
import {
  ActivityAttendee,
  ActivityAttendeeConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseAttendeeData = (
  attendee: DeepPartial<ActivityAttendee>
): AttendeeData => {
  return {
    id: attendee?.id,
    oid: attendee?.oid,
    person: attendee?.person ? parsePersonData(attendee.person) : undefined,
    isGuest: isAttendeeGuest(attendee),
    guestName: attendee?.guestName,
  };
};

export const parseAttendeeConnection = (
  attendeeConnection?: DeepPartial<ActivityAttendeeConnection>
): Array<AttendeeData> => {
  const rawAttendees = attendeeConnection?.edges ?? [];
  const mappedAttendees = rawAttendees.map((edge) =>
    edge?.node ? parseAttendeeData(edge.node) : null
  );

  return filterNonNull(mappedAttendees);
};
