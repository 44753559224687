import { gql } from '@apollo/client';

export const getTouchpointsByOrgId = gql`
  query getTouchpointsByOrgId($organizationId: OrganizationId) {
    touchpoints(filter: { organization: { oid: { _eq: $organizationId } } }) {
      totalCount
      filteredCount
      edges {
        node {
          id
          oid
          activityType {
            id
            oid
            name
            baseType
          }
          startDate
          startDateTime
          endDate
          endDateTime
          state
          status
          organizer {
            prefixAcademicTitle {
              name
              state
            }
            postfixAcademicTitle {
              name
              state
            }
            firstName
            middleName
            lastName
          }
          organization {
            id
            name
            organizationTypes {
              edges {
                node {
                  name
                }
              }
            }
            rootOrganization {
              id
              name
            }
          }
          attendees {
            guestName
            person {
              firstName
              middleName
              lastName
              employments {
                edges {
                  node {
                    organization {
                      name
                      addresses {
                        edges {
                          node {
                            address {
                              id
                              oid
                              addressLine1
                              addressLine2
                              addressLine3
                              city
                              postalCode
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
