import { ActivityData, PromotionalMaterialGroupData } from '@ysura/common';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { PromotionalMaterialEditDialog } from '@/components/PromotionalMaterial';
import { useInteraction } from '@/hooks';

import { haveSignatureRelevantMaterialsChanged } from './utils';

type PromotionalMaterialExpandDialogProps = {
  activity: ActivityData;
  isRemoteInteraction?: boolean;
  isAllowedToSign?: boolean;
  isRestrictedEdit?: boolean;
  isOpen: boolean;
  givenPromotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  onChangePromats: (value: PromotionalMaterialGroupData[]) => void;
  onClose: VoidFunction;
  openCollectionDialog: VoidFunction;
  handleSignFormInRemote: VoidFunction;
};

export const PromotionalMaterialExpandDialog = ({
  activity,
  isRemoteInteraction = false,
  isAllowedToSign = false,
  isRestrictedEdit = false,
  isOpen,
  givenPromotionalMaterialGroups,
  onChangePromats,
  onClose,
  openCollectionDialog,
  handleSignFormInRemote,
}: PromotionalMaterialExpandDialogProps) => {
  const { t } = useTranslation();
  const { setSignedSampleRequestForm, signedSampleRequestForm } =
    useInteraction();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [promotionalMaterialGroups, setPromotionalMaterialGroups] = useState<
    Array<PromotionalMaterialGroupData>
  >(givenPromotionalMaterialGroups);

  // This is needed in order to be able to compare changed promotional materials and if form needs to be signed again
  const [prevPromotionalMaterialGroups] = useState<
    Array<PromotionalMaterialGroupData>
  >(cloneDeep(promotionalMaterialGroups));

  const handleClickDone = () => {
    onChangePromats(promotionalMaterialGroups);

    const filteredPromatGroups = promotionalMaterialGroups.filter(
      (group) =>
        !!group.requestedQuantity && !!group?.availableQuantityInCurrentActivity
    );
    onChangePromats(filteredPromatGroups);

    const hasSignatureRelevantMaterialsChanged =
      haveSignatureRelevantMaterialsChanged(
        promotionalMaterialGroups,
        prevPromotionalMaterialGroups
      );
    // we need to reset the sampleRequestForm as the old one is not valid because promotional materials are changed
    if (hasSignatureRelevantMaterialsChanged) {
      activity.sampleRequestFormDocument = null;
      setSignedSampleRequestForm?.(null);
    }

    // If there are still promotional materials which require signature selected and any of them is changed
    // then we need to invalidate the sample request form and ask the user to collect a new signature
    const showSignatureConfirmationDialog =
      isAllowedToSign &&
      hasSignatureRelevantMaterialsChanged &&
      filteredPromatGroups.filter(
        (each) => each.promotionalMaterial?.signatureRequired
      ).length;
    if (showSignatureConfirmationDialog) {
      setIsConfirmationDialogOpen(true);
    } else {
      return onClose();
    }
  };

  const handleSignNow = () => {
    setIsConfirmationDialogOpen(false);
    onClose();

    if (isRemoteInteraction) {
      // Set the timeout so there is enough time for the dialog animation to run
      setTimeout(() => {
        // This will render ProMatCollection component inside InteractionItems.tsx
        handleSignFormInRemote();
      }, 500);
    } else {
      openCollectionDialog();
    }
  };

  const handleSignLater = () => {
    setIsConfirmationDialogOpen(false);
    onClose();
  };

  return (
    <>
      <ActionDialog
        isOpen={isOpen}
        title={t('pages.interaction.common.promatColumnTitle')}
        testId="edit-touchpoint-promat-dialog"
        primaryButtonText={t('components.common.done')}
        secondaryButtonText={t('components.common.cancel')}
        onClickPrimaryButton={handleClickDone}
        onClickSecondaryButton={onClose}
      >
        <PromotionalMaterialEditDialog
          isRestrictedEdit={isRestrictedEdit}
          activity={activity}
          promotionalMaterialGroups={promotionalMaterialGroups}
          signedSampleRequestForm={signedSampleRequestForm ?? undefined}
          onChangePromats={setPromotionalMaterialGroups}
        />
      </ActionDialog>

      <ConfirmationDialog
        title={t('pages.interaction.expand.signRequestForm')}
        content={t(
          'pages.interaction.expand.requestedSamplesRequiredSignature'
        )}
        isOpen={isConfirmationDialogOpen}
        primaryButton={{
          text: t('pages.interaction.expand.signNow'),
          onClickHandler: handleSignNow,
        }}
        secondaryButton={{
          text: t('pages.interaction.expand.signLater'),
          onClickHandler: handleSignLater,
        }}
      />
    </>
  );
};
