import { MediaData, ReactionDataEnum } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { KeyMessagesList } from '@/components/KeyMessagesList';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useInteraction } from '@/hooks';

type KeyMessagesStaticBlockProps = {
  shownMedia: Array<MediaData>;
  isInteractionDone?: boolean;
  isReactionEditable?: boolean;
};

export const InteractionKeyMessagesStaticBlock = ({
  shownMedia,
  isInteractionDone = false,
  isReactionEditable = false,
}: KeyMessagesStaticBlockProps) => {
  const { t } = useTranslation();
  const { reactions, setReactionForKeyMessage } = useInteraction();

  const handleChangeReaction = (
    mediaId: string | undefined,
    keyMessageId: string | undefined,
    reaction: ReactionDataEnum
  ) => {
    if (isReactionEditable && mediaId && keyMessageId) {
      setReactionForKeyMessage?.(mediaId, keyMessageId, reaction);
    }
  };

  return (
    <OverviewStaticBlock
      title={t('pages.interaction.common.keymessageColumnTitle')}
    >
      <KeyMessagesList
        media={shownMedia}
        isReactionEditable={isReactionEditable}
        isDisplayReactions={isInteractionDone}
        reactions={reactions}
        handleChangeReaction={handleChangeReaction}
      />
    </OverviewStaticBlock>
  );
};
