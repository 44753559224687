import { gql } from '@apollo/client';

export const collectDigitalPersonConsent = gql`
  mutation collectDigitalPersonConsent(
    $oid: PersonConsentId!
    $consentTypeId: ConsentTypeId!
    $issuerPersonId: PersonId!
    $signerPersonId: PersonId!
    $actionAt: DateTime!
    $content: String!
  ) {
    collectDigitalPersonConsent(
      input: {
        oid: $oid
        consentTypeId: $consentTypeId
        issuerPersonId: $issuerPersonId
        signerPersonId: $signerPersonId
        actionAt: $actionAt
        content: $content
      }
    ) {
      id
      oid
    }
  }
`;
