import { PersonConsentData, PersonData } from '@ysura/common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import {
  errorConfigBannerDialog,
  useInteraction,
  useNotification,
} from '@/hooks';
import { useRejectPersonConsentMutation } from '@/services/graphql/hooks';

type RejectConsentComponentProps = {
  isInRemoteRoom?: boolean;
  consent: PersonConsentData;
  person: PersonData;
  onCloseDialog: VoidFunction;
  onConfirmAction: VoidFunction;
};

export const RejectConsentDialog = ({
  isInRemoteRoom = false,
  consent,
  person,
  onConfirmAction,
  onCloseDialog,
}: RejectConsentComponentProps) => {
  const {
    rejectSharedConsent,
    onRejectConsentAttendeeConfirmed,
    onConsentClosed,
    broadcastConsentClose,
  } = useInteraction();

  // Reject button should be disabled while attendee has not confirmed the action
  const [isRejectButtonEnable, setIsRejectButtonEnable] =
    useState<boolean>(true);

  // Organizer has already clicked to reject the consent, so to cancel it is mandatory to notify the attendee to close consent
  const [isRejectButtonClicked, setIsRejectButtonClicked] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const { toast } = useNotification();

  const [rejectPersonConsentMutation, { loading }] =
    useRejectPersonConsentMutation();

  const handleRejectConsentMutation = useCallback(() => {
    rejectPersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent?.oid ?? uuidv4(),
        consentTypeId: consent.consentType?.oid ?? '',
        signerPersonId: person.oid ?? '',
      },
      onCompleted: () => {
        onConfirmAction();
      },
      onError: () => {
        onCloseDialog();
      },
    });
  }, [
    consent.consentType?.oid,
    consent?.oid,
    onCloseDialog,
    onConfirmAction,
    person.oid,
    rejectPersonConsentMutation,
  ]);

  const handleClickOnRejectConsent = () => {
    if (isInRemoteRoom && consent.consentType) {
      setIsRejectButtonEnable(false);
      setIsRejectButtonClicked(true);
      rejectSharedConsent({
        id: consent.oid ?? '',
        consentType: {
          id: consent?.consentType.oid ?? '',
          name: consent?.consentType.name ?? '',
        },
      });
    } else {
      // Not remote interaction
      handleRejectConsentMutation();
    }
  };

  const handleCancelRejectConsent = () => {
    // If reject has been clicked, attendee should be notified ¿?
    if (
      isInRemoteRoom &&
      isRejectButtonClicked &&
      consent &&
      consent.consentType
    ) {
      broadcastConsentClose?.({
        id: consent.consentType.oid ?? '',
      });
    }
    onCloseDialog();
  };

  useEffect(() => {
    const handleAttendeeRejectConsentConfirmation = () => {
      handleRejectConsentMutation();
    };

    const handleCloseConsent = () => {
      toast?.({
        message: t('pages.interaction.toasts.consentRejectCanceled'),
        type: 'warning',
      });
      onCloseDialog?.();
    };

    const unsubscribeOnRejectConsentAttendeeConfirmed =
      onRejectConsentAttendeeConfirmed?.(
        handleAttendeeRejectConsentConfirmation
      );

    const unsubscribeOnConsentClosed = onConsentClosed?.(handleCloseConsent);

    return () => {
      if (unsubscribeOnRejectConsentAttendeeConfirmed) {
        unsubscribeOnRejectConsentAttendeeConfirmed();
      }

      if (unsubscribeOnConsentClosed) {
        unsubscribeOnConsentClosed();
      }
    };
  }, [
    handleRejectConsentMutation,
    onCloseDialog,
    onConsentClosed,
    onRejectConsentAttendeeConfirmed,
    t,
    toast,
  ]);

  return (
    <ConfirmationDialog
      testId="reject-consent"
      isOpen={true}
      title={t('components.consentDialog.rejectConsent.title')}
      content={
        isInRemoteRoom
          ? t('components.consentDialog.rejectConsent.textForRemote')
          : t('components.consentDialog.rejectConsent.textForNonRemote')
      }
      primaryButton={{
        text: t('components.consentDialog.action.reject'),
        onClickHandler: handleClickOnRejectConsent,
        color: 'error',
        isButtonDisabled: !isRejectButtonEnable,
        isButtonLoading: loading,
      }}
      secondaryButton={{
        text: t('components.common.cancel'),
        onClickHandler: handleCancelRejectConsent,
      }}
    />
  );
};
