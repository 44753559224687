import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import merge from 'lodash/merge';
// eslint-disable-next-line import/default
import Chart from 'react-apexcharts';

import { BaseOptionChart } from '@/components/Chart';
import { getTouchpointPlanPercentage } from '@/views/PersonView/Touchpoint/helpers';

type TouchpointRadialBarProps = {
  color: string;
  label?: string;
  plannedActivities?: number | null;
  finalizedActivities?: number | null;
};

export const TouchpointRadialBar = ({
  label,
  color,
  plannedActivities,
  finalizedActivities,
}: TouchpointRadialBarProps) => {
  const numberOfPlannedActivities = plannedActivities ?? '-';
  const numberOfFinalizedActivities = finalizedActivities ?? '-';

  const chartOptions = merge(BaseOptionChart(), {
    legend: { show: false },
    fill: {
      colors: [color],
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '46%' },
        dataLabels: {
          name: {
            show: false,
          },
          total: {
            formatter: () =>
              `${numberOfFinalizedActivities}/${numberOfPlannedActivities}`,
          },
        },
      },
    },
  });

  const finalizedTouchpointPercentage = getTouchpointPlanPercentage({
    plannedActivities,
    finalizedActivities,
  });

  return (
    <Wrapper>
      <SingleChartWrapper>
        <Chart
          options={chartOptions}
          series={[finalizedTouchpointPercentage]}
          type="radialBar"
          height="100px"
        />
      </SingleChartWrapper>
      <Typography variant="caption">{label}</Typography>
    </Wrapper>
  );
};

const SingleChartWrapper = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  height: 50,
  width: 50,
});

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  flex: 1,
}));
