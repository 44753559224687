import {
  EmploymentData,
  getPersonFullNameWithTitle,
  PersonData,
  PromotionalMaterialGroupData,
} from '@ysura/common';
import Handlebars from 'handlebars';

import { getAddress } from '@/services/employment';

import {
  extractSampleRequestFormItems,
  SampleRequestFormSampleItemData,
} from '../SampleRequestForm/sampleRequestUtils';

type ReceiverForSampleRequestInput = {
  receiver: PersonData | undefined;
  receiverAddress: string;
  receiverAddressExtension: string;
};

export const getReceiverForSampleRequestPlaceholders = ({
  receiver,
  receiverAddress,
  receiverAddressExtension,
}: ReceiverForSampleRequestInput) => {
  // Address
  const { address, employment } = findAddressInReceiver(
    receiver,
    receiverAddress
  );

  const postalCodeAndCity = `${address?.postalCode ?? ''} ${
    address?.city ?? ''
  }`.trim();

  return {
    RECEIVER_FULL_NAME: getPersonFullNameWithTitle(receiver),
    RECEIVER_ADDRESS_ORG: employment ? buildOrgName(employment) : '',
    RECEIVER_ADDRESS_EXTENSION: receiverAddressExtension ?? '',
    RECEIVER_ADDRESS_STREET: addressLinesToSingleLine(address?.addressLines),
    RECEIVER_ADDRESS_POSTAL_CODE_AND_CITY: postalCodeAndCity,
  };
};

export const getSamplesTablePlaceholder = (
  samples: Array<PromotionalMaterialGroupData>
) => {
  const sampleDropItems = extractSampleRequestFormItems(samples);

  return {
    SAMPLES_TABLE: buildSamplesTable(sampleDropItems),
  };
};

const buildSampleLine = (sample: SampleRequestFormSampleItemData): string => {
  const cells = [
    `<td class="ys-first-col">${Handlebars.escapeExpression(
      sample?.name
    )}</td>`,
    `<td class="ys-other-col">${sample?.physicalQuantity}</td>`,
    `<td class="ys-other-col">${sample?.virtualQuantity}</td>`,
  ];

  return cells.join('');
};

const buildSamplesTable = (samples: Array<SampleRequestFormSampleItemData>) => {
  const tableHtml = samples.reduce((accHtml, currentSample) => {
    return `${accHtml}<tr>${buildSampleLine(currentSample)}</tr>`;
  }, '');

  return new Handlebars.SafeString(tableHtml);
};

const addressLinesToSingleLine = (lines: Array<string> | undefined): string => {
  const safeLines = lines ?? [];

  return safeLines.join('\n');
};

const parseAddressValue = (addressValue: string) => {
  const [addressOid, employmentOid] = addressValue.split('_');

  return {
    addressOid,
    employmentOid,
  };
};

const findAddressInReceiver = (
  receiver: PersonData | undefined,
  addressValue: string
) => {
  const { addressOid, employmentOid } = parseAddressValue(addressValue);

  const receiverEmployments = receiver?.employments ?? [];
  const relevantEmployment = receiverEmployments.find(
    (employment) => employment?.oid === employmentOid
  );

  const address = getAddress(relevantEmployment);
  const relevantAddress = address?.oid === addressOid ? address : undefined;

  return {
    employment: relevantEmployment,
    address: relevantAddress,
  };
};

const buildOrgName = (employment: EmploymentData) => {
  const nameLines = [];

  if (employment?.organization?.rootOrganization) {
    nameLines.push(employment.organization?.rootOrganization.name);
  }

  if (employment?.organization) {
    nameLines.push(employment.organization.name);
  }

  return nameLines.join('<br/>');
};
