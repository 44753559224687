import { NetworkStatus } from '@apollo/client';
import { OrganizationData, PersonFilterData } from '@ysura/common';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { countSelectedItemFilters } from '@/components/GlobalSearch/utils';
import {
  EmploymentPersonDataStaticBlock,
  MobileEmploymentFilterCard,
} from '@/components/Organization/Employment';
import { buildPersonFilterQuery } from '@/services/graphql/helpers';
import { useGetSearchedPersonsLazyQuery } from '@/services/graphql/hooks';
import { parsePersonConnection } from '@/services/graphql/parsers';
import { PersonFilterInput, Scope } from '@/services/graphql/types';
import { filterNonNull } from '@/utils/dataHelpers/filters';

interface MobileOrganizationEmploymentContentProps {
  isOpen: boolean;
  onClose: VoidFunction;
  organization: OrganizationData;
}

const NUMBER_OF_PERSONS_TO_SEARCH = 20;
export const MobileOrganizationEmploymentDialog = ({
  isOpen,
  onClose,
  organization,
}: MobileOrganizationEmploymentContentProps) => {
  const { t } = useTranslation();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [personFiltersData, setPersonFiltersData] = useState<
    PersonFilterData[]
  >([]);
  const [showAllEmployments, setShowAllEmployments] = useState(false);

  const [
    searchEmployments,
    { data, fetchMore, called, refetch, networkStatus },
  ] = useGetSearchedPersonsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isOpen) {
      const personFilterQuery: PersonFilterInput = buildPersonFilterQuery(
        personFiltersData,
        Scope.TERRITORY
      );
      const organizationIds = !showAllEmployments
        ? filterNonNull([organization.oid])
        : map(
            organization.hierarchyOrganizations,
            (organization: OrganizationData) => organization.oid!
          );
      if (!personFilterQuery.employments) {
        personFilterQuery.employments = {};
      }
      personFilterQuery.employments.organization = {
        oid: { _in: organizationIds },
      };

      if (called) {
        refetch({
          search: searchQuery,
          first: NUMBER_OF_PERSONS_TO_SEARCH,
          filter: personFilterQuery,
        });
      } else {
        searchEmployments({
          variables: {
            search: searchQuery,
            first: NUMBER_OF_PERSONS_TO_SEARCH,
            filter: personFilterQuery,
          },
        });
      }
    }
  }, [
    organization,
    showAllEmployments,
    personFiltersData,
    searchEmployments,
    searchQuery,
    isOpen,
    called,
    refetch,
  ]);

  const handleFetchMorePersons = () => {
    const endCursor = data?.persons?.pageInfo?.endCursor ?? '';

    fetchMore({
      variables: {
        after: endCursor,
      },
    });
  };

  const toggleFilterCard = () => {
    setIsFilterOpen((pre) => !pre);
  };

  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const persons = data?.persons
    ? parsePersonConnection(data.persons)
    : undefined;

  const hasMore = data?.persons?.pageInfo?.hasNextPage ?? false;

  const handleFiltersChange = (
    personFiltersData: PersonFilterData[],
    showAllEmployments: boolean
  ) => {
    setPersonFiltersData(personFiltersData);
    setShowAllEmployments(showAllEmployments);
    setIsFilterOpen(false);
  };

  if (isFilterOpen) {
    return (
      <MobileEmploymentFilterCard
        isOpen={isFilterOpen}
        filtersData={personFiltersData}
        onClose={handleFiltersChange}
        onCancel={toggleFilterCard}
      />
    );
  }

  const count = countSelectedItemFilters(personFiltersData);

  return (
    <ActionDialog
      isOpen={isOpen}
      isLoading={isLoading || networkStatus == NetworkStatus.fetchMore}
      title={t('pages.organizationView.personDataCard.personData')}
      secondaryButtonText={t('components.common.close')}
      testId="organization-employment-mobile-info"
      onClickSecondaryButton={onClose}
    >
      <EmploymentPersonDataStaticBlock
        isLoading={isLoading}
        total={data?.persons?.filteredCount}
        persons={persons}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        hasMore={hasMore}
        fetchMorePersons={handleFetchMorePersons}
        filtersCount={count}
        handleFiltersChanges={toggleFilterCard}
      />
    </ActionDialog>
  );
};
