import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetConsentTypesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetConsentTypesQuery = {
  __typename?: 'Query';
  consentTypes: {
    __typename?: 'ConsentTypeConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ConsentTypeEdge';
      node?: {
        __typename?: 'ConsentType';
        id: string;
        oid: string;
        name: string;
      } | null;
    }> | null;
  };
};

/**
 * __useGetConsentTypesQuery__
 *
 * To run a query within a React component, call `useGetConsentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConsentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConsentTypesQuery,
    GetConsentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetConsentTypesQuery, GetConsentTypesQueryVariables>(
    Operations.getConsentTypes,
    options
  );
}
export function useGetConsentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConsentTypesQuery,
    GetConsentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetConsentTypesQuery,
    GetConsentTypesQueryVariables
  >(Operations.getConsentTypes, options);
}
export function useGetConsentTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetConsentTypesQuery,
    GetConsentTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetConsentTypesQuery,
    GetConsentTypesQueryVariables
  >(Operations.getConsentTypes, options);
}
export type GetConsentTypesQueryHookResult = ReturnType<
  typeof useGetConsentTypesQuery
>;
export type GetConsentTypesLazyQueryHookResult = ReturnType<
  typeof useGetConsentTypesLazyQuery
>;
export type GetConsentTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetConsentTypesSuspenseQuery
>;
export type GetConsentTypesQueryResult = Apollo.QueryResult<
  GetConsentTypesQuery,
  GetConsentTypesQueryVariables
>;
