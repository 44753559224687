import { useTranslation } from 'react-i18next';

import { ConsentSkeletonCard } from '@/components/Consent';
import { OverviewStaticBlock } from '@/components/StaticBlock';

export const ConsentSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.consent')}
      testId="consent-skeleton-static-block"
    >
      <ConsentSkeletonCard />
    </OverviewStaticBlock>
  );
};
