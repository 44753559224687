import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDateTime } from '@/services/graphql/hooks/mutations/helpers/helper';

import {
  RejectPersonConsentMutation,
  RejectPersonConsentMutationVariables,
  useRejectPersonConsentMutation as useRejectPersonConsentMutationApollo,
} from '../generated';

type RejectPersonConsentVariables =
  // oid, consentTypeId, signerPersonId are required
  Pick<
    RejectPersonConsentMutationVariables,
    'oid' | 'consentTypeId' | 'signerPersonId'
  > &
    // issuerPersonId and actionAt are optional
    Partial<
      Pick<RejectPersonConsentMutationVariables, 'issuerPersonId' | 'actionAt'>
    >;

/**
 * __useRejectPersonConsentMutation__
 *
 * To run a mutation, you first call `useRejectPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPersonConsent, { data, loading, error }] = useRejectPersonConsentMutation();
 *
 * rejectPersonConsent({
 *   variables: {
 *      oid: // defaults to a new uuid
 *      consentTypeId: // Oid of the consent type
 *      signerPersonId: // Oid of the person signing the consent
 *
 *      // Optional
 *      issuerPersonId: // defaults to the current user
 *      actionAt: // defaults to the current timestamp
 *   },
 * });
 */
export const useRejectPersonConsentMutation = (
  baseOptions?: MutationHookOptions<
    RejectPersonConsentMutation,
    RejectPersonConsentMutationVariables
  >
) => {
  const { currentUser } = useCurrentUser();

  const [rejectPersonConsent, status] = useRejectPersonConsentMutationApollo({
    // We invalidate all queries related to consent to refresh the data
    refetchQueries: ['getPersonConsentList', 'getPersonCommunicationData'],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<
      RejectPersonConsentMutation,
      RejectPersonConsentVariables
    >
  ) => {
    const defaultVariables = {
      issuerPersonId: currentUser?.person?.oid ?? '',
      actionAt: buildCurrentDateTime(),

      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      consentTypeId: '',
      signerPersonId: '',
    };

    return rejectPersonConsent({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
