/* eslint-disable */
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
/* eslint-enable */

import Handlebars from 'handlebars';

export const getDatePlaceholders = (dateFormat = 'P') => {
  return { CURRENT_DATE: format(new Date(), dateFormat, { locale: de }) };
};

// Signatures are always strings of the following form:
// data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUo==
// To allow Handlebars replacement, we need to not escape the string.
// As this would be a security risk for XSS attacks, we strictly validate the input.
// If the signature does not match the expected format, we return an empty string.
export const getSignaturePlaceholder = (signature: string) => {
  const signatureRegExp = /^data:image\/png;base64,[A-Za-z0-9+/]+={0,2}$/;
  if (!signatureRegExp.test(signature)) {
    return { SIGNATURE: '' };
  }

  return { SIGNATURE: new Handlebars.SafeString(signature) };
};
