import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
  ActivityStatusDataEnum,
  PersonConsentData,
  useLocalStorage,
} from '@ysura/common';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useInteraction } from '@/hooks';
import { InteractionBackTopLayout, InteractionLayout } from '@/layouts';
import { InvalidActivity } from '@/pages/Error';
import { InteractionProps } from '@/pages/Interaction';
import { isUserOrganizer } from '@/services/activities';
import { redirectBackToCrm } from '@/services/api';
import { useGetPersonConsentListQuery } from '@/services/graphql/hooks';
import { parsePersonConsents } from '@/services/graphql/parsers';
import { InteractionSummary } from '@/views/Interaction';
import {
  TelephoneRoom,
  TelephoneWaitingRoom,
  TelephoneWaitingRoomMobile,
} from '@/views/Interaction/Telephone';

export const TelephoneInteraction = ({
  activity,
  canEditActivity,
}: InteractionProps) => {
  // Local storage
  const [isFromCrm] = useLocalStorage<boolean>('fromCrm', false);

  // Hooks
  const { currentUser } = useCurrentUser();
  const {
    interactionStep,
    joinInteractionFromWaitingRoom,
    initializeStateServer,
    leaveInteraction,
  } = useInteraction();
  const navigate = useNavigate();

  const isOrganizer = isUserOrganizer(activity, currentUser?.person);
  const isInMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    if (
      (interactionStep === 'interaction' ||
        interactionStep === 'waiting-room') &&
      activity.id
    ) {
      initializeStateServer({
        roomId: activity.id,
        role: 'organizer',
        interactionStep: interactionStep,
      });
    }
  }, [initializeStateServer, activity.id, interactionStep, isOrganizer]);

  // Handlers
  const handleStartInteraction = () => {
    joinInteractionFromWaitingRoom();
  };

  const handleCancelInteraction = () => {
    leaveInteraction();
    // user starts from interact, so stay in interact
    if (!isFromCrm) {
      navigate(`/touchpoint/${activity?.id}`);

      return;
    }

    // user start from CRM, so redirect back to it
    return redirectBackToCrm();
  };

  // Get Person Consent by PersonId
  const { data: consentData } = useGetPersonConsentListQuery({
    variables: {
      id: activity?.attendees?.[0]?.person?.id ?? '',
    },
    skip: !activity?.attendees?.[0]?.person?.oid,
  });

  const availableConsents: PersonConsentData[] = parsePersonConsents(
    consentData?.person
  );

  if (!activity?.id) {
    return null;
  }

  if (interactionStep === 'summary') {
    return (
      <InteractionBackTopLayout>
        <InteractionSummary
          activity={activity}
          consentList={availableConsents}
          canEditActivity={canEditActivity}
        />
      </InteractionBackTopLayout>
    );
  }

  // We make the decision about whether the activity is planned or closed here
  // because we need to allow closed activities in the summary page.
  if (activity?.status !== ActivityStatusDataEnum.PLANNED) {
    const redirectTarget = isFromCrm ? undefined : `/touchpoint/${activity.id}`;
    const redirectAction = isFromCrm ? redirectBackToCrm : undefined;

    return (
      <InvalidActivity
        reason={'activityNotPlanned'}
        redirectAction={redirectAction}
        redirectTarget={redirectTarget}
      />
    );
  }

  return (
    <InteractionLayout>
      {interactionStep === 'waiting-room' ? (
        <>
          {isInMobile ? (
            <TelephoneWaitingRoomMobile
              activity={activity}
              isOrganizer={isOrganizer}
              consentList={availableConsents}
              isActivityEditable={canEditActivity}
              onStartInteraction={handleStartInteraction}
              onCancelInteraction={handleCancelInteraction}
            />
          ) : (
            <TelephoneWaitingRoom
              activity={activity}
              isOrganizer={isOrganizer}
              consentList={availableConsents}
              isActivityEditable={canEditActivity}
              onStartInteraction={handleStartInteraction}
              onCancelInteraction={handleCancelInteraction}
            />
          )}
        </>
      ) : (
        <TelephoneRoom
          canEditActivity
          isOrganizer={isOrganizer}
          activity={activity}
          roomId={activity.id}
          consentList={availableConsents}
        />
      )}
    </InteractionLayout>
  );
};
