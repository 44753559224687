import { gql } from '@apollo/client';

export const collectManualPersonConsent = gql`
  mutation collectManualPersonConsent(
    $oid: PersonConsentId!
    $consentTypeId: ConsentTypeId!
    $issuerPersonId: PersonId!
    $signerPersonId: PersonId!
    $actionAt: DateTime!
    $consentItemsValues: [PersonConsentItemValueInput!]!
    $manualProcessReason: ConsentTypeManualProcessReasonId!
  ) {
    collectManualPersonConsent(
      input: {
        oid: $oid
        consentTypeId: $consentTypeId
        issuerPersonId: $issuerPersonId
        signerPersonId: $signerPersonId
        actionAt: $actionAt
        consentItemsValues: $consentItemsValues
        manualProcessReason: $manualProcessReason
      }
    ) {
      id
      oid
    }
  }
`;
