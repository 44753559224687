import { UserData } from '@ysura/common';

import { CommonClientPermissions } from '@/services/user/permissions/ClientPermissions';
import { hasClientPermission } from '@/services/user/permissions/helper';

export const getCommonPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): CommonClientPermissions => {
  return {
    canSwitchSearchScope: hasClientPermission(
      apiUserData,
      isAdmin,
      'search-global-switch-scope'
    ),
    canReadHistory: hasClientPermission(
      apiUserData,
      isAdmin,
      'history-global-read'
    ),
    canMakePhoneCall: hasClientPermission(
      apiUserData,
      isAdmin,
      'phone-make-call'
    ),
  };
};
