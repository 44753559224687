import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetOrganizationTypesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetOrganizationTypesQuery = {
  __typename?: 'Query';
  organizationTypes: {
    __typename?: 'OrganizationTypeConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'OrganizationTypeEdge';
      node?: {
        __typename?: 'OrganizationType';
        id: string;
        oid: string;
        name?: string | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetOrganizationTypesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationTypesQuery,
    GetOrganizationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetOrganizationTypesQuery,
    GetOrganizationTypesQueryVariables
  >(Operations.getOrganizationTypes, options);
}
export function useGetOrganizationTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationTypesQuery,
    GetOrganizationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetOrganizationTypesQuery,
    GetOrganizationTypesQueryVariables
  >(Operations.getOrganizationTypes, options);
}
export function useGetOrganizationTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationTypesQuery,
    GetOrganizationTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetOrganizationTypesQuery,
    GetOrganizationTypesQueryVariables
  >(Operations.getOrganizationTypes, options);
}
export type GetOrganizationTypesQueryHookResult = ReturnType<
  typeof useGetOrganizationTypesQuery
>;
export type GetOrganizationTypesLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationTypesLazyQuery
>;
export type GetOrganizationTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationTypesSuspenseQuery
>;
export type GetOrganizationTypesQueryResult = Apollo.QueryResult<
  GetOrganizationTypesQuery,
  GetOrganizationTypesQueryVariables
>;
