import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';

type TextOrLoaderProps = {
  isLoading: boolean;
  text: string;
};

export const TextOrLoader = ({ isLoading, text }: TextOrLoaderProps) => {
  return (
    <Wrapper textLength={text.length}>
      {isLoading ? <Loader size={24} /> : text}
    </Wrapper>
  );
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'textLength',
})<{ textLength: number }>(({ textLength }) => ({
  minWidth: `${textLength * 10}px`,
}));
