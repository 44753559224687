import { useTranslation } from 'react-i18next';

import { MediaSkeletonCard } from '@/components/Media';
import { OverviewStaticBlock } from '@/components/StaticBlock';

type MediaSkeletonStaticBlockProps = {
  isInRemoteRoom?: boolean;
};

export const MediaSkeletonStaticBlock = ({
  isInRemoteRoom = false,
}: MediaSkeletonStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.interaction.common.mediaColumnTitle')}
      testId="media-skeleton-static-block"
      spacing={isInRemoteRoom ? 'dense' : 'spacious'}
    >
      <MediaSkeletonCard />
    </OverviewStaticBlock>
  );
};
