import { ActivityData, NoItemPlaceholder } from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';
import { OrganizationCard } from '@/components/Organization';
import { useInteraction } from '@/hooks';

type OrganizationAccordionBlockProps = {
  activity: ActivityData | null;
  isLoading?: boolean;
};

export const OrganizationAccordionBlock = ({
  activity,
  isLoading = false,
}: OrganizationAccordionBlockProps) => {
  const { t } = useTranslation();
  const { isOwnScreenShared } = useInteraction();
  const [expanded, setExpanded] = useState(false);

  const firstAttendee = activity?.attendees?.[0]?.person;

  const handleAccordionToggle = () => {
    if (isLoading) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (isOwnScreenShared) {
      setExpanded(false);
    }
  }, [isOwnScreenShared]);

  return (
    <OverviewAccordionBlock
      expanded={expanded}
      title={t('pages.interaction.remote.organization')}
      onChange={handleAccordionToggle}
    >
      {firstAttendee?.employments?.[0] ? (
        <OrganizationCard
          isInsideAccordion
          shouldBeNavigateToOrganization={false}
          employment={firstAttendee.employments[0]}
        />
      ) : (
        <NoItemPlaceholder>
          {t('pages.interaction.common.noDataAvailable')}
        </NoItemPlaceholder>
      )}
    </OverviewAccordionBlock>
  );
};
