import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationData, SearchTextField } from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getHierarchyTree } from '@/services/organization';

import { OrganizationHierarchyTreeDataBlock } from './OrganizationHierarchyTreeDataBlock';

interface OrganizationHierarchyCardPros {
  setSelectedOrganization: (organization: OrganizationData) => void;
  organization: OrganizationData;
}
export const OrganizationHierarchyCard = ({
  organization,
  setSelectedOrganization,
}: OrganizationHierarchyCardPros) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');

  const hierarchy = getHierarchyTree(organization.hierarchyOrganizations ?? []);

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target?.value ?? '');
  };
  const handleClearSearchQuery = () => {
    setSearchQuery('');
  };

  return (
    <ParentStack>
      <Stack>
        <SearchTextField
          testId="hierarchy-search-field"
          placeholder={t('components.common.search')}
          searchQuery={searchQuery}
          clearButtonText={t('components.common.clear')}
          onClearSearchQuery={handleClearSearchQuery}
          onChangeSearchQuery={handleChangeSearchQuery}
        />
      </Stack>
      <ScrollableStack>
        <>
          {hierarchy && (
            <OrganizationHierarchyTreeDataBlock
              organization={organization}
              hierarchy={hierarchy}
              setSelectedOrganization={setSelectedOrganization}
              searchQuery={searchQuery.trim()}
            />
          )}
        </>
      </ScrollableStack>
    </ParentStack>
  );
};

const ParentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(1),
  height: '100%',
}));

const ScrollableStack = styled(Stack)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  paddingBottom: theme.spacing(4),
}));
