import {
  Box,
  DialogContent as MuiDialogContent,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizerPresentationStateChangePayload } from '@ysura/common';
import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

import { PDFFileActions } from '@/components/DocumentPreview';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction, usePDFControlling } from '@/hooks';
import { DocumentType } from '@/services/api';
import { PointerContainer } from '@/views/Interaction/Remote/Room/MediaSharing/PointerContainer';

type PDFFileProps = {
  data: ArrayBuffer;
  initialPage?: number;
  fileName: string;
  onClose: VoidFunction;
  isInInteraction?: boolean;
  isReadOnlyMode?: boolean;
  isDownloadButtonShown?: boolean;
  isFullScreenShown?: boolean;
  mediaId?: string;
  isFileSharing?: boolean;
};

export const PDFFile = ({
  data,
  initialPage,
  fileName,
  isInInteraction = false,
  isDownloadButtonShown,
  isReadOnlyMode = false,
  isFullScreenShown,
  onClose,
}: PDFFileProps) => {
  const {
    currentPage,
    setCurrentPage,
    getPDFSize,
    handleChangeAspect,
    handleChangePage,
    handleLoadDocument,
    numberOfPages,
    containerRef,
    isPdfJsLoaded,
    isPdfDocumentLoaded,
  } = usePDFControlling({ initialPage, isInInteraction });

  const [contentBlob, setContentBlob] = useState<Blob | null>(null);
  const { onMediaStateChanged } = useInteraction();

  useEffect(() => {
    if (data && data.byteLength > 0) {
      setContentBlob(new Blob([data], { type: DocumentType.PDF }));
    }
  }, [data]);

  // change the page when in isReadOnlyMode (e.g. for co-organizer). When in isReadOnlyMode,
  // we need to follow the main presenter and set the page to the page which the presenter navigates to
  useEffect(() => {
    const handlePresentationStateChanged = (
      data: OrganizerPresentationStateChangePayload
    ) => {
      if (data.type === 'pdf' && data.stateChange.namespace === 'pdfViewer') {
        if (data.stateChange.state?.page && isReadOnlyMode) {
          setCurrentPage(data.stateChange.state.page);
        }
      }
    };

    const unsubscribeOnMediaStateChanged = onMediaStateChanged?.(
      handlePresentationStateChanged
    );

    return () => {
      if (unsubscribeOnMediaStateChanged) {
        unsubscribeOnMediaStateChanged();
      }
    };
  }, [onMediaStateChanged, setCurrentPage, isReadOnlyMode]);

  const renderPDF = () => {
    return (
      isPdfJsLoaded && (
        <PDFDocument
          isPdfDocumentLoaded={isPdfDocumentLoaded}
          file={data}
          onLoadSuccess={handleLoadDocument}
        >
          <Page pageNumber={currentPage} {...getPDFSize()} />
        </PDFDocument>
      )
    );
  };

  const renderContainer = () => {
    return (
      <Container ref={containerRef} data-testid="pdf-container">
        <ScrollBox>
          {isInInteraction ? (
            <PointerContainer>{renderPDF()}</PointerContainer>
          ) : (
            renderPDF()
          )}

          {!isPdfDocumentLoaded && <ResponsiveSkeleton />}
        </ScrollBox>
      </Container>
    );
  };

  const renderActionButtons = () => {
    return (
      <PDFFileActions
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        fileName={fileName}
        contentLink={contentBlob ? URL.createObjectURL(contentBlob) : ''}
        isDownloadButtonShown={isDownloadButtonShown && contentBlob !== null}
        isFullScreenShown={isFullScreenShown}
        isFitScreenShown={true}
        isReadOnlyMode={isReadOnlyMode}
        onClose={onClose}
        onChangePage={handleChangePage}
        onChangeAspect={handleChangeAspect}
      />
    );
  };

  return (
    <>
      <Content data-testid="document-preview-content">
        <BorderBox>{renderContainer()}</BorderBox>

        <MobileLandscapeActions data-testid="mobile-landscape-buttons">
          {renderActionButtons()}
        </MobileLandscapeActions>
      </Content>

      <LargeScreenActions data-testid="large-screen-buttons">
        {renderActionButtons()}
      </LargeScreenActions>

      <MobilePortraitActions data-testid="mobile-portrait-buttons">
        {renderActionButtons()}
      </MobilePortraitActions>
    </>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',

  height: '100%',
  width: '100%',

  background: theme.palette.grey[800],

  overflowY: 'auto',
}));

const ScrollBox = styled(Box)({
  display: 'grid',
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const Content = styled(MuiDialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
    },
}));

const BorderBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  height: '100%',
  width: '100%',
  overflow: 'hidden',
}));

const LargeScreenActions = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const MobilePortraitActions = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.only('xs')]: {
    display: 'block',
  },
}));

const MobileLandscapeActions = styled(Box)({
  display: 'none',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'block',
    },
});

const PDFDocument = styled(Document, {
  shouldForwardProp: (prop) => prop !== 'isPdfDocumentLoaded',
})<{ isPdfDocumentLoaded: boolean }>(({ isPdfDocumentLoaded }) => ({
  justifySelf: 'center',
  alignSelf: 'center',
  display: isPdfDocumentLoaded ? 'block' : 'none',
}));

const ResponsiveSkeleton = styled(Skeleton)({
  height: '100%',
});
