import { gql } from '@apollo/client';

export const getMediaTopics = gql`
  query getMediaTopics($oids: [MediaId!]) {
    medias(filter: { oid: { _in: $oids } }) {
      edges {
        node {
          id
          oid
          name
          topics(filter: { state: { _eq: ACTIVE } }) {
            edges {
              node {
                id
                oid
              }
            }
          }
          keyMessages {
            edges {
              node {
                id
                oid
                topics(filter: { state: { _eq: ACTIVE } }) {
                  edges {
                    node {
                      id
                      oid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
