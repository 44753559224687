import { NoItemPlaceholder } from '@ysura/common';

import { OverviewStaticBlock } from '@/components/StaticBlock';

type PlaceholderStaticBlockProps = {
  title: string;
  text: string;
};

export const PlaceholderStaticBlock = ({
  title,
  text,
}: PlaceholderStaticBlockProps) => {
  return (
    <OverviewStaticBlock title={title}>
      <NoItemPlaceholder>{text}</NoItemPlaceholder>
    </OverviewStaticBlock>
  );
};
