import { Box } from '@mui/material';
import { ActivityData, TopicData, useLocalStorage } from '@ysura/common';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useInteraction, useNotification } from '@/hooks';
import { redirectBackToCrm, redirectToCrmEditActivity } from '@/services/api';
import { useUpdateActivityMutation } from '@/services/graphql/hooks';
import { EditActivityDialog } from '@/views/Activity/Update';
import {
  addingTopics,
  updateViewedMediaKeyMessages,
  validInteractionType,
} from '@/views/Interaction/helpers';

import { EndInteractionDialog } from './EndInteractionDialog';
import { prepareMutationForEndingInteraction } from './helper';

type EndInteractionProps = {
  isOpen: boolean;
  activity: ActivityData | null;
  onClose: VoidFunction;
  onUpdateAndSaveAndExit?: VoidFunction;
  onEndWithoutSaving?: VoidFunction;
  testId?: string;
};

export const EndInteraction = ({
  isOpen,
  activity,
  onUpdateAndSaveAndExit,
  onEndWithoutSaving,
  onClose,
  testId = 'end-interaction-dialog',
}: EndInteractionProps) => {
  // Hooks
  const { t } = useTranslation();
  const [isFromCrm] = useLocalStorage<boolean>('fromCrm', false);

  const {
    originalActivity,
    startDateTime,
    promotionalMaterialGroups,
    viewedMedia,
    topics,
    comments,
    signedSampleRequestForm,
    setMediaUpdatingKeyMessages,
    setTopics,
    leaveInteraction,
    goToInteractionSummary,
  } = useInteraction();
  const navigate = useNavigate();
  const { toast } = useNotification();

  const [updateActivityMutation, { loading }] = useUpdateActivityMutation();

  // States
  const [isEditTopicsDialogOpen, setIsEditTopicsDialogOpen] = useState(false);

  // Handlers
  const closeTopicsDialog = () => setIsEditTopicsDialogOpen(false);

  const handleSaveAndExit = async () => {
    if (!activity?.id) {
      navigate('/');

      return;
    }

    // updated topics base on viewed medias and added promotional material
    const activityTypeTopics = activity?.activityType?.topics ?? [];
    const currentTopics = [...topics, ...(activity.topics || [])];
    const shownTopics: Array<TopicData> = addingTopics(
      currentTopics,
      activityTypeTopics,
      viewedMedia,
      promotionalMaterialGroups
    );
    setTopics?.(shownTopics);

    if (isEmpty(shownTopics)) {
      setIsEditTopicsDialogOpen(true);

      return;
    }

    // update key messages for viewed medias: only display those which topics are include in shownTopics
    const updateViewedMedias = updateViewedMediaKeyMessages(
      viewedMedia,
      shownTopics
    );
    setMediaUpdatingKeyMessages?.(updateViewedMedias);

    const changes = prepareMutationForEndingInteraction({
      originalActivity,
      startDateTime,
      viewedMedias: updateViewedMedias,
      sampleRequestForm: signedSampleRequestForm,
      promotionalMaterialGroups,
      topics: shownTopics,
      comments,
    });

    await updateActivityMutation({
      variables: {
        oid: activity.oid ?? '',
        actions: [changes],
      },
      onCompleted: () => {
        toast?.({
          message: t('pages.interaction.toasts.remoteInteractionSaved'),
          type: 'success',
        });

        onUpdateAndSaveAndExit?.();
        onClose();

        if (isFromCrm) {
          redirectToCrm(false);
        } else {
          goToInteractionSummary();
        }
      },
      onError: () => {
        onClose();
      },
    });
  };

  const handleEndWithoutSaving = async () => {
    if (!activity?.id) {
      navigate('/');

      return;
    }

    onEndWithoutSaving?.();
    leaveInteraction();

    if (isFromCrm) {
      redirectToCrm(true);
    } else {
      navigate(`/touchpoint/${activity.id}`);
    }
  };

  const redirectToCrm = (toDashboard: boolean) => {
    if (
      activity?.activityType?.baseType &&
      !validInteractionType(activity?.activityType?.baseType)
    ) {
      return;
    }

    // User started from CRM, so we redirect to the crm
    return toDashboard
      ? redirectBackToCrm()
      : redirectToCrmEditActivity(activity?.oid);
  };

  return (
    <Box data-testid={testId}>
      <EditActivityDialog
        type="topics"
        activity={activity!}
        isOpen={isEditTopicsDialogOpen}
        onClose={() => {}}
        onSave={(updatedActivity: ActivityData) => {
          closeTopicsDialog();
          if (updatedActivity.topics) {
            setTopics?.(updatedActivity.topics);
          }

          return handleSaveAndExit();
        }}
        onCancel={() => {
          closeTopicsDialog();
          onClose();
        }}
      />
      <EndInteractionDialog
        isOpen={isOpen}
        closeDialog={onClose}
        handleSaveAndExit={handleSaveAndExit}
        handleEndWithoutSaving={handleEndWithoutSaving}
        shouldShowLoader={loading}
      />
    </Box>
  );
};
