import {
  PromotionalMaterialGroupBatchData,
  PromotionalMaterialGroupData,
} from '@ysura/common';

import { filterNonNull } from '@/utils/dataHelpers/filters';

export type SampleRequestFormSampleItemData = {
  physicalQuantity: number;
  virtualQuantity: number;
  name: string;
};

export const extractSampleRequestFormItems = (
  sampleDrops: Array<PromotionalMaterialGroupData>
): Array<SampleRequestFormSampleItemData> => {
  const handledDrops = sampleDrops
    .filter((each) => each.promotionalMaterial?.signatureRequired)
    .map((drop) => {
      if (!drop?.batches || drop.batches.length === 0) {
        return null;
      }

      const mappedBatches = (drop?.batches ?? []).map((batch) =>
        extractQuantities(batch)
      );

      const filteredItems = filterNonNull(mappedBatches);

      const summedItems = filteredItems.reduce(
        (acc, currentItem) => {
          return {
            physicalQuantity:
              acc.physicalQuantity + currentItem.physicalQuantity,
            virtualQuantity: acc.virtualQuantity + currentItem.virtualQuantity,
          };
        },
        {
          physicalQuantity: 0,
          virtualQuantity: 0,
        }
      );

      return {
        name: drop?.promotionalMaterial?.name ?? '',
        ...summedItems,
      };
    });

  return filterNonNull(handledDrops);
};

const extractQuantities = (batch: PromotionalMaterialGroupBatchData) => {
  const hasPhysicalStock = batch.physicalStock.requestedQuantity !== 0;
  const hasVirtualStock = batch.virtualStock.requestedQuantity !== 0;

  // Neither physical nor virtual stock quantity means we do not add it to the table.
  if (!hasPhysicalStock && !hasVirtualStock) {
    return null;
  }

  return {
    physicalQuantity: batch?.physicalStock?.requestedQuantity ?? 0,
    virtualQuantity: batch?.virtualStock?.requestedQuantity ?? 0,
  };
};
