import { ActivityData, NoItemPlaceholder } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIconButton } from '@/components/Button';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { TopicsList } from '@/components/TopicsList';
import { EditActivityDialog } from '@/views/Activity/Update';

type TopicsCardProps = {
  activity: ActivityData;
  canEditActivity?: boolean;
};

export const TopicsStaticBlock = ({
  activity,
  canEditActivity = false,
}: TopicsCardProps) => {
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleEditClicked = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditClosed = () => {
    setIsEditDialogOpen(false);
  };

  return (
    <OverviewStaticBlock
      title={t('pages.activity.topics')}
      subtitle={
        canEditActivity && <EditIconButton onClick={handleEditClicked} />
      }
    >
      {!activity.topics?.length ? (
        <NoItemPlaceholder>{t('pages.activity.noTopics')}</NoItemPlaceholder>
      ) : (
        <TopicsList topics={activity.topics} />
      )}
      <EditActivityDialog
        type="topics"
        activity={activity}
        isOpen={isEditDialogOpen}
        onClose={handleEditClosed}
      />
    </OverviewStaticBlock>
  );
};
