import {
  Button as MuiButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { addDays, formatISO, startOfDay } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivitySkeletonCard } from '@/components/Activity';
import { useCurrentUser, useFormatDate } from '@/hooks';
import {
  useGetActivityTypeListQuery,
  useGetTouchpointListByDateQuery,
} from '@/services/graphql/hooks';
import {
  parseActivityTypeConnection,
  parseTouchpointConnection,
} from '@/services/graphql/parsers';
import { hasAssignActivityTypePermission } from '@/services/permission';
import { CreateActivityDialog } from '@/views/Activity/TouchpointManagement';
import { MobileCalendar } from '@/views/CalendarActivities/MobileCalendar';

import { sortActivities } from './helper';
import { ScrollableCardList } from './ScrollableCardList';

type ActivityCardListProps = {
  selectedDate: Date;
  onChangeDate: (date: Date | null) => void;
};

export const ActivityCardList = ({
  selectedDate,
  onChangeDate,
}: ActivityCardListProps) => {
  const [isCreateActivityDialogOpen, setIsCreateActivityDialogOpen] =
    useState(false);

  const { t } = useTranslation();
  const { formatDate } = useFormatDate();
  const { loading: currentUserLoading, currentUser } = useCurrentUser();
  const isMatchedMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const selectedDateMidnight = startOfDay(selectedDate);
  const startDateTime = formatISO(selectedDateMidnight);
  const endDateTime = formatISO(addDays(selectedDateMidnight, 1));
  const currentUserPersonId = currentUser?.person?.oid;
  const { data, loading } = useGetTouchpointListByDateQuery({
    skip: currentUserLoading || !currentUser?.person?.oid,
    variables: {
      startDateTime,
      endDateTime,
      currentUserPersonId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: activityTypeData, loading: activityTypeLoading } =
    useGetActivityTypeListQuery();

  const activityList = data?.touchpoints
    ? parseTouchpointConnection(data.touchpoints)
    : [];

  const activityTypeList = parseActivityTypeConnection(
    activityTypeData?.activityTypes
  );

  const sortedActivities = sortActivities(activityList);

  const canCreateActivity =
    (currentUser?.permissions?.activity?.canCreateActivity &&
      hasAssignActivityTypePermission(activityTypeList)) ??
    false;

  const handleCloseCreateTouchpointDialog = () => {
    setIsCreateActivityDialogOpen(false);
  };

  const canReadActivity = currentUser?.permissions?.activity?.canReadActivity;

  return (
    <>
      <Container>
        <Wrapper>
          {canReadActivity && (
            <TypographyWrapper>
              <Typography variant="body2">
                {t('pages.homepage.touchpointList.touchpointsOn')}
              </Typography>
              <Typography variant="subtitle2">
                {formatDate(selectedDate, 'PP')}
              </Typography>
            </TypographyWrapper>
          )}

          {isMatchedMediaQuery && (
            <MobileCalendar
              selectedDate={selectedDate}
              onChangeDate={onChangeDate}
            />
          )}
        </Wrapper>

        {canReadActivity && (
          <>
            {canCreateActivity && (
              <Button
                variant="contained"
                data-testid="create-activity-btn"
                onClick={() => setIsCreateActivityDialogOpen(true)}
              >
                {t('components.touchpointManagement.createTouchpoint')}
              </Button>
            )}

            {(loading || activityTypeLoading) && (
              <>
                <SmallStack data-testid="activity-skeleton-card-list">
                  <ActivitySkeletonCard />
                  <ActivitySkeletonCard />
                  <ActivitySkeletonCard />
                </SmallStack>
              </>
            )}

            {data && <ScrollableCardList activityList={sortedActivities} />}
          </>
        )}
      </Container>

      <CreateActivityDialog
        isOpen={isCreateActivityDialogOpen}
        onClose={handleCloseCreateTouchpointDialog}
      />
    </>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const Wrapper = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const TypographyWrapper = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
