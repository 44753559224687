import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { ReactNode, useEffect, useState } from 'react';

import { CUSTOM_BREAKPOINTS, TELEPHONE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';

type InteractionMobileLayoutProps = {
  drawerBleeding: string;
  actionComp: ReactNode;
  contentComp: ReactNode;
  onChangeDrawer?: (change: boolean) => void;
};

export const TelephoneInteractionMobileLayout = ({
  drawerBleeding,
  contentComp,
  actionComp,
  onChangeDrawer,
}: InteractionMobileLayoutProps) => {
  const { isAnyContentShared, isStateServerInitialized } = useInteraction();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
    onChangeDrawer?.(isDrawerOpen);
  };

  useEffect(() => {
    if (!isAnyContentShared) {
      setIsDrawerOpen(false);
    }
  }, [isAnyContentShared]);

  return (
    <Wrapper>
      <TelephoneWrapper
        isDrawerOpen={isDrawerOpen || isAnyContentShared}
        drawerBleeding={drawerBleeding}
      >
        {isStateServerInitialized ? (
          <CallInfoWrapper
            isDrawerOpen={isDrawerOpen || isAnyContentShared}
            data-testid="call-info-wrapper"
          >
            {actionComp}
          </CallInfoWrapper>
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </TelephoneWrapper>

      <ExtendableBox isDrawerOpen={isDrawerOpen || isAnyContentShared}>
        {!isAnyContentShared && (
          <IconButton onClick={toggleDrawer}>
            {isDrawerOpen ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
          </IconButton>
        )}

        <ContentBox
          isDrawerOpen={isDrawerOpen || isAnyContentShared}
          isSharing={isAnyContentShared}
        >
          {contentComp}
        </ContentBox>
      </ExtendableBox>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey[800],
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
}));

type TelephoneWrapperProps = {
  isDrawerOpen: boolean;
  drawerBleeding?: string;
};

const TelephoneWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    ['isDrawerOpen', 'drawerBleeding'].indexOf(prop as string) === -1,
})<TelephoneWrapperProps>(({ isDrawerOpen, drawerBleeding, theme }) => ({
  height: isDrawerOpen ? 'auto' : `calc(100% - ${drawerBleeding})`,
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(1),
  // Hide the gap when isDrawerOpen is true or when sharing form or media (as the VideoWrapper is hidden by height: 0)
  gap: isDrawerOpen ? 0 : theme.spacing(1),

  // When form or media sharing happens or the drawer is opened we need to hide the top video container
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      height: isDrawerOpen
        ? 0
        : `calc(100% - ${TELEPHONE_INTERACTION.MOBILE.DRAWER_HEIGHT_PORTRAIT})`,
    },
}));

type CallInfoWrapperStyleProps = {
  isDrawerOpen: boolean;
};

const CallInfoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<CallInfoWrapperStyleProps>(({ isDrawerOpen }) => ({
  height: isDrawerOpen ? 'unset' : '100%',
  overflow: 'hidden',
}));

type ExtendableProps = {
  isDrawerOpen: boolean;
};

const ExtendableBox = styled(Box, {
  shouldForwardProp: (prop) => !(prop === 'isDrawerOpen'),
})<ExtendableProps>(({ isDrawerOpen, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  background: theme.palette.background.neutral,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
      maxHeight: isDrawerOpen
        ? `calc(100% - 70px)`
        : TELEPHONE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE,
    },
}));

const ContentBox = styled(Box, {
  shouldForwardProp: (prop) =>
    ['isDrawerOpen', 'isSharing'].indexOf(prop as string) === -1,
})<{ isDrawerOpen: boolean; isSharing: boolean }>(
  ({ theme, isDrawerOpen, isSharing }) => ({
    flexGrow: 1,
    padding: isSharing ? 0 : theme.spacing(2),
    overflowY: 'auto',
    [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
      {
        visibility: isDrawerOpen ? 'visible' : 'hidden',
      },
  })
);

const LoaderWrapper = styled(Box)({
  display: 'grid',
  height: '100%',
});
