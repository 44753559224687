import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

type DetailPageLayoutProps = {
  headerComp: ReactNode;
  firstColumnComp: ReactNode;
  middleColumnComp: ReactNode;
  lastColumnComp: ReactNode;
};
export const DetailPageLayout = ({
  headerComp,
  firstColumnComp,
  middleColumnComp,
  lastColumnComp,
}: DetailPageLayoutProps) => {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {/* Header */}
      <Grid item xs={12}>
        {headerComp}
      </Grid>

      {/* First column */}
      <Grid
        item
        container
        wrap={'nowrap'}
        xs={12}
        md={4}
        direction="column"
        rowSpacing={{ xs: 2, md: 3 }}
      >
        {firstColumnComp}
      </Grid>

      {/* Middle column */}
      <Grid
        item
        container
        wrap={'nowrap'}
        xs={12}
        md={4}
        direction="column"
        rowSpacing={{ xs: 2, md: 3 }}
      >
        {middleColumnComp}
      </Grid>

      {/* Last column  */}
      <LastGrid
        item
        container
        wrap={'nowrap'}
        xs={12}
        md={4}
        direction="column"
        rowSpacing={{ xs: 2, md: 3 }}
      >
        {lastColumnComp}
      </LastGrid>
    </Grid>
  );
};

const LastGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(5),
  },
}));
