import {
  Box,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent as MuiDialogContent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { ReactNode } from 'react';

type ConfirmationDialogButtonProps = {
  text: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | undefined;
  onClickHandler: VoidFunction;
  isButtonLoading?: boolean;
  isButtonDisabled?: boolean;
  testId?: string;
};

type ConfirmationDialogProps = {
  isOpen: boolean;
  title: string;
  content: string | ReactNode;
  primaryButton: ConfirmationDialogButtonProps;
  secondaryButton: ConfirmationDialogButtonProps;
  testId?: string;
};

export const ConfirmationDialog = ({
  isOpen,
  title,
  content,
  primaryButton,
  secondaryButton,
  testId,
}: ConfirmationDialogProps) => {
  const hasPrimaryButton =
    primaryButton && primaryButton?.text && primaryButton?.onClickHandler;
  const hasSecondaryButton =
    secondaryButton && secondaryButton?.text && secondaryButton?.onClickHandler;

  return (
    <Dialog data-testid={testId} open={isOpen} maxWidth="xs">
      <DialogTitle data-testid={testId + '-title'}>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {content && typeof content === 'string' ? (
          <WarningMessage variant="body2">{content}</WarningMessage>
        ) : (
          <>{content}</>
        )}
      </DialogContent>
      <DialogActions>
        {hasSecondaryButton && (
          <Button
            data-testid={secondaryButton.testId}
            variant={secondaryButton?.variant ?? 'outlined'}
            color={secondaryButton?.color ?? 'inherit'}
            disabled={
              secondaryButton.isButtonDisabled ||
              primaryButton.isButtonLoading ||
              secondaryButton.isButtonLoading
            }
            onClick={secondaryButton.onClickHandler}
          >
            {secondaryButton.isButtonLoading ? (
              <Loader size={22} />
            ) : (
              secondaryButton.text
            )}
          </Button>
        )}
        {hasPrimaryButton && (
          <Button
            data-testid={primaryButton.testId}
            variant={primaryButton?.variant ?? 'contained'}
            color={primaryButton?.color ?? 'primary'}
            disabled={
              primaryButton.isButtonDisabled ||
              primaryButton.isButtonLoading ||
              secondaryButton.isButtonLoading
            }
            onClick={primaryButton.onClickHandler}
          >
            <ActionSpinnerBox displayTextLength={primaryButton.text.length}>
              {primaryButton.isButtonLoading ? (
                <Loader size={22} />
              ) : (
                primaryButton.text
              )}
            </ActionSpinnerBox>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const DialogTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const DialogContent = styled(MuiDialogContent)({
  paddingTop: 0,
  paddingBottom: 0,
});

const WarningMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});

const ActionSpinnerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'displayTextLength',
})<{ displayTextLength: number }>(({ displayTextLength }) => ({
  minWidth: displayTextLength * 10 + 'px',
  height: '24px',
}));
