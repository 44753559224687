import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentTypeData,
  Label,
  OverviewCard,
  PersonConsentLifecycleStateDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { getLifecycleLabelColor } from '@/utils/getLifecycleLabelColor';

type ConsentCardProps = {
  consent: {
    id?: string;
    oid?: string;
    consentType?: ConsentTypeData;
    lifecycleState?: PersonConsentLifecycleStateDataEnum;
  };
  handleClickCard?: VoidFunction;
};

export const ConsentCard = ({ consent, handleClickCard }: ConsentCardProps) => {
  const { consentType, lifecycleState } = consent;
  const { t } = useTranslation();

  return (
    <OverviewCard testId="consent-card" handleClickCard={handleClickCard}>
      <ContentWrapper>
        <Typography data-testid="consent-name" variant="subtitle2">
          {consentType?.name}
        </Typography>
        <Box>
          {lifecycleState && (
            <Label
              variant="ghost"
              color={getLifecycleLabelColor(lifecycleState)}
            >
              {t(
                `pages.personView.personLifecycleState.${lifecycleState.toLowerCase()}`
              )}
            </Label>
          )}
        </Box>
        <StyleTypography variant="body2">
          {consentType?.description}
        </StyleTypography>
      </ContentWrapper>
    </OverviewCard>
  );
};

const ContentWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyleTypography = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));
