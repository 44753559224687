import { KeyMessageData } from '@ysura/common';

import { parseTopicConnection } from '@/services/graphql/parsers/topic/topicParser';
import {
  convertedReaction,
  convertedState,
} from '@/services/graphql/parsers/utils';
import {
  ActivityShownMediaKeyMessage,
  KeyMessage,
  KeyMessageConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

const parseShownMediaKeyMessage = (
  keyMessage?: DeepPartial<ActivityShownMediaKeyMessage>
): KeyMessageData | undefined => {
  if (!keyMessage?.keyMessage) {
    return undefined;
  }

  const innerKeyMessage: KeyMessageData = parseKeyMessage(
    keyMessage?.keyMessage
  );

  return {
    ...innerKeyMessage,
    reaction: convertedReaction(keyMessage?.reaction),
  };
};

export const parseShownMediaKeyMessages = (
  keyMessages?: Array<DeepPartial<ActivityShownMediaKeyMessage | undefined>>
): Array<KeyMessageData> => {
  if (!keyMessages) {
    return [];
  }

  const parsedKeyMessages = keyMessages.map((keyMessage) =>
    parseShownMediaKeyMessage(keyMessage ?? undefined)
  );

  return filterNonNull(parsedKeyMessages);
};

export const parseKeyMessage = (
  keyMessage?: DeepPartial<KeyMessage>
): KeyMessageData => {
  return {
    id: keyMessage?.id ?? undefined,
    oid: keyMessage?.oid ?? undefined,
    name: keyMessage?.name ?? undefined,
    description: keyMessage?.description ?? undefined,
    state: convertedState(keyMessage?.state),
    topics: keyMessage?.topics
      ? parseTopicConnection(keyMessage?.topics)
      : undefined,
  };
};

export const parseKeyMessageConnection = (
  keyMessages?: DeepPartial<KeyMessageConnection>
): Array<KeyMessageData> => {
  if (!keyMessages || !keyMessages?.edges?.length) {
    return [];
  }

  const parsedKeyMessages = keyMessages.edges.map((keyMessage) =>
    keyMessage?.node ? parseKeyMessage(keyMessage?.node) : null
  );

  return filterNonNull(parsedKeyMessages);
};
