import { Divider, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const SamplesSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.samples')}
      testId="samples-skeleton-static-block"
    >
      <Wrapper>
        <SmallStack>
          <Skeleton width={200} height={22} />
          <Skeleton width={64} height={22} />
        </SmallStack>

        <Divider />

        <SmallStack>
          <Skeleton width={200} height={22} />
          <Skeleton width={64} height={22} />
        </SmallStack>
      </Wrapper>
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
