import {
  CommunicationDataData,
  ConsentData,
  ConsentHistoryData,
  ConsentItemValueData,
  PersonConsentData,
} from '@ysura/common';

import {
  parseConsentTypeData,
  parseDocumentData,
  parsePersonData,
} from '@/services/graphql/parsers';
import {
  convertedCommunicationDataBaseType,
  convertedConsentAction,
  convertedPersonConsentLifecycleState,
} from '@/services/graphql/parsers/utils';
import {
  Person,
  PersonConsent,
  PersonConsentCommunicationDataValue,
  PersonConsentHistory,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

const parseConsentData = (
  consentData?: DeepPartial<PersonConsent>
): ConsentData => {
  return {
    id: consentData?.id,
    oid: consentData?.oid ?? undefined,
    consentType: parseConsentTypeData(consentData?.consentType),
    recCreatedAt: consentData?.recCreatedAt
      ? new Date(consentData?.recCreatedAt)
      : undefined,
    issuer: consentData?.issuer
      ? parsePersonData(consentData.issuer)
      : undefined,
    signer: consentData?.signer
      ? parsePersonData(consentData.signer)
      : undefined,
  };
};

const parseConsentContactInformationData = (
  contactInfo?: Array<
    DeepPartial<PersonConsentCommunicationDataValue> | undefined
  >
): Array<CommunicationDataData> => {
  if (!contactInfo) {
    return [];
  }

  const mappedCommunication = contactInfo.map((item) =>
    item ? parsePersonConsentCommunicationDataValueData(item) : null
  );

  return filterNonNull(mappedCommunication);
};

const parseConsentItemValues = (
  consentData?: DeepPartial<PersonConsent>
): Array<ConsentItemValueData> => {
  if (!consentData?.consentItemValues) {
    return [];
  }

  const givenConsentItemValues = consentData?.consentItemValues ?? [];
  const mappedConsentItemValue = givenConsentItemValues?.map(
    (item) =>
      item && {
        consentItem: {
          oid: item.consentItem?.oid ?? undefined,
          name: item.consentItem?.name ?? undefined,
          description: item.consentItem?.description ?? undefined,
        },
        consentItemState: item.consentItemState,
        communicationDataValues: parseConsentContactInformationData(
          item.communicationDataValues
        ),
      }
  );

  return filterNonNull(mappedConsentItemValue);
};

export const parseConsentHistoryData = (
  consentHistory: DeepPartial<PersonConsentHistory | undefined>
): ConsentHistoryData => {
  return {
    action: convertedConsentAction(consentHistory?.action),
    issuer: consentHistory?.issuer
      ? parsePersonData(consentHistory.issuer)
      : undefined,
    sequenceNumber: consentHistory?.sequenceNumber ?? undefined,
    createdAt: consentHistory?.createdAt
      ? new Date(consentHistory?.createdAt)
      : undefined,
    handledAt: consentHistory?.handledAt
      ? new Date(consentHistory?.handledAt)
      : undefined,
  };
};

export const parsePersonConsent = (
  consent?: DeepPartial<PersonConsent>
): PersonConsentData => {
  const consentData = consent?.consentType
    ? parseConsentData(consent)
    : {
        id: consent?.id ?? undefined,
        oid: consent?.oid ?? undefined,
      };

  const history =
    consent?.consentHistory?.map((consentHistory) => {
      return parseConsentHistoryData(consentHistory);
    }) ?? [];

  return {
    consentHistory: history,
    document: consent?.document
      ? parseDocumentData(consent?.document)
      : undefined,
    lifecycleState: convertedPersonConsentLifecycleState(
      consent?.lifecycleState
    ),
    consentItemValues: parseConsentItemValues(consent),
    ...consentData,
  };
};

export const parsePersonConsents = (person?: DeepPartial<Person> | null) => {
  if (!person || !person?.consents) {
    return [];
  }

  const consentList = person.consents?.edges?.map((consent) => {
    return consent?.node ? parsePersonConsent(consent?.node) : null;
  });

  return filterNonNull(consentList);
};

const parsePersonConsentCommunicationDataValueData = (
  item: DeepPartial<PersonConsentCommunicationDataValue>
): CommunicationDataData => {
  return {
    value: item.value ?? undefined,
    oid: item.consentCommunicationDataType?.oid ?? undefined,
    communicationDataType: {
      id:
        item.consentCommunicationDataType?.communicationDataType?.id ??
        undefined,
      name:
        item.consentCommunicationDataType?.communicationDataType?.name ??
        undefined,
      baseType: convertedCommunicationDataBaseType(
        item.consentCommunicationDataType?.communicationDataType?.baseType
      ),
    },
  };
};
