import { CommunicationDataData } from '@ysura/common';

import { convertedCommunicationDataBaseType } from '@/services/graphql/parsers/utils';
import { CommunicationDataConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseCommunicationDataData = (
  contactInfo?: DeepPartial<CommunicationDataConnection>
): Array<CommunicationDataData> => {
  if (!contactInfo) {
    return [];
  }

  const givenCommunication = contactInfo?.edges ?? [];
  const mappedCommunication = givenCommunication.map(
    (item) =>
      item?.node && {
        value: item?.node?.value ?? undefined,
        oid: item?.node?.oid ?? undefined,
        communicationDataType: {
          name: item?.node?.communicationDataType?.name ?? undefined,
          baseType: convertedCommunicationDataBaseType(
            item?.node?.communicationDataType?.baseType
          ),
        },
      }
  );

  return filterNonNull(mappedCommunication);
};
