import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetTouchpointListByPersonIdQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID']['input'];
  status?: Types.InputMaybe<Types.ActivityStatus>;
}>;

export type GetTouchpointListByPersonIdQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    activities: {
      __typename?: 'ActivityConnection';
      edges?: Array<{
        __typename?: 'ActivityEdge';
        node?: {
          __typename?: 'Activity';
          id: string;
          oid: string;
          state: Types.State;
          status: Types.ActivityStatus;
          startDate?: string | null;
          startDateTime?: string | null;
          endDate?: string | null;
          endDateTime?: string | null;
          activityType: {
            __typename?: 'ActivityType';
            id: string;
            oid: string;
            name: string;
            baseType: Types.ActivityTypeBaseType;
            schedulingType: Types.ActivityTypeScheduling;
            hideTimeForClosedActivity: boolean;
          };
          organization: {
            __typename?: 'Organization';
            id: string;
            oid: string;
            name?: string | null;
            addresses: {
              __typename?: 'EntityAddressConnection';
              edges?: Array<{
                __typename?: 'EntityAddressEdge';
                node?: {
                  __typename?: 'EntityAddress';
                  address: {
                    __typename?: 'Address';
                    addressLine1?: string | null;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    city: string;
                    postalCode: string;
                  };
                } | null;
              }> | null;
            };
            rootOrganization?: {
              __typename?: 'Organization';
              id: string;
              oid: string;
              name?: string | null;
            } | null;
          };
          organizer: {
            __typename?: 'Person';
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            prefixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              name?: string | null;
              state: Types.State;
            } | null;
            postfixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              name?: string | null;
              state: Types.State;
            } | null;
          };
          attendees: {
            __typename?: 'ActivityAttendeeConnection';
            edges?: Array<{
              __typename?: 'ActivityAttendeeEdge';
              node?: {
                __typename?: 'ActivityAttendee';
                person?: {
                  __typename?: 'Person';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefixAcademicTitle?: {
                    __typename?: 'AcademicTitle';
                    name?: string | null;
                    state: Types.State;
                  } | null;
                  postfixAcademicTitle?: {
                    __typename?: 'AcademicTitle';
                    name?: string | null;
                    state: Types.State;
                  } | null;
                } | null;
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetTouchpointListByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetTouchpointListByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTouchpointListByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTouchpointListByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetTouchpointListByPersonIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTouchpointListByPersonIdQuery,
    GetTouchpointListByPersonIdQueryVariables
  > &
    (
      | { variables: GetTouchpointListByPersonIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetTouchpointListByPersonIdQuery,
    GetTouchpointListByPersonIdQueryVariables
  >(Operations.getTouchpointListByPersonId, options);
}
export function useGetTouchpointListByPersonIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTouchpointListByPersonIdQuery,
    GetTouchpointListByPersonIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetTouchpointListByPersonIdQuery,
    GetTouchpointListByPersonIdQueryVariables
  >(Operations.getTouchpointListByPersonId, options);
}
export function useGetTouchpointListByPersonIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTouchpointListByPersonIdQuery,
    GetTouchpointListByPersonIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetTouchpointListByPersonIdQuery,
    GetTouchpointListByPersonIdQueryVariables
  >(Operations.getTouchpointListByPersonId, options);
}
export type GetTouchpointListByPersonIdQueryHookResult = ReturnType<
  typeof useGetTouchpointListByPersonIdQuery
>;
export type GetTouchpointListByPersonIdLazyQueryHookResult = ReturnType<
  typeof useGetTouchpointListByPersonIdLazyQuery
>;
export type GetTouchpointListByPersonIdSuspenseQueryHookResult = ReturnType<
  typeof useGetTouchpointListByPersonIdSuspenseQuery
>;
export type GetTouchpointListByPersonIdQueryResult = Apollo.QueryResult<
  GetTouchpointListByPersonIdQuery,
  GetTouchpointListByPersonIdQueryVariables
>;
