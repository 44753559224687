import { OrganizationTypeData } from '@ysura/common';

import { convertedOrganizationBaseType } from '@/services/graphql/parsers/utils';
import {
  OrganizationType,
  OrganizationTypeConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseOrganizationTypeData = (
  organizationType: DeepPartial<OrganizationType>
): OrganizationTypeData => {
  return {
    id: organizationType?.id,
    oid: organizationType?.oid,
    name: organizationType?.name ?? undefined,
    baseType: convertedOrganizationBaseType(organizationType?.baseType),
  };
};

export const parseOrganizationTypeConnection = (
  organizationTypeConnection: DeepPartial<OrganizationTypeConnection>
): Array<OrganizationTypeData> => {
  const rawOrganizationTypes = organizationTypeConnection?.edges ?? [];
  const parsedOrganizationTypes = rawOrganizationTypes
    .map((edge) => edge?.node ?? null)
    .map((organizationType) =>
      organizationType ? parseOrganizationTypeData(organizationType) : null
    );

  return filterNonNull(parsedOrganizationTypes);
};
