import { TopicData } from '@ysura/common';

import { Topic, TopicConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseTopicData = (topic?: DeepPartial<Topic>): TopicData => {
  return {
    id: topic?.id,
    oid: topic?.oid,
    name: topic?.name,
  };
};

export const parseTopicConnection = (
  topics?: DeepPartial<TopicConnection>
): Array<TopicData> => {
  const rawTopics = topics?.edges ?? [];
  const mappedTopics = rawTopics
    .map((edge) => edge?.node ?? null)
    .map((topic) => (topic ? parseTopicData(topic) : null));

  return filterNonNull(mappedTopics);
};
