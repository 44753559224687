import { Person } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface EmploymentDataStaticBlockProps {
  totalEmployeeCounts: number;
  totalAllEmployeeCounts: number;
  hasParentOrganization?: boolean;
}

export const EmploymentDataStaticBlock = ({
  totalEmployeeCounts,
  totalAllEmployeeCounts,
  hasParentOrganization,
}: EmploymentDataStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <ContentStack data-testid="employment-statistic-data">
      <DisableTypography variant="caption">
        {t('pages.organizationView.personDataCard.totalEmploymentsCount')}
      </DisableTypography>
      <StyledBox>
        <Person />
        <Typography variant="caption">{totalEmployeeCounts ?? 0}</Typography>
      </StyledBox>
      {hasParentOrganization && (
        <>
          <DisableTypography variant="caption">
            {t(
              'pages.organizationView.personDataCard.totalRootEmploymentsCount'
            )}
          </DisableTypography>
          <StyledBox>
            <Person />
            <Typography variant="caption">{totalAllEmployeeCounts}</Typography>
          </StyledBox>
        </>
      )}
    </ContentStack>
  );
};

const ContentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));
