import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  ColumnContentWrapper,
  RequiredField,
  SectionLabel,
  SectionWrapper,
} from '../SharedStyledComponents';

export const AttendeeColumnSkeleton = () => {
  const { t } = useTranslation();

  return (
    <ColumnContentWrapper data-testid="attendee-column-skeleton">
      <SectionWrapper>
        <MediumBox>
          <SectionLabel variant="subtitle2">
            {`${t('components.touchpointManagement.attendee')} (...)`}
            <RequiredField>*</RequiredField>
          </SectionLabel>
        </MediumBox>
        <Skeleton width={'100%'} height={56} />
      </SectionWrapper>

      <SectionWrapper>
        <Skeleton width={70} height={22} />

        <Skeleton width={'100%'} height={56} />
      </SectionWrapper>
    </ColumnContentWrapper>
  );
};

const MediumBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
