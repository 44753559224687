import { useMediaQuery, useTheme } from '@mui/material';
import {
  ActivityData,
  ConsentCollectionColumn,
  ConsentViewerPayload,
  Media,
  MediaSharingColumn,
  PersonConsentData,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentWrapper, DrawerContentWrapper } from '@/components/Interaction';
import { REMOTE_INTERACTION } from '@/config/layout';
import { useInteraction, useNotification } from '@/hooks';
import { InteractionRemoteContent } from '@/views/Interaction/Remote';
import {
  ConsentCollection,
  MediaSharingContent,
  ProMatCollection,
  ScreenShareColumn,
} from '@/views/Interaction/Remote/Room';
import {
  CoOrganizerConsentCollection,
  CoOrganizerPDFMedia,
  CoOrganizerSampleRequestCollection,
} from '@/views/Interaction/Remote/Room/CoOrganizer';

import { InteractionInformationAccordionList } from '../InteractionInformation';

type InteractionItemsProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  roomId: string;
  isDrawerOpen: boolean;
  toggleDrawer: VoidFunction;
  isLoading?: boolean;
  canEditActivity?: boolean;
};

export const InteractionItems = ({
  isDrawerOpen,
  activity,
  isOrganizer,
  consentList,
  toggleDrawer,
  isLoading = false,
  canEditActivity,
}: InteractionItemsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const { toast } = useNotification();

  const {
    currentRole,
    currentSampleRequest,
    setCurrentSampleRequest,
    isParticipantsScreenShared,
    isAnyContentShared,
    consentSharedByOrganizer,
    consentSharedForCoOrganizer,
    setConsentSharedForCoOrganizer,
    mediaSharedByOrganizer,
    mediaSharedForCoOrganizer,
    setMediaSharedForCoOrganizer,
    onConsentFormOpened,
    onConsentClosed,
    onConsentFormAttendeeSubmitted,
    onSampleRequestOpened,
    onSampleRequestClosed,
    onSampleRequestAttendeeSubmitted,
    onMediaOpened,
    onMediaClosed,
    onMediaStateChanged,
    onPointerShown,
    broadcastPointerShow,
  } = useInteraction();

  const [attendeeConsentSubmitting, setAttendeeConsentSubmitting] =
    useState<boolean>(false);

  const [attendeeSampleRequestSubmitting, setAttendeeSampleRequestSubmitting] =
    useState<boolean>(false);

  useEffect(() => {
    const handleShowConsentForm = (data: ConsentViewerPayload) => {
      setConsentSharedForCoOrganizer(data);
    };

    const handleCloseConsentForm = () => {
      if (!isAnyContentShared) {
        return;
      }

      if (attendeeConsentSubmitting) {
        toast?.({
          message: t('pages.interaction.toasts.consentFormSubmitted'),
          type: 'success',
        });
      } else {
        toast?.({
          message: t('pages.interaction.toasts.consentFormCanceled'),
          type: 'warning',
        });
      }
      setConsentSharedForCoOrganizer(null);
    };

    const unsubscribeOnConsentFormOpen = onConsentFormOpened?.(
      handleShowConsentForm
    );

    const unsubscribeOnConsentClose = onConsentClosed?.(handleCloseConsentForm);

    const unsubscribeOnConsentFormSubmitted = onConsentFormAttendeeSubmitted?.(
      () => setAttendeeConsentSubmitting(true)
    );

    return () => {
      if (unsubscribeOnConsentFormOpen) {
        unsubscribeOnConsentFormOpen();
      }
      if (unsubscribeOnConsentClose) {
        unsubscribeOnConsentClose();
      }
      if (unsubscribeOnConsentFormSubmitted) {
        unsubscribeOnConsentFormSubmitted();
      }
    };
  }, [
    isAnyContentShared,
    onConsentFormOpened,
    onConsentClosed,
    onConsentFormAttendeeSubmitted,
    attendeeConsentSubmitting,
    toast,
    t,
    setConsentSharedForCoOrganizer,
  ]);

  useEffect(() => {
    const handleShowSampleRequest = (data: SampleRequestViewerPayload) => {
      setCurrentSampleRequest?.(data);
    };

    const handleCloseSampleRequest = () => {
      if (attendeeSampleRequestSubmitting) {
        toast?.({
          message: t('pages.interaction.toasts.sampleRequestFormSubmitted'),
          type: 'success',
        });
      } else {
        toast?.({
          message: t('pages.interaction.toasts.sampleRequestFormCanceled'),
          type: 'warning',
        });
      }
      setCurrentSampleRequest?.(null);
    };

    const unsubscribeOnSampleRequestOpen = onSampleRequestOpened?.(
      handleShowSampleRequest
    );
    const unsubscribeOnSampleRequestClose = onSampleRequestClosed?.(
      handleCloseSampleRequest
    );
    const unsubscribeOnSampleRequestSubmitted =
      onSampleRequestAttendeeSubmitted?.(() =>
        setAttendeeSampleRequestSubmitting(true)
      );

    return () => {
      if (unsubscribeOnSampleRequestOpen) {
        unsubscribeOnSampleRequestOpen();
      }
      if (unsubscribeOnSampleRequestClose) {
        unsubscribeOnSampleRequestClose();
      }
      if (unsubscribeOnSampleRequestSubmitted) {
        unsubscribeOnSampleRequestSubmitted();
      }
    };
  }, [
    onSampleRequestOpened,
    onSampleRequestClosed,
    setCurrentSampleRequest,
    onSampleRequestAttendeeSubmitted,
    attendeeSampleRequestSubmitting,
    toast,
    t,
  ]);

  useEffect(() => {
    const handleShowMedia = (media: Media) => {
      setMediaSharedForCoOrganizer({ media });
    };

    const handleCloseMedia = () => {
      setMediaSharedForCoOrganizer(null);
    };

    const unsubscribeOnMediaOpen = onMediaOpened?.(handleShowMedia);
    const unsubscribeOnMediaClose = onMediaClosed?.(handleCloseMedia);

    return () => {
      if (unsubscribeOnMediaOpen) {
        unsubscribeOnMediaOpen();
      }
      if (unsubscribeOnMediaClose) {
        unsubscribeOnMediaClose();
      }
    };
  }, [onMediaClosed, onMediaOpened, setMediaSharedForCoOrganizer]);

  const firstAttendee = activity?.attendees?.[0]?.person;

  const renderContent = () => {
    // Consent collection
    if (currentRole === 'organizer') {
      if (consentSharedByOrganizer) {
        return <ConsentCollection attendee={firstAttendee} />;
      }
      // ProMat collection
      if (currentSampleRequest) {
        return <ProMatCollection activity={activity} />;
      }
      // PDF and HTML media presentation
      if (mediaSharedByOrganizer) {
        return (
          <MediaSharingContent
            sharedMedia={mediaSharedByOrganizer}
            isReadOnlyMode={false}
          />
        );
      }
    } else {
      if (consentSharedForCoOrganizer) {
        return (
          <ConsentCollectionColumn>
            <CoOrganizerConsentCollection
              consent={consentSharedForCoOrganizer}
            />
          </ConsentCollectionColumn>
        );
      }

      if (currentSampleRequest) {
        return (
          <CoOrganizerSampleRequestCollection payload={currentSampleRequest} />
        );
      }

      if (mediaSharedForCoOrganizer) {
        return (
          <MediaSharingColumn
            media={mediaSharedForCoOrganizer?.media}
            initialPage={mediaSharedForCoOrganizer?.initialPage}
            broadcastPointerShow={broadcastPointerShow}
            PdfMediaComponent={
              <CoOrganizerPDFMedia
                media={mediaSharedForCoOrganizer?.media}
                initialPage={mediaSharedForCoOrganizer?.initialPage?.indexh}
              />
            }
            onMediaStateChanged={onMediaStateChanged}
            onPointerShown={onPointerShown}
          />
        );
      }
    }

    // For mobile devices, render interaction items with accordions
    if (isMobile) {
      if (isParticipantsScreenShared) {
        return null;
      }

      return (
        <InteractionInformationAccordionList
          isInRemoteRoom
          isMediaViewedStatusUpdatedOnOpen
          isOrganizer={isOrganizer}
          activity={activity}
          consentList={consentList}
          isActivityEditable={canEditActivity}
        />
      );
    }

    // For desktop devices, render interaction card
    return (
      <InteractionRemoteContent
        isOrganizer={isOrganizer}
        consentList={consentList}
        activity={activity}
        isLoading={isLoading}
        isActivityEditable={canEditActivity}
      />
    );
  };

  return isMobile ? (
    <DrawerContentWrapper
      isDrawerOpen={isDrawerOpen || isParticipantsScreenShared}
      isSharing={isAnyContentShared}
      drawerBleeding={REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_PORTRAIT}
      toggleDrawer={toggleDrawer}
    >
      <ScreenShareColumn
        activity={activity}
        attendee={firstAttendee}
        canEditActivity={canEditActivity}
      />

      {renderContent()}
    </DrawerContentWrapper>
  ) : (
    <>
      <ScreenShareColumn
        activity={activity}
        attendee={firstAttendee}
        canEditActivity={canEditActivity}
      />

      {!isParticipantsScreenShared && (
        <ContentWrapper flex={2}>{renderContent()}</ContentWrapper>
      )}
    </>
  );
};
