import { useLocalStorage } from '@ysura/common';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { OrderBy } from '@/services/graphql/types';

type UseMediaContextInitialValue = {
  mediaLayout: string;
  changeMediaLayout?: FunctionStringCallback;
  mediaOrder: OrderBy;
  changeMediaOrder?: FunctionStringCallback;
  mediaQueryString: string;
  changeMediaQueryString?: FunctionStringCallback;
};

export const useMediaContextInitialValue: UseMediaContextInitialValue = {
  mediaLayout: 'grid',
  mediaOrder: OrderBy.ASC,
  mediaQueryString: '',
};

const MediaContext = createContext(useMediaContextInitialValue);

export const MediaContextProvider: FC = (props) => {
  const [mediaLayout, setMediaLayout] = useLocalStorage<'grid' | 'list'>(
    'mediaLayout',
    'grid'
  );
  const [mediaOrder, setMediaOrder] = useLocalStorage<OrderBy>(
    'mediaOrder',
    OrderBy.ASC
  );
  const [mediaQueryString, setMediaQueryString] = useState('');

  const changeMediaLayout = useCallback(
    (newLayout) => {
      setMediaLayout(newLayout);
    },
    [setMediaLayout]
  );
  const changeMediaOrder = useCallback(
    (newOrder) => {
      setMediaOrder(newOrder);
    },
    [setMediaOrder]
  );
  const changeMediaQueryString = useCallback(
    (newQueryString) => {
      setMediaQueryString(newQueryString);
    },
    [setMediaQueryString]
  );

  const values = useMemo(
    () => ({
      mediaLayout,
      changeMediaLayout,
      mediaOrder,
      changeMediaOrder,
      mediaQueryString,
      changeMediaQueryString,
    }),
    [
      mediaLayout,
      changeMediaLayout,
      mediaOrder,
      changeMediaOrder,
      mediaQueryString,
      changeMediaQueryString,
    ]
  );

  return <MediaContext.Provider value={values} {...props} />;
};

export const useMediaContext = () => {
  return useContext(MediaContext);
};
