import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityStatusDataEnum,
  CommentData,
  CommentTypeData,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';
import { CommentEditedDialog, CommentsBlock } from '@/components/Comment/index';
import { useCurrentUser, useInteraction } from '@/hooks';

type CommentAccordionBlockProps = {
  defaultExpanded?: boolean;
  isLoading?: boolean;
  isActivityEditable?: boolean;
  commentTypes?: CommentTypeData[];
  status?: ActivityStatusDataEnum;
};

export const CommentAccordionBlock = ({
  commentTypes,
  status,
  defaultExpanded = false,
  isLoading = false,
  isActivityEditable = false,
}: CommentAccordionBlockProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const { isOwnScreenShared, comments, setComments } = useInteraction();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { currentUser } = useCurrentUser();

  const handleAccordionToggle = () => {
    if (isLoading) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (isOwnScreenShared) {
      setExpanded(false);
    }
  }, [isOwnScreenShared]);

  const handleClose = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  const handleSave = (comments: CommentData[]) => {
    setComments(comments);
    setIsDialogOpen((prevState) => !prevState);
  };

  return (
    <OverviewAccordionBlock
      expanded={expanded}
      title={t('pages.interaction.remote.comment')}
      onChange={handleAccordionToggle}
    >
      <CommentsBlock comments={comments} status={status} />
      {isActivityEditable &&
        currentUser?.permissions?.activity?.canEditActivityComment && (
          <StyledButton variant="outlined" onClick={handleClose}>
            {t('pages.interaction.common.addEdit')}
          </StyledButton>
        )}
      {isDialogOpen && (
        <CommentEditedDialog
          isOpen={isDialogOpen}
          save={handleSave}
          closeDialog={handleClose}
          sourceComments={comments}
          commentTypes={commentTypes}
        />
      )}
    </OverviewAccordionBlock>
  );
};

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;
