import { Call, CallEnd, Info } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AttendeeData, ParticipantBox, RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useCurrentUser } from '@/hooks';
import { getPersonFullName } from '@/services/helper';
import { ScreenShareButton } from '@/views/Interaction/Common';

type CallStatusInfoProps = {
  attendees?: AttendeeData[] | undefined;
  isMobile?: boolean;
  isInteraction?: boolean;
  isFullScreen?: boolean;
  onCallInfoDialog?: VoidFunction;
  onStartInteraction?: VoidFunction;
  onEndInteraction?: VoidFunction;
  onCancelInteraction?: VoidFunction;
};

export const CallStatusContentActions = ({
  attendees,
  onStartInteraction,
  onEndInteraction,
  onCallInfoDialog,
  onCancelInteraction,
  isMobile = false,
  isInteraction = false,
  isFullScreen = false,
}: CallStatusInfoProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const infoRoomButton = (
    <RoundButton
      size="large"
      variant="secondary"
      onClick={() => onCallInfoDialog?.()}
    >
      <Info />
    </RoundButton>
  );

  return (
    <Line
      data-testid="call-status-content-actions"
      isInteraction={isInteraction}
      isFullScreen={isFullScreen}
    >
      {!isMobile ? (
        isFullScreen ? (
          <FullScreenWrapper>
            <ParticipantBoxWrapper>
              <ParticipantBox
                showAsThumbnail
                dark
                attendeeName={getPersonFullName(currentUser?.person)}
                size="small"
              />
              {attendees?.map((attendee) => (
                <ParticipantBox
                  key={attendee.id}
                  showAsThumbnail
                  attendeeName={getPersonFullName(attendee.person)}
                  size="small"
                />
              ))}
            </ParticipantBoxWrapper>

            {infoRoomButton}
          </FullScreenWrapper>
        ) : (
          <>
            <Typography variant="subtitle2">
              {t('pages.interaction.telephone.callStatus.callStatusInfo.title')}
            </Typography>
            <ButtonsStyled>
              {infoRoomButton}
              {currentUser?.permissions?.activity
                ?.canUseTelephoneInteractionScreenShare && (
                <ScreenShareButton
                  testId="screen-sharing-controls"
                  variant="round"
                  shouldBroadcast={true}
                  participantSharingId={currentUser?.person?.oid}
                />
              )}
            </ButtonsStyled>
          </>
        )
      ) : (
        <>
          {isInteraction ? (
            <>
              {infoRoomButton}
              <RoundButton
                size="medium"
                variant="danger"
                onClick={() => onEndInteraction?.()}
              >
                <CallEnd />
              </RoundButton>
            </>
          ) : (
            <>
              <RoundButton
                size="medium"
                variant="danger"
                onClick={() => onCancelInteraction?.()}
              >
                <CallEnd />
              </RoundButton>

              <RoundButton
                size="medium"
                variant="primary"
                onClick={() => onStartInteraction?.()}
              >
                <Call />
              </RoundButton>
            </>
          )}
        </>
      )}
    </Line>
  );
};

type LineProps = {
  isInteraction: boolean;
  isFullScreen: boolean;
};
const Line = styled(Box, {
  shouldForwardProp: (prop) =>
    ['isInteraction', 'isFullScreen'].indexOf(prop as string) === -1,
})<LineProps>(({ theme, isInteraction, isFullScreen }) => ({
  height: isFullScreen ? '100%' : 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.text.secondary,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: isInteraction ? 'column-reverse' : 'row',
      padding: `${theme.spacing(1)}`,
      background: theme.palette.grey[800],
    },
}));

const FullScreenWrapper = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ParticipantBoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const ButtonsStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
