import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';
import { PromotionalMaterialSkeletonCard } from '@/views/Interaction/Overview/PromotionalMaterial';

type PromotionalMaterialSkeletonStaticBlockProps = {
  isInRemoteRoom?: boolean;
};

export const PromotionalMaterialSkeletonStaticBlock = ({
  isInRemoteRoom = false,
}: PromotionalMaterialSkeletonStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.interaction.common.promatColumnTitle')}
      spacing={isInRemoteRoom ? 'dense' : 'spacious'}
      testId="promotional-material-skeleton-static-block"
    >
      <PromotionalMaterialWrapper>
        <PromotionalMaterialSkeletonCard />
        <SmallBox>
          <Skeleton width={80} height={22} />
        </SmallBox>
      </PromotionalMaterialWrapper>
    </OverviewStaticBlock>
  );
};

const SmallBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const PromotionalMaterialWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
