import { Grid } from '@mui/material';
import { EntityFilterTypeDataEnum } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPageLayout } from '@/components/DetailPageLayout';
import { convertPersonFilterValue } from '@/components/GlobalSearch/utils';
import { PageHeader } from '@/components/PageHeader';
import {
  EditSettingDialog,
  ItemsToUpdate,
  LabelType,
  SettingsFiltersListComponent,
} from '@/components/Settings/common';
import {
  checkPersonFilterPermission,
  EditFilterData,
  extractPersonFilters,
  FilterPermissionData,
  FilterTypeData,
  prepareSettingsForUpdatePreferenceMutation,
} from '@/components/Settings/helper';
import {
  errorConfigBannerDialog,
  useCurrentUser,
  useNotification,
} from '@/hooks';
import {
  useGetPreferencesQuery,
  useUpdatePreferenceMutation,
} from '@/services/graphql/hooks';
import { parsePersonPreference } from '@/services/graphql/parsers/person';
import { UpdatePreferenceAction } from '@/services/graphql/types';

import { PersonSettingComponentSkeleton } from './PersonSettingComponentSkeleton';

export const PersonSettingComponent = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { toast } = useNotification();

  const [isVisibleEditDialog, setIsVisibleEditDialog] = useState(false);
  const [isEditableFilter, setEditableFilter] = useState<EditFilterData | null>(
    null
  );

  const preferenceId = currentUser?.preferenceId ?? '';

  // Query
  const { data: preferencesData, loading } = useGetPreferencesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      preferenceId,
    },
    skip: !preferenceId,
  });

  const [updatePreferenceMutation, { loading: isUpdating }] =
    useUpdatePreferenceMutation();

  const personFacets = parsePersonPreference(
    preferencesData?.preference?.facets?.personFacets
  );
  const filters = convertPersonFilterValue(personFacets);

  // Labels to display
  const personFilters = extractPersonFilters(filters);
  const personTypes = personFilters.get('personTypes') ?? [];
  const personTypesDisplay = personTypes.map(({ name }) => {
    return { name };
  });
  const employeeFunctions = personFilters.get('employeeFunctions') ?? [];
  const employeeFunctionsDisplay = employeeFunctions.map(({ name }) => {
    return { name };
  });
  const specialities = personFilters.get('specialities') ?? [];
  const specialitiesDisplay: LabelType[] = specialities.map(
    ({ name, avatar }) => {
      return {
        name: name ?? '',
        avatar: { name: avatar ?? '', type: 'speciality' },
      };
    }
  );
  const specialityGroups = personFilters.get('specialityGroups') ?? [];
  const specialityGroupsDisplay = specialityGroups.map(({ name }) => {
    return { name };
  });
  const consentTypes = personFilters.get('consentTypes') ?? [];
  const consentTypesDisplay = consentTypes.map(({ name }) => {
    return { name };
  });
  const attributes = personFilters.get('attributes') ?? [];
  const attributesDisplay: LabelType[] = attributes.map(({ name, avatar }) => {
    return {
      name: name ?? '',
      avatar: { name: avatar ?? '', type: 'attribute' },
    };
  });

  // Permission
  const personPermission: FilterPermissionData = checkPersonFilterPermission(
    currentUser?.permissions?.person
  );

  // Mutation
  const handleSave = (itemsToUpdate: ItemsToUpdate) => {
    if (!preferencesData) {
      return;
    }

    const personFacets = parsePersonPreference(
      preferencesData?.preference?.facets?.personFacets
    );

    const changes: UpdatePreferenceAction =
      prepareSettingsForUpdatePreferenceMutation(
        currentUser?.person,
        itemsToUpdate,
        EntityFilterTypeDataEnum.PERSON,
        personFacets,
        undefined
      );

    updatePreferenceMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: preferenceId,
        actions: [changes],
      },
      onCompleted: () => {
        setIsVisibleEditDialog(false);
        toast?.({
          message: t('components.settings.edit.success'),
          type: 'success',
        });
      },
    });
  };

  if (loading) {
    return <PersonSettingComponentSkeleton />;
  }

  const handleFilterEdition = (entity: FilterTypeData) => {
    setIsVisibleEditDialog(true);

    setEditableFilter({
      entity: entity,
      displayName: t(`components.settings.person.${entity}.title`),
      selectedItems:
        personFilters?.get(entity)?.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [],
    });
  };

  return (
    <>
      <DetailPageLayout
        headerComp={
          <PageHeader title={t(`components.settings.person.title`)} />
        }
        firstColumnComp={
          <>
            <Grid item>
              {personPermission.permission.includes('personTypes') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.person.personTypes.title')}
                  noItemsText={t(
                    'components.settings.person.personTypes.noItems'
                  )}
                  labels={personTypesDisplay}
                  onClickEdit={() => handleFilterEdition('personTypes')}
                />
              )}
            </Grid>
            <Grid item>
              {personPermission.permission.includes('employeeFunctions') && (
                <SettingsFiltersListComponent
                  title={t(
                    'components.settings.person.employeeFunctions.title'
                  )}
                  noItemsText={t(
                    'components.settings.person.employeeFunctions.noItems'
                  )}
                  labels={employeeFunctionsDisplay}
                  onClickEdit={() => handleFilterEdition('employeeFunctions')}
                />
              )}
            </Grid>
          </>
        }
        middleColumnComp={
          <>
            <Grid item>
              {personPermission.permission.includes('specialities') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.person.specialities.title')}
                  noItemsText={t(
                    'components.settings.person.specialities.noItems'
                  )}
                  labels={specialitiesDisplay}
                  onClickEdit={() => handleFilterEdition('specialities')}
                />
              )}
            </Grid>
            <Grid item>
              {personPermission.permission.includes('specialityGroups') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.person.specialityGroups.title')}
                  noItemsText={t(
                    'components.settings.person.specialityGroups.noItems'
                  )}
                  labels={specialityGroupsDisplay}
                  onClickEdit={() => handleFilterEdition('specialityGroups')}
                />
              )}
            </Grid>
          </>
        }
        lastColumnComp={
          <>
            <Grid item>
              {personPermission.permission.includes('consentTypes') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.person.consentTypes.title')}
                  noItemsText={t(
                    'components.settings.person.consentTypes.noItems'
                  )}
                  labels={consentTypesDisplay}
                  onClickEdit={() => handleFilterEdition('consentTypes')}
                />
              )}
            </Grid>
            <Grid item>
              {personPermission.permission.includes('attributes') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.person.attributes.title')}
                  noItemsText={t(
                    'components.settings.person.attributes.noItems'
                  )}
                  labels={attributesDisplay}
                  onClickEdit={() => handleFilterEdition('attributes')}
                />
              )}
            </Grid>
          </>
        }
      ></DetailPageLayout>
      {isVisibleEditDialog && isEditableFilter && (
        <EditSettingDialog
          isOpen={isVisibleEditDialog}
          isUpdating={isUpdating}
          aggregate={EntityFilterTypeDataEnum.PERSON}
          entity={isEditableFilter.entity}
          displayName={isEditableFilter.displayName}
          selectedItems={isEditableFilter.selectedItems}
          onCancel={() => setIsVisibleEditDialog(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
};
