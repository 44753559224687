import {
  Box,
  Skeleton,
  TableCell,
  TableRow as MuiTableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MediaListItemSkeleton = () => {
  return (
    <TableRow data-testid="media-list-item-skeleton">
      <NameTableCell>
        <Skeleton variant="rounded" height={64} width={64} />

        <NameWrapper>
          <BoldTextSkeleton width={200} />
          <TextSkeleton width={160} />
        </NameWrapper>
      </NameTableCell>

      <NoPortraitTableCell>
        <Skeleton variant="rounded" height={22} width={64} />
      </NoPortraitTableCell>
    </TableRow>
  );
};

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  ...theme.shape,
  border: '1px solid',
  borderColor: theme.palette.grey[500_12],
  boxShadow: theme.customShadows.card,
  '.MuiTableCell-sizeMedium:first-of-type': {
    paddingLeft: theme.spacing(2),
  },
}));

const NameTableCell = styled(TableCell)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

const NameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const BoldTextSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

const TextSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
}));

const NoPortraitTableCell = styled(TableCell)(({ theme }) => ({
  display: 'table-cell',
  verticalAlign: 'top',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
