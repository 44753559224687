import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonAvailableCommunicationDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  baseType?: Types.InputMaybe<Types.CommunicationDataBaseType>;
}>;

export type GetPersonAvailableCommunicationDataQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    communicationData: {
      __typename?: 'CommunicationDataConnection';
      edges?: Array<{
        __typename?: 'CommunicationDataEdge';
        node?: {
          __typename?: 'CommunicationData';
          oid?: string | null;
          value?: string | null;
          communicationDataType: {
            __typename?: 'CommunicationDataType';
            name?: string | null;
            baseType?: Types.CommunicationDataBaseType | null;
          };
        } | null;
      }> | null;
    };
    employments: {
      __typename?: 'EmploymentConnection';
      filteredCount: number;
      edges?: Array<{
        __typename?: 'EmploymentEdge';
        node?: {
          __typename?: 'Employment';
          id: string;
          oid: string;
          communicationData: {
            __typename?: 'CommunicationDataConnection';
            edges?: Array<{
              __typename?: 'CommunicationDataEdge';
              node?: {
                __typename?: 'CommunicationData';
                oid?: string | null;
                value?: string | null;
                communicationDataType: {
                  __typename?: 'CommunicationDataType';
                  name?: string | null;
                  baseType?: Types.CommunicationDataBaseType | null;
                };
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
    consents: {
      __typename?: 'PersonConsentConnection';
      edges?: Array<{
        __typename?: 'PersonConsentEdge';
        node?: {
          __typename?: 'PersonConsent';
          id: string;
          oid?: string | null;
          consentItemValues: Array<{
            __typename?: 'PersonConsentItemValue';
            communicationDataValues: Array<{
              __typename?: 'PersonConsentCommunicationDataValue';
              value?: string | null;
              consentCommunicationDataType: {
                __typename?: 'ConsentCommunicationDataType';
                oid: string;
                communicationDataType: {
                  __typename?: 'CommunicationDataType';
                  id: string;
                  baseType?: Types.CommunicationDataBaseType | null;
                };
              };
            }>;
          }>;
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetPersonAvailableCommunicationDataQuery__
 *
 * To run a query within a React component, call `useGetPersonAvailableCommunicationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonAvailableCommunicationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonAvailableCommunicationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      baseType: // value for 'baseType'
 *   },
 * });
 */
export function useGetPersonAvailableCommunicationDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonAvailableCommunicationDataQuery,
    GetPersonAvailableCommunicationDataQueryVariables
  > &
    (
      | {
          variables: GetPersonAvailableCommunicationDataQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPersonAvailableCommunicationDataQuery,
    GetPersonAvailableCommunicationDataQueryVariables
  >(Operations.getPersonAvailableCommunicationData, options);
}
export function useGetPersonAvailableCommunicationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonAvailableCommunicationDataQuery,
    GetPersonAvailableCommunicationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPersonAvailableCommunicationDataQuery,
    GetPersonAvailableCommunicationDataQueryVariables
  >(Operations.getPersonAvailableCommunicationData, options);
}
export function useGetPersonAvailableCommunicationDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonAvailableCommunicationDataQuery,
    GetPersonAvailableCommunicationDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonAvailableCommunicationDataQuery,
    GetPersonAvailableCommunicationDataQueryVariables
  >(Operations.getPersonAvailableCommunicationData, options);
}
export type GetPersonAvailableCommunicationDataQueryHookResult = ReturnType<
  typeof useGetPersonAvailableCommunicationDataQuery
>;
export type GetPersonAvailableCommunicationDataLazyQueryHookResult = ReturnType<
  typeof useGetPersonAvailableCommunicationDataLazyQuery
>;
export type GetPersonAvailableCommunicationDataSuspenseQueryHookResult =
  ReturnType<typeof useGetPersonAvailableCommunicationDataSuspenseQuery>;
export type GetPersonAvailableCommunicationDataQueryResult = Apollo.QueryResult<
  GetPersonAvailableCommunicationDataQuery,
  GetPersonAvailableCommunicationDataQueryVariables
>;
