import { Email as EmailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import { Box, Stack as MuiStack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CommunicationDataBaseTypeDataEnum,
  ConsentItemValueData,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useCurrentUser } from '@/hooks';

type ConsentItemProps = {
  consentItem: ConsentItemValueData;
};

export const ConsentItem = ({ consentItem }: ConsentItemProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  return (
    <Stack data-testid="person-consent-item">
      {consentItem?.consentItem && (
        <>
          <SubTitleTypography variant="subtitle1" data-testid="consent-name">
            {consentItem?.consentItem?.name ??
              t('pages.interaction.common.noDataAvailable')}
          </SubTitleTypography>
          <Typography variant="body2" data-testid="consent-description">
            {consentItem?.consentItem?.description ??
              t('pages.interaction.common.noDataAvailable')}
          </Typography>
        </>
      )}

      {!!consentItem?.communicationDataValues?.length &&
        consentItem.communicationDataValues.map((contact, idx) => (
          <TinyStack
            key={`contact-info-${idx}`}
            data-testid="consent-contact-info"
          >
            <StyledCaptionTypography variant="caption">
              {contact?.communicationDataType?.name}
            </StyledCaptionTypography>
            <RowBox>
              {contact?.communicationDataType?.baseType ===
                CommunicationDataBaseTypeDataEnum.EMAIL && (
                <StyledEmailIcon data-testid="icon-email" />
              )}
              {currentUser?.permissions?.common?.canMakePhoneCall &&
                contact?.communicationDataType?.baseType ===
                  CommunicationDataBaseTypeDataEnum.PHONE && (
                  <a href={'tel:' + contact?.value}>
                    <StyledPhoneIcon data-testid="icon-phone" />
                  </a>
                )}
              <Typography variant="caption">
                {contact?.value ??
                  t('pages.interaction.common.noDataAvailable')}
              </Typography>
            </RowBox>
          </TinyStack>
        ))}
    </Stack>
  );
};

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Stack = styled(MuiStack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const TinyStack = styled(MuiStack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

const StyledCaptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const RowBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledEmailIcon = styled(EmailIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 16,
  height: 16,
}));

const StyledPhoneIcon = styled(PhoneIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 16,
  height: 16,
}));
