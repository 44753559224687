import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, KeyboardEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContentStyle } from './InvitationDialogLayout';

type HTMLInputChangeEventFunction = (
  event: ChangeEvent<HTMLInputElement>
) => void;

type InvitationDialogContentProps = {
  availableEmails: Array<string>;
  selectedEmail: string;
  isCustomEmailValid: boolean;
  handleEmailRadioChange: HTMLInputChangeEventFunction;
  handleEmailInputChange: HTMLInputChangeEventFunction;
  handleValidateEmailInput: VoidFunction;
  handleCustomEmailInputClick: VoidFunction;
  handleEmailSend: VoidFunction;
};

export const InvitationDialogContent = ({
  availableEmails,
  selectedEmail,
  isCustomEmailValid,
  handleEmailRadioChange,
  handleEmailInputChange,
  handleValidateEmailInput,
  handleCustomEmailInputClick,
  handleEmailSend,
}: InvitationDialogContentProps) => {
  const { t } = useTranslation();
  const customEmailInputRef = useRef<HTMLInputElement>(null);

  const onEnterKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEmailSend();
    }
  };

  // If no person communication data, then only possibility is to send to a custom email
  const showRadioOptionsChoice = !!availableEmails?.length;

  const isCustomEmailSelected =
    !showRadioOptionsChoice || selectedEmail === 'customEmail';

  return (
    <DialogContentStyle>
      <Typography variant="h5">
        {t('pages.interaction.remote.sendInvitationEmailTo')}
      </Typography>

      {showRadioOptionsChoice && (
        <FormControl>
          <RadioGroup
            aria-labelledby="emails"
            value={selectedEmail}
            onChange={handleEmailRadioChange}
          >
            {availableEmails.map((value: string) => {
              return (
                <FormControlLabel
                  key={value}
                  value={value}
                  label={value}
                  control={<Radio size="small" />}
                />
              );
            })}
            <FormControlLabel
              value="customEmail"
              control={<Radio size="small" />}
              label={t('pages.interaction.remote.addNewEmail')}
              onClick={() => customEmailInputRef.current?.focus()}
            />
          </RadioGroup>
        </FormControl>
      )}

      <EmailTextField
        data-testid="email-text-field"
        inputRef={(input: HTMLDivElement) => {
          if (input && isCustomEmailSelected) {
            input.focus();
          }
        }}
        error={!isCustomEmailValid}
        helperText={
          !isCustomEmailValid ? t('pages.interaction.remote.emailError') : ' '
        }
        label={t('pages.interaction.remote.email')}
        disabled={!isCustomEmailSelected}
        onChange={handleEmailInputChange}
        onBlur={handleValidateEmailInput}
        onClick={handleCustomEmailInputClick}
        onKeyUp={onEnterKeyUp}
      />
    </DialogContentStyle>
  );
};

const EmailTextField = styled(TextField)({
  'input.Mui-disabled': {
    cursor: 'pointer',
  },
});
