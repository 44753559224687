import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MediaGridItemSkeleton = () => {
  return (
    <Card data-testid="media-grid-item-skeleton">
      <SkeletonWrapper>
        <Skeleton variant="rounded" height={120} />
        <TextSkeleton width={200} />
        <TextSkeleton width={64} />
      </SkeletonWrapper>
    </Card>
  );
};

const SkeletonWrapper = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const TextSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  maxWidth: '100%',
}));
