import {
  ConsentActionDataEnum,
  CountryData,
  PersonConsentData,
  PersonData,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import {
  EditConsentCollectionDialog,
  F2FConsentCollectionDialog,
  ManualConsentCollectionDialog,
  PaperConsentCollectionDialog,
} from '@/components/Consent';
import {
  RejectConsentDialog,
  ResendDoubleOptInDialog,
  ResetConsentDialog,
  RevokeConsentDialog,
} from '@/components/Consent/ConsentActionManager';
import { useNotification } from '@/hooks';

type ConsentActionManagerProps = {
  isInRemoteRoom?: boolean;
  consent: PersonConsentData;
  person: PersonData;
  consentActionType: ConsentActionDataEnum | null;
  defaultCountry?: CountryData;
  onConfirmAction: VoidFunction;
  onCloseDialog: VoidFunction;
};

export const ConsentActionManager = ({
  isInRemoteRoom,
  consent,
  person,
  consentActionType,
  defaultCountry,
  onConfirmAction,
  onCloseDialog,
}: ConsentActionManagerProps) => {
  const { t } = useTranslation();
  const { toast } = useNotification();

  const handleOnConfirmAction = (confirmMessage: string) => {
    toast?.({
      message: confirmMessage,
      type: 'success',
    });
    onConfirmAction();
  };

  return (
    <>
      {consentActionType === ConsentActionDataEnum.COLLECT_DIGITAL_CONSENT && (
        <F2FConsentCollectionDialog
          isOpen={true}
          consent={consent}
          person={person}
          defaultCountry={defaultCountry}
          handleClose={onCloseDialog}
          handleConfirm={(message: string) => handleOnConfirmAction(message)}
        />
      )}
      {consentActionType === ConsentActionDataEnum.REJECT_CONSENT && (
        <RejectConsentDialog
          isInRemoteRoom={isInRemoteRoom}
          consent={consent}
          person={person}
          onConfirmAction={() =>
            handleOnConfirmAction(
              t('components.consentDialog.rejectConsent.successMessage')
            )
          }
          onCloseDialog={onCloseDialog}
        />
      )}

      {consentActionType === ConsentActionDataEnum.REVOKE_CONSENT && (
        <RevokeConsentDialog
          isInRemoteRoom={isInRemoteRoom}
          consent={consent}
          onConfirmAction={() =>
            handleOnConfirmAction(
              t('components.consentDialog.revokeConsent.successMessage')
            )
          }
          onCloseDialog={onCloseDialog}
        />
      )}

      {consentActionType === ConsentActionDataEnum.RESEND_DOUBLE_OPT_IN && (
        <ResendDoubleOptInDialog
          consent={consent}
          onConfirmAction={() =>
            handleOnConfirmAction(
              t('components.consentDialog.resendDoubleOptIn.successMessage')
            )
          }
          onCloseDialog={onCloseDialog}
        />
      )}

      {consentActionType === ConsentActionDataEnum.RESET_CONSENT && (
        <ResetConsentDialog
          consent={consent}
          onConfirmAction={() =>
            handleOnConfirmAction(
              t('components.consentDialog.resetConsent.successMessage')
            )
          }
          onCloseDialog={onCloseDialog}
        />
      )}

      {consentActionType === ConsentActionDataEnum.COLLECT_MANUAL_CONSENT && (
        <ManualConsentCollectionDialog
          isOpen={true}
          consent={consent}
          person={person}
          defaultCountry={defaultCountry}
          handleClose={onCloseDialog}
          handleConfirm={(message: string) => handleOnConfirmAction(message)}
        />
      )}

      {consentActionType === ConsentActionDataEnum.SET_CONSENT_ITEMS && (
        <EditConsentCollectionDialog
          isOpen={true}
          consent={consent}
          person={person}
          defaultCountry={defaultCountry}
          handleClose={onCloseDialog}
          handleConfirm={(message: string) => handleOnConfirmAction(message)}
        />
      )}

      {consentActionType === ConsentActionDataEnum.COLLECT_PAPER_CONSENT && (
        <PaperConsentCollectionDialog
          isOpen={true}
          consent={consent}
          person={person}
          defaultCountry={defaultCountry}
          handleClose={onCloseDialog}
          handleConfirm={(message: string) => handleOnConfirmAction(message)}
        />
      )}
    </>
  );
};
