import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDateTime } from '@/services/graphql/hooks/mutations/helpers/helper';

import {
  RevokePersonConsentMutation,
  RevokePersonConsentMutationVariables,
  useRevokePersonConsentMutation as useRevokePersonConsentMutationApollo,
} from '../generated';

type RevokePersonConsentVariables =
  // oid is required
  Pick<RevokePersonConsentMutationVariables, 'oid'> &
    // issuerPersonId and actionAt are optional
    Partial<
      Pick<RevokePersonConsentMutationVariables, 'issuerPersonId' | 'actionAt'>
    >;

/**
 * __useRevokePersonConsentMutation__
 *
 * To run a mutation, you first call `useRevokePersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokePersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokePersonConsent, { data, loading, error }] = useRevokePersonConsentMutation();
 *
 * revokePersonConsent({
 *   variables: {
 *      oid: // defaults to a new uuid
 *
 *      // Optional
 *      issuerPersonId: // defaults to the current user
 *      actionAt: // defaults to the current timestamp
 *   },
 * });
 */
export const useRevokePersonConsentMutation = (
  baseOptions?: MutationHookOptions<
    RevokePersonConsentMutation,
    RevokePersonConsentMutationVariables
  >
) => {
  const { currentUser } = useCurrentUser();

  const [revokePersonConsent, status] = useRevokePersonConsentMutationApollo({
    // We invalidate all queries related to consent to refresh the data
    refetchQueries: ['getPersonConsentList', 'getPersonCommunicationData'],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<
      RevokePersonConsentMutation,
      RevokePersonConsentVariables
    >
  ) => {
    const defaultVariables = {
      issuerPersonId: currentUser?.person?.oid ?? '',
      actionAt: buildCurrentDateTime(),

      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
    };

    return revokePersonConsent({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
