import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, NoItemPlaceholder, TouchpointData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ActivitySkeletonStaticBlock } from '@/components/Activity';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { ActivityCard } from '@/views/CalendarActivities/ActivityCard';

interface OrganizationActivityDataStaticBlockProps {
  title: string;
  loading?: boolean;
  activities: Array<ActivityData | TouchpointData>;
}
export const OrganizationActivityDataStaticBlock = ({
  title,
  loading,
  activities,
}: OrganizationActivityDataStaticBlockProps) => {
  const { t } = useTranslation();

  if (loading) {
    return <ActivitySkeletonStaticBlock title={t(title)} />;
  }

  const activityList = activities.slice(0, 2);

  return (
    <OverviewStaticBlock
      title={t(title)}
      testId="organization-activity-static-block"
    >
      <CardListContainer>
        {!activityList.length ? (
          <EmptyWrapper>
            <NoItemPlaceholder>
              {t('pages.organizationView.noTouchpoint')}
            </NoItemPlaceholder>
          </EmptyWrapper>
        ) : (
          activityList.map((activity) => (
            <ActivityCard key={activity.id} activity={activity} />
          ))
        )}
      </CardListContainer>
    </OverviewStaticBlock>
  );
};

const CardListContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

const EmptyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));
