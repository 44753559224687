import { NetworkStatus } from '@apollo/client';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationData, PersonFilterData } from '@ysura/common';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { parsePersonFilters } from '@/components/GlobalSearch/utils';
import {
  EmploymentFilterCard,
  EmploymentPersonDataStaticBlock,
} from '@/components/Organization/Employment';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { buildPersonFilterQuery } from '@/services/graphql/helpers';
import {
  useGetCurrentUserPreferenceQuery,
  useGetSearchedPersonsLazyQuery,
} from '@/services/graphql/hooks';
import {
  parsePersonConnection,
  parsePersonPreference,
} from '@/services/graphql/parsers';
import { PersonFilterInput, Scope } from '@/services/graphql/types';
import { filterNonNull } from '@/utils/dataHelpers/filters';

interface DesktopOrganizationEmploymentDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  organization: OrganizationData;
}

const NUMBER_OF_PERSONS_TO_SEARCH = 20;

export const DesktopOrganizationEmploymentDialog = ({
  isOpen,
  onClose,
  organization,
}: DesktopOrganizationEmploymentDialogProps) => {
  const { t } = useTranslation();
  const [showAllEmployments, setShowAllEmployments] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [personFiltersData, setPersonFiltersData] = useState<
    PersonFilterData[]
  >([]);

  const { loading } = useGetCurrentUserPreferenceQuery({
    ...errorConfigBannerDialog,
    skip: !isOpen || Boolean(personFiltersData.length),
    onCompleted: (data) => {
      const personFacets = parsePersonPreference(
        data.currentUser?.preference?.facets?.personFacets
      );
      setPersonFiltersData(parsePersonFilters(personFacets));
    },
  });

  const [
    searchEmployments,
    { data, fetchMore, called, refetch, networkStatus },
  ] = useGetSearchedPersonsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isOpen) {
      const personFilterQuery: PersonFilterInput = buildPersonFilterQuery(
        personFiltersData,
        Scope.TERRITORY
      );
      const organizationIds = !showAllEmployments
        ? filterNonNull([organization.oid])
        : map(
            organization.hierarchyOrganizations,
            (organization: OrganizationData) => organization.oid!
          );
      if (!personFilterQuery.employments) {
        personFilterQuery.employments = {};
      }
      personFilterQuery.employments.organization = {
        oid: { _in: organizationIds },
      };

      if (called) {
        refetch({
          search: searchQuery,
          first: NUMBER_OF_PERSONS_TO_SEARCH,
          filter: personFilterQuery,
        });
      } else {
        searchEmployments({
          variables: {
            search: searchQuery,
            first: NUMBER_OF_PERSONS_TO_SEARCH,
            filter: personFilterQuery,
          },
        });
      }
    }
  }, [
    organization,
    searchEmployments,
    searchQuery,
    isOpen,
    called,
    showAllEmployments,
    personFiltersData,
    refetch,
  ]);

  const handleFetchMorePersons = () => {
    const endCursor = data?.persons?.pageInfo?.endCursor ?? '';

    fetchMore({
      variables: {
        after: endCursor,
      },
    });
  };

  const isLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  const persons = data?.persons
    ? parsePersonConnection(data.persons)
    : undefined;

  const hasMore = data?.persons?.pageInfo?.hasNextPage ?? false;

  return (
    <ActionDialog
      isOpen={isOpen}
      isLoading={isLoading || networkStatus == NetworkStatus.fetchMore}
      title={t('pages.organizationView.personDataCard.personData')}
      primaryButtonText={t('components.common.close')}
      testId="organization-employment-info-dialog"
      onClickPrimaryButton={onClose}
    >
      <StyledGrid container spacing={{ md: 1 }}>
        <StyledGrid item md={6}>
          <EmploymentFilterCard
            loading={loading}
            personFiltersData={personFiltersData}
            setPersonFiltersData={setPersonFiltersData}
            toggleShowAllEmployments={setShowAllEmployments}
          />
        </StyledGrid>
        <StyledGrid item md={6}>
          <EmploymentPersonDataStaticBlock
            isLoading={isLoading}
            total={data?.persons?.filteredCount}
            persons={persons}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            hasMore={hasMore}
            fetchMorePersons={handleFetchMorePersons}
          />
        </StyledGrid>
      </StyledGrid>
    </ActionDialog>
  );
};

const StyledGrid = styled(Grid)(() => ({
  height: '100%',
}));
