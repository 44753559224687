import { DataPermissionTypeDataEnum, UserData } from '@ysura/common';

export const hasClientPermission = (
  apiUserData?: UserData,
  isAdmin?: boolean,
  clientPermission?: string
): boolean => {
  return isAdmin
    ? true
    : !!apiUserData?.allClientPermissions?.some(
        (item) => item.permission === clientPermission
      );
};
export const hasDataPermission = (
  apiUserData?: UserData,
  isAdmin?: boolean,
  dataPermission?: DataPermissionTypeDataEnum
): boolean => {
  return isAdmin
    ? true
    : !!apiUserData?.allDataPermissions?.some(
        (item) => item.type === dataPermission
      );
};
