import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { IconButton } from '@mui/material';
import { MenuPopover } from '@ysura/common';
import { Dispatch, MouseEvent, ReactNode, SetStateAction } from 'react';

type MoreMenuButtonProps = {
  children?: ReactNode;
  open?: HTMLElement | null;
  setOpen?: Dispatch<SetStateAction<HTMLElement | null>>;
};

export const MoreMenuButton = ({
  children,
  open = null,
  setOpen,
}: MoreMenuButtonProps) => {
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setOpen?.(event.currentTarget);
  };

  const handleClose = () => {
    setOpen?.(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertOutlinedIcon />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            borderRadius: 0.75,
          },
        }}
        onClose={handleClose}
      >
        {children}
      </MenuPopover>
    </>
  );
};
