import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import { BackToTopButton } from '@/components/Button';
import {
  NotificationBannerList,
  NotificationPopup,
} from '@/components/Notification';
import { HEADER } from '@/config';

import { Header } from './header';

export const MainLayout = () => {
  return (
    <Wrapper>
      <Header />
      <Main>
        <NotificationBannerList displayLocation="main" />
        <Outlet />
      </Main>
      <NotificationPopup />
      <BackToTopButton />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.neutral,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  minHeight: '100%',
}));

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(2),
  padding: `${theme.spacing(3)}`,
  paddingTop: `${HEADER.MOBILE_HEIGHT + 24}px`,
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: theme.spacing(3),
    maxWidth: theme.breakpoints.values.lg,
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  width: '100vw',
}));
