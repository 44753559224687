import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonPromotionalMaterialsQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID']['input'];
}>;

export type GetPersonPromotionalMaterialsQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    promotionalMaterials: {
      __typename?: 'PersonPromotionalMaterialConnection';
      edges?: Array<{
        __typename?: 'PersonPromotionalMaterialEdge';
        node?: {
          __typename?: 'PersonPromotionalMaterial';
          givenQuantityInCurrentPeriod: number;
          maximumQuantityInCurrentPeriod?: number | null;
          promotionalMaterial: {
            __typename?: 'PromotionalMaterial';
            id: string;
            oid: string;
            type: Types.PromotionalMaterialType;
            name: string;
            signatureRequired?: boolean | null;
            batches: {
              __typename?: 'PromotionalMaterialBatchConnection';
              edges?: Array<{
                __typename?: 'PromotionalMaterialBatchEdge';
                node?: {
                  __typename?: 'PromotionalMaterialBatch';
                  oid: string;
                  name?: string | null;
                  expiryDate?: string | null;
                } | null;
              }> | null;
            };
            topics: {
              __typename?: 'TopicConnection';
              edges?: Array<{
                __typename?: 'TopicEdge';
                node?: {
                  __typename?: 'Topic';
                  id: string;
                  oid: string;
                  name: string;
                } | null;
              }> | null;
            };
            rules: Array<
              | {
                  __typename?: 'PromotionalMaterialEndDateRule';
                  oid: string;
                  endDate: string;
                }
              | {
                  __typename?: 'PromotionalMaterialMaximumQuantityPerActivityRule';
                  oid: string;
                  maximumQuantity: number;
                }
              | {
                  __typename?: 'PromotionalMaterialMaximumQuantityPerPeriodRule';
                  oid: string;
                  maximumQuantity: number;
                  period: {
                    __typename?: 'PromotionalMaterialRulePeriod';
                    type: Types.PromotionalMaterialPeriodType;
                  };
                }
            >;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetPersonPromotionalMaterialsQuery__
 *
 * To run a query within a React component, call `useGetPersonPromotionalMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonPromotionalMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonPromotionalMaterialsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonPromotionalMaterialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonPromotionalMaterialsQuery,
    GetPersonPromotionalMaterialsQueryVariables
  > &
    (
      | {
          variables: GetPersonPromotionalMaterialsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPersonPromotionalMaterialsQuery,
    GetPersonPromotionalMaterialsQueryVariables
  >(Operations.getPersonPromotionalMaterials, options);
}
export function useGetPersonPromotionalMaterialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonPromotionalMaterialsQuery,
    GetPersonPromotionalMaterialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPersonPromotionalMaterialsQuery,
    GetPersonPromotionalMaterialsQueryVariables
  >(Operations.getPersonPromotionalMaterials, options);
}
export function useGetPersonPromotionalMaterialsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonPromotionalMaterialsQuery,
    GetPersonPromotionalMaterialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonPromotionalMaterialsQuery,
    GetPersonPromotionalMaterialsQueryVariables
  >(Operations.getPersonPromotionalMaterials, options);
}
export type GetPersonPromotionalMaterialsQueryHookResult = ReturnType<
  typeof useGetPersonPromotionalMaterialsQuery
>;
export type GetPersonPromotionalMaterialsLazyQueryHookResult = ReturnType<
  typeof useGetPersonPromotionalMaterialsLazyQuery
>;
export type GetPersonPromotionalMaterialsSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonPromotionalMaterialsSuspenseQuery
>;
export type GetPersonPromotionalMaterialsQueryResult = Apollo.QueryResult<
  GetPersonPromotionalMaterialsQuery,
  GetPersonPromotionalMaterialsQueryVariables
>;
