import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SquareButton } from '@ysura/common';

import { useInteraction } from '@/hooks';

type FullScreenButtonProps = {
  onChangeFullScreen: VoidFunction;
};

export const FullScreenButton = ({
  onChangeFullScreen,
}: FullScreenButtonProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isSharingInFullScreen } = useInteraction();

  return isSmallScreen ? null : (
    <SquareButton
      variant="secondary"
      testId={'fullscreen-button'}
      buttonSize={36}
      onClick={onChangeFullScreen}
    >
      {isSharingInFullScreen ? <CloseFullscreenButton /> : <OpenInFullButton />}
    </SquareButton>
  );
};

const CloseFullscreenButton = styled(CloseFullscreenIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const OpenInFullButton = styled(OpenInFullIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
