import { gql } from '@apollo/client';

export const getDocumentById = gql`
  query getDocumentById($documentId: ID!) {
    document(id: $documentId) {
      id
      oid
      name
      contentLink
      mediaType
    }
  }
`;
