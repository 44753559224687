import { Grid } from '@mui/material';
import { OrganizationData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ActivitySkeletonStaticBlock } from '@/components/Activity';
import { DetailPageLayout } from '@/components/DetailPageLayout';
import { PageHeader, PageHeaderSkeleton } from '@/components/PageHeader';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { useGetActivityTypeListQuery } from '@/services/graphql/hooks';
import { parseActivityTypeConnection } from '@/services/graphql/parsers';
import { hasAssignActivityTypePermission } from '@/services/permission';
import {
  OrganizationActivityCard,
  OrganizationDataCard,
  OrganizationPersonsCard,
} from '@/views/OrganizationView';

type OrganizationProps = {
  organization?: OrganizationData;
  isPageLoading: boolean;
};

export const OrganizationView = ({
  organization,
  isPageLoading,
}: OrganizationProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const { data, loading } = useGetActivityTypeListQuery();

  const activityTypeList = parseActivityTypeConnection(data?.activityTypes);

  const canCreateActivity =
    currentUser?.permissions?.activity?.canCreateActivity &&
    hasAssignActivityTypePermission(activityTypeList);

  const canReadActivity = currentUser?.permissions?.activity?.canReadActivity;

  const isLoading = isPageLoading || loading || !organization;

  const pageHeader = canCreateActivity ? (
    <PageHeader
      title={organization?.name ?? ''}
      pageHeaderType={t('pages.organizationView.navigationTarget')}
      buttonText={t('components.touchpointManagement.createTouchpoint')}
      buttonHandler={() => {}}
    />
  ) : (
    <PageHeader
      title={organization?.name ?? ''}
      pageHeaderType={t('pages.organizationView.navigationTarget')}
    />
  );

  return (
    <DetailPageLayout
      headerComp={isLoading ? <PageHeaderSkeleton showSubtitle /> : pageHeader}
      firstColumnComp={
        <OrganizationDataCard
          isLoading={isLoading}
          organization={organization}
        />
      }
      middleColumnComp={
        <>
          {canReadActivity &&
            (isLoading ? (
              <>
                <Grid item>
                  <ActivitySkeletonStaticBlock
                    title={t('pages.organizationView.plannedTouchpoint')}
                  />
                </Grid>
                <Grid item>
                  <ActivitySkeletonStaticBlock
                    title={t('pages.organizationView.touchpointHistory')}
                  />
                </Grid>
              </>
            ) : (
              <OrganizationActivityCard organization={organization} />
            ))}
        </>
      }
      lastColumnComp={
        <OrganizationPersonsCard
          isLoading={isLoading}
          organization={organization}
        />
      }
    />
  );
};
