import { Grid } from '@mui/material';
import { OrganizationData } from '@ysura/common';

import { OrganizationPersonDataStaticBlock } from '@/components/Organization';
import { PersonDataSkeletonStaticBlock } from '@/components/PersonData';

interface OrganizationPersonsCardProps {
  organization?: OrganizationData;
  isLoading?: boolean;
}
export const OrganizationPersonsCard = ({
  organization,
  isLoading,
}: OrganizationPersonsCardProps) => {
  if (isLoading) {
    return (
      <Grid item>
        <PersonDataSkeletonStaticBlock
          title={'pages.organizationView.personDataCard.personData'}
        />
      </Grid>
    );
  }

  return (
    <Grid item>
      <>
        {organization && (
          <OrganizationPersonDataStaticBlock organization={organization} />
        )}
      </>
    </Grid>
  );
};
