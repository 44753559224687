import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';

type TouchpointConfirmationDialogProps = {
  isOpen: boolean;
  text: string;
  submitButtonText: string;
  submitHandler: VoidFunction;
  closeDialogHandler: VoidFunction;
  isLoading: boolean;
};

export const ActivityConfirmationDialog = ({
  isOpen,
  text,
  submitButtonText,
  submitHandler,
  closeDialogHandler,
  isLoading,
}: TouchpointConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t('components.common.warning')}
      content={text}
      primaryButton={{
        text: submitButtonText,
        onClickHandler: submitHandler,
        color: 'error',
        isButtonLoading: isLoading,
      }}
      secondaryButton={{
        text: t('components.common.cancel'),
        onClickHandler: closeDialogHandler,
      }}
    />
  );
};
