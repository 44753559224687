import FullscreenIcon from '@mui/icons-material/Fullscreen';
import NotesIcon from '@mui/icons-material/Notes';
import {
  alpha,
  Box,
  IconButton as MuiIconButton,
  Popover,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  CommentData,
  getPersonFullNameWithTitle,
  PersonData,
  SquareButton,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommentsStaticBlock } from '@/components/Comment';
import { InteractionItemsColumn } from '@/components/Interaction';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useCurrentUser, useInteraction } from '@/hooks';

type ScreenShareColumnProps = {
  attendee: PersonData | undefined;
  activity: ActivityData;
  canEditActivity?: boolean;
};

export const ScreenShareColumn = ({
  attendee,
  activity,
  canEditActivity,
}: ScreenShareColumnProps) => {
  const { t } = useTranslation();
  const {
    isParticipantsScreenShared,
    toggleIsSharingInFullscreen,
    comments,
    setComments,
  } = useInteraction();
  const { currentUser } = useCurrentUser();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenNote = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNote = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isParticipantsScreenShared) {
      handleCloseNote();
    }
  }, [isParticipantsScreenShared]);

  const handleSave = (comments: CommentData[], closeDialog: VoidFunction) => {
    setComments(comments);
    closeDialog();
  };

  const isNoteOpen = Boolean(anchorEl);

  const canUseNotes =
    currentUser?.permissions?.activity?.canReadActivityComment;

  const isSmallScreenLandscape = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  const handleScreenExpand = () => {
    toggleIsSharingInFullscreen();
  };

  return (
    // Hide the column if isScreenSharing = false, because this component is always rendered (see InteractionContent.tsx)
    <InteractionItemsColumn
      isSharing
      isHidden={!isParticipantsScreenShared}
      flex={2}
      testId="screen-share-column"
    >
      <Container>
        <Title variant="h5">
          {t('pages.interaction.remote.participantStartedSharing', {
            fullName: getPersonFullNameWithTitle(attendee),
          })}
        </Title>

        <ScreenShare
          id="participantScreenShare"
          data-testid="participantScreenShare"
        />

        <Footer>
          <FullScreenButton
            variant="secondary"
            handleClick={handleScreenExpand}
          >
            <FullscreenIcon />
          </FullScreenButton>

          {canUseNotes && (
            <>
              <NoteButton isNoteOpen={isNoteOpen} onClick={handleOpenNote}>
                <NotesIcon />
              </NoteButton>

              <NotePopover
                open={isNoteOpen && isParticipantsScreenShared}
                anchorEl={anchorEl}
                transformOrigin={
                  isSmallScreenLandscape
                    ? {
                        vertical: 'top',
                        horizontal: 'right',
                      }
                    : {
                        vertical: 'bottom',
                        horizontal: 'left',
                      }
                }
                onClose={handleCloseNote}
              >
                <CommentsStaticBlock
                  commentTypes={activity.activityType?.commentTypes}
                  sourceComments={comments}
                  status={activity.status}
                  canEditActivity={canEditActivity}
                  save={handleSave}
                ></CommentsStaticBlock>
              </NotePopover>
            </>
          )}
        </Footer>
      </Container>
    </InteractionItemsColumn>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  gap: theme.spacing(2),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
    },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const ScreenShare = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['700'],
  borderRadius: theme.shape.borderRadius,
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',

  video: {
    height: '100%',
    width: '100%',
    borderRadius: theme.shape.borderRadius,
  },
}));

const Footer = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
    },
});

const NoteButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'isNoteOpen',
})<{ isNoteOpen: boolean }>(({ theme, isNoteOpen }) => ({
  backgroundColor: isNoteOpen
    ? theme.palette.primary.main
    : alpha(theme.palette.grey[500], 0.24),
  borderRadius: theme.shape.borderRadius,
  color: isNoteOpen ? theme.palette.common.white : theme.palette.text.primary,
  height: '45px',
  width: '45px',

  [theme.breakpoints.down('md')]: {
    ':hover': {
      backgroundColor: isNoteOpen
        ? theme.palette.primary.main
        : alpha(theme.palette.grey[500], 0.24),
    },
  },
}));

const FullScreenButton = styled(SquareButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  svg: {
    color: theme.palette.text.primary,
  },
}));

const NotePopover = styled(Popover)({
  // Design wants the Popover to have its margin between the Popover and the Button triggers the Popover, both of them are absolute positioned so override the transform style is the only way to achieve that
  '.MuiPopover-paper': {
    transform: 'translateY(-8px) !important',
    width: 'min(100%, 384px)',

    [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
      {
        transform: 'translateX(-8px) !important',
      },
  },
});
