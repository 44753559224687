import { format } from 'date-fns';

import { useDateLocale } from './useDateLocale';

type IncomingDate = string | number | Date;

export const useFormatDate = () => {
  const { dateLocale } = useDateLocale();

  const formatDate = (date: number | Date, formatString: string) => {
    let formattedDate = '';

    try {
      formattedDate = format(date, formatString, { locale: dateLocale });
    } catch {
      console.warn('Invalid date!');
    }

    return formattedDate;
  };

  const formatLocalizedDate = (date?: IncomingDate | null) => {
    if (!date) {
      return '';
    }

    return formatDate(new Date(date), 'PP');
  };

  return {
    formatDate,
    formatLocalizedDate,
  };
};
