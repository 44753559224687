import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonConsentListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetPersonConsentListQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    oid: string;
    consents: {
      __typename?: 'PersonConsentConnection';
      edges?: Array<{
        __typename?: 'PersonConsentEdge';
        node?: {
          __typename?: 'PersonConsent';
          id: string;
          oid?: string | null;
          actionAt?: string | null;
          state?: Types.State | null;
          lifecycleState: Types.PersonConsentLifecycleState;
          recCreatedAt?: string | null;
          recUpdatedAt?: string | null;
          issuer?: {
            __typename?: 'Person';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            middleName?: string | null;
          } | null;
          consentItemValues: Array<{
            __typename?: 'PersonConsentItemValue';
            consentItemState: Types.ConsentItemStateChange;
            consentItem: {
              __typename?: 'ConsentItem';
              oid: string;
              name: string;
              description?: string | null;
            };
            communicationDataValues: Array<{
              __typename?: 'PersonConsentCommunicationDataValue';
              value?: string | null;
              consentCommunicationDataType: {
                __typename?: 'ConsentCommunicationDataType';
                oid: string;
                description?: string | null;
                communicationDataType: {
                  __typename?: 'CommunicationDataType';
                  oid: string;
                  baseType?: Types.CommunicationDataBaseType | null;
                  name?: string | null;
                };
              };
            }>;
          }>;
          consentType: {
            __typename?: 'ConsentType';
            id: string;
            oid: string;
            name: string;
            description?: string | null;
            startDate: string;
            endDate?: string | null;
            lifecycleStatus?: Types.LifecycleStatus | null;
            validityEndDate?: string | null;
            validityMode: Types.ConsentTypeValidityMode;
            validityPeriodInMonths?: number | null;
            allowRecollection: boolean;
            digitalSignatureProcessEnabled: boolean;
            paperProcessEnabled: boolean;
            manualProcessEnabled: boolean;
            digitalSignatureTemplate?: {
              __typename?: 'Template';
              id: string;
              oid: string;
              document?: {
                __typename?: 'Document';
                id: string;
                oid: string;
                name?: string | null;
              } | null;
            } | null;
            consentItems: {
              __typename?: 'ConsentItemConnection';
              edges?: Array<{
                __typename?: 'ConsentItemEdge';
                node?: {
                  __typename?: 'ConsentItem';
                  oid: string;
                  name: string;
                  description?: string | null;
                  defaultItemState?: Types.ConsentItemStateChange | null;
                  inputId?: string | null;
                  pendingPaperItemState?: Types.ConsentItemStateChange | null;
                  pendingPaperSwitchable: boolean;
                  pendingDoubleOptInItemState?: Types.ConsentItemStateChange | null;
                  pendingDoubleOptInSwitchable: boolean;
                  acceptedItemState?: Types.ConsentItemStateChange | null;
                  acceptedSwitchable: boolean;
                  consentCommunicationDataTypes: Array<{
                    __typename?: 'ConsentCommunicationDataType';
                    oid: string;
                    inputId?: string | null;
                    description?: string | null;
                    mandatory?: boolean | null;
                    communicationDataType: {
                      __typename?: 'CommunicationDataType';
                      id: string;
                      oid: string;
                      baseType?: Types.CommunicationDataBaseType | null;
                      name?: string | null;
                    };
                  }>;
                } | null;
              }> | null;
            };
            manualProcessReasons: Array<{
              __typename?: 'ConsentTypeManualProcessReason';
              oid?: string | null;
              doiEnabled: boolean;
              reason: string;
            }>;
            dataPermissions: Array<{
              __typename?: 'DataPermission';
              type: Types.DataPermissionType;
            }>;
          };
          consentHistory: Array<{
            __typename?: 'PersonConsentHistory';
            action: Types.ConsentAction;
            sequenceNumber?: number | null;
            createdAt?: string | null;
            handledAt?: string | null;
            issuer: {
              __typename?: 'Person';
              id: string;
              firstName?: string | null;
              middleName?: string | null;
              lastName?: string | null;
            };
          }>;
          document?: {
            __typename?: 'Document';
            id: string;
            oid: string;
            name?: string | null;
          } | null;
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetPersonConsentListQuery__
 *
 * To run a query within a React component, call `useGetPersonConsentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonConsentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonConsentListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonConsentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonConsentListQuery,
    GetPersonConsentListQueryVariables
  > &
    (
      | { variables: GetPersonConsentListQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPersonConsentListQuery,
    GetPersonConsentListQueryVariables
  >(Operations.getPersonConsentList, options);
}
export function useGetPersonConsentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonConsentListQuery,
    GetPersonConsentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPersonConsentListQuery,
    GetPersonConsentListQueryVariables
  >(Operations.getPersonConsentList, options);
}
export function useGetPersonConsentListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonConsentListQuery,
    GetPersonConsentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonConsentListQuery,
    GetPersonConsentListQueryVariables
  >(Operations.getPersonConsentList, options);
}
export type GetPersonConsentListQueryHookResult = ReturnType<
  typeof useGetPersonConsentListQuery
>;
export type GetPersonConsentListLazyQueryHookResult = ReturnType<
  typeof useGetPersonConsentListLazyQuery
>;
export type GetPersonConsentListSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonConsentListSuspenseQuery
>;
export type GetPersonConsentListQueryResult = Apollo.QueryResult<
  GetPersonConsentListQuery,
  GetPersonConsentListQueryVariables
>;
