import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type RejectPersonConsentMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
  consentTypeId: Types.Scalars['ConsentTypeId']['input'];
  issuerPersonId: Types.Scalars['PersonId']['input'];
  signerPersonId: Types.Scalars['PersonId']['input'];
  actionAt: Types.Scalars['DateTime']['input'];
}>;

export type RejectPersonConsentMutation = {
  __typename?: 'Mutation';
  rejectPersonConsent?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type RejectPersonConsentMutationFn = Apollo.MutationFunction<
  RejectPersonConsentMutation,
  RejectPersonConsentMutationVariables
>;

/**
 * __useRejectPersonConsentMutation__
 *
 * To run a mutation, you first call `useRejectPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectPersonConsentMutation, { data, loading, error }] = useRejectPersonConsentMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      consentTypeId: // value for 'consentTypeId'
 *      issuerPersonId: // value for 'issuerPersonId'
 *      signerPersonId: // value for 'signerPersonId'
 *      actionAt: // value for 'actionAt'
 *   },
 * });
 */
export function useRejectPersonConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectPersonConsentMutation,
    RejectPersonConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RejectPersonConsentMutation,
    RejectPersonConsentMutationVariables
  >(Operations.rejectPersonConsent, options);
}
export type RejectPersonConsentMutationHookResult = ReturnType<
  typeof useRejectPersonConsentMutation
>;
export type RejectPersonConsentMutationResult =
  Apollo.MutationResult<RejectPersonConsentMutation>;
export type RejectPersonConsentMutationOptions = Apollo.BaseMutationOptions<
  RejectPersonConsentMutation,
  RejectPersonConsentMutationVariables
>;
