import { Box, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationData, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';

import { OrganizationHierarchyCard } from './OrganizationHierarchyCard';
import { OrganizationHierarchyPersonCard } from './OrganizationHierarchyPersonCard';

interface DesktopOrganizationHierarchyDialogProps {
  isOpen: boolean;
  hasMore: boolean;
  onClose: VoidFunction;
  handleFetchMorePersons: VoidFunction;
  organization: OrganizationData;
  selectedOrganization: OrganizationData;
  setSelectedOrganization: (organization: OrganizationData) => void;
  persons?: Array<PersonData>;
  total: number;
}
export const DesktopOrganizationHierarchyDialog = ({
  isOpen,
  onClose,
  handleFetchMorePersons,
  hasMore,
  organization,
  selectedOrganization,
  setSelectedOrganization,
  persons,
  total,
}: DesktopOrganizationHierarchyDialogProps) => {
  const { t } = useTranslation();

  return (
    <ActionDialog
      isOpen={isOpen}
      title={t('pages.organizationView.hierarchy.title')}
      primaryButtonText={t('components.common.close')}
      testId="organization-hierarchy-info-dialog"
      onClickPrimaryButton={onClose}
    >
      <StyledGrid container spacing={{ md: 1 }}>
        <StyledGrid item md={6}>
          <StyledWrapper>
            <StyledContent>
              <OrganizationHierarchyCard
                organization={organization}
                setSelectedOrganization={setSelectedOrganization}
              />
            </StyledContent>
            <Divider orientation="vertical" />
          </StyledWrapper>
        </StyledGrid>
        <StyledGrid item md={6}>
          <OrganizationHierarchyPersonCard
            organization={selectedOrganization}
            persons={persons}
            total={total}
            fetchMorePersons={handleFetchMorePersons}
            hasMore={hasMore}
          />
        </StyledGrid>
      </StyledGrid>
    </ActionDialog>
  );
};

const StyledGrid = styled(Grid)(() => ({
  height: '100%',
}));

const StyledWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  paddingRight: theme.spacing(1),
}));
