import { Button as MuiButton, styled } from '@mui/material';
import { ErrorPage } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import NotFound404Image from '@/assets/404.svg';

export const NotFound404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToDashboardButton = (
    <Button variant="contained" onClick={() => navigate('/')}>
      {t('pages.404.goToDashboard')}
    </Button>
  );

  return (
    <ErrorPage
      title={t('pages.404.pageNotFound')}
      subtitleLine1={t('pages.404.pageNotFoundDescription')}
      subtitleLine2={t('pages.404.wayBack')}
      actionButton={goToDashboardButton}
      imageFile={NotFound404Image}
      imageAlt="404 page not found"
    />
  );
};

const Button = styled(MuiButton)({
  textTransform: 'inherit',
});
