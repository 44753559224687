import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, styled, Typography, useTheme } from '@mui/material';
import { SnackbarIcon } from '@ysura/common';
import { useState } from 'react';

import { BannerOptions } from '@/hooks/useNotification';

type NotificationBannerProps = BannerOptions & {
  onClose: VoidFunction;
};

export const NotificationBanner = ({
  type = 'info',
  message,
  contentComponent,
  hasCloseButton,
  isFullWidth = false,
  button,
  onClose,
}: NotificationBannerProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const color = theme.palette[type].main;
  const backgroundColor = theme.palette[type].lighter;

  const handleClick = () => {
    if (button) {
      setIsLoading(true);
      button
        .action()
        .then((data) => {
          if (data && data.data) {
            onClose();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Wrapper isFullWidth={isFullWidth} data-testid="notification-banner">
      <MainContent>
        <SnackbarIcon
          icon={type}
          color={color}
          backgroundColor={backgroundColor}
        />
        {contentComponent ? (
          <div>{contentComponent}</div>
        ) : (
          <Typography variant="subtitle2">{message}</Typography>
        )}
      </MainContent>

      {button && (
        <LoadingButton
          color={type}
          variant="outlined"
          loading={isLoading}
          onClick={handleClick}
        >
          {button?.label}
        </LoadingButton>
      )}

      {hasCloseButton && !button && (
        <CloseButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </CloseButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullWidth',
})<{ isFullWidth: boolean }>(({ theme, isFullWidth }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.customShadows.z8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: isFullWidth ? '100%' : 'auto',
}));

const MainContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const CloseButton = styled(IconButton)({
  maxHeight: 30,
});
