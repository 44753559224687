import { ApolloError } from '@apollo/client';
import { TemplateData } from '@ysura/common';
import { useEffect, useState } from 'react';

import { DocumentType, useGetDocument } from '@/services/api';
import { useGetSampleRequestFormDocumentQuery } from '@/services/graphql/hooks';
import { parseTemplateData } from '@/services/graphql/parsers';

export type UseGetSampleRequestTemplate = {
  isLoading: boolean;
  data: TemplateData | null;
  error: ApolloError | undefined;
};

/**
 * __useGetSampleRequestTemplate__
 *
 * Finds the template for sample request forms and loads the HTML content.
 *
 * We expect, that the current realm has exactly one Template of type 'SAMPLE_REQUEST_FORM'.
 * For this template, we load and return the corresponding HTML content.
 *
 * @example
 * const { template, isLoading, error } = useGetSampleRequestTemplate();
 */
export const useGetSampleRequestTemplate = (): UseGetSampleRequestTemplate => {
  const [templateWithContent, setTemplateWithContent] =
    useState<TemplateData | null>(null);

  const {
    data: templateData,
    loading: isTemplateLoading,
    error: templateError,
  } = useGetSampleRequestFormDocumentQuery();

  // We expect that for Sample Request Forms, there is always excatly 1 template.
  const template = templateData?.templates?.edges?.[0]?.node
    ? parseTemplateData(templateData.templates.edges[0].node)
    : null;

  const { data: document, isLoading: isDocumentLoading } = useGetDocument(
    template?.document ?? null
  );

  const isLoading = isTemplateLoading || isDocumentLoading;

  useEffect(() => {
    if (
      !isLoading &&
      !templateError &&
      !templateWithContent &&
      template &&
      document &&
      document.mediaType === DocumentType.HTML
    ) {
      const documentData = template?.document;
      const newTemplate: TemplateData = {
        ...template,
        document: {
          ...documentData,
          content: document.data,
        },
      };

      setTemplateWithContent(newTemplate);
    }
  }, [isLoading, templateError, templateWithContent, template, document]);

  return {
    isLoading,
    data: !isLoading && templateWithContent ? templateWithContent : null,
    error: templateError,
  };
};
