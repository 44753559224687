import { gql } from '@apollo/client';

export const getSearchedOrganizations = gql`
  query getSearchedOrganizations(
    $search: String
    $first: Int
    $after: String
    $filter: OrganizationFilterInput
  ) {
    organizations(
      search: $search
      first: $first
      after: $after
      orderBy: [{ name: ASC }]
      filter: $filter
    ) {
      filteredCount
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          oid
          name
          customerReference
          organizationTypes(orderBy: { sortCode: ASC }) {
            edges {
              node {
                id
                oid
                baseType
              }
            }
          }
          rootOrganization {
            id
            oid
            name
          }
          addresses(
            filter: {
              addressType: { _eq: STANDARD }
              address: { state: { _eq: ACTIVE } }
            }
          ) {
            edges {
              node {
                addressType
                address {
                  id
                  oid
                  addressLine1
                  addressLine2
                  addressLine3
                  city
                  postalCode
                }
              }
            }
          }
        }
      }
    }
  }
`;
