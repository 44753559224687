import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetTouchPointGroupListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetTouchPointGroupListQuery = {
  __typename?: 'Query';
  touchPointGroups: {
    __typename?: 'TouchPointGroupConnection';
    edges?: Array<{
      __typename?: 'TouchPointGroupEdge';
      node?: {
        __typename?: 'TouchPointGroup';
        id: string;
        oid: string;
        name: string;
        state: Types.State;
      } | null;
    }> | null;
  };
};

/**
 * __useGetTouchPointGroupListQuery__
 *
 * To run a query within a React component, call `useGetTouchPointGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTouchPointGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTouchPointGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTouchPointGroupListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTouchPointGroupListQuery,
    GetTouchPointGroupListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetTouchPointGroupListQuery,
    GetTouchPointGroupListQueryVariables
  >(Operations.getTouchPointGroupList, options);
}
export function useGetTouchPointGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTouchPointGroupListQuery,
    GetTouchPointGroupListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetTouchPointGroupListQuery,
    GetTouchPointGroupListQueryVariables
  >(Operations.getTouchPointGroupList, options);
}
export function useGetTouchPointGroupListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTouchPointGroupListQuery,
    GetTouchPointGroupListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetTouchPointGroupListQuery,
    GetTouchPointGroupListQueryVariables
  >(Operations.getTouchPointGroupList, options);
}
export type GetTouchPointGroupListQueryHookResult = ReturnType<
  typeof useGetTouchPointGroupListQuery
>;
export type GetTouchPointGroupListLazyQueryHookResult = ReturnType<
  typeof useGetTouchPointGroupListLazyQuery
>;
export type GetTouchPointGroupListSuspenseQueryHookResult = ReturnType<
  typeof useGetTouchPointGroupListSuspenseQuery
>;
export type GetTouchPointGroupListQueryResult = Apollo.QueryResult<
  GetTouchPointGroupListQuery,
  GetTouchPointGroupListQueryVariables
>;
