import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddressData } from '@ysura/common';

import {
  getPostalCodeCityAddress,
  getStreetAddress,
} from '@/services/employment';

type AddressBoxProps = {
  address?: AddressData;
};

export const AddressLine = ({ address }: AddressBoxProps) => {
  const street = getStreetAddress(address);
  const postalCodeCityAddress = getPostalCodeCityAddress(address);

  if (!street && !postalCodeCityAddress) {
    return null;
  }

  return (
    <StreetBox>
      <StyledLocationOnIcon />
      <Box>
        <StyledTypography data-testid="address-line-address" variant="caption">
          {`${street} ${postalCodeCityAddress}`}
        </StyledTypography>
      </Box>
    </StreetBox>
  );
};

const StreetBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const StyledLocationOnIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'block',
}));
