import { DocumentData } from '@ysura/common';

import { convertedState } from '@/services/graphql/parsers/utils';
import { Document, DocumentConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseDocumentData = (
  document?: DeepPartial<Document>
): DocumentData => {
  return {
    id: document?.id,
    oid: document?.oid,
    name: document?.name ?? undefined,
    contentLink: document?.contentLink ?? undefined,
    mediaType: document?.mediaType ?? undefined,
    state: convertedState(document?.state),
  };
};

export const parseDocumentConnection = (
  documentConnection?: DeepPartial<DocumentConnection>
): Array<DocumentData> => {
  const rawDocuments = documentConnection?.edges ?? [];
  const mappedDocuments = rawDocuments
    .map((edge) => edge?.node ?? null)
    .map((document) => (document ? parseDocumentData(document) : null));

  return filterNonNull(mappedDocuments);
};
