import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer as MuiTableContainer,
  TableHead,
  TableRow as MuiTableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaData, NoItemPlaceholder } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useMediaContext } from '@/hooks/state';
import { OrderBy } from '@/services/graphql/types';

import { MediaListItem } from './MediaListItem';
import { MediaListItemSkeleton } from './MediaListItemSkeleton';

type MediaListProps = {
  isLoading: boolean;
  mediaData: Array<MediaData>;
  onOpenMediaContent(media: MediaData): void;
};

export const MediaList = ({
  isLoading,
  mediaData,
  onOpenMediaContent,
}: MediaListProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isInLandscapeScreen = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );
  const isSmallScreen =
    useMediaQuery(theme.breakpoints.down('sm')) || isInLandscapeScreen;
  const { mediaOrder, mediaQueryString, changeMediaOrder } = useMediaContext();

  // TODO: add a message if mediaData length is 0 and search filter is not applied
  if (!isLoading && mediaQueryString && mediaData.length === 0) {
    return (
      <NoItemPlaceholder>
        {t('pages.interaction.common.noSearchResult')}
      </NoItemPlaceholder>
    );
  }

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        {/* Table head */}
        {!isSmallScreen && (
          <TableHead>
            <MuiTableRow>
              <HeadTableCell align="left">
                <TableSortLabel
                  hideSortIcon
                  active={true}
                  direction={mediaOrder === OrderBy.ASC ? 'asc' : 'desc'}
                  onClick={() =>
                    changeMediaOrder?.(
                      mediaOrder === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC
                    )
                  }
                >
                  {t('pages.interaction.common.name')}

                  <SortingArrowBox>
                    {mediaOrder === OrderBy.DESC
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </SortingArrowBox>
                </TableSortLabel>
              </HeadTableCell>
              <HeadTableCell hasFixedWidth={true} align="left">
                {t('pages.interaction.common.status')}
              </HeadTableCell>
            </MuiTableRow>
          </TableHead>
        )}

        {/* Table Content Skeleton */}
        {isLoading && (
          <TableBody>
            {Array.from(new Array(5)).map((_, index) => (
              <MediaListItemSkeleton key={index} />
            ))}
          </TableBody>
        )}

        {/* Table Content */}
        {!isLoading && (
          <TableBody>
            {mediaData.map((media) => (
              <MediaListItem
                key={media.id}
                media={media}
                onSelectMedia={() => onOpenMediaContent(media)}
              />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled(MuiTableContainer)(() => ({
  paddingLeft: 1,
  paddingRight: 1,
}));

const Table = styled(MuiTable)(({ theme }) => ({
  borderCollapse: 'separate',
  borderSpacing: `0px ${theme.spacing(1)}`,
  marginTop: theme.spacing(-1),
}));

const SortingArrowBox = styled(Box)`
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
`;

type HeadTableCellStyleProps = {
  hasFixedWidth?: boolean;
};

const HeadTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'hasFixedWidth',
})<HeadTableCellStyleProps>(({ hasFixedWidth }) => ({
  boxShadow: 'none !important',
  width: hasFixedWidth ? 200 : 'auto',
}));
