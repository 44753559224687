import { Mic, Videocam } from '@mui/icons-material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, TextField, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconWrapper } from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

type AudioVideoSourceSelectProps = {
  isInDialog?: boolean;
};

export const AudioVideoSourceSelect = ({
  isInDialog = false,
}: AudioVideoSourceSelectProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    isCameraAccessDisabled,
    isMicAccessDisabled,
    audioDevices,
    videoDevices,
    setSelectedAudioInput,
    setSelectedVideoInput,
    selectedVideoInput,
    selectedAudioInput,
  } = useInteraction();

  const handleChangeDevice = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    const { value } = event.target;

    if (type === 'video') {
      setSelectedVideoInput?.(value);
    } else {
      setSelectedAudioInput?.(value);
    }
  };

  const renderLabel = (type: string) => {
    if (type === 'camera') {
      return (
        <LabelContainer>
          <Videocam /> {`${t('pages.interaction.audioVideoSettings.camera')}`}
        </LabelContainer>
      );
    }

    return (
      <LabelContainer>
        <Mic /> {`${t('pages.interaction.audioVideoSettings.mic')}`}
      </LabelContainer>
    );
  };

  const renderNotAllowedLabel = (type: string) => {
    return (
      <LabelContainer>
        <IconWrapper size={20} backgroundColor={theme.palette.error.main}>
          <ExclamationMarkIcon />
        </IconWrapper>
        {type === 'camera'
          ? `${t('pages.interaction.audioVideoSettings.cameraNotAllowed')}`
          : `${t('pages.interaction.audioVideoSettings.micNotAllowed')}`}
      </LabelContainer>
    );
  };

  return (
    <Container isInDialog={isInDialog}>
      <TextField
        fullWidth
        select={audioDevices?.length > 0}
        data-testid="select-mic"
        disabled={isMicAccessDisabled || audioDevices?.length === 0}
        value={selectedAudioInput}
        label={
          isMicAccessDisabled
            ? renderNotAllowedLabel('mic')
            : renderLabel('mic')
        }
        SelectProps={{
          native: true,
        }}
        onChange={(e) => handleChangeDevice(e, 'audio')}
      >
        {audioDevices.map((device) => {
          return (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          );
        })}
      </TextField>
      <TextField
        fullWidth
        select={videoDevices?.length > 0}
        data-testid="select-camera"
        disabled={isCameraAccessDisabled || videoDevices?.length === 0}
        value={selectedVideoInput}
        label={
          isCameraAccessDisabled
            ? renderNotAllowedLabel('camera')
            : renderLabel('camera')
        }
        SelectProps={{
          native: true,
        }}
        onChange={(e) => handleChangeDevice(e, 'video')}
      >
        {videoDevices.map((device) => {
          return (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          );
        })}
      </TextField>
    </Container>
  );
};

type ContainerStyleProps = {
  isInDialog: boolean;
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isInDialog',
})<ContainerStyleProps>(({ theme, isInDialog }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  flexDirection: isInDialog ? 'column' : 'row',
  paddingTop: isInDialog ? theme.spacing(1) : 0,
}));

const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
}));

const ExclamationMarkIcon = styled(PriorityHighIcon)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.grey[0],
}));
