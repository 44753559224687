import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Label, NoItemPlaceholder } from '@ysura/common';

import { EditIconButton } from '@/components/Button';
import { DisplayAvatar } from '@/components/Settings/common/DisplayAvatar';
import { OverviewStaticBlock } from '@/components/StaticBlock';

export type LabelType = {
  name?: string | undefined;
  avatar?:
    | {
        name: string;
        type: 'speciality' | 'attribute';
      }
    | undefined;
};

type SettingsFiltersListComponentProps = {
  title: string;
  noItemsText: string;
  labels: LabelType[];
  canEdit?: boolean;
  onClickEdit?: VoidFunction;
};

export const SettingsFiltersListComponent = ({
  title,
  noItemsText,
  labels,
  canEdit = true,
  onClickEdit,
}: SettingsFiltersListComponentProps) => {
  const handleClickEdit = () => {
    onClickEdit?.();
  };

  return (
    <OverviewStaticBlock
      title={title}
      subtitle={canEdit && <EditIconButton onClick={handleClickEdit} />}
    >
      <Container>
        {labels.length > 0 ? (
          labels.map(({ name, avatar }, idx) => {
            return (
              name && (
                <Box key={idx}>
                  <Label
                    key={`label-${idx}`}
                    variant="filled"
                    hasAvatar={!!avatar}
                  >
                    <Box>
                      {avatar && (
                        <DisplayAvatar
                          text={avatar.name.toLowerCase()}
                          icon={
                            avatar.type === 'speciality'
                              ? 'TRANSLATION'
                              : avatar.name
                          }
                        />
                      )}
                    </Box>
                    <StyledLabel variant="caption">{name}</StyledLabel>
                  </Label>
                </Box>
              )
            );
          })
        ) : (
          <NoItemPlaceholder>{noItemsText}</NoItemPlaceholder>
        )}
      </Container>
    </OverviewStaticBlock>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textWrap: 'wrap',
  paddingLeft: theme.spacing(0.5),
}));
