import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type FilterInputSearchProps = {
  searchQuery: string;
  isMobileSearchActive: boolean;
  changeSearchQuery: (value: string) => void;
  onClearSearchQuery: VoidFunction;
  onChangeSearchQuery: (value: ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
};

//TODO: refactor to a common input search
// https://youtrack.ysura.com/issue/CIP-1137/CleanUp-Search-Input
export const FilterInputSearch = ({
  searchQuery,
  isMobileSearchActive,
  onClearSearchQuery,
  onChangeSearchQuery,
  testId = 'filter-input-search',
}: FilterInputSearchProps) => {
  // Hooks
  const { t } = useTranslation();

  return (
    <Search
      data-testid={testId}
      autoComplete="off"
      size="small"
      fullWidth={true}
      placeholder={t('components.common.search')}
      value={searchQuery}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        ),
        endAdornment: !isMobileSearchActive && searchQuery && (
          <InputAdornment position="end">
            <ClearButton variant="text" onClick={onClearSearchQuery}>
              {t('components.common.clear')}
            </ClearButton>
          </InputAdornment>
        ),
      }}
      onChange={onChangeSearchQuery}
    />
  );
};

const Search = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  height: '30px',
}));

const IconSearch = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: 20,
}));

const ClearButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
}));
