import { StockData } from '@ysura/common';

import { parsePromotionalMaterial } from '@/services/graphql/parsers/promotionalMaterial/promotionalMaterialParser';
import { convertedStockType } from '@/services/graphql/parsers/utils';
import { Stock, StockConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseStockData = (stock: DeepPartial<Stock>): StockData => {
  return {
    oid: stock.oid ?? undefined,
    type: convertedStockType(stock.type),
    promotionalMaterial: stock.promotionalMaterial
      ? parsePromotionalMaterial(stock.promotionalMaterial)
      : undefined,
    promotionalMaterialBatch: stock.promotionalMaterialBatch
      ? {
          oid: stock.promotionalMaterialBatch.oid,
          name: stock.promotionalMaterialBatch.name ?? undefined,
          expiryDate: stock.promotionalMaterialBatch.expiryDate ?? undefined,
        }
      : undefined,
    quantity: stock.quantity ?? undefined,
  };
};

export const parseStockConnection = (
  stockConnection: DeepPartial<StockConnection>
): Array<StockData> => {
  const parsedConnection = stockConnection.edges?.map((edge) => {
    return edge?.node ? parseStockData(edge.node) : null;
  });

  return filterNonNull(parsedConnection);
};
