import { gql } from '@apollo/client';

export const getTouchpointListByPersonId = gql`
  query getTouchpointListByPersonId(
    $personId: ID!
    $status: ActivityStatus = PLANNED
  ) {
    person(id: $personId) {
      id
      activities(
        first: 2
        orderBy: [{ startDateTime: DESC }]
        filter: { state: { _eq: ACTIVE }, status: { _eq: $status } }
      ) {
        edges {
          node {
            id
            oid
            activityType {
              id
              oid
              name
              baseType
              schedulingType
              hideTimeForClosedActivity
            }
            organization {
              id
              oid
              name
              addresses(
                filter: {
                  addressType: { _eq: STANDARD }
                  address: { state: { _eq: ACTIVE } }
                }
              ) {
                edges {
                  node {
                    address {
                      addressLine1
                      addressLine2
                      addressLine3
                      city
                      postalCode
                    }
                  }
                }
              }
              rootOrganization {
                id
                oid
                name
              }
            }
            organizer {
              firstName
              middleName
              lastName
              prefixAcademicTitle {
                name
                state
              }
              postfixAcademicTitle {
                name
                state
              }
            }
            state
            status
            startDate
            startDateTime
            endDate
            endDateTime
            attendees(first: 1000) {
              edges {
                node {
                  person {
                    id
                    firstName
                    middleName
                    lastName
                    prefixAcademicTitle {
                      name
                      state
                    }
                    postfixAcademicTitle {
                      name
                      state
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
