import { useCallback } from 'react';

import { useCipState } from './useCipState';

export type UseLocale = {
  locale: string;
  setLocale: (locale: string) => void;
};

export const useLocale = (): UseLocale => {
  const { cipState, setCipState } = useCipState();

  const setLocale = useCallback(
    (locale: string) => {
      setCipState((oldState) => {
        return { ...oldState, locale };
      });
    },
    [setCipState]
  );

  return {
    locale: cipState.locale,
    setLocale,
  };
};
