import { useMediaQuery } from '@mui/material';
import {
  ActivityCoOrganizerData,
  AttendeeData,
  PersonData,
  Video as VideoComponent,
  VideoContainer,
} from '@ysura/common';
import { useCallback, useEffect, useRef } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useCurrentUser, useInteraction } from '@/hooks';
import { getPersonFullNameForInteraction } from '@/services/helper';

type VideoProps = {
  organizer?: PersonData;
  coOrganizers: Array<ActivityCoOrganizerData>;
  attendee?: AttendeeData;
};

export const Video = ({ organizer, attendee, coOrganizers }: VideoProps) => {
  const {
    initializeVideo,
    isCameraActive,
    isMicActive,
    isSharingInFullScreen,
    isAnyContentShared,
    getParticipantInteractionStep,
    participantDeviceState,
    participantVideoStatus,
  } = useInteraction();

  const { currentUser } = useCurrentUser();

  const attendeePersonId = attendee?.person?.oid;

  const videosPlaying = useRef<Array<string>>([]);

  /* ***************************************
   * Connect subscriber and provider videos
   ***************************************** */
  useEffect(() => {
    initializeVideo();
  }, [initializeVideo]);

  /* ***************************************
   * Video playback error management
   ***************************************** */
  const isVideoStatusOk = useCallback(
    (participantId: string) => {
      const videoStatus = participantVideoStatus[participantId];

      return (
        !videoStatus.shouldBePlaying ||
        (videoStatus.shouldBePlaying && videoStatus.isPlaying)
      );
    },
    [participantVideoStatus]
  );

  useEffect(() => {
    Object.keys(participantVideoStatus).forEach((participantId) => {
      if (isVideoStatusOk(participantId)) {
        console.log('Video status ok:', participantId);
        if (!videosPlaying.current.includes(participantId)) {
          videosPlaying.current.push(participantId);
        }
      } else {
        setTimeout(() => {
          if (!videosPlaying.current.includes(participantId)) {
            console.error('Video status not OK after 10s.', participantId);

            // TODO: Fix the problem
          }
        }, 10 * 1000);
      }
    });
  }, [isVideoStatusOk, participantVideoStatus]);

  const isLandscapeMode = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  const videos = coOrganizers
    .map((each) => each.person?.oid)
    .includes(currentUser?.person?.oid)
    ? [
        <VideoComponent
          key={attendeePersonId ?? ''}
          identifier={attendeePersonId ?? ''}
          currentStep={getParticipantInteractionStep(attendeePersonId ?? '')}
          name={getPersonFullNameForInteraction(attendee?.person)}
          isVideoActive={
            !isSharingInFullScreen &&
            participantDeviceState?.[attendeePersonId ?? '']?.isCameraActive
          }
          isAudioActive={
            participantDeviceState?.[attendeePersonId ?? '']?.isMicActive
          }
          showAsThumbnail={isSharingInFullScreen}
        />,
        <VideoComponent
          key={organizer?.oid ?? ''}
          identifier={organizer?.oid ?? ''}
          currentStep={getParticipantInteractionStep(organizer?.oid ?? '')}
          name={getPersonFullNameForInteraction(organizer)}
          isVideoActive={
            !isSharingInFullScreen &&
            participantDeviceState?.[organizer?.oid ?? '']?.isCameraActive
          }
          isAudioActive={
            participantDeviceState?.[organizer?.oid ?? '']?.isMicActive
          }
          showAsThumbnail={isSharingInFullScreen}
        />,
      ]
    : [
        <VideoComponent
          key={attendeePersonId ?? ''}
          identifier={attendeePersonId ?? ''}
          currentStep={getParticipantInteractionStep(attendeePersonId ?? '')}
          name={getPersonFullNameForInteraction(attendee?.person)}
          isVideoActive={
            !isSharingInFullScreen &&
            participantDeviceState?.[attendeePersonId ?? '']?.isCameraActive
          }
          isAudioActive={
            participantDeviceState?.[attendeePersonId ?? '']?.isMicActive
          }
          showAsThumbnail={isSharingInFullScreen}
        />,
      ];

  coOrganizers
    .filter((each) => each.person?.oid !== currentUser?.person?.oid)
    .forEach((each) =>
      videos.push(
        <VideoComponent
          key={each.person?.oid ?? ''}
          identifier={each.person?.oid ?? ''}
          currentStep={getParticipantInteractionStep(each.person?.oid ?? '')}
          name={getPersonFullNameForInteraction(each?.person)}
          isVideoActive={
            !isSharingInFullScreen &&
            participantDeviceState?.[each.person?.oid ?? '']?.isCameraActive
          }
          isAudioActive={
            participantDeviceState?.[each.person?.oid ?? '']?.isMicActive
          }
          showAsThumbnail={isSharingInFullScreen}
        />
      )
    );

  const myVideo = (
    <VideoComponent
      identifier={currentUser?.person?.oid ?? ''}
      currentStep={'interaction'}
      name={getPersonFullNameForInteraction(currentUser?.person)}
      showAsThumbnail={isSharingInFullScreen}
      isVideoActive={isCameraActive}
      isAudioActive={isMicActive}
    />
  );

  return (
    <VideoContainer
      topCameras={videos}
      bottomCamera={myVideo}
      showAsThumbnail={isSharingInFullScreen}
      hideCameraInMobile={isAnyContentShared}
      cameraDirection={isLandscapeMode ? 'horizontal' : 'vertical'}
    />
  );
};
