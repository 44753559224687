import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageOverlay } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { useNotification } from '@/hooks';
import {
  useGetKeycloakUserLazy,
  useImpersonateKeycloakUser,
} from '@/services/api';

type ImpersonationDialogProps = {
  isOpen: boolean;
  handleDialogClose: VoidFunction;
};

export const ImpersonationDialog = ({
  isOpen,
  handleDialogClose,
}: ImpersonationDialogProps) => {
  const [canSumbit, setCanSubmit] = useState(false);
  const [isInTransition, setIsInTransition] = useState(false);
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');

  const [getUser, { isLoading: isLoadingGetUser }] = useGetKeycloakUserLazy();
  const [impersonateUser, { isLoading: isLoadingImpersonate }] =
    useImpersonateKeycloakUser();

  const { toast } = useNotification();

  const { t } = useTranslation();

  const resetDialog = () => {
    setUsername('');
    setCanSubmit(false);
  };

  const handleValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newUsername = event.target.value;

    setUsername(newUsername);
    setCanSubmit(!!(newUsername?.trim().length > 0));
  };

  const handleCancel = () => {
    handleDialogClose();
    resetDialog();
  };

  const handleSuccessfulImpersonation = async () => {
    handleDialogClose();
    setIsInTransition(true);

    // We wait for 600ms to give the animation time to complete
    await setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  const handleUserNotFound = () => {
    // If the user name does not exist, we show the error message but leave
    // the dialog open with the user name filled out so the user can corrfect it.
    toast?.({
      type: 'error',
      message: t('components.impersonation.error.userDoesNotExist'),
    });
  };

  const handleSubmit = () => {
    getUser(username, {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSuccess: (data, _variables, _context) => {
        if (typeof data !== 'undefined' && data?.id) {
          const keycloakId = data?.id;
          setFullName(`${data?.firstName} ${data?.lastName}`.trim());

          impersonateUser(keycloakId, {
            onSuccess: handleSuccessfulImpersonation,

            onError: () => {
              handleCancel();
            },
          });
        } else {
          handleUserNotFound();
        }
      },

      onError: handleUserNotFound,
    });
  };

  return (
    <>
      <ConfirmationDialog
        testId="impersonation-dialog"
        content={
          <UsernameInput
            // A11y is not an issue here as this is a very small form anyways
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            data-testid="impersonation-dialog-username"
            size={'small'}
            label={t('components.impersonation.placeholder')}
            value={username}
            onChange={handleValueChange}
          />
        }
        title={t('components.impersonation.dialogTitle')}
        primaryButton={{
          text: t('components.common.ok'),
          isButtonDisabled:
            !canSumbit || isLoadingGetUser || isLoadingImpersonate,
          onClickHandler: handleSubmit,
          testId: 'impersonation-dialog-btn-ok',
        }}
        secondaryButton={{
          text: t('components.common.cancel'),
          onClickHandler: handleCancel,
          testId: 'impersonation-dialog-btn-cancel',
        }}
        isOpen={isOpen}
      />
      {isInTransition && (
        <PageOverlay
          animate="enter"
          overlayText={t('components.impersonation.overlayMessage', {
            fullName,
          })}
        />
      )}
    </>
  );
};

const UsernameInput = styled(TextField)(({ theme }) => ({
  // we need a small margin as otherwise the focus ring is cut off
  marginTop: theme.spacing(1),
}));
