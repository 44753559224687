import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';

type QueryLoadingErrorMessageProps = {
  componentName: string;
};

export const QueryLoadingErrorMessage = ({
  componentName,
}: QueryLoadingErrorMessageProps) => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="components.error.dataErrorMessage"
      values={{
        component: componentName,
        service: t('components.error.customerService'),
      }}
      components={{
        Link: <StyledLink href="mailto:service@ysura.com" target="_blank" />,
      }}
    />
  );
};

const StyledLink = styled(Link)(() => ({
  textDecoration: 'underline',
}));
