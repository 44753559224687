import { gql } from '@apollo/client';

export const getPreferences = gql`
  query getPreferences($preferenceId: ID!) {
    preference(id: $preferenceId) {
      oid
      facets {
        personFacets {
          personTypes {
            id
            oid
            name
          }
          specialities {
            id
            oid
            name
            classification
          }
          specialityGroups {
            id
            oid
            name
          }
          employeeFunctions {
            id
            oid
            name
          }
          consentTypes {
            id
            oid
            name
            digitalSignatureProcessEnabled
            paperProcessEnabled
            manualProcessEnabled
            doubleOptInDigitalEnabled
            doubleOptInPaperEnabled
            doubleOptInManualEnabled
          }
          attributes {
            id
            oid
            name
            type
          }
        }
        organizationFacets {
          organizationTypes {
            id
            oid
            name
          }
          cities
          attributes {
            id
            oid
            name
            type
          }
        }
      }
    }
  }
`;
