import { UserData } from '@ysura/common';

import { OrganizationClientPermissions } from '@/services/user/permissions/ClientPermissions';
import { hasClientPermission } from '@/services/user/permissions/helper';

export const getOrganizationPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): OrganizationClientPermissions => {
  return {
    canReadFacets: hasClientPermission(
      apiUserData,
      isAdmin,
      'organization-facets-read'
    ),
    canReadCityFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'organization-city-filter'
    ),
    canReadTypeFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'organization-type-filter'
    ),
    canReadAttributeFilter: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-attributes-filter'
    ),
  };
};
