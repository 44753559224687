import { PersonPromotionalMaterialData } from '@ysura/common';

export const getSortedSamples = (
  samples: Array<PersonPromotionalMaterialData>
) => {
  if (!samples?.length) {
    return [];
  }

  const fulfilledSamples = samples.filter(
    (sample) =>
      (sample?.maximumQuantityInCurrentPeriod || 0) -
        (sample?.givenQuantityInCurrentPeriod || 0) ===
      0
  );

  const unfulfilledSamples = samples.filter(
    (sample) =>
      (sample?.maximumQuantityInCurrentPeriod || 0) -
        (sample?.givenQuantityInCurrentPeriod || 0) >
      0
  );

  const sortFunction = (
    sampleA: PersonPromotionalMaterialData,
    sampleB: PersonPromotionalMaterialData
  ) => {
    if (
      (sampleA.promotionalMaterial.name || '') <
      (sampleB.promotionalMaterial.name || '')
    ) {
      return -1;
    }
    if (
      (sampleA.promotionalMaterial.name || '') >
      (sampleB.promotionalMaterial.name || '')
    ) {
      return 1;
    }

    return 0;
  };

  const sortedFulfilledSamples = fulfilledSamples.sort(sortFunction);

  const sortedUnfulfilledSamples = unfulfilledSamples.sort(sortFunction);

  return [...sortedUnfulfilledSamples, ...sortedFulfilledSamples];
};
