import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  ActivityStatusDataEnum,
  ActivityTypeSchedulingDataEnum,
  TouchpointManagementModeData,
  TouchpointStatusTypeDataEnum,
} from '@ysura/common';
import { isAfter, isBefore } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SectionLabel } from '../SharedStyledComponents';
import { BaseFormikProps } from '../types';

interface TouchpointStatusProps extends BaseFormikProps {
  selectedTouchpointType: string;
  mode: TouchpointManagementModeData;
  activity?: ActivityData;
  isSubmitting?: boolean;
}

export const TouchpointStatus = ({
  touchpointValues,
  selectedTouchpointType,
  activity,
  mode,
  isSubmitting = false,
  setFieldValue,
}: TouchpointStatusProps) => {
  const { t } = useTranslation();

  const getDateValue = useCallback(() => {
    const startDate = touchpointValues?.startDateTime
      ? touchpointValues?.startDateTime
      : touchpointValues?.startDate;
    if (!startDate) {
      return new Date();
    }

    return new Date(startDate);
  }, [touchpointValues?.startDateTime, touchpointValues?.startDate]);

  const isDateOnly = useCallback(() => {
    return (
      touchpointValues?.touchpointType?.schedulingType ===
        ActivityTypeSchedulingDataEnum.START_DATE ||
      touchpointValues?.touchpointType?.schedulingType ===
        ActivityTypeSchedulingDataEnum.START_AND_END_DATE
    );
  }, [touchpointValues?.touchpointType?.schedulingType]);

  const isStatusSelectionDisabled =
    !selectedTouchpointType ||
    isSubmitting ||
    activity?.status === ActivityStatusDataEnum.CLOSED;

  const isClosedStatusEnabled = useCallback(() => {
    const now = new Date();
    const activityDate = getDateValue();
    if (isDateOnly()) {
      // for comparison reasons we need to set the time part to midnight
      now.setHours(0, 0, 0, 0);
      activityDate.setHours(0, 0, 0, 0);
    }

    return !isStatusSelectionDisabled && !isAfter(activityDate, now);
  }, [getDateValue, isDateOnly, isStatusSelectionDisabled]);

  const isPlannedStatusEnabled = useCallback(() => {
    const now = new Date();
    const activityDate = getDateValue();
    if (isDateOnly()) {
      // for comparison reasons we need to set the time part to midnight
      now.setHours(0, 0, 0, 0);
      activityDate.setHours(0, 0, 0, 0);
    }

    return !isStatusSelectionDisabled && !isBefore(activityDate, now);
  }, [getDateValue, isDateOnly, isStatusSelectionDisabled]);

  useEffect(() => {
    // if both planned and closed are possible,
    // do not update currently selected value
    if (isPlannedStatusEnabled() && isClosedStatusEnabled()) {
      return;
    }

    if (
      // if currently PlannedTouchpoint or ConfirmedPlannedTouchpoint is selected
      // and only done is possible, we need to set to DoneTouchpoint
      isClosedStatusEnabled() &&
      touchpointValues?.touchpointStatus !==
        TouchpointStatusTypeDataEnum.DoneTouchpoint
    ) {
      setFieldValue?.(
        'touchpointStatus',
        TouchpointStatusTypeDataEnum.DoneTouchpoint
      );
    }

    if (
      // if currently DoneTouchpoint is selected and only planned is possible, we need to set to PlannedTouchpoint
      isPlannedStatusEnabled() &&
      touchpointValues?.touchpointStatus ===
        TouchpointStatusTypeDataEnum.DoneTouchpoint
    ) {
      setFieldValue?.(
        'touchpointStatus',
        TouchpointStatusTypeDataEnum.PlannedTouchpoint
      );
    }
  }, [
    isPlannedStatusEnabled,
    isClosedStatusEnabled,
    activity?.status,
    touchpointValues,
    setFieldValue,
  ]);

  const handleChangeTouchpointType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const touchpointTypeStatus = (event.target as HTMLInputElement).value;

    setFieldValue?.('touchpointStatus', touchpointTypeStatus);
  };

  return (
    <>
      <SectionLabel variant="subtitle2">
        {mode === 'create'
          ? t('components.touchpointManagement.createAs')
          : t('components.touchpointManagement.touchpointStatus')}
      </SectionLabel>

      <RadioGroup
        value={touchpointValues?.touchpointStatus}
        onChange={handleChangeTouchpointType}
      >
        <RadioLabel
          value={TouchpointStatusTypeDataEnum.PlannedTouchpoint}
          control={<Radio />}
          label={t('components.touchpointManagement.plannedTouchpoint')}
          disabled={!isPlannedStatusEnabled()}
        />
        <RadioLabel
          value={TouchpointStatusTypeDataEnum.ConfirmedPlannedTouchpoint}
          control={<Radio />}
          label={t(
            'components.touchpointManagement.confirmedPlannedTouchpoint'
          )}
          disabled={!isPlannedStatusEnabled()}
        />
        <RadioLabel
          value={TouchpointStatusTypeDataEnum.DoneTouchpoint}
          control={<Radio />}
          label={t('components.touchpointManagement.doneTouchpoint')}
          disabled={!isClosedStatusEnabled()}
        />
      </RadioGroup>
    </>
  );
};

const RadioLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  [theme.breakpoints.down('md')]: {
    // this is needed so the radio button is not cut on the left
    paddingLeft: '1px',
  },
}));
