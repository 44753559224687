import { PersonConsentData, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';
import { ConsentCardList } from '@/components/Consent';

type ConsentAccordionBlockProps = {
  consentList: Array<PersonConsentData>;
  person: PersonData;
  isInRemoteRoom?: boolean;
  isDigitalConsentActionsEnable?: boolean;
};

export const ConsentAccordionBlock = ({
  consentList,
  person,
  isInRemoteRoom = false,
  isDigitalConsentActionsEnable,
}: ConsentAccordionBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewAccordionBlock
      title={t('pages.interaction.remote.consent')}
      numberOfItems={consentList?.length}
      testId="consent-accordion-block"
    >
      <ConsentCardList
        isInRemoteRoom={isInRemoteRoom}
        isDigitalConsentActionsEnable={isDigitalConsentActionsEnable}
        consentList={consentList}
        person={person}
      />
    </OverviewAccordionBlock>
  );
};
