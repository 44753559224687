import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useAuth } from '@/hooks';

import { KeycloakUser } from './types';
import { getKeycloakRestApiUrl, parseKeycloakUserList } from './utils';

const getUserByUsername = async (
  username: string,
  token = ''
): Promise<KeycloakUser | undefined> => {
  const apiUrl = getKeycloakRestApiUrl();

  const params = `?username=${username}`;

  const response = await axios.get(`${apiUrl}/users/${params}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'json',
  });

  const userList = parseKeycloakUserList(response?.data);

  return userList?.[0];
};

/**
 * __useGetKeycloakUserLazy__
 *
 * Allows you to call the `useGetKeycloakUserLazy` hook lazily. The first parameter in the returned
 * array is a function that can be called to execute the query.
 *
 * @param username The Keycloak username to load.
 *
 * @example
 * const [getKeycloakUser, { data, isLoading, error }] = useGetKeycloakUserLazy();
 * ...
 * getKeycloakUser('basler');
 */
export function useGetKeycloakUserLazy() {
  const { getToken } = useAuth();

  const token = getToken();

  const { mutate, ...query } = useMutation(
    ['keycloakUsers'],
    (username: string) => getUserByUsername(username, token),
    {}
  );

  return [mutate, query] as const;
}
