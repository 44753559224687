import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CallStatusContentInfoBlock } from '@/views/Interaction/Telephone';

type CallStatusProps = {
  activity: ActivityData;
};

export const CallStatusBlockCard = ({ activity }: CallStatusProps) => {
  const { t } = useTranslation();

  return (
    <Card data-testid="call-status">
      <CardHeader
        title={t('pages.interaction.telephone.callStatus.title')}
        titleTypographyProps={{
          variant: 'subtitle2',
        }}
      />
      <CardContent>
        <CallStatusContentInfoBlock activity={activity} />
      </CardContent>
    </Card>
  );
};

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: 0,
  color: theme.palette.text.secondary,
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
}));
