import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';

type DrawerButtonProps = {
  isSharing: boolean;
  isDrawerOpen: boolean;
  toggleDrawer: VoidFunction;
};

export const DrawerButton = ({
  isSharing,
  isDrawerOpen,
  toggleDrawer,
}: DrawerButtonProps) => {
  // show drawer button only while isSharing is false
  if (isSharing) {
    return null;
  }

  return (
    <IconButton onClick={toggleDrawer}>
      {isDrawerOpen ? (
        <KeyboardArrowDownIcon fontSize="small" />
      ) : (
        <KeyboardArrowUpIcon fontSize="small" />
      )}
    </IconButton>
  );
};
