import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

export const LeftArrow = styled(ArrowLeftRoundedIcon)(({ theme }) => ({
  fontSize: 26,
  color: theme.palette.text.primary,
  transform: 'scale(2)',
}));

export const RightArrow = styled(ArrowRightRoundedIcon)(({ theme }) => ({
  fontSize: 26,
  color: theme.palette.text.primary,
  transform: 'scale(2)',
}));

export const MiddleButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
}));
