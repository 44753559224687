import {
  PersonData,
  PersonPromotionalMaterialData,
  PersonSpecialityData,
  PersonSpecialityGroupData,
  PersonTypeData,
} from '@ysura/common';

import {
  parseActivityConnection,
  parseCommunicationDataData,
  parseEmploymentConnection,
  parsePromotionalMaterial,
  parseTouchPointStatisticData,
} from '@/services/graphql/parsers';
import {
  convertedGender,
  convertedSpecialityClassification,
  convertedState,
} from '@/services/graphql/parsers/utils';
import {
  Person,
  PersonConnection,
  PersonPromotionalMaterialConnection,
  PersonType,
  PersonTypeConnection,
  Speciality,
  SpecialityConnection,
  SpecialityGroup,
  SpecialityGroupConnection,
  State,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseSpecialityGroup = (
  specialityGroup: DeepPartial<SpecialityGroup>
): PersonSpecialityGroupData => {
  return {
    id: specialityGroup?.id,
    oid: specialityGroup?.oid,
    state: convertedState(specialityGroup?.state),
    name: specialityGroup?.name || undefined,
  };
};

export const parseSpeciality = (
  speciality: DeepPartial<Speciality>
): PersonSpecialityData => {
  return {
    id: speciality?.id,
    oid: speciality?.oid,
    name: speciality?.name ?? undefined,
    classification: convertedSpecialityClassification(
      speciality?.classification
    ),
  };
};

export const parseSpecialityGroups = (
  specialityGroups: DeepPartial<SpecialityGroupConnection>
): PersonSpecialityGroupData[] => {
  const rawSpecialityGroups = specialityGroups?.edges ?? [];
  const mappedSpecialityGroups = rawSpecialityGroups
    .map((edge) => edge?.node ?? null)
    .map((item) => (item ? parseSpecialityGroup(item) : null));

  return filterNonNull(mappedSpecialityGroups);
};

export const parseSpecialities = (
  specialities: DeepPartial<SpecialityConnection>
) => {
  const rawSpecialities = specialities?.edges ?? [];
  const mappedSpecialities = rawSpecialities
    .map((edge) => edge?.node ?? null)
    .map((item) => (item ? parseSpeciality(item) : null));

  return filterNonNull(mappedSpecialities);
};

export const parsePersonType = (
  parsePersonType: DeepPartial<PersonType> | null
): PersonTypeData => {
  return {
    id: parsePersonType?.id ?? '',
    oid: parsePersonType?.oid ?? '',
    name: parsePersonType?.name ?? '',
  };
};

export const parsePersonTypes = (
  personTypes: DeepPartial<PersonTypeConnection>
) => {
  const rawPersonTypes = personTypes?.edges ?? [];
  const mappedPersonTypes = rawPersonTypes
    .map((edge) => edge?.node ?? null)
    .map((item) => (item ? parsePersonType(item) : null));

  return filterNonNull(mappedPersonTypes);
};

export const parsePersonData = (
  person?: DeepPartial<Person> | null
): PersonData => {
  return {
    id: person?.id,
    oid: person?.oid,
    customerReference: person?.customerReference ?? undefined,
    gender: convertedGender(person?.gender),
    types: filterNonNull(
      person?.personTypes?.edges?.map((item) => item?.node?.name)
    ),
    specialities: filterNonNull(
      person?.specialities?.edges?.map((item) => item?.node?.speciality?.name)
    ),
    specialityGroup: person?.specialityGroup
      ? parseSpecialityGroup(person?.specialityGroup)
      : undefined,
    communicationData: parseCommunicationDataData(person?.communicationData),
    prefixAcademicTitle:
      person?.prefixAcademicTitle?.state === State.ACTIVE
        ? person?.prefixAcademicTitle?.name ?? undefined
        : undefined,
    postfixAcademicTitle:
      person?.postfixAcademicTitle?.state === State.ACTIVE
        ? person?.postfixAcademicTitle?.name ?? undefined
        : undefined,
    firstName: person?.firstName ?? undefined,
    lastName: person?.lastName ?? undefined,
    totalEmployments: person?.employments?.filteredCount,
    employments: parseEmploymentConnection(person?.employments),
    state: convertedState(person?.state),
    activities: person?.activities
      ? parseActivityConnection(person?.activities)
      : undefined,
    promotionalMaterials: person?.promotionalMaterials
      ? parsePersonPromotionalMaterialConnection(person?.promotionalMaterials)
      : undefined,
    // the yRoom attendeeId defaults to the oid but is later overwritten with the room data
    yroomAttendeeId: person?.oid,
    touchPointStatistics: parseTouchPointStatisticData(person),
  };
};

export const parsePersonsData = (
  persons: DeepPartial<Array<Person>>
): Array<PersonData> => {
  const parsedPersons = persons.map((person) =>
    person ? parsePersonData(person) : null
  );

  return filterNonNull(parsedPersons);
};

export const parsePersonConnection = (
  personConnection?: DeepPartial<PersonConnection>
): Array<PersonData> => {
  const rawPersons = personConnection?.edges ?? [];
  const mappedPersons = rawPersons.map((edge) => edge?.node ?? null);

  return parsePersonsData(filterNonNull(mappedPersons));
};

export const parsePersonPromotionalMaterialConnection = (
  promotionalMaterials:
    | DeepPartial<PersonPromotionalMaterialConnection>
    | undefined
): Array<PersonPromotionalMaterialData> => {
  if (!promotionalMaterials?.edges?.length) {
    return [];
  }

  return promotionalMaterials?.edges?.map((item) => {
    return {
      promotionalMaterial: parsePromotionalMaterial(
        item?.node?.promotionalMaterial
      ),
      givenQuantityInCurrentPeriod:
        item?.node?.givenQuantityInCurrentPeriod ?? undefined,
      maximumQuantityInCurrentPeriod:
        item?.node?.maximumQuantityInCurrentPeriod ?? undefined,
    };
  });
};
