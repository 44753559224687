import { StateDataEnum } from '@ysura/common';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { errorConfigBannerDialog, useCurrentUser } from '@/hooks';
import {
  useAddRecentAccountMutation,
  useGetPersonByIdQuery,
} from '@/services/graphql/hooks';
import { parsePersonData } from '@/services/graphql/parsers';
import { RecentAccountType } from '@/services/graphql/types';
import { ActivePerson, InactivePerson } from '@/views/PersonView';

import { NotFound404 } from '../Error';

export const PersonPage = () => {
  const { id } = useParams();
  const { currentUser } = useCurrentUser();

  const [addRecentAccountMutation] = useAddRecentAccountMutation();
  const { data, loading, error } = useGetPersonByIdQuery({
    variables: {
      personId: id ?? '',
    },
    onCompleted: (data) => {
      if (
        currentUser?.permissions?.common?.canReadHistory &&
        data.person?.oid
      ) {
        addRecentAccountMutation({
          ...errorConfigBannerDialog,
          variables: {
            oid: data.person?.oid,
            type: RecentAccountType.PERSON,
          },
        });
      }
    },
  });

  if (!loading && !error && isEmpty(data?.person)) {
    return <NotFound404 />;
  }

  const person = data?.person ? parsePersonData(data.person) : null;

  if (person && person.state !== StateDataEnum.ACTIVE) {
    return <InactivePerson person={person} />;
  } else {
    return <ActivePerson isPageLoading={loading} person={person} />;
  }
};
