import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  Card,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CommentData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { hasEditCommentPermission } from '@/services/permission';

type CommentEditFieldProps = {
  comment: CommentData;
  onChange: (comment: CommentData) => void;
  isExpanded?: boolean;
};

export const CommentEditField = ({
  comment,
  onChange,
  isExpanded,
}: CommentEditFieldProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(isExpanded);

  const handleAccordionToggle = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updateComment = { ...comment, content: event.target.value };
    onChange(updateComment);
  };

  // If the comment exists (it's not dummy object), then check if user has edit permission
  const isEditDisabled =
    !!comment.permissions && !hasEditCommentPermission(comment);

  return (
    <Container>
      <Accordion expanded={expanded} onChange={handleAccordionToggle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <StyledTypography variant="body1">
            {comment.commentType?.name ?? ''}
          </StyledTypography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <TextField
            multiline
            fullWidth
            disabled={isEditDisabled}
            value={comment.content}
            placeholder={t(
              'components.touchpointManagement.comments.commentPlaceholder'
            )}
            onChange={handleCommentChange}
          />
        </MuiAccordionDetails>
      </Accordion>
    </Container>
  );
};

const Container = styled(Card)(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
