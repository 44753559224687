import { createContext } from 'react';

import { IAuthContextProps } from './types';

export const KeycloakContext = createContext<IAuthContextProps>({
  initialized: false,
  authClient: null,
});

export const KeycloakConsumer = KeycloakContext.Consumer;
