import { gql } from '@apollo/client';

export const getTouchPointGroupList = gql`
  query getTouchPointGroupList {
    touchPointGroups {
      edges {
        node {
          id
          oid
          name
          state
        }
      }
    }
  }
`;
