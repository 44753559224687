import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder, TouchPointGroupData } from '@ysura/common';
import upperFirst from 'lodash/upperFirst';
import { useTranslation } from 'react-i18next';

import { getTouchPointColor } from '@/views/PersonView/Touchpoint/helpers';

import { TouchpointRadialBar } from './TouchpointRadialBar';

type TouchpointGroupsProps = {
  touchpointGroups: Array<TouchPointGroupData>;
};

export const TouchpointGroups = ({
  touchpointGroups,
}: TouchpointGroupsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Wrapper>
      {touchpointGroups.length === 0 && (
        <EmptyWrapper>
          <NoItemPlaceholder>
            {t('pages.personView.noTouchpointPlan')}
          </NoItemPlaceholder>
        </EmptyWrapper>
      )}

      <Wrapper>
        {touchpointGroups?.map((touchpointGroup) => (
          <TouchpointRadialBar
            key={touchpointGroup?.name}
            label={upperFirst(touchpointGroup?.name)}
            color={getTouchPointColor(theme, touchpointGroup?.name)}
          />
        ))}
      </Wrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  gap: theme.spacing(1),
}));

const EmptyWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',
}));
