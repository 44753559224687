import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CommentData,
  CommentTargetTypeDataEnum,
  CommentTypeData,
  NoItemPlaceholder,
  StateDataEnum,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { CommentEditField } from '@/components/Comment';

type CommentEditedDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
  save: (comments: CommentData[]) => void;
  commentTypes?: CommentTypeData[];
  sourceComments: CommentData[];
  isLoading?: boolean;
};
export const CommentEditedDialog = ({
  isOpen,
  closeDialog,
  save,
  commentTypes,
  sourceComments,
  isLoading,
}: CommentEditedDialogProps) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState<CommentData[]>(sourceComments);

  const handleSave = () => {
    save(comments);
  };

  const handleChange = (comment: CommentData) => {
    const unrelatedComments = comments.filter(
      (it) => comment.commentType?.oid !== it.commentType?.oid
    );
    const updateComments: CommentData[] = [
      ...unrelatedComments,
      comment,
    ].filter((it: CommentData) => it.content);
    setComments(updateComments);
  };

  const handleComments = (activityComments: CommentData[]) => {
    return (commentTypes ?? []).map((commentType) => {
      const relatedComment = activityComments.find(
        (comment) => comment.commentType?.oid === commentType.oid
      );
      if (relatedComment) {
        relatedComment.commentType = commentType;

        return relatedComment;
      } else {
        return {
          commentType: commentType,
          targetType: CommentTargetTypeDataEnum.ACTIVITY,
          state: StateDataEnum.ACTIVE,
          content: undefined,
        };
      }
    });
  };

  const parseComments: CommentData[] = handleComments(comments);

  return (
    <ActionDialog
      isOpen={isOpen}
      isLoading={isLoading}
      testId="comment-edited-dialog"
      title={t('pages.activity.comments')}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      maxWidth={'xs'}
      height={'medium'}
      onClickPrimaryButton={handleSave}
      onClickSecondaryButton={closeDialog}
    >
      <Container>
        {!parseComments.length && (
          <NoItemPlaceholder>
            {t('pages.activity.noComment.planned')}
          </NoItemPlaceholder>
        )}
        {parseComments.map((comment: CommentData, index: number) => (
          <CommentEditField
            key={comment.commentType?.oid}
            comment={comment}
            isExpanded={index === 0}
            onChange={handleChange}
          />
        ))}
      </Container>
    </ActionDialog>
  );
};

const Container = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  height: '100%',
  overflowY: 'scroll',
  gap: theme.spacing(1),
}));
