import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconWrapper } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useNotification } from '@/hooks';

type CopyToClipBoardButtonProps = {
  copyValue: string;
  darkStyle?: boolean;
};

export const CopyToClipBoardButton = ({
  copyValue,
  darkStyle,
}: CopyToClipBoardButtonProps) => {
  const { toast } = useNotification();
  const { t } = useTranslation();

  const handleClick = () => {
    toast?.({
      message: t('components.notification.copiedToClipboard'),
    });
    navigator.clipboard.writeText(copyValue);
  };

  return (
    <IconButton data-testid="copy-to-clip-board-button" onClick={handleClick}>
      <StyledIconWrapper darkStyle={darkStyle}>
        <ContentCopy />
      </StyledIconWrapper>
    </IconButton>
  );
};

const StyledIconWrapper = styled(IconWrapper, {
  shouldForwardProp: (prop) => prop !== 'darkStyle',
})<{ darkStyle: boolean | undefined }>(({ theme, darkStyle }) => ({
  backgroundColor: darkStyle ? theme.palette.grey[500_48] : 'transparent',
  svg: {
    fill: darkStyle ? theme.palette.common.white : theme.palette.common.black,
  },
}));
