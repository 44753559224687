import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type ResetPersonConsentMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
  issuerPersonId: Types.Scalars['PersonId']['input'];
  actionAt: Types.Scalars['DateTime']['input'];
}>;

export type ResetPersonConsentMutation = {
  __typename?: 'Mutation';
  resetPersonConsent?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type ResetPersonConsentMutationFn = Apollo.MutationFunction<
  ResetPersonConsentMutation,
  ResetPersonConsentMutationVariables
>;

/**
 * __useResetPersonConsentMutation__
 *
 * To run a mutation, you first call `useResetPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPersonConsentMutation, { data, loading, error }] = useResetPersonConsentMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      issuerPersonId: // value for 'issuerPersonId'
 *      actionAt: // value for 'actionAt'
 *   },
 * });
 */
export function useResetPersonConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPersonConsentMutation,
    ResetPersonConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ResetPersonConsentMutation,
    ResetPersonConsentMutationVariables
  >(Operations.resetPersonConsent, options);
}
export type ResetPersonConsentMutationHookResult = ReturnType<
  typeof useResetPersonConsentMutation
>;
export type ResetPersonConsentMutationResult =
  Apollo.MutationResult<ResetPersonConsentMutation>;
export type ResetPersonConsentMutationOptions = Apollo.BaseMutationOptions<
  ResetPersonConsentMutation,
  ResetPersonConsentMutationVariables
>;
