import { gql } from '@apollo/client';

export const getPersonPromotionalMaterials = gql`
  query getPersonPromotionalMaterials($personId: ID!) {
    person(id: $personId) {
      id
      promotionalMaterials(orderBy: { promotionalMaterial: { name: ASC } }) {
        edges {
          node {
            givenQuantityInCurrentPeriod
            maximumQuantityInCurrentPeriod
            promotionalMaterial {
              id
              oid
              type
              name
              signatureRequired
              batches {
                edges {
                  node {
                    oid
                    name
                    expiryDate
                  }
                }
              }
              topics {
                edges {
                  node {
                    id
                    oid
                    name
                  }
                }
              }
              rules {
                ... on PromotionalMaterialEndDateRule {
                  oid
                  endDate
                }
                ... on PromotionalMaterialMaximumQuantityPerPeriodRule {
                  oid
                  maximumQuantity
                  period {
                    type
                  }
                }
                ... on PromotionalMaterialMaximumQuantityPerActivityRule {
                  oid
                  maximumQuantity
                }
              }
            }
          }
        }
      }
    }
  }
`;
