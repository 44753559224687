import { Person } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, getPersonFullNameWithTitle } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { RowWrapper } from '@/components/ActivityDetails';

type ActivityOrganizerProps = {
  activity: ActivityData;
};

export const ActivityOrganizer = ({ activity }: ActivityOrganizerProps) => {
  const { t } = useTranslation();

  return (
    <WrapperStack>
      <Typography color="disabled" variant="caption">
        {t('pages.personView.organizer')}
      </Typography>
      <RowWrapper>
        <Person />
        <Typography variant="caption">
          {getPersonFullNameWithTitle(activity.organizer)}
        </Typography>
      </RowWrapper>
    </WrapperStack>
  );
};

const WrapperStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));
