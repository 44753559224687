import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, PersonConsentData } from '@ysura/common';

import { CommentAccordionBlock } from '@/components/Comment';
import { useCurrentUser } from '@/hooks';
import { checkIfPromatExists } from '@/views/Interaction/helpers';
import {
  ConsentWrapper,
  MediaAccordionBlock,
  OrganizationAccordionBlock,
  PersonDataAccordionBlock,
} from '@/views/Interaction/Overview';
import { PromotionalMaterialAccordionBlock } from '@/views/Interaction/Overview/PromotionalMaterial';

type InteractionInformationAccordionListProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  isInRemoteRoom: boolean;
  isMediaViewedStatusUpdatedOnOpen: boolean;
  isActivityEditable?: boolean;
};

export const InteractionInformationAccordionList = ({
  activity,
  isOrganizer,
  consentList,
  isInRemoteRoom,
  isMediaViewedStatusUpdatedOnOpen,
  isActivityEditable,
}: InteractionInformationAccordionListProps) => {
  const isPromatAvailable = activity ? checkIfPromatExists(activity) : false;
  const { currentUser } = useCurrentUser();

  const firstAttendee = activity?.attendees?.[0]?.person;

  return (
    <Wrapper data-testid="list-accordion">
      {isOrganizer && (
        <>
          <MediaAccordionBlock
            activity={activity}
            isViewedStatusUpdatedOnOpen={isMediaViewedStatusUpdatedOnOpen}
          />

          {isPromatAvailable &&
            currentUser?.permissions?.person?.canReadSampleDrop && (
              <PromotionalMaterialAccordionBlock
                activity={activity}
                isRemoteInteraction={isInRemoteRoom}
              />
            )}

          {firstAttendee && (
            <ConsentWrapper
              isInRemoteRoom={isInRemoteRoom}
              isDigitalConsentActionsEnable={false}
              consentList={consentList}
              person={firstAttendee}
            />
          )}

          {currentUser?.permissions?.activity?.canReadActivityComment && (
            <CommentAccordionBlock
              commentTypes={activity.activityType?.commentTypes}
              status={activity.status}
              isActivityEditable={isActivityEditable}
            />
          )}
        </>
      )}

      <PersonDataAccordionBlock activity={activity} />

      <OrganizationAccordionBlock activity={activity} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(4),
  },
}));
