import { ActivityData } from '@ysura/common';

import { CallStatusContentInfoBlock } from '@/views/Interaction/Telephone';

import { CallStatusContentActions } from './';

type CallStatusContentProps = {
  activity: ActivityData;
  onStartInteraction?: VoidFunction;
  onCancelInteraction?: VoidFunction;
  onCallInfoDialog?: VoidFunction;
  isMobile?: boolean;
  isDrawerOpen?: boolean;
};

export const CallStatusContentBlock = ({
  activity,
  onStartInteraction,
  onCancelInteraction,
  onCallInfoDialog,
  isDrawerOpen = false,
  isMobile = false,
}: CallStatusContentProps) => {
  return (
    <>
      {isDrawerOpen && <CallStatusContentInfoBlock activity={activity} />}
      <CallStatusContentActions
        isMobile={isMobile}
        onStartInteraction={onStartInteraction}
        onCallInfoDialog={onCallInfoDialog}
        onCancelInteraction={onCancelInteraction}
      />
    </>
  );
};
