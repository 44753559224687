import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonData, PersonInfo, PersonInfoProps } from '@ysura/common';

import { getAttendeeData } from '@/components/Attendee';
import { BaseFormikProps } from '@/views/Activity/TouchpointManagement/types';

interface AttendeeInfoProps extends Partial<BaseFormikProps> {
  variant: 'add' | 'remove' | 'selected' | 'none';
  attendee: PersonData;
  searchQuery?: string;
  isSubmitting?: boolean;
  onCloseSearch?: VoidFunction;
  showDivider?: boolean;
}
export const AttendeeInfo = ({
  variant,
  attendee,
  isSubmitting = false,
  onCloseSearch,
  setFieldValue,
  showDivider = true,
}: AttendeeInfoProps) => {
  const { person, organization, address } = getAttendeeData(attendee);

  const handlerOnClickAddAttendee = () => {
    if (isSubmitting) {
      return;
    }

    setFieldValue?.('attendees', [attendee], true);

    if (attendee.employments?.length) {
      setFieldValue?.(
        'organization',
        attendee.employments?.[0].organization ?? null,
        false
      );
    }

    onCloseSearch?.();
  };

  const handlerOnClickRemoveAttendee = () => {
    if (isSubmitting) {
      return;
    }

    setFieldValue?.('attendees', [], true);
    setFieldValue?.('organization', null, false);
  };

  const handlerOnClick: VoidFunction =
    variant === 'add'
      ? handlerOnClickAddAttendee
      : handlerOnClickRemoveAttendee;

  const personInfoProps: PersonInfoProps = {
    person: {
      title: person,
      showIcon: true,
      showBalloonIcon: true,
    },
    organization: {
      title: organization,
      showIcon: true,
    },
    address: {
      street: address,
      showIcon: true,
    },
    operation: variant,
    onClick: handlerOnClick,
  };

  return (
    <Wrapper data-testid="attendee-info">
      <PersonInfo
        person={personInfoProps.person}
        organization={personInfoProps.organization}
        address={personInfoProps.address}
        operation={personInfoProps.operation}
        onClick={personInfoProps.onClick}
      />
      {showDivider && <CustomizeDivider />}
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'contents',
});

const CustomizeDivider = styled(Divider)({
  background: 'rgba(145, 158, 171, 0.24)',
  width: '95%',
});
