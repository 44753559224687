import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type UpdatePreferenceMutationVariables = Types.Exact<{
  oid: Types.Scalars['PreferenceId']['input'];
  actions: Array<Types.UpdatePreferenceAction> | Types.UpdatePreferenceAction;
}>;

export type UpdatePreferenceMutation = {
  __typename?: 'Mutation';
  updatePreference?: { __typename?: 'Preference'; oid: string } | null;
};

export type UpdatePreferenceMutationFn = Apollo.MutationFunction<
  UpdatePreferenceMutation,
  UpdatePreferenceMutationVariables
>;

/**
 * __useUpdatePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferenceMutation, { data, loading, error }] = useUpdatePreferenceMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      actions: // value for 'actions'
 *   },
 * });
 */
export function useUpdatePreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreferenceMutation,
    UpdatePreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdatePreferenceMutation,
    UpdatePreferenceMutationVariables
  >(Operations.updatePreference, options);
}
export type UpdatePreferenceMutationHookResult = ReturnType<
  typeof useUpdatePreferenceMutation
>;
export type UpdatePreferenceMutationResult =
  Apollo.MutationResult<UpdatePreferenceMutation>;
export type UpdatePreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreferenceMutation,
  UpdatePreferenceMutationVariables
>;
