import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetCurrentUserPreferenceQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserPreferenceQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    oid: string;
    preference?: {
      __typename?: 'Preference';
      oid: string;
      facets?: {
        __typename?: 'FacetsPreference';
        personFacets: {
          __typename?: 'PersonFacetsPreference';
          personTypes: Array<{
            __typename?: 'PersonType';
            id: string;
            oid: string;
            name?: string | null;
          }>;
          specialities: Array<{
            __typename?: 'Speciality';
            id: string;
            oid: string;
            name?: string | null;
          }>;
          specialityGroups: Array<{
            __typename?: 'SpecialityGroup';
            id: string;
            oid: string;
            name?: string | null;
          }>;
          employeeFunctions: Array<{
            __typename?: 'EmployeeFunction';
            id: string;
            oid: string;
            name?: string | null;
          }>;
          attributes: Array<{
            __typename?: 'Attribute';
            id: string;
            oid: string;
            name: string;
            type: Types.AttributeType;
            options: Array<{
              __typename?: 'AttributeOption';
              id: string;
              oid: string;
              value: string;
            }>;
          }>;
          consentTypes: Array<{
            __typename?: 'ConsentType';
            id: string;
            oid: string;
            name: string;
            digitalSignatureProcessEnabled: boolean;
            paperProcessEnabled: boolean;
            manualProcessEnabled: boolean;
            doubleOptInDigitalEnabled?: boolean | null;
            doubleOptInPaperEnabled?: boolean | null;
            doubleOptInManualEnabled?: boolean | null;
            consentItems: {
              __typename?: 'ConsentItemConnection';
              edges?: Array<{
                __typename?: 'ConsentItemEdge';
                node?: {
                  __typename?: 'ConsentItem';
                  oid: string;
                  name: string;
                } | null;
              }> | null;
            };
          }>;
        };
        organizationFacets: {
          __typename?: 'OrganizationFacetsPreference';
          cities: Array<string>;
          organizationTypes: Array<{
            __typename?: 'OrganizationType';
            id: string;
            oid: string;
            name?: string | null;
          }>;
          attributes: Array<{
            __typename?: 'Attribute';
            id: string;
            oid: string;
            name: string;
            type: Types.AttributeType;
            options: Array<{
              __typename?: 'AttributeOption';
              id: string;
              oid: string;
              value: string;
            }>;
          }>;
        };
      } | null;
    } | null;
  } | null;
};

/**
 * __useGetCurrentUserPreferenceQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPreferenceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserPreferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserPreferenceQuery,
    GetCurrentUserPreferenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetCurrentUserPreferenceQuery,
    GetCurrentUserPreferenceQueryVariables
  >(Operations.getCurrentUserPreference, options);
}
export function useGetCurrentUserPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserPreferenceQuery,
    GetCurrentUserPreferenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetCurrentUserPreferenceQuery,
    GetCurrentUserPreferenceQueryVariables
  >(Operations.getCurrentUserPreference, options);
}
export function useGetCurrentUserPreferenceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCurrentUserPreferenceQuery,
    GetCurrentUserPreferenceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetCurrentUserPreferenceQuery,
    GetCurrentUserPreferenceQueryVariables
  >(Operations.getCurrentUserPreference, options);
}
export type GetCurrentUserPreferenceQueryHookResult = ReturnType<
  typeof useGetCurrentUserPreferenceQuery
>;
export type GetCurrentUserPreferenceLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserPreferenceLazyQuery
>;
export type GetCurrentUserPreferenceSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserPreferenceSuspenseQuery
>;
export type GetCurrentUserPreferenceQueryResult = Apollo.QueryResult<
  GetCurrentUserPreferenceQuery,
  GetCurrentUserPreferenceQueryVariables
>;
