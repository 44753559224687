import { MediaVersionData } from '@ysura/common';

import { parseDocumentData } from '@/services/graphql/parsers';
import { MediaVersion } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const parseMediaVersionData = (
  version: DeepPartial<MediaVersion>
): MediaVersionData => {
  return {
    oid: version?.oid ?? undefined,
    version: version?.version ?? undefined,
    document: version?.document
      ? parseDocumentData(version.document)
      : undefined,
  };
};
