import { WAITING_ROOM } from '@ysura/common';
import { useState } from 'react';

import { InteractionMobileLayout } from '@/components/InteractionMobileLayout';
import {
  InteractionInformationContent,
  WaitingRoomProps,
} from '@/views/Interaction/Common';
import {
  AudioVideoControlsMobile,
  AudioVideoSettingsDialog,
  PreviewVideo,
} from '@/views/Interaction/Remote/AudioVideoSettings';

export const RemoteWaitingRoomMobile = ({
  activity,
  isOrganizer,
  consentList,
  onStartInteraction,
  onCancelInteraction,
  isActivityEditable,
}: WaitingRoomProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <InteractionMobileLayout
        drawerBleeding={WAITING_ROOM.MOBILE.DRAWER_HEIGHT_PORTRAIT}
        videoComp={<PreviewVideo />}
        actionComp={
          <AudioVideoControlsMobile
            handleOpenSettingsDialog={() => setIsDialogOpen(true)}
            handleStartInteraction={onStartInteraction}
            handleCancelInteraction={onCancelInteraction}
          />
        }
        contentComp={
          <InteractionInformationContent
            isOrganizer={isOrganizer}
            activity={activity}
            consentList={consentList}
            isMediaViewedStatusUpdatedOnOpen={false}
            isActivityEditable={isActivityEditable}
          />
        }
      />

      <AudioVideoSettingsDialog
        isOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
      />
    </>
  );
};
