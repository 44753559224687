import { SettingsButton } from '@ysura/common';
import { useNavigate } from 'react-router-dom';

import {
  checkOrganizationFilterPermission,
  FilterPermissionData,
} from '@/components/Settings/helper';
import { useCurrentUser } from '@/hooks';

type PersonSettingsButtonProps = {
  onClick: VoidFunction;
};
export const OrganizationSettingsButton = ({
  onClick,
}: PersonSettingsButtonProps) => {
  const navigate = useNavigate();

  const handleClickSettingButton = () => {
    navigate(`/settings/organization`);
    onClick?.();
  };

  // Check permission to visualize settings for organizations
  const { currentUser } = useCurrentUser();
  const organizationPermission: FilterPermissionData =
    checkOrganizationFilterPermission(currentUser?.permissions?.organization);

  if (organizationPermission?.numAvailablePermission === 0) {
    return <></>;
  }

  return (
    <SettingsButton
      color="inherit"
      testId="organization-filter-setting-button"
      onClick={handleClickSettingButton}
    />
  );
};
