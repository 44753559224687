import {
  ConsentHistoryData,
  ConsentTypeData,
  ConsentTypeValidityModeDateEnum,
  getPersonFullNameWithTitle,
  PersonConsentData,
} from '@ysura/common';

export const getConsentValidity = (
  consentType: ConsentTypeData,
  collectedDate: Date | undefined,
  translation: (value: string) => string,
  formatDate: (date: number | Date, formatString: string) => string
) => {
  switch (consentType.validityMode) {
    case ConsentTypeValidityModeDateEnum.INFINITE:
      return translation('components.consentDialog.infiniteValidityMode');
    case ConsentTypeValidityModeDateEnum.PERIOD:
      return (
        (collectedDate &&
          formatDate(
            new Date(
              collectedDate.getFullYear(),
              collectedDate.getMonth() +
                (consentType.validityPeriodInMonths ?? 0),
              collectedDate.getDate()
            ),
            'dd.MM.yyyy'
          )) ??
        ''
      );
    case ConsentTypeValidityModeDateEnum.END_DATE:
      return (
        (consentType.validityEndDate &&
          formatDate(consentType.validityEndDate, 'dd.MM.yyyy')) ??
        ''
      );
  }
};

export const getCollectionPeriod = (
  consent: PersonConsentData,
  formatDate: (date: number | Date, formatString: string) => string
): string => {
  const startDate = consent.consentType?.startDate
    ? formatDate(consent?.consentType?.startDate, 'dd.MM.yyyy')
    : '';
  const endDate = consent.consentType?.endDate
    ? formatDate(consent?.consentType?.endDate, 'dd.MM.yyyy')
    : '';

  return `${startDate} - ${endDate}`;
};

export const getSource = (
  translation: (value: string) => string,
  source: ConsentHistoryData
): string => {
  return source.action
    ? translation(
        'components.consentDialog.personConsent.action.' + source.action
      )
    : '';
};

export const handleActionContent = (
  handledAt: Date,
  translation: (value: string) => string,
  formatDate: (date: number | Date, formatString: string) => string
): string => {
  const handleDate = formatDate(handledAt, 'dd.MM.yyyy');

  return `${translation(
    'components.consentDialog.personConsent.handled'
  )}, ${handleDate}`;
};

export const getHistoryTitle = (
  history: ConsentHistoryData,
  formatDate: (date: number | Date, formatString: string) => string
) => {
  return history.createdAt
    ? `${formatDate(
        history.createdAt,
        'dd.MM.yyyy'
      )}, ${getPersonFullNameWithTitle(history.issuer)}`.trim()
    : getPersonFullNameWithTitle(history?.issuer);
};
