import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetSearchedPersonsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PersonFilterInput>;
}>;

export type GetSearchedPersonsQuery = {
  __typename?: 'Query';
  persons: {
    __typename?: 'PersonConnection';
    filteredCount: number;
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
    };
    edges?: Array<{
      __typename?: 'PersonEdge';
      node?: {
        __typename?: 'Person';
        id: string;
        oid: string;
        firstName?: string | null;
        lastName?: string | null;
        prefixAcademicTitle?: {
          __typename?: 'AcademicTitle';
          name?: string | null;
          state: Types.State;
        } | null;
        employments: {
          __typename?: 'EmploymentConnection';
          edges?: Array<{
            __typename?: 'EmploymentEdge';
            node?: {
              __typename?: 'Employment';
              id: string;
              oid: string;
              organization: {
                __typename?: 'Organization';
                id: string;
                oid: string;
                name?: string | null;
                addresses: {
                  __typename?: 'EntityAddressConnection';
                  edges?: Array<{
                    __typename?: 'EntityAddressEdge';
                    node?: {
                      __typename?: 'EntityAddress';
                      addressType: Types.AddressType;
                      address: {
                        __typename?: 'Address';
                        addressLine1?: string | null;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        city: string;
                        postalCode: string;
                        id: string;
                      };
                    } | null;
                  }> | null;
                };
              };
            } | null;
          }> | null;
        };
      } | null;
    }> | null;
  };
};

/**
 * __useGetSearchedPersonsQuery__
 *
 * To run a query within a React component, call `useGetSearchedPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchedPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchedPersonsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSearchedPersonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchedPersonsQuery,
    GetSearchedPersonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetSearchedPersonsQuery,
    GetSearchedPersonsQueryVariables
  >(Operations.getSearchedPersons, options);
}
export function useGetSearchedPersonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchedPersonsQuery,
    GetSearchedPersonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetSearchedPersonsQuery,
    GetSearchedPersonsQueryVariables
  >(Operations.getSearchedPersons, options);
}
export function useGetSearchedPersonsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSearchedPersonsQuery,
    GetSearchedPersonsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetSearchedPersonsQuery,
    GetSearchedPersonsQueryVariables
  >(Operations.getSearchedPersons, options);
}
export type GetSearchedPersonsQueryHookResult = ReturnType<
  typeof useGetSearchedPersonsQuery
>;
export type GetSearchedPersonsLazyQueryHookResult = ReturnType<
  typeof useGetSearchedPersonsLazyQuery
>;
export type GetSearchedPersonsSuspenseQueryHookResult = ReturnType<
  typeof useGetSearchedPersonsSuspenseQuery
>;
export type GetSearchedPersonsQueryResult = Apollo.QueryResult<
  GetSearchedPersonsQuery,
  GetSearchedPersonsQueryVariables
>;
