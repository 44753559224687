import { RoomHeaderWrapper } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/PageHeader';
import { useInteraction } from '@/hooks';

type RoomHeaderProps = {
  title: string;
  isOrganizer: boolean;
  openEndInteractionDialog: VoidFunction;
};

export const RoomHeader = ({
  title,
  isOrganizer,
  openEndInteractionDialog,
}: RoomHeaderProps) => {
  const { t } = useTranslation();
  const { isAnyContentShared } = useInteraction();

  const buttonLabel = `pages.interaction.common.${
    isOrganizer ? 'endInteraction' : 'leaveInteraction'
  }`;

  // the coOrganizer can always leave
  const isButtonDisabled = isOrganizer && isAnyContentShared;

  return (
    <RoomHeaderWrapper>
      <PageHeader
        title={title}
        titleVariant="dark"
        buttonText={t(buttonLabel)}
        buttonHandler={openEndInteractionDialog}
        isButtonDisabled={isButtonDisabled}
      />
    </RoomHeaderWrapper>
  );
};
