import {
  ActivityData,
  getPersonFullNameWithTitle,
  PersonConsentData,
  SimpleDialog,
  useLocalStorage,
  VideoControlsWrapper,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageContainer } from '@/components/Interaction';
import { useInteraction } from '@/hooks';
import { redirectBackToCrm } from '@/services/api';
import { RoomHeader } from '@/views/Interaction/Common/Room';
import { EndRemoteInteractionDialog } from '@/views/Interaction/EndInteraction';

import { AudioVideoSettingsDialog } from '../AudioVideoSettings';
import { VideoControlsMobile } from '../Video';
import { CallInfoDialog } from './CallInfoDialog';
import { RemoteRoomContent } from './RemoteRoomContent';

interface RoomProps {
  activity: ActivityData;
  consentList: PersonConsentData[];
  canEditActivity?: boolean;
  roomId: string;
  isOrganizer: boolean;
}

export const RemoteRoom = ({
  activity,
  consentList,
  roomId,
  isOrganizer,
}: RoomProps) => {
  const { t } = useTranslation();
  const [isCallInfoOpen, setIsCallInfoOpen] = useState(false);
  const [isEndInteractionOpen, setIsEndInteractionOpen] = useState(false);
  const [
    isCoOrganizerLeaveInteractionOpen,
    setIsEndCoOrganizerLeaveInteractionOpen,
  ] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const navigate = useNavigate();
  const { leaveInteraction } = useInteraction();
  const [isFromCrm] = useLocalStorage<boolean>('fromCrm', false);

  const firstAttendee = activity?.attendees?.[0]?.person;

  const renderVideoControlsMobile = () => {
    return (
      <VideoControlsMobile
        openSettingsDialog={() => setIsSettingsOpen(true)}
        openCallInfoDialog={() => setIsCallInfoOpen(true)}
        openEndInteractionDialog={handleOpenEndInteractionDialog}
        attendee={firstAttendee}
        isOrganizer={isOrganizer}
      />
    );
  };

  const handleOpenEndInteractionDialog = () => {
    if (isOrganizer) {
      setIsEndInteractionOpen(true);
    } else {
      setIsEndCoOrganizerLeaveInteractionOpen(true);
    }
  };

  const handleLeaveEndInteractionDialog = () => {
    setIsEndCoOrganizerLeaveInteractionOpen(false);
    leaveInteraction();

    if (isFromCrm) {
      redirectBackToCrm();
    } else {
      navigate(`/touchpoint/${activity.id}`);
    }
  };

  return (
    <>
      <PageContainer>
        {/* TelephoneRoom header is only visible on desktop */}
        <RoomHeader
          title={t('pages.interaction.common.interactionWithAttendee', {
            name: getPersonFullNameWithTitle(firstAttendee),
          })}
          isOrganizer={isOrganizer}
          openEndInteractionDialog={handleOpenEndInteractionDialog}
        />

        {/* This exact controls component is only visible on mobile landscape */}
        <VideoControlsWrapper>
          {renderVideoControlsMobile()}
        </VideoControlsWrapper>

        {/* Content is always visible */}
        <RemoteRoomContent
          canEditActivity
          isOrganizer={isOrganizer}
          activity={activity}
          consentList={consentList}
          roomId={roomId}
          renderVideoControlsMobile={renderVideoControlsMobile}
          openSettingsDialog={() => setIsSettingsOpen(true)}
          openCallInfoDialog={() => setIsCallInfoOpen(true)}
        />
      </PageContainer>

      {/* Dialogs are always accessible */}
      <>
        <CallInfoDialog
          activity={activity}
          isOpen={isCallInfoOpen}
          closeDialog={() => setIsCallInfoOpen(false)}
        />

        <EndRemoteInteractionDialog
          activity={activity}
          isOpen={isEndInteractionOpen}
          closeDialog={() => setIsEndInteractionOpen(false)}
        />

        <AudioVideoSettingsDialog
          isOpen={isSettingsOpen}
          closeDialog={() => setIsSettingsOpen(false)}
        />

        <SimpleDialog
          isOpen={isCoOrganizerLeaveInteractionOpen}
          testId={'leave-interaction-dialog'}
          title={t('pages.interaction.common.leaveInteractionTitle')}
          closeDialog={handleLeaveEndInteractionDialog}
          closeDialogText={t('pages.interaction.common.leave')}
          cancelDialog={() => setIsEndCoOrganizerLeaveInteractionOpen(false)}
          cancelDialogText={t('components.common.cancel')}
        />
      </>
    </>
  );
};
