import { ActivityCoOrganizerData } from '@ysura/common';

import {
  parseOrganizationalUnitData,
  parsePersonData,
} from '@/services/graphql/parsers';
import {
  ActivityCoOrganizer,
  ActivityCoOrganizerConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseActivityCoOrganizerData = (
  coOrganizer: DeepPartial<ActivityCoOrganizer>
): ActivityCoOrganizerData => {
  return {
    id: coOrganizer?.id,
    oid: coOrganizer?.oid,
    person: coOrganizer?.person
      ? parsePersonData(coOrganizer.person)
      : undefined,
    organizationalUnit: coOrganizer?.organizationalUnit
      ? parseOrganizationalUnitData(coOrganizer.organizationalUnit)
      : undefined,
  };
};

export const parseActivityCoOrganizerConnection = (
  activityCoOrganizerConnection?: DeepPartial<ActivityCoOrganizerConnection>
): Array<ActivityCoOrganizerData> => {
  const rawActivityCoOrganizerConnections =
    activityCoOrganizerConnection?.edges ?? [];
  const mappedRawActivityCoOrganizerConnections =
    rawActivityCoOrganizerConnections.map((edge) =>
      edge?.node ? parseActivityCoOrganizerData(edge.node) : null
    );

  return filterNonNull(mappedRawActivityCoOrganizerConnections);
};
