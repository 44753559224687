import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import {
  ResendDoubleOptInMutation,
  ResendDoubleOptInMutationVariables,
  useResendDoubleOptInMutation as useResendConsentDoubleComponentMutationApollo,
} from '../generated';

type ResendConsentDoubleComponentVariables =
  // oid is required
  Pick<ResendDoubleOptInMutationVariables, 'oid'>;

/**
 * __useResendConsentDoubleComponentMutation__
 *
 * To run a mutation, you first call `useResendConsentDoubleComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConsentDoubleComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConsentDoubleOptIn, { data, loading, error }] = useResendConsentDoubleComponentMutation();
 *
 * resendConsentDoubleOptIn({
 *   variables: {
 *      oid: // defaults to a new uuid
 *   },
 * });
 */
export const useResendDoubleOptInMutation = (
  baseOptions?: MutationHookOptions<
    ResendDoubleOptInMutation,
    ResendDoubleOptInMutationVariables
  >
) => {
  const [resendConsentDoubleOptIn, status] =
    useResendConsentDoubleComponentMutationApollo({
      ...baseOptions,
    });

  const doMutate = async (
    options: MutationFunctionOptions<
      ResendDoubleOptInMutation,
      ResendConsentDoubleComponentVariables
    >
  ) => {
    const defaultVariables = {
      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
    };

    return resendConsentDoubleOptIn({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
