import { PersonConsentData, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ConsentCardList } from '@/components/Consent';
import { OverviewStaticBlock } from '@/components/StaticBlock';

type ConsentStaticBlockProps = {
  person: PersonData;
  consentList: Array<PersonConsentData>;
  isInRemoteRoom?: boolean;
};

export const ConsentStaticBlock = ({
  consentList,
  person,
  isInRemoteRoom = false,
}: ConsentStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.interaction.common.consentColumnTitle')}
      spacing={isInRemoteRoom ? 'dense' : 'spacious'}
      testId="consent-static-block"
    >
      <ConsentCardList
        consentList={consentList}
        person={person}
        isInRemoteRoom={isInRemoteRoom}
      />
    </OverviewStaticBlock>
  );
};
