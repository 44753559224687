import {
  AttributeData,
  AttributeOptionData,
  AttributeTypeDataEnum,
  BooleanEnumData,
  ConsentItemData,
  ConsentItemStateChangeDataEnum,
  ConsentTypeData,
  FacetFilterData,
  FilterItemsData,
  filterNonNull,
  OptionFilterData,
  OrganizationFilterData,
  OrganizationPreferenceFacetData,
  PersonConsentLifecycleStateDataEnum,
  PersonFilterData,
  PersonPreferenceFacetData,
  SubFacetFilterData,
} from '@ysura/common';
import uniq from 'lodash/uniq';

import { MULTI_ITEM_FILTER_TYPES } from '@/components/GlobalSearch/utils/constants';
import { DeepPartial } from '@/types/data';

export const countSelectedItemFilters = (filters: FilterItemsData): number => {
  let count = 0;

  filters?.forEach((category) => {
    const selectedFilters = category.values.filter((item) => item.isSelected);
    if (selectedFilters?.length) {
      count += selectedFilters.length;
    }
  });

  return count;
};

export const updateSelectedFilterItems = (
  selectedFilterId: string,
  selectedItem: string,
  checked: boolean,
  filters: FilterItemsData
): FilterItemsData => {
  const newFilterData = [...filters];

  newFilterData.forEach((filter) => {
    const targetFilter = filter.values.find(
      (item: FacetFilterData) => item.id === selectedFilterId
    );
    if (targetFilter) {
      const options = updatedSelectedItemsInOptions(
        selectedItem,
        checked,
        targetFilter?.options ?? []
      );
      const optionsInSubFacet = targetFilter.subFacets
        ? updateSelectedItemsInSubFacets(
            selectedItem,
            checked,
            targetFilter.subFacets ?? []
          )
        : [];

      targetFilter.isSelected =
        (!MULTI_ITEM_FILTER_TYPES.includes(filter.filterType) && checked) ||
        options.some((it) => it.isSelected) ||
        optionsInSubFacet.some((it) => it.isSelected);
      if (!targetFilter.isSelected) {
        delete targetFilter.isSelected;
      }
    }
  });

  return newFilterData;
};

const updatedSelectedItemsInOptions = (
  selectedItem: string,
  checked: boolean,
  options: OptionFilterData[]
): OptionFilterData[] => {
  const targetInOptions = options.find((option: OptionFilterData) => {
    return option.oid
      ? option.oid === selectedItem
      : option.name === selectedItem;
  });

  if (targetInOptions && checked) {
    targetInOptions.isSelected = true;
  } else {
    delete targetInOptions?.isSelected;
  }

  return options;
};

const updateSelectedItemsInSubFacets = (
  selectedItem: string,
  checked: boolean,
  subFacets: SubFacetFilterData[]
): SubFacetFilterData[] => {
  subFacets.forEach((subFacet: SubFacetFilterData) => {
    if (subFacet.options) {
      // Looking for in subFacet.options
      const targetInSubFacetsOption = subFacet.options?.find(
        (subOptions: OptionFilterData) => {
          return subOptions.oid
            ? subOptions.oid === selectedItem
            : subOptions.name === selectedItem;
        }
      );

      if (targetInSubFacetsOption && checked) {
        targetInSubFacetsOption.isSelected = true;
      } else {
        delete targetInSubFacetsOption?.isSelected;
      }
      subFacet.isSelected = subFacet.options?.some((it) => it.isSelected);
      if (!subFacet.isSelected) {
        delete subFacet.isSelected;
      }
    }
  });

  return subFacets;
};

export const resetSelectedFilterItems = (
  filters: FilterItemsData
): FilterItemsData => {
  const newFilterData = [...filters];
  newFilterData.forEach((filter) => {
    filter.values.forEach((val) => {
      delete val.isSelected;
      val.options?.forEach((option) => delete option.isSelected);
      val.subFacets?.forEach((subFacet) => {
        delete subFacet.isSelected;
        subFacet.options?.forEach((option) => delete option.isSelected);
      });
    });
  });

  return newFilterData;
};

export const parsePersonFilters = (
  personFacetsPreferences?: PersonPreferenceFacetData
): Array<PersonFilterData> => {
  const filters: Array<PersonFilterData> = [];

  filters.push({
    filterType: 'personTypes',
    values:
      personFacetsPreferences?.personTypes?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'specialities',
    values:
      personFacetsPreferences?.specialities?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'specialityGroups',
    values:
      personFacetsPreferences?.specialityGroups?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'employeeFunctions',
    values:
      personFacetsPreferences?.employeeFunctions?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'attributes',
    values: personFacetsPreferences?.attributes
      ? getAttributesFilters(personFacetsPreferences.attributes)
      : [],
  });

  filters.push({
    filterType: 'consentTypes',
    values: personFacetsPreferences?.consentTypes
      ? parsePersonConsentTypeFilters(personFacetsPreferences.consentTypes)
      : [],
  });

  return filters;
};

export const convertPersonFilterValue = (
  personFacets?: PersonPreferenceFacetData
): Array<PersonFilterData> => {
  const filters: Array<PersonFilterData> = [];

  filters.push({
    filterType: 'personTypes',
    values:
      personFacets?.personTypes?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'specialities',
    values:
      personFacets?.specialities?.map(({ id, oid, name, classification }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          avatar: `specialities.classification.${classification}` ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'specialityGroups',
    values:
      personFacets?.specialityGroups?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'employeeFunctions',
    values:
      personFacets?.employeeFunctions?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'consentTypes',
    values:
      personFacets?.consentTypes?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'attributes',
    values:
      personFacets?.attributes?.map(({ id, oid, name, type }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
          avatar: type?.toString() ?? undefined,
        };
      }) ?? [],
  });

  return filters;
};

const buildConsentTypeOptions = (
  consentType: ConsentTypeData
): Array<OptionFilterData> => {
  let lifeCycles = [
    PersonConsentLifecycleStateDataEnum.DEFAULT,
    PersonConsentLifecycleStateDataEnum.ACCEPTED,
    PersonConsentLifecycleStateDataEnum.REJECTED,
  ];

  if (consentType.paperProcessEnabled) {
    lifeCycles = [
      ...lifeCycles,
      PersonConsentLifecycleStateDataEnum.PENDING_PAPER,
    ];
  }

  if (
    consentType.doubleOptInDigitalEnabled ||
    consentType.doubleOptInManualEnabled ||
    consentType.doubleOptInPaperEnabled
  ) {
    lifeCycles = [
      ...lifeCycles,
      PersonConsentLifecycleStateDataEnum.PENDING_DOUBLE_OPT_IN,
    ];
  }

  return lifeCycles.map((lifeCycle: PersonConsentLifecycleStateDataEnum) => {
    return {
      id: lifeCycle,
      oid: `${consentType.oid ?? ''}-${lifeCycle}`,
      name: `components.globalSearch.filter.type.consentType.state.${lifeCycle}`,
    };
  });
};

const parsePersonConsentTypeFilters = (
  consentTypes: ConsentTypeData[]
): Array<FacetFilterData> => {
  return consentTypes.map((consentType) => {
    return {
      id: consentType?.id,
      oid: consentType?.oid,
      name: consentType?.name,
      options: buildConsentTypeOptions(consentType),
      subFacets: buildSubFacets(consentType?.consentItems),
    };
  });
};

export const parseOrganizationFilters = (
  organizationFacets?: OrganizationPreferenceFacetData
): Array<OrganizationFilterData> => {
  const filters: Array<OrganizationFilterData> = [];

  filters.push({
    filterType: 'organizationTypes',
    values:
      organizationFacets?.organizationTypes?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'cities',
    values: uniq(organizationFacets?.cities ?? []).map((name) => {
      return {
        id: name ?? undefined,
        name: name ?? undefined,
      };
    }),
  });

  filters.push({
    filterType: 'attributes',
    values: organizationFacets?.attributes
      ? getAttributesFilters(organizationFacets.attributes)
      : [],
  });

  return filters;
};

export const convertOrganizationFilterValue = (
  preference?: OrganizationPreferenceFacetData
): Array<OrganizationFilterData> => {
  const filters: Array<OrganizationFilterData> = [];

  filters.push({
    filterType: 'organizationTypes',
    values:
      preference?.organizationTypes?.map(({ id, oid, name }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
        };
      }) ?? [],
  });

  filters.push({
    filterType: 'cities',
    values: uniq(preference?.cities ?? []).map((name) => {
      return {
        id: name ?? undefined,
        name: name ?? undefined,
      };
    }),
  });

  filters.push({
    filterType: 'attributes',
    values:
      preference?.attributes?.map(({ id, oid, name, type }) => {
        return {
          id: id ?? undefined,
          name: name ?? undefined,
          oid: oid ?? undefined,
          avatar: type?.toString() ?? undefined,
        };
      }) ?? [],
  });

  return filters;
};

const buildOptionsByEnumeration = (
  oid: string,
  enumValue: NonNullable<unknown>,
  translation: string
): Array<OptionFilterData> => {
  return Object.keys(enumValue).map((option) => {
    return {
      id: option,
      oid: `${oid}-${option}`,
      name: `${translation}.${option}`,
    };
  });
};

const buildSubFacets = (
  consentItems?: Array<ConsentItemData>
): Array<SubFacetFilterData> => {
  if (!consentItems) {
    return [];
  }

  return consentItems.map((consentItem: ConsentItemData) => {
    return {
      oid: consentItem.oid,
      name: consentItem.name,
      options: buildOptionsByEnumeration(
        consentItem.oid ?? '',
        ConsentItemStateChangeDataEnum,
        'components.globalSearch.filter.type.consentType.items.state'
      ),
    };
  });
};

const attributesOptionsByAttributeType = (
  options?: Array<AttributeOptionData>
): Array<OptionFilterData> => {
  return (
    options?.map((option) => {
      return {
        id: option?.id ?? undefined,
        name: option?.value ?? undefined,
        oid: option?.oid ?? undefined,
      };
    }) ?? []
  );
};

const buildOptions = (attribute: AttributeData): Array<OptionFilterData> => {
  if (attribute.type === AttributeTypeDataEnum.BOOLEAN) {
    return buildOptionsByEnumeration(
      attribute.oid ?? '',
      Object.fromEntries(
        Object.entries(BooleanEnumData).map(([k, v]) => [
          k.toLowerCase(),
          v.toLowerCase(),
        ])
      ),
      'components.globalSearch.filter.type.attributes.boolean'
    );
  }

  return attributesOptionsByAttributeType(attribute.options);
};

export const getAttributesFilters = (
  attributes: Array<AttributeData>
): Array<FacetFilterData> => {
  return attributes.map((attribute) => {
    return {
      id: attribute?.id,
      oid: attribute?.oid,
      name: attribute?.name,
      type: attribute?.type,
      options: buildOptions(attribute),
    };
  });
};

export const parsePreferenceData = <Type, Result>(
  items: Array<DeepPartial<Type> | undefined>,
  parseFunction: (data: DeepPartial<Type>) => Result
): Array<Result> => {
  const mappedItems = items.map((it) => (it ? parseFunction(it) : null));

  return filterNonNull(mappedItems);
};
