import { ReactionDataEnum } from '@ysura/common';

import { MediaCard, MediaCardProps } from './MediaCard';

export const TouchpointMediaCard = (initialProps: MediaCardProps) => {
  const {
    media,
    isReactionsEditable,
    onChangeReaction,
    onChangePresentationDialogState,
  } = initialProps;
  const handleChangeReaction = (
    mediaId: string | undefined,
    reaction: ReactionDataEnum
  ) => {
    if (isReactionsEditable && mediaId) {
      onChangeReaction?.(mediaId, reaction);
    }
  };

  const handleClickCard = () => {
    onChangePresentationDialogState?.(true);
  };

  return (
    <MediaCard
      {...initialProps}
      selectedReaction={media.reaction}
      onChangeReaction={handleChangeReaction}
      onClickCard={handleClickCard}
    />
  );
};
