import { KeyMessageData, TopicData } from '@ysura/common';

export const hasKeyMessagesSomeTopics = (
  selectedTopics: Array<TopicData>,
  keyMessage: KeyMessageData
): boolean => {
  if (!selectedTopics.length) {
    return false;
  }
  const selectedTopicNames = selectedTopics?.map(({ name }) => name);

  return (
    keyMessage.topics?.some(
      (topic) => selectedTopicNames.indexOf(topic.name ?? '') >= 0
    ) ?? false
  );
};
