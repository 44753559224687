import { Typography } from '@mui/material';
import { StateDataEnum } from '@ysura/common';
import { useParams } from 'react-router-dom';

import { useCurrentUser } from '@/hooks';
import { isActivityEditable } from '@/services/activities';
import { useGetActivityByIdQuery } from '@/services/graphql/hooks';
import { parseActivityData } from '@/services/graphql/parsers';
import {
  ActiveActivity,
  ActivitySkeleton,
  InactiveActivity,
} from '@/views/Activity';

export const ActivityPage = () => {
  const { activityId = '' } = useParams();
  const { currentUser } = useCurrentUser();
  const { data, loading, error } = useGetActivityByIdQuery({
    variables: {
      id: activityId,
    },
    skip: !activityId,
  });

  if (loading) {
    return <ActivitySkeleton />;
  }

  if (error && !data) {
    return <Typography>Error</Typography>;
  }

  const activity = data?.activity ? parseActivityData(data.activity) : null;
  const canEditActivity = isActivityEditable(activity, currentUser);

  return (
    <>
      {activity && activity.state === StateDataEnum.ACTIVE && (
        <ActiveActivity activity={activity} canEditActivity={canEditActivity} />
      )}

      {activity && activity.state !== StateDataEnum.ACTIVE && (
        <InactiveActivity activity={activity} />
      )}
    </>
  );
};
