import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { VideoControlsMobileKebabPopover } from '@/components/Interaction';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useCurrentUser, useInteraction } from '@/hooks';
import { ScreenShareButton } from '@/views/Interaction/Common';
import {
  ToggleCameraButton,
  ToggleMicButton,
} from '@/views/Interaction/Remote/Buttons';

export type VideoControlsProps = {
  isOrganizer: boolean;
  openSettingsDialog: VoidFunction;
  openCallInfoDialog: VoidFunction;
};

export const VideoControls = ({
  isOrganizer,
  openSettingsDialog,
  openCallInfoDialog,
}: VideoControlsProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { isSharingInFullScreen } = useInteraction();
  const { currentUser } = useCurrentUser();

  const buttonSize = isSmallScreen ? 'medium' : 'large';

  return (
    <>
      <Container
        data-testid="audio-video-controls"
        isFullScreen={isSharingInFullScreen}
      >
        <ToggleMicButton size={buttonSize} />
        <ToggleCameraButton size={buttonSize} />

        {currentUser?.permissions?.activity
          ?.canUseRemoteInteractionScreenShare && (
          <ScreenShareButton testId="screen-sharing-controls" variant="round" />
        )}

        {isOrganizer ? (
          <VideoControlsMobileKebabPopover
            menuEntries={[
              {
                entry: t('pages.interaction.remote.settings'),
                icon: <SettingsIcon />,
                onClick: openSettingsDialog,
              },
              {
                entry: t('pages.interaction.remote.roomInformation'),
                icon: <InfoIcon />,
                onClick: openCallInfoDialog,
              },
            ]}
          />
        ) : (
          <RoundButton
            size="large"
            variant="secondary"
            onClick={openSettingsDialog}
          >
            <SettingsIcon />
          </RoundButton>
        )}
      </Container>
    </>
  );
};

type ContainerStyleProps = {
  isFullScreen: boolean;
};

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})<ContainerStyleProps>(({ theme, isFullScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: isFullScreen ? 'column' : 'row',

  gap: theme.spacing(3),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));
