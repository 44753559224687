import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PromotionalMaterialGroupBatchData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useFormatDate } from '@/hooks';

type BatchInfoProps = {
  batch: PromotionalMaterialGroupBatchData;
};

export const BatchInfo = ({ batch }: BatchInfoProps) => {
  const { t } = useTranslation();
  const { formatLocalizedDate } = useFormatDate();

  const expiryDate = batch?.expiryDate
    ? formatLocalizedDate(new Date(batch.expiryDate))
    : '';

  return (
    <Wrapper>
      <FlexTypography variant="caption">
        <MobileHidden>{t('pages.interaction.common.expiryDate')}:</MobileHidden>
        <MobileVisible>
          {t('pages.interaction.common.expiryDateShort')}:
        </MobileVisible>
        &nbsp;{expiryDate}
      </FlexTypography>

      <FlexDisabledTypography variant="caption">
        <MobileHidden>
          {t('pages.interaction.common.batchNumber')}:&nbsp;
        </MobileHidden>
        {batch?.name}
      </FlexDisabledTypography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

const FlexTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

const FlexDisabledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}));

const MobileVisible = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const MobileHidden = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
