import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

type UpperCaseButtonProps = {
  title: string;
  variant: 'text' | 'outlined' | 'contained';
  onClick: VoidFunction;
};

export const UpperCaseButton = ({
  title,
  variant,
  onClick,
}: UpperCaseButtonProps) => {
  return (
    <StyledButton variant={variant} onClick={onClick}>
      {title}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
`;
