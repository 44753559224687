import { AddressData } from '@ysura/common';

import { convertedAddressType } from '@/services/graphql/parsers/utils';
import {
  Address,
  AddressConnection,
  EntityAddress,
  EntityAddressConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseAddressData = (
  address?: DeepPartial<Address>
): AddressData => {
  const addressLines = filterNonNull([
    address?.addressLine1,
    address?.addressLine2,
    address?.addressLine3,
  ]);

  return {
    id: address?.id,
    oid: address?.oid,
    addressLines:
      addressLines && addressLines.length ? addressLines : undefined,
    postalCode: address?.postalCode,
    city: address?.city,
  };
};

export const parseEntityAddressData = (
  entityAddress?: DeepPartial<EntityAddress>
): AddressData => {
  const address = parseAddressData(entityAddress?.address);
  const addressType = convertedAddressType(entityAddress?.addressType);

  return {
    ...address,
    addressType,
  };
};

export const parseAddressConnection = (
  addressConnection?: DeepPartial<AddressConnection>
): Array<AddressData> => {
  const rawAddresses = addressConnection?.edges ?? [];
  const mappedAddresses = rawAddresses
    .map((edge) => edge?.node ?? null)
    .map((address) => (address ? parseAddressData(address) : null));

  return filterNonNull(mappedAddresses);
};

export const parseEntityAddressConnection = (
  addressConnection?: DeepPartial<EntityAddressConnection>
): Array<AddressData> => {
  const rawAddresses = addressConnection?.edges ?? [];
  const mappedAddresses = rawAddresses
    .map((edge) => edge?.node ?? null)
    .map((address) => (address ? parseEntityAddressData(address) : null));

  return filterNonNull(mappedAddresses);
};
