import { ActivityData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { DocumentPreviewDialog } from '@/components/DocumentPreview';
import { useInteraction } from '@/hooks';

type PromotionalMaterialSignedFormDialogProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  activity: ActivityData | undefined;
};

export const PromotionalMaterialSignedFormDialog = ({
  isOpen,
  onClose,
  activity,
}: PromotionalMaterialSignedFormDialogProps) => {
  const { t } = useTranslation();

  const { signedSampleRequestForm } = useInteraction();

  let document = null;
  if (signedSampleRequestForm?.document) {
    // used for interactions
    document = signedSampleRequestForm.document;
  }

  if (activity?.sampleRequestFormDocument) {
    // used for DONE touchpoints
    document = activity.sampleRequestFormDocument;
  }

  return document ? (
    <DocumentPreviewDialog
      document={document}
      title={document.name ?? t('pages.interaction.common.requestForm')}
      isOpen={isOpen}
      onClose={onClose}
    />
  ) : null;
};
