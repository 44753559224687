import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, PersonConsentData } from '@ysura/common';

import { CommentAccordionBlock } from '@/components/Comment';
import { useCurrentUser } from '@/hooks';
import {
  ConsentWrapper,
  MediaSkeletonStaticBlock,
  MediaStaticBlock,
  OrganizationAccordionBlock,
  PersonDataAccordionBlock,
  PromotionalMaterialSkeletonStaticBlock,
  PromotionalMaterialStaticBlock,
} from '@/views/Interaction/Overview';

type InteractionRemoteContentProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  isLoading?: boolean;
  isActivityEditable?: boolean;
};

export const InteractionRemoteContent = ({
  activity,
  isOrganizer,
  consentList,
  isLoading = false,
  isActivityEditable,
}: InteractionRemoteContentProps) => {
  const { currentUser } = useCurrentUser();
  const firstAttendee = activity?.attendees?.[0]?.person;

  return (
    <Card data-testid="remote-content-card">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <PersonDataAccordionBlock
                activity={activity}
                isLoading={isLoading}
              />
            </Box>

            {isOrganizer && (
              <>
                <GridItem>
                  {isLoading ? (
                    <MediaSkeletonStaticBlock isInRemoteRoom />
                  ) : (
                    <MediaStaticBlock
                      isInRemoteInteraction
                      isSharingAvailable
                      isDisplayViewedLabel
                      media={activity?.media}
                    />
                  )}
                </GridItem>

                {firstAttendee && (
                  <ConsentWrapper
                    isInRemoteRoom
                    isLoading={isLoading}
                    consentList={consentList}
                    person={firstAttendee}
                  />
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <OrganizationAccordionBlock
                activity={activity}
                isLoading={isLoading}
              />
            </Box>

            {isOrganizer && (
              <>
                {currentUser?.permissions?.activity?.canReadActivityComment && (
                  <GridItem>
                    <CommentAccordionBlock
                      commentTypes={activity.activityType?.commentTypes}
                      status={activity.status}
                      defaultExpanded={!isLoading}
                      isLoading={isLoading}
                      isActivityEditable={isActivityEditable}
                    />
                  </GridItem>
                )}

                {currentUser?.permissions?.person?.canReadSampleDrop && (
                  <GridItem>
                    {isLoading ? (
                      <PromotionalMaterialSkeletonStaticBlock isInRemoteRoom />
                    ) : (
                      <PromotionalMaterialStaticBlock
                        isInRemoteRoom
                        activity={activity}
                      />
                    )}
                  </GridItem>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: 'auto',
  flexGrow: 1,

  ':last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

const GridItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
