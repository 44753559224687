import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetRecentAccountsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetRecentAccountsQuery = {
  __typename?: 'Query';
  recentAccountHistory?: {
    __typename?: 'RecentAccountHistory';
    persons: {
      __typename?: 'PersonConnection';
      edges?: Array<{
        __typename?: 'PersonEdge';
        node?: {
          __typename?: 'Person';
          id: string;
          oid: string;
          firstName?: string | null;
          lastName?: string | null;
          prefixAcademicTitle?: {
            __typename?: 'AcademicTitle';
            name?: string | null;
          } | null;
          employments: {
            __typename?: 'EmploymentConnection';
            filteredCount: number;
            edges?: Array<{
              __typename?: 'EmploymentEdge';
              node?: {
                __typename?: 'Employment';
                id: string;
                oid: string;
                sortCode?: number | null;
                addresses: {
                  __typename?: 'EntityAddressConnection';
                  edges?: Array<{
                    __typename?: 'EntityAddressEdge';
                    node?: {
                      __typename?: 'EntityAddress';
                      addressType: Types.AddressType;
                      address: {
                        __typename?: 'Address';
                        id: string;
                        oid: string;
                        addressLine1?: string | null;
                        addressLine2?: string | null;
                        addressLine3?: string | null;
                        city: string;
                        postalCode: string;
                        state: Types.State;
                      };
                    } | null;
                  }> | null;
                };
                organization: {
                  __typename?: 'Organization';
                  id: string;
                  oid: string;
                  name?: string | null;
                  addresses: {
                    __typename?: 'EntityAddressConnection';
                    edges?: Array<{
                      __typename?: 'EntityAddressEdge';
                      node?: {
                        __typename?: 'EntityAddress';
                        addressType: Types.AddressType;
                        address: {
                          __typename?: 'Address';
                          id: string;
                          oid: string;
                          addressLine1?: string | null;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          city: string;
                          postalCode: string;
                          state: Types.State;
                        };
                      } | null;
                    }> | null;
                  };
                  rootOrganization?: {
                    __typename?: 'Organization';
                    id: string;
                    oid: string;
                    name?: string | null;
                  } | null;
                };
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
    organizations: {
      __typename?: 'OrganizationConnection';
      edges?: Array<{
        __typename?: 'OrganizationEdge';
        node?: {
          __typename?: 'Organization';
          id: string;
          oid: string;
          name?: string | null;
          organizationTypes: {
            __typename?: 'OrganizationTypeConnection';
            edges?: Array<{
              __typename?: 'OrganizationTypeEdge';
              node?: {
                __typename?: 'OrganizationType';
                id: string;
                oid: string;
                name?: string | null;
                baseType?: Types.OrganizationBaseType | null;
              } | null;
            }> | null;
          };
          rootOrganization?: {
            __typename?: 'Organization';
            id: string;
            oid: string;
            name?: string | null;
          } | null;
          addresses: {
            __typename?: 'EntityAddressConnection';
            edges?: Array<{
              __typename?: 'EntityAddressEdge';
              node?: {
                __typename?: 'EntityAddress';
                addressType: Types.AddressType;
                address: {
                  __typename?: 'Address';
                  id: string;
                  oid: string;
                  name?: string | null;
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  postalCode: string;
                  city: string;
                  country: {
                    __typename?: 'Country';
                    id: string;
                    oid: string;
                    name?: string | null;
                  };
                };
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetRecentAccountsQuery__
 *
 * To run a query within a React component, call `useGetRecentAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecentAccountsQuery,
    GetRecentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetRecentAccountsQuery,
    GetRecentAccountsQueryVariables
  >(Operations.getRecentAccounts, options);
}
export function useGetRecentAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentAccountsQuery,
    GetRecentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetRecentAccountsQuery,
    GetRecentAccountsQueryVariables
  >(Operations.getRecentAccounts, options);
}
export function useGetRecentAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRecentAccountsQuery,
    GetRecentAccountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetRecentAccountsQuery,
    GetRecentAccountsQueryVariables
  >(Operations.getRecentAccounts, options);
}
export type GetRecentAccountsQueryHookResult = ReturnType<
  typeof useGetRecentAccountsQuery
>;
export type GetRecentAccountsLazyQueryHookResult = ReturnType<
  typeof useGetRecentAccountsLazyQuery
>;
export type GetRecentAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentAccountsSuspenseQuery
>;
export type GetRecentAccountsQueryResult = Apollo.QueryResult<
  GetRecentAccountsQuery,
  GetRecentAccountsQueryVariables
>;
