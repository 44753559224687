import { Typography } from '@mui/material';
import { TopicData } from '@ysura/common';
import { intersectionBy, unionBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { BulletList } from '@/components/BulletList';
import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import {
  prepareDiscussedTopicsForMutation,
  prepareMediaForMutation,
  prepareRequestedGivenPromotionalMaterials,
  prepareRequestedPromotionalMaterials,
  useUpdateActivityMutation,
} from '@/services/graphql/hooks';

import { MediaDialogContent } from './Media';
import {
  getIntersectionTopicsForPromotionalMaterialsAndActivityType,
  PromotionalMaterialsDialogContent,
} from './PromotionalMaterials';
import { TopicsDialogContent } from './Topics';
import { EditActivityDialogProps, PossibleActivityChanges } from './types';
import { useUpdateActivityState } from './UpdateActivityState';

export const EditActivityDialogContent = ({
  type,
  isOpen,
  onClose,
  onCancel,
  onSave,
  activity,
}: EditActivityDialogProps) => {
  const {
    currentActivity,
    resetState,
    selectedTopics,
    selectedMedia,
    selectedPromotionalMaterials,
    initializeActivity,
    itemsToRemove,
    isTopicsLoading,
    availableTopics,
  } = useUpdateActivityState();
  const { t } = useTranslation();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [updateActivityMutation, { loading: isUpdateActivityLoading }] =
    useUpdateActivityMutation();

  useEffect(() => {
    if (isOpen) {
      initializeActivity(activity, type);
    }
  }, [activity, initializeActivity, isOpen, type]);

  const doUpdateActivity = (changes: PossibleActivityChanges) => {
    if (onSave) {
      if (selectedTopics) {
        activity.topics = [...selectedTopics];
      }
      onSave(activity);

      return;
    }
    if (currentActivity) {
      updateActivityMutation({
        ...errorConfigBannerDialog,
        variables: {
          oid: activity.oid ?? '',
          actions: [changes],
        },
        onCompleted: onUpdateCompleted,
      });
    }
  };

  const handleSubmit = () => {
    const changes: PossibleActivityChanges = {};

    if (type === 'topics') {
      // If we have items to remove, we need to show the confirmation dialog first
      if (itemsToRemove.length) {
        setIsConfirmationDialogOpen(true);

        return;
      }

      // If we don't have items to remove, we need to check if there are keyMessages
      // that do not have corresponding topics anymore and need to be removed
      if (currentActivity?.media?.length) {
        let isKeyMessageRemoved = false;
        const updatedMedia = currentActivity.media.map((media) => {
          const currentKeyMessages = media?.keyMessages ?? [];

          media.keyMessages = media?.keyMessages?.filter(
            (item) =>
              intersectionBy(selectedTopics, item.topics ?? [], 'oid')
                .length !== 0
          );

          if (currentKeyMessages.length !== media?.keyMessages?.length) {
            isKeyMessageRemoved = true;
          }

          return media;
        });

        // We only update the media if keyMessages were removed
        if (isKeyMessageRemoved && updatedMedia && updatedMedia.length > 0) {
          changes.media = {
            value: prepareMediaForMutation(updatedMedia),
          };
        }
      }

      if (selectedTopics) {
        changes.discussedTopics = {
          value: prepareDiscussedTopicsForMutation(selectedTopics),
        };
      }
    }

    if (type === 'media') {
      // Combine topics from current activity with topics of media
      let updatedTopics: Array<TopicData> = [];
      if (activity?.topics?.length) {
        updatedTopics = [...activity.topics];

        selectedMedia.forEach((media) => {
          if (media.topics?.length && updatedTopics.length) {
            // Filter topics that don't existed in updatedTopics
            const nonExistedTopics = media.topics.filter(
              (mediaTopic) =>
                !updatedTopics.map((item) => item.oid).includes(mediaTopic.oid)
            );

            updatedTopics = [...updatedTopics, ...nonExistedTopics];
          }
        });
      }

      if (selectedMedia) {
        changes.media = {
          value: prepareMediaForMutation(selectedMedia),
        };
      }

      if (updatedTopics && updatedTopics.length > 0) {
        changes.discussedTopics = {
          value: prepareDiscussedTopicsForMutation(updatedTopics),
        };
      }
    }

    if (type === 'promotionalMaterials') {
      // Combine topics from current activity with topics of promotional materials
      const updatedTopics = unionBy(
        currentActivity?.topics,
        getIntersectionTopicsForPromotionalMaterialsAndActivityType(
          availableTopics,
          selectedPromotionalMaterials
        ),
        'oid'
      );

      if (selectedPromotionalMaterials) {
        changes.requestedPromotionalMaterials = {
          value: prepareRequestedPromotionalMaterials(
            selectedPromotionalMaterials
          ),
        };
      }

      if (updatedTopics && updatedTopics.length > 0) {
        changes.discussedTopics = {
          value: prepareDiscussedTopicsForMutation(updatedTopics),
        };
      }
    }

    doUpdateActivity(changes);
  };

  const handleCancel = () => {
    resetState();
    onCancel?.();
    onClose();
  };

  const onUpdateCompleted = () => {
    resetState();
    onClose();
  };

  const handleConfirmationCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmationDelete = () => {
    // From the current media, we first remove those marked for deletion
    const filteredMedia = currentActivity?.media?.filter((media) => {
      return !itemsToRemove.find(
        (item) => item.type === 'media' && item.oid === media.oid
      );
    });

    // Then, we clean up the key messages which do no match the selected topics anymore
    const cleanedMedia = filteredMedia?.map((media) => {
      media.keyMessages = media?.keyMessages?.filter(
        (item) =>
          intersectionBy(selectedTopics, item.topics ?? [], 'oid').length !== 0
      );

      return media;
    });

    // From the current promotional materials, we remove those marked for deletion
    const filteredPromotionalMaterials =
      currentActivity?.promotionalMaterials?.filter((proMat) => {
        return !itemsToRemove.find(
          (item) =>
            item.type === 'promotionalMaterial' &&
            item.oid === proMat?.promotionalMaterial?.oid
        );
      });

    doUpdateActivity({
      media: cleanedMedia
        ? {
            value: prepareMediaForMutation(cleanedMedia),
          }
        : undefined,
      discussedTopics: {
        value: prepareDiscussedTopicsForMutation(selectedTopics),
      },
      requestedPromotionalMaterials: filteredPromotionalMaterials
        ? {
            value: prepareRequestedGivenPromotionalMaterials(
              filteredPromotionalMaterials
            ),
          }
        : undefined,
    });

    setIsConfirmationDialogOpen(false);
  };

  const getWarningContent = () => {
    return (
      <>
        <Typography variant="body2">
          {t('components.touchpointManagement.warning.topicsRemoveItems')}
        </Typography>
        <BulletList
          items={itemsToRemove
            .filter((item) => item.type === 'promotionalMaterial')
            .map((item) => item.name ?? '')}
        />
        <BulletList
          items={itemsToRemove
            .filter((item) => item.type === 'media')
            .map((item) => item.name ?? '')}
        />
      </>
    );
  };

  return (
    <ActionDialog
      isOpen={isOpen}
      title={t(`components.touchpointManagement.${type}`)}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      testId={`edit-touchpoint-${type}-dialog`}
      maxWidth={type === 'topics' ? 'xs' : 'md'}
      isLoading={isUpdateActivityLoading || isTopicsLoading}
      isPrimaryButtonDisabled={
        isTopicsLoading || (type === 'topics' && selectedTopics.length === 0)
      }
      onClickPrimaryButton={handleSubmit}
      onClickSecondaryButton={handleCancel}
    >
      {type === 'media' && <MediaDialogContent />}
      {type === 'promotionalMaterials' && <PromotionalMaterialsDialogContent />}
      {type === 'topics' && <TopicsDialogContent />}

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        title={t('components.common.warning')}
        content={getWarningContent()}
        primaryButton={{
          text: t('components.common.delete'),
          onClickHandler: handleConfirmationDelete,
          color: 'error',
        }}
        secondaryButton={{
          text: t('components.common.cancel'),
          onClickHandler: handleConfirmationCancel,
        }}
      />
    </ActionDialog>
  );
};
