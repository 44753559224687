import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

import { AudioVideoSettings } from './AudioVideoSettings';

export const AudioVideoSettingsCard = () => {
  const { t } = useTranslation();
  const { isUserMediaCollected } = useInteraction();

  return (
    <Card>
      <CardHeader
        title={t('pages.interaction.remote.settings')}
        titleTypographyProps={{
          variant: 'subtitle2',
        }}
      />
      <CardContent data-testid="audio-video-settings-content">
        {isUserMediaCollected ? (
          <AudioVideoSettings />
        ) : (
          <FullHeightLoader>
            <Loader />
          </FullHeightLoader>
        )}
      </CardContent>
    </Card>
  );
};

const FullHeightLoader = styled(Box)({
  height: '100%',
  display: 'grid',
});

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
}));

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: 0,
  color: theme.palette.text.secondary,
}));

const CardContent = styled(MuiCardContent)(({ theme }) => ({
  // 38px is the height of the CardHeader
  height: 'calc(100% - 38px)',
  padding: theme.spacing(2),

  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));
