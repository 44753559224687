import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import {
  ActivityData,
  ActivityStatusDataEnum,
  ActivityTypeSchedulingDataEnum,
  TouchpointManagementModeData,
} from '@ysura/common';
import { add, isBefore, isSameDay, sub } from 'date-fns';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { MobileCalendarActionBar } from '@/views/CalendarActivities';

import { SectionLabel } from '../SharedStyledComponents';
import type { BaseFormikProps } from '../types';

export enum DateFields {
  StartDate = 'startDate',
  StartDateTime = 'startDateTime',
  EndDate = 'endDate',
  EndDateTime = 'endDateTime',
}

interface TouchpointDateAndTimeProps extends Partial<BaseFormikProps> {
  mode: TouchpointManagementModeData;
  activity?: ActivityData;
  isSubmitting?: boolean;
  isSelectionDisabled?: boolean;
}

export const TouchpointDateAndTime = ({
  touchpointValues,
  mode,
  setFieldValue,
  isSelectionDisabled,
  isSubmitting = false,
  activity,
}: TouchpointDateAndTimeProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [currentlyOpenPicker, setCurrentlyOpenPicker] = useState('');
  const [selectedActivityType, setSelectedActivityType] = useState(
    touchpointValues?.touchpointType
  );

  //if edit activity and was previously saved with just date, the set to true, all other cases to false
  const [hideTimeSelected, setHideTimeSelected] = useState(
    mode === 'edit' && !touchpointValues?.startDateTime
  );

  const minimumAllowedDuration = 15;
  const defaultDuration =
    selectedActivityType?.defaultDuration ?? minimumAllowedDuration;
  const timeIsOptional = selectedActivityType?.timeIsOptional ?? false;

  const isDisabled =
    !selectedActivityType || isSubmitting || isSelectionDisabled;
  const isTouchpointClosed = activity?.status === ActivityStatusDataEnum.CLOSED;

  const withEndDate =
    selectedActivityType?.schedulingType ===
      ActivityTypeSchedulingDataEnum.START_AND_END_DATE ||
    selectedActivityType?.schedulingType ===
      ActivityTypeSchedulingDataEnum.START_AND_END_DATE_TIME;

  const withTime =
    selectedActivityType?.schedulingType ===
      ActivityTypeSchedulingDataEnum.START_DATE_TIME ||
    selectedActivityType?.schedulingType ===
      ActivityTypeSchedulingDataEnum.START_AND_END_DATE_TIME;

  const getDateTimeValue = (incomingDateTime?: string | Date | null): Date => {
    if (!incomingDateTime) {
      return new Date();
    }

    return new Date(incomingDateTime);
  };

  const getStartDate = useCallback((): Date => {
    return touchpointValues?.startDateTime
      ? getDateTimeValue(touchpointValues?.startDateTime)
      : getDateTimeValue(touchpointValues?.startDate);
  }, [touchpointValues]);

  const getEndDate = (): Date => {
    return touchpointValues?.endDateTime
      ? getDateTimeValue(touchpointValues?.endDateTime)
      : getDateTimeValue(touchpointValues?.endDate);
  };

  const minTime = isSameDay(getStartDate(), getEndDate())
    ? add(getStartDate(), { minutes: minimumAllowedDuration })
    : undefined;

  const maxDate =
    mode === 'edit' && isTouchpointClosed ? new Date() : undefined;

  const disabledManualDateTimeChangeMethods = {
    onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => {
      e.preventDefault();
    },
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
    },
  };

  const setStartAndEndDate = useCallback(
    (startDate: string, endDate: string) => {
      setFieldValue?.(DateFields.StartDate, startDate);
      setFieldValue?.(DateFields.EndDate, endDate);
      setFieldValue?.(DateFields.StartDateTime, null);
      setFieldValue?.(DateFields.EndDateTime, null);
    },
    [setFieldValue]
  );

  const setStartAndEndDateTime = useCallback(
    (startDateTime: string, endDateTime: string) => {
      setFieldValue?.(DateFields.StartDateTime, startDateTime);
      setFieldValue?.(DateFields.EndDateTime, endDateTime);
      setFieldValue?.(DateFields.StartDate, null);
      setFieldValue?.(DateFields.EndDate, null);
    },
    [setFieldValue]
  );

  const handleChangeStartDate = (newDate: Date | null) => {
    if (!newDate) {
      return;
    }

    const defaultEndDate = add(newDate, { minutes: defaultDuration });
    const newEndDate = isBefore(defaultEndDate, getEndDate())
      ? getEndDate()
      : defaultEndDate;
    if (withTime && !hideTimeSelected) {
      setStartAndEndDateTime(newDate.toISOString(), newEndDate.toISOString());
    } else {
      setStartAndEndDate(
        newDate.toISOString().slice(0, 10),
        newEndDate.toISOString().slice(0, 10)
      );
    }
  };

  const handleChangeEndDate = (newDate: Date | null) => {
    if (!newDate) {
      return;
    }

    const defaultStartDate = sub(newDate, { minutes: defaultDuration });
    const newStartDate = isBefore(defaultStartDate, getStartDate())
      ? defaultStartDate
      : getStartDate();
    if (withTime && !hideTimeSelected) {
      setStartAndEndDateTime(newStartDate.toISOString(), newDate.toISOString());
    } else {
      setStartAndEndDate(
        newStartDate.toISOString().slice(0, 10),
        newDate.toISOString().slice(0, 10)
      );
    }
  };

  const handleChangeStartDateTime = (newTime: Date | null) => {
    if (!newTime) {
      return;
    }

    const defaultEndDate = add(newTime, { minutes: defaultDuration });
    const newEndDate = isBefore(defaultEndDate, getEndDate())
      ? getEndDate()
      : defaultEndDate;
    setStartAndEndDateTime(newTime.toISOString(), newEndDate.toISOString());
  };

  const handleChangeEndDateTime = (newTime: Date | null) => {
    if (!newTime) {
      return;
    }

    const defaultStartDate = sub(newTime, { minutes: defaultDuration });
    const newEndDate = isBefore(defaultStartDate, getStartDate())
      ? defaultStartDate
      : getStartDate();
    setStartAndEndDateTime(newEndDate.toISOString(), newTime.toISOString());
  };

  const handleHideTime = () => {
    setHideTimeSelected(!hideTimeSelected);
    if (hideTimeSelected) {
      setStartAndEndDate(
        getStartDate().toISOString().slice(0, 10),
        getEndDate().toISOString().slice(0, 10)
      );
    } else {
      const newStartDate = getStartDate();
      setStartAndEndDateTime(
        newStartDate.toISOString().slice(0, 10),
        add(newStartDate, { minutes: defaultDuration })
          .toISOString()
          .slice(0, 10)
      );
    }
  };

  const handleOpenDateTimePicker = (picker: string) => {
    if (!isDisabled) {
      setCurrentlyOpenPicker(picker);
    }
  };

  const handleCloseDateTimePicker = () => {
    setCurrentlyOpenPicker('');
  };

  useEffect(() => {
    const incomingActivityType = touchpointValues?.touchpointType;

    // If the currently selected type doesn't match the newly selected one we need to update the dates.
    const newTypeSupportsTime =
      incomingActivityType &&
      (incomingActivityType?.schedulingType ===
        ActivityTypeSchedulingDataEnum.START_DATE_TIME ||
        incomingActivityType?.schedulingType ===
          ActivityTypeSchedulingDataEnum.START_AND_END_DATE_TIME);
    if (selectedActivityType !== incomingActivityType) {
      if (newTypeSupportsTime) {
        // if the new type supports time and the old one didn't, we need to set the time to current date
        const currentStartDate = !touchpointValues?.startDateTime
          ? new Date()
          : getStartDate();
        setStartAndEndDateTime(
          currentStartDate.toISOString(),
          add(currentStartDate, { minutes: defaultDuration }).toISOString()
        );
      } else {
        setStartAndEndDate(
          getStartDate().toISOString().slice(0, 10),
          getStartDate().toISOString().slice(0, 10)
        );
      }
      setSelectedActivityType(incomingActivityType);
    }
  }, [
    selectedActivityType,
    defaultDuration,
    getStartDate,
    setStartAndEndDate,
    setStartAndEndDateTime,
    setFieldValue,
    touchpointValues?.startDateTime,
    touchpointValues?.touchpointType,
  ]);

  return (
    <>
      <SectionLabel variant="subtitle2">
        {withTime
          ? t('components.touchpointManagement.dateAndTime')
          : t('components.touchpointManagement.date')}
      </SectionLabel>

      <DateTimeWrapper>
        <DateTimeRow>
          <DatePicker
            open={currentlyOpenPicker === DateFields.StartDate}
            label={t('components.touchpointManagement.startDate')}
            maxDate={maxDate}
            // Renders the input in a way that disabled the user inputing or pasting values
            renderInput={(params) => {
              return (
                <DateField
                  {...params}
                  {...disabledManualDateTimeChangeMethods}
                  onClick={() => handleOpenDateTimePicker(DateFields.StartDate)}
                />
              );
            }}
            components={{
              // Custom action bar to only display a "Done" button
              ActionBar: MobileCalendarActionBar,
            }}
            // removes the option for the user to go into "manual editing mode" (mobile only)
            showToolbar={false}
            disabled={isDisabled}
            value={getStartDate()}
            desktopModeMediaQuery={theme.breakpoints.up('xs')}
            onClose={handleCloseDateTimePicker}
            onChange={handleChangeStartDate}
          />

          {withTime ? (
            <TimePickerWrapper isHidden={!withTime || hideTimeSelected}>
              <TimePicker
                ampmInClock
                open={currentlyOpenPicker === DateFields.StartDateTime}
                label={t('components.touchpointManagement.startTime')}
                renderInput={(params) => (
                  <DateField
                    {...params}
                    {...disabledManualDateTimeChangeMethods}
                    onClick={() =>
                      handleOpenDateTimePicker(DateFields.StartDateTime)
                    }
                  />
                )}
                components={{
                  ActionBar: MobileCalendarActionBar,
                }}
                showToolbar={false}
                disabled={isDisabled}
                value={getStartDate()}
                desktopModeMediaQuery={theme.breakpoints.up('xs')}
                onClose={handleCloseDateTimePicker}
                onChange={handleChangeStartDateTime}
              />
            </TimePickerWrapper>
          ) : (
            <HiddenBox />
          )}
        </DateTimeRow>

        {withEndDate && (
          <DateTimeRow>
            <DatePicker
              open={currentlyOpenPicker === DateFields.EndDate}
              label={t('components.touchpointManagement.endDate')}
              renderInput={(params) => (
                <DateField
                  {...params}
                  {...disabledManualDateTimeChangeMethods}
                  onClick={() => handleOpenDateTimePicker(DateFields.EndDate)}
                />
              )}
              components={{
                ActionBar: MobileCalendarActionBar,
              }}
              showToolbar={false}
              disabled={isDisabled}
              minDate={getStartDate()}
              value={getEndDate()}
              desktopModeMediaQuery={theme.breakpoints.up('xs')}
              onClose={handleCloseDateTimePicker}
              onChange={handleChangeEndDate}
            />

            {withTime && (
              <TimePickerWrapper isHidden={!withTime || hideTimeSelected}>
                <TimePicker
                  ampmInClock
                  open={currentlyOpenPicker === DateFields.EndDateTime}
                  label={t('components.touchpointManagement.endTime')}
                  renderInput={(params) => (
                    <DateField
                      {...params}
                      {...disabledManualDateTimeChangeMethods}
                      onClick={() =>
                        handleOpenDateTimePicker(DateFields.EndDateTime)
                      }
                    />
                  )}
                  components={{
                    ActionBar: MobileCalendarActionBar,
                  }}
                  showToolbar={false}
                  disabled={isDisabled}
                  value={getEndDate()}
                  minTime={minTime}
                  desktopModeMediaQuery={theme.breakpoints.up('xs')}
                  onClose={handleCloseDateTimePicker}
                  onChange={handleChangeEndDateTime}
                />
              </TimePickerWrapper>
            )}
          </DateTimeRow>
        )}

        {withEndDate && withTime && !hideTimeSelected && (
          <HelperText variant="caption">
            {t('components.touchpointManagement.minDuration', {
              minDuration: minimumAllowedDuration,
            })}
          </HelperText>
        )}

        {withTime && timeIsOptional && (
          <CheckboxLabel
            label={t('components.touchpointManagement.saveOnlyDate')}
            checked={hideTimeSelected}
            control={
              <Checkbox
                data-testid="components.touchpointManagement.saveOnlyDate"
                disabled={isDisabled}
                onClick={handleHideTime}
              />
            }
          />
        )}
      </DateTimeWrapper>
    </>
  );
};

const DateTimeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const DateTimeRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingLeft: theme.spacing(2),
}));

const CheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: 22,
}));

const HiddenBox = styled(Box)({
  width: '100%',
});

const DateField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '.MuiInputBase-input': {
    cursor: 'pointer',
    // removes the blinking input caret
    caretColor: 'transparent',
  },

  '.MuiInputAdornment-root': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

type TimePickerWrapperStyleProps = {
  isHidden: boolean;
};

const TimePickerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHidden',
})<TimePickerWrapperStyleProps>(({ isHidden }) => ({
  visibility: isHidden ? 'hidden' : 'visible',
  width: '100%',
}));
