import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, ActivityStatusDataEnum, MediaData } from '@ysura/common';

import { TouchpointMediaCard } from './MediaCard';

type MediaCardListProps = {
  activity: ActivityData;
  onChangePresentationDialogState: (val: boolean) => void;
  onChangeMediaWithDocument: (media: MediaData) => void;
};
export const MediaCardList = ({
  activity,
  onChangeMediaWithDocument,
  onChangePresentationDialogState,
}: MediaCardListProps) => {
  const mediaList = activity?.media;

  return (
    <Wrapper>
      {mediaList?.map((media) => {
        return (
          <TouchpointMediaCard
            key={media?.id}
            media={media}
            variant="short"
            isDisplayReactions={
              activity?.status === ActivityStatusDataEnum.CLOSED
            }
            onChangeMediaWithDocument={onChangeMediaWithDocument}
            onChangePresentationDialogState={onChangePresentationDialogState}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
