import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentTypeData,
  ConsentValue,
  CountryData,
  PersonData,
} from '@ysura/common';

import { ConsentCollectionContent } from '@/components/Consent/ConsentCollectionContent';

type ConsentFormCollectionProps = {
  id?: string;
  consentType?: ConsentTypeData;
  attendee?: PersonData;
  consentValues: ConsentValue;
  defaultCountry?: CountryData;
  setConsentValues: (consentValue: ConsentValue) => void;
  setErrorMessage?: (error?: string) => void;
  signature?: string;
};

export const ConsentFormCollection = ({
  id = 'consentFormCollection',
  consentType,
  attendee,
  consentValues,
  defaultCountry,
  setConsentValues,
  setErrorMessage,
  signature,
}: ConsentFormCollectionProps) => {
  return (
    <CardContent data-testid="consent-value-collection" id={id}>
      <ConsentCollectionContent
        consentType={consentType}
        person={attendee}
        defaultCountry={defaultCountry}
        setConsentValues={setConsentValues}
        isSignatureRequired={true}
        signature={signature}
        disableSignature={true}
        consentValues={consentValues}
        setErrorMessage={setErrorMessage}
      />
    </CardContent>
  );
};

const CardContent = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
}));
