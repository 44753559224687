import { PersonData } from '@ysura/common';
import i18next from 'i18next';
import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  ClientPermissions,
  DataPermissions,
} from '@/services/user/permissions';

export type CipState = {
  locale: string;
  settings: SettingsState;
  user: CurrentUserState | null;
};

export type SettingsState = {
  displayMode: 'light' | 'dark';
};

export type CurrentUserState = {
  ysuraUserId?: string | undefined;
  email?: string | undefined;
  person?: PersonData | undefined;
  username?: string | undefined;
  preferenceId?: string | undefined;
  isAdmin: boolean;
  permissions?: ClientPermissions;
  dataPermissions?: DataPermissions;
};

type UseCipState = {
  cipState: CipState;
  setCipState: Dispatch<SetStateAction<CipState>>;
};

const initialState: CipState = {
  locale: i18next?.languages?.[0] || 'en-US',
  settings: {
    displayMode: 'light',
  },
  user: null,
};

export const CipStateContext = createContext<UseCipState>({
  cipState: initialState,
  setCipState: () => {},
});

export const useCipState = (): UseCipState => {
  return useContext(CipStateContext);
};

type CipStateProviderProps = {
  children: ReactElement;
};

export const CipStateProvider = ({
  children,
}: CipStateProviderProps): JSX.Element => {
  const [cipState, setCipState] = useState<CipState>(initialState);

  const contextValue = useMemo(
    () => ({
      cipState,
      setCipState,
    }),
    [cipState]
  );

  return (
    <CipStateContext.Provider value={contextValue}>
      {children}
    </CipStateContext.Provider>
  );
};
