import { CommentTypeData, DataPermissionTypeDataEnum } from '@ysura/common';
import { includes } from 'lodash';

export const hasAssignCommentTypePermission = (
  commentTypes: Array<CommentTypeData> | undefined
): boolean => {
  return (
    commentTypes?.some((item) =>
      includes(
        item?.permissions,
        DataPermissionTypeDataEnum.ASSIGN_COMMENT_TYPE
      )
    ) ?? false
  );
};
