import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type AttendeeSearchFieldProps = {
  variant: 'initial' | 'expand';
  searchAttendeeQuery: string;
  selectedTouchpointType: string;
  isSubmitting?: boolean;
  onOpenSearch?: VoidFunction;
  onChangeSearchQuery?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClearSearchQuery?: VoidFunction;
};

export const AttendeeSearchField = ({
  variant,
  selectedTouchpointType,
  isSubmitting = false,
  onOpenSearch,
  searchAttendeeQuery,
  onChangeSearchQuery,
  onClearSearchQuery,
}: AttendeeSearchFieldProps) => {
  const { t } = useTranslation();

  const isDisabled = !selectedTouchpointType || isSubmitting;

  if (variant === 'initial') {
    return (
      <Search
        data-testid="search-dialog-search-field-initial"
        disabled={isDisabled}
        autoComplete="off"
        placeholder={t('components.touchpointManagement.searchForAttendee')}
        value={searchAttendeeQuery}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          ),
        }}
        onClick={selectedTouchpointType ? onOpenSearch : undefined}
      />
    );
  }

  return (
    <Search
      data-testid="search-dialog-search-field-expand"
      /**
       * In order for the transition from the searchField inside the header to the
       * search field inside this dialog to look like it's the same exact component,
       * we need to set autoFocus to true.
       */
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={true}
      autoComplete="off"
      placeholder={t('components.touchpointManagement.searchForAttendee')}
      value={searchAttendeeQuery}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        ),
        endAdornment: searchAttendeeQuery && (
          <InputAdornment position="end">
            {/* This button is for desktop */}
            <ClearButton variant="text" onClick={onClearSearchQuery}>
              {t('components.common.clear')}
            </ClearButton>
            {/* This button is for mobile mode */}
            <ClearIconButton onClick={onClearSearchQuery}>
              <IconClose />
            </ClearIconButton>
          </InputAdornment>
        ),
      }}
      onChange={onChangeSearchQuery}
    />
  );
};

const Search = styled(TextField)({
  width: '100%',
});

const IconSearch = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: 20,
  height: 20,
}));

const IconClose = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const ClearButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ClearIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
