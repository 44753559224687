import {
  ActivityData,
  CommentData,
  ConsentEventReplayParams,
  ConsentTypeData,
  ConsentValue,
  ConsentViewerPayload,
  Media,
  MediaData,
  MediaEventReplayParams,
  MediaTrackingInfoDataData,
  Page,
  PromotionalMaterialGroupData,
  ReactionDataEnum,
  Room,
  SampleRequestEventReplayParams,
  SampleRequestFormData,
  ScreenSharingEventReplayParams,
  TopicData,
} from '@ysura/common';
import { Dispatch, SetStateAction } from 'react';

import { ReactionState, SharedItem } from './useInteractionState';

export const DEFAULT_FN = () => {};
export const DEFAULT_CALLBACK = () => () => {};

export type SetState<T> = Dispatch<SetStateAction<T>>;

export type MediaBeingShared = {
  media: MediaData;
  initialPage?: Page;
};

export type MediaSharedForCoOrganizer = {
  media: Media;
  initialPage?: Page;
};

export type ConsentBeingShared = {
  oid?: string;
  consentType: ConsentTypeData;
  currentConsentValues?: ConsentValue;
  currentSignature?: string;
  currentPreview?: string;
  currentStep?: 'collect' | 'preview';
  initialState?: ConsentViewerPayload;
};

export type OrganizerParticipantRole = 'organizer' | 'co-organizer';

type InitialInteractionStep = 'waiting-room' | 'interaction';

export type InitChannelArgs = {
  roomId?: string | undefined;
  role: OrganizerParticipantRole;
  interactionStep: InitialInteractionStep;
  onTokenCreated?: (token: string, room: Room) => void;
  onMediaEventReplay?: (params: MediaEventReplayParams) => void;
  onConsentEventReplay?: (params: ConsentEventReplayParams) => void;
  onScreenSharingEventReplay?: (params: ScreenSharingEventReplayParams) => void;
  onSampleRequestEventReplay?: (params: SampleRequestEventReplayParams) => void;
  hasVideoConnection: boolean;
};

export type InitializeStateServerArgs = {
  roomId: string;
  role: OrganizerParticipantRole;
  interactionStep: InitialInteractionStep;
};

export type ParticipantDeviceState = {
  isMicActive: boolean;
  isCameraActive: boolean;
};

export type VideoStatus = {
  isPlaying: boolean;
  shouldBePlaying: boolean;
};

export type InteractionMode = 'f2f' | 'remote' | 'phone';

export type MediaTrackingData = {
  id: string;
};
export type ViewedMedia = Record<
  string,
  { media: MediaData; mediaTracking: Array<MediaTrackingData> }
>;

export type PersitantInteractionState = {
  originalActivity: ActivityData | null;
  interactionStep: 'waiting-room' | 'interaction' | 'summary';
  currentRole: OrganizerParticipantRole | null;
  interactionMode: InteractionMode | null;
  startDateTime: string;
  viewedMedia: ViewedMedia;
  signedSampleRequestForm: SampleRequestFormData | null;
  comments: Array<CommentData>;
  collectedConsent: Array<SharedItem>;
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  topics: Array<TopicData>;
  reactions: ReactionState;
  startTimeForMediaTracking: string | null;
  currentPageSharingMedia: number | null;
  mediaTrackingInfo: Array<MediaTrackingInfoDataData> | null;
  mediaSharingSequence: number | null;
};

export type PersitantInteractionStateSetter = {
  setInteractionStep: (
    interactionStep: PersitantInteractionState['interactionStep']
  ) => void;
  setCurrentRole: (currentRole: OrganizerParticipantRole) => void;
  setMediaAsViewed: (media: MediaData) => void;
  setMediaTrackingInViewedMedia: (
    media: MediaData,
    mediaTracking: MediaTrackingData
  ) => void;
  startMediaTracking: (initialPage: number | undefined) => void;
  setMediaUpdatingKeyMessages: (medias: Array<MediaData>) => void;
  setConsentAsCollected?: FunctionStringCallback;
  setSignedSampleRequestForm: (
    requestForm: SampleRequestFormData | null
  ) => void;
  setComments: (comments: Array<CommentData>) => void;
  setReactionForMedia: (mediaId: string, reaction: ReactionDataEnum) => void;
  setReactionForKeyMessage: (
    mediaId: string,
    keyMessageId: string,
    reaction: ReactionDataEnum
  ) => void;
  setPromotionalMaterialGroups: (
    promotionalMaterialGroups: Array<PromotionalMaterialGroupData>
  ) => void;
  setTopics: (topics: Array<TopicData>) => void;
};

export type UseVideoState = {
  audioDevices: Array<MediaDeviceInfo>;
  videoDevices: Array<MediaDeviceInfo>;

  selectedVideoInput: string;
  selectedAudioInput: string;

  isUserMediaCollected: boolean;
  isMicAccessDisabled: boolean;
  isCameraAccessDisabled: boolean;
  isMicActive: boolean;
  isCameraActive: boolean;

  isOwnScreenShared: boolean;
  isParticipantsScreenShared: boolean;

  participantDeviceState: Record<string, ParticipantDeviceState>;
  participantVideoStatus: Record<string, VideoStatus>;

  setAudioDevices: SetState<Array<MediaDeviceInfo>>;
  setVideoDevices: SetState<Array<MediaDeviceInfo>>;
  setSelectedVideoInput: FunctionStringCallback;
  setSelectedAudioInput: FunctionStringCallback;
  setIsUserMediaCollected: SetState<boolean>;
  setIsMicAccessDisabled: SetState<boolean>;
  setIsCameraAccessDisabled: SetState<boolean>;
  toggleIsMicActive: VoidFunction;
  toggleIsCameraActive: VoidFunction;

  initializeVideo: VoidFunction;
  handleVideoTokenGenerated: (token: string, room: Room) => void;
  leaveVideoCall: VoidFunction;

  startScreenSharing: (
    shouldBroadcast?: boolean,
    participantSharingId?: string
  ) => void;
  handleNotifyScreenShareStarted: VoidFunction;
  stopScreenSharing: VoidFunction;
};
