import { Loader } from '@ysura/common';

import { HTMLFile, PDFFile } from '@/components/DocumentPreview';
import { useInteraction } from '@/hooks';
import { DocumentType, DownloadedDocument } from '@/services/api';

type DocumentPreviewContentProps = {
  documentName: string;
  document: DownloadedDocument;
  isDownloadButtonShown?: boolean;
  isInInteraction?: boolean;
  onClose: VoidFunction;
};

export const DocumentPreviewContent = ({
  documentName,
  document,
  isDownloadButtonShown = true,
  isInInteraction = false,
  onClose,
}: DocumentPreviewContentProps) => {
  const { currentPageSharingMedia } = useInteraction();
  if (document.mediaType === DocumentType.PDF) {
    return (
      <PDFFile
        data={document.data}
        fileName={documentName}
        isDownloadButtonShown={isDownloadButtonShown}
        isInInteraction={isInInteraction}
        initialPage={currentPageSharingMedia ?? 1}
        onClose={onClose}
      />
    );
  } else if (document.mediaType === DocumentType.HTML) {
    return (
      <HTMLFile
        data={document.data}
        isInInteraction={isInInteraction}
        initialPage={{ indexh: currentPageSharingMedia ?? 0, indexv: 0 }}
        onClose={onClose}
      />
    );
  } else {
    return <Loader />;
  }
};
