import {
  ActivityData,
  ActivityStatusDataEnum,
  getPersonFullNameWithTitle,
  useLocalStorage,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InteractionDetailPageLayout } from '@/components/InteractionDetailPageLayout';
import { PageHeader } from '@/components/PageHeader';
import { useCurrentUser, useInteraction } from '@/hooks';
import { InvalidActivity } from '@/pages/Error';
import { redirectBackToCrm } from '@/services/api';
import { useGetPersonConsentListQuery } from '@/services/graphql/hooks';
import { parsePersonConsents } from '@/services/graphql/parsers';
import {
  ConsentSkeletonStaticBlock,
  ConsentStaticBlock,
  EndF2FInteractionDialog,
  InteractionSummary,
  MediaStaticBlock,
  PromotionalMaterialStaticBlock,
} from '@/views/Interaction';

type F2FInteractionProps = {
  activity: ActivityData;
  canEditActivity?: boolean;
};

export const F2FInteraction = ({
  activity,
  canEditActivity,
}: F2FInteractionProps) => {
  const { currentUser } = useCurrentUser();
  const [isEndInteractionOpen, setIsEndInteractionOpen] = useState(false);
  const { t } = useTranslation();
  const { interactionStep } = useInteraction();
  const [isFromCrm] = useLocalStorage<boolean>('fromCrm', false);

  const firstAttendee = activity?.attendees?.[0]?.person;

  // Get Person Consent by PersonId
  const {
    data: consentData,
    loading: isConsentLoading,
    error: consentError,
  } = useGetPersonConsentListQuery({
    variables: {
      id: firstAttendee?.id ?? '',
    },
    skip: activity.attendees?.length !== 1,
  });

  const consentList = parsePersonConsents(consentData?.person ?? undefined);

  const mediaList = activity?.media;

  if (interactionStep === 'summary') {
    return (
      <InteractionSummary
        canEditActivity={canEditActivity}
        activity={activity}
        consentList={consentList}
      />
    );
  }

  // We make the decision about whether the activity is planned or closed here
  // because we need to allow closed activities in the summary page.
  if (activity?.status !== ActivityStatusDataEnum.PLANNED) {
    const redirectTarget = isFromCrm ? undefined : `/touchpoint/${activity.id}`;
    const redirectAction = isFromCrm ? redirectBackToCrm : undefined;

    return (
      <InvalidActivity
        reason={'activityNotPlanned'}
        redirectAction={redirectAction}
        redirectTarget={redirectTarget}
      />
    );
  }

  /**
   * If there's no consent data, we should not render the entire column at all.
   * Media column will always be rendered, as we'll add a default media if no media was planned.
   * Promat column will always be rendered, as the org has an option to add promats during the interaction.
   */
  return (
    <>
      <InteractionDetailPageLayout
        header={
          <PageHeader
            title={t('pages.interaction.common.title', {
              name: getPersonFullNameWithTitle(firstAttendee),
            })}
            buttonText={t('pages.interaction.common.endInteraction')}
            buttonHandler={() => setIsEndInteractionOpen((prev) => !prev)}
            titleVariant="dark"
          />
        }
        firstColumn={
          <MediaStaticBlock
            isInteractionDone={false}
            media={mediaList}
            isDisplayViewedLabel={true}
          />
        }
        middleColumn={
          isConsentLoading ? (
            <ConsentSkeletonStaticBlock />
          ) : (
            !consentError &&
            consentList.length > 0 &&
            firstAttendee && (
              <ConsentStaticBlock
                consentList={consentList}
                person={firstAttendee}
                isInRemoteRoom={false}
              />
            )
          )
        }
        lastColumn={
          currentUser?.permissions?.person?.canReadSampleDrop && (
            <PromotionalMaterialStaticBlock activity={activity} />
          )
        }
      />

      <EndF2FInteractionDialog
        isOpen={isEndInteractionOpen}
        activity={activity}
        closeDialog={() => setIsEndInteractionOpen(false)}
      />
    </>
  );
};
