import { MediaData } from '@ysura/common';

import { DocumentPreviewDialog } from '@/components/DocumentPreview';
import { useInteraction } from '@/hooks';

type MediaPresentationDialogProps = {
  isOpen: boolean;
  media: MediaData | null;
  isInInteraction?: boolean;
  onClose: () => void;
};

export const MediaPresentationDialog = ({
  isOpen,
  media,
  isInInteraction = false,
  onClose,
}: MediaPresentationDialogProps) => {
  const { stopSharingMedia } = useInteraction();

  const handleClose = () => {
    if (isInInteraction) {
      stopSharingMedia?.();
    }

    onClose();
  };

  return (
    <>
      {media?.latestVersion?.document && (
        <DocumentPreviewDialog
          document={media.latestVersion.document}
          title={media.fileName}
          maxWidth={'xl'}
          isOpen={isOpen}
          isDownloadButtonShown={false}
          isInInteraction={isInInteraction}
          onClose={handleClose}
        />
      )}
    </>
  );
};
