import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  styled,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { HEADER } from '@/config';
import { GRAPHIC_DIALOG } from '@/config/layout';
import { useNotification } from '@/hooks';

import { ConfirmationDialog } from '../ConfirmationDialog';

export const NotificationPopup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { popupOptions } = useNotification();

  if (isEmpty(popupOptions)) {
    return null;
  }

  const {
    type,
    graphic,
    title,
    content,
    contentComponent,
    primaryButton,
    secondaryButton,
  } = popupOptions;

  const graphicPopupHandler = () => {
    setIsLoading(true);
    primaryButton?.action().finally(() => {
      setIsLoading(false);
    });
  };

  if (type === 'pageOverlay') {
    return (
      <PageOverlayWrapper>
        <PageOverlayComponentWrapper>
          {contentComponent}
        </PageOverlayComponentWrapper>
      </PageOverlayWrapper>
    );
  }

  if (type === 'graphic') {
    return (
      <GraphicDialog open fullWidth maxWidth="xs">
        {graphic && <Graphic src={graphic} alt={title} />}

        <GraphicDialogTitle>{title}</GraphicDialogTitle>

        <GraphicDialogContent>
          <GraphicDialogContentText>{content}</GraphicDialogContentText>
        </GraphicDialogContent>

        <GraphicDialogActions>
          {primaryButton && (
            <GraphicDialogButton
              fullWidth
              variant="contained"
              loading={isLoading}
              onClick={graphicPopupHandler}
            >
              {primaryButton.label}
            </GraphicDialogButton>
          )}
        </GraphicDialogActions>
      </GraphicDialog>
    );
  }

  return (
    <ConfirmationDialog
      isOpen
      title={title}
      content={content}
      primaryButton={{
        text: primaryButton?.label ?? '',
        onClickHandler: primaryButton?.action ?? (() => {}),
      }}
      secondaryButton={{
        text: secondaryButton?.label ?? '',
        onClickHandler: secondaryButton?.action ?? (() => {}),
      }}
    />
  );
};

const GraphicDialog = styled(Dialog)({
  '.MuiDialog-paper': {
    alignItems: 'center',
  },
});

const Graphic = styled('img')(({ theme }) => ({
  maxWidth: GRAPHIC_DIALOG.MAX_WIDTH,
  paddingBottom: theme.spacing(3),
}));

const GraphicDialogTitle = styled(DialogContent)(({ theme }) => ({
  ...theme.typography.h5,
  paddingBottom: 0,
}));

const GraphicDialogContent = styled(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: 0,
}));

const GraphicDialogContentText = styled(DialogContentText)(({ theme }) => ({
  ...theme.typography.caption,
  textAlign: 'center',
}));

const GraphicDialogActions = styled(DialogActions)({
  width: '100%',
});

const GraphicDialogButton = styled(LoadingButton)({
  textTransform: 'uppercase',
});

const PageOverlayWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  position: 'absolute',
  paddingTop: `${HEADER.MOBILE_HEIGHT + 24}px`,
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
  },
}));

const PageOverlayComponentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
}));
