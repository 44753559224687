import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { useAuth } from '@/hooks';

import { KeycloakImpersonationResponse } from './types';
import { getKeycloakRestApiUrl } from './utils';

const impersonateUserById = async (
  keycloakId: string,
  token = ''
): Promise<KeycloakImpersonationResponse> => {
  const apiUrl = getKeycloakRestApiUrl();

  const response = await axios.post(
    `${apiUrl}/users/${keycloakId}/impersonation`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'json',
      withCredentials: true,
    }
  );

  const result: KeycloakImpersonationResponse = {
    redirect: response.data?.redirect,
    sameRealm: response?.data?.sameRealm,
  };

  return result;
};

/**
 * __useImpersonateKeycloakUser__
 *
 * Allows you to call the `useImpersonateKeycloakUserLazy` hook lazily. The first parameter in the returned
 * array is a function that can be called to execute the query.
 *
 * @param username The Keycloak username to load.
 *
 * @example
 * const [useImpersonateKeycloakUser, { data, isLoading, error }] = useImpersonateKeycloakUserLazy('8ace4ad5-2138-4e08-8501-2a762d3e55dd');
 * ...
 * useImpersonateKeycloakUser();
 */
export function useImpersonateKeycloakUser() {
  const { getToken } = useAuth();

  const token = getToken();

  const { mutate, ...query } = useMutation(
    ['keycloakImpersonate'],
    (keycloakId: string) => impersonateUserById(keycloakId, token),
    {}
  );

  return [mutate, query] as const;
}
