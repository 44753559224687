import { Media } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const getFileName = (media?: DeepPartial<Media>) => {
  const documentName = media?.latestVersion?.document?.name ?? '';

  if (documentName) {
    return documentName;
  }

  const mediaFileExtension = media?.mediaType
    ? `.${media?.mediaType?.toLowerCase()}`
    : '';

  return `${media?.name ?? ''}${mediaFileExtension}`;
};
