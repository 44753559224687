import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetActivityTypeListQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetActivityTypeListQuery = {
  __typename?: 'Query';
  activityTypes: {
    __typename?: 'ActivityTypeConnection';
    edges?: Array<{
      __typename?: 'ActivityTypeEdge';
      node?: {
        __typename?: 'ActivityType';
        id: string;
        oid: string;
        baseType: Types.ActivityTypeBaseType;
        maxAttendeeCount: number;
        minAttendeeCount: number;
        name: string;
        schedulingType: Types.ActivityTypeScheduling;
        defaultDuration: number;
        timeIsOptional: boolean;
        topics: {
          __typename?: 'TopicConnection';
          edges?: Array<{
            __typename?: 'TopicEdge';
            node?: {
              __typename?: 'Topic';
              id: string;
              oid: string;
              name: string;
            } | null;
          }> | null;
        };
        dataPermissions: Array<{
          __typename?: 'DataPermission';
          type: Types.DataPermissionType;
        }>;
      } | null;
    }> | null;
  };
};

/**
 * __useGetActivityTypeListQuery__
 *
 * To run a query within a React component, call `useGetActivityTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivityTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivityTypeListQuery,
    GetActivityTypeListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetActivityTypeListQuery,
    GetActivityTypeListQueryVariables
  >(Operations.getActivityTypeList, options);
}
export function useGetActivityTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityTypeListQuery,
    GetActivityTypeListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetActivityTypeListQuery,
    GetActivityTypeListQueryVariables
  >(Operations.getActivityTypeList, options);
}
export function useGetActivityTypeListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivityTypeListQuery,
    GetActivityTypeListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetActivityTypeListQuery,
    GetActivityTypeListQueryVariables
  >(Operations.getActivityTypeList, options);
}
export type GetActivityTypeListQueryHookResult = ReturnType<
  typeof useGetActivityTypeListQuery
>;
export type GetActivityTypeListLazyQueryHookResult = ReturnType<
  typeof useGetActivityTypeListLazyQuery
>;
export type GetActivityTypeListSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityTypeListSuspenseQuery
>;
export type GetActivityTypeListQueryResult = Apollo.QueryResult<
  GetActivityTypeListQuery,
  GetActivityTypeListQueryVariables
>;
