import { Box, Divider, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { KebabMenuOption } from '@ysura/common';
import { useState } from 'react';

import { MoreMenuButton } from '@/components/Button';

export type KebabPopoverProps = { menuEntries: Array<KebabMenuOption> };

export const KebabPopover = ({ menuEntries }: KebabPopoverProps) => {
  menuEntries = menuEntries.filter((it) => !it.hidden);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  return (
    <MoreMenuButton open={open} setOpen={setOpen}>
      {menuEntries.map((option, index) => {
        const { entry, icon, colorType, onClick } = option;
        const isLastOption = index === menuEntries.length - 1;

        return (
          <Box key={index}>
            <MenuItem
              onClick={() => {
                onClick();
                setOpen(null);
              }}
            >
              <Content colorType={colorType}>
                {icon}
                {entry}
              </Content>
            </MenuItem>
            {!isLastOption && <Divider />}
          </Box>
        );
      })}
    </MoreMenuButton>
  );
};

type ContentProps = Pick<KebabMenuOption, 'colorType'>;

const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'colorType',
})<ContentProps>(({ theme, colorType }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  color:
    colorType && colorType !== 'inherit'
      ? theme.palette[colorType].main
      : 'inherit',
}));
