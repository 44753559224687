import {
  ActivityData,
  ActivityStatusDataEnum,
  PromotionalMaterialGroupData,
} from '@ysura/common';
import { intersectionBy } from 'lodash';

import { useCurrentUser } from '@/hooks/useCurrentUser';
import {
  useGetActivityTypePromotionalMaterialsQuery,
  useGetActivityTypeTopicsQuery,
  useGetCurrentUserInventoryQuery,
  useGetPersonPromotionalMaterialsQuery,
} from '@/services/graphql/hooks';
import {
  parseInventory,
  parsePersonData,
  parsePromotionalMaterialConnection,
  parseTopicConnection,
} from '@/services/graphql/parsers';
import { Person, State } from '@/services/graphql/types';
import { convertPromotionalMaterials } from '@/views/Activity/Update/PromotionalMaterials/helper';

export const usePersonPromotionalMaterials = (
  activity: ActivityData | null,
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>
) => {
  const { loading: currentUserLoading, currentUser } = useCurrentUser();
  const {
    data: activityTypePromotionalMaterialsData,
    loading: activityTypePromotionalMaterialsLoading,
  } = useGetActivityTypePromotionalMaterialsQuery({
    skip:
      currentUserLoading ||
      !currentUser?.person?.oid ||
      !activity?.activityType?.oid,
    variables: {
      oid: activity?.activityType?.oid ?? '',
      states:
        activity?.status === ActivityStatusDataEnum.CLOSED
          ? [State.ACTIVE, State.INACTIVE]
          : [State.ACTIVE],
    },
  });
  const activityTypePromotionalMaterials = parsePromotionalMaterialConnection(
    activityTypePromotionalMaterialsData?.promotionalMaterials
  );

  const { data: activityTypeTopicsData } = useGetActivityTypeTopicsQuery({
    skip:
      currentUserLoading ||
      !currentUser?.person?.oid ||
      !activity?.activityType?.id,
    variables: {
      id: activity?.activityType?.id ?? '',
    },
  });
  const activityTypeTopics = parseTopicConnection(
    activityTypeTopicsData?.activityType?.topics
  );

  const { data: currentUserInventory, loading: currentUserInventoryLoading } =
    useGetCurrentUserInventoryQuery({
      skip: currentUserLoading || !currentUser?.person?.oid,
    });

  const inventory = currentUserInventory?.currentUser?.inventory
    ? parseInventory(currentUserInventory?.currentUser?.inventory)
    : undefined;

  const {
    data: personPromotionalMaterialsData,
    loading: personPromotionalMaterialsLoading,
  } = useGetPersonPromotionalMaterialsQuery({
    skip: !activity?.attendees?.length,
    variables: {
      personId: activity?.attendees?.[0]?.person?.id ?? '',
    },
    fetchPolicy: 'network-only',
  });
  const parsedPersonPromotionalMaterials =
    parsePersonData(personPromotionalMaterialsData?.person as Person)
      .promotionalMaterials ?? [];

  // enrich with promotional material data
  parsedPersonPromotionalMaterials.forEach((item) => {
    const found = activityTypePromotionalMaterials.find(
      (pm) => pm.id === item.promotionalMaterial.id
    );
    item.promotionalMaterial.topics = found?.topics;
    item.promotionalMaterial.signatureRequired = found?.signatureRequired;
  });

  const isActivityTypeRelevant = (item: PromotionalMaterialGroupData) => {
    return (
      // Check if promotional material is available for selected activity type
      activityTypePromotionalMaterials
        .map((promat) => promat.id)
        .includes(item.promotionalMaterial?.id) &&
      // Check if promotional material and activity type have at least one intersecting topic
      intersectionBy(
        activityTypeTopics,
        item.promotionalMaterial?.topics ?? [],
        'id'
      ).length > 0
    );
  };

  const availablePromotionalMaterials = convertPromotionalMaterials(
    parsedPersonPromotionalMaterials,
    promotionalMaterialGroups,
    activityTypePromotionalMaterials,
    inventory
  ).filter((item) => isActivityTypeRelevant(item));

  const selectedPromotionalMaterials = availablePromotionalMaterials.filter(
    (each) => each.selected
  );

  return {
    isLoading:
      currentUserLoading ||
      !currentUser ||
      personPromotionalMaterialsLoading ||
      !personPromotionalMaterialsData ||
      activityTypePromotionalMaterialsLoading ||
      currentUserInventoryLoading ||
      !currentUserInventory,
    availablePromotionalMaterials,
    selectedPromotionalMaterials,
  };
};
