import {
  Box,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { AudioVideoSettings } from './AudioVideoSettings';

type AudioVideoSettingsDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
};

export const AudioVideoSettingsDialog = ({
  isOpen,
  closeDialog,
}: AudioVideoSettingsDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth={'sm'}
      data-testid="settings-dialog"
    >
      <DialogTitle>
        <Typography variant="h5">
          {t('pages.interaction.audioVideoSettings.settings')}
        </Typography>
        <Button variant="contained" onClick={closeDialog}>
          {t('pages.interaction.audioVideoSettings.done')}
        </Button>
      </DialogTitle>
      <DialogContent>
        <AudioVideoSettings isMobileDevice isInDialog />
      </DialogContent>
    </Dialog>
  );
};

const Dialog = styled(MuiDialog)({
  '.MuiDialog-paperWidthXl, .MuiDialog-paperWidthMd': {
    height: '100%',
  },
});

const DialogTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  height: '100%',
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
