import { UserData } from '@ysura/common';

import { UserClientPermissions } from '@/services/user/permissions/ClientPermissions';
import { hasClientPermission } from '@/services/user/permissions/helper';

export const getUserPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): UserClientPermissions => {
  return {
    canImpersonate: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-impersonate-read'
    ),
  };
};
