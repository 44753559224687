import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonTypesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPersonTypesQuery = {
  __typename?: 'Query';
  personTypes: {
    __typename?: 'PersonTypeConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'PersonTypeEdge';
      node?: {
        __typename?: 'PersonType';
        id: string;
        oid: string;
        name?: string | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetPersonTypesQuery__
 *
 * To run a query within a React component, call `useGetPersonTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPersonTypesQuery,
    GetPersonTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetPersonTypesQuery, GetPersonTypesQueryVariables>(
    Operations.getPersonTypes,
    options
  );
}
export function useGetPersonTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonTypesQuery,
    GetPersonTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetPersonTypesQuery, GetPersonTypesQueryVariables>(
    Operations.getPersonTypes,
    options
  );
}
export function useGetPersonTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonTypesQuery,
    GetPersonTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonTypesQuery,
    GetPersonTypesQueryVariables
  >(Operations.getPersonTypes, options);
}
export type GetPersonTypesQueryHookResult = ReturnType<
  typeof useGetPersonTypesQuery
>;
export type GetPersonTypesLazyQueryHookResult = ReturnType<
  typeof useGetPersonTypesLazyQuery
>;
export type GetPersonTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonTypesSuspenseQuery
>;
export type GetPersonTypesQueryResult = Apollo.QueryResult<
  GetPersonTypesQuery,
  GetPersonTypesQueryVariables
>;
