import { gql } from '@apollo/client';

export const getActivityTypeTopics = gql`
  query getActivityTypeTopics($id: ID!) {
    activityType(id: $id) {
      id
      oid
      topics(filter: { state: { _eq: ACTIVE } }) {
        edges {
          node {
            id
            oid
            name
          }
        }
      }
    }
  }
`;
