import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SquareButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import {
  DocumentDownloadLink,
  FitScreenButton,
  FullScreenButton,
} from '@/components/Button';
import { PageNumberController } from '@/components/PageNumberController';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';

import { LeftArrow, MiddleButtons, RightArrow } from './ActionStyle';

const buttonSize = 36;

type FilePreviewActionsProps = {
  onClose: VoidFunction;
  onChangePage: (offset: number) => void;
  onChangeAspect: VoidFunction;
  currentPage: number;
  numberOfPages: number;
  fileName: string;
  contentLink: string;
  isDownloadButtonShown?: boolean;
  isReadOnlyMode?: boolean;
  isFullScreenShown?: boolean;
  isFitScreenShown?: boolean;
};

type RenderButtonsProps = {
  shouldRenderPageTracker: boolean;
  pageTrackerVariant?: 'h4' | 'h6' | 'subtitle2';
};

export const PDFFileActions = ({
  onClose,
  onChangeAspect,
  onChangePage,
  currentPage,
  numberOfPages,
  fileName,
  contentLink,
  isDownloadButtonShown = true,
  isReadOnlyMode = false,
  isFullScreenShown = false,
  isFitScreenShown = true,
}: FilePreviewActionsProps) => {
  const { t } = useTranslation();
  const { toggleIsSharingInFullscreen } = useInteraction();

  const handleFullScreen = () => {
    toggleIsSharingInFullscreen();

    setTimeout(() => {
      /**
       * calls the resize event so the PDF takes proper
       * width/height when the container extends or shrinks
       */
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  const renderFitScreenButton = () => {
    return (
      isFitScreenShown && <FitScreenButton onChangeAspect={onChangeAspect} />
    );
  };

  const renderFullScreenButton = () => {
    return (
      isFullScreenShown && (
        <FullScreenButton onChangeFullScreen={handleFullScreen} />
      )
    );
  };

  const renderPageDownButton = () => {
    return (
      <SquareButton
        variant="secondary"
        buttonSize={buttonSize}
        testId="page_down"
        disabled={currentPage === 1}
        onClick={() => onChangePage(-1)}
      >
        <LeftArrow />
      </SquareButton>
    );
  };

  const renderPageUpButton = () => {
    return (
      <SquareButton
        variant="secondary"
        buttonSize={buttonSize}
        testId="page_up"
        disabled={currentPage === numberOfPages}
        onClick={() => onChangePage(1)}
      >
        <RightArrow />
      </SquareButton>
    );
  };

  const renderButtons = ({
    shouldRenderPageTracker,
    pageTrackerVariant = 'subtitle2',
  }: RenderButtonsProps) => {
    return (
      <MiddleButtons>
        {renderPageDownButton()}
        {shouldRenderPageTracker && (
          <PageNumberController
            currentPage={currentPage}
            totalPages={numberOfPages}
            variant={pageTrackerVariant}
          />
        )}
        {renderPageUpButton()}
      </MiddleButtons>
    );
  };

  const renderDownloadButton = (icon = false) => {
    return (
      <DocumentDownloadLink
        contentLink={contentLink}
        fileName={fileName + '.pdf'}
        variant={icon ? 'iconButton' : 'secondaryButton'}
        displayText={t('pages.interaction.expand.download')}
      />
    );
  };

  return (
    <>
      <LargeScreenDialogPDFActions data-testid="large-screen-dialog-actions">
        <LeftAbsoluteBox>
          {isDownloadButtonShown && renderDownloadButton()}
          {!isDownloadButtonShown && renderFullScreenButton()}
        </LeftAbsoluteBox>
        {!isReadOnlyMode &&
          renderButtons({
            shouldRenderPageTracker: true,
          })}
        <RightAbsoluteBox>
          <SmallBox>
            {isDownloadButtonShown && renderFullScreenButton()}
            {renderFitScreenButton()}
          </SmallBox>
        </RightAbsoluteBox>
      </LargeScreenDialogPDFActions>

      <MobileLandscapeDialogPDFActions data-testid="mobile-landscape-dialog-actions">
        <SmallStack>
          <SquareButton
            buttonSize={buttonSize}
            data-testid="close_button"
            onClick={onClose}
          >
            <CloseIcon />
          </SquareButton>
          {isDownloadButtonShown && renderDownloadButton(true)}
        </SmallStack>

        <SmallStack>
          <MobileLandscapeButtonContainer>
            {renderButtons({ shouldRenderPageTracker: true })}
          </MobileLandscapeButtonContainer>
        </SmallStack>
        {renderFitScreenButton()}
      </MobileLandscapeDialogPDFActions>

      <MobilePortraitDialogPDFActions data-testid="mobile-portrait-dialog-actions">
        <LeftAbsoluteBox>
          {isDownloadButtonShown && renderDownloadButton(true)}
        </LeftAbsoluteBox>
        <MobilePortraitButtonContainer>
          {renderButtons({
            shouldRenderPageTracker: true,
          })}
        </MobilePortraitButtonContainer>
        <RightAbsoluteBox>{renderFitScreenButton()}</RightAbsoluteBox>
      </MobilePortraitDialogPDFActions>
    </>
  );
};

const LargeScreenDialogPDFActions = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  paddingTop: 0,
  gap: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },

  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const MobilePortraitDialogPDFActions = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.only('xs')]: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

const MobileLandscapeDialogPDFActions = styled(Box)(({ theme }) => ({
  display: 'none',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      paddingLeft: theme.spacing(2),
    },
}));

const MobileLandscapeButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const MobilePortraitButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const LeftAbsoluteBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(3),
}));

const RightAbsoluteBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(3),
}));

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
