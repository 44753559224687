import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

type PageContainerProps = {
  children: ReactNode;
};

export const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <Container data-testid="remote-interaction-container">{children}</Container>
  );
};

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  height: '100%',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    gap: 'initial',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
    },
}));
