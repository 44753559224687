import { gql } from '@apollo/client';

export const getRecentAccounts = gql`
  query getRecentAccounts {
    recentAccountHistory {
      persons(first: 3) {
        edges {
          node {
            id
            oid
            firstName
            lastName
            prefixAcademicTitle {
              name
            }

            employments(
              first: 1
              filter: { state: { _eq: ACTIVE } }
              orderBy: { sortCode: ASC }
            ) {
              filteredCount
              edges {
                node {
                  id
                  oid
                  sortCode
                  addresses(
                    filter: {
                      addressType: { _eq: STANDARD }
                      address: { state: { _eq: ACTIVE } }
                    }
                  ) {
                    edges {
                      node {
                        addressType
                        address {
                          id
                          oid
                          addressLine1
                          addressLine2
                          addressLine3
                          city
                          postalCode
                          state
                        }
                      }
                    }
                  }
                  organization {
                    id
                    oid
                    name
                    addresses(
                      filter: {
                        addressType: { _eq: STANDARD }
                        address: { state: { _eq: ACTIVE } }
                      }
                    ) {
                      edges {
                        node {
                          addressType
                          address {
                            id
                            oid
                            addressLine1
                            addressLine2
                            addressLine3
                            city
                            postalCode
                            state
                          }
                        }
                      }
                    }
                    rootOrganization {
                      id
                      oid
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
      organizations(first: 3) {
        edges {
          node {
            id
            oid
            name
            organizationTypes {
              edges {
                node {
                  id
                  oid
                  name
                  baseType
                }
              }
            }
            rootOrganization {
              id
              oid
              name
            }
            addresses {
              edges {
                node {
                  address {
                    id
                    oid
                    name
                    addressLine1
                    addressLine2
                    addressLine3
                    postalCode
                    city
                    country {
                      id
                      oid
                      name
                    }
                  }
                  addressType
                }
              }
            }
          }
        }
      }
    }
  }
`;
