import {
  Box,
  Button as MuiButton,
  Dialog,
  DialogContent as MuiDialogContent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { useTranslation } from 'react-i18next';

type EndInteractionDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
  handleSaveAndExit: VoidFunction;
  handleEndWithoutSaving: VoidFunction;
  shouldShowLoader?: boolean;
};

export const EndInteractionDialog = ({
  isOpen,
  closeDialog,
  handleSaveAndExit,
  handleEndWithoutSaving,
  shouldShowLoader = false,
}: EndInteractionDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} data-testid="end-interaction-dialog">
      {shouldShowLoader ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h6" data-testid="end-interaction-title">
              {t('pages.interaction.common.endInteractionPopupTitle')}
            </Typography>
            <Typography variant="body2" data-testid="end-interaction-details">
              {t('pages.interaction.common.endInteractionPopUpDetail')}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Button variant="contained" onClick={handleSaveAndExit}>
              {t('pages.interaction.common.saveAndExit')}
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleEndWithoutSaving}
            >
              {t('pages.interaction.common.endNoSave')}
            </Button>

            <Button variant="outlined" color="inherit" onClick={closeDialog}>
              {t('components.common.cancel')}
            </Button>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const DialogTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  padding: theme.spacing(2),
  paddingTop: 0,
  height: '100%',
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});

const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: theme.spacing(3),
}));
