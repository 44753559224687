import AddCircleIcon from '@mui/icons-material/AddCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MedicationIcon from '@mui/icons-material/Medication';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  IconWrapper,
  Label,
  PromotionalMaterialGroupData,
  PromotionalMaterialTypeDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

type PromotionalMaterialListItemProps = {
  isRestrictedEdit?: boolean;
  promotionalMaterialListItem: PromotionalMaterialGroupData;
  isSigned: boolean;
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const PromotionalMaterialListItem = ({
  isRestrictedEdit = false,
  promotionalMaterialListItem,
  isSigned,
  promotionalMaterialGroups,
  onChangePromats,
}: PromotionalMaterialListItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const promotionalMaterial = promotionalMaterialListItem.promotionalMaterial;

  const isProduct =
    promotionalMaterial?.type === PromotionalMaterialTypeDataEnum.PRODUCT;
  const isPromotionalMaterialSigned =
    promotionalMaterial?.signatureRequired &&
    promotionalMaterialListItem.selected &&
    isSigned;
  const isDisabled = !!promotionalMaterialListItem.availabilityReason;
  const availableLabelColor = isDisabled ? 'error' : 'default';

  // The label is:
  // - If not available for some reason display the reason
  // - If there is a maximum quantity for the period, display e.g. Available: 1/2
  // - If there is not a maximum quantity for the period, display e.g. Available: 1
  const availableLabelContent = promotionalMaterialListItem.availabilityReason
    ? t(
        'pages.interaction.common.' +
          promotionalMaterialListItem.availabilityReason
      )
    : `${t('pages.interaction.common.available')}: ${
        promotionalMaterialListItem.availableQuantityInCurrentActivity ?? '-'
      }` +
      (promotionalMaterialListItem.maximumQuantityInCurrentPeriod
        ? `/${promotionalMaterialListItem.maximumQuantityInCurrentPeriod}`
        : '');

  const isDisabledToAdd =
    isDisabled || (promotionalMaterial?.signatureRequired && isRestrictedEdit);

  const handleMaterialSelect = () => {
    onChangePromats([
      promotionalMaterialListItem,
      ...promotionalMaterialGroups.filter((each) => each.selected),
    ]);
  };

  return (
    <Wrapper>
      <InformationWrapper>
        <Title>
          <IconWrapper
            isDisabled={isDisabled}
            size={20}
            backgroundColor={
              isProduct ? theme.palette.info.dark : theme.palette.error.light
            }
          >
            {isProduct ? <ProductIcon /> : <MarketingMaterialIcon />}
          </IconWrapper>

          <Name variant="subtitle2" isDisabled={isDisabled}>
            {promotionalMaterial?.name}
          </Name>
        </Title>

        {isProduct && (
          <LabelsWrapper>
            {promotionalMaterial.signatureRequired &&
              !isPromotionalMaterialSigned && (
                <Label color="warning">
                  {t('pages.interaction.common.signatureRequired')}
                </Label>
              )}
            {isPromotionalMaterialSigned && (
              <Label color="success">
                {t('pages.interaction.common.signatureCollected')}
              </Label>
            )}
            <Label color={availableLabelColor}>{availableLabelContent}</Label>
          </LabelsWrapper>
        )}

        {!isProduct && isDisabled && (
          <LabelsWrapper>
            <Label color={availableLabelColor}>{availableLabelContent}</Label>
          </LabelsWrapper>
        )}
      </InformationWrapper>

      {promotionalMaterialListItem.selected ? (
        <AddedLabel variant="overline">
          {t('pages.interaction.expand.added')}
        </AddedLabel>
      ) : (
        <IconButton
          color="primary"
          disabled={isDisabledToAdd ?? false}
          onClick={handleMaterialSelect}
        >
          <AddCircleIcon />
        </IconButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.customShadows.z8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

const InformationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const LabelsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const ProductIcon = styled(MedicationIcon)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.common.white,
}));

const MarketingMaterialIcon = styled(LibraryBooksIcon)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.common.white,
}));

const Name = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled: boolean }>(({ theme, isDisabled }) => ({
  color: isDisabled ? theme.palette.text.disabled : 'inherit',
}));

const AddedLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
