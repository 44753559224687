import { Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const CommentsSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.activity.comments')}
      testId="notes-skeleton-static-block"
    >
      <Typography variant="body2">
        <Skeleton height={40} />
      </Typography>
    </OverviewStaticBlock>
  );
};
