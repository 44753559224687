import { gql } from '@apollo/client';

export const resendDoubleOptIn = gql`
  mutation resendDoubleOptIn($oid: PersonConsentId!) {
    resendDoubleOptIn(input: { oid: $oid }) {
      id
      oid
    }
  }
`;
