import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetSampleRequestFormDocumentQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSampleRequestFormDocumentQuery = {
  __typename?: 'Query';
  templates: {
    __typename?: 'TemplateConnection';
    filteredCount: number;
    edges?: Array<{
      __typename?: 'TemplateEdge';
      node?: {
        __typename?: 'Template';
        id: string;
        oid: string;
        document?: {
          __typename?: 'Document';
          id: string;
          oid: string;
          name?: string | null;
          contentLink: string;
          mediaType: string;
        } | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetSampleRequestFormDocumentQuery__
 *
 * To run a query within a React component, call `useGetSampleRequestFormDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSampleRequestFormDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSampleRequestFormDocumentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSampleRequestFormDocumentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSampleRequestFormDocumentQuery,
    GetSampleRequestFormDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetSampleRequestFormDocumentQuery,
    GetSampleRequestFormDocumentQueryVariables
  >(Operations.getSampleRequestFormDocument, options);
}
export function useGetSampleRequestFormDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSampleRequestFormDocumentQuery,
    GetSampleRequestFormDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetSampleRequestFormDocumentQuery,
    GetSampleRequestFormDocumentQueryVariables
  >(Operations.getSampleRequestFormDocument, options);
}
export function useGetSampleRequestFormDocumentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSampleRequestFormDocumentQuery,
    GetSampleRequestFormDocumentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetSampleRequestFormDocumentQuery,
    GetSampleRequestFormDocumentQueryVariables
  >(Operations.getSampleRequestFormDocument, options);
}
export type GetSampleRequestFormDocumentQueryHookResult = ReturnType<
  typeof useGetSampleRequestFormDocumentQuery
>;
export type GetSampleRequestFormDocumentLazyQueryHookResult = ReturnType<
  typeof useGetSampleRequestFormDocumentLazyQuery
>;
export type GetSampleRequestFormDocumentSuspenseQueryHookResult = ReturnType<
  typeof useGetSampleRequestFormDocumentSuspenseQuery
>;
export type GetSampleRequestFormDocumentQueryResult = Apollo.QueryResult<
  GetSampleRequestFormDocumentQuery,
  GetSampleRequestFormDocumentQueryVariables
>;
