import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { GLOBAL_SEARCH } from '@/config/layout';

type SearchFieldProps = {
  onClickSearch: VoidFunction;
  searchQuery: string;
  fullWidth?: boolean;
  testId?: string;
};

//TODO: refactor to a common input search
// https://youtrack.ysura.com/issue/CIP-1137/CleanUp-Search-Input
export const SearchField = ({
  onClickSearch,
  searchQuery,
  fullWidth = false,
  testId = 'header-search-field',
}: SearchFieldProps) => {
  const { t } = useTranslation();

  return (
    <Search
      data-testid={testId}
      fullWidth={fullWidth}
      autoComplete="off"
      placeholder={t('components.common.search')}
      size="small"
      value={searchQuery}
      InputProps={{
        // this affects only in main global search to hide cursor
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            <Icon />
          </InputAdornment>
        ),
      }}
      onClick={onClickSearch}
    />
  );
};

const Search = styled(TextField)(({ theme }) => ({
  width: GLOBAL_SEARCH.HEADER_SEARCH_FIELD.WIDTH_LARGE,

  /**
   * Updating the focused fieldset border in order to avoid "flashing"
   * border transition when we open the SearchDialog.
   */
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.grey[500_32]}`,
    },
  },

  [theme.breakpoints.down('lg')]: {
    width: GLOBAL_SEARCH.HEADER_SEARCH_FIELD.WIDTH_MEDIUM,
  },
}));

const Icon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: 20,
  height: 20,
}));
