import { NoItemPlaceholder, PersonData } from '@ysura/common';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentCardList, ConsentSkeletonCard } from '@/components/Consent';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { errorConfigQueryLoadingError } from '@/hooks/useNotification';
import { useGetPersonConsentListQuery } from '@/services/graphql/hooks';
import { parsePersonConsents } from '@/services/graphql/parsers';

type ConsentStaticBlockProps = {
  person: PersonData;
};

export const ConsentStaticBlock = ({ person }: ConsentStaticBlockProps) => {
  const { t } = useTranslation();
  const staticBlockTitle = t('pages.personView.consent');
  const errorCfg = useRef(
    errorConfigQueryLoadingError(t('components.error.consent'))
  );

  const { data, loading, error, refetch } = useGetPersonConsentListQuery({
    ...errorCfg.current.context,
    variables: {
      id: person?.id ?? '',
    },
  });

  errorCfg.current.bannerOptions.button = {
    label: t('components.error.reload'),
    action: refetch,
  };

  if (loading || error) {
    const testId = loading
      ? 'consent-static-block'
      : 'consent-static-block-error';

    return (
      <OverviewStaticBlock title={staticBlockTitle} testId={testId}>
        <ConsentSkeletonCard />
      </OverviewStaticBlock>
    );
  }

  const consentList = parsePersonConsents(data?.person ?? undefined);

  if (!consentList.length) {
    return (
      <OverviewStaticBlock
        title={staticBlockTitle}
        testId="consent-static-block"
      >
        <NoItemPlaceholder>
          {t('pages.personView.noConsents')}
        </NoItemPlaceholder>
      </OverviewStaticBlock>
    );
  }

  return (
    <OverviewStaticBlock title={staticBlockTitle} testId="consent-static-block">
      {person && <ConsentCardList person={person} consentList={consentList} />}
    </OverviewStaticBlock>
  );
};
