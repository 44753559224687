import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type ResendDoubleOptInMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
}>;

export type ResendDoubleOptInMutation = {
  __typename?: 'Mutation';
  resendDoubleOptIn?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type ResendDoubleOptInMutationFn = Apollo.MutationFunction<
  ResendDoubleOptInMutation,
  ResendDoubleOptInMutationVariables
>;

/**
 * __useResendDoubleOptInMutation__
 *
 * To run a mutation, you first call `useResendDoubleOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendDoubleOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendDoubleOptInMutation, { data, loading, error }] = useResendDoubleOptInMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *   },
 * });
 */
export function useResendDoubleOptInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendDoubleOptInMutation,
    ResendDoubleOptInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ResendDoubleOptInMutation,
    ResendDoubleOptInMutationVariables
  >(Operations.resendDoubleOptIn, options);
}
export type ResendDoubleOptInMutationHookResult = ReturnType<
  typeof useResendDoubleOptInMutation
>;
export type ResendDoubleOptInMutationResult =
  Apollo.MutationResult<ResendDoubleOptInMutation>;
export type ResendDoubleOptInMutationOptions = Apollo.BaseMutationOptions<
  ResendDoubleOptInMutation,
  ResendDoubleOptInMutationVariables
>;
