import { Button as MuiButton, Dialog, DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  AttendeeData,
  CommunicationDataBaseTypeDataEnum,
} from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextOrLoader } from '@/components/Button/TextOrLoader';
import { useNotification } from '@/hooks';
import {
  useGetPersonAvailableEmailsQuery,
  useSendInvitationEmailMutation,
} from '@/services/graphql/hooks';
import {
  parsePersonConsents,
  parsePersonData,
} from '@/services/graphql/parsers';
import { emailRegex } from '@/utils/regex';
import { InvitationSkeletonDialogContent } from '@/views/Interaction/Remote/Invitation';

import { InvitationDialogContent } from './InvitationDialogContent';

type InvitationDialogProps = {
  activity: ActivityData;
  isOpen: boolean;
  onClose: VoidFunction;
  attendee?: AttendeeData;
};

export const isEmailValid = (email: string) => emailRegex.test(email);

export const InvitationDialog = ({
  activity,
  attendee,
  isOpen,
  onClose,
}: InvitationDialogProps) => {
  const { t } = useTranslation();

  const [selectedEmail, setSelectedEmail] = useState('');
  const [customEmail, setCustomEmail] = useState('');
  const [isCustomEmailValid, setIsCustomEmailValid] = useState(true);

  const [sendInvitationEmailMutation, { loading: isSendingInvitationLoading }] =
    useSendInvitationEmailMutation();
  const { toast } = useNotification();

  const resetState = () => {
    setSelectedEmail('');
    setCustomEmail('');
    setIsCustomEmailValid(false);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const isCustomEmailSelected = () => {
    return !!customEmail || selectedEmail === 'customEmail';
  };

  const isSendAllowed = () => {
    return isCustomEmailSelected() ? isCustomEmailValid : !!selectedEmail;
  };

  const { data, loading } = useGetPersonAvailableEmailsQuery({
    variables: { id: attendee?.person?.id ?? '' },
  });
  const availableEmails: string[] = [];
  const person = parsePersonData(data?.person);
  person?.communicationData?.forEach((each) => {
    if (each.value) {
      availableEmails.push(each.value);
    }
  });
  person?.employments?.forEach(
    (employment) =>
      employment.communicationData?.forEach((each) => {
        if (each.value) {
          availableEmails.push(each.value);
        }
      })
  );

  const personConsents = parsePersonConsents(data?.person);
  personConsents?.forEach(
    (each) =>
      each.consentItemValues?.forEach(
        (item) =>
          item.communicationDataValues?.forEach((value) => {
            if (
              value?.value &&
              value?.communicationDataType?.baseType ===
                CommunicationDataBaseTypeDataEnum.EMAIL
            ) {
              availableEmails.push(value.value);
            }
          })
      )
  );

  const handleEmailRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setSelectedEmail(value);

    if (value !== 'customEmail') {
      setCustomEmail('');
    }
  };

  const handleEmailInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomEmail(event.target.value);
  };

  const handleValidateEmailInput = () => {
    setIsCustomEmailValid(isEmailValid(customEmail));
  };

  const handleCustomEmailInputClick = () => {
    setSelectedEmail('customEmail');
  };

  const handleEmailSend = async () => {
    if (!isSendAllowed()) {
      toast?.({
        type: 'warning',
        message: t('pages.interaction.remote.invalidEmailWarning'),
      });

      return;
    }
    // There are 2 cases here:
    if (isCustomEmailSelected()) {
      // 1.Send custom email:
      await sendEmail(customEmail);
    } else if (selectedEmail) {
      // 2. Send radio email:
      await sendEmail(selectedEmail);
    }
  };

  const sendEmail = async (email: string) => {
    await sendInvitationEmailMutation({
      variables: {
        input: {
          oid: activity.oid ?? '',
          receivers: [
            {
              attendeeId: attendee?.oid ?? '',
              email,
            },
          ],
        },
      },
      onCompleted() {
        toast?.({
          type: 'success',
          message: t('pages.interaction.toasts.inviteAttendeeViaEmailSuccess'),
        });
        resetState();
        onClose();
      },
      onError() {
        toast?.({
          type: 'error',
          message: t('pages.interaction.toasts.inviteAttendeeViaEmailFailure'),
        });
        resetState();
      },
    });
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xs">
      {loading ? (
        <InvitationSkeletonDialogContent />
      ) : (
        <InvitationDialogContent
          availableEmails={availableEmails}
          selectedEmail={selectedEmail}
          isCustomEmailValid={!customEmail || isCustomEmailValid}
          handleEmailRadioChange={handleEmailRadioChange}
          handleEmailInputChange={handleEmailInputChange}
          handleValidateEmailInput={handleValidateEmailInput}
          handleCustomEmailInputClick={handleCustomEmailInputClick}
          handleEmailSend={handleEmailSend}
        />
      )}

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('components.common.cancel')}
        </Button>
        <Button
          disabled={isSendingInvitationLoading}
          variant="contained"
          onClick={handleEmailSend}
        >
          <TextOrLoader
            isLoading={isSendingInvitationLoading}
            text={t('pages.interaction.remote.send')}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
