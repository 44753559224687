import { UserData } from '@ysura/common';

import { getActivityPermissions } from '@/services/user/permissions/activity';
import { getCommonPermissions } from '@/services/user/permissions/common';
import { getConsentPermissions } from '@/services/user/permissions/consent';
import { getOrganizationPermissions } from '@/services/user/permissions/organization';
import { getPersonPermissions } from '@/services/user/permissions/person';
import { getUserPermissions } from '@/services/user/permissions/user';

export type ActivityClientPermissions = {
  canReadActivity: boolean;
  canCreateActivity: boolean;
  canEditActivity: boolean;
  canSealActivity: boolean;
  canEditActivityMedia: boolean;
  canReadActivityComment: boolean;
  canEditActivityComment: boolean;
  canUseRemoteInteraction: boolean;
  canUseRemoteInteractionScreenShare: boolean;
  canUseF2FInteraction: boolean;
  canUseTelephoneInteraction: boolean;
  canUseTelephoneInteractionScreenShare: boolean;
};

export type UserClientPermissions = {
  canImpersonate: boolean;
};

export type ConsentClientPermissions = {
  canCollectConsent: boolean;
  canResetConsent: boolean;
  canResendDoubleOptIn: boolean;
  canReadConsentTemplate: boolean;
};

export type CommonClientPermissions = {
  canSwitchSearchScope: boolean;
  canReadHistory: boolean;
  canMakePhoneCall: boolean;
};

export type OrganizationClientPermissions = {
  canReadFacets: boolean;
  canReadTypeFilter: boolean;
  canReadCityFilter: boolean;
  canReadAttributeFilter: boolean;
};

export type PersonClientPermissions = {
  canReadFacets: boolean;
  canReadTypeFilter: boolean;
  canReadRoleFilter: boolean;
  canReadSpecialityFilter: boolean;
  canReadSpecialityGroupFilter: boolean;
  canReadAttributeFilter: boolean;
  canReadConsentFilter: boolean;
  canReadCommunicationData: boolean;
  canReadTouchpointStatistics: boolean;
  canReadSampleDrop: boolean;
};

export type ClientPermissions = {
  activity: ActivityClientPermissions;
  person: PersonClientPermissions;
  organization: OrganizationClientPermissions;
  consent: ConsentClientPermissions;
  user: UserClientPermissions;
  common: CommonClientPermissions;
};
export const getClientPermission = (
  apiUserData?: UserData,
  isAdmin?: boolean
): ClientPermissions => {
  return {
    activity: getActivityPermissions(apiUserData, isAdmin),
    person: getPersonPermissions(apiUserData, isAdmin),
    organization: getOrganizationPermissions(apiUserData, isAdmin),
    consent: getConsentPermissions(apiUserData, isAdmin),
    user: getUserPermissions(apiUserData, isAdmin),
    common: getCommonPermissions(apiUserData, isAdmin),
  };
};
