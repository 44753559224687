import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetOrganizationByIdQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['ID']['input'];
}>;

export type GetOrganizationByIdQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    oid: string;
    name?: string | null;
    customerReference?: string | null;
    inClearance: boolean;
    organizationTypes: {
      __typename?: 'OrganizationTypeConnection';
      edges?: Array<{
        __typename?: 'OrganizationTypeEdge';
        node?: {
          __typename?: 'OrganizationType';
          id: string;
          oid: string;
          name?: string | null;
          baseType?: Types.OrganizationBaseType | null;
        } | null;
      }> | null;
    };
    parentOrganization?: {
      __typename?: 'Organization';
      id: string;
      oid: string;
      name?: string | null;
    } | null;
    rootOrganization?: {
      __typename?: 'Organization';
      id: string;
      oid: string;
      name?: string | null;
      customerReference?: string | null;
      organizationTypes: {
        __typename?: 'OrganizationTypeConnection';
        edges?: Array<{
          __typename?: 'OrganizationTypeEdge';
          node?: {
            __typename?: 'OrganizationType';
            id: string;
            oid: string;
            name?: string | null;
            baseType?: Types.OrganizationBaseType | null;
          } | null;
        }> | null;
      };
      addresses: {
        __typename?: 'EntityAddressConnection';
        edges?: Array<{
          __typename?: 'EntityAddressEdge';
          node?: {
            __typename?: 'EntityAddress';
            addressType: Types.AddressType;
            address: {
              __typename?: 'Address';
              id: string;
              oid: string;
              name?: string | null;
              customerReference?: string | null;
              addressLine1?: string | null;
              addressLine2?: string | null;
              addressLine3?: string | null;
              postalCode: string;
              city: string;
              country: {
                __typename?: 'Country';
                id: string;
                oid: string;
                name?: string | null;
              };
            };
          } | null;
        }> | null;
      };
      communicationData: {
        __typename?: 'CommunicationDataConnection';
        edges?: Array<{
          __typename?: 'CommunicationDataEdge';
          node?: {
            __typename?: 'CommunicationData';
            value?: string | null;
            communicationDataType: {
              __typename?: 'CommunicationDataType';
              id: string;
              oid: string;
              name?: string | null;
              baseType?: Types.CommunicationDataBaseType | null;
            };
          } | null;
        }> | null;
      };
    } | null;
    hierarchyOrganizations: {
      __typename?: 'OrganizationConnection';
      edges?: Array<{
        __typename?: 'OrganizationEdge';
        node?: {
          __typename?: 'Organization';
          id: string;
          oid: string;
          name?: string | null;
          parentOrganization?: {
            __typename?: 'Organization';
            id: string;
            oid: string;
            name?: string | null;
          } | null;
          organizationTypes: {
            __typename?: 'OrganizationTypeConnection';
            edges?: Array<{
              __typename?: 'OrganizationTypeEdge';
              node?: {
                __typename?: 'OrganizationType';
                id: string;
                oid: string;
                name?: string | null;
                baseType?: Types.OrganizationBaseType | null;
              } | null;
            }> | null;
          };
          addresses: {
            __typename?: 'EntityAddressConnection';
            edges?: Array<{
              __typename?: 'EntityAddressEdge';
              node?: {
                __typename?: 'EntityAddress';
                addressType: Types.AddressType;
                address: {
                  __typename?: 'Address';
                  id: string;
                  oid: string;
                  name?: string | null;
                  customerReference?: string | null;
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  postalCode: string;
                  city: string;
                  country: {
                    __typename?: 'Country';
                    id: string;
                    oid: string;
                    name?: string | null;
                  };
                };
              } | null;
            }> | null;
          };
          employments: {
            __typename?: 'EmploymentConnection';
            edges?: Array<{
              __typename?: 'EmploymentEdge';
              node?: {
                __typename?: 'Employment';
                oid: string;
                organization: {
                  __typename?: 'Organization';
                  id: string;
                  oid: string;
                  name?: string | null;
                  addresses: {
                    __typename?: 'EntityAddressConnection';
                    edges?: Array<{
                      __typename?: 'EntityAddressEdge';
                      node?: {
                        __typename?: 'EntityAddress';
                        addressType: Types.AddressType;
                        address: {
                          __typename?: 'Address';
                          name?: string | null;
                          customerReference?: string | null;
                          addressLine1?: string | null;
                          addressLine2?: string | null;
                          addressLine3?: string | null;
                          postalCode: string;
                          city: string;
                          country: {
                            __typename?: 'Country';
                            name?: string | null;
                          };
                        };
                      } | null;
                    }> | null;
                  };
                };
                person: {
                  __typename?: 'Person';
                  id: string;
                  firstName?: string | null;
                  middleName?: string | null;
                  lastName?: string | null;
                  prefixAcademicTitle?: {
                    __typename?: 'AcademicTitle';
                    name?: string | null;
                    state: Types.State;
                  } | null;
                  postfixAcademicTitle?: {
                    __typename?: 'AcademicTitle';
                    name?: string | null;
                    state: Types.State;
                  } | null;
                };
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
    addresses: {
      __typename?: 'EntityAddressConnection';
      edges?: Array<{
        __typename?: 'EntityAddressEdge';
        node?: {
          __typename?: 'EntityAddress';
          addressType: Types.AddressType;
          address: {
            __typename?: 'Address';
            id: string;
            oid: string;
            name?: string | null;
            customerReference?: string | null;
            addressLine1?: string | null;
            addressLine2?: string | null;
            addressLine3?: string | null;
            postalCode: string;
            city: string;
            country: {
              __typename?: 'Country';
              id: string;
              oid: string;
              name?: string | null;
            };
          };
        } | null;
      }> | null;
    };
    communicationData: {
      __typename?: 'CommunicationDataConnection';
      edges?: Array<{
        __typename?: 'CommunicationDataEdge';
        node?: {
          __typename?: 'CommunicationData';
          value?: string | null;
          communicationDataType: {
            __typename?: 'CommunicationDataType';
            id: string;
            oid: string;
            name?: string | null;
            baseType?: Types.CommunicationDataBaseType | null;
          };
        } | null;
      }> | null;
    };
    employments: {
      __typename?: 'EmploymentConnection';
      edges?: Array<{
        __typename?: 'EmploymentEdge';
        node?: {
          __typename?: 'Employment';
          oid: string;
          organization: {
            __typename?: 'Organization';
            name?: string | null;
            addresses: {
              __typename?: 'EntityAddressConnection';
              edges?: Array<{
                __typename?: 'EntityAddressEdge';
                node?: {
                  __typename?: 'EntityAddress';
                  addressType: Types.AddressType;
                  address: {
                    __typename?: 'Address';
                    id: string;
                    oid: string;
                    name?: string | null;
                    customerReference?: string | null;
                    addressLine1?: string | null;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    postalCode: string;
                    city: string;
                    country: {
                      __typename?: 'Country';
                      id: string;
                      oid: string;
                      name?: string | null;
                    };
                  };
                } | null;
              }> | null;
            };
          };
          person: {
            __typename?: 'Person';
            id: string;
            firstName?: string | null;
            middleName?: string | null;
            lastName?: string | null;
            prefixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              name?: string | null;
              state: Types.State;
            } | null;
            postfixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              name?: string | null;
              state: Types.State;
            } | null;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetOrganizationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
  > &
    (
      | { variables: GetOrganizationByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
  >(Operations.getOrganizationById, options);
}
export function useGetOrganizationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
  >(Operations.getOrganizationById, options);
}
export function useGetOrganizationByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetOrganizationByIdQuery,
    GetOrganizationByIdQueryVariables
  >(Operations.getOrganizationById, options);
}
export type GetOrganizationByIdQueryHookResult = ReturnType<
  typeof useGetOrganizationByIdQuery
>;
export type GetOrganizationByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationByIdLazyQuery
>;
export type GetOrganizationByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationByIdSuspenseQuery
>;
export type GetOrganizationByIdQueryResult = Apollo.QueryResult<
  GetOrganizationByIdQuery,
  GetOrganizationByIdQueryVariables
>;
