import { useContext } from 'react';

import { KeycloakContext } from './keycloakContext';

export function useKeycloak() {
  const ctx = useContext(KeycloakContext);

  if (!ctx) {
    throw new Error(
      'useKeycloak hook must be used inside ReactKeycloakProvider context'
    );
  }

  if (!ctx.authClient) {
    throw new Error(
      'authClient has not been assigned to ReactKeycloakProvider'
    );
  }

  const { authClient, initialized } = ctx;

  return {
    initialized,
    keycloak: authClient,
  };
}
