import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDate } from '@/services/graphql/hooks/mutations/helpers/helper';
import { State } from '@/services/graphql/types';

import {
  AddSampleRequestFormMutation,
  AddSampleRequestFormMutationVariables,
  useAddSampleRequestFormMutation as useAddSampleRequestFormMutationApollo,
} from '../generated';

type AddSampleRequestFormVariables =
  // oid, signerId, issuerId, addressId, createdAt, activityTypeId, body, filename, items, state are required
  Pick<
    AddSampleRequestFormMutationVariables,
    'signerId' | 'addressId' | 'body' | 'filename' | 'items'
  > &
    // addressExtension, activityTypeId, deliveryTimeFrameId, deliveryDate, skipToDo are optional
    Partial<
      Pick<
        AddSampleRequestFormMutationVariables,
        | 'oid'
        | 'issuerId'
        | 'createdAt'
        | 'addressExtension'
        | 'activityTypeId'
        | 'deliveryTimeFrameId'
        | 'deliveryDate'
        | 'skipToDo'
        | 'state'
      >
    >;

/**
 * __useAddSampleRequestFormMutation__
 *
 * To run a mutation, you first call `useAddSampleRequestFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSampleRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSampleRequestFormMutation, { data, loading, error }] = useAddSampleRequestFormMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      issuerId: // defaults to the current user
 *      signerId: // oid of the person who sings the sample request form
 *      addressId: // oid of the address of the sample request form
 *      createdAt: // defaults to the current timestamp the sample request is created
 *      body: // document body for the sample request form
 *      filename: // document filename for the sample request form
 *      items: // value for the 'items' of the sample requests form
 *      state: // value for state. The current state, e.g. ACTIVE, INACTIVE, DELETED
 *
 *      // Optional
 *      activityTypeId: // oid of the type of the activity associated to the sample request form
 *      addressExtension: // value for extension of the address
 *      deliveryTimeFrameId: // Oid of the delivery time frame for the sample request
 *      deliveryDate: // value for date when the samples should be delivered
 *      skipToDo: // boolean to skip the ToDo for this sample request form.
 *   },
 * });
 */
export function useAddSampleRequestFormMutation(
  baseOptions?: MutationHookOptions<
    AddSampleRequestFormMutation,
    AddSampleRequestFormMutationVariables
  >
) {
  const { currentUser } = useCurrentUser();

  const [addSampleRequestForm, status] = useAddSampleRequestFormMutationApollo({
    // We invalidate all queries related to sample request to refresh the data
    refetchQueries: ['getSampleRequestFormDocument'],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<
      AddSampleRequestFormMutation,
      AddSampleRequestFormVariables
    >
  ) => {
    const defaultVariables = {
      oid: uuidv4(),
      issuerId: currentUser?.person?.oid ?? '',
      createdAt: buildCurrentDate(),
      state: State.ACTIVE,

      // placeholders, have to be overwritten when calling the mutate function
      signerId: '',
      addressId: '',
      activityTypeId: '',
      body: '',
      filename: '',
      items: [],
      addressExtension: '',
    };

    return addSampleRequestForm({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
}
