import { gql } from '@apollo/client';

export const getTouchpointListByDate = gql`
  query getTouchpointListByDate(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $currentUserPersonId: PersonId
  ) {
    touchpoints(
      filter: {
        _and: [
          { state: { _eq: ACTIVE } }
          { startDateTime: { _ge: $startDateTime } }
          { startDateTime: { _lt: $endDateTime } }
          {
            _or: [
              { organizer: { oid: { _eq: $currentUserPersonId } } }
              {
                coOrganizers: { person: { oid: { _eq: $currentUserPersonId } } }
              }
            ]
          }
        ]
      }
    ) {
      filteredCount
      edges {
        node {
          id
          oid
          state
          status
          startDate
          startDateTime
          endDate
          endDateTime
          confirmed
          activityType {
            id
            oid
            name
            baseType
            schedulingType
            hideTimeForClosedActivity
          }
          organization {
            id
            oid
            name
            addresses(
              filter: {
                addressType: { _eq: STANDARD }
                address: { state: { _eq: ACTIVE } }
              }
            ) {
              edges {
                node {
                  address {
                    addressLine1
                    addressLine2
                    addressLine3
                    city
                    postalCode
                  }
                }
              }
            }
            rootOrganization {
              id
              oid
              name
            }
          }
          attendees {
            person {
              id
              firstName
              lastName
              prefixAcademicTitle {
                name
                state
              }
            }
          }
        }
      }
    }
  }
`;
