import { Phone } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CommunicationDataBaseTypeDataEnum,
  CommunicationDataData,
  IconWrapper,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CopyToClipBoardButton } from '@/components/CopyToClipBoard';

type CommunicationDataCallStatusBlockProps = {
  communicationData: CommunicationDataData[];
};
export const CommunicationDataCallStatusBlock = ({
  communicationData,
}: CommunicationDataCallStatusBlockProps) => {
  const { t } = useTranslation();

  const renderTitle = (
    name: string | undefined,
    baseType: CommunicationDataBaseTypeDataEnum | undefined
  ) => {
    if (name) {
      return name;
    }

    if (baseType && baseType === CommunicationDataBaseTypeDataEnum.PHONE) {
      return t('pages.interaction.common.telephone');
    }

    return;
  };

  return (
    <Container data-testid="communication-data-call-status-block">
      {communicationData &&
        communicationData.slice(0, 2).map((comm) => {
          return (
            <CommunicationDataWrapper key={comm.oid}>
              <LineTitle data-testid="communication-data-call-status-item">
                <DisableTypography variant="caption">
                  {renderTitle(
                    comm.communicationDataType?.name,
                    comm.communicationDataType?.baseType
                  )}
                </DisableTypography>
                <ValueTypography variant="caption">
                  {comm.value}
                </ValueTypography>
              </LineTitle>
              <LineIcons>
                <a href={'tel:' + comm?.value}>
                  <StyledIconWrapper size={36}>
                    <Phone
                      fontSize="medium"
                      data-testid="icon-communication-data-call-status-phone"
                    />
                  </StyledIconWrapper>
                </a>

                <StyledIconWrapper size={36}>
                  <CopyToClipBoardButton
                    darkStyle
                    data-testid="icon-communication-data-call-status-clipboard"
                    copyValue={comm.value ?? ''}
                  />
                </StyledIconWrapper>
              </LineIcons>
            </CommunicationDataWrapper>
          );
        })}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  flex: 'fill',
  width: '300px',
  padding: `0 ${theme.spacing(1)}`,
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const CommunicationDataWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  borderBottom: '1px solid',
  paddingBottom: theme.spacing(1),

  ':last-child': {
    borderBottom: '0px',
    paddingBottom: theme.spacing(0),
  },
}));

const LineTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
}));

const LineIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const ValueTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  background: theme.palette.grey[500_48],
  cursor: 'pointer',
  display: 'flex',
  color: theme.palette.common.white,
}));
