import { gql } from '@apollo/client';

export const getPersonConsentList = gql`
  query getPersonConsentList($id: ID!) {
    person(id: $id) {
      id
      oid
      consents(filter: { consentType: { state: { _eq: ACTIVE } } }) {
        edges {
          node {
            id
            oid
            actionAt
            issuer {
              id
              firstName
              lastName
              middleName
            }
            consentItemValues {
              consentItemState
              consentItem {
                oid
                name
                description
              }
              communicationDataValues {
                consentCommunicationDataType {
                  oid
                  description
                  communicationDataType {
                    oid
                    baseType
                    name
                  }
                }
                value
              }
            }
            state
            consentType {
              id
              oid
              name
              description
              startDate
              endDate
              lifecycleStatus
              validityEndDate
              validityMode
              validityPeriodInMonths
              allowRecollection
              digitalSignatureProcessEnabled
              digitalSignatureTemplate {
                id
                oid
                document {
                  id
                  oid
                  name
                }
              }
              consentItems {
                edges {
                  node {
                    oid
                    name
                    description
                    defaultItemState
                    inputId
                    pendingPaperItemState
                    pendingPaperSwitchable
                    pendingDoubleOptInItemState
                    pendingDoubleOptInSwitchable
                    acceptedItemState
                    acceptedSwitchable
                    consentCommunicationDataTypes {
                      oid
                      inputId
                      description
                      mandatory
                      communicationDataType {
                        id
                        oid
                        baseType
                        name
                      }
                    }
                  }
                }
              }
              paperProcessEnabled
              manualProcessEnabled
              manualProcessReasons {
                oid
                doiEnabled
                reason
              }
              dataPermissions {
                type
              }
            }
            consentHistory {
              action
              issuer {
                id
                firstName
                middleName
                lastName
              }
              sequenceNumber
              createdAt
              handledAt
            }
            document {
              id
              oid
              name
            }
            lifecycleState
            recCreatedAt
            recUpdatedAt
          }
        }
      }
    }
  }
`;
