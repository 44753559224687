import { Person } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  getPersonFullNameWithTitle,
  Loader,
  PersonConsentData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UpperCaseButton } from '@/components/Button';
import { useCurrentUser, useInteraction } from '@/hooks';
import { getAttendeeStatusMessage } from '@/views/Interaction/Common/InteractionInformation/helpers';
import { CallInfoDialog } from '@/views/Interaction/Remote/Room';

import { InteractionInformationAccordionList } from './InteractionInformationAccordionList';

type InteractionInformationContentProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  isMediaViewedStatusUpdatedOnOpen: boolean;
  isActivityEditable?: boolean;
};

export const InteractionInformationContent = ({
  activity,
  isOrganizer,
  consentList,
  isMediaViewedStatusUpdatedOnOpen,
  isActivityEditable,
}: InteractionInformationContentProps) => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const { isStateServerInitialized, getParticipantInteractionStep } =
    useInteraction();

  const [isCallInfoOpen, setIsCallInfoOpen] = useState(false);
  const firstAttendee = activity?.attendees?.[0];

  const personFullName = getPersonFullNameWithTitle(firstAttendee?.person);

  const attendeeInteractionStep = firstAttendee?.person?.oid
    ? getParticipantInteractionStep(firstAttendee.person.oid)
    : null;

  const attendeeStatusMessage = getAttendeeStatusMessage(
    attendeeInteractionStep,
    personFullName,
    t
  );

  const organizerFullName = getPersonFullNameWithTitle(activity.organizer);

  const organizerInteractionStep = activity.organizer?.oid
    ? getParticipantInteractionStep(activity.organizer.oid)
    : null;

  const organizerStatusMessage = getAttendeeStatusMessage(
    organizerInteractionStep,
    organizerFullName,
    t
  );

  const organizerStatusInformation = (
    <>
      <LineBox>
        <Typography variant="caption">
          {t('pages.interaction.remote.organizer')}
        </Typography>
      </LineBox>
      <LineBox>
        <Person fontSize="small" />
        <Typography variant="subtitle2">{organizerStatusMessage}</Typography>
      </LineBox>
    </>
  );

  const coOrganizerStatusInformation = activity.coOrganizers
    ?.filter(
      (coOrganizer) => coOrganizer.person?.oid != currentUser?.person?.oid
    )
    ?.map((coOrganizer) => {
      const coOrganizerFullNames = getPersonFullNameWithTitle(
        coOrganizer.person
      );

      const coOrganizerInteractionStep = coOrganizer.person?.oid
        ? getParticipantInteractionStep(coOrganizer.person.oid)
        : null;

      const coOrganizerStatusMessage = getAttendeeStatusMessage(
        coOrganizerInteractionStep,
        coOrganizerFullNames,
        t
      );

      return (
        <LineBox key={coOrganizer?.oid ?? ''}>
          <Person fontSize="small" />
          <Typography variant="subtitle2">
            {coOrganizerStatusMessage}
          </Typography>
        </LineBox>
      );
    });

  if (!firstAttendee) {
    return <Typography>No attendee in interaction</Typography>;
  }

  return (
    <>
      <Wrapper data-testid="interaction-info-content">
        {isStateServerInitialized ? (
          <>
            <LineBox>
              <Typography variant="caption">
                {t('pages.interaction.remote.attendees')}
              </Typography>
            </LineBox>
            <LineBox>
              <Person fontSize="small" />
              <Typography variant="subtitle2">
                {attendeeStatusMessage}
              </Typography>
            </LineBox>
            {isOrganizer && (
              <UpperCaseButton
                title={t('pages.interaction.remote.roomInformation')}
                variant={'outlined'}
                onClick={() => setIsCallInfoOpen(true)}
              />
            )}
            {!isOrganizer && organizerStatusInformation}
            {!!coOrganizerStatusInformation?.length && (
              <>
                <LineBox>
                  <Typography variant="caption">
                    {t('pages.interaction.remote.coOrganizers')}
                  </Typography>
                </LineBox>
                {coOrganizerStatusInformation}
              </>
            )}
          </>
        ) : (
          <Loader />
        )}

        <InteractionInformationAccordionList
          activity={activity}
          isOrganizer={isOrganizer}
          consentList={consentList}
          isInRemoteRoom={false}
          isMediaViewedStatusUpdatedOnOpen={isMediaViewedStatusUpdatedOnOpen}
          isActivityEditable={isActivityEditable}
        />
      </Wrapper>

      <CallInfoDialog
        activity={activity}
        isOpen={isCallInfoOpen}
        closeDialog={() => setIsCallInfoOpen(false)}
      />
    </>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
}));

const LineBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
}));
