import { UserData } from '@ysura/common';

import { CurrentUserState } from '@/hooks/state/useCipState';
import { UserInfo } from '@/hooks/useAuth';
import {
  ClientPermissions,
  getClientPermission,
} from '@/services/user/permissions/ClientPermissions';
import {
  DataPermissions,
  getDataPermission,
} from '@/services/user/permissions/DataPermissions';

// All ysura users become admins, nobody else is an admin.
const determineAdminStatus = (keycloakUserData: UserInfo | undefined) => {
  return !!keycloakUserData?.isYsuraUser;
};

export const decideCurrentUserData = (
  apiUserData?: UserData,
  keycloakUserData?: UserInfo
): CurrentUserState | null => {
  if (!apiUserData && !keycloakUserData) {
    return null;
  }
  const isAdmin = determineAdminStatus(keycloakUserData);

  const permissions: ClientPermissions = getClientPermission(
    apiUserData,
    isAdmin
  );

  const dataPermissions: DataPermissions = getDataPermission(
    apiUserData,
    isAdmin
  );

  return {
    ysuraUserId: apiUserData?.id,
    email: apiUserData?.email,
    username: apiUserData?.username,
    person: apiUserData?.person,
    preferenceId: apiUserData?.preferenceId,
    isAdmin,
    permissions,
    dataPermissions,
  };
};
