import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { HTMLFile, PDFFile } from '@/components/DocumentPreview';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';
import { MediaBeingShared } from '@/hooks/useInteraction';
import { DocumentType, useGetDocument } from '@/services/api';
import { useGetDocumentByIdQuery } from '@/services/graphql/hooks';
import { parseDocumentData } from '@/services/graphql/parsers';

type MediaSharingContentProps = {
  sharedMedia: MediaBeingShared | null;
  isReadOnlyMode?: boolean;
};

export const MediaSharingContent = ({
  sharedMedia,
  isReadOnlyMode = false,
}: MediaSharingContentProps) => {
  const { t } = useTranslation();
  const isSmallScreenLandscape = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );
  const { stopSharingMedia } = useInteraction();

  const onCloseHandler = () => {
    stopSharingMedia();
  };

  const { data: documentData } = useGetDocumentByIdQuery({
    variables: {
      documentId: sharedMedia?.media?.latestVersion?.document?.id ?? '',
    },
    fetchPolicy: 'network-only',
  });

  const document = documentData?.document
    ? parseDocumentData(documentData.document)
    : null;

  const { data } = useGetDocument(document);

  return (
    <Wrapper data-testid="media-sharing-wrapper">
      {!isSmallScreenLandscape && (
        <Header>
          <Typography variant="h5">{sharedMedia?.media?.name}</Typography>

          {!isReadOnlyMode && (
            <Button variant="contained" onClick={onCloseHandler}>
              {t('components.mediaPresentationDialog.close')}
            </Button>
          )}
        </Header>
      )}

      <Content>
        {data?.mediaType === DocumentType.HTML && (
          <HTMLFile
            isInInteraction
            isFullScreenShown
            data={data.data}
            initialPage={sharedMedia?.initialPage}
            isReadOnlyMode={isReadOnlyMode}
            onClose={onCloseHandler}
          />
        )}

        {data?.mediaType === DocumentType.PDF && (
          <PDFFile
            isInInteraction
            isFullScreenShown
            data={data.data}
            fileName={sharedMedia?.media.fileName ?? ''}
            isDownloadButtonShown={false}
            mediaId={sharedMedia?.media?.id}
            initialPage={sharedMedia?.initialPage?.indexh}
            isFileSharing={true}
            isReadOnlyMode={isReadOnlyMode}
            onClose={onCloseHandler}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Stack)({
  height: '100%',
  maxHeight: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: theme.spacing(2),
  paddingBottom: 0,
  gap: theme.spacing(1),

  button: {
    textTransform: 'uppercase',
  },
}));

const Content = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto',
  flexDirection: 'column',
});
