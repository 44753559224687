import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type AddActivityMutationVariables = Types.Exact<{
  oid: Types.Scalars['ActivityId']['input'];
  organizerId: Types.Scalars['PersonId']['input'];
  organizationId: Types.Scalars['OrganizationId']['input'];
  attendees: Array<Types.ActivityAttendeeInput> | Types.ActivityAttendeeInput;
  coOrganizers:
    | Array<Types.ActivityCoOrganizerInput>
    | Types.ActivityCoOrganizerInput;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  discussedTopics:
    | Array<Types.Scalars['TopicId']['input']>
    | Types.Scalars['TopicId']['input'];
  activityTypeId: Types.Scalars['ActivityTypeId']['input'];
  requestedPromotionalMaterials:
    | Array<Types.PromotionalMaterialRequestInput>
    | Types.PromotionalMaterialRequestInput;
  state: Types.State;
  status: Types.ActivityStatus;
  media: Array<Types.ActivityShownMediaInput> | Types.ActivityShownMediaInput;
  comments: Array<Types.CommentInput> | Types.CommentInput;
  callDetails:
    | Array<Types.ActivityCallDetailInput>
    | Types.ActivityCallDetailInput;
  confirmed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AddActivityMutation = {
  __typename?: 'Mutation';
  addActivity?: { __typename?: 'Activity'; id: string; oid: string } | null;
};

export type AddActivityMutationFn = Apollo.MutationFunction<
  AddActivityMutation,
  AddActivityMutationVariables
>;

/**
 * __useAddActivityMutation__
 *
 * To run a mutation, you first call `useAddActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityMutation, { data, loading, error }] = useAddActivityMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      organizerId: // value for 'organizerId'
 *      organizationId: // value for 'organizationId'
 *      attendees: // value for 'attendees'
 *      coOrganizers: // value for 'coOrganizers'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      discussedTopics: // value for 'discussedTopics'
 *      activityTypeId: // value for 'activityTypeId'
 *      requestedPromotionalMaterials: // value for 'requestedPromotionalMaterials'
 *      state: // value for 'state'
 *      status: // value for 'status'
 *      media: // value for 'media'
 *      comments: // value for 'comments'
 *      callDetails: // value for 'callDetails'
 *      confirmed: // value for 'confirmed'
 *   },
 * });
 */
export function useAddActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddActivityMutation,
    AddActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AddActivityMutation, AddActivityMutationVariables>(
    Operations.addActivity,
    options
  );
}
export type AddActivityMutationHookResult = ReturnType<
  typeof useAddActivityMutation
>;
export type AddActivityMutationResult =
  Apollo.MutationResult<AddActivityMutation>;
export type AddActivityMutationOptions = Apollo.BaseMutationOptions<
  AddActivityMutation,
  AddActivityMutationVariables
>;
