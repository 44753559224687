import { FilterList } from '@mui/icons-material';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  InfiniteScrollingPersonCardSkeleton,
  NoItemPlaceholder,
  PersonCard,
  PersonCardSkeleton,
  PersonData,
  SearchTextField,
} from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LinkNoDecoration } from '@/components/LinkTo';
import { getPersonDetails } from '@/services/helper';

interface EmploymentPersonDataStaticBlockProps {
  isLoading?: boolean;
  total?: number;
  persons?: Array<PersonData>;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  fetchMorePersons: VoidFunction;
  hasMore: boolean;
  filtersCount?: number;
  handleFiltersChanges?: VoidFunction;
}
export const EmploymentPersonDataStaticBlock = ({
  isLoading,
  total,
  persons,
  searchQuery,
  setSearchQuery,
  fetchMorePersons,
  hasMore,
  filtersCount,
  handleFiltersChanges,
}: EmploymentPersonDataStaticBlockProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target?.value);
  };

  const handleClearSearchQuery = () => {
    setSearchQuery('');
  };

  const handleFiltersDialog = () => {
    if (handleFiltersChanges) {
      handleFiltersChanges();
    }
  };

  const totalCount = isLoading ? '' : `(${total})`;

  return (
    <ParentStack>
      {isMobile ? (
        <Wrapper>
          <DisableTypography variant="subtitle1">
            {`${t(
              'pages.organizationView.personDataCard.personData'
            )} ${totalCount}`}
          </DisableTypography>
          <ButtonWrapper variant="text" onClick={handleFiltersDialog}>
            {`${t(
              'pages.organizationView.personDataCard.filter'
            )} (${filtersCount})`}
            <FilterList />
          </ButtonWrapper>
        </Wrapper>
      ) : (
        <DisableTypography variant="subtitle1">
          {`${t(
            'pages.organizationView.personDataCard.personData'
          )} ${totalCount}`}
        </DisableTypography>
      )}
      <Stack>
        <SearchTextField
          testId="employment-search-field"
          placeholder={t('components.common.search')}
          searchQuery={searchQuery}
          clearButtonText={t('components.common.clear')}
          onClearSearchQuery={handleClearSearchQuery}
          onChangeSearchQuery={handleChangeSearchQuery}
        />
      </Stack>
      {isLoading ? (
        <SearchResultsWrapper>
          {[...Array(4).keys()].map((index) => (
            <Box key={index}>
              <PersonCardSkeleton />
            </Box>
          ))}
        </SearchResultsWrapper>
      ) : (
        <ScrollableStack id="person-search-list">
          <InfiniteScroll
            dataLength={persons?.length ?? 0}
            hasMore={hasMore}
            next={fetchMorePersons}
            scrollThreshold={0.7}
            scrollableTarget="person-search-list"
            loader={<InfiniteScrollingPersonCardSkeleton />}
          >
            <SearchResultsWrapper>
              {persons?.map((person: PersonData) => {
                const { id, personName, organizationName, addressName } =
                  getPersonDetails(person);

                return (
                  <LinkNoDecoration
                    key={id}
                    to={`/person/${id}`}
                    data-testid="person-card"
                  >
                    <PersonCard
                      personName={personName}
                      organizationName={organizationName}
                      addressName={addressName}
                    />
                  </LinkNoDecoration>
                );
              })}
            </SearchResultsWrapper>
          </InfiniteScroll>

          {!persons?.length && (
            <NoItemsWrapper>
              <NoItemPlaceholder
                subMessage={t('components.globalSearch.noResultLong')}
              >
                {t('components.globalSearch.noResult')}
              </NoItemPlaceholder>
            </NoItemsWrapper>
          )}
        </ScrollableStack>
      )}
    </ParentStack>
  );
};

const ParentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: '100%',
  overflow: 'hidden',
}));

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ButtonWrapper = styled(Button)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
  color: theme.palette.text.primary,
}));

const SearchResultsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
}));

const ScrollableStack = styled(Stack)(() => ({
  height: '100%',
  overflowY: 'auto',
}));

const NoItemsWrapper = styled(Box)({
  height: 'auto',
});
