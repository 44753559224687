import { PersonData, PromotionalMaterialGroupData } from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviewDialog } from '@/components/PreviewDialog';
import {
  compileSampleRequestFormTemplate,
  useGetSampleRequestTemplate,
} from '@/services/templates';

type PromotionalMaterialPreviewDialogProps = {
  isOpen: boolean;
  firstAttendee: PersonData;
  address: string | null;
  addressExtension: string;
  signature: string;
  promats: Array<PromotionalMaterialGroupData>;
  onClosePreviewDialog: VoidFunction;
  onSubmit: (formContent: string) => void;
  isSubmitLoading: boolean;
};

export const PromotionalMaterialPreviewDialog = ({
  isOpen,
  firstAttendee,
  address,
  addressExtension,
  promats,
  signature,
  onClosePreviewDialog,
  onSubmit,
  isSubmitLoading,
}: PromotionalMaterialPreviewDialogProps) => {
  const [compiledForm, setCompiledForm] = useState('');

  const { t } = useTranslation();

  const { isLoading: isTemplateLoading, data: templateData } =
    useGetSampleRequestTemplate();

  useEffect(() => {
    // compile form
    setCompiledForm(
      compileSampleRequestFormTemplate({
        template: templateData,
        samples: promats,
        receiver: firstAttendee,
        receiverAddress: address ?? '',
        receiverAddressExtension: addressExtension,
        signature,
      })
    );
  }, [
    address,
    addressExtension,
    firstAttendee,
    promats,
    signature,
    templateData,
  ]);

  const handleSubmitForm = () => {
    onSubmit(compiledForm);
  };

  return (
    <PreviewDialog
      isOpen={isOpen}
      title={t('pages.interaction.sampleRequestForm.previewTitle')}
      previewContent={compiledForm}
      isSubmitLoading={isSubmitLoading}
      isContentLoading={isTemplateLoading}
      onClosePreviewDialog={onClosePreviewDialog}
      onSubmit={handleSubmitForm}
    />
  );
};
