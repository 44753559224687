import { Box, Button as MuiButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ButtonWrapperProps } from '@/components/PageHeader/PageHeaderTypes';

export const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const HeaderTextWrapper = styled(Stack)(({ theme }) => ({
  rowGap: theme.spacing(1),
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

export const ButtonWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMatchedMediaQuery',
})<ButtonWrapperProps>(({ theme, isMatchedMediaQuery }) => ({
  position: isMatchedMediaQuery ? 'fixed' : 'static',
  width: isMatchedMediaQuery ? `calc(100% - ${theme.spacing(4)})` : 'auto',
  bottom: theme.spacing(2),
  zIndex: 1,
  left: isMatchedMediaQuery ? theme.spacing(2) : 0,
}));

export const Button = styled(MuiButton)`
  width: 100%;
  text-transform: uppercase;
`;
