import { Close } from '@mui/icons-material';
import {
  Box,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { KebabMenuOption, Loader } from '@ysura/common';
import { ReactNode } from 'react';

import { KebabPopover } from '@/components/PageHeader/KebabPopover';
import { DIALOG } from '@/config/layout';
import { useNotification } from '@/hooks';

import { NotificationBannerList } from '../Notification';

type ActionDialogProps = {
  isOpen: boolean;
  children: ReactNode;
  title: string;
  testId?: string;
  isLoading?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  height?: 'full' | 'medium' | 'small';
  isPrimaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  onClickPrimaryButton?: VoidFunction;
  secondaryButtonText?: string;
  onClickSecondaryButton?: VoidFunction;
  kebabMenuEntries?: Array<KebabMenuOption>;
  isCloseButtonEnable?: boolean;
  onClickCloseButton?: VoidFunction;
};

export const ActionDialog = ({
  isOpen,
  title,
  children,
  testId,
  isLoading = false,
  maxWidth = 'md',
  height = 'full',
  isPrimaryButtonDisabled = false,
  primaryButtonText,
  onClickPrimaryButton,
  secondaryButtonText,
  onClickSecondaryButton,
  kebabMenuEntries,
  isCloseButtonEnable,
  onClickCloseButton,
}: ActionDialogProps) => {
  const { bannerOptionsList, closeBanner } = useNotification();

  const handleSecondaryButtonClick = () => {
    if (onClickSecondaryButton) {
      onClickSecondaryButton();
    }
    handleCleanBanner();
  };

  const handlePrimaryButtonClick = () => {
    if (onClickPrimaryButton) {
      onClickPrimaryButton();
    }
    handleCleanBanner();
  };

  const handleCloseButtonClick = () => {
    if (onClickCloseButton) {
      onClickCloseButton();
    }
    handleCleanBanner();
  };

  const handleCleanBanner = () => {
    bannerOptionsList
      .filter((banner) => banner.displayLocation === 'dialog')
      .forEach((banner) => {
        if (banner.id) {
          closeBanner?.(banner.id);
        }
      });
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth={maxWidth}
      data-testid={testId}
      height={height === 'small' ? '50%' : height === 'medium' ? '75%' : '100%'}
    >
      <DialogHeader>
        <Title variant="h5">{title}</Title>
        <ButtonWrapper>
          {secondaryButtonText && (
            <Button
              variant="outlined"
              disabled={isLoading}
              onClick={handleSecondaryButtonClick}
            >
              {secondaryButtonText}
            </Button>
          )}
          {isCloseButtonEnable && (
            <IconButton onClick={handleCloseButtonClick}>
              <Close />
            </IconButton>
          )}
          {primaryButtonText && (
            <PrimaryButtonWrapper>
              <Button
                variant="contained"
                disabled={isPrimaryButtonDisabled || isLoading}
                onClick={handlePrimaryButtonClick}
              >
                <ActionSpinnerBox displayTextLength={primaryButtonText.length}>
                  {isLoading ? <Loader size={22} /> : primaryButtonText}
                </ActionSpinnerBox>
              </Button>
            </PrimaryButtonWrapper>
          )}

          {kebabMenuEntries && <KebabPopover menuEntries={kebabMenuEntries} />}
        </ButtonWrapper>
      </DialogHeader>
      <DialogContent>
        <NotificationBannerList displayLocation="dialog" />
        {children}

        {/* Add empty space in the bottom because there is fixed button at the bottom of dialog
            Only exist on mobile
        */}
        <EmptyBoxMobile />
      </DialogContent>
    </Dialog>
  );
};
type StyledDialogProps = {
  height: string;
};
const Dialog = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== 'height',
})<StyledDialogProps>(({ theme, height }) => ({
  '.MuiDialog-paper': {
    height,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
}));

const DialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),

  padding: theme.spacing(3),

  width: '100%',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  // we need the padding top for components like autocomplete which have inlined title
  paddingTop: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  height: '100%',
  overflow: 'hidden',
}));

const PrimaryButtonWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    zIndex: 99,
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),

    button: {
      width: '100%',
    },
  },
}));

const EmptyBoxMobile = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    width: '100%',
    height: DIALOG.MOBILE.FLOATING_BUTTON_HEIGHT,
  },
}));

const ActionSpinnerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'displayTextLength',
})<{ displayTextLength: number }>(({ displayTextLength }) => ({
  minWidth: displayTextLength * 10 + 'px',
  height: '24px',
}));
