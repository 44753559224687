import { useTranslation } from 'react-i18next';

import { ActivitySkeletonCard } from '@/components/Activity/ActivitySkeletonCard';
import { OverviewStaticBlock } from '@/components/StaticBlock';

interface ActivitySkeletonStaticBlockProps {
  title: string;
}
export const ActivitySkeletonStaticBlock = ({
  title,
}: ActivitySkeletonStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t(title)}
      testId="activity-data-header-skeleton"
    >
      <ActivitySkeletonCard />
    </OverviewStaticBlock>
  );
};
