import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPostalCodeCitiesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPostalCodeCitiesQuery = {
  __typename?: 'Query';
  postalCodeCities: {
    __typename?: 'PostalCodeCityConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'PostalCodeCityEdge';
      node?: {
        __typename?: 'PostalCodeCity';
        id: string;
        oid: string;
        postalCode: string;
        city: string;
      } | null;
    }> | null;
  };
};

/**
 * __useGetPostalCodeCitiesQuery__
 *
 * To run a query within a React component, call `useGetPostalCodeCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostalCodeCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostalCodeCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostalCodeCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPostalCodeCitiesQuery,
    GetPostalCodeCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPostalCodeCitiesQuery,
    GetPostalCodeCitiesQueryVariables
  >(Operations.getPostalCodeCities, options);
}
export function useGetPostalCodeCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPostalCodeCitiesQuery,
    GetPostalCodeCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPostalCodeCitiesQuery,
    GetPostalCodeCitiesQueryVariables
  >(Operations.getPostalCodeCities, options);
}
export function useGetPostalCodeCitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPostalCodeCitiesQuery,
    GetPostalCodeCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPostalCodeCitiesQuery,
    GetPostalCodeCitiesQueryVariables
  >(Operations.getPostalCodeCities, options);
}
export type GetPostalCodeCitiesQueryHookResult = ReturnType<
  typeof useGetPostalCodeCitiesQuery
>;
export type GetPostalCodeCitiesLazyQueryHookResult = ReturnType<
  typeof useGetPostalCodeCitiesLazyQuery
>;
export type GetPostalCodeCitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetPostalCodeCitiesSuspenseQuery
>;
export type GetPostalCodeCitiesQueryResult = Apollo.QueryResult<
  GetPostalCodeCitiesQuery,
  GetPostalCodeCitiesQueryVariables
>;
