import {
  Stack,
  TableCell,
  TableRow as MuiTableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Label, MediaData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { MediaThumbnail } from '@/components/Media/MediaThumbnail';

type ListMediaCardProps = {
  media: MediaData;
  onSelectMedia: VoidFunction;
};

export const MediaListItem = ({ media, onSelectMedia }: ListMediaCardProps) => {
  const { t } = useTranslation();

  return (
    <TableRow onClick={onSelectMedia}>
      <NameTableCell>
        <MediaThumbnail width={64} height={64} media={media} />
        <StyledStack>
          <Typography variant="subtitle2">{media.fileName}</Typography>

          <Typography variant="body2">{media.description}</Typography>
        </StyledStack>
      </NameTableCell>

      <StatusTableCell>
        <Label variant="ghost" color={media.isViewed ? 'success' : 'default'}>
          {media.isViewed
            ? t('components.mediaCard.viewed')
            : t('components.mediaCard.notViewed')}
        </Label>
      </StatusTableCell>
    </TableRow>
  );
};

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  ...theme.shape,
  border: '1px solid',
  borderColor: theme.palette.grey[500_12],
  boxShadow: theme.customShadows.card,
  cursor: 'pointer',
  '.MuiTableCell-sizeMedium:first-of-type': {
    paddingLeft: theme.spacing(2),
  },
  '&:hover td': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  flex: 1,
}));

const NameTableCell = styled(TableCell)(({ theme }) => ({
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

const StatusTableCell = styled(TableCell)(({ theme }) => ({
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  verticalAlign: 'top',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
