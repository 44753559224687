import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColumnContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const SectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const SectionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export const RequiredField = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));
