import { ActivityTypeData, DataPermissionTypeDataEnum } from '@ysura/common';
import { includes } from 'lodash';

export const hasAssignActivityTypePermission = (
  activityTypes: Array<ActivityTypeData> | undefined
): boolean => {
  return (
    activityTypes?.some((item) =>
      includes(
        item?.permissions,
        DataPermissionTypeDataEnum.ASSIGN_ACTIVITY_TYPE
      )
    ) ?? false
  );
};
