import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { VideoControlsMobileKebabPopover } from '@/components/Interaction';
import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';
import {
  ToggleCameraButton,
  ToggleMicButton,
} from '@/views/Interaction/Remote/Buttons';

type AudioVideoControlsProps = {
  handleOpenSettingsDialog: VoidFunction;
  handleStartInteraction: VoidFunction;
  handleCancelInteraction: VoidFunction;
};

export const AudioVideoControlsMobile = ({
  handleOpenSettingsDialog,
  handleStartInteraction,
  handleCancelInteraction,
}: AudioVideoControlsProps) => {
  const {
    isStateServerInitialized,
    isUserMediaCollected,
    isMicAccessDisabled,
    isCameraAccessDisabled,
  } = useInteraction();
  const { t } = useTranslation();

  const isInteractionButtonDisabled =
    (isCameraAccessDisabled && isMicAccessDisabled) ||
    !isUserMediaCollected ||
    !isStateServerInitialized;

  const renderKebabMenu = () => {
    return (
      <VideoControlsMobileKebabPopover
        menuEntries={[
          {
            entry: t('pages.interaction.remote.settings'),
            icon: <SettingsIcon />,
            onClick: handleOpenSettingsDialog,
          },
          {
            entry: t('pages.interaction.common.leaveInteraction'),
            icon: <CallEndIcon />,
            colorType: 'error',
            onClick: handleCancelInteraction,
          },
        ]}
      />
    );
  };

  return (
    <Container data-testid="audio-video-controls-mobile">
      <ButtonWrapper>
        <HideInPortraitMode>{renderKebabMenu()}</HideInPortraitMode>

        <ToggleMicButton size="medium" />
        <ToggleCameraButton size="medium" />
      </ButtonWrapper>

      <ButtonWrapper>
        <ShowInPortraitMode>{renderKebabMenu()}</ShowInPortraitMode>
        <RoundButton
          size="medium"
          variant="primary"
          disabled={isInteractionButtonDisabled}
          onClick={handleStartInteraction}
        >
          <CallIcon />
        </RoundButton>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      position: 'fixed',
      top: 0,
      left: 0,

      flexDirection: 'column-reverse',
      alignItems: 'center',

      height: '100%',
      width: REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_WIDTH_LANDSCAPE,
      padding: `${theme.spacing(2)} 0`,
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
    },
}));

const HideInPortraitMode = styled(Box)({
  display: 'none',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'block',
    },
});

const ShowInPortraitMode = styled(Box)({
  display: 'block',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
});
