import { ConsentValue, PersonConsentData, PersonData } from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviewDialog } from '@/components/PreviewDialog';
import { useCurrentUser } from '@/hooks';
import {
  compilePersonConsentFormTemplate,
  useGetPersonConsentTemplate,
} from '@/services/templates';

type ConsentPreviewDialogProps = {
  isOpen: boolean;
  consent: PersonConsentData;
  signature: string;
  receiver: PersonData;
  consentValues: ConsentValue;
  onClosePreviewDialog: VoidFunction;
  onSubmit: (formContent: string) => void;
  isSubmitLoading: boolean;
};

export const F2FConsentPreviewDialog = ({
  isOpen,
  consent,
  signature,
  receiver,
  consentValues,
  onClosePreviewDialog,
  onSubmit,
  isSubmitLoading,
}: ConsentPreviewDialogProps) => {
  const [compiledForm, setCompiledForm] = useState('');

  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const handleSubmitForm = () => {
    onSubmit(compiledForm);
  };

  // Load template document
  const { isLoading, data: template } = useGetPersonConsentTemplate(
    consent?.consentType
  );

  useEffect(() => {
    // compile form
    if (consent?.consentType) {
      setCompiledForm(
        compilePersonConsentFormTemplate({
          consentType: consent.consentType,
          signature,
          template,
          sender: currentUser?.person,
          receiver,
          consentValues,
        })
      );
    }
  }, [consent, consentValues, receiver, signature, template, currentUser]);

  return (
    <PreviewDialog
      isOpen={isOpen}
      title={t('pages.interaction.personConsent.previewTitle')}
      previewContent={compiledForm}
      isSubmitLoading={isSubmitLoading}
      isContentLoading={isLoading}
      onClosePreviewDialog={onClosePreviewDialog}
      onSubmit={handleSubmitForm}
    />
  );
};
