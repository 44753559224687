import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconWrapper, PersonData, TextHighlighter } from '@ysura/common';
import { useNavigate } from 'react-router-dom';

import {
  getPersonEmploymentAddress,
  getPersonFullName,
} from '@/services/helper';

type PersonSearchResultCardProps = {
  person: PersonData;
  searchQuery: string;
  closeDialog: VoidFunction;
};

export const PersonSearchResultCard = ({
  person,
  searchQuery,
  closeDialog,
}: PersonSearchResultCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const searchQueryArray = searchQuery.split(' ');

  const fullName = getPersonFullName(person);
  const firstEmployment = person.employments?.[0];
  const organizationName = firstEmployment?.organization?.name;
  const address = firstEmployment
    ? getPersonEmploymentAddress(firstEmployment)
    : null;

  const navigateToPersonPage = () => {
    closeDialog();
    navigate(`/person/${person?.id}`);
  };

  return (
    <Wrapper
      data-testid="person-search-result-card"
      onClick={navigateToPersonPage}
    >
      <Row>
        <IconWrapper backgroundColor={theme.palette.info.main} size={20}>
          <IconPerson />
        </IconWrapper>
        <Name noWrap variant="subtitle2">
          {fullName ? (
            <TextHighlighter
              testId="fullname-text-highlighter"
              searchQueryArray={searchQueryArray}
              text={fullName}
            />
          ) : (
            'N/A'
          )}
        </Name>
      </Row>

      <RowWrapper>
        <Row>
          <IconWrapper size={20}>
            <IconCompany />
          </IconWrapper>
          <Name noWrap variant="caption">
            {organizationName ? (
              <TextHighlighter
                testId="organization-name-text-highlighter"
                searchQueryArray={searchQueryArray}
                text={organizationName}
              />
            ) : (
              'N/A'
            )}
          </Name>
        </Row>

        <Row>
          <IconWrapper size={20}>
            <IconAddress />
          </IconWrapper>
          <Address noWrap variant="caption">
            {address ? (
              <TextHighlighter
                testId="address-text-highlighter"
                searchQueryArray={searchQueryArray}
                text={address}
              />
            ) : (
              'N/A'
            )}
          </Address>
        </Row>
      </RowWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),

  padding: theme.spacing(1),
  width: '100%',

  cursor: 'pointer',
}));

const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.25),
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const Address = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const IconPerson = styled(PersonIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  height: 12,
  width: 12,
}));

const IconCompany = styled(LocalHospitalIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: 16,
  width: 16,
}));

const IconAddress = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  height: 16,
  width: 16,
}));
