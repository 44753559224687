import { styled } from '@mui/material/styles';
import {
  PersonData,
  PromotionalMaterialGroupData,
  TemplateData,
} from '@ysura/common';
import { useEffect, useRef } from 'react';

import { compileSampleRequestFormTemplate } from '@/services/templates';

type ProMatCollectionPreviewProps = {
  template: TemplateData | null;
  promotionalMaterialGroups: PromotionalMaterialGroupData[];
  receiver?: PersonData;
  receiverAddress: string;
  receiverAddressExtension: string;
  signature: string;
};

export const ProMatCollectionPreview = ({
  template,
  promotionalMaterialGroups,
  receiver,
  receiverAddress,
  receiverAddressExtension,
  signature,
}: ProMatCollectionPreviewProps) => {
  const proMatRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = proMatRef.current;

    const iframeDocument = iframe?.contentWindow?.document;
    if (iframeDocument) {
      const html = compileSampleRequestFormTemplate({
        template: template,
        samples: promotionalMaterialGroups,
        receiver: receiver,
        receiverAddress: receiverAddress ?? '',
        receiverAddressExtension: receiverAddressExtension,
        signature,
      });

      iframeDocument?.open();
      iframeDocument?.write(html);
      iframeDocument?.close();
    }
  }, [
    promotionalMaterialGroups,
    receiver,
    receiverAddress,
    receiverAddressExtension,
    signature,
    template,
  ]);

  return (
    <ProMatHTML ref={proMatRef} id="form-frame" data-testid="promat-form" />
  );
};

const ProMatHTML = styled('iframe')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: 'none',
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
  paddingTop: 0,
}));
