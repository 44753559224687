import { gql } from '@apollo/client';

export const getActivityTypePromotionalMaterials = gql`
  query getActivityTypePromotionalMaterials(
    $oid: ActivityTypeId!
    $states: [State!]
  ) {
    promotionalMaterials(
      filter: { state: { _in: $states }, activityTypes: { oid: { _eq: $oid } } }
    ) {
      edges {
        node {
          id
          oid
          signatureRequired
          topics {
            edges {
              node {
                id
                oid
              }
            }
          }
          activityTypes(filter: { activityType: { oid: { _eq: $oid } } }) {
            activityType {
              id
              oid
            }
            stockTypes
          }
        }
      }
    }
  }
`;
