import { useEffect } from 'react';

import { useInteraction } from './';

export const useAudioVideoSettings = () => {
  const {
    setSelectedAudioInput,
    setSelectedVideoInput,
    setIsMicAccessDisabled,
    setIsCameraAccessDisabled,
    selectedVideoInput,
    selectedAudioInput,
    isCameraAccessDisabled,
    isMicAccessDisabled,
    setAudioDevices,
    setVideoDevices,
    isUserMediaCollected,
    setIsUserMediaCollected,
  } = useInteraction();

  const shouldEnumerateDevices =
    isUserMediaCollected && (!isCameraAccessDisabled || !isMicAccessDisabled);

  // STEP 1: get the hardware access from the user
  useEffect(() => {
    if (!isUserMediaCollected) {
      navigator.mediaDevices
        ?.getUserMedia({ audio: true })
        .then(() => {
          setIsMicAccessDisabled?.(false);
        })
        .catch(() => {
          console.log('mic permission denied');
          setIsMicAccessDisabled?.(true);
        });

      navigator.mediaDevices
        ?.getUserMedia({
          video: true,
        })
        .then(() => {
          setIsCameraAccessDisabled?.(false);
          setIsUserMediaCollected?.(true);
        })
        .catch(() => {
          console.log('camera permission denied');
          setIsCameraAccessDisabled?.(true);
          setIsUserMediaCollected?.(true);
        });
    }
  }, [
    isUserMediaCollected,
    setIsCameraAccessDisabled,
    setIsMicAccessDisabled,
    setIsUserMediaCollected,
  ]);

  // STEP 2: query media devices AFTER the user gave the permission
  useEffect(() => {
    if (shouldEnumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices = []) => {
        const videoInputList = devices.filter(
          (device) => device?.kind === 'videoinput'
        );
        const audioInputList = devices.filter(
          (device) => device?.kind === 'audioinput'
        );

        // TODO: handle the case if there is no video or audio hardware available at all
        // TODO: we should handle audiooutput cases too (e.g. listening to audio via headphones)
        setVideoDevices?.(videoInputList);
        setAudioDevices?.(audioInputList);

        const videoIdList = videoInputList.map((input) => input.deviceId);
        const audioIdList = audioInputList.map((input) => input.deviceId);

        /**
         * if videoInput was not selected or if selected video input is currently not
         * available (e.g. an USB camera was unplugged), we should set a new value
         * for the selectedVideoInput. Same rule applies for audioInput.
         */
        if (!selectedVideoInput || !videoIdList.includes(selectedVideoInput)) {
          setSelectedVideoInput?.(videoInputList?.[0]?.deviceId ?? '');
        }

        if (!selectedAudioInput || !audioIdList.includes(selectedAudioInput)) {
          setSelectedAudioInput?.(audioInputList?.[0]?.deviceId ?? '');
        }
      });
    }
    // TODO: can we check hardware change events (e.g. adding a new camera duing the call/waiting room)?
    // TODO: idea >> when this component is mounted, set an interval to check for the mediaDevices each n seconds
  }, [
    selectedAudioInput,
    selectedVideoInput,
    setAudioDevices,
    setSelectedAudioInput,
    setSelectedVideoInput,
    setVideoDevices,
    shouldEnumerateDevices,
  ]);
};
