import MuiSearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Grid as MuiGrid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  PromotionalMaterialGroupData,
  SampleRequestFormData,
} from '@ysura/common';
import { ChangeEvent, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersonPromotionalMaterials } from '@/hooks';

import { PromotionalMaterialAccordion } from './PromotionalMaterialAccordion';
import { PromotionalMaterialTabPanels } from './PromotionalMaterialTabPanels';
import { PromotionalMaterialTabs } from './PromotionalMaterialTabs';
import { SelectedItemList } from './SelectedItemList';

type PromotionalMaterialExpandDialogContentProps = {
  activity: ActivityData;
  isRestrictedEdit?: boolean;
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
  signedSampleRequestForm?: SampleRequestFormData;
};

/**
 * Currently, this is used in UpdateActivity and in the F2F interaction.
 * When we use this in the Remote interaction, we will need to move it to
 * commons.
 */
export const PromotionalMaterialEditDialog = ({
  activity,
  isRestrictedEdit = false,
  promotionalMaterialGroups,
  onChangePromats,
  signedSampleRequestForm,
}: PromotionalMaterialExpandDialogContentProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const { t } = useTranslation();
  const theme = useTheme();
  // TODO: should remove this and implement a CSS solution below
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    isLoading,
    availablePromotionalMaterials,
    selectedPromotionalMaterials,
  } = usePersonPromotionalMaterials(activity, promotionalMaterialGroups);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const isSigned =
    !!activity?.sampleRequestFormDocument || !!signedSampleRequestForm;

  const filteredPromats = useMemo(
    () =>
      // We need the ? here even ts does not throw an error because we mock test for the case no data is returned
      availablePromotionalMaterials?.filter((promat) => {
        const query = searchQuery?.toLowerCase() ?? '';

        const isPromatNameMatched = promat.promotionalMaterial?.name
          ? promat.promotionalMaterial?.name.toLowerCase().indexOf(query) > -1
          : false;

        // return early, as there is a match based on the promat name
        if (isPromatNameMatched) {
          return true;
        }

        // Return if batch name is matched
        return promat.batches && promat.batches.length
          ? promat.batches.findIndex((batch) => batch.name?.includes(query)) >
              -1
          : false;
      }),
    [availablePromotionalMaterials, searchQuery]
  );

  const handleClearSearchQuery = () => {
    setSearchQuery('');
  };

  return (
    <Container data-testid="promotionalMaterial-edit-dialog">
      {isMobile ? (
        <>
          <PromotionalMaterialAccordion
            title={t('pages.interaction.expand.selectedItems')}
          >
            <SelectedItemList
              selectedPromotionalMaterials={selectedPromotionalMaterials}
              isSigned={isSigned}
              onChangePromats={onChangePromats}
            />
          </PromotionalMaterialAccordion>

          <PromotionalMaterialAccordion
            defaultExpanded
            title={t('pages.interaction.expand.allPromotionalMaterial')}
          >
            <Wrapper>
              <PromotionalMaterialTabs
                tabIndex={tabIndex}
                handleTabChange={handleTabChange}
                promats={filteredPromats}
              />

              <TextField
                fullWidth
                size="small"
                placeholder={`${t('pages.interaction.expand.search')}...`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={handleSearch}
              />

              <PromotionalMaterialTabPanels
                tabIndex={tabIndex}
                isLoading={isLoading}
                isFiltered={!!searchQuery}
                isSigned={isSigned}
                isRestrictedEdit={isRestrictedEdit}
                promotionalMaterialGroups={filteredPromats}
                onChangePromats={onChangePromats}
              />
            </Wrapper>
          </PromotionalMaterialAccordion>
        </>
      ) : (
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={4}>
            <Wrapper>
              <TitleContainer>
                <Title variant="subtitle2">
                  {t('pages.interaction.expand.allPromotionalMaterial')}
                </Title>

                <PromotionalMaterialTabs
                  tabIndex={tabIndex}
                  isLoading={isLoading}
                  handleTabChange={handleTabChange}
                  promats={filteredPromats}
                />
              </TitleContainer>

              <PanelsWrapper>
                <TextField
                  fullWidth
                  size="small"
                  placeholder={`${t('pages.interaction.expand.search')}...`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: searchQuery && (
                      <InputAdornment position="end">
                        <ClearButton
                          variant="text"
                          onClick={handleClearSearchQuery}
                        >
                          {t('pages.interaction.expand.clear')}
                        </ClearButton>
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <PromotionalMaterialTabPanels
                  tabIndex={tabIndex}
                  isLoading={isLoading}
                  isFiltered={!!searchQuery}
                  isSigned={isSigned}
                  isRestrictedEdit={isRestrictedEdit}
                  promotionalMaterialGroups={filteredPromats}
                  onChangePromats={onChangePromats}
                />
              </PanelsWrapper>
            </Wrapper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Wrapper>
              <Title variant="subtitle2">
                {t('pages.interaction.expand.selectedItems')}
              </Title>

              <SelectedItemList
                isRestrictedEdit={isRestrictedEdit}
                selectedPromotionalMaterials={selectedPromotionalMaterials}
                isSigned={isSigned}
                isLoading={isLoading}
                onChangePromats={onChangePromats}
              />
            </Wrapper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  padding: 0,
  height: '100%',

  [theme.breakpoints.down('md')]: {
    overflowY: 'auto',
  },
}));

const Grid = styled(MuiGrid)({
  overflowY: 'auto',
  height: '100%',
});

const Wrapper = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const PanelsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
}));

const SearchIcon = styled(MuiSearchIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const ClearButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
}));
