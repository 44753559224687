import {
  ActivityData,
  ActivityStatusDataEnum,
  NoItemPlaceholder,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';
import { useInteraction } from '@/hooks';

import { PromatButtons } from './PromatButtons';
import { PromotionalMaterialCard } from './PromotionalMaterialCard';

type PromotionalMaterialAccordionProps = {
  activity: ActivityData | null;
  isRemoteInteraction?: boolean;
};

export const PromotionalMaterialAccordionBlock = ({
  activity,
  isRemoteInteraction,
}: PromotionalMaterialAccordionProps) => {
  const { t } = useTranslation();

  const {
    interactionStep,
    promotionalMaterialGroups,
    setPromotionalMaterialGroups,
  } = useInteraction();

  if (!activity) {
    return (
      <OverviewAccordionBlock
        title={t('pages.interaction.common.promatColumnTitle')}
      >
        <NoItemPlaceholder>
          {t('pages.interaction.common.noPromatPlanned')}
        </NoItemPlaceholder>
      </OverviewAccordionBlock>
    );
  }

  const firstAttendee = activity.attendees?.[0]?.person;

  return (
    <OverviewAccordionBlock
      title={t('pages.interaction.common.promatColumnTitle')}
      numberOfItems={promotionalMaterialGroups.length}
    >
      <>
        {activity && promotionalMaterialGroups.length === 0 && (
          <NoItemPlaceholder>
            {t('pages.interaction.common.noPromatPlanned')}
          </NoItemPlaceholder>
        )}

        {promotionalMaterialGroups.map((material, index) => (
          <PromotionalMaterialCard
            key={material.promotionalMaterial?.id}
            promotionalMaterial={material}
            isSigned={!!activity?.sampleRequestFormDocument}
            isDoneTouchpoint={
              activity?.status === ActivityStatusDataEnum.CLOSED
            }
            isLastItem={index === promotionalMaterialGroups.length - 1}
          />
        ))}

        {firstAttendee && setPromotionalMaterialGroups && (
          <PromatButtons
            isAllowedToSign={interactionStep === 'interaction'}
            isAllowToViewSignedForm={
              interactionStep === 'interaction' || interactionStep === 'summary'
            }
            isRemoteInteraction={isRemoteInteraction}
            activity={activity}
            promats={promotionalMaterialGroups}
            onChangePromats={setPromotionalMaterialGroups}
          />
        )}
      </>
    </OverviewAccordionBlock>
  );
};
