import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { GLOBAL_SEARCH } from '@/config/layout';

type DesktopContentSearchCardProps = {
  filterCard: ReactNode;
  onClickFilterCard: VoidFunction;
  filteredCount: number;
  counterSelectedItems: number;
  children: ReactNode;
  testId?: string;
};

export const DesktopContentSearchCard = ({
  filterCard,
  filteredCount,
  counterSelectedItems,
  onClickFilterCard,
  children,
  testId = 'default-content-search-tab',
}: DesktopContentSearchCardProps) => {
  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ContentWrapper data-testid={testId}>
      <FilterAreaContainer>{filterCard}</FilterAreaContainer>
      <ResultAreaContainer>
        <HeaderWrapper>
          <SearchResultBox>
            <Text variant="subtitle2">
              {t('components.globalSearch.searchResults')}
            </Text>
            <Text variant="body2">{`(${
              filteredCount !== -1 ? filteredCount : '...'
            })`}</Text>
          </SearchResultBox>
          <FilterButton
            variant={isSmallScreen ? 'text' : 'outlined'}
            size="small"
            color="inherit"
            endIcon={
              isSmallScreen ? <FilterListIcon fontSize="small" /> : undefined
            }
            onClick={onClickFilterCard}
          >
            {`${t(
              'components.globalSearch.filter.filter'
            )} (${counterSelectedItems})`}
          </FilterButton>
        </HeaderWrapper>

        {children}
      </ResultAreaContainer>
    </ContentWrapper>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  width: '100%',
  height: '100%',
  minHeight: '80vh',
}));

const FilterAreaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  maxHeight: GLOBAL_SEARCH.DIALOG.DESKTOP.MAX_HEIGHT,
  height: 'fit-content',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ResultAreaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: GLOBAL_SEARCH.DIALOG.DESKTOP.RESULT_AREA_WIDTH,
  height: GLOBAL_SEARCH.DIALOG.DESKTOP.MAX_HEIGHT,
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  justifyContent: 'space-between',
}));

const SearchResultBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const FilterButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  flex: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));
