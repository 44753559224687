import { DialogContent, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  paddingBottom: 0,
}));

export const TinyStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
