import { AttendeeData, TouchpointData } from '@ysura/common';

import {
  parseActivityTypeData,
  parseOrganizationData,
  parsePersonData,
} from '@/services/graphql/parsers';
import {
  convertedActivityStatus,
  convertedState,
} from '@/services/graphql/parsers/utils';
import {
  Touchpoint,
  TouchpointAttendee,
  TouchpointConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseTouchpointData = (
  touchpoint: DeepPartial<Touchpoint>
): TouchpointData => {
  const attendees = parseAttendeesData(touchpoint?.attendees);

  const organization = touchpoint?.organization
    ? parseOrganizationData(touchpoint.organization)
    : undefined;
  const organizer = touchpoint?.organizer
    ? parsePersonData(touchpoint.organizer)
    : undefined;

  return {
    id: touchpoint?.id,
    oid: touchpoint?.oid,
    state: convertedState(touchpoint?.state),
    status: convertedActivityStatus(touchpoint?.status),
    activityType: touchpoint?.activityType
      ? parseActivityTypeData(touchpoint.activityType)
      : undefined,
    startDate: touchpoint?.startDate,
    endDate: touchpoint?.endDate,
    startDateTime: touchpoint?.startDateTime,
    endDateTime: touchpoint?.endDateTime,
    confirmed: touchpoint?.confirmed,
    organization,
    organizer,
    attendees,
  };
};

const parseAttendeesData = (
  attendees?: DeepPartial<TouchpointAttendee[]>
): AttendeeData[] => {
  return filterNonNull(attendees).map((attendee) => {
    return {
      person: attendee?.person ? parsePersonData(attendee.person) : undefined,
      isGuest: attendee.isGuest ?? false,
      guestName: attendee?.guestName,
    };
  });
};

export const parseTouchpointConnection = (
  touchpointConnection?: DeepPartial<TouchpointConnection>
): Array<TouchpointData> => {
  const rawTouchPoints = touchpointConnection?.edges ?? [];
  const mappedTouchPoints = rawTouchPoints.map((edge) => edge?.node ?? null);

  return filterNonNull(mappedTouchPoints).map((act: DeepPartial<Touchpoint>) =>
    parseTouchpointData(act)
  );
};
