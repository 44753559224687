import {
  ConsentTypeData,
  ConsentValue,
  getConsentInputName,
  PersonData,
  TemplateData,
} from '@ysura/common';
import Handlebars from 'handlebars';

import {
  getConsentTypePlaceholders,
  getDatePlaceholders,
  getReceiverPlaceholders,
  getSenderPlaceholders,
} from '@/services/templates/placeholderService';

type CompilePersonFormTemplateInput = {
  template: TemplateData | null;
  consentType: ConsentTypeData | undefined;
  sender: PersonData | undefined;
  receiver: PersonData | undefined;
  consentValues: ConsentValue;
  signature: string;
};

export const compilePersonConsentFormTemplate = ({
  template,
  consentType,
  sender,
  receiver,
  consentValues,
  signature,
}: CompilePersonFormTemplateInput): string => {
  if (!template?.document?.content || !consentType) {
    return '';
  }

  // First, we replace handlebar placeholders
  const compileTemplate = Handlebars.compile(template.document.content);

  const data = {
    ...getDatePlaceholders(),
    ...getReceiverPlaceholders(receiver),
    ...getSenderPlaceholders(sender),
    ...getConsentTypePlaceholders(consentType),
  };

  const compiledTemplate = compileTemplate(data);

  // Second, we replace the form input fields with the collected values.
  return replaceInputFields(
    compiledTemplate,
    filterUncheckeckedConsentItems(consentValues, consentType),
    signature
  );
};

const filterUncheckeckedConsentItems = (
  consentValues: ConsentValue,
  consentType: ConsentTypeData
): ConsentValue => {
  const filteredConsentValues: ConsentValue = consentValues;

  consentType?.consentItems?.forEach((item) => {
    if (item.inputId && consentValues[item.inputId] !== true) {
      item.consentInputFields?.forEach((inputField) => {
        const consentInputName = getConsentInputName(item, inputField);

        filteredConsentValues[consentInputName] = '';
      });
    }
  });

  return filteredConsentValues;
};

const replaceInputFields = (
  form: string,
  consentValues: ConsentValue,
  signature: string
): string => {
  const domParser = new DOMParser();
  const consentDOM = domParser.parseFromString(form, 'text/html');

  // Consent values
  if (consentValues) {
    Object.entries(consentValues).forEach(([key, value]) => {
      // Find the element that has the consent value's ID
      const consentElement = consentDOM.getElementById(key);

      if (!consentElement) {
        return;
      }

      // Add the value as a child
      // valueTypes can be BOOLEAN or the communication data base types (EMAIL, PHONE, etc.)
      const valueType = consentElement.getAttribute('ys-template-collect');

      if (valueType !== 'BOOLEAN') {
        if (typeof value === 'string') {
          const valueNode = consentDOM.createTextNode(value);
          consentElement.appendChild(valueNode);
        }
      } else {
        if (typeof value === 'boolean') {
          const isChecked: boolean = value;
          const inputId = `${key}-value`;

          // the label
          const labelNode = consentDOM.createElement('label');
          labelNode.setAttribute('for', inputId);
          labelNode.setAttribute(
            'style',
            'padding: .3em 1em; display: inline-block;'
          );

          // the checkbox
          const inputNode = consentDOM.createElement('input');
          inputNode.setAttribute('id', inputId);
          inputNode.setAttribute('type', 'checkbox');
          inputNode.setAttribute('value', isChecked ? 'true' : 'false');
          inputNode.setAttribute('disabled', 'disabled');
          if (isChecked) {
            inputNode.setAttribute('checked', 'checked');
          }

          // The label text
          const labelText = consentElement.getAttribute('yes');
          const valueNode = consentDOM.createTextNode(labelText ?? '');

          labelNode.appendChild(inputNode);
          labelNode.appendChild(valueNode);
          consentElement.appendChild(labelNode);
        }
      }
    });
  }

  // Signature
  const signatureNode = consentDOM.getElementById('signature');
  if (signatureNode && signature) {
    signatureNode.setAttribute('src', signature);
  }

  // Convert DOM back to string
  const html = consentDOM.getElementsByTagName('html').item(0);

  return html?.outerHTML ?? form;
};
