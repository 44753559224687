import {
  ActivityCoOrganizerData,
  ActivityData,
  AttendeeData,
  CallDetailData,
  CommentData,
  DocumentData,
  GivenPromotionalMaterialData,
  MediaData,
  OrganizationalUnitData,
  OrganizationData,
  PersonData,
  TopicData,
} from '@ysura/common';

import {
  parseActivityCoOrganizerConnection,
  parseActivityTypeData,
  parseAttendeeConnection,
  parseCallDetailConnection,
  parseCommentConnection,
  parseDocumentData,
  parseGivenPromotionalMaterialConnection,
  parseOrganizationalUnitData,
  parseOrganizationData,
  parsePersonData,
  parseShownMediaConnection,
  parseTopicConnection,
} from '@/services/graphql/parsers';
import {
  convertedActivityStatus,
  convertedState,
} from '@/services/graphql/parsers/utils';
import { Activity, ActivityConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseActivityData = (
  activity: DeepPartial<Activity>
): ActivityData => {
  const attendees: AttendeeData[] = parseAttendeeConnection(
    activity?.attendees
  );

  const media: MediaData[] = parseShownMediaConnection(activity?.media);
  const topics: TopicData[] | undefined = activity?.topics
    ? parseTopicConnection(activity.topics)
    : undefined;
  const comments: CommentData[] = parseCommentConnection(activity?.comments);
  const promotionalMaterials: GivenPromotionalMaterialData[] =
    activity?.promotionalMaterials
      ? parseGivenPromotionalMaterialConnection(activity.promotionalMaterials)
      : [];
  const organization: OrganizationData | undefined = activity?.organization
    ? parseOrganizationData(activity.organization)
    : undefined;
  const organizer: PersonData | undefined = activity?.organizer
    ? parsePersonData(activity.organizer)
    : undefined;
  const coOrganizers: ActivityCoOrganizerData[] =
    parseActivityCoOrganizerConnection(activity?.coOrganizers);

  const organizationalUnit: OrganizationalUnitData | undefined =
    activity?.organizationalUnit
      ? parseOrganizationalUnitData(activity.organizationalUnit)
      : undefined;

  const callDetails: CallDetailData[] = activity?.callDetails
    ? parseCallDetailConnection(activity.callDetails)
    : [];

  const sampleRequestFormDocument: DocumentData | undefined = activity
    ?.sampleRequestForm?.document
    ? parseDocumentData(activity?.sampleRequestForm?.document)
    : undefined;

  return {
    id: activity?.id,
    oid: activity?.oid,
    state: convertedState(activity?.state),
    status: convertedActivityStatus(activity.status),
    activityType: activity?.activityType
      ? parseActivityTypeData(activity.activityType)
      : undefined,
    startDate: activity?.startDate,
    endDate: activity?.endDate,
    startDateTime: activity?.startDateTime,
    endDateTime: activity?.endDateTime,
    organization,
    organizationalUnit,
    organizer,
    coOrganizers,
    attendees,
    promotionalMaterials,
    media,
    topics,
    comments,
    sampleRequestFormDocument,
    callDetails,
    confirmed: activity?.confirmed,
    sealed: activity?.sealed,
  };
};

export const parseActivitiesData = (
  activities: DeepPartial<Activity>[]
): Array<ActivityData> => {
  const parsedActivities = activities.map((act) =>
    act ? parseActivityData(act) : null
  );

  return filterNonNull(parsedActivities);
};

export const parseActivityConnection = (
  activityConnection: DeepPartial<ActivityConnection>
): Array<ActivityData> => {
  const rawActivities = activityConnection?.edges ?? [];
  const mappedActivities = rawActivities.map((edge) => edge?.node ?? null);

  return parseActivitiesData(filterNonNull(mappedActivities));
};
