import {
  ActivityData,
  CommentData,
  getPersonFullNameWithTitle,
  MediaData,
  PersonConsentData,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CommentsStaticBlock } from '@/components/Comment';
import { InteractionDetailPageLayout } from '@/components/InteractionDetailPageLayout';
import { PageHeader } from '@/components/PageHeader';
import { useCurrentUser, useInteraction } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import {
  prepareCommentsForMutation,
  useUpdateActivityMutation,
} from '@/services/graphql/hooks';
import { record2Array } from '@/utils/arrays';
import { checkIfPromatExists } from '@/views/Interaction/helpers';
import {
  ConsentStaticBlock,
  InteractionKeyMessagesStaticBlock,
  MediaStaticBlock,
  PromotionalMaterialStaticBlock,
  TopicsStaticBlock,
} from '@/views/Interaction/Overview';

import { PlaceholderStaticBlock } from './PlaceholderStaticBlock';

type InteractionSummaryProps = {
  activity: ActivityData;
  consentList: PersonConsentData[];
  canEditActivity?: boolean;
};

const hasAnyMediaKeyMessages = (mediaList: Array<MediaData>): boolean => {
  let result = false;

  mediaList.forEach((media) => {
    if (Array.isArray(media.keyMessages) && media.keyMessages.length > 0) {
      result = true;
    }
  });

  return result;
};

export const InteractionSummary = ({
  activity,
  consentList,
  canEditActivity,
}: InteractionSummaryProps) => {
  const { t } = useTranslation();
  const { comments, viewedMedia, collectedConsent, topics, leaveInteraction } =
    useInteraction();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [updateActivityMutation, { loading: isUpdateActivityMutationLoading }] =
    useUpdateActivityMutation();

  const closeTouchpointHandler = () => {
    leaveInteraction();
    navigate('/');
  };

  const handleSaveComments = (
    comments: CommentData[],
    closeDialog: VoidFunction
  ) => {
    const activityId = activity.oid ?? '';
    updateActivityMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: activityId,
        actions: {
          comments: {
            value: prepareCommentsForMutation(comments, activityId),
          },
        },
      },
      onCompleted: () => {
        closeDialog();
      },
    });
  };

  const firstAttendee = activity?.attendees?.[0]?.person;

  const collectedConsentOids = collectedConsent.map(
    (collectedConsent) => collectedConsent.id
  );

  const consentsCollectedDuringInteraction = consentList.filter(
    (consent) => consent?.oid && collectedConsentOids.includes(consent.oid)
  );

  const shownMedia = record2Array(viewedMedia).map(
    (viewedMedia) => viewedMedia.media
  );
  const isPromatAvailable = checkIfPromatExists(activity);
  const pageTitle = t('pages.interaction.summary.title');
  const personTitle = getPersonFullNameWithTitle(firstAttendee);
  const fullTitle = `${pageTitle}${personTitle ? ` - ${personTitle}` : ''}`;

  return (
    <InteractionDetailPageLayout
      header={
        <PageHeader
          title={fullTitle}
          buttonText={t('pages.interaction.summary.close')}
          buttonHandler={closeTouchpointHandler}
          titleVariant="dark"
        />
      }
      firstColumn={
        <>
          {topics?.length ? (
            <TopicsStaticBlock topics={topics} />
          ) : (
            <PlaceholderStaticBlock
              title={t('pages.interaction.common.topicColumnTitle')}
              text={t('pages.interaction.summary.placeholders.topics')}
            />
          )}
          {shownMedia?.length ? (
            <MediaStaticBlock
              media={shownMedia}
              isInteractionDone={true}
              isReactionEditable={true}
              isDisplayViewedLabel={false}
            />
          ) : (
            <PlaceholderStaticBlock
              title={t('pages.interaction.common.mediaColumnTitle')}
              text={t('pages.interaction.summary.placeholders.media')}
            />
          )}
          {hasAnyMediaKeyMessages(shownMedia) ? (
            <InteractionKeyMessagesStaticBlock
              shownMedia={shownMedia}
              isInteractionDone={true}
              isReactionEditable={true}
            />
          ) : (
            <PlaceholderStaticBlock
              title={t('pages.interaction.common.keymessageColumnTitle')}
              text={t('pages.interaction.summary.placeholders.keymessages')}
            />
          )}
        </>
      }
      middleColumn={
        <>
          {consentsCollectedDuringInteraction.length > 0 ? (
            firstAttendee && (
              <ConsentStaticBlock
                consentList={consentsCollectedDuringInteraction}
                person={firstAttendee}
              />
            )
          ) : (
            <PlaceholderStaticBlock
              title={t('pages.interaction.common.consentColumnTitle')}
              text={t('pages.interaction.summary.placeholders.consent')}
            />
          )}
          {currentUser?.permissions?.activity?.canReadActivityComment && (
            <CommentsStaticBlock
              canEditActivity={canEditActivity}
              commentTypes={activity.activityType?.commentTypes}
              sourceComments={comments}
              status={activity.status}
              save={handleSaveComments}
              isLoading={isUpdateActivityMutationLoading}
            />
          )}
        </>
      }
      lastColumn={
        currentUser?.permissions?.person?.canReadSampleDrop ? (
          isPromatAvailable ? (
            <PromotionalMaterialStaticBlock activity={activity} />
          ) : (
            <PlaceholderStaticBlock
              title={t('pages.interaction.common.promatColumnTitle')}
              text={t('pages.interaction.summary.placeholders.promats')}
            />
          )
        ) : null
      }
    />
  );
};
