import { CallDetailData } from '@ysura/common';

import { convertedCallType } from '@/services/graphql/parsers/utils';
import {
  ActivityCallDetail,
  ActivityCallDetailConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseCallDetailData = (
  callDetail: DeepPartial<ActivityCallDetail>
): CallDetailData => {
  return {
    oid: callDetail?.oid,
    customerReference: callDetail?.customerReference,
    startDateTime: callDetail?.startDateTime,
    endDateTime: callDetail?.endDateTime,
    callerPhoneNumber: callDetail?.callerPhoneNumber,
    calleePhoneNumber: callDetail?.calleePhoneNumber,
    callType: convertedCallType(callDetail?.callType),
    callOutcomeOid: callDetail?.callOutcome?.oid,
  };
};

export const parseCallDetailConnection = (
  callDetailConnection: DeepPartial<ActivityCallDetailConnection> | undefined
): Array<CallDetailData> => {
  const rawDetails = callDetailConnection?.edges ?? [];
  const mappedDetails = rawDetails
    .map((edge) => edge?.node ?? null)
    .map((callDetail) => (callDetail ? parseCallDetailData(callDetail) : null));

  return filterNonNull(mappedDetails);
};
