import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export const NoHardwareAccess = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Message variant="h4">
        {t('pages.interaction.audioVideoSettings.allowHardware')}
      </Message>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  background: theme.palette.grey[700],
  borderRadius: Number(theme.shape.borderRadius) * 2,
  padding: theme.spacing(2),
}));

const Message = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[0],
  textAlign: 'center',
}));
