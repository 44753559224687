import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

type PageNumberControllerProps = {
  currentPage: number;
  totalPages: number;
  variant: 'h4' | 'h6' | 'subtitle2';
};

export const PageNumberController = ({
  currentPage,
  totalPages,
  variant,
}: PageNumberControllerProps) => {
  return (
    <PageTracker variant={variant} isHiddenText={totalPages === 0}>
      {currentPage}/{totalPages}
    </PageTracker>
  );
};

type StyledTypographyProps = TypographyProps & {
  isHiddenText: boolean;
};

const PageTracker = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isHiddenText',
})<StyledTypographyProps>(({ theme, isHiddenText }) => ({
  visibility: isHiddenText ? 'hidden' : 'unset',
  color: theme.palette.text.disabled,
  minWidth: 50,
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    minWidth: 20,
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      justifyContent: 'center',
    },
}));
