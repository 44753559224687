import {
  ConsentValue,
  CountryData,
  PersonConsentData,
  PersonData,
} from '@ysura/common';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ActionDialog } from '@/components/ActionDialog';
import { ConsentCollectionContent } from '@/components/Consent/ConsentCollectionContent';
import { F2FConsentPreviewDialog } from '@/components/Consent/F2FConsentCollection/F2FConsentPreviewDialog';
import { useInteraction } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { useCollectDigitalPersonConsentMutation } from '@/services/graphql/hooks';

type F2FConsentCollectionDialogProps = {
  isOpen: boolean;
  consent: PersonConsentData;
  person: PersonData;
  defaultCountry?: CountryData;
  handleClose: VoidFunction;
  handleConfirm: (message: string) => void;
};

export const F2FConsentCollectionDialog = ({
  isOpen,
  consent,
  person,
  defaultCountry,
  handleClose,
  handleConfirm,
}: F2FConsentCollectionDialogProps) => {
  const { t } = useTranslation();

  const { setConsentAsCollected } = useInteraction();

  const [signature, setSignature] = useState('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [consentValues, setConsentValues] = useState<ConsentValue | null>(null);

  const [
    collectDigitalPersonConsentMutation,
    { loading: isCollectConsentLoading },
  ] = useCollectDigitalPersonConsentMutation();

  const handleCloseAndReset = useCallback(() => {
    setSignature('');
    handleClosePreviewDialog();
    handleConfirm(t('pages.interaction.toasts.consentFormSubmitted'));
  }, [handleConfirm, t]);

  const handleCancelDialog = () => {
    setSignature('');
    handleClosePreviewDialog();
    handleClose();
  };

  const handleSubmitForm = (formContent: string) => {
    if (!person?.oid || !consent?.consentType?.oid || !formContent) {
      return;
    }

    collectDigitalPersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent?.oid ?? uuidv4(),
        consentTypeId: consent.consentType.oid,
        signerPersonId: person.oid,
        content: formContent,
      },
      onCompleted: (data) => {
        if (data?.collectDigitalPersonConsent?.oid) {
          setConsentAsCollected?.(data.collectDigitalPersonConsent.oid);
        }
        handleCloseAndReset();
      },
    });
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewOpen(false);
  };

  if (isOpen && isPreviewOpen && !person) {
    console.log('No attendee in activity');

    return null;
  }

  return (
    <>
      <ActionDialog
        isOpen={isOpen}
        maxWidth={'lg'}
        title={consent?.consentType?.name ?? ''}
        primaryButtonText={t('components.common.next')}
        secondaryButtonText={t('components.common.cancel')}
        isPrimaryButtonDisabled={!isFormValid || !signature}
        isLoading={isCollectConsentLoading}
        testId="f2f-consent-collection-dialog"
        onClickPrimaryButton={() => setIsPreviewOpen(true)}
        onClickSecondaryButton={handleCancelDialog}
      >
        <ConsentCollectionContent
          consentType={consent.consentType}
          person={person}
          defaultCountry={defaultCountry}
          isSignatureRequired={true}
          setIsFormValid={setIsFormValid}
          setConsentValues={setConsentValues}
          onChangeSignature={setSignature}
        />
      </ActionDialog>

      {isPreviewOpen && consentValues && person && (
        <F2FConsentPreviewDialog
          isOpen={isPreviewOpen}
          consent={consent}
          signature={signature}
          receiver={person}
          consentValues={consentValues}
          isSubmitLoading={isCollectConsentLoading}
          onClosePreviewDialog={handleClosePreviewDialog}
          onSubmit={handleSubmitForm}
        />
      )}
    </>
  );
};
