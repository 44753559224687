import {
  Box,
  Card as MuiCard,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DisplaySearchResultCardSkeleton = () => {
  return (
    <Card data-testid="display-search-result-card-skeleton">
      <Wrapper>
        <Row>
          <Skeleton variant="circular" width={20} height={20} />
          <Typography variant="subtitle2">
            <Skeleton width={200} height={22} />
          </Typography>
        </Row>
        <Typography variant="subtitle2">
          <Skeleton width={160} height={18} />
        </Typography>
        <Typography variant="subtitle2">
          <Skeleton width={160} height={18} />
        </Typography>
      </Wrapper>
    </Card>
  );
};

const Card = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
}));

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const Row = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
}));
