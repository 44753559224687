import { Grid, styled } from '@mui/material';
import { getPersonFullNameWithTitle } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/PageHeader';
import { REMOTE_INTERACTION } from '@/config/layout';
import { useCurrentUser, useInteraction } from '@/hooks';
import {
  InteractionInformation,
  WaitingRoomProps,
} from '@/views/Interaction/Common';
import { CallStatusBlockCard } from '@/views/Interaction/Telephone';

export const TelephoneWaitingRoom = ({
  activity,
  isOrganizer,
  consentList,
  onStartInteraction,
  onCancelInteraction,
  isActivityEditable,
}: WaitingRoomProps) => {
  const { t } = useTranslation();

  const { isStateServerInitialized } = useInteraction();

  const { currentUser } = useCurrentUser();

  const isInteractionButtonDisabled = !isStateServerInitialized;

  return (
    <Grid container rowSpacing={5} columnSpacing={3}>
      {/* Header */}
      <Grid item xs={12}>
        <PageHeader
          hasCancel
          title={t('pages.interaction.common.title', {
            name: getPersonFullNameWithTitle(currentUser?.person),
          })}
          buttonText={t('pages.interaction.common.joinInteraction')}
          buttonHandler={onStartInteraction}
          cancelHandler={onCancelInteraction}
          titleVariant="dark"
          isButtonDisabled={isInteractionButtonDisabled}
        />
      </Grid>

      {/* First column */}
      <StyledGrid item xs={12} md={8}>
        <CallStatusBlockCard activity={activity} />
      </StyledGrid>

      {/* Second column */}
      <StyledGrid item xs={12} md={4}>
        <InteractionInformation
          activity={activity}
          isOrganizer={isOrganizer}
          consentList={consentList}
          // In the waiting room, we do not update the viewed status
          isMediaViewedStatusUpdatedOnOpen={false}
          isActivityEditable={isActivityEditable}
        />
      </StyledGrid>
    </Grid>
  );
};

const StyledGrid = styled(Grid)({
  height: REMOTE_INTERACTION.DESKTOP.SHARING_CONTAINER_HEIGHT,
});
