import {
  ActivityData,
  MediaData,
  PromotionalMaterialData,
  PromotionalMaterialGroupData,
  ReactionDataEnum,
  TopicData,
} from '@ysura/common';
import { intersectionBy } from 'lodash';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  useGetActivityTypeTopicsQuery,
  useGetMediaTopicsQuery,
  useGetPromotionalMaterialsTopicsQuery,
} from '@/services/graphql/hooks';
import {
  parseMediaConnection,
  parsePromotionalMaterialConnection,
  parseTopicConnection,
} from '@/services/graphql/parsers';

import { groupGivenPromotionalMaterials } from '../PromotionalMaterials';
import { EditActivityDialogType, ItemToRemove } from '../types';

type UseUpdateActivityStateInitialValue = {
  currentActivity: ActivityData | null;
  selectedMedia: Array<MediaData>;
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>;
  selectedTopics: Array<TopicData>;
  availableTopics: Array<TopicData>;
  itemsToRemove: Array<ItemToRemove>;

  isTopicsLoading: boolean;

  initializeActivity: (
    activity: ActivityData,
    type: EditActivityDialogType
  ) => void;
  resetState: () => void;
  changeTopics: (topics: Array<TopicData>) => void;
  changeMediaReaction: (mediaId: string, reaction: ReactionDataEnum) => void;
  addMediaToSelection: (media: MediaData) => void;
  removeMediaFromSelection: (media: MediaData) => void;
  changePromotionalMaterials: (
    proMats: Array<PromotionalMaterialGroupData>
  ) => void;
};

const UpdateActivityStateContext =
  createContext<UseUpdateActivityStateInitialValue>({
    currentActivity: null,
    selectedMedia: [],
    selectedPromotionalMaterials: [],
    selectedTopics: [],
    itemsToRemove: [],
    availableTopics: [],

    isTopicsLoading: false,

    initializeActivity: () => {},
    resetState: () => {},
    changeTopics: () => {},
    changeMediaReaction: () => {},
    addMediaToSelection: () => {},
    removeMediaFromSelection: () => {},
    changePromotionalMaterials: () => {},
  });

export const UpdateActivityStateContextProvider: FC = ({ children }) => {
  const [currentActivity, setCurrentActivity] = useState<ActivityData | null>(
    null
  );
  const [selectedMedia, setSelectedMedia] = useState<Array<MediaData>>([]);
  const [selectedPromotionalMaterials, setSelectedPromotionalMaterials] =
    useState<Array<PromotionalMaterialGroupData>>([]);
  const [selectedTopics, setSelectedTopics] = useState<Array<TopicData>>([]);
  const [availableTopics, setAvailableTopics] = useState<Array<TopicData>>([]);
  const [mediaForCurrentActivity, setMediaForCurrentActivity] = useState<
    Array<MediaData>
  >([]);
  const [
    promotionalMaterialsForCurrentActivity,
    setPromotionalMaterialsForCurrentActivity,
  ] = useState<Array<PromotionalMaterialData>>([]);

  const [itemsToRemove, setItemsToRemove] = useState<Array<ItemToRemove>>([]);

  const { loading: isActivityTypeTopicsLoading } =
    useGetActivityTypeTopicsQuery({
      variables: {
        id: currentActivity?.activityType?.id ?? '',
      },
      skip: !currentActivity,
      onCompleted: (data) => {
        setAvailableTopics(parseTopicConnection(data.activityType?.topics));
      },
    });

  const { loading: isMediaTopicsLoading } = useGetMediaTopicsQuery({
    variables: {
      oids: currentActivity?.media?.map((m) => m.oid || '') ?? [],
    },
    skip: !currentActivity,
    onCompleted: (data) => {
      setMediaForCurrentActivity(parseMediaConnection(data.medias).medias);
    },
  });

  const { loading: isPromotionalMaterialsTopicsLoading } =
    useGetPromotionalMaterialsTopicsQuery({
      variables: {
        oids:
          currentActivity?.promotionalMaterials?.map(
            (m) => m?.promotionalMaterial?.oid || ''
          ) ?? [],
      },
      skip: !currentActivity,
      onCompleted: (data) => {
        setPromotionalMaterialsForCurrentActivity(
          parsePromotionalMaterialConnection(data.promotionalMaterials)
        );
      },
    });

  const initializeActivity = useCallback(
    (activity: ActivityData, type: EditActivityDialogType) => {
      setCurrentActivity(activity);

      if (type === 'media') {
        setSelectedMedia(activity?.media ?? []);
      }

      if (type === 'promotionalMaterials') {
        setSelectedPromotionalMaterials(
          groupGivenPromotionalMaterials(activity?.promotionalMaterials ?? [])
        );
      }

      if (type === 'topics') {
        setSelectedTopics(activity?.topics ?? []);
      }
    },
    []
  );

  const resetState = useCallback(() => {
    setCurrentActivity(null);
    setSelectedMedia([]);
    setSelectedPromotionalMaterials([]);
    setSelectedTopics([]);
    setAvailableTopics([]);
    setMediaForCurrentActivity([]);
    setPromotionalMaterialsForCurrentActivity([]);
    setItemsToRemove([]);
  }, []);

  const changeMediaReaction = useCallback(
    (mediaId: string, reaction: ReactionDataEnum) => {
      setSelectedMedia((currentMedia) => {
        const newMediaList: MediaData[] = [...currentMedia];

        const foundMediaIndex = newMediaList.findIndex(
          (media) => media.oid === mediaId
        );
        const updatedMedia = { ...newMediaList[foundMediaIndex], reaction };

        newMediaList[foundMediaIndex] = updatedMedia;

        return newMediaList;
      });
    },
    [setSelectedMedia]
  );

  const addMediaToSelection = useCallback(
    (media: MediaData) => {
      setSelectedMedia((currentValue) => {
        return [...currentValue, media];
      });
    },
    [setSelectedMedia]
  );

  const removeMediaFromSelection = useCallback(
    (media: MediaData) => {
      setSelectedMedia((currentValue) => {
        return currentValue.filter(
          (selectedItem) => selectedItem.id !== media.id
        );
      });
    },
    [setSelectedMedia]
  );

  const changePromotionalMaterials = useCallback(
    (newPromotionalMaterials: Array<PromotionalMaterialGroupData>) => {
      setSelectedPromotionalMaterials(newPromotionalMaterials);
    },
    [setSelectedPromotionalMaterials]
  );

  /**
   * This function is called when topics are changed in the EditTopic dialog.
   *
   * If the activity has Promotional Materials or Media with topics that are
   * not in the new list of topics, those items are set to be removed to keep
   * the activity valid.
   */
  const changeTopics = useCallback(
    (newTopics: Array<TopicData>) => {
      if (currentActivity) {
        setSelectedTopics(() => {
          const toRemove: Array<ItemToRemove> = [];

          mediaForCurrentActivity.forEach((media) => {
            if (
              intersectionBy(newTopics, media.topics ?? [], 'oid').length === 0
            ) {
              if (media?.oid && media?.name) {
                toRemove.push({
                  oid: media.oid,
                  name: media.name,
                  type: 'media',
                });
              }
            }
          });

          promotionalMaterialsForCurrentActivity.forEach((proMat) => {
            if (
              intersectionBy(newTopics, proMat.topics ?? [], 'oid').length === 0
            ) {
              if (proMat?.oid && proMat?.name) {
                toRemove.push({
                  oid: proMat.oid,
                  name: proMat.name,
                  type: 'promotionalMaterial',
                });
              }
            }
          });

          setItemsToRemove(toRemove);

          return newTopics;
        });
      }
    },
    [
      currentActivity,
      mediaForCurrentActivity,
      promotionalMaterialsForCurrentActivity,
    ]
  );

  const values = useMemo(
    () => ({
      currentActivity,
      selectedMedia,
      selectedPromotionalMaterials,
      selectedTopics,
      availableTopics,
      itemsToRemove,

      isTopicsLoading:
        isActivityTypeTopicsLoading ||
        isMediaTopicsLoading ||
        isPromotionalMaterialsTopicsLoading,

      initializeActivity,
      resetState,
      changeMediaReaction,
      addMediaToSelection,
      removeMediaFromSelection,
      changePromotionalMaterials,
      changeTopics,
    }),
    [
      currentActivity,
      selectedMedia,
      selectedPromotionalMaterials,
      selectedTopics,
      availableTopics,
      itemsToRemove,
      isActivityTypeTopicsLoading,
      isMediaTopicsLoading,
      isPromotionalMaterialsTopicsLoading,
      initializeActivity,
      resetState,
      changeMediaReaction,
      addMediaToSelection,
      removeMediaFromSelection,
      changePromotionalMaterials,
      changeTopics,
    ]
  );

  return (
    <UpdateActivityStateContext.Provider value={values}>
      {children}
    </UpdateActivityStateContext.Provider>
  );
};

export const useUpdateActivityState = () => {
  return useContext(UpdateActivityStateContext);
};
