import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

type EditIconButtonProps = {
  onClick: VoidFunction;
  testId?: string;
};

export const EditIconButton = ({ onClick, testId }: EditIconButtonProps) => {
  return (
    <IconButton data-testid={testId} onClick={onClick}>
      <Edit />
    </IconButton>
  );
};

const Edit = styled(EditIcon)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.main,
}));
