import { ActivityData, ActivityStatusDataEnum, Label } from '@ysura/common';
import { useTranslation } from 'react-i18next';

interface ActivityStatusDataBlockProps {
  activity: ActivityData;
}
export const ActivityStatusDataBlock = ({
  activity,
}: ActivityStatusDataBlockProps) => {
  const { t } = useTranslation();
  const isPlannedActivity = activity.status === ActivityStatusDataEnum.PLANNED;
  const isPlannedActivityConfirmed = isPlannedActivity && activity.confirmed;

  return (
    <>
      {isPlannedActivityConfirmed ? (
        <Label color="primary">
          {t('pages.homepage.touchpointList.confirmed')}
        </Label>
      ) : isPlannedActivity ? (
        <Label color="primary">
          {t('pages.homepage.touchpointList.planned')}
        </Label>
      ) : (
        <Label color="default">{t('pages.homepage.touchpointList.done')}</Label>
      )}
    </>
  );
};
