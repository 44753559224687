import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader as MuiCardHeader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, PersonConsentData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { getScrollbarWidth } from '@/utils/scrollbar';

import { InteractionInformationContent } from './InteractionInformationContent';

type InteractionInformationProps = {
  activity: ActivityData;
  isOrganizer: boolean;
  consentList: PersonConsentData[];
  isMediaViewedStatusUpdatedOnOpen: boolean;
  isActivityEditable?: boolean;
};

export const InteractionInformation = ({
  activity,
  isOrganizer,
  consentList,
  isMediaViewedStatusUpdatedOnOpen,
  isActivityEditable,
}: InteractionInformationProps) => {
  const { t } = useTranslation();
  const scrollbarWidth = getScrollbarWidth();

  return (
    <Card>
      <CardHeader
        title={t('pages.interaction.common.interactionInformation')}
        titleTypographyProps={{
          variant: 'subtitle2',
        }}
      />
      <CardContent scrollbarWidth={scrollbarWidth}>
        <InteractionInformationContent
          isOrganizer={isOrganizer}
          activity={activity}
          consentList={consentList}
          isMediaViewedStatusUpdatedOnOpen={isMediaViewedStatusUpdatedOnOpen}
          isActivityEditable={isActivityEditable}
        />
      </CardContent>
    </Card>
  );
};

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: 0,
  color: theme.palette.text.secondary,
}));

const CardContent = styled(MuiCardContent, {
  shouldForwardProp: (prop) => prop !== 'scrollbarWidth',
})<{ scrollbarWidth: number }>(({ theme, scrollbarWidth }) => ({
  padding: theme.spacing(2),

  // TODO: currently, we are always showing the scrollbar if scrollbarGutter is not available.
  // In the future, we might want to switch to a better solution like described here:
  // https://maxschmitt.me/posts/react-prevent-layout-shift-body-scrollable/
  overflowY: 'scroll',
  '@supports (scrollbar-gutter: stable)': {
    scrollbarGutter: 'stable',
    // if the scrollbar is overlayed (scrollbarWidth === 0), we need to add extra padding
    paddingRight: scrollbarWidth === 0 ? theme.spacing(2) : 0,
    overflowY: 'auto',
  },

  flexGrow: 1,
}));
