import { EditActivityDialogContent } from './EditActivityDialogContent';
import { EditActivityDialogProps } from './types';
import { UpdateActivityStateContextProvider } from './UpdateActivityState';

export const EditActivityDialog = ({
  type,
  isOpen,
  onClose,
  onCancel,
  onSave,
  activity,
}: EditActivityDialogProps) => {
  return (
    <UpdateActivityStateContextProvider>
      <EditActivityDialogContent
        activity={activity}
        type={type}
        isOpen={isOpen}
        onClose={onClose}
        onSave={onSave}
        onCancel={onCancel}
      />
    </UpdateActivityStateContextProvider>
  );
};
