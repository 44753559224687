import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPreferencesQueryVariables = Types.Exact<{
  preferenceId: Types.Scalars['ID']['input'];
}>;

export type GetPreferencesQuery = {
  __typename?: 'Query';
  preference?: {
    __typename?: 'Preference';
    oid: string;
    facets?: {
      __typename?: 'FacetsPreference';
      personFacets: {
        __typename?: 'PersonFacetsPreference';
        personTypes: Array<{
          __typename?: 'PersonType';
          id: string;
          oid: string;
          name?: string | null;
        }>;
        specialities: Array<{
          __typename?: 'Speciality';
          id: string;
          oid: string;
          name?: string | null;
          classification?: Types.SpecialityClassification | null;
        }>;
        specialityGroups: Array<{
          __typename?: 'SpecialityGroup';
          id: string;
          oid: string;
          name?: string | null;
        }>;
        employeeFunctions: Array<{
          __typename?: 'EmployeeFunction';
          id: string;
          oid: string;
          name?: string | null;
        }>;
        consentTypes: Array<{
          __typename?: 'ConsentType';
          id: string;
          oid: string;
          name: string;
          digitalSignatureProcessEnabled: boolean;
          paperProcessEnabled: boolean;
          manualProcessEnabled: boolean;
          doubleOptInDigitalEnabled?: boolean | null;
          doubleOptInPaperEnabled?: boolean | null;
          doubleOptInManualEnabled?: boolean | null;
        }>;
        attributes: Array<{
          __typename?: 'Attribute';
          id: string;
          oid: string;
          name: string;
          type: Types.AttributeType;
        }>;
      };
      organizationFacets: {
        __typename?: 'OrganizationFacetsPreference';
        cities: Array<string>;
        organizationTypes: Array<{
          __typename?: 'OrganizationType';
          id: string;
          oid: string;
          name?: string | null;
        }>;
        attributes: Array<{
          __typename?: 'Attribute';
          id: string;
          oid: string;
          name: string;
          type: Types.AttributeType;
        }>;
      };
    } | null;
  } | null;
};

/**
 * __useGetPreferencesQuery__
 *
 * To run a query within a React component, call `useGetPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreferencesQuery({
 *   variables: {
 *      preferenceId: // value for 'preferenceId'
 *   },
 * });
 */
export function useGetPreferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPreferencesQuery,
    GetPreferencesQueryVariables
  > &
    (
      | { variables: GetPreferencesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetPreferencesQuery, GetPreferencesQueryVariables>(
    Operations.getPreferences,
    options
  );
}
export function useGetPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreferencesQuery,
    GetPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetPreferencesQuery, GetPreferencesQueryVariables>(
    Operations.getPreferences,
    options
  );
}
export function useGetPreferencesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPreferencesQuery,
    GetPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPreferencesQuery,
    GetPreferencesQueryVariables
  >(Operations.getPreferences, options);
}
export type GetPreferencesQueryHookResult = ReturnType<
  typeof useGetPreferencesQuery
>;
export type GetPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetPreferencesLazyQuery
>;
export type GetPreferencesSuspenseQueryHookResult = ReturnType<
  typeof useGetPreferencesSuspenseQuery
>;
export type GetPreferencesQueryResult = Apollo.QueryResult<
  GetPreferencesQuery,
  GetPreferencesQueryVariables
>;
