import { KeycloakUser } from '../types';

// We need `any` here as we do not know (and do not care about) the exact
// structure returned from the Keycloak API.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseKeycloakUser = (userData: any): KeycloakUser => {
  const attributes = userData?.attributes;

  const locale = attributes?.locale?.[0];
  const ysuraUserId = attributes?.ysura_userId?.[0];

  return {
    id: userData?.id,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    email: userData?.email,
    username: userData?.username,
    locale,
    ysuraUserId,
  };
};

export const parseKeycloakUserList = (
  userList: unknown
): Array<KeycloakUser> => {
  if (typeof userList === 'undefined' || !Array.isArray(userList)) {
    return [];
  }

  return userList.map((user) => parseKeycloakUser(user));
};
