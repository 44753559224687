import { OrganizationData, PersonData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';

import { OrganizationHierarchyCard } from './OrganizationHierarchyCard';
import { OrganizationHierarchyPersonCard } from './OrganizationHierarchyPersonCard';

interface MobileOrganizationHierarchyDialogProps {
  isOpen: boolean;
  hasMore: boolean;
  onClose: VoidFunction;
  handleFetchMorePersons: VoidFunction;
  organization: OrganizationData;
  selectedOrganization: OrganizationData;
  setSelectedOrganization: (organization: OrganizationData) => void;
  persons?: Array<PersonData>;
  isLoading: boolean;
  total: number;
}
export const MobileOrganizationHierarchyDialog = ({
  isOpen,
  onClose,
  handleFetchMorePersons,
  hasMore,
  organization,
  selectedOrganization,
  setSelectedOrganization,
  persons,
  isLoading,
  total,
}: MobileOrganizationHierarchyDialogProps) => {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);

  const toggleDetailPage = () => {
    setShowDetail((prevState) => !prevState);
  };

  const detailButtonText = showDetail
    ? t('components.common.back')
    : t('pages.organizationView.hierarchy.detail');

  return (
    <ActionDialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={t('pages.organizationView.hierarchy.title')}
      primaryButtonText={detailButtonText}
      testId="organization-hierarchy-info-dialog"
      secondaryButtonText={t('components.common.close')}
      onClickPrimaryButton={toggleDetailPage}
      onClickSecondaryButton={onClose}
    >
      {showDetail ? (
        <OrganizationHierarchyPersonCard
          organization={selectedOrganization}
          persons={persons}
          total={total}
          fetchMorePersons={handleFetchMorePersons}
          hasMore={hasMore}
        />
      ) : (
        <OrganizationHierarchyCard
          organization={organization}
          setSelectedOrganization={setSelectedOrganization}
        />
      )}
    </ActionDialog>
  );
};
