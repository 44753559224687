import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaData, NoItemPlaceholder } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useMediaContext } from '@/hooks/state';

import { MediaGridItem } from './MediaGridItem';
import { MediaGridItemSkeleton } from './MediaGridItemSkeleton';

type MediaGridProps = {
  isDisplayViewedLabel?: boolean;
  isLoading: boolean;
  mediaData: Array<MediaData>;
  onOpenMediaContent: (media: MediaData) => void;
};

export const MediaGrid = ({
  isDisplayViewedLabel = false,
  isLoading,
  mediaData,
  onOpenMediaContent,
}: MediaGridProps) => {
  const { t } = useTranslation();
  const { mediaQueryString } = useMediaContext();

  if (isLoading) {
    return (
      <GridStyle container spacing={{ md: 3, xs: 1 }}>
        {Array.from(new Array(7)).map((_, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <MediaGridItemSkeleton />
          </Grid>
        ))}
      </GridStyle>
    );
  }

  if (mediaQueryString && mediaData.length === 0) {
    return (
      <NoItemPlaceholder>
        {t('pages.interaction.common.noSearchResult')}
      </NoItemPlaceholder>
    );
  }

  return (
    <GridStyle container spacing={{ md: 3, xs: 1 }}>
      {mediaData.map((media) => (
        <Grid key={media.id} item xs={12} sm={6} md={3}>
          <MediaGridItem
            media={media}
            isDisplayViewedLabel={isDisplayViewedLabel}
            onSelectMedia={onOpenMediaContent}
          />
        </Grid>
      ))}
    </GridStyle>
  );
};

const GridStyle = styled(Grid)({
  alignItems: 'baseline',
  height: '100%',
});
