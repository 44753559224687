import { Button } from '@mui/material';
import { ErrorPage } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InteractionBackTopLayout } from '@/layouts';

type InvalidActivityProps = {
  redirectAction?: VoidFunction;
  redirectTarget?: string;
  reason:
    | 'impersonated'
    | 'userNotOrganizerAndCoOrganizer'
    | 'activityNotActive'
    | 'activityNotPlanned'
    | 'activitySealed'
    | 'activityNotDirectContactOrRemote';
};

export const InvalidActivity = ({
  reason,
  redirectTarget,
  redirectAction,
}: InvalidActivityProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (redirectAction) {
      redirectAction();
    } else {
      navigate(redirectTarget ?? '/');
    }
  };

  const goBackButton = (
    <Button variant="contained" onClick={handleBackButton}>
      {t('components.error.invalidActivity.button')}
    </Button>
  );

  return (
    <InteractionBackTopLayout>
      <ErrorPage
        title={t('components.error.invalidActivity.title')}
        subtitleLine1={t(`components.error.invalidActivity.reasons.${reason}`)}
        actionButton={goBackButton}
      />
    </InteractionBackTopLayout>
  );
};
