import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentCollectState,
  ConsentValue,
  ConsentViewerPayload,
  HTMLPreviewState,
  Loader,
  PersonData,
} from '@ysura/common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useCurrentUser, useInteraction, useNotification } from '@/hooks';
import { useCollectDigitalPersonConsentMutation } from '@/services/graphql/hooks';
import {
  compilePersonConsentFormTemplate,
  useGetPersonConsentTemplate,
} from '@/services/templates';
import {
  ConsentCollectionPreview,
  ConsentFormCollection,
} from '@/views/Interaction/Remote/Room/ConsentCollection';

type ConsentCollectionProps = {
  attendee: PersonData | undefined;
};

export const ConsentCollection = ({ attendee }: ConsentCollectionProps) => {
  const {
    consentSharedByOrganizer,
    setCurrentConsentValues,
    setCurrentConsentSignature,
    setCurrentConsentPreview,
    setCurrentConsentStep,
    setConsentAsCollected,
    changeSharingConsent,
    stopSharingConsent,
    onConsentFormValueChanged,
    onConsentFormAttendeeSubmitted,
  } = useInteraction();

  const defaultCountry =
    consentSharedByOrganizer?.initialState?.validation?.defaultCountryPhone;

  const [errorMessage, setErrorMessage] = useState<string>();

  const { t } = useTranslation();
  const { toast } = useNotification();
  const { currentUser } = useCurrentUser();

  const currentConsentType = consentSharedByOrganizer?.consentType;

  const { isLoading, data: templateData } =
    useGetPersonConsentTemplate(currentConsentType);

  const [
    collectDigitalPersonConsentMutation,
    { loading: isCollectConsentLoading },
  ] = useCollectDigitalPersonConsentMutation();

  const documentId = currentConsentType?.digitalSignatureTemplate?.document?.id;

  useEffect(() => {
    const handleConsentFormValueChange = (data: ConsentViewerPayload) => {
      setCurrentConsentStep(data.step);
      if (data.state.step == 'collect') {
        setCurrentConsentSignature(data.state.signature);
        setCurrentConsentValues(data.state.consentItems);
        const consentFormCollectionEl = document.getElementById(
          'consentFormCollection'
        );
        if (consentFormCollectionEl) {
          consentFormCollectionEl.scrollTop =
            consentFormCollectionEl.scrollHeight;
        }
      }
    };

    const unsubscribeOnConsentFormValueChanged = onConsentFormValueChanged?.(
      handleConsentFormValueChange
    );

    const handleSubmitConsentCollection = (
      event: ConsentViewerPayload,
      formContent: string
    ) => {
      collectDigitalPersonConsentMutation({
        variables: {
          oid: consentSharedByOrganizer?.oid ?? uuidv4(),
          consentTypeId: currentConsentType?.oid ?? '',
          signerPersonId: attendee?.oid ?? '',
          content: formContent,
        },
        onCompleted: (data) => {
          if (data?.collectDigitalPersonConsent?.oid) {
            setConsentAsCollected?.(data.collectDigitalPersonConsent.oid);
          }
          stopSharingConsent();
          toast?.({
            message: t('pages.interaction.toasts.consentFormSubmitted'),
            type: 'success',
          });
        },
      });
    };

    const handleAttendeeSubmittedConsentForm = (
      event: ConsentViewerPayload
    ) => {
      const formContent =
        event.state.step === 'preview' ? event.state.content : '';
      handleSubmitConsentCollection(event, formContent);
    };

    const unsubscribeOnConsentFormSubmitted = onConsentFormAttendeeSubmitted?.(
      handleAttendeeSubmittedConsentForm
    );

    return () => {
      if (unsubscribeOnConsentFormValueChanged) {
        unsubscribeOnConsentFormValueChanged();
      }
      if (unsubscribeOnConsentFormSubmitted) {
        unsubscribeOnConsentFormSubmitted();
      }
    };
    // Only listened to changed events to update the component's current state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConsentFormValueChanged, onConsentFormAttendeeSubmitted]);

  const handleCancelForm = () => {
    if (documentId) {
      stopSharingConsent();

      toast?.({
        message: t('pages.interaction.toasts.consentFormCanceled'),
        type: 'warning',
      });
    }
  };

  const compileContentWithData = useCallback(
    (url: string) => {
      return compilePersonConsentFormTemplate({
        consentType: currentConsentType,
        signature: url,
        template: templateData,
        sender: currentUser?.person,
        receiver: attendee,
        consentValues: consentSharedByOrganizer?.currentConsentValues ?? {},
      });
    },
    [
      attendee,
      consentSharedByOrganizer?.currentConsentValues,
      currentConsentType,
      currentUser?.person,
      templateData,
    ]
  );

  const handleDataChange = useCallback(
    (step: 'collect' | 'preview', consentValues: ConsentValue) => {
      if (!currentConsentType) {
        return;
      }

      let payload: ConsentViewerPayload;
      const title = currentConsentType?.name ?? '';
      const signature = consentSharedByOrganizer?.currentSignature ?? '';
      if (step == 'collect') {
        const state: ConsentCollectState = {
          step: 'collect',
          title,
          staticText: currentConsentType?.description ?? '',
          consentItems: consentValues,
          consentCommunicationData: currentConsentType?.consentItems ?? [],
          signature,
        };

        payload = {
          id: documentId ?? '',
          step: 'collect',
          consentType: currentConsentType,
          state: state,
        };
      } else {
        const previewContent = compileContentWithData(signature);
        setCurrentConsentPreview(previewContent);

        const state: HTMLPreviewState = {
          title: title,
          step: 'preview',
          content: previewContent,
        };

        payload = {
          id: documentId ?? '',
          step: 'preview',
          consentType: currentConsentType,
          state: state,
        };
      }
      changeSharingConsent(payload);
    },
    [
      changeSharingConsent,
      compileContentWithData,
      consentSharedByOrganizer?.currentSignature,
      currentConsentType,
      documentId,
      setCurrentConsentPreview,
    ]
  );

  const handleConsentValueChange = (consentValue: ConsentValue) => {
    setCurrentConsentValues(consentValue);
    handleDataChange('collect', consentValue);
  };

  const handleStepChange = () => {
    if (errorMessage) {
      toast?.({
        message: t(errorMessage),
        type: 'warning',
      });
    } else if (
      !consentSharedByOrganizer?.currentSignature &&
      consentSharedByOrganizer?.currentStep === 'collect'
    ) {
      toast?.({
        message: t('components.signatureBox.signatureNotValid'),
        type: 'warning',
      });
    } else {
      const nextStep =
        consentSharedByOrganizer?.currentStep === 'collect'
          ? 'preview'
          : 'collect';
      setCurrentConsentStep(nextStep);
      handleDataChange(
        nextStep,
        consentSharedByOrganizer?.currentConsentValues ?? {}
      );
    }
  };

  if (isLoading || isCollectConsentLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  const isCollectStep = consentSharedByOrganizer?.currentStep === 'collect';

  return (
    <Card data-testid="consent-collection-card">
      <CardHeader>
        <Typography variant="h5">{currentConsentType?.name}</Typography>
        <Container>
          <Button variant="contained" onClick={handleStepChange}>
            {isCollectStep
              ? t('components.common.next')
              : t('components.common.back')}
          </Button>
          <Button variant="outlined" onClick={handleCancelForm}>
            {t('components.common.cancel')}
          </Button>
        </Container>
      </CardHeader>
      {isCollectStep ? (
        <ConsentFormCollection
          id={'consentFormCollection'}
          attendee={attendee}
          consentType={currentConsentType}
          consentValues={consentSharedByOrganizer?.currentConsentValues ?? {}}
          defaultCountry={defaultCountry}
          setConsentValues={handleConsentValueChange}
          setErrorMessage={setErrorMessage}
          signature={consentSharedByOrganizer?.currentSignature ?? ''}
        />
      ) : (
        <ConsentCollectionPreview
          consentPreview={consentSharedByOrganizer?.currentPreview ?? ''}
        />
      )}
    </Card>
  );
};

const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  height: '100%',
}));

const CardHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const LoaderWrapper = styled(Box)({
  height: '100%',
  display: 'grid',
  placeContent: 'center',
});
