import { PersonAssignmentBaseTypeDataEnum } from '@ysura/common';

import { createEnumMapperFunction } from '@/services/graphql/mappers/utils';
import { PersonAssignmentBaseType } from '@/services/graphql/types';

export const mapperToPersonAssignmentTypeBaseTypeDataEnum =
  createEnumMapperFunction(
    PersonAssignmentBaseType,
    PersonAssignmentBaseTypeDataEnum
  );
