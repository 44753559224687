import { TemplateData } from '@ysura/common';

import { parseDocumentData } from '@/services/graphql/parsers';
import {
  convertedState,
  convertedTemplateType,
} from '@/services/graphql/parsers/utils';
import { Template, TemplateConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseTemplateData = (
  template?: DeepPartial<Template>
): TemplateData => {
  return {
    id: template?.id ?? undefined,
    oid: template?.oid ?? undefined,
    name: template?.name ?? undefined,
    description: template?.description ?? undefined,
    type: convertedTemplateType(template?.type),
    document: template?.document
      ? parseDocumentData(template.document)
      : undefined,
    state: convertedState(template?.state),
  };
};

export const parseTemplateConnection = (
  templateConnection?: DeepPartial<TemplateConnection>
): Array<TemplateData> => {
  const rawTemplates = templateConnection?.edges ?? [];
  const mappedTemplates = rawTemplates
    .map((edge) => edge?.node ?? null)
    .map((template) => (template ? parseTemplateData(template) : null));

  return filterNonNull(mappedTemplates);
};
