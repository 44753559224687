import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonInfoSkeleton } from '@ysura/common';

export const AttendeesInfoListSkeleton = () => {
  return (
    <Wrapper data-testid="attendee-info-list-skeleton">
      <PersonInfoSkeleton />
      <PersonInfoSkeleton />
      <PersonInfoSkeleton />
      <PersonInfoSkeleton />
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  // Add this padding to see shadow
  padding: `0 1px`,
}));
