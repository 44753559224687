import { OrganizationCardSkeleton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const RelatedOrganizationsSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.relatedOrganizations')}
      testId="related-organization-skeleton-static-block"
    >
      <OrganizationCardSkeleton />
    </OverviewStaticBlock>
  );
};
