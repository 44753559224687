import { ListItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  PromotionalMaterialGroupBatchData,
  StockTypeDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { useFormatDate } from '@/hooks';

import { PromotionalMaterialStockItem } from './PromotionalMaterialStockItem';

type PromotionalMaterialProductTypeProps = {
  batch: PromotionalMaterialGroupBatchData;
  isDoneTouchpoint: boolean;
};

export const PromotionalMaterialBatchItem = ({
  batch,
  isDoneTouchpoint,
}: PromotionalMaterialProductTypeProps) => {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();

  const expiryDate = batch?.expiryDate
    ? formatDate(new Date(batch?.expiryDate), 'PP')
    : '';

  return (
    <StyledListItem>
      <Typography variant="caption">
        {`${t('pages.interaction.common.expiryDate')}: ${expiryDate}`}
      </Typography>

      <StyledTypography variant="caption">
        {`${t('pages.interaction.common.batchNumber')}: ${batch?.name ?? '-'}`}
      </StyledTypography>

      {batch.physicalStock?.requestedQuantity !== 0 && (
        <PromotionalMaterialStockItem
          type={StockTypeDataEnum.PHYSICAL}
          isDoneTouchpoint={isDoneTouchpoint}
          quantity={batch.physicalStock?.quantity ?? 0}
          requestedQuantity={batch.physicalStock?.requestedQuantity ?? 0}
        />
      )}

      {batch.virtualStock?.requestedQuantity !== 0 && (
        <PromotionalMaterialStockItem
          type={StockTypeDataEnum.VIRTUAL}
          isDoneTouchpoint={isDoneTouchpoint}
          quantity={batch.virtualStock?.quantity ?? 0}
          requestedQuantity={batch.virtualStock?.requestedQuantity ?? 0}
        />
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
