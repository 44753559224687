import { List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

type BulletListProps = {
  items: Array<string>;
};

export const BulletList = ({ items }: BulletListProps) => {
  return (
    <StyledList>
      {items.map((item) => (
        <StyledListItem key={item} disablePadding>
          <ListItemText secondary={item} />
        </StyledListItem>
      ))}
    </StyledList>
  );
};

const StyledList = styled(List)(({ theme }) => ({
  listStyleType: 'disc',
  paddingLeft: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)({
  display: 'list-item',
});
