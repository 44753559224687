import { MediaData } from '@ysura/common';

import { ViewedMedia } from '@/hooks/useInteraction';
import { record2Array } from '@/utils/arrays';

export const isMediaViewed = (
  mediaId: string | undefined,
  viewedMedia: ViewedMedia
) => {
  if (!mediaId) {
    return 0;
  }

  const viewedItem = viewedMedia[mediaId];

  if (viewedItem && viewedItem?.media?.id === mediaId) {
    return 1;
  }

  return 0;
};

export const applyViewedStatus = (
  media: Array<MediaData> | undefined,
  viewedMedia: ViewedMedia
): Array<MediaData> => {
  if (!media?.length) {
    return [];
  }

  return media?.map((media) => ({
    ...media,
    isViewed: isMediaViewed(media?.id, viewedMedia),
  }));
};

const sortMediaByViewedStatus = (
  media: Array<MediaData> | undefined
): Array<MediaData> => {
  if (!media || !Array.isArray(media)) {
    return [];
  }

  return media?.sort(
    (mediaA, mediaB) => (mediaA?.isViewed ?? 0) - (mediaB?.isViewed ?? 0)
  );
};

const mergeMediaListWithViewedMedia = (
  mediaList: Array<MediaData> | undefined,
  viewedMedia: ViewedMedia
): Array<MediaData> => {
  const mergedList = mediaList && mediaList?.length > 0 ? [...mediaList] : [];

  const plannedMediaIds = mergedList
    .map((media) => media.id)
    .filter((id) => !!id);

  const viewedMediaList = record2Array(viewedMedia);
  viewedMediaList.forEach((viewedMedia) => {
    if (
      viewedMedia &&
      viewedMedia.media &&
      viewedMedia.media.id &&
      !plannedMediaIds.includes(viewedMedia.media.id)
    ) {
      mergedList.push(viewedMedia.media);
    }
  });

  return mergedList;
};

export const applyViewedStatusAndSortMedia = (
  media: Array<MediaData> | undefined,
  viewedMedia: ViewedMedia
): Array<MediaData> => {
  const mediaWithViewedKey = applyViewedStatus(media, viewedMedia);

  return sortMediaByViewedStatus(mediaWithViewedKey);
};

export const mergePlannedAndViewedMediaAndSort = (
  plannedMedia: Array<MediaData> | undefined,
  viewedMedia: ViewedMedia
): Array<MediaData> => {
  const mergedList = mergeMediaListWithViewedMedia(plannedMedia, viewedMedia);

  const mergedListWithViewedStatus = applyViewedStatus(mergedList, viewedMedia);

  return sortMediaByViewedStatus(mergedListWithViewedStatus);
};

export const filterViewedMedia = (
  media: Array<MediaData> | undefined,
  viewedMedia: ViewedMedia
) => {
  if (!media?.length) {
    return [];
  }

  const mediaWithViewedKey = media?.map((media) => ({
    ...media,
    isViewed: isMediaViewed(media?.id, viewedMedia),
  }));

  return mediaWithViewedKey.filter((media) => media.isViewed);
};
