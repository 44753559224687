import {
  Badge,
  ExpandLessRounded,
  ExpandMoreRounded,
} from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AddressData,
  AddressTypeDataEnum,
  Label,
  OrganizationData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressLine } from '@/components/Address';
import { CommunicationDataStaticBlock } from '@/components/CommunicationData';
import { LabelsContainer } from '@/components/LabelsContainer';

interface OrganizationDataContentProps {
  organization: OrganizationData;
}

export const OrganizationDataContent = ({
  organization,
}: OrganizationDataContentProps) => {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);

  const { organizationTypes, communicationData, addresses } = organization;
  const address = addresses?.find(
    (it: AddressData) => it.addressType == AddressTypeDataEnum.STANDARD
  );

  const handleExpand = () => {
    setIsExpand((prevState) => !prevState);
  };

  return (
    <StyleStack data-testid="organization-content-data">
      {organization.inClearance && (
        <Stack>
          <LabelsContainer>
            <Label
              data-testid="organization-clearance-label"
              variant="ghost"
              color="warning"
            >
              {t('pages.organizationView.organizationDataCard.inClearance')}
            </Label>
          </LabelsContainer>
        </Stack>
      )}
      {organizationTypes && organizationTypes.length > 0 && (
        <Stack>
          <LabelsContainer>
            {organizationTypes.map((type, idx) => (
              <Label
                key={`type-${idx}`}
                data-testid="organization-type-label"
                variant="ghost"
                color="info"
              >
                {type.name}
              </Label>
            ))}
          </LabelsContainer>
        </Stack>
      )}

      {address && (
        <Stack>
          <AddressLine address={address} />
        </Stack>
      )}

      {organization?.customerReference && (
        <Stack>
          <InformationBox>
            <StyleBadgeIcon />
            <DisableTypography variant="caption">
              {organization?.customerReference}
            </DisableTypography>
          </InformationBox>
        </Stack>
      )}

      <Stack>
        <StyleBox>
          <Typography variant="caption">
            {t('pages.organizationView.organizationDataCard.moreInformation')}
          </Typography>
          {isExpand ? (
            <StyleBox onClick={handleExpand}>
              <StyleTypography variant="subtitle2">
                {t('pages.organizationView.organizationDataCard.showLess')}
              </StyleTypography>
              <IconButton>
                <ExpandLessRounded />
              </IconButton>
            </StyleBox>
          ) : (
            <StyleBox onClick={handleExpand}>
              <StyleTypography variant="subtitle2">
                {t('pages.organizationView.organizationDataCard.showMore')}
              </StyleTypography>
              <IconButton>
                <ExpandMoreRounded />
              </IconButton>
            </StyleBox>
          )}
        </StyleBox>
      </Stack>

      {isExpand && (
        <CommunicationDataStaticBlock
          title={
            'pages.organizationView.organizationDataCard.contactInformation'
          }
          communicationData={communicationData ?? []}
        />
      )}
    </StyleStack>
  );
};

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const StyleStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const InformationBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

const StyleBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(0.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyleBadgeIcon = styled(Badge)(({ theme }) => ({
  size: 16,
  color: theme.palette.text.disabled,
}));

const StyleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));
