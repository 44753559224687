import { gql } from '@apollo/client';

export const rejectPersonConsent = gql`
  mutation rejectPersonConsent(
    $oid: PersonConsentId!
    $consentTypeId: ConsentTypeId!
    $issuerPersonId: PersonId!
    $signerPersonId: PersonId!
    $actionAt: DateTime!
  ) {
    rejectPersonConsent(
      input: {
        oid: $oid
        consentTypeId: $consentTypeId
        issuerPersonId: $issuerPersonId
        signerPersonId: $signerPersonId
        actionAt: $actionAt
      }
    ) {
      id
      oid
    }
  }
`;
