import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import { useCurrentUser } from '@/hooks';
import { ActivityStatus, State } from '@/services/graphql/types';

import {
  AddActivityMutation,
  AddActivityMutationVariables,
  useAddActivityMutation as useAddActivityMutationApollo,
} from '../generated';

type AddActivityVariables = Pick<
  AddActivityMutationVariables,
  'organizationId' | 'attendees' | 'discussedTopics' | 'activityTypeId'
> &
  Partial<
    Pick<
      AddActivityMutationVariables,
      | 'oid'
      | 'organizerId'
      | 'state'
      | 'status'
      | 'startDate'
      | 'endDate'
      | 'confirmed'
      | 'requestedPromotionalMaterials'
      | 'coOrganizers'
      | 'media'
      | 'comments'
      | 'callDetails'
    >
  >;

/**
 * __useAddActivityMutation__
 *
 * To run a mutation, you first call `useAddActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSampleRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivity, { data, loading, error }] = useAddActivityMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      organizerId: // oid of the organizer of the activity
 *      organizationId: // oid of the organization which is the target of the activity
 *      attendees: // values of the registered person or external guest which will be attendee the activity
 *      startDate: // start date and time of the activity
 *      endDate: // end date and time of the activity
 *      discussedTopics: // ids of the discussed topics in the activity
 *      activityTypeId: // value the type of the activity (DIRECT_CONTACT, EMAIL, etc.)
 *      requestedPromotionalMaterials: // values for the promotional materials requested during the activity
 *      coOrganizers: // values for the ico-organizers of the activity
 *
 *      // Optional
 *      state: // value of the state of the activity
 *      status: // value of the status of the activity
 *      media: // values for the media shown during the activity
 *      comments: // values of the comments of an activity
 *      callDetails: // values for the call details (phone number, outcome of the call, etc.) of an activity of type PHONE
 *      confirmed: // flag to indicate if an activity is confirmed
 *   },
 * });
 */
export function useAddActivityMutation(
  baseOptions?: MutationHookOptions<
    AddActivityMutation,
    AddActivityMutationVariables
  >
) {
  const { currentUser } = useCurrentUser();

  const [addActivity, status] = useAddActivityMutationApollo({
    // We invalidate all queries related to activity to refresh the data
    refetchQueries: [
      'getActivityTypeList',
      'getTouchPointGroupList',
      'getPersonPromotionalMaterials',
    ],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<AddActivityMutation, AddActivityVariables>
  ) => {
    const defaultVariables = {
      oid: uuidv4(),
      organizerId: currentUser?.person?.oid ?? '',
      state: State.ACTIVE,
      requestedPromotionalMaterials: [],
      media: [],
      comments: [],
      callDetails: [],
      coOrganizers: [],

      // placeholders, have to be overwritten when calling the mutate function
      status: ActivityStatus.PLANNED,
      organizationId: '',
      attendees: [],
      startDate: undefined,
      endDate: undefined,
      discussedTopics: [],
      activityTypeId: '',
      confirmed: false,
    };

    return addActivity({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
}
