import { Grid } from '@mui/material';
import { ActivityStatusDataEnum, OrganizationData } from '@ysura/common';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationActivityDataStaticBlock } from '@/components/Organization';
import { errorConfigQueryLoadingError } from '@/hooks/useNotification';
import { useGetTouchpointsByOrgIdQuery } from '@/services/graphql/hooks';
import { parseTouchpointConnection } from '@/services/graphql/parsers';

interface OrganizationActivityCardProps {
  organization: OrganizationData;
}
export const OrganizationActivityCard = ({
  organization,
}: OrganizationActivityCardProps) => {
  const { t } = useTranslation();
  const errorCfg = useRef(
    errorConfigQueryLoadingError(t('pages.organizationView.plannedTouchpoint'))
  );

  const { data, loading, refetch } = useGetTouchpointsByOrgIdQuery({
    ...errorCfg.current.context,
    variables: {
      organizationId: organization.oid,
    },
  });

  errorCfg.current.bannerOptions.button = {
    label: t('components.error.reload'),
    action: refetch,
  };

  const touchPoints = parseTouchpointConnection(data?.touchpoints);

  const plannedTouchPoints = touchPoints.filter(
    (it) => it.status == ActivityStatusDataEnum.PLANNED
  );
  const closedTouchPoints = touchPoints.filter(
    (it) => it.status == ActivityStatusDataEnum.CLOSED
  );

  return (
    <>
      <Grid item>
        <OrganizationActivityDataStaticBlock
          title={'pages.organizationView.plannedTouchpoint'}
          loading={loading}
          activities={plannedTouchPoints}
        />
      </Grid>
      <Grid item>
        <OrganizationActivityDataStaticBlock
          title={'pages.organizationView.touchpointHistory'}
          loading={loading}
          activities={closedTouchPoints}
        />
      </Grid>
    </>
  );
};
