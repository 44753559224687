import { Grid } from '@mui/material';
import { ActivityData } from '@ysura/common';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { useUpdateActivityMutation } from '@/services/graphql/hooks';
import { UpdateActivityAction } from '@/services/graphql/types';
import {
  AttendeeColumn,
  TouchpointTypeColumn,
} from '@/views/Activity/TouchpointManagement';

import {
  getDefaultActivityStatusValue,
  prepareDataForUpdateActivityMutation,
} from './helper';

type EditActivityInformationDialogProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  activity: ActivityData;
};

export const EditActivityInformationDialog = ({
  activity,
  isOpen,
  onClose,
}: EditActivityInformationDialogProps) => {
  // startDateTime can be invalid if activity is CLOSED and the newly
  // selected startDateTime > new Date()
  const [isStartDateTimeValid, setIsStartDateTimeValid] = useState(true);
  // endDateTime depends on the selected activity type, startDateTime & default duration
  // therefore, we need additional validation to ensure it has a correct value
  const [isEndDateTimeValid, setIsEndDateTimeValid] = useState(true);

  const { t } = useTranslation();
  const [updateActivityMutation, { loading: isLoading }] =
    useUpdateActivityMutation();

  const {
    handleSubmit,
    getFieldProps,
    isSubmitting,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      // activity column
      startDate: !activity?.startDateTime ? activity?.startDate ?? null : null,
      startDateTime: activity?.startDateTime ?? null,
      endDate: !activity?.endDateTime ? activity?.endDate ?? null : null,
      endDateTime: activity?.endDateTime ?? null,
      touchpointStatus: getDefaultActivityStatusValue(activity),
      touchpointType: activity.activityType ?? null,
      // attendee column
      organization: activity.organization,
    },
    onSubmit: async (values) => {
      const changes: UpdateActivityAction =
        prepareDataForUpdateActivityMutation(values, activity);

      updateActivityMutation({
        ...errorConfigBannerDialog,
        variables: {
          oid: activity.oid ?? '',
          actions: [changes],
        },
        onCompleted: () => {
          resetDateTimeValidation();
          resetForm({ values });
          onClose();
        },
      });
    },
  });

  const handleClose = () => {
    resetForm();
    resetDateTimeValidation();
    onClose();
  };

  const resetDateTimeValidation = () => {
    setIsEndDateTimeValid(true);
    setIsStartDateTimeValid(true);
  };

  return (
    <ActionDialog
      isOpen={isOpen}
      isLoading={isLoading}
      title={t('components.touchpointManagement.touchpointInfo')}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      isPrimaryButtonDisabled={!isEndDateTimeValid || !isStartDateTimeValid}
      testId="edit-touchpoint-info-dialog"
      onClickPrimaryButton={handleSubmit}
      onClickSecondaryButton={handleClose}
    >
      <Grid container spacing={{ md: 3, xs: 2 }}>
        <Grid item md={6} xs={12}>
          <TouchpointTypeColumn
            mode="edit"
            activity={activity}
            setFieldValue={setFieldValue}
            touchpointValues={values}
            isSubmitting={isSubmitting}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <AttendeeColumn
            mode="edit"
            activity={activity}
            getFieldProps={getFieldProps}
            setFieldValue={setFieldValue}
            touchpointValues={values}
            isSubmitting={isSubmitting}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  );
};
