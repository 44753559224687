import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ColumnContentWrapper,
  RequiredField,
  SectionLabel,
  SectionWrapper,
} from '@/views/Activity/TouchpointManagement/SharedStyledComponents';

export const TopicsCardSkeleton = () => {
  const { t } = useTranslation();

  return (
    <ColumnContentWrapper data-testid="touchpoint-topics-skeleton">
      <SectionWrapper>
        <SectionLabel variant="subtitle2">
          {`${t('components.touchpointManagement.topics')} (...)`}
          <RequiredField>*</RequiredField>
        </SectionLabel>
        <Skeleton height={56} />
      </SectionWrapper>
    </ColumnContentWrapper>
  );
};
