import { Box, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageHeaderSkeleton = ({ showSubtitle = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Wrapper data-testid="page-header-skeleton">
      <Group>
        <ResponsiveSkeleton
          height={30}
          customWidth={isMobile ? '200px' : '400px'}
        />
        {showSubtitle && (
          <ResponsiveSkeleton
            height={24}
            customWidth={isMobile ? '280px' : '480px'}
          />
        )}
      </Group>
      <ActionButtonSkeleton height={36} />
    </Wrapper>
  );
};

const ResponsiveSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'customWidth',
})<{ customWidth: string }>(({ customWidth }) => ({
  width: `${customWidth}`,
}));

const ActionButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 400,

  // On mobile we do not have actions in the header
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Group = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
