import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import {
  Label,
  MediaData,
  OverviewCard,
  ReactionDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { AttendeeReactions } from '@/components/AttendeeReactions';

import { cutMediaDescription } from '../helper';
import {
  ContentWrapper,
  MediaDescription,
  TextWrapper,
  TitleContainer,
} from '../MediaLayoutCard';
import { MediaThumbnail } from '../MediaThumbnail';

export type MediaCardProps = {
  media: MediaData;
  variant: 'short' | 'long';
  isDisplayReactions: boolean;
  isReactionsEditable?: boolean;
  selectedReaction?: ReactionDataEnum;
  isDisplayViewedLabel?: boolean;
  isViewed?: boolean;
  isMediaClickable?: boolean;
  isDisplayDeleteButton?: boolean;
  onChangeReaction?: (mediaId: string, reaction: ReactionDataEnum) => void;
  onDeleteMedia?: (media: MediaData) => void;
  onChangeMediaWithDocument?: (media: MediaData) => void;
  onChangePresentationDialogState?: (val: boolean) => void;
  onClickCard?: (value: MediaData) => void;
};

export const MediaCard = ({
  media,
  variant,
  isDisplayReactions,
  isReactionsEditable = false,
  selectedReaction,
  isMediaClickable = true,
  isDisplayViewedLabel = false,
  isViewed = false,
  isDisplayDeleteButton = false,
  onDeleteMedia,
  onChangeReaction,
  onChangeMediaWithDocument,
  onClickCard,
}: MediaCardProps) => {
  const { t } = useTranslation();

  const handleClickDeleteButton = () => {
    onDeleteMedia?.(media);
  };

  const handleClickCard = async () => {
    if (isMediaClickable) {
      onChangeMediaWithDocument?.(media);
      onClickCard?.(media);
    }
  };

  return (
    <OverviewCard
      testId="media_card_wrapper"
      handleClickCard={isMediaClickable ? handleClickCard : undefined}
    >
      <ContentWrapper>
        <MediaThumbnail media={media} />

        <TextWrapper>
          <TitleContainer>
            <Typography noWrap variant="subtitle2">
              {media.fileName}
            </Typography>

            {isDisplayDeleteButton && (
              <Box>
                <IconButton
                  color="error"
                  size="small"
                  onClick={handleClickDeleteButton}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </TitleContainer>
          <MediaDescription variant="caption">
            {variant === 'long'
              ? media?.description
              : cutMediaDescription(media?.description)}
          </MediaDescription>
        </TextWrapper>

        {isDisplayViewedLabel && (
          <Box>
            <Label variant="ghost" color={isViewed ? 'success' : 'default'}>
              {isViewed
                ? t('components.mediaCard.viewed')
                : t('components.mediaCard.notViewed')}
            </Label>
          </Box>
        )}

        {isDisplayReactions && (
          <>
            <Divider />
            <AttendeeReactions
              reaction={selectedReaction}
              isClickable={isReactionsEditable}
              onChangeReaction={(reaction: ReactionDataEnum) =>
                onChangeReaction?.(media?.oid ?? '', reaction)
              }
            />
          </>
        )}
      </ContentWrapper>
    </OverviewCard>
  );
};
