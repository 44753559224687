import {
  OrganizationConnection,
  PersonConnection,
} from '@/services/graphql/types';

export const typePolicies = {
  Query: {
    fields: {
      persons: {
        /**
         * keyArgs specifies which of the field's arguments cause the cache to store a
         * separate value for each unique combination of those arguments.
         */
        keyArgs: ['search'],
        merge(
          existing: PersonConnection | undefined,
          incoming: PersonConnection
        ) {
          /**
           * This will ensure the merging of the edges below will not break. When
           * running the query for the first time, exisitng will be undefined.
           */
          if (
            !existing ||
            !existing?.edges?.length ||
            !incoming?.edges?.length
          ) {
            return incoming;
          }

          return {
            ...incoming,
            // merging the personEdges array into one (used for infinite scrolling)
            edges: [...existing.edges, ...incoming.edges],
          };
        },
      },
      organizations: {
        /**
         * keyArgs specifies which of the field's arguments cause the cache to store a
         * separate value for each unique combination of those arguments.
         */
        keyArgs: ['search'],
        merge(
          existing: OrganizationConnection | undefined,
          incoming: OrganizationConnection
        ) {
          /**
           * This will ensure the merging of the edges below will not break. When
           * running the query for the first time, existing will be undefined.
           */
          if (
            !existing ||
            !existing?.edges?.length ||
            !incoming?.edges?.length
          ) {
            return incoming;
          }

          return {
            ...incoming,
            // merging the organizationEdges array into one (used for infinite scrolling)
            edges: [...existing.edges, ...incoming.edges],
          };
        },
      },
    },
  },
};
