import { gql } from '@apollo/client';

export const addMediaTrackingInfo = gql`
  mutation addMediaTrackingInfo(
    $oid: MediaTrackingInfoId!
    $mediaId: MediaId!
    $mediaVersionId: MediaVersionId!
    $shownTime: DateTime!
    $presenterPersonId: PersonId!
    $audiencePersonId: PersonId
    $data: [MediaTrackingInfoDataInput!]!
    $state: State!
  ) {
    addMediaTrackingInfo(
      input: {
        audiencePersonId: $audiencePersonId
        data: $data
        mediaId: $mediaId
        mediaVersionId: $mediaVersionId
        oid: $oid
        presenterPersonId: $presenterPersonId
        shownTime: $shownTime
        state: $state
      }
    ) {
      id
      oid
    }
  }
`;
