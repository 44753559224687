import { Skeleton, Typography } from '@mui/material';
import { OrganizationCardSkeleton, OrganizationData } from '@ysura/common';

import { EditIconButton } from '@/components/Button';
import {
  OrganizationDataContent,
  OrganizationDataStaticBlock,
} from '@/components/Organization';

interface OrganizationBasicDataCardProps {
  isLoading?: boolean;
  organization?: OrganizationData;
}

export const OrganizationBasicDataCard = ({
  isLoading,
  organization,
}: OrganizationBasicDataCardProps) => {
  if (isLoading) {
    return (
      <OrganizationDataStaticBlock
        title={'pages.organizationView.organizationDataCard.organizationData'}
        subTitle={
          <Typography variant="subtitle2">
            <Skeleton width={120} />
          </Typography>
        }
      >
        <OrganizationCardSkeleton />
      </OrganizationDataStaticBlock>
    );
  }

  return (
    <OrganizationDataStaticBlock
      title={'pages.organizationView.organizationDataCard.organizationData'}
      subTitle={
        <EditIconButton
          testId="touchpoint-info-edit-button"
          onClick={() => {}}
        />
      }
    >
      <>
        {organization && (
          <OrganizationDataContent organization={organization} />
        )}
      </>
    </OrganizationDataStaticBlock>
  );
};
