import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Stack, Theme, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { IconWrapper, ReactionDataEnum } from '@ysura/common';

import { Reaction } from '@/services/graphql/types';

type AttendeeReactionsProps = {
  reaction?: ReactionDataEnum;
  isClickable?: boolean;
  onChangeReaction?: (reaction: ReactionDataEnum) => void;
};

export const AttendeeReactions = ({
  reaction,
  isClickable = false,
  onChangeReaction,
}: AttendeeReactionsProps) => {
  const theme = useTheme();

  const getBackgroundColor = (reaction: ReactionDataEnum) => {
    if (reaction === ReactionDataEnum.NEGATIVE) {
      return alpha(theme.palette.error.main, 0.08);
    }

    if (reaction === ReactionDataEnum.POSITIVE) {
      return alpha(theme.palette.success.main, 0.08);
    }

    // For Neutral reaction
    return alpha(theme.palette.primary.main, 0.08);
  };

  const reactionHandler = (newReaction: ReactionDataEnum) => {
    if (isClickable) {
      // if the same icon is clicked again, we un-highlight
      if (reaction === newReaction) {
        onChangeReaction?.(ReactionDataEnum.NONE);
      } else {
        onChangeReaction?.(newReaction);
      }
    }
  };

  return (
    <Wrapper isClickable={isClickable}>
      <IconWrapper
        size={32}
        backgroundColor={
          reaction === ReactionDataEnum.NEGATIVE
            ? getBackgroundColor(ReactionDataEnum.NEGATIVE)
            : 'none'
        }
        onClick={() => reactionHandler(ReactionDataEnum.NEGATIVE)}
      >
        <SadIcon isMarked={reaction === ReactionDataEnum.NEGATIVE} />
      </IconWrapper>

      <IconWrapper
        size={32}
        backgroundColor={
          reaction === ReactionDataEnum.NEUTRAL
            ? getBackgroundColor(ReactionDataEnum.NEUTRAL)
            : 'none'
        }
        onClick={() => reactionHandler(ReactionDataEnum.NEUTRAL)}
      >
        <NeutralIcon isMarked={reaction === ReactionDataEnum.NEUTRAL} />
      </IconWrapper>

      <IconWrapper
        size={32}
        backgroundColor={
          reaction === ReactionDataEnum.POSITIVE
            ? getBackgroundColor(ReactionDataEnum.POSITIVE)
            : 'none'
        }
        onClick={() => reactionHandler(ReactionDataEnum.POSITIVE)}
      >
        <HappyIcon isMarked={reaction === ReactionDataEnum.POSITIVE} />
      </IconWrapper>
    </Wrapper>
  );
};

const getIconColor = (reaction: Reaction, theme: Theme) => {
  if (reaction === Reaction.NEGATIVE) {
    return theme.palette.error.main;
  }

  if (reaction === Reaction.POSITIVE) {
    return theme.palette.success.main;
  }

  // For Neutral reaction
  return theme.palette.text.secondary;
};

type IconProps = {
  isMarked: boolean;
};

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isClickable',
})<{ isClickable: boolean }>(({ theme, isClickable }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),

  '.MuiBox-root': {
    cursor: isClickable ? 'pointer' : 'initial',
  },
}));

const SadIcon = styled(SentimentVeryDissatisfiedIcon, {
  shouldForwardProp: (prop) => prop !== 'isMarked',
})<IconProps>(({ isMarked, theme }) => ({
  fontSize: 24,
  color: isMarked
    ? getIconColor(Reaction.NEGATIVE, theme)
    : theme.palette.text.disabled,
}));

const NeutralIcon = styled(SentimentNeutralIcon, {
  shouldForwardProp: (prop) => prop !== 'isMarked',
})<IconProps>(({ isMarked, theme }) => ({
  fontSize: 24,
  color: isMarked
    ? getIconColor(Reaction.NEUTRAL, theme)
    : theme.palette.text.disabled,
}));

const HappyIcon = styled(SentimentSatisfiedAltIcon, {
  shouldForwardProp: (prop) => prop !== 'isMarked',
})<IconProps>(({ isMarked, theme }) => ({
  fontSize: 24,
  color: isMarked
    ? getIconColor(Reaction.POSITIVE, theme)
    : theme.palette.text.disabled,
}));
