import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import {
  ToggleCameraButton,
  ToggleMicButton,
} from '@/views/Interaction/Remote/Buttons';

export const AudioVideoControls = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container data-testid="audio-video-controls">
      <ToggleMicButton size={isSmallScreen ? 'medium' : 'large'} />
      <ToggleCameraButton size={isSmallScreen ? 'medium' : 'large'} />
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));
