import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetSystemConfigurationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSystemConfigurationQuery = {
  __typename?: 'Query';
  systemConfiguration?: {
    __typename?: 'SystemConfiguration';
    id: string;
    sampleRequestAdditionalDeliveryInformationEnabled: boolean;
    sampleRequestDeliveryDateEnabled: boolean;
    sampleRequestDeliveryDateMinimumDaysInFuture: number;
    defaultCountry: {
      __typename?: 'Country';
      id: string;
      oid: string;
      alpha2Code?: string | null;
    };
  } | null;
};

/**
 * __useGetSystemConfigurationQuery__
 *
 * To run a query within a React component, call `useGetSystemConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSystemConfigurationQuery,
    GetSystemConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetSystemConfigurationQuery,
    GetSystemConfigurationQueryVariables
  >(Operations.getSystemConfiguration, options);
}
export function useGetSystemConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemConfigurationQuery,
    GetSystemConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetSystemConfigurationQuery,
    GetSystemConfigurationQueryVariables
  >(Operations.getSystemConfiguration, options);
}
export function useGetSystemConfigurationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSystemConfigurationQuery,
    GetSystemConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetSystemConfigurationQuery,
    GetSystemConfigurationQueryVariables
  >(Operations.getSystemConfiguration, options);
}
export type GetSystemConfigurationQueryHookResult = ReturnType<
  typeof useGetSystemConfigurationQuery
>;
export type GetSystemConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetSystemConfigurationLazyQuery
>;
export type GetSystemConfigurationSuspenseQueryHookResult = ReturnType<
  typeof useGetSystemConfigurationSuspenseQuery
>;
export type GetSystemConfigurationQueryResult = Apollo.QueryResult<
  GetSystemConfigurationQuery,
  GetSystemConfigurationQueryVariables
>;
