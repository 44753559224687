/*
 * This clears the persistant local storage if the activity id is
 * different from the one in the local storage. This is to prevent
 * stale persistant local storage from being used in the interaction.
 */

export const clearPersistentLocalStorage = (activityId: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const rawPersistantLocalStorage = window?.localStorage?.getItem(
    'persitantInteractionState'
  );

  if (rawPersistantLocalStorage) {
    let persistantLocalStorage;
    try {
      persistantLocalStorage = JSON.parse(rawPersistantLocalStorage);
    } catch (error) {
      console.log(error);

      return;
    }

    const persistantActivityId = persistantLocalStorage?.originalActivity?.id;

    if (persistantActivityId !== activityId) {
      window.localStorage.removeItem('persitantInteractionState');
    }
  }
};
