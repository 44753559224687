import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InfiniteScrollingPersonCardSkeleton, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  AttendeesInfoList,
  AttendeesInfoListSkeleton,
} from '@/components/Attendee';
import { getScrollbarWidth } from '@/utils/scrollbar';

import { BaseFormikProps } from '../types';

interface AttendeeSearchResultsProps extends Partial<BaseFormikProps> {
  loading: boolean;
  persons: Array<PersonData>;
  filteredCount: number;
  fetchMorePersons: VoidFunction;
  hasMorePerson: boolean;
  onCloseSearch: VoidFunction;
  dialogHeight?: number;
  isSubmitting?: boolean;
}

export const AttendeeSearchResults = ({
  loading,
  persons,
  fetchMorePersons,
  hasMorePerson,
  filteredCount,
  dialogHeight,
  onCloseSearch,
  setFieldValue,
  isSubmitting = false,
}: AttendeeSearchResultsProps) => {
  const { t } = useTranslation();
  const scrollbarWidth = getScrollbarWidth();

  if (loading) {
    return <AttendeesInfoListSkeleton />;
  }

  return (
    <ResultWrapper
      id="attendee-search-dialog"
      dialogHeight={dialogHeight}
      scrollbarWidth={scrollbarWidth}
    >
      <CounterResultsBox>
        <Typography variant="subtitle2">
          {t('components.globalSearch.searchResults')}
        </Typography>
        <Typography variant="body2">{`(${
          filteredCount !== -1 ? filteredCount : '...'
        })`}</Typography>
      </CounterResultsBox>
      <InfiniteScroll
        dataLength={persons?.length ?? 0}
        next={fetchMorePersons}
        hasMore={hasMorePerson}
        scrollThreshold={0.7}
        scrollableTarget="attendee-search-dialog"
        loader={<InfiniteScrollingPersonCardSkeleton />}
      >
        <AttendeesInfoList
          attendees={persons}
          variant={'add'}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          onCloseSearch={onCloseSearch}
        />
      </InfiniteScroll>
    </ResultWrapper>
  );
};

type ResultWrapperProps = {
  scrollbarWidth?: number;
  dialogHeight?: number;
};

const ResultWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'dialogHeight' && prop !== 'scrollbarWidth',
})<ResultWrapperProps>(({ theme, dialogHeight, scrollbarWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  // 198px is the height from top of Dialog content to the infinity scroll component
  height: `calc(${dialogHeight + 'px'} - 198px - ${theme.spacing(-2)})`,
  /**
   * add marginRight so the search field inside the dialog
   * doesn't "jump" compared to the search field inside the header.
   */
  overflow: 'auto',
  marginRight: scrollbarWidth ? -scrollbarWidth : 0,
}));

const CounterResultsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
}));
