import { TouchPointGroupData } from '@ysura/common';

import {
  TouchPointGroup,
  TouchPointGroupConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseTouchpointGroup = (
  touchpointGroup: DeepPartial<TouchPointGroup>
): TouchPointGroupData => {
  return {
    id: touchpointGroup.id,
    oid: touchpointGroup.oid,
    name: touchpointGroup.name?.toLowerCase(),
    state: touchpointGroup.state,
  };
};

export const parseTouchpointGroupConnection = (
  touchpointGroupConnection?: DeepPartial<TouchPointGroupConnection>
): Array<TouchPointGroupData> => {
  const rawTouchPointGroups = touchpointGroupConnection?.edges ?? [];
  const mappedTouchPointGroups = rawTouchPointGroups
    .map((edge) => edge?.node ?? null)
    .map((touchpointGroup) =>
      touchpointGroup ? parseTouchpointGroup(touchpointGroup) : null
    );

  return filterNonNull(mappedTouchPointGroups);
};
