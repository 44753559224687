import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDateTime } from '@/services/graphql/hooks/mutations/helpers/helper';

import {
  CollectManualPersonConsentMutation,
  CollectManualPersonConsentMutationVariables,
  useCollectManualPersonConsentMutation as useCollectManualPersonConsentMutationApollo,
} from '../generated';

type CollectManualPersonConsentVariables =
  // oid, consentTypeId, signerPersonId, consentItemsValues are required
  Pick<
    CollectManualPersonConsentMutationVariables,
    | 'oid'
    | 'consentTypeId'
    | 'signerPersonId'
    | 'consentItemsValues'
    | 'manualProcessReason'
  > &
    // issuerPersonId, actionAt are optional
    Partial<
      Pick<
        CollectManualPersonConsentMutationVariables,
        'issuerPersonId' | 'actionAt'
      >
    >;

/**
 * __useCollectManualPersonConsentMutation__
 *
 * To run a mutation, you first call `useCollectManualPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectManualPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectManualPersonConsent, { data, loading, error }] = useCollectManualPersonConsentMutation();
 *
 * collectManualPersonConsent({
 *   variables: {
 *      oid: // defaults to a new uuid
 *      consentTypeId: // Oid of the consent type
 *      signerPersonId: // Oid of the person signing the consent
 *      consentItemsValues: [], consent item values
 *      manualProcessReason: // Oid of the manual reason for the consent
 *
 *      // Optional
 *      issuerPersonId: // defaults to the current user
 *      actionAt: // defaults to the current timestamp
 *   },
 * });
 */
export const useCollectManualPersonConsentMutation = (
  baseOptions?: MutationHookOptions<
    CollectManualPersonConsentMutation,
    CollectManualPersonConsentMutationVariables
  >
) => {
  const { currentUser } = useCurrentUser();

  const [collectManualPersonConsent, status] =
    useCollectManualPersonConsentMutationApollo({
      // We invalidate all queries related to consent to refresh the data
      refetchQueries: ['getPersonConsentList'],

      ...baseOptions,
    });

  const doMutate = async (
    options: MutationFunctionOptions<
      CollectManualPersonConsentMutation,
      CollectManualPersonConsentVariables
    >
  ) => {
    const defaultVariables = {
      issuerPersonId: currentUser?.person?.oid ?? '',
      actionAt: buildCurrentDateTime(),

      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      consentTypeId: '',
      signerPersonId: '',
      consentItemsValues: [],
      manualProcessReason: '',
    };

    return collectManualPersonConsent({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
