import { Grid, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

import { ActivityCardList } from './ActivityCardList';
import { CalendarCard } from './Calendar';

export interface CalendarActivitiesStaticBlockProps {
  startingDate?: Date;
}

export const CalendarActivitiesStaticBlock = ({
  startingDate = new Date(),
}: CalendarActivitiesStaticBlockProps) => {
  const isMatchedMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const [selectedDate, setSelectedDate] = useState(startingDate);
  const { t } = useTranslation();

  const onChangeDate = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  return (
    <OverviewStaticBlock
      title={t('pages.homepage.touchpointList.touchpoints')}
      testId="calendar-activities-static-block"
    >
      <Grid container spacing={3}>
        {!isMatchedMediaQuery && (
          <Grid item xs>
            <CalendarCard
              selectedDate={selectedDate}
              onChangeDate={onChangeDate}
            />
          </Grid>
        )}
        <Grid item xs>
          <ActivityCardList
            selectedDate={selectedDate}
            onChangeDate={onChangeDate}
          />
        </Grid>
      </Grid>
    </OverviewStaticBlock>
  );
};
