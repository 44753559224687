import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AttributeOptionFilterData,
  FacetFilterData,
  OptionFilterData,
  SubFacetFilterData,
} from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterChangeEvent } from '@/components/FilterCategory/FilterCategory';
import { FilterTitleCategory } from '@/components/FilterCategory/FilterTitleCategory';

type MultiItemsDisplayCategoryProps = {
  title: string | undefined;
  value: FacetFilterData;
  onChangeFilters?: FilterChangeEvent;
};

export const MultiItemsDisplayCategory = ({
  title,
  value,
  onChangeFilters,
}: MultiItemsDisplayCategoryProps) => {
  const { t } = useTranslation();

  const numberOfSelectedFilters = value?.options
    ? value?.options?.filter(
        (item: AttributeOptionFilterData) => item.isSelected
      ).length
    : 0;

  const { id, options, subFacets } = value;

  const countOptionsInSubFacet = (subFacet: SubFacetFilterData) =>
    subFacet.options?.filter((it) => it.isSelected).length ?? 0;

  return (
    <StyledFormControl key={id} variant="outlined">
      {title && (
        <FilterTitleCategory
          title={title}
          numberOfSelectedFilters={numberOfSelectedFilters}
        />
      )}

      <FormGroup>
        {options &&
          options.map((option: OptionFilterData, index: number) => (
            <StyledFormControlLabel
              key={option.id ?? index}
              control={
                <Checkbox
                  data-testid="filter-checkbox"
                  checked={!!option.isSelected}
                  name={option.oid ?? option.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onChangeFilters?.(event, id ?? '')
                  }
                />
              }
              label={t(option.name ?? '')}
            />
          ))}

        {subFacets &&
          subFacets.map((subFacet: SubFacetFilterData, index: number) => (
            <Box key={subFacet.id ?? index}>
              {index === 0 && (
                <StyledTypography variant="subtitle2">
                  {title} Content
                </StyledTypography>
              )}
              <FilterTitleCategory
                title={subFacet.name ?? ''}
                numberOfSelectedFilters={countOptionsInSubFacet(subFacet)}
                applyBold={false}
              />
              {subFacet.options &&
                subFacet.options.map(
                  (option: OptionFilterData, subIndex: number) => (
                    <StyledFormControlLabel
                      key={option.id ?? subIndex}
                      control={
                        <Checkbox
                          data-testid="filter-checkbox"
                          checked={!!option.isSelected}
                          name={option.oid ?? option.name}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            onChangeFilters?.(event, id ?? '')
                          }
                        />
                      }
                      label={t(option.name ?? '')}
                    />
                  )
                )}
            </Box>
          ))}
      </FormGroup>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  gap: theme.spacing(1),
  paddingRight: '5px',
  paddingBottom: '15px',
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  overflowWrap: 'anywhere',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  paddingRight: '5px',
  paddingTop: '15px',
  paddingBottom: '15px',
}));
