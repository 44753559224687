import { ActivityStatusDataEnum } from '@ysura/common';

import { PromotionalMaterialEditDialog } from '@/components/PromotionalMaterial';

import { useUpdateActivityState } from '../UpdateActivityState';

export const PromotionalMaterialsDialogContent = () => {
  const {
    selectedPromotionalMaterials,
    changePromotionalMaterials,
    currentActivity,
  } = useUpdateActivityState();

  return (
    currentActivity && (
      <PromotionalMaterialEditDialog
        isRestrictedEdit={
          currentActivity?.status === ActivityStatusDataEnum.CLOSED
        }
        activity={currentActivity}
        promotionalMaterialGroups={selectedPromotionalMaterials}
        onChangePromats={changePromotionalMaterials}
      />
    )
  );
};
