import { gql } from '@apollo/client';

export const updatePersonConsentItems = gql`
  mutation updatePersonConsentItems(
    $oid: PersonConsentId!
    $issuerPersonId: PersonId!
    $actionAt: DateTime!
    $consentItemsValues: [PersonConsentItemValueInput!]!
  ) {
    updatePersonConsentItems(
      input: {
        oid: $oid
        issuerPersonId: $issuerPersonId
        actionAt: $actionAt
        consentItemsValues: $consentItemsValues
      }
    ) {
      id
      oid
    }
  }
`;
