import {
  ActivityStatusDataEnum,
  CommentTargetTypeDataEnum,
  ReactionDataEnum,
} from '@ysura/common';

import {
  mapperAddressTyperDataEnum,
  mapperAttributeTypeDataEnum,
  mapperCommunicationDataBaseTypeDataEnum,
  mapperConsentActionDataEnum,
  mapperConsentItemStateChangeDataEnum,
  mapperConsentTypeValidityModeDateEnum,
  mapperGenderDataEnum,
  mapperOrganizationBaseTypeDataEnum,
  mapperPersonConsentLifecycleStateDataEnum,
  mapperPromotionalMaterialTypeDataEnum,
  mapperReaction,
  mapperReactionDataEnum,
  mapperSpecialityClassificationDataEnum,
  mapperStockTypeDataEnum,
  mapperSupportedMediaTypeDataEnum,
  mapperTemplateTypeDataEnum,
  mapperToActivityStatus,
  mapperToActivityStatusDataEnum,
  mapperToActivityTypeBaseTypeDataEnum,
  mapperToCallTypeDataEnum,
  mapperToCommentTargetType,
  mapperToCommentTargetTypeDataEnum,
  mapperToDataPermissionTypeDataEnum,
  mapperToGivenPromotionalMaterialChangeReasonDataEnum,
  mapperToLifecycleStatusDataEnum,
  mapperToPersonAssignmentTypeBaseTypeDataEnum,
  mapperToStateDataEnum,
} from '@/services/graphql/mappers';
import {
  ActivityStatus,
  ActivityTypeBaseType,
  AddressType,
  AttributeType,
  CallType,
  CommentTargetType,
  CommunicationDataBaseType,
  ConsentAction,
  ConsentItemStateChange,
  ConsentTypeValidityMode,
  DataPermissionType,
  Gender,
  GivenPromotionalMaterialChangeReason,
  LifecycleStatus,
  OrganizationBaseType,
  PersonAssignmentBaseType,
  PersonConsentLifecycleState,
  PromotionalMaterialType,
  Reaction,
  SpecialityClassification,
  State,
  StockType,
  SupportedMediaType,
  TemplateType,
} from '@/services/graphql/types';

export const convertedState = (state: State | undefined | null) =>
  state ? mapperToStateDataEnum(state) : undefined;

export const convertedLifecycleStatus = (
  status: LifecycleStatus | undefined | null
) => (status ? mapperToLifecycleStatusDataEnum(status) : undefined);

export const convertedActivityStatus = (status: ActivityStatus | undefined) =>
  status ? mapperToActivityStatusDataEnum(status) : undefined;

export const convertedActivityStatusDataEnum = (
  status: ActivityStatusDataEnum | undefined
) => (status ? mapperToActivityStatus(status) : undefined);

export const convertedCallType = (callType: CallType | undefined) =>
  callType ? mapperToCallTypeDataEnum(callType) : undefined;

export const convertedCommentTargetType = (
  commentTargetType: CommentTargetType | undefined
) =>
  commentTargetType
    ? mapperToCommentTargetTypeDataEnum(commentTargetType)
    : undefined;

export const convertedCommentTargetTypeDataEnum = (
  commentTargetTypeDataEnum: CommentTargetTypeDataEnum | undefined
) =>
  commentTargetTypeDataEnum
    ? mapperToCommentTargetType(commentTargetTypeDataEnum)
    : undefined;

export const convertedDataPermissionType = (
  dataPermissionType: DataPermissionType | undefined
) =>
  dataPermissionType
    ? mapperToDataPermissionTypeDataEnum(dataPermissionType)
    : undefined;

export const convertedGivenPromotionalMaterialChangeReason = (
  reason: GivenPromotionalMaterialChangeReason | undefined | null
) =>
  reason
    ? mapperToGivenPromotionalMaterialChangeReasonDataEnum(reason)
    : undefined;

export const convertedActivityTypeBaseType = (
  activityTypeBaseType: ActivityTypeBaseType | undefined
) =>
  activityTypeBaseType
    ? mapperToActivityTypeBaseTypeDataEnum(activityTypeBaseType)
    : undefined;

export const convertedAddressType = (addressType: AddressType | undefined) =>
  addressType ? mapperAddressTyperDataEnum(addressType) : undefined;

export const convertedCommunicationDataBaseType = (
  communicationDataBaseType: CommunicationDataBaseType | undefined | null
) =>
  communicationDataBaseType
    ? mapperCommunicationDataBaseTypeDataEnum(communicationDataBaseType)
    : undefined;

export const convertedStockType = (stockType: StockType | undefined | null) =>
  stockType ? mapperStockTypeDataEnum(stockType) : undefined;

export const convertedReaction = (reaction: Reaction | undefined) =>
  reaction ? mapperReactionDataEnum(reaction) : undefined;

export const convertedReactionDataEnum = (
  reaction: ReactionDataEnum | undefined
) => (reaction ? mapperReaction(reaction) : undefined);

export const convertedSupportedMediaType = (
  supportedMediaType: SupportedMediaType | undefined | null
) =>
  supportedMediaType
    ? mapperSupportedMediaTypeDataEnum(supportedMediaType)
    : undefined;

export const convertedOrganizationBaseType = (
  organizationBaseType: OrganizationBaseType | undefined | null
) =>
  organizationBaseType
    ? mapperOrganizationBaseTypeDataEnum(organizationBaseType)
    : undefined;

export const convertedGender = (gender: Gender | undefined | null) =>
  gender ? mapperGenderDataEnum(gender) : undefined;

export const convertedPersonConsentLifecycleState = (
  personConsentLifecycleState: PersonConsentLifecycleState | undefined
) =>
  personConsentLifecycleState
    ? mapperPersonConsentLifecycleStateDataEnum(personConsentLifecycleState)
    : undefined;

export const convertedConsentAction = (
  consentAction: ConsentAction | undefined
) => (consentAction ? mapperConsentActionDataEnum(consentAction) : undefined);

export const convertedConsentItemStateChange = (
  consentItemStateChange: ConsentItemStateChange | undefined | null
) =>
  consentItemStateChange
    ? mapperConsentItemStateChangeDataEnum(consentItemStateChange)
    : undefined;

export const convertedConsentTypeValidityMode = (
  consentTypeValidityMode: ConsentTypeValidityMode | undefined
) =>
  consentTypeValidityMode
    ? mapperConsentTypeValidityModeDateEnum(consentTypeValidityMode)
    : undefined;

export const convertedTemplateType = (
  templateType: TemplateType | undefined
) => (templateType ? mapperTemplateTypeDataEnum(templateType) : undefined);

export const convertedPromotionalMaterialType = (
  promotionalMaterialType: PromotionalMaterialType | undefined
) =>
  promotionalMaterialType
    ? mapperPromotionalMaterialTypeDataEnum(promotionalMaterialType)
    : undefined;

export const convertedPersonAssignmentBaseType = (
  baseType: PersonAssignmentBaseType | undefined
) =>
  baseType ? mapperToPersonAssignmentTypeBaseTypeDataEnum(baseType) : undefined;

export const convertedAttributeType = (
  attributeType: AttributeType | undefined
) => (attributeType ? mapperAttributeTypeDataEnum(attributeType) : undefined);

export const convertedSpecialityClassification = (
  specialityClassification: SpecialityClassification | undefined | null
) =>
  specialityClassification
    ? mapperSpecialityClassificationDataEnum(specialityClassification)
    : undefined;
