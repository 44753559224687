import { NetworkStatus } from '@apollo/client';
import { Box } from '@mui/material';
import {
  EntityFilterTypeDataEnum,
  FilterItemsData,
  PersonData,
} from '@ysura/common';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  DesktopContentSearchCard,
  DisplaySearchResults,
  FilterCard,
  MobileSearchCard,
} from '@/components/GlobalSearch';
import { FilterInputSearch } from '@/components/GlobalSearch/components';
import { PersonSearchResultCard } from '@/components/GlobalSearch/person';
import {
  countSelectedItemFilters,
  NUMBER_OF_PERSONS_TO_SEARCH,
  parsePersonFilters,
} from '@/components/GlobalSearch/utils';
import { errorConfigBannerDialog } from '@/hooks';
import { buildPersonFilterQuery } from '@/services/graphql/helpers';
import {
  useGetCurrentUserPreferenceQuery,
  useGetSearchedPersonsLazyQuery,
} from '@/services/graphql/hooks';
import {
  parsePersonConnection,
  parsePersonPreference,
} from '@/services/graphql/parsers';
import { Scope } from '@/services/graphql/types';

type PersonSearchTabProps = {
  isOpen: boolean;
  isMobileSearchActive: boolean;
  searchQuery: string;
  closeDialog: VoidFunction;
  changeSearchQuery: (value: string) => void;
};

export const PersonSearchTab = ({
  isOpen,
  isMobileSearchActive,
  searchQuery,
  closeDialog,
  changeSearchQuery,
}: PersonSearchTabProps) => {
  // Hooks
  const [personFiltersData, setPersonFiltersData] = useState<FilterItemsData>(
    []
  );

  const [filterScope, setFilterScope] = useState<Scope>(Scope.TERRITORY);

  // States
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    searchQuery ?? ''
  );
  const [showMobileFilterCard, setShowMobileFilterCard] = useState(false);

  // Graphql queries
  const [
    searchPersons,
    {
      data: searchData,
      fetchMore: searchFetchMore,
      called: searchCalled,
      refetch: searchRefetch,
      networkStatus,
    },
  ] = useGetSearchedPersonsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  // Graphql queries
  const { loading: isPreferenceLoading } = useGetCurrentUserPreferenceQuery({
    ...errorConfigBannerDialog,
    onCompleted: (data) => {
      const personFacets = parsePersonPreference(
        data.currentUser?.preference?.facets?.personFacets
      );
      setPersonFiltersData?.(parsePersonFilters(personFacets));
    },
  });

  // Handlers
  const handleFetchMorePersons = () => {
    const endCursor = searchData?.persons?.pageInfo?.endCursor ?? '';

    searchFetchMore({
      variables: {
        after: endCursor,
      },
    });
  };

  const handleClickFilterCard = () => {
    setShowMobileFilterCard((showMobileFilterCard) => !showMobileFilterCard);
  };

  const handleClearSearchQuery = () => {
    changeSearchQuery('');
    setDebouncedSearchQuery('');
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    changeSearchQuery(event.target?.value);
    debouncedFunction(event.target?.value);
  };

  const handleDebounce = (value: string) => {
    setDebouncedSearchQuery(value);
  };

  const handleChangePersonFilter = (value: FilterItemsData) => {
    setPersonFiltersData(value);
  };

  const handleChangeFilterScope = (value: Scope) => {
    setFilterScope(value);
  };

  // Memo
  const debouncedFunction = useMemo(() => debounce(handleDebounce, 500), []);

  // Use effect
  useEffect(() => {
    if (isOpen) {
      const personFilterQuery = buildPersonFilterQuery(
        personFiltersData,
        filterScope
      );

      if (searchCalled) {
        searchRefetch({
          search: debouncedSearchQuery,
          first: NUMBER_OF_PERSONS_TO_SEARCH,
          filter: personFilterQuery,
        });
      } else {
        searchPersons({
          variables: {
            search: debouncedSearchQuery,
            first: NUMBER_OF_PERSONS_TO_SEARCH,
            filter: personFilterQuery,
          },
        });
      }
    }
  }, [
    searchPersons,
    debouncedSearchQuery,
    isOpen,
    personFiltersData,
    filterScope,
    searchCalled,
    searchRefetch,
  ]);

  // Variables
  const filteredCount = searchData?.persons?.filteredCount ?? -1;
  const hasMore = searchData?.persons?.pageInfo?.hasNextPage ?? false;
  const counterSelectedPersonsInFilter =
    countSelectedItemFilters(personFiltersData);
  const isLoading =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  const persons = parsePersonConnection(searchData?.persons);

  // Sub-Components
  const personInputSearch = (
    <FilterInputSearch
      searchQuery={searchQuery}
      isMobileSearchActive={isMobileSearchActive}
      changeSearchQuery={changeSearchQuery}
      testId="person-filter-input-search"
      onClearSearchQuery={handleClearSearchQuery}
      onChangeSearchQuery={handleChangeSearchQuery}
    />
  );

  const filterCard = (
    <FilterCard
      testId="person-filter-card"
      entity={EntityFilterTypeDataEnum.PERSON}
      filters={personFiltersData}
      scope={filterScope}
      isLoading={isPreferenceLoading}
      onChangeFilters={handleChangePersonFilter}
      onChangeScope={handleChangeFilterScope}
      onResetFilter={handleChangePersonFilter}
      onCloseDialog={closeDialog}
    />
  );

  return (
    <Box data-testid="person-search-tab">
      {personInputSearch}

      {showMobileFilterCard && isMobileSearchActive ? (
        <MobileSearchCard
          filterCard={filterCard}
          onClickFilterCard={handleClickFilterCard}
        />
      ) : (
        <DesktopContentSearchCard
          filterCard={filterCard}
          filteredCount={filteredCount}
          counterSelectedItems={counterSelectedPersonsInFilter}
          testId="default-content-person-search-tab"
          onClickFilterCard={handleClickFilterCard}
        >
          {persons && (
            <DisplaySearchResults
              itemsLength={persons.length}
              filteredCount={filteredCount}
              closeDialog={closeDialog}
              searchQuery={debouncedSearchQuery}
              fetchMore={handleFetchMorePersons}
              hasMore={hasMore}
              loading={isLoading}
              testId="display-person-search-results"
            >
              {persons.map((person: PersonData) => (
                <PersonSearchResultCard
                  key={person?.id}
                  person={person}
                  searchQuery={searchQuery}
                  closeDialog={closeDialog}
                />
              ))}
            </DisplaySearchResults>
          )}
        </DesktopContentSearchCard>
      )}
    </Box>
  );
};
