import * as Sentry from '@sentry/react';
import { getPersonFullNameWithTitle } from '@ysura/common';
import { useEffect } from 'react';

import { SENTRY } from '@/config';
import { useAuth } from '@/hooks';
import { useGetCurrentUserLazyQuery } from '@/services/graphql/hooks';
import { parseUserData } from '@/services/graphql/parsers';
import { decideCurrentUserData } from '@/services/user';
import { createPersonInitials } from '@/utils/createPersonInitials';

import { CurrentUserState, useCipState } from './state/useCipState';

export type UseCurrentUser = {
  loading: boolean;
  currentUser: CurrentUserState | null;
  currentUserInitials?: string;
};

export const useCurrentUser = (): UseCurrentUser => {
  const { user: keycloakUserData } = useAuth();
  const { cipState, setCipState } = useCipState();

  const currentUserInitials = createPersonInitials(
    getPersonFullNameWithTitle(cipState?.user?.person)
  );

  const [getCurrentUser, { data, loading, called }] =
    useGetCurrentUserLazyQuery({
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    // Either we have loaded the user data in the past or we are currently waiting for it
    if (loading || cipState?.user?.ysuraUserId) {
      return;
    }

    if (!called) {
      getCurrentUser();
    }
  }, [called, cipState, getCurrentUser, loading]);

  useEffect(() => {
    if (cipState?.user?.ysuraUserId) {
      return;
    }

    if (data?.currentUser) {
      const userData = parseUserData(data.currentUser);
      const newUser = decideCurrentUserData(userData, keycloakUserData);

      if (SENTRY.ENABLED && SENTRY.USER_ENRICHMENT) {
        Sentry.setUser({
          id: newUser?.ysuraUserId,
          username: newUser?.username,
          extra: {
            personId: newUser?.person?.oid,
          },
        });
      }

      setCipState((prevState) => ({
        ...prevState,
        user: newUser,
      }));
    }
  }, [cipState, data?.currentUser, keycloakUserData, setCipState]);

  return {
    loading: loading,
    currentUser: cipState.user,
    currentUserInitials,
  };
};
