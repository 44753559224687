import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import { useCurrentUser } from '@/hooks';
import { buildCurrentDateTime } from '@/services/graphql/hooks/mutations/helpers/helper';

import {
  UpdatePersonConsentItemsMutation,
  UpdatePersonConsentItemsMutationVariables,
  useUpdatePersonConsentItemsMutation as useUpdatePersonConsentItemsMutationApollo,
} from '../generated';

type UpdatePersonConsentVariables =
  // oid, consentTypeId, signerPersonId, consentItemsValues are required
  Pick<
    UpdatePersonConsentItemsMutationVariables,
    'oid' | 'consentItemsValues'
  > &
    // issuerPersonId, actionAt are optional
    Partial<
      Pick<
        UpdatePersonConsentItemsMutationVariables,
        'issuerPersonId' | 'actionAt'
      >
    >;

/**
 * __useUpdatePersonConsentItemsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonConsentItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonConsentItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectManualPersonConsent, { data, loading, error }] = useUpdatePersonConsentItemsMutation();
 *
 * collectManualPersonConsent({
 *   variables: {
 *      oid: // defaults to a new uuid
 *      consentItemsValues: [], consent item values
 *
 *      // Optional
 *      issuerPersonId: // defaults to the current user
 *      actionAt: // defaults to the current timestamp
 *   },
 * });
 */
export const useUpdatePersonConsentItemsMutation = (
  baseOptions?: MutationHookOptions<
    UpdatePersonConsentItemsMutation,
    UpdatePersonConsentItemsMutationVariables
  >
) => {
  const { currentUser } = useCurrentUser();

  const [collectManualPersonConsent, status] =
    useUpdatePersonConsentItemsMutationApollo({
      // We invalidate all queries related to consent to refresh the data
      refetchQueries: ['getPersonConsentList'],

      ...baseOptions,
    });

  const doMutate = async (
    options: MutationFunctionOptions<
      UpdatePersonConsentItemsMutation,
      UpdatePersonConsentVariables
    >
  ) => {
    const defaultVariables = {
      issuerPersonId: currentUser?.person?.oid ?? '',
      actionAt: buildCurrentDateTime(),

      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      consentItemsValues: [],
    };

    return collectManualPersonConsent({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
};
