import { EntityFilterTypeDataEnum } from '@ysura/common';

import { PersonSettingsButton } from '@/components/Settings';
import { OrganizationSettingsButton } from '@/components/Settings/organization';

type FilterSettingsButtonProps = {
  entity: EntityFilterTypeDataEnum;
  onClick: VoidFunction;
};
export const FilterSettingsButton = ({
  entity,
  onClick,
}: FilterSettingsButtonProps) => {
  return (
    <>
      {entity === EntityFilterTypeDataEnum.PERSON && (
        <PersonSettingsButton onClick={onClick} />
      )}
      {entity === EntityFilterTypeDataEnum.ORGANIZATION && (
        <OrganizationSettingsButton onClick={onClick} />
      )}
    </>
  );
};
