import {
  DialogContent as MuiDialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  DeliveryInformation,
  PromotionalMaterialGroupData,
  RequestedSamples,
} from '@ysura/common';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { getAddress } from '@/services/employment';
import {
  useAddSampleRequestFormMutation,
  useGetDeliveryTimeFrameListQuery,
  useGetSystemConfigurationQuery,
} from '@/services/graphql/hooks';
import {
  parseDeliveryTimeFrameConnection,
  parseSystemConfigurationData,
} from '@/services/graphql/parsers';
import {
  buildFilename,
  extractSampleRequestFormItems,
} from '@/views/Interaction/ExpandView/PromotionalMaterial/utils';

import { Button, Dialog, DialogTitle, StyledBox } from './DialogComponents';
import { PromotionalMaterialPreviewDialog } from './PromotionalMaterialPreviewDialog';

type PromotionalMaterialCollectionDialogProps = {
  activity: ActivityData;
  promats: Array<PromotionalMaterialGroupData>;
  onChangePromats: (value: PromotionalMaterialGroupData[]) => void;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const PromotionalMaterialCollectionDialog = ({
  activity,
  promats,
  onChangePromats,
  isOpen,
  onClose,
}: PromotionalMaterialCollectionDialogProps) => {
  const firstAttendee = activity.attendees?.[0]?.person;

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [addressExtension, setAddressExtension] = useState('');
  const [deliveryTimeFrame, setDeliveryTimeFrame] = useState<string | null>(
    null
  );
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [addressValue, setAddressValue] = useState<string | null>(null);
  const [canvasImageDataUrl, setCanvasImageDataUrl] = useState<string>('');
  const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);

  const { setSignedSampleRequestForm, signedSampleRequestForm } =
    useInteraction();
  const { t } = useTranslation();

  const { data: systemConfigurationData } = useGetSystemConfigurationQuery();
  const systemConfiguration = systemConfigurationData?.systemConfiguration
    ? parseSystemConfigurationData(systemConfigurationData.systemConfiguration)
    : null;

  const { data: deliveryTimeFrameData } = useGetDeliveryTimeFrameListQuery();
  const deliveryTimeFrames = parseDeliveryTimeFrameConnection(
    deliveryTimeFrameData?.deliveryTimeFrames
  );
  const [addSampleRequestFormMutation, { loading: isLoading }] =
    useAddSampleRequestFormMutation();

  const isButtonDisabled = !addressValue || !canvasImageDataUrl;

  // Aditional delivery information selection is available only for virtual samples
  const isDeliveryInformationEnabled =
    promats.flatMap(
      (each) =>
        each.batches?.filter(
          (batch) => batch.virtualStock.requestedQuantity > 0
        )
    ).length > 0;

  const handleOnChangeAddressExtension = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAddressExtension(event.target.value);
  };

  const handleChangeAddressCheckbox = (value: string | null) => {
    setAddressValue(value);
  };

  const handleChangeDeliveryTimeFrame = (
    event: SelectChangeEvent<string | null>
  ) => {
    setDeliveryTimeFrame(event.target.value);
  };

  const handleChangeDeliveryDate = (value: Date | null) => {
    console.log(value);
    setDeliveryDate(value);
  };

  const handleChangeSignature = (url: string) => {
    setCanvasImageDataUrl(url);
  };

  const handleCloseDialog = () => {
    onClose();
    setAddressExtension('');
    setCanvasImageDataUrl('');
  };

  const handleSubmitForm = (formContent: string) => {
    if (!firstAttendee || !formContent) {
      return;
    }

    addSampleRequestFormMutation({
      ...errorConfigBannerDialog,
      variables: {
        signerId: firstAttendee?.oid ?? '',
        addressId: addressValue ?? '',
        activityTypeId: activity?.activityType?.oid ?? '',
        body: formContent,
        filename: buildFilename(firstAttendee),
        items: extractSampleRequestFormItems(promats),
        deliveryTimeFrameId: deliveryTimeFrame,
        deliveryDate: deliveryDate?.toISOString()?.slice(0, 10),
      },
      onCompleted: ({ addSampleRequestForm }) => {
        activity.sampleRequestFormDocument = {
          id: addSampleRequestForm?.document?.id,
        };

        const updatedPromats = promats.map((promat) => {
          if (promat.promotionalMaterial?.signatureRequired) {
            return { ...promat, isSigned: true };
          }

          return { ...promat };
        });
        onChangePromats(updatedPromats);
        const sampleRequestForm = {
          id: addSampleRequestForm?.id,
          oid: addSampleRequestForm?.oid,
          document: {
            id: addSampleRequestForm?.document?.id,
            oid: addSampleRequestForm?.document?.oid,
            name: addSampleRequestForm?.document?.name ?? '',
          },
        };

        setSignedSampleRequestForm(sampleRequestForm);
        setHasFormBeenSubmitted(true);
      },
    });
  };

  const handleShowPreviewDialog = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewOpen(false);
  };

  useEffect(() => {
    if (firstAttendee?.employments?.length === 1) {
      setAddressValue(getAddress(firstAttendee?.employments?.[0])?.oid ?? '');
    }
  }, [firstAttendee?.employments]);

  useEffect(() => {
    if (!isLoading && hasFormBeenSubmitted) {
      setHasFormBeenSubmitted(false);
      handleClosePreviewDialog();
      onClose();
    }
  }, [isLoading, setSignedSampleRequestForm, onClose, hasFormBeenSubmitted]);

  if (!firstAttendee) {
    return null;
  }

  // either the signed form is already in the activity or it's still in the interaction state
  const isSigned =
    !!activity.sampleRequestFormDocument || !!signedSampleRequestForm;

  return (
    <>
      <Dialog
        fullWidth
        data-testid="promat-collection-dialog"
        maxWidth={'lg'}
        open={isOpen}
      >
        <DialogTitle>
          <Typography noWrap variant="h5">
            {t('pages.interaction.common.requestForm')}
          </Typography>

          <StyledBox>
            <Button variant="outlined" onClick={handleCloseDialog}>
              {t('components.common.cancel')}
            </Button>
            <Button
              disabled={isButtonDisabled}
              variant="contained"
              onClick={handleShowPreviewDialog}
            >
              {t('components.common.next')}
            </Button>
          </StyledBox>
        </DialogTitle>

        <DialogContent>
          <StyledStack>
            <RequestedSamples
              title={`${t('pages.interaction.expand.requestedSamples')}:`}
              promats={promats.filter(
                (promat) => promat.promotionalMaterial?.signatureRequired
              )}
              isSigned={isSigned}
            />

            <DeliveryInformation
              addressExtension={addressExtension}
              firstAttendee={firstAttendee}
              deliveryTimeFrame={deliveryTimeFrame}
              deliveryDate={deliveryDate}
              deliveryTimeFrameEnabled={
                isDeliveryInformationEnabled &&
                (systemConfiguration?.sampleRequestAdditionalDeliveryInformationEnabled ??
                  false)
              }
              deliveryDateEnabled={
                isDeliveryInformationEnabled &&
                (systemConfiguration?.sampleRequestDeliveryDateEnabled ?? false)
              }
              deliveryDateMinimumDays={
                systemConfiguration?.sampleRequestDeliveryDateMinimumDaysInFuture ??
                0
              }
              deliveryTimeFrames={deliveryTimeFrames}
              onChangeDeliveryTimeFrame={handleChangeDeliveryTimeFrame}
              onChangeDeliveryDate={handleChangeDeliveryDate}
              onChangeAddressExtension={handleOnChangeAddressExtension}
              onChangeAddress={handleChangeAddressCheckbox}
              onChangeSignature={handleChangeSignature}
            />
          </StyledStack>
        </DialogContent>
      </Dialog>

      {isPreviewOpen && (
        <PromotionalMaterialPreviewDialog
          isOpen={isPreviewOpen}
          firstAttendee={firstAttendee}
          promats={promats}
          address={addressValue}
          addressExtension={addressExtension}
          signature={canvasImageDataUrl}
          isSubmitLoading={isLoading}
          onClosePreviewDialog={handleClosePreviewDialog}
          onSubmit={handleSubmitForm}
        />
      )}
    </>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2)}`,
  },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));
