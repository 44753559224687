import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import {
  getWrapperHeight,
  getWrapperPadding,
} from '@/components/Interaction/helpers';
import { CUSTOM_BREAKPOINTS, TELEPHONE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';

type ControlsWrapperProps = {
  children: ReactNode;
  isDrawerOpen: boolean;
  drawerBleeding: string;
  flex: number;
};

export const ControlsWrapper = ({
  flex,
  isDrawerOpen,
  drawerBleeding,
  children,
}: ControlsWrapperProps) => {
  const { isSharingInFullScreen } = useInteraction();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Wrapper
      isFullScreen={isSharingInFullScreen}
      isMobile={isMobile}
      isDrawerOpen={isDrawerOpen}
      drawerBleeding={drawerBleeding}
      flex={flex}
      data-testid="controls-wrapper"
    >
      {children}
    </Wrapper>
  );
};

type ControlsWrapperStyleProps = {
  isDrawerOpen: boolean;
  isMobile: boolean;
  isFullScreen: boolean;
  drawerBleeding: string;
  flex: number;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    ['isDrawerOpen', 'isMobile', 'isFullScreen', 'drawerBleeding'].indexOf(
      prop as string
    ) === -1,
})<ControlsWrapperStyleProps>(
  ({ isDrawerOpen, isMobile, isFullScreen, flex, drawerBleeding, theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    /**
     * For waiting room: TBD
     * For remote room: Video "column" will have flex 1, Content "column" flex 2
     */
    flex,

    maxWidth: isFullScreen
      ? TELEPHONE_INTERACTION.DESKTOP.FULLSCREEN_WIDTH
      : '100%',

    padding: getWrapperPadding({ isDrawerOpen, isFullScreen, theme }),
    gap: isDrawerOpen ? 0 : theme.spacing(2),

    height: isDrawerOpen
      ? 'auto'
      : getWrapperHeight({ drawerBleeding, isMobile }),

    background: theme.palette.grey[800],
    borderRadius: Number(theme.shape.borderRadius) * 2,

    [theme.breakpoints.down('md')]: {
      flex: 'initial',
      borderRadius: 'initial',
      paddingBottom: theme.spacing(1),
      gap: isDrawerOpen ? 0 : theme.spacing(1),
      maxWidth: '100%',
    },

    // When form or media sharing happens or the drawer is opened we need to hide the top video container
    [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
      {
        height: isDrawerOpen
          ? 0
          : `calc(100% - ${TELEPHONE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE})`,
        padding: isDrawerOpen ? 0 : theme.spacing(1),
      },
  })
);
