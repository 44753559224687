import {
  AttributeData,
  AttributeOptionData,
  filterNonNull,
} from '@ysura/common';

import { convertedAttributeType } from '@/services/graphql/parsers/utils';
import {
  Attribute,
  AttributeConnection,
  AttributeOption,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

const parseAttributeOptions = (
  options: Array<DeepPartial<AttributeOption> | undefined>
): Array<AttributeOptionData> => {
  return options.map((option: DeepPartial<AttributeOption> | undefined) => {
    return {
      id: option?.id,
      oid: option?.oid,
      value: option?.value,
    };
  });
};

export const parseAttribute = (
  attribute: DeepPartial<Attribute>
): AttributeData => {
  return {
    id: attribute?.id,
    oid: attribute?.oid,
    name: attribute?.name || undefined,
    type: convertedAttributeType(attribute.type),
    options: attribute?.options
      ? parseAttributeOptions(attribute.options)
      : undefined,
  };
};

export const parseAttributes = (
  attributes: DeepPartial<AttributeConnection>
) => {
  const rawAttributes = attributes?.edges ?? [];
  const mappedAttributes = rawAttributes
    .map((edge) => edge?.node ?? null)
    .map((item: DeepPartial<Attribute> | null) =>
      item ? parseAttribute(item) : null
    );

  return filterNonNull(mappedAttributes);
};
