import { SystemConfigurationData } from '@ysura/common';

import { parseCountryData } from '@/services/graphql/parsers/country';
import { SystemConfiguration } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const parseSystemConfigurationData = (
  systemConfiguration?: DeepPartial<SystemConfiguration>
): SystemConfigurationData => {
  return {
    id: systemConfiguration?.id,
    defaultCountry: parseCountryData(systemConfiguration?.defaultCountry),
    sampleRequestAdditionalDeliveryInformationEnabled:
      systemConfiguration?.sampleRequestAdditionalDeliveryInformationEnabled,
    sampleRequestDeliveryDateEnabled:
      systemConfiguration?.sampleRequestDeliveryDateEnabled,
    sampleRequestDeliveryDateMinimumDaysInFuture:
      systemConfiguration?.sampleRequestDeliveryDateMinimumDaysInFuture,
  };
};
