import { CommentData, DataPermissionTypeDataEnum } from '@ysura/common';

import { parseCommentTypeData } from '@/services/graphql/parsers';
import {
  convertedCommentTargetType,
  convertedState,
} from '@/services/graphql/parsers/utils';
import { Comment, CommentConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseCommentData = (
  comment: DeepPartial<Comment>
): CommentData => {
  return {
    id: comment?.id,
    oid: comment?.oid,
    commentType: comment?.commentType
      ? parseCommentTypeData(comment.commentType)
      : undefined,
    targetType: convertedCommentTargetType(comment?.targetType),
    state: convertedState(comment?.state),
    activityOid: comment?.activity?.oid ?? undefined,
    content: comment?.content ?? undefined,
    permissions:
      comment?.dataPermissions?.map(
        (p) => p?.type as unknown as DataPermissionTypeDataEnum
      ) ?? [],
  };
};

export const parseCommentConnection = (
  commentConnection: DeepPartial<CommentConnection> | undefined
): Array<CommentData> => {
  const rawComments = commentConnection?.edges ?? [];
  const parsedComments = rawComments
    .map((edge) => edge?.node ?? null)
    .map((comment) => (comment ? parseCommentData(comment) : null));

  return filterNonNull(parsedComments);
};
