import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationData, TextWithEllipsis } from '@ysura/common';

import { AddressBox } from '@/components/Address';

type OrganizationItemProps = {
  organization: OrganizationData | undefined;
};

export const OrganizationItem = ({ organization }: OrganizationItemProps) => {
  if (!organization) {
    return null;
  }

  return (
    <Wrapper data-testid="organization-item">
      {organization.name && (
        <RowWrapper>
          <StyledLocalHospitalIcon />
          <TextWithEllipsis variant="caption">
            {organization.name}
          </TextWithEllipsis>
        </RowWrapper>
      )}

      <AddressBox address={organization.addresses?.[0] ?? undefined} />
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  color: theme.palette.text.primary,
}));

const StyledLocalHospitalIcon = styled(LocalHospitalIcon)({
  fontSize: 16,
});
