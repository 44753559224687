import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaData, ReactionDataEnum } from '@ysura/common';

import { AttendeeReactions } from '@/components/AttendeeReactions';
import { ReactionState } from '@/hooks/useInteraction/useInteractionState';

type KeyMessagesListProps = {
  media: Array<MediaData>;
  isReactionEditable?: boolean;
  isDisplayReactions?: boolean;
  reactions?: ReactionState;
  handleChangeReaction?: (
    mediaId: string | undefined,
    keyMessageId: string | undefined,
    reaction: ReactionDataEnum
  ) => void;
};

export const KeyMessagesList = ({
  media,
  isDisplayReactions = false,
  isReactionEditable = false,
  reactions = {},
  handleChangeReaction,
}: KeyMessagesListProps) => {
  const hasMediaKeyMessage = (media: MediaData) => {
    if (!media) {
      return false;
    }

    return media?.keyMessages?.length && media.keyMessages.length > 0;
  };

  const mediaWithKeyMessages = media?.filter((singleMedia) =>
    hasMediaKeyMessage(singleMedia)
  );

  return (
    <MediaList data-testid="media-key-message-list">
      {mediaWithKeyMessages.map((singleMedia, index) => (
        <MediaBox key={singleMedia.id} data-testid="media-key-message">
          {index > 0 && <Divider />}

          <MediaName variant="caption">{singleMedia.name}</MediaName>

          {singleMedia?.keyMessages?.length &&
            singleMedia.keyMessages.length > 0 && (
              <ReactionBox>
                {singleMedia.keyMessages.map((keyMessage) => (
                  <ReactionBox key={keyMessage.id}>
                    <Typography variant="body2">{keyMessage.name}</Typography>

                    {isDisplayReactions &&
                      singleMedia?.oid &&
                      keyMessage?.oid && (
                        <AttendeeReactions
                          reaction={
                            isReactionEditable
                              ? reactions?.[singleMedia.oid]?.keyMessages?.[
                                  keyMessage.oid
                                ]
                              : keyMessage?.reaction
                          }
                          isClickable={isReactionEditable}
                          onChangeReaction={(reaction: ReactionDataEnum) =>
                            handleChangeReaction?.(
                              singleMedia.oid,
                              keyMessage.oid,
                              reaction
                            )
                          }
                        />
                      )}
                  </ReactionBox>
                ))}
              </ReactionBox>
            )}
        </MediaBox>
      ))}
    </MediaList>
  );
};

const MediaList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MediaBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MediaName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ReactionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
