import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';

import { DrawerButton } from './DrawerButton';

type DrawerContentWrapperProps = {
  isDrawerOpen: boolean;
  isSharing: boolean;
  drawerBleeding: string;
  toggleDrawer: VoidFunction;
  children: ReactNode;
};

export const DrawerContentWrapper = ({
  isDrawerOpen,
  isSharing,
  drawerBleeding,
  toggleDrawer,
  children,
}: DrawerContentWrapperProps) => {
  return (
    <Wrapper
      drawerBleeding={drawerBleeding}
      isDrawerOpen={isDrawerOpen}
      data-testid="drawer-content-wrapper"
    >
      <DrawerButton
        isSharing={isSharing}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />

      <ContentWrapper isSharing={isSharing}>{children}</ContentWrapper>
    </Wrapper>
  );
};

type DrawerStyleProps = {
  isDrawerOpen: boolean;
  drawerBleeding?: string;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !(prop === 'isDrawerOpen' || prop === 'drawerBleeding'),
})<DrawerStyleProps>(({ isDrawerOpen, drawerBleeding, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  width: '100%',

  height: isDrawerOpen
    ? `calc(100% - ${REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_HEIGHT_PORTRAIT})`
    : drawerBleeding,

  background: theme.palette.common.white,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      height: isDrawerOpen
        ? '100%'
        : REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE,

      // maxHeight: isDrawerOpen
      //   ? '100%'
      //   : REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE,
    },
}));

type ContentWrapperStyleProps = {
  isSharing: boolean;
};

const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSharing',
})<ContentWrapperStyleProps>(({ theme, isSharing }) => ({
  padding: isSharing ? 0 : theme.spacing(2),

  overflowY: 'auto',

  height: '100%',
  width: '100%',
}));
