import { PersonPreferenceFacetData } from '@ysura/common';

import { parsePreferenceData } from '@/components/GlobalSearch/utils';
import {
  parseConsentTypeData,
  parsePersonType,
  parseSpeciality,
  parseSpecialityGroup,
} from '@/services/graphql/parsers';
import { parseAttribute } from '@/services/graphql/parsers/attributes';
import { parseEmploymentFunction } from '@/services/graphql/parsers/employment';
import { PersonFacetsPreference } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const parsePersonPreference = (
  preference?: DeepPartial<PersonFacetsPreference>
): PersonPreferenceFacetData => {
  return {
    attributes: preference?.attributes
      ? parsePreferenceData(preference.attributes, parseAttribute)
      : undefined,
    consentTypes: preference?.consentTypes
      ? parsePreferenceData(preference.consentTypes, parseConsentTypeData)
      : undefined,
    employeeFunctions: preference?.employeeFunctions
      ? parsePreferenceData(
          preference.employeeFunctions,
          parseEmploymentFunction
        )
      : undefined,
    personTypes: preference?.personTypes
      ? parsePreferenceData(preference.personTypes, parsePersonType)
      : undefined,
    specialities: preference?.specialities
      ? parsePreferenceData(preference.specialities, parseSpeciality)
      : undefined,
    specialityGroups: preference?.specialityGroups
      ? parsePreferenceData(preference.specialityGroups, parseSpecialityGroup)
      : undefined,
  };
};
