import { Grid } from '@mui/material';
import { EntityFilterTypeDataEnum } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPageLayout } from '@/components/DetailPageLayout';
import { convertOrganizationFilterValue } from '@/components/GlobalSearch/utils';
import { PageHeader } from '@/components/PageHeader';
import {
  EditSettingDialog,
  ItemsToUpdate,
  LabelType,
  SettingsFiltersListComponent,
} from '@/components/Settings/common';
import {
  EditFilterData,
  FilterPermissionData,
  FilterTypeData,
} from '@/components/Settings/helper';
import {
  checkOrganizationFilterPermission,
  extractOrganizationFilters,
  prepareSettingsForUpdatePreferenceMutation,
} from '@/components/Settings/helper/helper';
import { OrganizationSettingComponentSkeleton } from '@/components/Settings/organization';
import {
  errorConfigBannerDialog,
  useCurrentUser,
  useNotification,
} from '@/hooks';
import {
  useGetPreferencesQuery,
  useUpdatePreferenceMutation,
} from '@/services/graphql/hooks';
import { parseOrganizationPreference } from '@/services/graphql/parsers';
import { UpdatePreferenceAction } from '@/services/graphql/types';

export const OrganizationSettingComponent = () => {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { toast } = useNotification();

  const preferenceId = currentUser?.preferenceId ?? '';

  const [updatePreferenceMutation, { loading: isUpdating }] =
    useUpdatePreferenceMutation();

  const [isVisibleEditDialog, setIsVisibleEditDialog] = useState(false);
  const [isEditableFilter, setEditableFilter] = useState<EditFilterData | null>(
    null
  );

  // Query
  const { data: preferencesData, loading } = useGetPreferencesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      preferenceId,
    },
    skip: !preferenceId,
  });

  const organizationFacets = parseOrganizationPreference(
    preferencesData?.preference?.facets?.organizationFacets
  );
  const filters = convertOrganizationFilterValue(organizationFacets);

  // Labels to display
  const organizationFilters = extractOrganizationFilters(filters);
  const organizationTypes = organizationFilters.get('organizationTypes') ?? [];
  const organizationTypesDisplay = organizationTypes.map(({ name }) => {
    return { name };
  });
  const cities = organizationFilters.get('cities') ?? [];
  const citiesDisplay = cities.map(({ name }) => {
    return { name };
  });
  const attributes = organizationFilters.get('attributes') ?? [];
  const attributesDisplay: LabelType[] = attributes.map(({ name, avatar }) => {
    return {
      name: name ?? '',
      avatar: { name: avatar ?? '', type: 'attribute' },
    };
  });

  // Permission
  const organizationPermission: FilterPermissionData =
    checkOrganizationFilterPermission(currentUser?.permissions?.organization);

  const handleFilterEdition = (entity: FilterTypeData) => {
    setIsVisibleEditDialog(true);

    setEditableFilter({
      entity: entity,
      displayName: t(`components.settings.organization.${entity}.title`),
      selectedItems:
        organizationFilters?.get(entity)?.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [],
    });
  };

  // Mutation
  const handleSave = (itemsToUpdate: ItemsToUpdate) => {
    if (!preferencesData) {
      return;
    }

    const organizationFacets = parseOrganizationPreference(
      preferencesData?.preference?.facets?.organizationFacets
    );

    const changes: UpdatePreferenceAction =
      prepareSettingsForUpdatePreferenceMutation(
        currentUser?.person,
        itemsToUpdate,
        EntityFilterTypeDataEnum.ORGANIZATION,
        undefined,
        organizationFacets
      );

    updatePreferenceMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: preferenceId,
        actions: [changes],
      },
      onCompleted: () => {
        setIsVisibleEditDialog(false);
        toast?.({
          message: t('components.settings.edit.success'),
          type: 'success',
        });
      },
    });
  };

  if (loading) {
    return <OrganizationSettingComponentSkeleton />;
  }

  return (
    <>
      <DetailPageLayout
        headerComp={
          <PageHeader title={t(`components.settings.organization.title`)} />
        }
        firstColumnComp={
          <>
            <Grid item>
              {organizationPermission.permission.includes(
                'organizationTypes'
              ) && (
                <SettingsFiltersListComponent
                  title={t(
                    'components.settings.organization.organizationTypes.title'
                  )}
                  noItemsText={t(
                    'components.settings.organization.organizationTypes.noItems'
                  )}
                  labels={organizationTypesDisplay}
                  onClickEdit={() => handleFilterEdition('organizationTypes')}
                />
              )}
            </Grid>
          </>
        }
        middleColumnComp={
          <>
            <Grid item>
              {organizationPermission.permission.includes('cities') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.organization.cities.title')}
                  noItemsText={t(
                    'components.settings.organization.cities.noItems'
                  )}
                  labels={citiesDisplay}
                  onClickEdit={() => handleFilterEdition('cities')}
                />
              )}
            </Grid>
          </>
        }
        lastColumnComp={
          <>
            <Grid item>
              {organizationPermission.permission.includes('attributes') && (
                <SettingsFiltersListComponent
                  title={t('components.settings.organization.attributes.title')}
                  noItemsText={t(
                    'components.settings.organization.attributes.noItems'
                  )}
                  labels={attributesDisplay}
                  onClickEdit={() => handleFilterEdition('attributes')}
                />
              )}
            </Grid>
          </>
        }
      ></DetailPageLayout>
      {isVisibleEditDialog && isEditableFilter && (
        <EditSettingDialog
          isOpen={isVisibleEditDialog}
          isUpdating={isUpdating}
          aggregate={EntityFilterTypeDataEnum.ORGANIZATION}
          entity={isEditableFilter.entity}
          displayName={isEditableFilter.displayName}
          selectedItems={isEditableFilter.selectedItems}
          onCancel={() => setIsVisibleEditDialog(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
};
