import { gql } from '@apollo/client';

export const getPromotionalMaterialsTopics = gql`
  query getPromotionalMaterialsTopics($oids: [PromotionalMaterialId!]) {
    promotionalMaterials(filter: { oid: { _in: $oids } }) {
      edges {
        node {
          id
          oid
          name
          topics(filter: { state: { _eq: ACTIVE } }) {
            edges {
              node {
                id
                oid
              }
            }
          }
        }
      }
    }
  }
`;
