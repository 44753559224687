import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetTouchpointListByDateQueryVariables = Types.Exact<{
  startDateTime: Types.Scalars['DateTime']['input'];
  endDateTime: Types.Scalars['DateTime']['input'];
  currentUserPersonId?: Types.InputMaybe<Types.Scalars['PersonId']['input']>;
}>;

export type GetTouchpointListByDateQuery = {
  __typename?: 'Query';
  touchpoints: {
    __typename?: 'TouchpointConnection';
    filteredCount: number;
    edges?: Array<{
      __typename?: 'TouchpointEdge';
      node?: {
        __typename?: 'Touchpoint';
        id: string;
        oid: string;
        state: Types.State;
        status: Types.ActivityStatus;
        startDate?: string | null;
        startDateTime?: string | null;
        endDate?: string | null;
        endDateTime?: string | null;
        confirmed: boolean;
        activityType: {
          __typename?: 'ActivityType';
          id: string;
          oid: string;
          name: string;
          baseType: Types.ActivityTypeBaseType;
          schedulingType: Types.ActivityTypeScheduling;
          hideTimeForClosedActivity: boolean;
        };
        organization: {
          __typename?: 'Organization';
          id: string;
          oid: string;
          name?: string | null;
          addresses: {
            __typename?: 'EntityAddressConnection';
            edges?: Array<{
              __typename?: 'EntityAddressEdge';
              node?: {
                __typename?: 'EntityAddress';
                address: {
                  __typename?: 'Address';
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  city: string;
                  postalCode: string;
                };
              } | null;
            }> | null;
          };
          rootOrganization?: {
            __typename?: 'Organization';
            id: string;
            oid: string;
            name?: string | null;
          } | null;
        };
        attendees: Array<{
          __typename?: 'TouchpointAttendee';
          person?: {
            __typename?: 'Person';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            prefixAcademicTitle?: {
              __typename?: 'AcademicTitle';
              name?: string | null;
              state: Types.State;
            } | null;
          } | null;
        }>;
      } | null;
    }> | null;
  };
};

/**
 * __useGetTouchpointListByDateQuery__
 *
 * To run a query within a React component, call `useGetTouchpointListByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTouchpointListByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTouchpointListByDateQuery({
 *   variables: {
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      currentUserPersonId: // value for 'currentUserPersonId'
 *   },
 * });
 */
export function useGetTouchpointListByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTouchpointListByDateQuery,
    GetTouchpointListByDateQueryVariables
  > &
    (
      | { variables: GetTouchpointListByDateQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetTouchpointListByDateQuery,
    GetTouchpointListByDateQueryVariables
  >(Operations.getTouchpointListByDate, options);
}
export function useGetTouchpointListByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTouchpointListByDateQuery,
    GetTouchpointListByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetTouchpointListByDateQuery,
    GetTouchpointListByDateQueryVariables
  >(Operations.getTouchpointListByDate, options);
}
export function useGetTouchpointListByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTouchpointListByDateQuery,
    GetTouchpointListByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetTouchpointListByDateQuery,
    GetTouchpointListByDateQueryVariables
  >(Operations.getTouchpointListByDate, options);
}
export type GetTouchpointListByDateQueryHookResult = ReturnType<
  typeof useGetTouchpointListByDateQuery
>;
export type GetTouchpointListByDateLazyQueryHookResult = ReturnType<
  typeof useGetTouchpointListByDateLazyQuery
>;
export type GetTouchpointListByDateSuspenseQueryHookResult = ReturnType<
  typeof useGetTouchpointListByDateSuspenseQuery
>;
export type GetTouchpointListByDateQueryResult = Apollo.QueryResult<
  GetTouchpointListByDateQuery,
  GetTouchpointListByDateQueryVariables
>;
