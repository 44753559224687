import { ActivityData } from '@ysura/common';

import { useInteraction } from '@/hooks';
import { EndInteraction } from '@/views/Interaction/EndInteraction';

type EndRemoteInteractionDialogProps = {
  isOpen: boolean;
  activity: ActivityData | null;
  closeDialog: VoidFunction;
};

export const EndRemoteInteractionDialog = ({
  isOpen,
  activity,
  closeDialog,
}: EndRemoteInteractionDialogProps) => {
  const { organizerCloseSession } = useInteraction();

  const handleEndInteraction = () => {
    organizerCloseSession();
  };

  return (
    <EndInteraction
      activity={activity}
      isOpen={isOpen}
      testId="end-remote-interaction-dialog"
      onClose={closeDialog}
      onEndWithoutSaving={handleEndInteraction}
      onUpdateAndSaveAndExit={handleEndInteraction}
    />
  );
};
