import { gql } from '@apollo/client';

export const getCurrentUserInventory = gql`
  query getCurrentUserInventory {
    currentUser {
      id
      oid
      inventory {
        id
        oid
        stocks {
          edges {
            node {
              oid
              type
              quantity
              promotionalMaterial {
                id
                oid
                name
                type
                signatureRequired
              }
              promotionalMaterialBatch {
                oid
                expiryDate
                name
              }
            }
          }
        }
      }
    }
  }
`;
