import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { ReactNode, useEffect, useState } from 'react';

import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';

type InteractionMobileLayoutProps = {
  drawerBleeding: string;
  videoComp: ReactNode;
  actionComp: ReactNode;
  contentComp: ReactNode;
};

export const InteractionMobileLayout = ({
  drawerBleeding,
  contentComp,
  videoComp,
  actionComp,
}: InteractionMobileLayoutProps) => {
  const { isAnyContentShared, isUserMediaCollected } = useInteraction();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isAnyContentShared) {
      setIsDrawerOpen(false);
    }
  }, [isAnyContentShared]);

  return (
    <Wrapper>
      <AudioVideoWrapper
        isDrawerOpen={isDrawerOpen || isAnyContentShared}
        drawerBleeding={drawerBleeding}
      >
        {isUserMediaCollected ? (
          <>
            <VideoWrapper
              isDrawerOpen={isDrawerOpen || isAnyContentShared}
              data-testid="video-wrapper"
            >
              {videoComp}
            </VideoWrapper>
            {actionComp}
          </>
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </AudioVideoWrapper>

      <ExtendableBox
        isDrawerOpen={isDrawerOpen || isAnyContentShared}
        drawerBleeding={drawerBleeding}
      >
        {!isAnyContentShared && (
          <IconButton onClick={toggleDrawer}>
            {isDrawerOpen ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
          </IconButton>
        )}

        <ContentBox
          isDrawerOpen={isDrawerOpen || isAnyContentShared}
          isSharing={isAnyContentShared}
        >
          {contentComp}
        </ContentBox>
      </ExtendableBox>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.grey[800],
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      paddingLeft: theme.spacing(9),
    },
}));

type AudioVideoWrapperProps = {
  isDrawerOpen: boolean;
  drawerBleeding?: string;
};

const AudioVideoWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    typeof prop === 'string' &&
    ['isDrawerOpen', 'drawerBleeding'].indexOf(prop) === -1,
})<AudioVideoWrapperProps>(({ isDrawerOpen, drawerBleeding, theme }) => ({
  height: isDrawerOpen ? 'auto' : `calc(100% - ${drawerBleeding})`,
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(1),
  // Hide the gap when isDrawerOpen is true or when sharing form or media (as the VideoWrapper is hidden by height: 0)
  gap: isDrawerOpen ? 0 : theme.spacing(1),

  // When form or media sharing happens or the drawer is opened we need to hide the top video container
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      height: isDrawerOpen
        ? 0
        : `calc(100% - ${REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE})`,
      padding: isDrawerOpen ? 0 : theme.spacing(1),
    },
}));

type VideoWrapperStyleProps = {
  isDrawerOpen: boolean;
};

const VideoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDrawerOpen',
})<VideoWrapperStyleProps>(({ isDrawerOpen }) => ({
  height: isDrawerOpen ? 0 : '100%',
  overflow: 'hidden',
}));

type ExtendableProps = {
  isDrawerOpen: boolean;
  drawerBleeding?: string;
};

const ExtendableBox = styled(Box, {
  shouldForwardProp: (prop) =>
    !(prop === 'isDrawerOpen' || prop === 'drawerBleeding'),
})<ExtendableProps>(({ isDrawerOpen, drawerBleeding, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  height: isDrawerOpen
    ? `calc(100% - ${REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_HEIGHT_PORTRAIT})`
    : drawerBleeding,
  background: theme.palette.background.neutral,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
      height: isDrawerOpen
        ? '100%'
        : REMOTE_INTERACTION.MOBILE.DRAWER_HEIGHT_LANDSCAPE,
    },
}));

const ContentBox = styled(Box, {
  shouldForwardProp: (prop) =>
    typeof prop === 'string' &&
    ['isDrawerOpen', 'isSharing'].indexOf(prop) === -1,
})<{ isDrawerOpen: boolean; isSharing: boolean }>(
  ({ theme, isDrawerOpen, isSharing }) => ({
    flexGrow: 1,
    padding: isSharing ? 0 : theme.spacing(2),
    overflowY: 'auto',
    [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
      {
        visibility: isDrawerOpen ? 'visible' : 'hidden',
      },
  })
);

const LoaderWrapper = styled(Box)({
  display: 'grid',
  height: '100%',
});
