import { Box, styled } from '@mui/material';
import {
  ActivityData,
  getPersonFullNameWithTitle,
  PersonConsentData,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageContainer } from '@/components/Interaction';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';
import { RoomHeader } from '@/views/Interaction/Common';
import { EndTelephoneInteractionDialog } from '@/views/Interaction/EndInteraction';
import { CallInfoDialog } from '@/views/Interaction/Remote/Room';
import {
  CallStatusContentActions,
  TelephoneRoomContent,
} from '@/views/Interaction/Telephone';

interface RoomProps {
  activity: ActivityData;
  consentList: PersonConsentData[];
  canEditActivity?: boolean;
  roomId: string;
  isOrganizer: boolean;
}

export const TelephoneRoom = ({
  activity,
  consentList,
  roomId,
  isOrganizer,
}: RoomProps) => {
  const { t } = useTranslation();

  const [isCallInfoOpen, setIsCallInfoOpen] = useState(false);
  const [isEndInteractionOpen, setIsEndInteractionOpen] = useState(false);
  const navigate = useNavigate();
  const { leaveInteraction, onScreenShareStarted } = useInteraction();

  const firstAttendee = activity?.attendees?.[0]?.person;

  useEffect(() => {
    const unsubscribeOnScreenShareStarted = onScreenShareStarted();

    return () => {
      unsubscribeOnScreenShareStarted?.();
    };
  }, [onScreenShareStarted]);

  const handleOpenEndInteractionDialog = () => {
    if (isOrganizer) {
      setIsEndInteractionOpen(true);
    } else {
      leaveInteraction();
      navigate(`/touchpoint/${activity.id}`);
    }
  };

  return (
    <>
      <PageContainer>
        {/* TelephoneRoom header is only visible on desktop */}
        <RoomHeader
          title={t('pages.interaction.common.interactionWithAttendee', {
            name: getPersonFullNameWithTitle(firstAttendee),
          })}
          isOrganizer={isOrganizer}
          openEndInteractionDialog={handleOpenEndInteractionDialog}
        />

        {/* This exact controls component is only visible on mobile landscape */}
        <VisibleOnlyMobileLandscape>
          <CallStatusContentActions
            isMobile
            isInteraction
            onEndInteraction={handleOpenEndInteractionDialog}
            onCallInfoDialog={() => setIsCallInfoOpen(true)}
          />
        </VisibleOnlyMobileLandscape>

        {/* Content is always visible */}
        <TelephoneRoomContent
          canEditActivity
          isOrganizer={isOrganizer}
          activity={activity}
          consentList={consentList}
          roomId={roomId}
          onCallInfoDialog={() => setIsCallInfoOpen(true)}
          onEndInteraction={handleOpenEndInteractionDialog}
        />
      </PageContainer>

      {/* Dialogs are always accessible */}
      <>
        <CallInfoDialog
          activity={activity}
          isOpen={isCallInfoOpen}
          closeDialog={() => setIsCallInfoOpen(false)}
        />

        <EndTelephoneInteractionDialog
          activity={activity}
          isOpen={isEndInteractionOpen}
          closeDialog={() => setIsEndInteractionOpen(false)}
        />
      </>
    </>
  );
};

const VisibleOnlyMobileLandscape = styled(Box)({
  display: 'none',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'flex',
    },
});
