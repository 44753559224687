import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type SendInvitationEmailMutationVariables = Types.Exact<{
  input: Types.InvitationEmailActivityInput;
}>;

export type SendInvitationEmailMutation = {
  __typename?: 'Mutation';
  sendInvitationEmail?: { __typename?: 'Activity'; id: string } | null;
};

export type SendInvitationEmailMutationFn = Apollo.MutationFunction<
  SendInvitationEmailMutation,
  SendInvitationEmailMutationVariables
>;

/**
 * __useSendInvitationEmailMutation__
 *
 * To run a mutation, you first call `useSendInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationEmailMutation, { data, loading, error }] = useSendInvitationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationEmailMutation,
    SendInvitationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    SendInvitationEmailMutation,
    SendInvitationEmailMutationVariables
  >(Operations.sendInvitationEmail, options);
}
export type SendInvitationEmailMutationHookResult = ReturnType<
  typeof useSendInvitationEmailMutation
>;
export type SendInvitationEmailMutationResult =
  Apollo.MutationResult<SendInvitationEmailMutation>;
export type SendInvitationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationEmailMutation,
  SendInvitationEmailMutationVariables
>;
