import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { cutMediaDescription, MediaThumbnail } from '@/components/Media';
import {
  CardWrapper,
  MediaDescription,
  TitleContainer,
} from '@/components/Media/MediaLayoutCard';

type MediaSearchCardProps = {
  media: MediaData;
  isMediaSelected: boolean;
  onAddMediaToSelected: (val: MediaData) => void;
};

export const MediaSearchCard = ({
  media,
  isMediaSelected,
  onAddMediaToSelected,
}: MediaSearchCardProps) => {
  const { t } = useTranslation();

  const handleClickButtonAdd = () => {
    onAddMediaToSelected(media);
  };

  return (
    <CardWrapper data-testid="media-search-card">
      <MediaThumbnail media={media} width={64} height={64} />
      <StyleStack>
        <TitleContainer data-testid="media-content-card">
          <Typography noWrap variant="subtitle2">
            {media.name}
          </Typography>

          {isMediaSelected ? (
            <StyledTypography variant="overline">
              {t('components.touchpointManagement.added')}
            </StyledTypography>
          ) : (
            <IconButton color="primary" onClick={handleClickButtonAdd}>
              <AddCircleIcon />
            </IconButton>
          )}
        </TitleContainer>
        <MediaDescription variant="caption">
          {cutMediaDescription(media.description)}
        </MediaDescription>
      </StyleStack>
    </CardWrapper>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const StyleStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  maxWidth: `calc(100% - 64px - ${theme.spacing(1)})`,
}));
