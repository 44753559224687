import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { EmploymentData, OrganizationData } from '@ysura/common';
import { uniq } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EmploymentCardStaticBlock,
  EmploymentDataStaticBlock,
} from '@/components/Employment';
import { OrganizationEmploymentDialog } from '@/components/Organization';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { filterNonNull } from '@/utils/dataHelpers/filters';

interface OrganizationPersonDataStaticBlockProps {
  organization: OrganizationData;
}

export const OrganizationPersonDataStaticBlock = ({
  organization,
}: OrganizationPersonDataStaticBlockProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleToggleEmploymentDialogOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const totalEmployments = uniq(
    filterNonNull(
      organization.employments?.map((employment) => employment?.person?.id)
    )
  );

  const totalAllEmployments = uniq(
    organization.hierarchyOrganizations?.reduce(
      (accu: string[], current: OrganizationData) => {
        const currentIds = filterNonNull(
          current.employments?.map(
            (employment: EmploymentData) => employment?.person?.id
          )
        );

        return [...accu, ...currentIds];
      },
      []
    ) ?? []
  );

  return (
    <OverviewStaticBlock
      title={`${t('pages.organizationView.personDataCard.personData')} (${
        totalEmployments.length
      })`}
      subtitle={
        <StyledBox onClick={handleToggleEmploymentDialogOpen}>
          <StyleTypography variant="subtitle2">
            {t('pages.organizationView.personDataCard.personShowAll')}
          </StyleTypography>
          <IconButton>
            <StyleExpandMoreRounded data-testid="icon-person-all" />
          </IconButton>
        </StyledBox>
      }
      testId="organization-person-data-static-block"
    >
      {totalEmployments.length > 5 ? (
        <EmploymentDataStaticBlock
          totalEmployeeCounts={totalEmployments.length}
          totalAllEmployeeCounts={totalAllEmployments.length}
          hasParentOrganization={!!organization?.rootOrganization}
        />
      ) : (
        <EmploymentCardStaticBlock
          employments={organization.employments ?? []}
        />
      )}

      {isOpen && (
        <OrganizationEmploymentDialog
          isOpen={isOpen}
          isMobile={isMobile}
          organization={organization}
          onClose={handleToggleEmploymentDialogOpen}
        />
      )}
    </OverviewStaticBlock>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const StyleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));

const StyleExpandMoreRounded = styled(ExpandMoreRounded)(() => ({
  transform: 'rotate(-90deg)',
}));
