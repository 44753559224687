import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  getPersonFullNameWithTitle,
  Loader,
  ParticipantBox,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';
import { useGetPersonAvailableCommunicationDataQuery } from '@/services/graphql/hooks';
import {
  parsePersonConsents,
  parsePersonData,
} from '@/services/graphql/parsers';
import { CommunicationDataBaseType } from '@/services/graphql/types';
import {
  getPersonFullName,
  getTelephoneCommunicationData,
} from '@/services/helper/person';
import { getAttendeeStatusMessage } from '@/views/Interaction/Common/InteractionInformation/helpers';

import { CommunicationDataCallStatusBlock } from './';

type CallStatusContentProps = {
  activity: ActivityData;
  isDrawerOpen?: boolean;
};

export const CallStatusContentInfoBlock = ({
  activity,
  isDrawerOpen = false,
}: CallStatusContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isAttendedConnected, setIsAttendedConnected] = useState(false);

  const { isStateServerInitialized, getParticipantInteractionStep } =
    useInteraction();

  const firstAttendee = activity?.attendees?.[0].person;

  const { data, loading } = useGetPersonAvailableCommunicationDataQuery({
    variables: {
      id: firstAttendee?.id ?? '',
      baseType: CommunicationDataBaseType.PHONE,
    },
  });

  const person = parsePersonData(data?.person);
  const consents = parsePersonConsents(data?.person);
  const telephoneCommunicationData =
    firstAttendee && !loading
      ? getTelephoneCommunicationData(person, consents)
      : [];

  const attendeeInteractionStep = firstAttendee?.oid
    ? getParticipantInteractionStep(firstAttendee.oid)
    : null;

  useEffect(() => {
    if (
      attendeeInteractionStep &&
      (attendeeInteractionStep === 'waiting-room' ||
        attendeeInteractionStep === 'interaction')
    ) {
      setIsAttendedConnected(true);
    } else {
      setIsAttendedConnected(false);
    }
  }, [activity.id, isStateServerInitialized, attendeeInteractionStep]);

  const personFullName = getPersonFullNameWithTitle(firstAttendee);
  const attendeeStatusMessage = getAttendeeStatusMessage(
    attendeeInteractionStep,
    personFullName,
    t
  );

  return (
    <Container data-testid="call-status-content-info-block">
      <Wrapper>
        <AttendeeLine>
          {!isStateServerInitialized || !isAttendedConnected ? (
            <InitializedInteractionStyled>
              <Loader size={32} />
              <Typography
                variant="subtitle2"
                color={theme.palette.common.white}
              >
                {t('pages.interaction.telephone.callStatus.waitingForAttendee')}
              </Typography>
              <CommunicationDataCallStatusBlock
                communicationData={telephoneCommunicationData ?? []}
              />
            </InitializedInteractionStyled>
          ) : (
            <>
              {!isDrawerOpen && (
                <>
                  <ParticipantBox
                    showAsThumbnail
                    dark
                    attendeeName={getPersonFullName(firstAttendee)}
                    size="large"
                  />
                  <FullNameTitleAttendeeTypography variant="subtitle2">
                    {attendeeStatusMessage}
                  </FullNameTitleAttendeeTypography>
                </>
              )}
            </>
          )}
        </AttendeeLine>
      </Wrapper>
    </Container>
  );
};

const Container = styled(Box, {
  shouldForwardProp: (props) => props !== 'shouldRenderVideo',
})(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.grey[700],

  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `0 ${theme.spacing(2)}`,
  gap: theme.spacing(5),
  width: '70%',
}));

const AttendeeLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const FullNameTitleAttendeeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const InitializedInteractionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
