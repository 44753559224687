import { filterNonNull, OrganizationPreferenceFacetData } from '@ysura/common';

import { parsePreferenceData } from '@/components/GlobalSearch/utils';
import { parseAttribute } from '@/services/graphql/parsers/attributes';
import { parseOrganizationTypeData } from '@/services/graphql/parsers/organizationType';
import { OrganizationFacetsPreference } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const parseOrganizationPreference = (
  preference?: DeepPartial<OrganizationFacetsPreference>
): OrganizationPreferenceFacetData => {
  return {
    attributes: preference?.attributes
      ? parsePreferenceData(preference.attributes, parseAttribute)
      : undefined,
    cities: filterNonNull(preference?.cities),
    organizationTypes: preference?.organizationTypes
      ? parsePreferenceData(
          preference.organizationTypes,
          parseOrganizationTypeData
        )
      : undefined,
  };
};
