import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonAvailableEmailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetPersonAvailableEmailsQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    communicationData: {
      __typename?: 'CommunicationDataConnection';
      edges?: Array<{
        __typename?: 'CommunicationDataEdge';
        node?: {
          __typename?: 'CommunicationData';
          value?: string | null;
        } | null;
      }> | null;
    };
    employments: {
      __typename?: 'EmploymentConnection';
      filteredCount: number;
      edges?: Array<{
        __typename?: 'EmploymentEdge';
        node?: {
          __typename?: 'Employment';
          id: string;
          oid: string;
          communicationData: {
            __typename?: 'CommunicationDataConnection';
            edges?: Array<{
              __typename?: 'CommunicationDataEdge';
              node?: {
                __typename?: 'CommunicationData';
                value?: string | null;
              } | null;
            }> | null;
          };
        } | null;
      }> | null;
    };
    consents: {
      __typename?: 'PersonConsentConnection';
      edges?: Array<{
        __typename?: 'PersonConsentEdge';
        node?: {
          __typename?: 'PersonConsent';
          id: string;
          oid?: string | null;
          consentItemValues: Array<{
            __typename?: 'PersonConsentItemValue';
            communicationDataValues: Array<{
              __typename?: 'PersonConsentCommunicationDataValue';
              value?: string | null;
              consentCommunicationDataType: {
                __typename?: 'ConsentCommunicationDataType';
                oid: string;
                communicationDataType: {
                  __typename?: 'CommunicationDataType';
                  id: string;
                  baseType?: Types.CommunicationDataBaseType | null;
                };
              };
            }>;
          }>;
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetPersonAvailableEmailsQuery__
 *
 * To run a query within a React component, call `useGetPersonAvailableEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonAvailableEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonAvailableEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonAvailableEmailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonAvailableEmailsQuery,
    GetPersonAvailableEmailsQueryVariables
  > &
    (
      | { variables: GetPersonAvailableEmailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPersonAvailableEmailsQuery,
    GetPersonAvailableEmailsQueryVariables
  >(Operations.getPersonAvailableEmails, options);
}
export function useGetPersonAvailableEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonAvailableEmailsQuery,
    GetPersonAvailableEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPersonAvailableEmailsQuery,
    GetPersonAvailableEmailsQueryVariables
  >(Operations.getPersonAvailableEmails, options);
}
export function useGetPersonAvailableEmailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonAvailableEmailsQuery,
    GetPersonAvailableEmailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonAvailableEmailsQuery,
    GetPersonAvailableEmailsQueryVariables
  >(Operations.getPersonAvailableEmails, options);
}
export type GetPersonAvailableEmailsQueryHookResult = ReturnType<
  typeof useGetPersonAvailableEmailsQuery
>;
export type GetPersonAvailableEmailsLazyQueryHookResult = ReturnType<
  typeof useGetPersonAvailableEmailsLazyQuery
>;
export type GetPersonAvailableEmailsSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonAvailableEmailsSuspenseQuery
>;
export type GetPersonAvailableEmailsQueryResult = Apollo.QueryResult<
  GetPersonAvailableEmailsQuery,
  GetPersonAvailableEmailsQueryVariables
>;
