import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { OrderBy } from '@/services/graphql/types';

type SortMenuProps = {
  order: OrderBy;
  onChangeOrder: (val: OrderBy) => void;
};

export const SortMenu = ({ order, onChangeOrder }: SortMenuProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isInLandscapeScreen = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );
  const isSmallScreen =
    useMediaQuery(theme.breakpoints.down('sm')) || isInLandscapeScreen;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSortButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const sortMenuOpen = Boolean(anchorEl);

  return (
    <>
      <SortButton
        id="sort-button"
        aria-controls={anchorEl ? 'sort-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleSortButtonClick}
      >
        {isSmallScreen ? (
          <SortByAlphaRoundedIcon />
        ) : (
          <>
            <TextContainer>
              <ArrowIconContainer>
                {order === OrderBy.ASC ? (
                  <ArrowUpwardRoundedIcon />
                ) : (
                  <ArrowDownwardRoundedIcon />
                )}
              </ArrowIconContainer>

              <Typography variant="subtitle1">A-Z</Typography>
            </TextContainer>

            <ExpandMoreRoundedIconStyle />
          </>
        )}
      </SortButton>

      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={sortMenuOpen}
        MenuListProps={{
          'aria-labelledby': 'sort-button',
        }}
        onClose={handleSortMenuClose}
      >
        <CaptionMenuItemStyle>
          <Typography variant="caption">
            {t('pages.interaction.common.sortBy')}
          </Typography>
        </CaptionMenuItemStyle>

        <MenuItemStyle
          onClick={() => {
            handleSortMenuClose();
            onChangeOrder(OrderBy.DESC);
          }}
        >
          <TextContainer>
            <ArrowIconContainer>
              <ArrowDownwardRoundedIcon />
            </ArrowIconContainer>
            <Typography variant="subtitle1">A-Z</Typography>
          </TextContainer>
        </MenuItemStyle>

        <MenuItemStyle
          onClick={() => {
            handleSortMenuClose();
            onChangeOrder(OrderBy.ASC);
          }}
        >
          <TextContainer>
            <ArrowIconContainer>
              <ArrowUpwardRoundedIcon />
            </ArrowIconContainer>
            <Typography variant="subtitle1">A-Z</Typography>
          </TextContainer>
        </MenuItemStyle>
      </Menu>
    </>
  );
};

const SortButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.disabled,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingLeft: 0,
  paddingRight: 0,
  '&:hover': {
    background: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    width: 'fit-content',
  },
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const ArrowIconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '.MuiSvgIcon-root': {
    height: 16,
    width: 16,
  },
});

const ExpandMoreRoundedIconStyle = styled(ExpandMoreRoundedIcon)({
  fontSize: '16px',
});

const CaptionMenuItemStyle = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.disabled,
  justifyContent: 'center',
  padding: `0 ${theme.spacing(1)}`,
  '> div': {
    padding: theme.spacing(1),
    width: '100%',
  },
  ':hover': {
    background: 'none',
    '> div': {
      ...theme.shape,
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity
      ),
      color: theme.palette.primary.main,
    },
  },
}));
