import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import { errorConfigBannerDialog, useCurrentUser } from '@/hooks';
import {
  useAddRecentAccountMutation,
  useGetOrganizationByIdQuery,
} from '@/services/graphql/hooks';
import { parseOrganizationData } from '@/services/graphql/parsers';
import { RecentAccountType } from '@/services/graphql/types';
import { OrganizationView } from '@/views/OrganizationView';

import { NotFound404 } from '../Error';

export const OrganizationPage = () => {
  const { id } = useParams();
  const { currentUser } = useCurrentUser();

  const [addRecentAccountMutation] = useAddRecentAccountMutation();

  const { data, loading, error } = useGetOrganizationByIdQuery({
    variables: {
      organizationId: id ?? '',
    },
    onCompleted: (data) => {
      if (
        currentUser?.permissions?.common?.canReadHistory &&
        data.organization?.oid
      ) {
        addRecentAccountMutation({
          ...errorConfigBannerDialog,
          variables: {
            oid: data.organization?.oid,
            type: RecentAccountType.ORGANIZATION,
          },
        });
      }
    },
  });

  if (!loading && !error && isEmpty(data?.organization)) {
    return <NotFound404 />;
  }

  const organization = data?.organization
    ? parseOrganizationData(data.organization)
    : undefined;

  return (
    <OrganizationView isPageLoading={loading} organization={organization} />
  );
};
