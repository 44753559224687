import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type FilterTitleCategoryProps = {
  title: string;
  numberOfSelectedFilters?: number;
  applyBold?: boolean;
};
export const FilterTitleCategory = ({
  title,
  numberOfSelectedFilters,
  applyBold = true,
}: FilterTitleCategoryProps) => {
  return (
    <StyledTypography variant="subtitle2" applyBold={applyBold}>
      {`${title} (${numberOfSelectedFilters})`}
    </StyledTypography>
  );
};

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'applyBold',
})<{ applyBold: boolean }>(({ theme, applyBold }) => ({
  color: theme.palette.text.secondary,
  fontWeight: applyBold ? '700' : 'unset',
}));
