import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentItemData,
  ConsentItemStateChangeDataEnum,
  ConsentTypeData,
  ConsentValue,
  CountryData,
  getFormikInitialValues,
  getValidationSchema,
  PersonConsentLifecycleStateDataEnum,
  PersonData,
  SignatureBox,
} from '@ysura/common';
import { useFormik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AttendeeInfo } from '@/components/Attendee';
import { ConsentCommunicationCollection } from '@/components/Consent/ConsentCommunicationCollection';
import { SetState } from '@/hooks/useInteraction/useInteractionTypes';

type ConsentCollectionContentProps = {
  consentType?: ConsentTypeData;
  person?: PersonData;
  defaultCountry?: CountryData;
  additionalInputComponent?: ReactNode;
  isSignatureRequired?: boolean;
  signature?: string;
  disableSignature?: boolean;
  onChangeSignature?: (url: string) => void;
  setIsFormValid?: SetState<boolean>;
  setErrorMessage?: (error?: string) => void;
  consentValues?: ConsentValue;
  lifecycleState?: PersonConsentLifecycleStateDataEnum;
  switchable?: Record<
    string,
    Record<PersonConsentLifecycleStateDataEnum, boolean>
  >;
  setConsentValues: (consentValue: ConsentValue) => void;
};

export const ConsentCollectionContent = ({
  consentType,
  person,
  defaultCountry,
  additionalInputComponent,
  isSignatureRequired,
  signature,
  disableSignature,
  onChangeSignature,
  setIsFormValid,
  setErrorMessage,
  consentValues,
  lifecycleState,
  switchable,
  setConsentValues,
}: ConsentCollectionContentProps) => {
  const { t } = useTranslation();

  const consentItemsData = (consentType?.consentItems ?? []).map(
    (item: ConsentItemData) => {
      return {
        ...item,
        isDisabled: switchable
          ? !switchable[item.oid ?? ''][
              lifecycleState ?? PersonConsentLifecycleStateDataEnum.DEFAULT
            ]
          : false,
      };
    }
  );

  const [validationSchema, setValidationSchema] =
    useState<Record<string, Yup.AnySchema>>();

  const [expandedConsentItems, setExpandedConsentItems] = useState<
    Array<string>
  >(
    consentItemsData
      .map((consentItem) => {
        if (
          consentItem?.defaultItemState ===
          ConsentItemStateChangeDataEnum.TURN_ON
        ) {
          return consentItem.inputId ?? '';
        }

        return '';
      })
      .filter(Boolean)
  );

  const initialValues =
    consentValues ?? getFormikInitialValues(consentItemsData);

  const { validateForm, ...formik } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: (values: ConsentValue, actions) => {
      setConsentValues(values);
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (validationSchema) {
      // Whenever the values change, we validate
      // the form and set the error message for the parent, ...
      validateForm()
        .then((res) => {
          const errors = Object.values(res);
          setErrorMessage?.(errors[0] ?? '');
          setIsFormValid?.(!errors[0]);
        })
        .catch((e) => {
          setErrorMessage?.(e.toString());
          setIsFormValid?.(false);
        });
    }

    // ... and we update the state in the parent.
    if (formik.dirty) {
      setConsentValues(formik.values);
    }
  }, [
    formik.dirty,
    formik.values,
    setConsentValues,
    setErrorMessage,
    setIsFormValid,
    validateForm,
    validationSchema,
  ]);

  useEffect(() => {
    const consentItemsData = consentType?.consentItems ?? [];

    const updatedExpandedConsentItems = consentItemsData
      .map((consentItem) => {
        if (
          consentItem?.defaultItemState ===
            ConsentItemStateChangeDataEnum.TURN_ON ||
          formik.values?.[consentItem.inputId ?? '']
        ) {
          return consentItem.inputId ?? '';
        }

        return '';
      })
      .filter(Boolean);

    setExpandedConsentItems([...updatedExpandedConsentItems]);

    const schema = getValidationSchema(
      consentItemsData,
      updatedExpandedConsentItems,
      defaultCountry
    );
    setValidationSchema?.(schema);
  }, [consentType?.consentItems, formik.values, defaultCountry]);

  return (
    <DialogContent data-testid="consent-collection-content">
      <Container>
        <CaptionTypography variant="caption">
          {t('components.consentDialog.title')}
        </CaptionTypography>
        {person && (
          <AttendeeInfo attendee={person} variant="none" showDivider={false} />
        )}
        <CaptionTypography variant="caption">
          {t('components.consentDialog.description')}
        </CaptionTypography>
        <StyleTypography variant="body2">
          {consentType?.description}
        </StyleTypography>
      </Container>
      {additionalInputComponent}
      <ConsentCommunicationCollection
        consentItemsCommunicationData={consentItemsData}
        expandedConsentItems={expandedConsentItems}
        setExpandedConsentItems={setExpandedConsentItems}
        formik={formik}
      />
      {isSignatureRequired && (
        <SignatureContainer>
          <SignatureBox
            isClearable={true}
            signature={signature}
            disable={disableSignature}
            mandatory={true}
            signatureLabel={t('pages.interaction.expand.signature')}
            clearLabel={t('components.signatureBox.clear')}
            errorLabel={t('components.signatureBox.signatureNotValid')}
            onChangeSignature={onChangeSignature}
          />
        </SignatureContainer>
      )}
    </DialogContent>
  );
};

const DialogContent = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(8),
  },
}));

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const SignatureContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}));

const CaptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const StyleTypography = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));
