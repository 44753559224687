import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { PersonData, TouchPointGroupData } from '@ysura/common';
import upperFirst from 'lodash/upperFirst';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';
import { getTouchPointColor } from '@/views/PersonView/Touchpoint/helpers';

import { TouchpointGroups } from './TouchpointGroups';
import { TouchpointRadialBar } from './TouchpointRadialBar';

type TouchpointPlanStaticBlockProps = {
  person: PersonData;
  touchpointGroups: Array<TouchPointGroupData>;
};

export const TouchpointPlanStaticBlock = ({
  person,
  touchpointGroups,
}: TouchpointPlanStaticBlockProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.touchpointPlan')}
      testId="touchpoint-plan-static-block"
    >
      {person.touchPointStatistics?.length === 0 && (
        <TouchpointGroups touchpointGroups={touchpointGroups} />
      )}

      <Wrapper>
        {person.touchPointStatistics?.map((touchPointStatistics) => (
          <TouchpointRadialBar
            key={touchPointStatistics.touchPointGroup?.name}
            label={upperFirst(touchPointStatistics.touchPointGroup?.name)}
            color={getTouchPointColor(
              theme,
              touchPointStatistics.touchPointGroup?.name
            )}
            plannedActivities={touchPointStatistics.currentPlan}
            finalizedActivities={touchPointStatistics.currentPlanExecution}
          />
        ))}
      </Wrapper>
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));
