import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';

export const VideoSkeleton = () => {
  return (
    <Container isFullScreen={false} data-testid="video-container-skeleton">
      <MainVideoWrapper isFullScreen={false}>
        <Loader />
      </MainVideoWrapper>

      <OwnVideoWrapper isFullScreen={false}>
        <OwnVideoSkeleton />
      </OwnVideoWrapper>
    </Container>
  );
};

const OwnVideoSkeleton = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[700],
  aspectRatio: '4/3',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  height: '100%',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      width: '100%',
    },
}));

type FullScreenStyleProps = {
  isFullScreen: boolean;
};

export const OwnVideoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})<FullScreenStyleProps>(({ isFullScreen }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: isFullScreen ? 48 : '20%',
  width: isFullScreen ? 48 : 'auto',
  minHeight: isFullScreen ? 'unset' : 80,
  maxHeight: isFullScreen ? 'unset' : 120,

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      alignSelf: 'center',
      minWidth: 120,
      height: 'fit-content',

      flex: 0,
    },
}));

export const MainVideoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})<FullScreenStyleProps>(({ isFullScreen }) => ({
  height: '100%',
  width: '100%',
  display: isFullScreen ? 'none' : 'block',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flex: 1,
    },
}));

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFullScreen',
})<FullScreenStyleProps>(({ theme, isFullScreen }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  height: isFullScreen ? 'fit-content' : '100%',
  gap: isFullScreen ? theme.spacing(3) : theme.spacing(1),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
      width: '100%',
    },
}));
