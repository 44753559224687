import {
  ActivityCoOrganizerData,
  ActivityData,
  DoubleListItemType,
  PersonAssignmentData,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import {
  convertPersonAssignmentToCoOrganizer,
  CoOrganizerCard,
} from '@/components/CoOrganizer';
import { DoubleListDialog } from '@/components/DoubleList';
import { errorConfigBannerDialog } from '@/hooks';
import {
  useGetPersonAssignmentListQuery,
  useUpdateActivityMutation,
} from '@/services/graphql/hooks';
import { parsePersonAssignmentConnection } from '@/services/graphql/parsers';
import { UpdateActivityAction } from '@/services/graphql/types';
import { prepareDataForUpdateActivityMutation } from '@/views/Activity/TouchpointManagement/helper';

type CoOrganizerEditDialogProps = {
  isOpen: boolean;
  handleClose: VoidFunction;
  activity: ActivityData;
};
export const CoOrganizerEditDialog = ({
  isOpen,
  handleClose,
  activity,
}: CoOrganizerEditDialogProps) => {
  const { t } = useTranslation();

  const { data, loading } = useGetPersonAssignmentListQuery({
    ...errorConfigBannerDialog,
    variables: {
      filter: {
        primary: { _eq: true },
      },
    },
  });

  const [updateActivityMutation, { loading: isSubmitting }] =
    useUpdateActivityMutation();

  const personAssignments: PersonAssignmentData[] =
    parsePersonAssignmentConnection(data?.personAssignments);
  const availableCoOrganizers =
    convertPersonAssignmentToCoOrganizer(personAssignments);

  const availableItems: DoubleListItemType<ActivityCoOrganizerData>[] =
    availableCoOrganizers.map((item) => {
      return {
        id: item.person?.id ?? '',
        target: item,
      };
    });

  const selectedItems: DoubleListItemType<ActivityCoOrganizerData>[] =
    activity.coOrganizers?.map((item: ActivityCoOrganizerData) => {
      return {
        id: item.person?.id ?? '',
        target: item,
      };
    }) ?? [];

  const handleSave = (coOrganizers: ActivityCoOrganizerData[]) => {
    const changes: UpdateActivityAction = prepareDataForUpdateActivityMutation(
      { coOrganizers: coOrganizers },
      activity
    );

    updateActivityMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: activity.oid ?? '',
        actions: [changes],
      },
      onCompleted: () => {
        handleClose();
      },
    });
  };

  return (
    <DoubleListDialog
      isOpen={isOpen}
      isLoading={loading || isSubmitting}
      title={t('pages.activity.coOrganizer.coOrganizerTitle')}
      titleForAvailableCount={t(
        'pages.activity.coOrganizer.availableCoOrganizer'
      )}
      titleForSelectedCount={t(
        'pages.activity.coOrganizer.selectedCoOrganizer'
      )}
      emptySelectedInfo={t('pages.activity.coOrganizer.noCoOrganizer')}
      testId="coOrganizer-edit-card"
      handleSave={handleSave}
      handleCancel={handleClose}
      renderSelectedItem={(coOrganizer: ActivityCoOrganizerData) => (
        <CoOrganizerCard
          key={coOrganizer.id}
          person={coOrganizer.person}
          organizationalUnit={coOrganizer.organizationalUnit}
        />
      )}
      renderAvailableItem={(
        coOrganizer: ActivityCoOrganizerData,
        query?: string
      ) => (
        <CoOrganizerCard
          key={coOrganizer.id}
          person={coOrganizer.person}
          query={query}
          organizationalUnit={coOrganizer.organizationalUnit}
        />
      )}
      preSelectedItems={selectedItems}
      initialAvailableItems={availableItems}
      onSearch={(query: string, coOrganizer: ActivityCoOrganizerData) =>
        coOrganizer.person?.firstName
          ?.toLowerCase()
          ?.includes(query.toLowerCase()) ||
        coOrganizer.person?.lastName
          ?.toLowerCase()
          ?.includes(query.toLowerCase())
      }
    />
  );
};
