import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type AddMediaTrackingInfoMutationVariables = Types.Exact<{
  oid: Types.Scalars['MediaTrackingInfoId']['input'];
  mediaId: Types.Scalars['MediaId']['input'];
  mediaVersionId: Types.Scalars['MediaVersionId']['input'];
  shownTime: Types.Scalars['DateTime']['input'];
  presenterPersonId: Types.Scalars['PersonId']['input'];
  audiencePersonId?: Types.InputMaybe<Types.Scalars['PersonId']['input']>;
  data:
    | Array<Types.MediaTrackingInfoDataInput>
    | Types.MediaTrackingInfoDataInput;
  state: Types.State;
}>;

export type AddMediaTrackingInfoMutation = {
  __typename?: 'Mutation';
  addMediaTrackingInfo?: {
    __typename?: 'MediaTrackingInfo';
    id: string;
    oid: string;
  } | null;
};

export type AddMediaTrackingInfoMutationFn = Apollo.MutationFunction<
  AddMediaTrackingInfoMutation,
  AddMediaTrackingInfoMutationVariables
>;

/**
 * __useAddMediaTrackingInfoMutation__
 *
 * To run a mutation, you first call `useAddMediaTrackingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMediaTrackingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMediaTrackingInfoMutation, { data, loading, error }] = useAddMediaTrackingInfoMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      mediaId: // value for 'mediaId'
 *      mediaVersionId: // value for 'mediaVersionId'
 *      shownTime: // value for 'shownTime'
 *      presenterPersonId: // value for 'presenterPersonId'
 *      audiencePersonId: // value for 'audiencePersonId'
 *      data: // value for 'data'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAddMediaTrackingInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMediaTrackingInfoMutation,
    AddMediaTrackingInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddMediaTrackingInfoMutation,
    AddMediaTrackingInfoMutationVariables
  >(Operations.addMediaTrackingInfo, options);
}
export type AddMediaTrackingInfoMutationHookResult = ReturnType<
  typeof useAddMediaTrackingInfoMutation
>;
export type AddMediaTrackingInfoMutationResult =
  Apollo.MutationResult<AddMediaTrackingInfoMutation>;
export type AddMediaTrackingInfoMutationOptions = Apollo.BaseMutationOptions<
  AddMediaTrackingInfoMutation,
  AddMediaTrackingInfoMutationVariables
>;
