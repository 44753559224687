import {
  DataPermissionData,
  DataPermissionTypeDataEnum,
  PersonAssignmentData,
} from '@ysura/common';

import {
  parseOrganizationalUnitData,
  parsePersonAssignmentTypeData,
  parsePersonData,
} from '@/services/graphql/parsers';
import { convertedState } from '@/services/graphql/parsers/utils';
import {
  DataPermission,
  PersonAssignment,
  PersonAssignmentConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parsePersonAssignmentData = (
  personAssignment?: DeepPartial<PersonAssignment>
): PersonAssignmentData => {
  return {
    id: personAssignment?.id,
    oid: personAssignment?.oid,
    person: personAssignment?.person
      ? parsePersonData(personAssignment.person)
      : undefined,
    organizationalUnit: personAssignment?.organizationalUnit
      ? parseOrganizationalUnitData(personAssignment.organizationalUnit)
      : undefined,
    primary: personAssignment?.primary ?? undefined,
    startDate: personAssignment?.startDate,
    endDate: personAssignment?.endDate,
    type: personAssignment?.type
      ? parsePersonAssignmentTypeData(personAssignment.type)
      : undefined,
    state: convertedState(personAssignment?.state),
    dataPermissions: parseDataPermissions(
      personAssignment?.dataPermissions ?? []
    ),
  };
};

export const parseDataPermissions = (
  dataPermissions: DeepPartial<DataPermission[]>
): Array<DataPermissionData> => {
  const permissions = dataPermissions.map((each) => {
    return parseDataPermission(each);
  });

  return filterNonNull(permissions);
};

export const parseDataPermission = (
  dataPermission?: DeepPartial<DataPermission>
): DataPermissionData | undefined => {
  return dataPermission
    ? {
        type: dataPermission.type as unknown as DataPermissionTypeDataEnum,
        scoped: dataPermission.scoped,
      }
    : undefined;
};

export const parsePersonAssignmentConnection = (
  personAssignmentConnection?: DeepPartial<PersonAssignmentConnection>
): Array<PersonAssignmentData> => {
  const rawData = personAssignmentConnection?.edges ?? [];
  const mappedData = rawData
    .map((edge) => edge?.node ?? null)
    .map((data) => (data ? parsePersonAssignmentData(data) : null));

  return filterNonNull(mappedData);
};
