import { Box } from '@mui/material';
import { getPersonFullNameWithTitle, Loader } from '@ysura/common';

import { useAuth, useCurrentUser } from '@/hooks';
import { createPersonInitials } from '@/utils/createPersonInitials';

import { Avatar, Props as AvatarProps } from './Avatar';

export const MyAvatar = ({ ...other }: AvatarProps) => {
  const { user: userInfo } = useAuth();
  const { loading, currentUser } = useCurrentUser();

  if (loading) {
    return <Loader />;
  }

  const displayName = currentUser?.person?.firstName
    ? getPersonFullNameWithTitle(currentUser?.person)
    : currentUser?.username;
  const initials = createPersonInitials(displayName);

  return (
    <Box data-testid="menu-avatar">
      <Avatar
        src={userInfo?.avatarUrl}
        alt={displayName}
        color={'default'}
        {...other}
      >
        {initials}
      </Avatar>
    </Box>
  );
};
