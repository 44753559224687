import {
  getPersonFullNameWithTitle,
  PersonData,
  PromotionalMaterialGroupBatchData,
  PromotionalMaterialGroupData,
} from '@ysura/common';
import { format } from 'date-fns';

import { SampleRequestFormItemInput } from '@/services/graphql/types';
import { filterNonNull } from '@/utils/dataHelpers/filters';

type SelectedStock = {
  stockId: string | undefined;
  requestedQuantity?: number;
};

export const extractStockInfo = (
  batch: PromotionalMaterialGroupBatchData
): SampleRequestFormItemInput | null => {
  const hasPhysicalStock = batch.physicalStock.requestedQuantity !== 0;
  const hasVirtualStock = batch.virtualStock.requestedQuantity !== 0;

  // Both physical and virtual stock is invalid
  if (hasPhysicalStock && hasVirtualStock) {
    return null;
  }

  // Neither physical nor virtual stock quantity requested is invalid.
  if (!hasPhysicalStock && !hasVirtualStock) {
    return null;
  }

  const stock = hasPhysicalStock ? batch.physicalStock : batch.virtualStock;

  if (!stock || !stock.stock?.oid) {
    return null;
  }

  return {
    stockId: stock.stock.oid,
    quantity: stock.requestedQuantity,
  };
};

export const buildFilename = (signer: PersonData): string => {
  const date = format(new Date(), 'yyyy_MM_dd');
  const signerName = getPersonFullNameWithTitle(signer)
    .replace(' ', '_')
    .replace('.', '_');

  return `${date}_${signerName}_Samplerequest`;
};

export const extractSampleRequestFormItems = (
  sampleDrops: Array<PromotionalMaterialGroupData>
): Array<SampleRequestFormItemInput> => {
  return sampleDrops
    .filter((each) => each.promotionalMaterial?.signatureRequired)
    .flatMap((drop) => {
      const mappedBatches = (drop?.batches ?? []).map((batch) =>
        extractStockInfo(batch)
      );

      return filterNonNull(mappedBatches);
    });
};

export const haveSignatureRelevantMaterialsChanged = (
  previousPromotionalMaterials: Array<PromotionalMaterialGroupData>,
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>
): boolean => {
  const selectedStocksWithSignature = extractSignatureRequiredSelectedItems(
    selectedPromotionalMaterials
  );
  const previousStocksWithSignature = extractSignatureRequiredSelectedItems(
    previousPromotionalMaterials
  );

  return (
    JSON.stringify(previousStocksWithSignature) !==
    JSON.stringify(selectedStocksWithSignature)
  );
};

export const extractSignatureRequiredSelectedItems = (
  promotionalMaterials: Array<PromotionalMaterialGroupData>
): Array<SelectedStock> => {
  return filterNonNull(
    promotionalMaterials
      .filter((each) => each.promotionalMaterial?.signatureRequired)
      .map((each) => {
        return each.batches.flatMap((batch) => {
          const selected = [];
          if (batch.physicalStock.requestedQuantity) {
            selected.push({
              stockId: batch.physicalStock.stock?.oid,
              requestedQuantity: batch.physicalStock.requestedQuantity,
            });
          }
          if (batch.virtualStock.requestedQuantity) {
            selected.push({
              stockId: batch.virtualStock.stock?.oid,
              requestedQuantity: batch.virtualStock.requestedQuantity,
            });
          }

          return selected;
        });
      })
      .flat()
  );
};
