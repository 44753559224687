import { Card, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, ActivityStatusDataEnum } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  ActivityAttendee,
  ActivityDate,
  ActivityOrganization,
  ActivityOrganizer,
  ActivityStatusDataBlock,
} from '@/components/ActivityDetails';
import { EditIconButton } from '@/components/Button';
import { ActivityIcon } from '@/components/Icon/ActivityIcon';

import {
  ActivityTypeStack,
  CardHeaderStack,
  StyledCardContent,
  StyledCardHeader,
} from './ActivityDetailStyles';

type ActivityDetailProps = {
  activity: ActivityData;
  isLink: boolean;
  variant: 'dense' | 'spacious';
  canEditActivity?: boolean;
  openEditActivityInfoDialog?: VoidFunction;
};

export const ActivityDetailCard = ({
  activity,
  isLink,
  variant,
  canEditActivity,
  openEditActivityInfoDialog,
}: ActivityDetailProps) => {
  const { t } = useTranslation();

  const isPlannedActivity = activity.status === ActivityStatusDataEnum.PLANNED;
  const padding = variant === 'dense' ? 2 : 3;
  const typographyProps: TypographyProps = {
    color: isPlannedActivity ? 'text.primary' : 'text.secondary',
    variant: 'subtitle2',
  };

  const handleStartEditing = () => {
    openEditActivityInfoDialog?.();
  };

  const Content = () => (
    <Card>
      <StyledCardHeader
        title={
          !isLink ? (
            <CardHeaderStack>
              {t('components.touchpointManagement.touchpointInfo')}
              {canEditActivity && (
                <EditIconButton
                  testId="touchpoint-info-edit-button"
                  onClick={handleStartEditing}
                />
              )}
            </CardHeaderStack>
          ) : null
        }
        subheader={
          <CardHeaderStack>
            <ActivityTypeStack>
              <ActivityIcon baseType={activity?.activityType?.baseType} />
              {activity.activityType?.name}
            </ActivityTypeStack>

            <ActivityStatusDataBlock activity={activity} />
          </CardHeaderStack>
        }
        titleTypographyProps={typographyProps}
        subheaderTypographyProps={typographyProps}
        padding={padding}
      />

      <StyledCardContent padding={padding}>
        <ActivityDate activity={activity} />

        <ActivityOrganizer activity={activity} />

        <ActivityAttendee activity={activity} />

        {activity.organization && <ActivityOrganization activity={activity} />}
      </StyledCardContent>
    </Card>
  );

  if (isLink) {
    return (
      <StyledLink
        to={`/touchpoint/${activity.id}`}
        data-testid="TouchpointLink"
      >
        <Content />
      </StyledLink>
    );
  }

  return <Content />;
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;
