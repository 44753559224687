type SymmetricalEnum<TEnum> = {
  [key in keyof TEnum]: key;
};

type MapperResult<
  TSourceEnumObj,
  TDestEnumObj extends SymmetricalEnum<TSourceEnumObj>,
  TSourceValue extends keyof TSourceEnumObj,
> = TDestEnumObj extends { [key in TSourceValue]: infer TResult }
  ? TResult
  : never;

export const createEnumMapperFunction =
  <TSourceEnumObj, TDestEnumObj extends SymmetricalEnum<TSourceEnumObj>>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    from: TSourceEnumObj,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    to: TDestEnumObj
  ) =>
  <TInput extends keyof TSourceEnumObj>(value: TInput) =>
    value as MapperResult<TSourceEnumObj, TDestEnumObj, TInput>;
