import {
  ConsentInputFieldData,
  ConsentItemData,
  ConsentValue,
  CountryData,
  getInitialValues,
  PersonConsentData,
  PersonConsentLifecycleStateDataEnum,
  PersonData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { ConsentCollectionContent } from '@/components/Consent/ConsentCollectionContent';
import { useInteraction } from '@/hooks';
import { errorConfigBannerDialog } from '@/hooks/useNotification';
import { useUpdatePersonConsentItemsMutation } from '@/services/graphql/hooks';
import {
  ConsentItemStateChange,
  PersonConsentItemValueInput,
} from '@/services/graphql/types/types';

type EditConsentCollectionDialogProps = {
  isOpen: boolean;
  consent: PersonConsentData;
  person: PersonData;
  defaultCountry?: CountryData;
  handleClose: VoidFunction;
  handleConfirm: (message: string) => void;
};

export const EditConsentCollectionDialog = ({
  isOpen,
  consent,
  person,
  defaultCountry,
  handleClose,
  handleConfirm,
}: EditConsentCollectionDialogProps) => {
  const { t } = useTranslation();

  const { setConsentAsCollected } = useInteraction();

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [consentValues, setConsentValues] = useState<ConsentValue | undefined>(
    () => {
      return getInitialValues(
        consent.consentItemValues ?? [],
        consent.consentType?.consentItems ?? []
      );
    }
  );

  const [updatePersonConsentMutation, { loading: isCollectConsentLoading }] =
    useUpdatePersonConsentItemsMutation();

  const handleSave = () => {
    const consentItemsValues: Array<PersonConsentItemValueInput> =
      consent.consentType?.consentItems?.map((it: ConsentItemData) => {
        const collectedCommunicationData =
          it.consentInputFields?.map((input: ConsentInputFieldData) => {
            return {
              oid: input.consentCommunicationDataTypeId,
              value: consentValues?.[input.inputId ?? ''] as string,
            };
          }) ?? [];

        return {
          collectedCommunicationData: collectedCommunicationData,
          itemState: consentValues?.[it.inputId ?? '']
            ? ConsentItemStateChange.TURN_ON
            : ConsentItemStateChange.TURN_OFF,
          oid: it.oid,
        };
      }) ?? [];
    handleSubmitForm(consentItemsValues);
  };

  const handleSubmitForm = (
    consentItemsValues: Array<PersonConsentItemValueInput>
  ) => {
    if (!person?.oid || !consent?.consentType?.oid) {
      return;
    }

    updatePersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent.oid ?? '',
        consentItemsValues: consentItemsValues,
      },
      onCompleted: (data) => {
        if (data?.updatePersonConsentItems?.oid) {
          setConsentAsCollected?.(data.updatePersonConsentItems.oid);
        }
        handleConfirm(t('pages.interaction.toasts.consentFormSubmitted'));
      },
    });
  };

  if (isOpen && !person) {
    console.log('No attendee in activity');

    return null;
  }

  const switchable: Record<
    string,
    Record<PersonConsentLifecycleStateDataEnum, boolean>
  > = (consent.consentType?.consentItems ?? []).reduce(
    (
      acc: Record<string, Record<PersonConsentLifecycleStateDataEnum, boolean>>,
      current: ConsentItemData
    ) => {
      if (current.oid) {
        acc[current.oid] = {
          DEFAULT: false,
          MERGED: false,
          REJECTED: false,
          PENDING_PAPER: current.pendingPaperSwitchable ?? false,
          PENDING_DOUBLE_OPT_IN: current.pendingDoubleOptInSwitchable ?? false,
          ACCEPTED: current.acceptedSwitchable ?? false,
        };
      }

      return acc;
    },
    {}
  );

  return (
    <ActionDialog
      isOpen={isOpen}
      maxWidth={'lg'}
      title={consent?.consentType?.name ?? ''}
      primaryButtonText={t('components.common.save')}
      secondaryButtonText={t('components.common.cancel')}
      isPrimaryButtonDisabled={!isFormValid}
      isLoading={isCollectConsentLoading}
      testId={'edit-consent-collection-dialog'}
      onClickPrimaryButton={handleSave}
      onClickSecondaryButton={handleClose}
    >
      <ConsentCollectionContent
        consentType={consent.consentType}
        person={person}
        defaultCountry={defaultCountry}
        setIsFormValid={setIsFormValid}
        consentValues={consentValues}
        switchable={switchable}
        lifecycleState={consent.lifecycleState}
        setConsentValues={setConsentValues}
      />
    </ActionDialog>
  );
};
