import { Box, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

type OverviewSkeletonStaticBlockProps = {
  children: ReactNode;
};

export const OverviewSkeletonStaticBlock = ({
  children,
}: OverviewSkeletonStaticBlockProps) => {
  return (
    <StyledCard data-testid="overview-skeleton-static-block">
      <StyledCardHeader
        customSpacing="spacious"
        title={
          <TitleWrapper>
            <Skeleton width={150} />
          </TitleWrapper>
        }
      />

      <StyledCardContent customSpacing="spacious">{children}</StyledCardContent>
    </StyledCard>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const StyledCardContent = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'customSpacing',
})<{ customSpacing: 'dense' | 'spacious' }>(({ theme, customSpacing }) => ({
  padding: customSpacing === 'dense' ? theme.spacing(2) : theme.spacing(3),
  paddingTop: theme.spacing(2),
  ':last-child': {
    paddingBottom:
      customSpacing === 'dense' ? theme.spacing(2) : theme.spacing(3),
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    ':last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const StyledCardHeader = styled(CardHeader, {
  shouldForwardProp: (prop) => prop !== 'customSpacing',
})<{ customSpacing: 'dense' | 'spacious' }>(({ theme, customSpacing }) => ({
  padding: customSpacing === 'dense' ? theme.spacing(2) : theme.spacing(3),
  paddingBottom: 0,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
