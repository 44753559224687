import { GivenPromotionalMaterialData } from '@ysura/common';

import {
  parsePromotionalMaterial,
  parseStockData,
} from '@/services/graphql/parsers';
import { convertedGivenPromotionalMaterialChangeReason } from '@/services/graphql/parsers/utils';
import {
  GivenPromotionalMaterial,
  GivenPromotionalMaterialConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseGivenPromotionalMaterialData = (
  givenPromotionalMaterial: DeepPartial<GivenPromotionalMaterial>
): GivenPromotionalMaterialData => {
  return {
    oid: givenPromotionalMaterial?.oid,
    promotionalMaterial: givenPromotionalMaterial?.promotionalMaterial
      ? parsePromotionalMaterial(givenPromotionalMaterial?.promotionalMaterial)
      : undefined,
    stock: givenPromotionalMaterial?.stock
      ? parseStockData(givenPromotionalMaterial?.stock)
      : undefined,
    quantity: givenPromotionalMaterial?.quantity ?? 0,
    requestedQuantity: givenPromotionalMaterial?.requestedQuantity ?? 0,
    changeReason: convertedGivenPromotionalMaterialChangeReason(
      givenPromotionalMaterial?.changeReason
    ),
  };
};

export const parseGivenPromotionalMaterialConnection = (
  givenPromotionalMaterialConnection: DeepPartial<GivenPromotionalMaterialConnection>
): Array<GivenPromotionalMaterialData> => {
  const edges = givenPromotionalMaterialConnection?.edges ?? [];

  const parsed = edges
    .map((edge) => edge?.node ?? null)
    .map((node) => (node ? parseGivenPromotionalMaterialData(node) : null));

  return filterNonNull(parsed);
};
