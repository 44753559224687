import { Stack } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  DeliveryInformation,
  DeliveryTimeFrameData,
  PersonData,
  PromotionalMaterialGroupData,
  RequestedSamples,
} from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

type ProMatCollectionCollectProps = {
  promotionalMaterialGroups: PromotionalMaterialGroupData[];
  attendee?: PersonData;
  activity: ActivityData;
  deliveryTimeFrames: DeliveryTimeFrameData[];
  addressValue?: string;
  setAddressValue: (value: string) => void;
  addressExtension: string;
  setAddressExtension: (value: string) => void;
  signature: string;
  isDeliveryInformationEnabled: boolean;
  deliveryTimeFrame: string | null;
  setDeliveryTimeFrame: (value: string) => void;
  isDeliveryDateEnabled: boolean;
  deliveryDate: Date | null;
  setDeliveryDate: (value: Date | null) => void;
  deliveryDateMinimumDays: number;
};

export const ProMatCollectionCollect = ({
  promotionalMaterialGroups,
  attendee,
  activity,
  deliveryTimeFrames,
  addressValue,
  setAddressValue,
  addressExtension,
  setAddressExtension,
  signature,
  isDeliveryInformationEnabled,
  deliveryTimeFrame,
  setDeliveryTimeFrame,
  isDeliveryDateEnabled,
  deliveryDate,
  setDeliveryDate,
  deliveryDateMinimumDays,
}: ProMatCollectionCollectProps) => {
  const { t } = useTranslation();
  const { signedSampleRequestForm } = useInteraction();
  const handleOnChangeAddressExtension = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAddressExtension(event.target.value);
  };

  const handleChangeAddressCheckbox = (value: string) => {
    setAddressValue(value);
  };

  const handleChangeDeliveryTimeFrame = (
    event: SelectChangeEvent<string | null>
  ) => {
    setDeliveryTimeFrame(event.target.value ?? '');
  };

  const handleChangeDeliveryDate = (value: Date | null) => {
    setDeliveryDate(value);
  };

  // either the signed form is already in the activity or it's still in the interaction state
  const isSigned =
    !!activity.sampleRequestFormDocument || !!signedSampleRequestForm;

  return (
    <StyledStack data-testid="promats-collection">
      <RequestedSamples
        title={`${t('pages.interaction.expand.requestedSamples')}:`}
        promats={promotionalMaterialGroups.filter(
          (promat) => promat.promotionalMaterial?.signatureRequired
        )}
        isSigned={isSigned}
      />

      <DeliveryInformation
        addressExtension={addressExtension}
        initialAddress={addressValue}
        firstAttendee={attendee}
        deliveryTimeFrame={deliveryTimeFrame}
        deliveryDate={deliveryDate}
        deliveryTimeFrameEnabled={isDeliveryInformationEnabled}
        deliveryDateEnabled={isDeliveryDateEnabled}
        deliveryDateMinimumDays={deliveryDateMinimumDays}
        deliveryTimeFrames={deliveryTimeFrames}
        signature={signature}
        isSignatureDisabled={true}
        onChangeDeliveryTimeFrame={handleChangeDeliveryTimeFrame}
        onChangeDeliveryDate={handleChangeDeliveryDate}
        onChangeAddressExtension={handleOnChangeAddressExtension}
        onChangeAddress={handleChangeAddressCheckbox}
      />
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));
