import {
  EmploymentData,
  EmploymentDepartmentData,
  EmploymentFunctionData,
} from '@ysura/common';

import {
  parseCommunicationDataData,
  parseEntityAddressConnection,
  parseOrganizationData,
  parsePersonData,
} from '@/services/graphql/parsers';
import { convertedState } from '@/services/graphql/parsers/utils';
import {
  EmployeeDepartment,
  EmployeeFunction,
  EmployeeFunctionConnection,
  Employment,
  EmploymentConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseEmploymentDepartment = (
  department?: DeepPartial<EmployeeDepartment> | null
): EmploymentDepartmentData => {
  return {
    id: department?.id,
    oid: department?.oid,
    name: department?.name ?? undefined,
    customerReference: department?.customerReference ?? undefined,
    state: convertedState(department?.state),
  };
};

export const parseEmploymentFunction = (
  employmentFunction?: DeepPartial<EmployeeFunction> | null
): EmploymentFunctionData => {
  return {
    id: employmentFunction?.id,
    oid: employmentFunction?.oid,
    name: employmentFunction?.name ?? undefined,
    customerReference: employmentFunction?.customerReference ?? undefined,
    state: convertedState(employmentFunction?.state),
  };
};

export const parseEmploymentData = (
  employment?: DeepPartial<Employment> | null
): EmploymentData => {
  return {
    id: employment?.id,
    oid: employment?.oid,
    employmentFunction: parseEmploymentFunction(employment?.function),
    department: parseEmploymentDepartment(employment?.department),
    addresses: parseEntityAddressConnection(employment?.addresses),
    communicationData: parseCommunicationDataData(
      employment?.communicationData
    ),
    organization: employment?.organization
      ? parseOrganizationData(employment.organization)
      : undefined,
    person: employment?.person ? parsePersonData(employment.person) : undefined,
  };
};

export const parseEmploymentConnection = (
  employment?: DeepPartial<EmploymentConnection> | null
): Array<EmploymentData> => {
  const rawEmployments = employment?.edges ?? [];
  const mappedEmployment = rawEmployments.map((edge) => edge?.node ?? null);

  return filterNonNull(mappedEmployment).map((node) =>
    parseEmploymentData(node)
  );
};

export const parseEmployeeFunctionsConnection = (
  employeeFunctions?: DeepPartial<EmployeeFunctionConnection> | null
): Array<EmploymentFunctionData> => {
  const rawEmployeeFunctions = employeeFunctions?.edges ?? [];
  const mappedEmployeeFunctions = rawEmployeeFunctions.map(
    (edge) => edge?.node ?? null
  );

  return filterNonNull(mappedEmployeeFunctions).map((node) =>
    parseEmploymentFunction(node)
  );
};
