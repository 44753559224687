import { ReactElement } from 'react';

import { keycloak } from './keycloak';
import { KeycloakProvider } from './useKeycloak';

type KeycloakProviderProps = {
  children: ReactElement;
};

export const ProviderWrapper = ({
  children,
}: KeycloakProviderProps): ReactElement | null => {
  return <KeycloakProvider authClient={keycloak}>{children}</KeycloakProvider>;
};
