import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DeliveryInformation,
  RequestedSamples,
  SampleRequestPreview,
  SampleRequestViewerPayload,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInteraction } from '@/hooks';

interface SampleRequestCollectionProps {
  payload: SampleRequestViewerPayload;
}
export const CoOrganizerSampleRequestCollection = ({
  payload,
}: SampleRequestCollectionProps) => {
  const [sampleRequest, setSharingSampleRequest] =
    useState<SampleRequestViewerPayload>(payload);

  const { onSampleRequestValueChanged } = useInteraction();

  useEffect(() => {
    setSharingSampleRequest(payload);
  }, [payload]);

  useEffect(() => {
    const handleShowSampleRequest = (data: SampleRequestViewerPayload) => {
      setSharingSampleRequest(data);
    };

    const unsubscribeOnSampleRequestChange = onSampleRequestValueChanged?.(
      handleShowSampleRequest
    );

    return () => {
      if (unsubscribeOnSampleRequestChange) {
        unsubscribeOnSampleRequestChange();
      }
    };
  }, [onSampleRequestValueChanged, setSharingSampleRequest]);

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Typography variant="h5">{sampleRequest.state.title}</Typography>
      </Header>
      <Content>
        {sampleRequest?.state?.step === 'collect' && (
          <StyledStack data-testid="sample-request-collection">
            <RequestedSamples
              title={`${t('pages.interaction.expand.requestedSamples')}:`}
              promats={sampleRequest.state.requestedSamples.filter(
                (promat) => promat.promotionalMaterial?.signatureRequired
              )}
              isSigned={sampleRequest.state.isSampleRequestSigned}
            />

            <DeliveryInformation
              addressExtension={sampleRequest.state.addressExtension}
              initialAddress={sampleRequest.state.address}
              firstAttendee={sampleRequest.state.attendee}
              deliveryTimeFrame={sampleRequest.state.deliveryTimeFrame}
              deliveryDate={sampleRequest.state.deliveryDate}
              deliveryTimeFrameEnabled={
                sampleRequest.state.isDeliveryInformationEnabled
              }
              deliveryDateEnabled={sampleRequest.state.isDeliveryDateEnabled}
              deliveryDateMinimumDays={
                sampleRequest.state.deliveryDateMinimumDays
              }
              deliveryTimeFrames={sampleRequest.state.deliveryTimeFrames}
              signature={sampleRequest.state.signature}
              isSignatureDisabled={true}
              disable={true}
            />
          </StyledStack>
        )}
        {sampleRequest?.state?.step === 'preview' && (
          <SampleRequestPreview state={sampleRequest.state} />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
});

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: theme.spacing(2),
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
}));

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  overflow: 'auto',
});

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));
