import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetTouchpointMediaListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  nameFilter?: Types.InputMaybe<Types.StringFilterInput>;
  orderByName: Types.OrderBy;
}>;

export type GetTouchpointMediaListQuery = {
  __typename?: 'Query';
  activity?: {
    __typename?: 'Activity';
    id: string;
    oid: string;
    activityType: {
      __typename?: 'ActivityType';
      id: string;
      oid: string;
      name: string;
      baseType: Types.ActivityTypeBaseType;
      medias: {
        __typename?: 'MediaConnection';
        filteredCount: number;
        edges?: Array<{
          __typename?: 'MediaEdge';
          node?: {
            __typename?: 'Media';
            id: string;
            oid: string;
            name: string;
            description?: string | null;
            mediaType?: Types.SupportedMediaType | null;
            thumbnailDocument?: {
              __typename?: 'Document';
              id: string;
              oid: string;
              contentLink: string;
              mediaType: string;
            } | null;
            latestVersion: {
              __typename?: 'MediaVersion';
              oid: string;
              version?: number | null;
              document?: {
                __typename?: 'Document';
                id: string;
                oid: string;
                contentLink: string;
                mediaType: string;
              } | null;
            };
            topics: {
              __typename?: 'TopicConnection';
              edges?: Array<{
                __typename?: 'TopicEdge';
                node?: {
                  __typename?: 'Topic';
                  id: string;
                  oid: string;
                  name: string;
                  state?: Types.State | null;
                } | null;
              }> | null;
            };
            keyMessages: {
              __typename?: 'KeyMessageConnection';
              edges?: Array<{
                __typename?: 'KeyMessageEdge';
                node?: {
                  __typename?: 'KeyMessage';
                  id: string;
                  oid: string;
                  name?: string | null;
                  state: Types.State;
                  topics: {
                    __typename?: 'TopicConnection';
                    edges?: Array<{
                      __typename?: 'TopicEdge';
                      node?: {
                        __typename?: 'Topic';
                        id: string;
                        oid: string;
                        name: string;
                        state?: Types.State | null;
                      } | null;
                    }> | null;
                  };
                } | null;
              }> | null;
            };
            dataPermissions: Array<{
              __typename?: 'DataPermission';
              type: Types.DataPermissionType;
            }>;
          } | null;
        }> | null;
      };
      topics: {
        __typename?: 'TopicConnection';
        edges?: Array<{
          __typename?: 'TopicEdge';
          node?: {
            __typename?: 'Topic';
            id: string;
            oid: string;
            name: string;
          } | null;
        }> | null;
      };
    };
  } | null;
};

/**
 * __useGetTouchpointMediaListQuery__
 *
 * To run a query within a React component, call `useGetTouchpointMediaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTouchpointMediaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTouchpointMediaListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nameFilter: // value for 'nameFilter'
 *      orderByName: // value for 'orderByName'
 *   },
 * });
 */
export function useGetTouchpointMediaListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTouchpointMediaListQuery,
    GetTouchpointMediaListQueryVariables
  > &
    (
      | { variables: GetTouchpointMediaListQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetTouchpointMediaListQuery,
    GetTouchpointMediaListQueryVariables
  >(Operations.getTouchpointMediaList, options);
}
export function useGetTouchpointMediaListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTouchpointMediaListQuery,
    GetTouchpointMediaListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetTouchpointMediaListQuery,
    GetTouchpointMediaListQueryVariables
  >(Operations.getTouchpointMediaList, options);
}
export function useGetTouchpointMediaListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTouchpointMediaListQuery,
    GetTouchpointMediaListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetTouchpointMediaListQuery,
    GetTouchpointMediaListQueryVariables
  >(Operations.getTouchpointMediaList, options);
}
export type GetTouchpointMediaListQueryHookResult = ReturnType<
  typeof useGetTouchpointMediaListQuery
>;
export type GetTouchpointMediaListLazyQueryHookResult = ReturnType<
  typeof useGetTouchpointMediaListLazyQuery
>;
export type GetTouchpointMediaListSuspenseQueryHookResult = ReturnType<
  typeof useGetTouchpointMediaListSuspenseQuery
>;
export type GetTouchpointMediaListQueryResult = Apollo.QueryResult<
  GetTouchpointMediaListQuery,
  GetTouchpointMediaListQueryVariables
>;
