import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ActivityStatusDataEnum, useLocalStorage } from '@ysura/common';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useAudioVideoSettings,
  useCurrentUser,
  useInteraction,
  useNotification,
} from '@/hooks';
import { InteractionBackTopLayout, InteractionLayout } from '@/layouts';
import { InvalidActivity } from '@/pages/Error';
import { InteractionProps } from '@/pages/Interaction';
import { isUserOrganizer } from '@/services/activities';
import { redirectBackToCrm } from '@/services/api';
import { useGetPersonConsentListQuery } from '@/services/graphql/hooks';
import { parsePersonConsents } from '@/services/graphql/parsers';
import { InteractionSummary } from '@/views/Interaction';
import {
  RemoteRoom,
  RemoteWaitingRoom,
  RemoteWaitingRoomMobile,
} from '@/views/Interaction/Remote';

export const RemoteInteraction = ({
  activity,
  canEditActivity,
}: InteractionProps) => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const {
    initializeStateServer,
    isStateServerInitialized,
    joinInteractionFromWaitingRoom,
    leaveInteraction,
    onOrganizerTerminateSession,
    interactionStep,
    currentRole,
    heartbeat,
  } = useInteraction();

  const { toast } = useNotification();
  const { t } = useTranslation();

  const isOrganizer = isUserOrganizer(activity, currentUser?.person);
  const [isFromCrm] = useLocalStorage<boolean>('fromCrm', false);

  const isInMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const firstAttendee = activity?.attendees?.[0];

  useEffect(() => {
    if (
      (interactionStep === 'interaction' ||
        interactionStep === 'waiting-room') &&
      activity.id
    ) {
      initializeStateServer({
        roomId: activity.id,
        role: isOrganizer ? 'organizer' : 'co-organizer',
        interactionStep,
      });
    }
  }, [initializeStateServer, activity.id, interactionStep, isOrganizer]);

  useEffect(() => {
    let heartbeatInterval: NodeJS.Timeout;
    if (
      isStateServerInitialized &&
      currentUser?.person?.oid &&
      currentRole &&
      (interactionStep === 'interaction' || interactionStep === 'waiting-room')
    ) {
      heartbeatInterval = setInterval(() => {
        heartbeat();
      }, 5000);
    }

    return () => {
      clearInterval(heartbeatInterval);
    };
  }, [
    currentRole,
    currentUser?.person?.oid,
    heartbeat,
    interactionStep,
    isStateServerInitialized,
  ]);

  const handleSessionTerminated = useCallback(() => {
    toast?.({
      message: t('pages.interaction.toasts.sessionEnded'),
      type: 'info',
    });
    leaveInteraction();
    if (isFromCrm) {
      redirectBackToCrm();
    } else {
      navigate(`/touchpoint/${activity.id}`);
    }
  }, [activity.id, leaveInteraction, navigate, t, toast, isFromCrm]);

  useEffect(() => {
    // handles the session terminating. Reasons for this can be:
    // - Organizer closes the room
    // - Organizer forcefully removes the attendee from the room
    // - The session expires
    const unsubscribeOnOrganizerTerminateSession =
      onOrganizerTerminateSession?.(handleSessionTerminated);

    return () => {
      unsubscribeOnOrganizerTerminateSession?.();
    };
  }, [handleSessionTerminated, onOrganizerTerminateSession]);

  // run necessary mic & video hardware checks
  useAudioVideoSettings();

  // Get Person Consent by PersonId
  const { data: consentData, loading: consentLoading } =
    useGetPersonConsentListQuery({
      variables: {
        id: firstAttendee?.person?.id ?? '',
      },
      skip: !firstAttendee?.person?.oid,
    });

  if (!activity?.id) {
    return null;
  }

  const consentList = parsePersonConsents(consentData?.person ?? undefined);

  const availableConsentList = !consentLoading ? consentList : [];

  const handleStartInteraction = () => {
    joinInteractionFromWaitingRoom();
  };

  const handleCancelInteraction = () => {
    leaveInteraction();
    // user starts from interact, so stay in interact
    if (!isFromCrm) {
      navigate(`/touchpoint/${activity?.id}`);

      return;
    }

    // user start from CRM, so redirect back to it
    return redirectBackToCrm();
  };

  if (interactionStep === 'summary') {
    return (
      <InteractionBackTopLayout>
        <InteractionSummary
          activity={activity}
          consentList={availableConsentList}
          canEditActivity={canEditActivity}
        />
      </InteractionBackTopLayout>
    );
  }

  // We make the decision about whether the activity is planned or closed here
  // because we need to allow closed activities in the summary page.
  if (activity?.status !== ActivityStatusDataEnum.PLANNED) {
    const redirectTarget = isFromCrm ? undefined : `/touchpoint/${activity.id}`;
    const redirectAction = isFromCrm ? redirectBackToCrm : undefined;

    return (
      <InvalidActivity
        reason={'activityNotPlanned'}
        redirectAction={redirectAction}
        redirectTarget={redirectTarget}
      />
    );
  }

  return (
    <InteractionLayout>
      {interactionStep === 'waiting-room' ? (
        <>
          {isInMobile ? (
            <RemoteWaitingRoomMobile
              activity={activity}
              isOrganizer={isOrganizer}
              consentList={availableConsentList}
              isActivityEditable={canEditActivity}
              onStartInteraction={handleStartInteraction}
              onCancelInteraction={handleCancelInteraction}
            />
          ) : (
            <RemoteWaitingRoom
              activity={activity}
              isOrganizer={isOrganizer}
              consentList={availableConsentList}
              isActivityEditable={canEditActivity}
              onStartInteraction={handleStartInteraction}
              onCancelInteraction={handleCancelInteraction}
            />
          )}
        </>
      ) : (
        <RemoteRoom
          canEditActivity
          isOrganizer={isOrganizer}
          activity={activity}
          roomId={activity.id}
          consentList={availableConsentList}
        />
      )}
    </InteractionLayout>
  );
};
