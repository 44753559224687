import { Box, styled } from '@mui/material';
import { useState } from 'react';

import { TelephoneInteractionMobileLayout } from '@/components/InteractionMobileLayout';
import { TELEPHONE_INTERACTION } from '@/config/layout';
import {
  InteractionInformationContent,
  WaitingRoomProps,
} from '@/views/Interaction/Common';
import { CallStatusContentBlock } from '@/views/Interaction/Telephone';

export const TelephoneWaitingRoomMobile = ({
  activity,
  isOrganizer,
  consentList,
  onStartInteraction,
  onCancelInteraction,
  isActivityEditable,
}: WaitingRoomProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const handleDrawerOpen = (change: boolean) => {
    setIsDrawerOpen?.(change);
  };

  return (
    <TelephoneInteractionMobileLayout
      drawerBleeding={
        TELEPHONE_INTERACTION.WAITING_ROOM.MOBILE.DRAWER_HEIGHT_PORTRAIT
      }
      actionComp={
        <CallStatusWrapper>
          <CallStatusContentBlock
            isMobile
            activity={activity}
            isDrawerOpen={isDrawerOpen}
            onStartInteraction={onStartInteraction}
            onCancelInteraction={onCancelInteraction}
          />
        </CallStatusWrapper>
      }
      contentComp={
        <InteractionInformationContent
          isOrganizer={isOrganizer}
          activity={activity}
          consentList={consentList}
          isMediaViewedStatusUpdatedOnOpen={false}
          isActivityEditable={isActivityEditable}
        />
      }
      onChangeDrawer={handleDrawerOpen}
    />
  );
};

const CallStatusWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
