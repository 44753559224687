// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUndefOrEmptyArray = (array: Array<any> | undefined): boolean => {
  if (!array) {
    return true;
  }

  return !Array.isArray(array) || array.length === 0;
};

export const record2Array = <T>(record: Record<string, T>): Array<T> => {
  return Object.entries(record).map(([, data]) => data);
};
