import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetAttributesQueryVariables = Types.Exact<{
  usageType: Types.AttributeUsageType;
}>;

export type GetAttributesQuery = {
  __typename?: 'Query';
  attributes: {
    __typename?: 'AttributeConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'AttributeEdge';
      node?: {
        __typename?: 'Attribute';
        id: string;
        oid: string;
        name: string;
      } | null;
    }> | null;
  };
};

/**
 * __useGetAttributesQuery__
 *
 * To run a query within a React component, call `useGetAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttributesQuery({
 *   variables: {
 *      usageType: // value for 'usageType'
 *   },
 * });
 */
export function useGetAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAttributesQuery,
    GetAttributesQueryVariables
  > &
    (
      | { variables: GetAttributesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAttributesQuery, GetAttributesQueryVariables>(
    Operations.getAttributes,
    options
  );
}
export function useGetAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAttributesQuery,
    GetAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAttributesQuery, GetAttributesQueryVariables>(
    Operations.getAttributes,
    options
  );
}
export function useGetAttributesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAttributesQuery,
    GetAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetAttributesQuery,
    GetAttributesQueryVariables
  >(Operations.getAttributes, options);
}
export type GetAttributesQueryHookResult = ReturnType<
  typeof useGetAttributesQuery
>;
export type GetAttributesLazyQueryHookResult = ReturnType<
  typeof useGetAttributesLazyQuery
>;
export type GetAttributesSuspenseQueryHookResult = ReturnType<
  typeof useGetAttributesSuspenseQuery
>;
export type GetAttributesQueryResult = Apollo.QueryResult<
  GetAttributesQuery,
  GetAttributesQueryVariables
>;
