import { UserData } from '@ysura/common';

import { getConsentDataPermissions } from '@/services/user/permissions/consent';
import { getMediaDataPermissions } from '@/services/user/permissions/media';

export type MediaDataPermissions = {
  canAddMediaTracking: boolean;
};

export type ConsentDataPermissions = {
  canResetConsent: boolean;
  canEditConsent: boolean;
};

export type DataPermissions = {
  media: MediaDataPermissions;
  consent: ConsentDataPermissions;
};
export const getDataPermission = (
  apiUserData?: UserData,
  isAdmin?: boolean
): DataPermissions => {
  return {
    media: getMediaDataPermissions(apiUserData, isAdmin),
    consent: getConsentDataPermissions(apiUserData, isAdmin),
  };
};
