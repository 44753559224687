import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  NoItemPlaceholder,
  OrganizationData,
  PersonData,
  TouchpointManagementModeData,
} from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationItem } from '@/components/Organization';

import { RequiredField, SectionLabel } from '../SharedStyledComponents';
import { BaseFormikProps } from '../types';

interface OrganizationSectionProps extends BaseFormikProps {
  selectedAttendees: Array<PersonData>;
  selectedOrganization: OrganizationData | null;
  mode: TouchpointManagementModeData;
  isSubmitting?: boolean;
}

export const OrganizationSection = ({
  selectedAttendees,
  setFieldValue,
  selectedOrganization,
  mode,
  isSubmitting = false,
}: OrganizationSectionProps) => {
  const { t } = useTranslation();

  const shouldShowAsterisk =
    selectedAttendees.length &&
    selectedAttendees[0].employments &&
    selectedAttendees[0].employments.length > 1;

  const handleChangeOrganization = (event: ChangeEvent<HTMLInputElement>) => {
    const checkedOrganization =
      selectedAttendees[0].employments?.find(
        (employment) => employment.organization?.oid === event.target.value
      )?.organization ?? null;

    setFieldValue?.('organization', checkedOrganization);
  };

  const mainContent = () => {
    if (
      selectedAttendees.length &&
      selectedOrganization &&
      selectedAttendees[0].employments?.length === 1
    ) {
      return <OrganizationItem organization={selectedOrganization} />;
    }

    if (
      selectedAttendees?.[0]?.employments &&
      selectedAttendees[0].employments?.length > 1
    ) {
      return (
        <FormControl>
          <StyledRadioGroup
            value={selectedOrganization?.oid}
            onChange={handleChangeOrganization}
          >
            {selectedAttendees[0].employments?.map((employment) => (
              <FormControlLabel
                key={employment.organization?.oid}
                disabled={isSubmitting}
                value={employment.organization?.oid}
                control={<Radio disabled={isSubmitting} />}
                label={
                  <OrganizationItem organization={employment.organization} />
                }
              />
            ))}
          </StyledRadioGroup>
        </FormControl>
      );
    }

    return (
      <NoItemPlaceholder>
        {t('components.touchpointManagement.organizationPlaceholder')}
      </NoItemPlaceholder>
    );
  };

  return (
    <>
      <SectionLabel variant="subtitle2">
        {t('components.touchpointManagement.organization')}

        {shouldShowAsterisk && mode === 'create' ? (
          <RequiredField>*</RequiredField>
        ) : null}
      </SectionLabel>

      {mainContent()}
    </>
  );
};

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  gap: theme.spacing(2),
}));
