import { DataPermissionTypeDataEnum, UserData } from '@ysura/common';

import { ConsentClientPermissions } from '@/services/user/permissions/ClientPermissions';
import { ConsentDataPermissions } from '@/services/user/permissions/DataPermissions';
import {
  hasClientPermission,
  hasDataPermission,
} from '@/services/user/permissions/helper';

export const getConsentPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): ConsentClientPermissions => {
  return {
    canCollectConsent: hasClientPermission(
      apiUserData,
      isAdmin,
      'consent-collection'
    ),
    canReadConsentTemplate: hasClientPermission(
      apiUserData,
      isAdmin,
      'consent-template-read'
    ),
    canResetConsent: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-consent-reset'
    ),
    canResendDoubleOptIn: hasClientPermission(
      apiUserData,
      isAdmin,
      'person-consent-doubleOptInResend'
    ),
  };
};

export const getConsentDataPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): ConsentDataPermissions => {
  return {
    canEditConsent: hasDataPermission(
      apiUserData,
      isAdmin,
      DataPermissionTypeDataEnum.EDIT_PERSON_CONSENT
    ),
    canResetConsent: hasDataPermission(
      apiUserData,
      isAdmin,
      DataPermissionTypeDataEnum.ADMINISTRATE_PERSON_CONSENT
    ),
  };
};
