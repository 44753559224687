import { Skeleton, Typography } from '@mui/material';
import { OrganizationCardSkeleton, OrganizationData } from '@ysura/common';

import {
  OrganizationDataStaticBlock,
  RootOrganizationDataStaticBlock,
} from '@/components/Organization';

interface RootOrganizationDataCardProps {
  isLoading?: boolean;
  organization?: OrganizationData;
}
export const RootOrganizationDataCard = ({
  isLoading,
  organization,
}: RootOrganizationDataCardProps) => {
  if (isLoading) {
    return (
      <OrganizationDataStaticBlock
        title={'pages.organizationView.rootOrganization'}
        subTitle={
          <Typography variant="subtitle2">
            <Skeleton width={120} />
          </Typography>
        }
      >
        <OrganizationCardSkeleton />
      </OrganizationDataStaticBlock>
    );
  }

  return (
    <>
      {organization?.rootOrganization && (
        <RootOrganizationDataStaticBlock
          title={'pages.organizationView.rootOrganization'}
          subTitle={'pages.organizationView.hierarchy.title'}
          isTopRootOrganization={
            organization.id == organization.rootOrganization.id
          }
          organization={organization}
        />
      )}
    </>
  );
};
