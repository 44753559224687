import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

type ContentWrapperProps = {
  flex: number;
  children: ReactNode;
};

export const ContentWrapper = ({ children, flex }: ContentWrapperProps) => {
  return (
    <Wrapper flex={flex} data-testid="content-wrapper">
      {children}
    </Wrapper>
  );
};

type WrapperStyleProps = {
  flex: number;
};

const Wrapper = styled(Box)<WrapperStyleProps>(({ theme, flex }) => ({
  /**
   * For waiting room: TBD
   * For remote room: Video "column" will have flex 1, Content "column" flex 2
   */
  flex,

  overflow: 'hidden',
  background: theme.palette.common.white,

  height: '100%',
  borderRadius: Number(theme.shape.borderRadius) * 2,
}));
