import { getPersonFullNameWithTitle, PersonData } from '@ysura/common';

import { getPersonEmploymentAddress } from '@/services/helper';

export const getAttendeeData = (attendee?: PersonData) => {
  const personName = getPersonFullNameWithTitle(attendee);
  const firstEmployment = attendee?.employments?.[0] ?? null;
  const organizationName = firstEmployment?.organization?.name ?? '';
  const addressName = firstEmployment
    ? getPersonEmploymentAddress(firstEmployment)
    : '';

  return {
    person: personName,
    organization: organizationName,
    address: addressName,
  };
};
