import { Box, BoxProps, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import LogoSvg from './logo.svg?react';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export const Logo = ({ disabledLink = false, sx }: Props) => {
  const theme = useTheme();

  const logo = (
    <Box data-testid="ysura-logo" sx={{ width: 40, height: 40, ...sx }}>
      <LogoSvg fill={theme.palette.primary.main} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
};
