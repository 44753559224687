import {
  AddressData,
  CommunicationDataData,
  EmploymentData,
  getPersonFullNameWithTitle,
} from '@ysura/common';

import { getPersonEmploymentAddress } from '@/services/helper';

export const getAddress = (
  employment?: EmploymentData
): AddressData | undefined => {
  // If the employment has an address, we use that one ...
  if (employment?.addresses?.length) {
    return employment.addresses[0];
  }

  // ... otherwise, we use the organizations address.
  else if (employment?.organization?.addresses?.length) {
    return employment?.organization?.addresses[0];
  }

  return undefined;
};

export const getContactInformation = (
  employment?: EmploymentData
): Array<CommunicationDataData> => {
  if (employment?.communicationData?.length) {
    return employment?.communicationData;
  } else {
    return employment?.organization?.communicationData ?? [];
  }
};

export const getStreetAddress = (address?: AddressData) => {
  return address?.addressLines?.filter(Boolean).join('\n') ?? '';
};

export const getPostalCodeCityAddress = (address?: AddressData): string => {
  if (!address) {
    return '';
  }

  const { postalCode, city } = address;

  if (!postalCode && !city) {
    return '';
  }

  if (postalCode && !city) {
    return postalCode;
  }
  if (city && !postalCode) {
    return city;
  }

  return `${postalCode}, ${city}`;
};

export const getEmploymentDetails = (employment: EmploymentData) => {
  const personName = getPersonFullNameWithTitle(employment.person);
  const organizationName = employment.organization?.name ?? '';
  const addressName = getPersonEmploymentAddress(employment);

  return {
    id: employment.person?.id ?? '',
    person: personName,
    organization: organizationName,
    address: addressName,
  };
};
