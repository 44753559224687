import { useLocalStorage } from '@ysura/common';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  NotificationBannerList,
  NotificationPopup,
} from '@/components/Notification';
import { useCurrentUser } from '@/hooks';
import { InteractionContextProvider } from '@/hooks/useInteraction';
import { InvalidActivity, NotFound404 } from '@/pages/Error';
import { validateForInteraction } from '@/services/activities';
import { redirectBackToCrm } from '@/services/api';
import { useGetActivityByIdQuery } from '@/services/graphql/hooks';
import { parseActivityData } from '@/services/graphql/parsers';
import { clearPersistentLocalStorage } from '@/views/Interaction/helpers';

import { InteractionMode } from './InteractionMode';
import { InteractionPageSkeleton } from './InteractionPageSkeleton';

export const InteractionPage = () => {
  const {
    activityId = '',
    interactionType = 'f2f',
    fromCrm = undefined,
  } = useParams();

  const { currentUser } = useCurrentUser();

  const [, setFromCrm] = useLocalStorage<boolean>('fromCrm', false);

  const { data, loading } = useGetActivityByIdQuery({
    variables: {
      id: activityId,
    },
    skip: activityId === 'adhoc',
  });

  useEffect(() => {
    if (fromCrm) {
      setFromCrm(true);

      if (activityId) {
        // Clear the persistant local storage if the activityId is different
        // to prevent stale data in local storage.
        clearPersistentLocalStorage(activityId);
      }
    }
  }, [activityId, fromCrm, setFromCrm]);

  if (loading) {
    return <InteractionPageSkeleton interactionType={interactionType} />;
  }

  const activity = data?.activity ? parseActivityData(data.activity) : null;

  const { isValidForInteraction, reason } = validateForInteraction({
    activity,
    currentUser,
  });

  // Only render planned, active & DirectContact | Remote baseType interactions | Telephone baseType interactions
  if (!isValidForInteraction) {
    if (!reason || reason === 'noActivity') {
      return <NotFound404 />;
    } else {
      const redirectTarget = fromCrm ? undefined : `/touchpoint/${activityId}`;
      const redirectAction = fromCrm ? redirectBackToCrm : undefined;

      return (
        <InvalidActivity
          reason={reason}
          redirectAction={redirectAction}
          redirectTarget={redirectTarget}
        />
      );
    }
  }

  return (
    <InteractionContextProvider>
      <NotificationBannerList displayLocation="main" />
      <InteractionMode activity={activity} activityId={activityId} />
      <NotificationPopup />
    </InteractionContextProvider>
  );
};
