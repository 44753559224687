import { gql } from '@apollo/client';

export const getMediaById = gql`
  query getMediaById($mediaId: ID!) {
    media(id: $mediaId) {
      id
      oid
      name
      description
      mediaType
      thumbnailDocument {
        id
        oid
        name
        contentLink
        mediaType
      }
      latestVersion {
        oid
        version
        document {
          id
          oid
          name
          contentLink
          mediaType
        }
      }
      topics {
        edges {
          node {
            id
            oid
            name
            state
          }
        }
      }
      keyMessages {
        edges {
          node {
            id
            oid
            name
            state
            topics {
              edges {
                node {
                  id
                  oid
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
