import { Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  NoItemPlaceholder,
  PersonSimpleInfo,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIconButton } from '@/components/Button';
import {
  CoOrganizerEditDialog,
  getCoOrganizerFullName,
} from '@/components/CoOrganizer';
import { OverviewStaticBlock } from '@/components/StaticBlock';

type CoOrganizerCardProps = {
  title: string;
  canEditCoOrganizer?: boolean;
  activity: ActivityData;
};

export const CoOrganizerCardList = ({
  title,
  canEditCoOrganizer = false,
  activity,
}: CoOrganizerCardProps) => {
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleEditClicked = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditClosed = () => {
    setIsEditDialogOpen(false);
  };

  return (
    <OverviewStaticBlock
      title={title}
      testId="coOrganizers-list"
      subtitle={
        canEditCoOrganizer && <EditIconButton onClick={handleEditClicked} />
      }
    >
      <Wrapper>
        {!activity.coOrganizers?.length && (
          <NoItemPlaceholder>
            {t('pages.activity.coOrganizer.noCoOrganizer')}
          </NoItemPlaceholder>
        )}
        {activity.coOrganizers?.map(({ oid, person }, index) => (
          <Wrapper key={oid}>
            <PersonSimpleInfo
              person={{
                name: getCoOrganizerFullName(person),
                showIcon: true,
                showBalloonIcon: true,
                greyBackground: true,
              }}
              testId="coOrganizer-person-info"
            />
            {index + 1 !== activity.coOrganizers?.length && <Divider />}
          </Wrapper>
        ))}
      </Wrapper>
      {isEditDialogOpen && (
        <CoOrganizerEditDialog
          isOpen={isEditDialogOpen}
          handleClose={handleEditClosed}
          activity={activity}
        />
      )}
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(1),
}));
