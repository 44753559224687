import {
  ActivityCoOrganizerData,
  ActivityData,
  ActivityStatusDataEnum,
  CreateTouchpointData,
  OrganizationData,
  PersonData,
  TopicData,
  TouchpointStatusTypeDataEnum,
  UpdateTouchpointInformationData,
} from '@ysura/common';
import { v4 as uuidv4 } from 'uuid';

import { convertedActivityStatusDataEnum } from '@/services/graphql/parsers/utils';
import {
  ActivityAttendeeInput,
  ActivityCoOrganizerInput,
  ActivityStatus,
  UpdateActivityAction,
} from '@/services/graphql/types';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const encodeOid = (oid: string): string => {
  const activityIdObject = {
    id: oid,
    idType: 'ActivityId',
  };

  return window.btoa(JSON.stringify(activityIdObject));
};

export const getActivityStatus = (
  activityStatus: string
): ActivityStatusDataEnum => {
  if (activityStatus === TouchpointStatusTypeDataEnum.DoneTouchpoint) {
    return ActivityStatusDataEnum.CLOSED;
  }

  return ActivityStatusDataEnum.PLANNED;
};

export const getDefaultActivityStatusValue = (
  activity: ActivityData
): TouchpointStatusTypeDataEnum => {
  if (activity.status == ActivityStatusDataEnum.CLOSED) {
    return TouchpointStatusTypeDataEnum.DoneTouchpoint;
  }

  if (activity.status == ActivityStatusDataEnum.PLANNED && activity.confirmed) {
    return TouchpointStatusTypeDataEnum.ConfirmedPlannedTouchpoint;
  }

  return TouchpointStatusTypeDataEnum.PlannedTouchpoint;
};

export const prepareDataForAddActivityMutation = (
  values: CreateTouchpointData
) => {
  const {
    touchpointStatus,
    startDate: formikStartDate,
    startDateTime: formikStartDateTime,
    endDate: formikEndDate,
    endDateTime: formikEndDateTime,
    attendees,
    organization: formikOrganization,
    topics,
    touchpointType,
  } = values;

  // organization and activityType will always have a value, so it's safe to cast types
  const organization: OrganizationData = formikOrganization as OrganizationData;
  const activityType: ActivityData = touchpointType as ActivityData;

  return {
    organizationId: organization.oid ?? '',
    attendees: buildAttendees(attendees),
    discussedTopics: topics.map((topic: TopicData) => topic.oid ?? ''),
    startDate: formikStartDateTime
      ? formikStartDateTime ?? ''
      : formikStartDate ?? '',
    endDate: formikEndDateTime ? formikEndDateTime ?? '' : formikEndDate ?? '',
    activityTypeId: activityType.oid ?? '',
    confirmed:
      touchpointStatus ===
      TouchpointStatusTypeDataEnum.ConfirmedPlannedTouchpoint,
    status:
      touchpointStatus === TouchpointStatusTypeDataEnum.DoneTouchpoint
        ? ActivityStatus.CLOSED
        : ActivityStatus.PLANNED,
  };
};

export const prepareDataForUpdateActivityMutation = (
  values: UpdateTouchpointInformationData,
  activity: ActivityData
): UpdateActivityAction => {
  const {
    startDate: formikStartDate,
    startDateTime: formikStartDateTime,
    endDate: formikEndDate,
    endDateTime: formikEndDateTime,
    organization,
    touchpointStatus,
    coOrganizers,
  } = values;

  const status = touchpointStatus
    ? getActivityStatus(touchpointStatus)
    : undefined;

  let actions: UpdateActivityAction = {};

  // StartDate field
  const updatedStartDateField = formikStartDateTime
    ? formikStartDateTime
    : formikStartDate ?? null;
  if (
    updatedStartDateField &&
    updatedStartDateField !== activity.startDateTime
  ) {
    actions = {
      ...actions,
      startDate: {
        value: updatedStartDateField,
      },
    };
  }

  // EndDate field
  const updatedEndDateField = formikEndDateTime
    ? formikEndDateTime
    : formikEndDate ?? null;
  if (updatedEndDateField && updatedEndDateField !== activity.endDateTime) {
    actions = {
      ...actions,
      endDate: {
        value: updatedEndDateField,
      },
    };
  }

  // Status field
  if (
    status &&
    status !== (activity?.status as unknown as ActivityStatusDataEnum)
  ) {
    actions = {
      ...actions,
      status: {
        value:
          convertedActivityStatusDataEnum(status) ?? ActivityStatus.PLANNED,
      },
    };
  }

  // Organization field
  if (organization && organization?.oid !== activity?.organization?.oid) {
    actions = {
      ...actions,
      organizationId: {
        value: organization.oid ?? '',
      },
    };
  }

  // Confirmed field
  const updatedConfirmedField =
    touchpointStatus ===
    TouchpointStatusTypeDataEnum.ConfirmedPlannedTouchpoint;
  if (updatedConfirmedField !== activity.confirmed) {
    actions = {
      ...actions,
      confirmed: {
        value: updatedConfirmedField,
      },
    };
  }

  // CoOrganizers field
  if (coOrganizers) {
    actions = {
      ...actions,
      coOrganizers: {
        value: buildCoOrganizers(coOrganizers),
      },
    };
  }

  return actions;
};

export const buildAttendees = (
  attendees: Array<PersonData> | undefined
): Array<ActivityAttendeeInput> => {
  const mappedAttendees = attendees?.map((attendee) => {
    if (!attendee?.oid) {
      return null;
    }

    return {
      oid: uuidv4(),
      person: attendee.oid,
      isGuest: false,
    };
  });

  return filterNonNull(mappedAttendees);
};

const buildCoOrganizers = (
  coOrganizers: ActivityCoOrganizerData[]
): Array<ActivityCoOrganizerInput> => {
  return coOrganizers.map((coOrganizer: ActivityCoOrganizerData) => {
    return {
      oid: coOrganizer.oid ?? uuidv4(),
      person: coOrganizer.person?.oid,
      organizationalUnit: coOrganizer.organizationalUnit?.oid,
    };
  });
};
