import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPersonAssignmentListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PersonAssignmentFilterInput>;
}>;

export type GetPersonAssignmentListQuery = {
  __typename?: 'Query';
  personAssignments: {
    __typename?: 'PersonAssignmentConnection';
    edges?: Array<{
      __typename?: 'PersonAssignmentEdge';
      node?: {
        __typename?: 'PersonAssignment';
        primary?: boolean | null;
        person?: {
          __typename?: 'Person';
          id: string;
          oid: string;
          firstName?: string | null;
          middleName?: string | null;
          lastName?: string | null;
          customerReference?: string | null;
          gender?: Types.Gender | null;
        } | null;
        organizationalUnit?: {
          __typename?: 'OrganizationalUnit';
          id: string;
          oid: string;
          name?: string | null;
          state: Types.State;
        } | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetPersonAssignmentListQuery__
 *
 * To run a query within a React component, call `useGetPersonAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonAssignmentListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPersonAssignmentListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPersonAssignmentListQuery,
    GetPersonAssignmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPersonAssignmentListQuery,
    GetPersonAssignmentListQueryVariables
  >(Operations.getPersonAssignmentList, options);
}
export function useGetPersonAssignmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonAssignmentListQuery,
    GetPersonAssignmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPersonAssignmentListQuery,
    GetPersonAssignmentListQueryVariables
  >(Operations.getPersonAssignmentList, options);
}
export function useGetPersonAssignmentListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPersonAssignmentListQuery,
    GetPersonAssignmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPersonAssignmentListQuery,
    GetPersonAssignmentListQueryVariables
  >(Operations.getPersonAssignmentList, options);
}
export type GetPersonAssignmentListQueryHookResult = ReturnType<
  typeof useGetPersonAssignmentListQuery
>;
export type GetPersonAssignmentListLazyQueryHookResult = ReturnType<
  typeof useGetPersonAssignmentListLazyQuery
>;
export type GetPersonAssignmentListSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonAssignmentListSuspenseQuery
>;
export type GetPersonAssignmentListQueryResult = Apollo.QueryResult<
  GetPersonAssignmentListQuery,
  GetPersonAssignmentListQueryVariables
>;
