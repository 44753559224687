import { Box, Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationCardSkeleton, PersonData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { OrganizationCard } from '@/components/Organization';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useGetOrganizationByPersonIdLazyQuery } from '@/services/graphql/hooks';
import { parseEmploymentData } from '@/services/graphql/parsers';

type RelatedOrganizationsStaticBlockProps = {
  person: PersonData;
};

export const RelatedOrganizationsStaticBlock = ({
  person,
}: RelatedOrganizationsStaticBlockProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [getMoreOrganizations, { data, loading }] =
    useGetOrganizationByPersonIdLazyQuery({
      variables: {
        personId: id ?? '',
        employeesLimit: 999,
      },
    });

  const [shouldShowAllOrganizations, setShouldShowAllOrganizations] =
    useState(false);

  const handleClickButton = () => {
    if (!shouldShowAllOrganizations) {
      getMoreOrganizations();
    }
    setShouldShowAllOrganizations(!shouldShowAllOrganizations);
  };

  const totalEmployments = person?.totalEmployments ?? 0;

  const firstEmployment = person.employments?.[0];

  if (loading) {
    return (
      <OverviewStaticBlock
        title={t('pages.personView.relatedOrganizations')}
        testId="related-organization-static-block"
      >
        <OrganizationCardSkeleton />
      </OverviewStaticBlock>
    );
  }

  return (
    <OverviewStaticBlock
      title={t('pages.personView.relatedOrganizations')}
      testId="related-organization-static-block"
    >
      <ContentWrapper>
        {totalEmployments === 1 && firstEmployment && (
          <OrganizationCard employment={firstEmployment} />
        )}

        {totalEmployments > 1 && firstEmployment && (
          <>
            {shouldShowAllOrganizations &&
              data?.person?.employments?.edges?.map((employment, idx) => (
                <OrganizationCard
                  key={`employment-${idx}`}
                  employment={parseEmploymentData(employment?.node)}
                />
              ))}

            {!shouldShowAllOrganizations && (
              <OrganizationCard employment={firstEmployment} />
            )}

            <Button variant="outlined" onClick={handleClickButton}>
              {shouldShowAllOrganizations
                ? t('pages.personView.seeLess')
                : t('pages.personView.seeMore')}
            </Button>
          </>
        )}
      </ContentWrapper>
    </OverviewStaticBlock>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const Button = styled(MuiButton)`
  text-transform: uppercase;
`;
