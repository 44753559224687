import {
  ConsentItemData,
  ConsentTypeData,
  ConsentTypeManualProcessReasonData,
} from '@ysura/common';

import {
  parseDataPermissions,
  parseTemplateData,
} from '@/services/graphql/parsers';
import {
  convertedCommunicationDataBaseType,
  convertedConsentItemStateChange,
  convertedConsentTypeValidityMode,
  convertedLifecycleStatus,
} from '@/services/graphql/parsers/utils';
import {
  ConsentItem,
  ConsentItemConnection,
  ConsentItemEdge,
  ConsentType,
  ConsentTypeConnection,
  ConsentTypeManualProcessReason,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseConsentTypeData = (
  consentType?: DeepPartial<ConsentType> | null
): ConsentTypeData => {
  return {
    id: consentType?.id,
    oid: consentType?.oid,
    name: consentType?.name,
    description: consentType?.description ?? undefined,
    startDate: consentType?.startDate
      ? new Date(consentType?.startDate)
      : undefined,
    endDate: consentType?.endDate ? new Date(consentType?.endDate) : undefined,
    lifecycleStatus: convertedLifecycleStatus(consentType?.lifecycleStatus),
    validityEndDate: consentType?.validityEndDate
      ? new Date(consentType?.validityEndDate)
      : undefined,
    validityMode: convertedConsentTypeValidityMode(consentType?.validityMode),
    validityPeriodInMonths: consentType?.validityPeriodInMonths ?? undefined,
    allowRecollection: consentType?.allowRecollection ?? undefined,
    digitalSignatureProcessEnabled:
      consentType?.digitalSignatureProcessEnabled ?? undefined,
    doubleOptInPaperEnabled: consentType?.doubleOptInPaperEnabled ?? undefined,
    doubleOptInDigitalEnabled:
      consentType?.doubleOptInDigitalEnabled ?? undefined,
    doubleOptInManualEnabled:
      consentType?.doubleOptInManualEnabled ?? undefined,
    digitalSignatureTemplate: consentType?.digitalSignatureTemplate
      ? parseTemplateData(consentType.digitalSignatureTemplate)
      : undefined,
    manualProcessEnabled: consentType?.manualProcessEnabled ?? undefined,
    manualProcessReasons: parseManualProcessReasons(
      consentType?.manualProcessReasons
    ),
    paperProcessEnabled: consentType?.paperProcessEnabled ?? undefined,
    consentItems: parseConsentItemConnectionData(consentType?.consentItems),
    permissions: parseDataPermissions(consentType?.dataPermissions ?? []),
  };
};

export const parseConsentTypes = (
  consentTypes: DeepPartial<ConsentTypeConnection>
) => {
  const rawConsentTypes = consentTypes?.edges ?? [];
  const mappedConsentTypes = rawConsentTypes.map((edge) => edge?.node ?? null);

  return mappedConsentTypes.map((item) => parseConsentTypeData(item));
};

export const parseManualProcessReasons = (
  manualProcessReasons?:
    | (DeepPartial<ConsentTypeManualProcessReason> | undefined)[]
    | undefined
): Array<ConsentTypeManualProcessReasonData> => {
  if (!manualProcessReasons) {
    return [];
  }

  const parsedManualProcessReasons = manualProcessReasons.map(
    (
      manualProcessReason:
        | DeepPartial<ConsentTypeManualProcessReason>
        | undefined
    ) => {
      return manualProcessReason
        ? {
            doiEnable: manualProcessReason.doiEnabled ?? undefined,
            oid: manualProcessReason.oid ?? undefined,
            reason: manualProcessReason.reason ?? undefined,
          }
        : undefined;
    }
  );

  return filterNonNull(parsedManualProcessReasons);
};

export const parseConsentItemConnectionData = (
  consentItems?: DeepPartial<ConsentItemConnection>
): Array<ConsentItemData> => {
  if (!consentItems?.edges || !consentItems?.edges?.length) {
    return [];
  }

  const consentItemList = consentItems?.edges?.map(
    (consentItem: DeepPartial<ConsentItemEdge> | undefined) => {
      return consentItem?.node ? parseConsentItemData(consentItem?.node) : null;
    }
  );

  return filterNonNull(consentItemList);
};

export const parseConsentItemData = (
  consentItem: DeepPartial<ConsentItem>
): ConsentItemData => {
  return {
    oid: consentItem?.oid,
    name: consentItem?.name,
    description: consentItem?.description ?? undefined,
    inputId: consentItem?.inputId ?? undefined,
    defaultItemState: convertedConsentItemStateChange(
      consentItem?.defaultItemState
    ),
    pendingPaperItemState: convertedConsentItemStateChange(
      consentItem?.pendingPaperItemState
    ),
    pendingPaperSwitchable: consentItem?.pendingPaperSwitchable,
    pendingDoubleOptInItemState: convertedConsentItemStateChange(
      consentItem?.pendingDoubleOptInItemState
    ),
    pendingDoubleOptInSwitchable: consentItem?.pendingDoubleOptInSwitchable,
    acceptedItemState: convertedConsentItemStateChange(
      consentItem?.acceptedItemState
    ),
    acceptedSwitchable: consentItem?.acceptedSwitchable,
    consentInputFields: consentItem?.consentCommunicationDataTypes?.map(
      (communicationDataType) => ({
        consentCommunicationDataTypeId: communicationDataType?.oid ?? undefined,
        isMandatory: communicationDataType?.mandatory ?? undefined,
        inputId: communicationDataType?.inputId ?? undefined,
        label: communicationDataType?.communicationDataType?.name ?? undefined,
        baseType: convertedCommunicationDataBaseType(
          communicationDataType?.communicationDataType?.baseType
        ),
        description: communicationDataType?.description ?? undefined,
      })
    ),
  };
};
