import {
  AssignmentTurnedInOutlined,
  EventAvailable,
} from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Label, PersonConsentData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentItem } from '@/components/Consent/ConsentDialog/ConsentItem';
import { DocumentPreviewDialog } from '@/components/DocumentPreview';
import { useFormatDate } from '@/hooks';
import { getLifecycleLabelColor } from '@/utils/getLifecycleLabelColor';
import {
  getCollectionPeriod,
  getConsentValidity,
  getSource,
} from '@/utils/getPersonConsentDetails';

type ConsentInfoProps = {
  consent: PersonConsentData;
};

export const ConsentInfo = ({ consent }: ConsentInfoProps) => {
  const { t } = useTranslation();
  const { formatDate } = useFormatDate();

  const [isPreviewDocumentDialogOpen, setIsPreviewDocumentDialogOpen] =
    useState(false);

  const onClosePreviewDocumentDialog = () => {
    setIsPreviewDocumentDialogOpen(false);
  };

  const handleViewDocument = () => {
    setIsPreviewDocumentDialogOpen(true);
  };

  return (
    <ParentStack>
      <ChildStack>
        <SubTitleTypography variant="subtitle1">
          {t('components.consentDialog.description')}
        </SubTitleTypography>
        <StyleTypography variant="body2">
          {consent?.consentType?.description ??
            t('pages.interaction.common.noDataAvailable')}
        </StyleTypography>
      </ChildStack>
      <ChildStack>
        <SubTitleTypography variant="subtitle1">
          {t('components.consentDialog.status')}
        </SubTitleTypography>
        <Box>
          {consent?.lifecycleState && (
            <Label
              variant="ghost"
              color={getLifecycleLabelColor(consent.lifecycleState)}
            >
              {t(
                `pages.interaction.f2f.personLifecycleState.${consent.lifecycleState.toLowerCase()}`
              )}
            </Label>
          )}
        </Box>
      </ChildStack>
      <ChildStack>
        <CaptionTypography variant="caption">
          {t('components.consentDialog.collectionPeriod')}
        </CaptionTypography>
        <RowBox>
          <StyledCollectionPeriodIcon />
          <Typography variant="caption">
            {getCollectionPeriod(consent, formatDate)}
          </Typography>
        </RowBox>
      </ChildStack>
      {consent?.consentType && (
        <ChildStack>
          <CaptionTypography variant="caption">
            {t('components.consentDialog.consentValidity')}
          </CaptionTypography>
          <RowBox>
            <StyleConsentValidityIcon />
            <Typography variant="caption">
              {getConsentValidity(
                consent?.consentType,
                consent?.recCreatedAt,
                t,
                formatDate
              )}
            </Typography>
          </RowBox>
        </ChildStack>
      )}
      {consent?.consentItemValues?.map((item, idx) => (
        <ConsentItem key={`consent-item-${idx}`} consentItem={item} />
      ))}
      {consent?.consentHistory?.length > 0 && (
        <ChildStack data-testid="consent-source">
          <SubTitleTypography variant="subtitle1">
            {t('components.consentDialog.source')}
          </SubTitleTypography>
          <Box>
            <Label variant="ghost" color="info">
              {getSource(t, consent?.consentHistory[0])}
            </Label>
          </Box>
        </ChildStack>
      )}
      {consent?.document?.oid && consent?.document?.name && (
        <ChildStack>
          <SubTitleTypography variant="subtitle1">
            {t('components.consentDialog.document')}
          </SubTitleTypography>
          <RowBox>
            <StyledButton
              disabled={isPreviewDocumentDialogOpen}
              onClick={handleViewDocument}
            >
              {t('pages.interaction.common.previewDocument')}
            </StyledButton>
          </RowBox>
        </ChildStack>
      )}
      {consent?.document && isPreviewDocumentDialogOpen && (
        <DocumentPreviewDialog
          document={consent.document}
          title={consent.consentType?.name ?? ''}
          isOpen={isPreviewDocumentDialogOpen}
          onClose={onClosePreviewDocumentDialog}
        />
      )}
    </ParentStack>
  );
};

const SubTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CaptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const StyleTypography = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));

const ParentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const ChildStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const RowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: theme.palette.text.primary,
  gap: theme.spacing(1),
}));

const StyledCollectionPeriodIcon = styled(EventAvailable)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: 16,
  height: 16,
}));

const StyleConsentValidityIcon = styled(AssignmentTurnedInOutlined)(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
    width: 16,
    height: 16,
  })
);

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;
