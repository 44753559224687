import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CUSTOM_BREAKPOINTS,
  Loader,
  Media,
  OrganizerPresentationStateChangePayload,
  PointerContainer,
  useElementSize,
} from '@ysura/common';
import { useEffect, useRef, useState } from 'react';
import { Document, Page as PdfPage } from 'react-pdf';

import { FitScreenButton } from '@/components/Button';
import { useInteraction } from '@/hooks';

type CoOrganizerPDFMediaProps = {
  media: Media;
  initialPage?: number;
};

export enum ASPECT {
  contain = 'contain',
  cover = 'cover',
}

export const CoOrganizerPDFMedia = ({
  media,
  initialPage = 1,
}: CoOrganizerPDFMediaProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [pageNumber, setPageNumber] = useState(initialPage);
  const [aspect, setAspect] = useState(ASPECT.contain);
  const [isPdfDocumentLoaded, setIsPdfDocumentLoaded] = useState(false);
  const [isPdfJsLoaded, setIsPdfJsLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { onMediaStateChanged, onPointerShown, broadcastPointerShow } =
    useInteraction();

  const { height, width } = useElementSize(containerRef);

  useEffect(() => {
    const loadPdfJs = async () => {
      const { pdfjs } = await import('react-pdf');
      pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.js',
        import.meta.url
      ).toString();
      setIsPdfJsLoaded(true);
      setAspect(isSmallScreen ? ASPECT.cover : ASPECT.contain);
    };

    loadPdfJs();
  }, [setAspect, isSmallScreen]);

  const handleLoadDocument = () => {
    setIsPdfDocumentLoaded(true);

    setTimeout(() => {
      // TODO: find a better way to properly fit PDF content in its container
      // calls the resize event so the PDF takes proper width/height when the PDF loads
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  const handleChangeAspect = () => {
    setAspect((prev) => {
      return prev === ASPECT.contain ? ASPECT.cover : ASPECT.contain;
    });

    setTimeout(() => {
      // calls the resize event so the PDF takes proper width/height when aspect changes
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  useEffect(() => {
    const handlePresentationStateChanged = (
      data: OrganizerPresentationStateChangePayload
    ) => {
      if (data.type === 'pdf' && data.stateChange.namespace === 'pdfViewer') {
        if (data.stateChange.state?.page) {
          setPageNumber(data.stateChange.state.page);
        }
      }
    };

    const unsubscribeOnMediaStateChanged = onMediaStateChanged?.(
      handlePresentationStateChanged
    );

    return () => {
      if (unsubscribeOnMediaStateChanged) {
        unsubscribeOnMediaStateChanged();
      }
    };
  }, [onMediaStateChanged]);

  const getPDFSize = () => {
    // contain aspect fits the content based on its height
    if (aspect === ASPECT.contain) {
      return { height };
    }

    // cover aspect fits the content based on its width
    return { width };
  };

  const renderContainer = () => {
    return (
      <PDFContainer ref={containerRef} data-testid="PDF_container">
        <ScrollBox>
          {isPdfJsLoaded && (
            <PointerContainer
              broadcastPointerShow={broadcastPointerShow}
              onPointerShown={onPointerShown}
            >
              <PDFDocument
                isPdfDocumentLoaded={isPdfDocumentLoaded}
                file={media.contentDocument.downloadUrl}
                onLoadSuccess={handleLoadDocument}
              >
                <PdfPage
                  {...getPDFSize()}
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </PDFDocument>
            </PointerContainer>
          )}

          {!isPdfDocumentLoaded && <Loader />}
        </ScrollBox>
      </PDFContainer>
    );
  };

  const renderActionButtons = () => {
    return (
      <ActionButtonWrapper>
        <FitScreenButton onChangeAspect={handleChangeAspect} />{' '}
      </ActionButtonWrapper>
    );
  };

  return (
    <>
      <Wrapper>
        {renderContainer()}

        <MobileLandscapeActions>{renderActionButtons()}</MobileLandscapeActions>
      </Wrapper>

      <LargeScreenActions>{renderActionButtons()}</LargeScreenActions>
      <MobilePortraitActions>{renderActionButtons()}</MobilePortraitActions>
    </>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  /**
   * 61px is the height of the action button container and the gap
   * between the media view and that container
   */
  height: 'calc(100% - 61px)',
  overflow: 'hidden',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      height: '100%',
      gap: theme.spacing(1),
    },
}));

const PDFContainer = styled(Box)(({ theme }) => ({
  display: 'grid',

  height: '100%',
  width: '100%',

  background: theme.palette.grey[800],
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  overflow: 'hidden',
}));

const ScrollBox = styled(Box)({
  display: 'grid',
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

const PDFDocument = styled(Document)<{ isPdfDocumentLoaded: boolean }>(
  ({ isPdfDocumentLoaded }) => ({
    justifySelf: 'center',
    alignSelf: 'center',
    display: isPdfDocumentLoaded ? 'block' : 'none',
  })
);

const ActionButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const LargeScreenActions = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
}));

const MobilePortraitActions = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.only('xs')]: {
    display: 'block',
  },
}));

const MobileLandscapeActions = styled(Box)({
  display: 'none',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'flex',
      alignItems: 'flex-end',
    },
});
