import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FacetFilterData } from '@ysura/common';
import { ChangeEvent } from 'react';

import { FilterChangeEvent } from '@/components/FilterCategory/FilterCategory';
import { FilterTitleCategory } from '@/components/FilterCategory/FilterTitleCategory';

type BasicCategoryProps = {
  title: string;
  values: Array<FacetFilterData>;
  onChangeFilters?: FilterChangeEvent;
};

export const GenericDisplayCategory = ({
  title,
  values,
  onChangeFilters,
}: BasicCategoryProps) => {
  const numberOfSelectedFilters = values.filter(
    (item) => item.isSelected
  ).length;

  return (
    <StyledFormControl variant="outlined">
      <FilterTitleCategory
        title={title}
        numberOfSelectedFilters={numberOfSelectedFilters}
      />
      <FormGroup>
        {values.map((value, index) => (
          <StyledFormControlLabel
            key={value.id ?? index}
            control={
              <Checkbox
                data-testid="filter-checkbox"
                checked={!!value.isSelected}
                name={value.oid ?? value.name}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChangeFilters?.(event, value.id ?? '')
                }
              />
            }
            label={value.name}
          />
        ))}
      </FormGroup>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  gap: theme.spacing(1),
  paddingRight: '5px',
  paddingBottom: '15px',
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  overflowWrap: 'anywhere',
}));
