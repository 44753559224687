import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

type PromotionalMaterialAccordionProps = {
  defaultExpanded?: boolean;
  title: string;
  children: ReactNode;
};

// TODO: Talk to the designers to see if we can use the same accordion here as OverviewAccordionBlock
export const PromotionalMaterialAccordion = ({
  defaultExpanded = false,
  title,
  children,
}: PromotionalMaterialAccordionProps) => {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={title}>
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: theme.customShadows.card,

  '&.Mui-expanded': {
    boxShadow: theme.customShadows.card,
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
