import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PromotionalMaterialGroupData } from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

type InputQuantityProps = {
  materialOid: string;
  quantity: number;
  promats: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const InputQuantity = ({
  materialOid,
  quantity,
  promats,
  onChangePromats,
}: InputQuantityProps) => {
  const { t } = useTranslation();
  const [isInputValid, setIsInputValid] = useState(true);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIsInputValid(/^\d+$/.test(value));

    const promotionalMaterial = promats.find(
      (material) => material.promotionalMaterial?.oid === materialOid
    );
    if (promotionalMaterial) {
      promotionalMaterial.requestedQuantity = Number(event.target.value);
    }

    onChangePromats([...promats]);
  };

  return (
    <QuantityInput
      label={t('pages.interaction.expand.quantity')}
      variant="outlined"
      defaultValue={quantity}
      error={!isInputValid}
      helperText={t('pages.interaction.expand.invalidQuantity')}
      type="number"
      size="small"
      onChange={handleChange}
    />
  );
};

const QuantityInput = styled(TextField)({
  width: 88,
  '.MuiFormHelperText-root': {
    visibility: 'hidden',
    '&.Mui-error': {
      visibility: 'visible',
    },
  },
});
