import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DataPermissionTypeDataEnum,
  MediaData,
  NoItemPlaceholder,
} from '@ysura/common';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SortMenu } from '@/components/SortMenu';
import { useGetTouchpointMediaListLazyQuery } from '@/services/graphql/hooks';
import {
  parseMediaConnection,
  parseTopicConnection,
} from '@/services/graphql/parsers';
import { OrderBy } from '@/services/graphql/types';

import { ColumnTitle } from './ColumnTitle';
import { DialogColumn } from './DialogColumn';
import { MediaEditAccordion } from './MediaEditAccordion';
import { MediaSearchCard } from './MediaSearchCard';
import { MediaSearchSkeleton } from './MediaSearchSkeleton';

type AllMediaProps = {
  selectedMedia: Array<MediaData>;
  onAddMediaToSelected: (val: MediaData) => void;
};

export const AllMedia = ({
  selectedMedia,
  onAddMediaToSelected,
}: AllMediaProps) => {
  const { activityId } = useParams();

  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [mediaQueryString, setMediaQueryString] = useState('');
  const [mediaOrder, setMediaOrder] = useState<OrderBy>(OrderBy.ASC);

  // States
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  // Graphql query
  const [searchMedias, { data: searchData, loading }] =
    useGetTouchpointMediaListLazyQuery();

  // Effect
  useEffect(() => {
    const mediaQuerySplitArray = debouncedSearchQuery
      .trim()
      .split(' ')
      .filter((query) => query);
    const mediaQueryFilter = `%${mediaQuerySplitArray.join('%')}%`;

    searchMedias({
      variables: {
        id: activityId ?? '',
        nameFilter: mediaQueryString ? { _like: mediaQueryFilter } : null,
        orderByName: mediaOrder,
      },
    });
  }, [
    searchMedias,
    debouncedSearchQuery,
    mediaOrder,
    activityId,
    mediaQueryString,
  ]);

  // Handlers
  const handleDebounce = (value: string) => {
    setDebouncedSearchQuery(value);
  };

  const isMediaSelected = (media: MediaData) => {
    return Boolean(selectedMedia.find((item) => item?.id === media?.id));
  };

  const handleChangeMediaOrder = (order: OrderBy) => {
    setMediaOrder(order);
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setMediaQueryString(event.target.value);
    debouncedFunction(event.target?.value);
  };

  const handleClearSearchQuery = () => {
    setMediaQueryString('');
    setDebouncedSearchQuery('');
  };

  // Memo
  const debouncedFunction = useMemo(() => debounce(handleDebounce, 500), []);

  // Variables
  const parsedMedia = searchData?.activity?.activityType?.medias
    ? parseMediaConnection(searchData?.activity?.activityType?.medias)
    : null;
  const parsedTopics = searchData?.activity?.activityType?.topics
    ? parseTopicConnection(searchData?.activity?.activityType?.topics)
    : [];

  const medias =
    parsedMedia?.medias.filter(
      (each) =>
        each.permissions?.includes(DataPermissionTypeDataEnum.ASSIGN_MEDIA) &&
        !!each.topics?.some(
          (t) => !!parsedTopics.find((at) => at.oid === t.oid)
        )
    ) ?? [];
  const filteredCount = medias?.length ?? 0;

  const textField = (
    <TextField
      name="search"
      fullWidth={true}
      placeholder={t('pages.interaction.common.search') + '...'}
      size="small"
      value={mediaQueryString}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIconStyle />
          </InputAdornment>
        ),
        endAdornment: mediaQueryString && (
          <InputAdornment position="end">
            <ClearButton variant="text" onClick={handleClearSearchQuery}>
              {t('components.common.clear')}
            </ClearButton>
          </InputAdornment>
        ),
      }}
      onChange={handleChangeSearchQuery}
    />
  );

  const mainContent = (
    <>
      {loading && (
        <ListWrapper>
          <MediaSearchSkeleton />
        </ListWrapper>
      )}

      {!loading && medias.length === 0 ? (
        <NoItemPlaceholder>
          {t('components.touchpointManagement.noSearchResult')}
        </NoItemPlaceholder>
      ) : (
        <ListWrapper>
          {medias.map(
            (media) =>
              media.id && (
                <MediaSearchCard
                  key={media.id}
                  media={media}
                  isMediaSelected={isMediaSelected(media)}
                  onAddMediaToSelected={onAddMediaToSelected}
                />
              )
          )}
        </ListWrapper>
      )}
    </>
  );

  if (isMobile) {
    return (
      <MediaEditAccordion
        title={t('components.touchpointManagement.allMedia', {
          filteredCount,
        })}
      >
        <MediumStack>
          {textField}
          {mainContent}
        </MediumStack>
      </MediaEditAccordion>
    );
  }

  return (
    <DialogColumn>
      <LineBox>
        <ColumnTitle>
          {mediaQueryString
            ? t('components.touchpointManagement.resultOfSearching', {
                filteredCount,
              })
            : t('components.touchpointManagement.allMedia', {
                filteredCount,
              })}
        </ColumnTitle>
        <SortMenu order={mediaOrder} onChangeOrder={handleChangeMediaOrder} />
      </LineBox>

      {textField}
      {mainContent}
    </DialogColumn>
  );
};

const LineBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SearchIconStyle = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: 20,
  height: 20,
}));

const ListWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),

  // Add this to make border show up
  padding: '0 1px',
}));

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const ClearButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
}));
