import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder } from '@ysura/common';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActivityDetailCardList } from '@/components/ActivityDetails';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { errorConfigQueryLoadingError } from '@/hooks/useNotification';
import { useGetTouchpointListByPersonIdQuery } from '@/services/graphql/hooks';
import { parseActivityConnection } from '@/services/graphql/parsers';
import { ActivityConnection, ActivityStatus } from '@/services/graphql/types';

import { TouchpointHistorySkeletonCard } from './TouchpointHistorySkeletonCard';

export const TouchpointsHistoryStaticBlock = () => {
  const { id = '' } = useParams();
  const { t } = useTranslation();
  const errorCfg = useRef(
    errorConfigQueryLoadingError(t('components.error.touchpointHistory'))
  );

  const { loading, data, error, refetch } = useGetTouchpointListByPersonIdQuery(
    {
      ...errorCfg.current.context,
      variables: {
        personId: id,
        status: ActivityStatus.CLOSED,
      },
    }
  );

  errorCfg.current.bannerOptions.button = {
    label: t('components.error.reload'),
    action: refetch,
  };

  if (loading || error) {
    return (
      <OverviewStaticBlock
        title={t('pages.personView.touchpointHistory')}
        testId="touchpoint-history-static-block"
      >
        <TouchpointHistorySkeletonCard />
      </OverviewStaticBlock>
    );
  }

  const activities = parseActivityConnection(
    data?.person?.activities as ActivityConnection
  );

  return (
    <OverviewStaticBlock title={t('pages.personView.touchpointHistory')}>
      <ContentWrapper>
        {activities?.length ? (
          <ActivityDetailCardList activities={activities} />
        ) : (
          <NoItemPlaceholder>
            {t('pages.personView.noTouchpoint')}
          </NoItemPlaceholder>
        )}
      </ContentWrapper>
    </OverviewStaticBlock>
  );
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
