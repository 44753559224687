import { Tab as MuiTab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  PromotionalMaterialGroupData,
  PromotionalMaterialTypeDataEnum,
} from '@ysura/common';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

type PromotionalMaterialTabsProps = {
  tabIndex: number;
  promats: Array<PromotionalMaterialGroupData>;
  isLoading?: boolean;
  handleTabChange: (event: SyntheticEvent, newValue: number) => void;
};

export const PromotionalMaterialTabs = ({
  tabIndex,
  promats,
  isLoading,
  handleTabChange,
}: PromotionalMaterialTabsProps) => {
  const { t } = useTranslation();

  // The ? here is preventing error for no mock data test case we have
  const productPromatsCount =
    promats?.filter(
      (material) =>
        material.promotionalMaterial?.type ===
        PromotionalMaterialTypeDataEnum.PRODUCT
    ).length ?? 0;

  // Same reason with line 22
  const nonProductPromatsCount = promats?.length - productPromatsCount;

  return (
    <Tabs value={tabIndex} onChange={handleTabChange}>
      <Tab
        label={
          <Typography variant="subtitle2">
            {`${t('pages.interaction.expand.samples')} ${
              !isLoading ? `(${productPromatsCount})` : ''
            }`}
          </Typography>
        }
      />
      <Tab
        label={
          <Typography variant="subtitle2">
            {`${t('pages.interaction.expand.otherMaterials')} ${
              !isLoading ? `(${nonProductPromatsCount})` : ''
            }`}
          </Typography>
        }
      />
    </Tabs>
  );
};

const Tab = styled(MuiTab)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(2),
  },
}));
