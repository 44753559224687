import { OrganizationalUnitData } from '@ysura/common';

import { convertedState } from '@/services/graphql/parsers/utils';
import {
  OrganizationalUnit,
  OrganizationalUnitConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseOrganizationalUnitData = (
  orgUnit: DeepPartial<OrganizationalUnit>
): OrganizationalUnitData => {
  return {
    id: orgUnit.id ?? undefined,
    oid: orgUnit.oid ?? undefined,
    name: orgUnit.name ?? undefined,
    state: convertedState(orgUnit.state),
  };
};

export const parseOrganizationalUnitConnection = (
  orgUnitConnection?: DeepPartial<OrganizationalUnitConnection>
): Array<OrganizationalUnitData> => {
  const rawOrgUnits = orgUnitConnection?.edges ?? [];
  const mappedOrgUnits = rawOrgUnits
    .map((edge) => edge?.node ?? null)
    .map((orgUnit) => (orgUnit ? parseOrganizationalUnitData(orgUnit) : null));

  return filterNonNull(mappedOrgUnits);
};
