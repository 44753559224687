import { gql } from '@apollo/client';

export const getTouchpointMediaList = gql`
  query getTouchpointMediaList(
    $id: ID!
    $nameFilter: StringFilterInput
    $orderByName: OrderBy!
  ) {
    activity(id: $id) {
      id
      oid
      activityType {
        id
        oid
        name
        baseType
        medias(
          filter: { name: $nameFilter, lifecycleStatus: { _eq: ACTIVE } }
          orderBy: { name: $orderByName }
        ) {
          edges {
            node {
              id
              oid
              name
              description
              mediaType
              thumbnailDocument {
                id
                oid
                contentLink
                mediaType
              }
              latestVersion {
                oid
                version
                document {
                  id
                  oid
                  contentLink
                  mediaType
                }
              }
              topics {
                edges {
                  node {
                    id
                    oid
                    name
                    state
                  }
                }
              }
              keyMessages {
                edges {
                  node {
                    id
                    oid
                    name
                    state
                    topics {
                      edges {
                        node {
                          id
                          oid
                          name
                          state
                        }
                      }
                    }
                  }
                }
              }
              dataPermissions {
                type
              }
            }
          }
          filteredCount
        }
        topics(filter: { state: { _eq: ACTIVE } }) {
          edges {
            node {
              id
              oid
              name
            }
          }
        }
      }
    }
  }
`;
