import { LocalHospital } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Stack, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AddressData,
  AddressTypeDataEnum,
  Label,
  OrganizationData,
  OrganizationInfo,
} from '@ysura/common';

import { LabelsContainer } from '@/components/LabelsContainer';
import { LinkNoDecoration } from '@/components/LinkTo';
import {
  getPostalCodeCityAddress,
  getStreetAddress,
} from '@/services/employment';

type OrganizationSimpleDataStaticBlockProps = {
  organization?: OrganizationData;
};

export const OrganizationSimpleDataStaticBlock = ({
  organization,
}: OrganizationSimpleDataStaticBlockProps) => {
  const theme = useTheme();
  if (!organization) {
    return null;
  }

  const { addresses, organizationTypes } = organization;
  const address = addresses?.find(
    (it: AddressData) => it.addressType == AddressTypeDataEnum.STANDARD
  );

  const street = getStreetAddress(address);
  const postalCodeCityAddress = getPostalCodeCityAddress(address);

  return (
    <LinkNoDecoration to={`/organization/${organization.id}`}>
      <Stack data-testid="organization-item">
        <OrganizationInfo
          organization={{
            name: organization.name ?? '',
            icon: <StyledLocalHospital fontSize="small" />,
            color: theme.palette.text.primary,
          }}
          organizationTypes={
            <>
              {organizationTypes && organizationTypes.length > 0 && (
                <Stack>
                  <LabelsContainer>
                    {organizationTypes.map((type, idx) => (
                      <Label
                        key={`type-${idx}`}
                        data-testid="organization-type-label"
                        variant="ghost"
                        color="info"
                      >
                        {type.name}
                      </Label>
                    ))}
                  </LabelsContainer>
                </Stack>
              )}
            </>
          }
          address={{
            street: `${street} ${postalCodeCityAddress}`,
            icon: <StyledLocationOnIcon fontSize="small" />,
            color: theme.palette.primary.main,
          }}
        />
      </Stack>
    </LinkNoDecoration>
  );
};

const StyledLocalHospital = styled(LocalHospital)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledLocationOnIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
