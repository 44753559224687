import { gql } from '@apollo/client';

export const getPersonTypes = gql`
  query getPersonTypes {
    personTypes(filter: { state: { _eq: ACTIVE } }) {
      totalCount
      edges {
        node {
          id
          oid
          name
        }
      }
    }
  }
`;
