import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, ActivityStatusDataEnum } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditIconButton, UpperCaseButton } from '@/components/Button';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { EditActivityDialog } from '@/views/Activity/Update';
import { groupGivenPromotionalMaterials } from '@/views/Activity/Update/PromotionalMaterials/helper';
import { PromotionalMaterialSignedFormDialog } from '@/views/Interaction/ExpandView/PromotionalMaterial';
import { PromotionalMaterialCardList } from '@/views/Interaction/Overview/PromotionalMaterial';

type PromotionalMaterialCardProps = {
  activity: ActivityData | null;
  canEditActivity?: boolean;
};

export const PromotionalMaterialsStaticBlock = ({
  activity,
  canEditActivity = false,
}: PromotionalMaterialCardProps) => {
  const { t } = useTranslation();

  const shouldShowViewSignedButton = !!activity?.sampleRequestFormDocument?.oid;

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const [isSignedFormDialogOpen, setIsSignedFormDialogOpen] = useState(false);

  const handleEditClicked = () => {
    setIsEditDialogOpen(true);
  };

  const handleEditClosed = () => {
    setIsEditDialogOpen(false);
  };

  const handleOpenSignedFormDialog = () => {
    setIsSignedFormDialogOpen(true);
  };

  const onCloseSignedFormDialog = () => {
    setIsSignedFormDialogOpen(false);
  };

  if (!activity) {
    return null;
  }

  return (
    <OverviewStaticBlock
      title={t('pages.interaction.common.promatColumnTitle')}
      spacing={'spacious'}
      subtitle={
        canEditActivity && <EditIconButton onClick={handleEditClicked} />
      }
    >
      <PromotionalMaterialWrapper>
        <PromotionalMaterialCardList
          promotionalMaterialGroups={groupGivenPromotionalMaterials(
            activity.promotionalMaterials ?? []
          )}
          isDoneTouchpoint={activity.status === ActivityStatusDataEnum.CLOSED}
          isSigned={!!activity.sampleRequestFormDocument}
        />

        {shouldShowViewSignedButton && (
          <UpperCaseButton
            title={t('pages.interaction.common.viewSignedRequestForm')}
            variant={'outlined'}
            onClick={handleOpenSignedFormDialog}
          />
        )}

        {isSignedFormDialogOpen && (
          <PromotionalMaterialSignedFormDialog
            isOpen={isSignedFormDialogOpen}
            activity={activity}
            onClose={onCloseSignedFormDialog}
          />
        )}

        {isEditDialogOpen && (
          <EditActivityDialog
            type="promotionalMaterials"
            activity={activity}
            isOpen={isEditDialogOpen}
            onClose={handleEditClosed}
          />
        )}
      </PromotionalMaterialWrapper>
    </OverviewStaticBlock>
  );
};

export const PromotionalMaterialWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const CardHeaderStack = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
});
