import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { AttendeeInfo } from '@/components/Attendee/AttendeeInfo';
import { SetFieldValue } from '@/views/Activity/TouchpointManagement/types';

type AttendeeInfoListProps = {
  attendees: PersonData[];
  variant: 'add' | 'remove';
  isSubmitting?: boolean;
  setFieldValue: SetFieldValue | undefined;
  onCloseSearch?: VoidFunction;
};
export const AttendeesInfoList = ({
  attendees,
  variant,
  isSubmitting = false,
  setFieldValue,
  onCloseSearch,
}: AttendeeInfoListProps) => {
  const { t } = useTranslation();

  if (!attendees?.length) {
    return (
      <CustomizeNoResultFound data-testid="no-found-attendees">
        <NoItemPlaceholder
          subMessage={t('components.globalSearch.noResultLong')}
        >
          {t('components.globalSearch.noResult')}
        </NoItemPlaceholder>
      </CustomizeNoResultFound>
    );
  }

  return (
    <CustomizeList data-testid="attendees-info-list">
      {attendees.map((attendee, index) => (
        <AttendeeInfo
          key={attendee.id}
          variant={variant}
          attendee={attendee}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          showDivider={index !== attendees.length - 1}
          onCloseSearch={onCloseSearch}
        />
      ))}
    </CustomizeList>
  );
};

const CustomizeNoResultFound = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  // Add this padding to see shadow
  padding: `0 1px`,
}));

const CustomizeList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  // added padding in order for the card border to be visible
  padding: '0 1px',
}));
