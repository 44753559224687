import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeIcon from '@mui/icons-material/Badge';
import CallIcon from '@mui/icons-material/Call';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import FaxIcon from '@mui/icons-material/Fax';
import MailIcon from '@mui/icons-material/Mail';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import PeopleIcon from '@mui/icons-material/People';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityTypeBaseTypeDataEnum, IconWrapper } from '@ysura/common';
import { ReactNode } from 'react';

import { ActivityTypeBaseType } from '@/services/graphql/types';

type iconPreferenceType = {
  [baseType: string]: {
    bgColor: string;
    icon: ReactNode;
  };
};

type ActivityIconProps = {
  baseType?: ActivityTypeBaseTypeDataEnum;
  isDisabled?: boolean;
  size?: number;
};

export const ActivityIcon = ({
  baseType = ActivityTypeBaseTypeDataEnum.WEBINAR,
  isDisabled = false,
  size,
}: ActivityIconProps) => {
  const theme = useTheme();
  const iconPreference: iconPreferenceType = {
    [ActivityTypeBaseType.DIRECT_CONTACT]: {
      bgColor: theme.palette.chart.violet[0],
      icon: <PeopleIcon />,
    },
    [ActivityTypeBaseType.WEBINAR]: {
      bgColor: theme.palette.secondary.main,
      icon: <VideocamIcon />,
    },
    [ActivityTypeBaseType.EMAIL]: {
      bgColor: theme.palette.info.main,
      icon: <MailIcon />,
    },
    [ActivityTypeBaseType.EVENT_CONTACT]: {
      bgColor: theme.palette.warning.main,
      icon: <BadgeIcon />,
    },
    [ActivityTypeBaseType.FAX]: {
      bgColor: theme.palette.warning.darker,
      icon: <FaxIcon />,
    },
    [ActivityTypeBaseType.MAIL]: {
      bgColor: theme.palette.warning.dark,
      icon: <MarkAsUnreadIcon />,
    },
    [ActivityTypeBaseType.TELEPHONE]: {
      bgColor: theme.palette.success.main,
      icon: <CallIcon />,
    },
    [ActivityTypeBaseType.TEMPLATED_EMAIL]: {
      bgColor: theme.palette.info.dark,
      icon: <AlternateEmailIcon />,
    },
    [ActivityTypeBaseType.CUSTOM]: {
      bgColor: theme.palette.grey[700],
      icon: <DashboardCustomizeIcon />,
    },
    [ActivityTypeBaseType.REMOTE]: {
      bgColor: theme.palette.secondary.main,
      icon: <VideocamIcon />,
    },
  };

  return (
    <StyledIconWrapper
      testId="icon-wrapper"
      isDisabled={isDisabled}
      backgroundColor={
        iconPreference[baseType]?.bgColor || theme.palette.secondary.main
      }
      size={size ?? 20}
    >
      {iconPreference[baseType]?.icon}
    </StyledIconWrapper>
  );
};

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  svg: {
    fill: theme.palette.common.white,
    width: 12,
    height: 12,
  },
}));
