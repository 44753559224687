import CloseIcon from '@mui/icons-material/Close';
import { Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SquareButton } from '@ysura/common';

import { FullScreenButton } from '@/components/Button';
import { PageNumberController } from '@/components/PageNumberController';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';

import { LeftArrow, MiddleButtons, RightArrow } from './ActionStyle';

type HTMLActionsProps = {
  currentPageNumber: number;
  totalPages: number;
  onHTMLPageTransition: (offset: number) => void;
  onClose: VoidFunction;
  isFullScreenShown?: boolean;
  isReadOnlyMode?: boolean;
};

export const HTMLFileActions = ({
  currentPageNumber,
  totalPages,
  onHTMLPageTransition,
  onClose,
  isFullScreenShown = false,
  isReadOnlyMode = false,
}: HTMLActionsProps) => {
  const isSmallScreenLandscape = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );

  const { toggleIsSharingInFullscreen } = useInteraction();
  const handleFullScreen = () => {
    toggleIsSharingInFullscreen();

    setTimeout(() => {
      /**
       * calls the resize event so the PDF takes proper
       * width/height when the container extends or shrinks
       */
      window.dispatchEvent(new Event('resize'));
    }, 0);
  };

  const renderFullScreenButton = () => {
    return (
      isFullScreenShown && (
        <FullScreenButton onChangeFullScreen={handleFullScreen} />
      )
    );
  };

  const renderPageDownButton = () => {
    return (
      <SquareButton
        variant="secondary"
        disabled={currentPageNumber === 1}
        testId="page_down"
        onClick={() => onHTMLPageTransition(-1)}
      >
        <LeftArrow />
      </SquareButton>
    );
  };

  const renderPageUpButton = () => {
    return (
      <SquareButton
        disabled={currentPageNumber >= totalPages}
        variant="secondary"
        testId="page_up"
        onClick={() => onHTMLPageTransition(1)}
      >
        <RightArrow />
      </SquareButton>
    );
  };

  const renderButtons = (
    shouldRenderPageTracker: boolean,
    pageTrackerVariant: 'h4' | 'h6' = 'h6'
  ) => {
    return (
      <>
        <Spacer />

        {!isReadOnlyMode && (
          <MiddleButtons>
            {renderPageDownButton()}

            {shouldRenderPageTracker && (
              <PageNumberController
                currentPage={currentPageNumber}
                totalPages={totalPages}
                variant={pageTrackerVariant}
              />
            )}

            {renderPageUpButton()}
          </MiddleButtons>
        )}

        <Spacer>{isFullScreenShown && renderFullScreenButton()}</Spacer>
      </>
    );
  };

  const renderCloseIcon = () => {
    return (
      <SquareButton testId="close_button" onClick={onClose}>
        <CloseIcon />
      </SquareButton>
    );
  };

  return (
    <>
      {isSmallScreenLandscape && (
        <MobileLandscapeDialogHTMLActions data-testid="mobile-landscape-dialog-actions">
          {renderCloseIcon()}
          <ButtonWrapper>{renderButtons(true)}</ButtonWrapper>
        </MobileLandscapeDialogHTMLActions>
      )}

      {!isSmallScreenLandscape && (
        <LargeScreenDialogHTMLActions data-testid="large-screen-dialog-actions">
          {renderButtons(true)}
        </LargeScreenDialogHTMLActions>
      )}
    </>
  );
};

const LargeScreenDialogHTMLActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  paddingTop: 0,
  justifyContent: 'center',
  gap: theme.spacing(5),

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
  },
}));

const MobileLandscapeDialogHTMLActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  height: '100%',
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const Spacer = styled(Box)({
  flex: 1,

  display: 'flex',
  justifyContent: 'flex-end',
});
