import {
  ActivityData,
  KeyMessageData,
  MediaData,
  TopicData,
} from '@ysura/common';

import { MediaTrackingData, ViewedMedia } from '@/hooks/useInteraction/';
import { isUndefOrEmptyArray, record2Array } from '@/utils/arrays';
import { hasKeyMessagesSomeTopics } from '@/views/Interaction/helpers/keyMessageHelper';

export const checkIfMediaExists = (
  activity: ActivityData | undefined,
  fallbackMedia: Array<MediaData> | undefined
) => {
  if (isUndefOrEmptyArray(activity?.media)) {
    if (isUndefOrEmptyArray(fallbackMedia)) {
      return false;
    }
  }

  return true;
};

export const updateViewedMediaKeyMessages = (
  viewedMedia: ViewedMedia,
  selectedTopics: Array<TopicData>
): Array<MediaData> => {
  const viewedMedias = record2Array(viewedMedia);

  return viewedMedias
    .map((viewedMedia) => updateViewedMediaTrackingInfos(viewedMedia))
    .map((media) => {
      let updatedKeyMessages: Array<KeyMessageData> = [];
      media.keyMessages?.forEach((keyMessage: KeyMessageData) => {
        const hasSomeTopics = hasKeyMessagesSomeTopics(
          selectedTopics,
          keyMessage
        );
        if (hasSomeTopics) {
          updatedKeyMessages = [...updatedKeyMessages, keyMessage];
        }
      });

      return {
        ...media,
        keyMessages: updatedKeyMessages,
      };
    });
};

export const getTopicsFromMedia = (media: ViewedMedia): Array<TopicData> => {
  const viewedMedias = record2Array(media);

  return viewedMedias
    .map((viewedMedia) => viewedMedia.media)
    .flatMap(({ topics }) => topics ?? []);
};

export const updateViewedMediaTrackingInfos = (viewedMedia: {
  media: MediaData;
  mediaTracking: Array<MediaTrackingData>;
}): MediaData => {
  return {
    ...viewedMedia.media,
    trackingInfos: viewedMedia.mediaTracking.flatMap(({ id }) => id),
  };
};
