import { Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { useMediaContext } from '@/hooks/state';

type MediaNumberTextProps = {
  isLoading: boolean;
  filteredCount: number;
};

export const MediaNumberText = ({
  isLoading,
  filteredCount,
}: MediaNumberTextProps) => {
  const { t } = useTranslation();
  const { mediaQueryString } = useMediaContext();

  if (isLoading) {
    return <TextSkeleton width={80} height={24} data-testid="text-skeleton" />;
  }

  if (mediaQueryString) {
    return (
      <TypographyStyle variant="subtitle1">
        {t('pages.interaction.common.resultOfSearching', {
          queryString: mediaQueryString,
          filteredCount: filteredCount,
        })}
      </TypographyStyle>
    );
  }

  return (
    <TypographyStyle variant="subtitle1">
      {t('pages.interaction.common.allMedia', {
        filteredCount: filteredCount,
      })}
    </TypographyStyle>
  );
};

const TextSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

const TypographyStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
