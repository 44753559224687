import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverviewCard } from '@ysura/common';

export const TouchpointHistorySkeletonCard = () => {
  return (
    <OverviewCard testId="touchpoint-history-skeleton">
      <Wrapper>
        <TopBox>
          <SmallBox>
            <Skeleton variant="circular" width={20} height={20} />
            <Typography component="div" variant="subtitle2">
              <Skeleton width={70} height={20} />
            </Typography>
          </SmallBox>
          <Skeleton width={64} height={22} />
        </TopBox>

        <Typography component="div" variant="subtitle2">
          <Skeleton width={200} height={20} />
        </Typography>

        <TinyStack>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={160} />
          </Typography>
        </TinyStack>

        <TinyStack>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={160} />
          </Typography>
        </TinyStack>

        <TinyStack>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={160} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={160} />
          </Typography>
        </TinyStack>

        <Typography variant="caption">
          <Skeleton width={160} />
        </Typography>
      </Wrapper>
    </OverviewCard>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const TopBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const TinyStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));
