import { Box, Typography, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { KebabMenuOption } from '@ysura/common';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { KebabPopover } from './KebabPopover';
import {
  Button,
  ButtonBox,
  ButtonWrapper,
  HeaderTextWrapper,
  HeaderWrapper,
} from './PageHeaderStyles';
import { TitleStyleProps, TypographyProps } from './PageHeaderTypes';

interface PageHeaderProps {
  title?: string;
  titleIcon?: ReactNode;
  subTitle?: string;
  pageHeaderType?: string;
  isPageInactive?: boolean;
  buttonText?: string;
  buttonTestId?: string;
  hasCancel?: boolean;
  hasInformationIcon?: boolean;
  titleVariant?: 'dark' | 'grey';
  isButtonDisabled?: boolean;
  kebabMenuEntries?: Array<KebabMenuOption>;
  buttonHandler?: VoidFunction;
  cancelHandler?: VoidFunction;
  showInfoHandler?: VoidFunction;
}

export const PageHeader = ({
  title,
  titleIcon,
  subTitle,
  pageHeaderType,
  isPageInactive = false,
  buttonText,
  buttonTestId,
  titleVariant = 'grey',
  hasCancel = false,
  isButtonDisabled = false,
  kebabMenuEntries,
  buttonHandler,
  cancelHandler,
}: PageHeaderProps) => {
  const { t } = useTranslation();

  const shouldRenderButton = buttonText && buttonHandler;
  const isMatchedMediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <HeaderWrapper>
      <HeaderTextWrapper>
        <TypographyWrapper
          isPageInactive={isPageInactive}
          data-testid="page-header-title"
        >
          {title && (
            <Title variant="h5" titleVariant={titleVariant}>
              {titleIcon}
              {title}
            </Title>
          )}
        </TypographyWrapper>

        {pageHeaderType && (
          <SubTitleWrapper>
            <Typography variant="body2">{pageHeaderType}</Typography>
            &bull;
            <TypographyWrapper isPageInactive={isPageInactive}>
              <Typography variant="body2">{subTitle ?? title}</Typography>
            </TypographyWrapper>
          </SubTitleWrapper>
        )}
      </HeaderTextWrapper>

      {!isPageInactive && (
        <ButtonBox>
          {hasCancel && (
            <Box>
              <Button variant="outlined" onClick={cancelHandler}>
                {t('components.common.cancel')}
              </Button>
            </Box>
          )}

          {shouldRenderButton && (
            <ButtonWrapper isMatchedMediaQuery={isMatchedMediaQuery}>
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                data-testid={buttonTestId}
                onClick={buttonHandler}
              >
                {buttonText}
              </Button>
            </ButtonWrapper>
          )}

          {kebabMenuEntries && <KebabPopover menuEntries={kebabMenuEntries} />}
        </ButtonBox>
      )}
    </HeaderWrapper>
  );
};

const SubTitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.disabled,
}));

const TypographyWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPageInactive',
})<TypographyProps>(({ isPageInactive, theme }) => ({
  textDecoration: isPageInactive ? 'line-through' : 'none',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'titleVariant',
})<TitleStyleProps>(({ theme, titleVariant }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color:
    titleVariant === 'grey'
      ? theme.palette.text.secondary
      : theme.palette.text.primary,
}));
