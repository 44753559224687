import { Videocam, VideocamOff } from '@mui/icons-material';
import { RoundButton } from '@ysura/common';

import { useInteraction } from '@/hooks';

type ToggleCameraButtonProps = {
  size?: 'large' | 'medium' | 'small';
};

export const ToggleCameraButton = ({
  size = 'large',
}: ToggleCameraButtonProps) => {
  const { isCameraActive, isCameraAccessDisabled, toggleIsCameraActive } =
    useInteraction();

  const toggleCamera = () => {
    toggleIsCameraActive();
  };

  return (
    <RoundButton
      size={size}
      variant="secondary"
      inactive={!isCameraActive}
      disabled={isCameraAccessDisabled}
      testId="camera-button"
      onClick={toggleCamera}
    >
      {isCameraAccessDisabled || !isCameraActive ? (
        <VideocamOff />
      ) : (
        <Videocam />
      )}
    </RoundButton>
  );
};
