import { CommentTypeData, DataPermissionTypeDataEnum } from '@ysura/common';

import { convertedState } from '@/services/graphql/parsers/utils';
import { CommentType, CommentTypeConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseCommentTypeData = (
  commentType?: DeepPartial<CommentType>
): CommentTypeData => {
  return {
    id: commentType?.id,
    oid: commentType?.oid,
    name: commentType?.name,
    state: convertedState(commentType?.state),
    permissions:
      commentType?.dataPermissions?.map(
        (p) => p?.type as unknown as DataPermissionTypeDataEnum
      ) ?? [],
  };
};

export const parseCommentTypeConnection = (
  commentTypeConnection?: DeepPartial<CommentTypeConnection>
): Array<CommentTypeData> => {
  const rawCommentTypes = commentTypeConnection?.edges ?? [];
  const mappedCommentTypes = rawCommentTypes
    .map((edge) => edge?.node ?? null)
    .map((commentType) =>
      commentType ? parseCommentTypeData(commentType) : null
    );

  return filterNonNull(mappedCommentTypes);
};
