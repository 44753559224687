import { ConsentTypeData, TemplateData } from '@ysura/common';
import { useEffect, useState } from 'react';

import { DocumentType, useGetDocument } from '@/services/api';
import { useGetDocumentByIdQuery } from '@/services/graphql/hooks';
import { parseDocumentData } from '@/services/graphql/parsers';

export type UseGetPersonConsentTemplate = {
  isLoading: boolean;
  data: TemplateData | null;
  error: unknown | undefined;
};

/**
 * __useGetPersonConsentTemplate__
 *
 * Finds the template document for a consent (and the corresponding consentType)
 *
 * @example
 * const { template, isLoading, error } = useGetPersonConsentTemplate();
 */
export const useGetPersonConsentTemplate = (
  consentType?: ConsentTypeData | null
): UseGetPersonConsentTemplate => {
  const [templateWithContent, setTemplateWithContent] =
    useState<TemplateData | null>(null);

  const template = consentType?.digitalSignatureTemplate;

  const { data: documentData } = useGetDocumentByIdQuery({
    variables: {
      documentId: template?.document?.id ?? '',
    },
    fetchPolicy: 'network-only',
  });
  const parsedDocument = documentData?.document
    ? parseDocumentData(documentData.document)
    : null;

  const { data, error, isLoading } = useGetDocument(parsedDocument);

  useEffect(() => {
    if (
      !isLoading &&
      !templateWithContent &&
      template &&
      data &&
      data.mediaType === DocumentType.HTML
    ) {
      const newTemplate: TemplateData = {
        ...template,
        document: {
          ...parsedDocument,
          content: data.data,
        },
      };

      setTemplateWithContent(newTemplate);
    }
  }, [isLoading, error, templateWithContent, template, data, parsedDocument]);

  return {
    isLoading,
    data: !isLoading && templateWithContent ? templateWithContent : null,
    error,
  };
};
