import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder, PromotionalMaterialGroupData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PromotionalMaterialCard } from './PromotionalMaterialCard';

type PromotionalMaterialCardListProps = {
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  isDoneTouchpoint: boolean;
  isSigned: boolean;
  viewAllHandler?: VoidFunction;
};

export const PromotionalMaterialCardList = ({
  promotionalMaterialGroups,
  isDoneTouchpoint,
  isSigned,
  viewAllHandler,
}: PromotionalMaterialCardListProps) => {
  const { t } = useTranslation();

  return (
    <>
      {promotionalMaterialGroups?.length === 0 && (
        <NoItemPlaceholder>
          {t('pages.interaction.common.noPromatPlanned')}
        </NoItemPlaceholder>
      )}

      {promotionalMaterialGroups && promotionalMaterialGroups.length > 0 && (
        <Wrapper onClick={viewAllHandler}>
          {promotionalMaterialGroups &&
            promotionalMaterialGroups.map((promotionalMaterial, index) => (
              <PromotionalMaterialCard
                key={`${promotionalMaterial.promotionalMaterial?.id}_${index}`}
                promotionalMaterial={promotionalMaterial}
                isSigned={isSigned}
                isDoneTouchpoint={isDoneTouchpoint}
                isLastItem={index === promotionalMaterialGroups.length - 1}
              />
            ))}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  ...theme.shape,
  backgroundColor: theme.palette.background.paper,
  gap: theme.spacing(2),

  // TODO: Uncomment this when implementing the promat modification view
  // cursor: 'pointer',
}));
