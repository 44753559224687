import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { Loader } from '@ysura/common';
import { Route, Routes as DomRoutes } from 'react-router-dom';

import {
  OrganizationSettingComponent,
  PersonSettingComponent,
} from '@/components/Settings';
import { useCurrentUser } from '@/hooks';
import { MainLayout } from '@/layouts';
import { ActivityPage } from '@/pages/Activity';
import { NotFound404 } from '@/pages/Error';
import { Homepage } from '@/pages/Homepage';
import { InteractionPage } from '@/pages/Interaction';
import { OrganizationPage } from '@/pages/Organization';
import { PersonPage } from '@/pages/Person';

const SentryRoutes = withSentryReactRouterV6Routing(DomRoutes);

export const Routes = () => {
  const { currentUser, loading } = useCurrentUser();
  const canReadActivity = currentUser?.permissions?.activity?.canReadActivity;

  return loading ? (
    // While the user is loading, we only allow navigating to the Homepage
    // to support fast initial page loads
    <SentryRoutes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Homepage />} />

        <Route
          path="*"
          element={
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          }
        />
      </Route>
    </SentryRoutes>
  ) : (
    // All the other routes are only allowed if the current user has finished loading
    // to be able to support routing based on permissions.
    <SentryRoutes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Homepage />} />
        <Route path="settings/person" element={<PersonSettingComponent />} />
        <Route
          path="settings/organization"
          element={<OrganizationSettingComponent />}
        />
        <Route path="person/:id" element={<PersonPage />} />
        {canReadActivity && (
          <Route path="touchpoint/:activityId" element={<ActivityPage />} />
        )}
        <Route path="organization/:id" element={<OrganizationPage />} />

        <Route path="*" element={<NotFound404 />} />
      </Route>
      <Route path="/interaction">
        <Route
          path=":activityId/:interactionType/:fromCrm"
          element={<InteractionPage />}
        />
        <Route
          path=":activityId/:interactionType"
          element={<InteractionPage />}
        />
      </Route>
    </SentryRoutes>
  );
};

const LoaderWrapper = styled(Box)({
  display: 'grid',
  height: '100%',
});
