import { Person } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  AttendeeData,
  getPersonFullNameWithTitle,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { RowWrapper } from './RowWrapper';

type ActivityAttendeeProps = {
  activity: ActivityData;
};

export const ActivityAttendee = ({ activity }: ActivityAttendeeProps) => {
  const { t } = useTranslation();

  const hasNoAttendeeDetail = (activity: ActivityData) => {
    // If the attendee was deleted (or only guest attendees are present) in the CRM,
    // do not render anything
    const hasNoAttendee = !activity?.attendees?.filter(
      (attendee: AttendeeData) => !attendee.isGuest
    ).length;
    const noAttendeeConfigured =
      activity.activityType?.minAttendeeCount == 0 &&
      activity.activityType?.maxAttendeeCount == 0;

    return hasNoAttendee || noAttendeeConfigured;
  };

  if (hasNoAttendeeDetail(activity)) {
    return null;
  }

  const activityAttendees: AttendeeData[] = activity.attendees ?? [];
  const firstAttendee = activityAttendees.find((it) => !it.isGuest);

  const attendeesContentView =
    activityAttendees.length > 1 ? (
      <Typography color="text.primary" variant="caption">
        {`${activityAttendees.length} ${t('pages.personView.attendee_other')}`}
      </Typography>
    ) : (
      <>
        {firstAttendee && (
          <RowWrapper>
            <Person />
            <Typography color="text.primary" variant="caption">
              {getPersonFullNameWithTitle(firstAttendee.person)}
            </Typography>
          </RowWrapper>
        )}
      </>
    );

  return (
    <WrapperStack>
      <Typography variant="caption">
        {t('pages.personView.attendee', { count: activityAttendees.length })}
      </Typography>
      {attendeesContentView}
    </WrapperStack>
  );
};

const WrapperStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));
