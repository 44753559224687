import {
  CommentData,
  GivenPromotionalMaterialData,
  KeyMessageData,
  MediaData,
  PromotionalMaterialGroupData,
  ReactionDataEnum,
  TopicData,
} from '@ysura/common';
import { formatISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import {
  convertedCommentTargetTypeDataEnum,
  convertedReactionDataEnum,
} from '@/services/graphql/parsers/utils';
import {
  ActivityShownMediaInput,
  ActivityShownMediaKeyMessageInput,
  CommentInput,
  CommentTargetType,
  PromotionalMaterialRequestInput,
  Reaction,
} from '@/services/graphql/types';

export const buildCurrentDateTime = () => {
  return formatISO(new Date());
};

export const buildCurrentDate = () => {
  return formatISO(new Date(), { representation: 'date' });
};

export const prepareCommentsForMutation = (
  comments: CommentData[],
  activityOid?: string
): CommentInput[] => {
  return comments.map((comment) => ({
    oid: comment.oid ?? uuidv4(),
    commentTypeId: comment.commentType?.oid ?? '',
    targetId: activityOid ? activityOid : comment.activityOid ?? '',
    targetType:
      convertedCommentTargetTypeDataEnum(comment.targetType) ??
      CommentTargetType.ACTIVITY,
    content: comment.content,
  }));
};

export const prepareDiscussedTopicsForMutation = (
  topics: TopicData[]
): string[] => {
  return topics.map((topic) => topic.oid ?? '');
};

export const prepareMediaForMutation = (
  medias: MediaData[]
): ActivityShownMediaInput[] => {
  if (!medias) {
    return [];
  }

  return medias.map((media, index) => {
    const isShownMedia = !!media?.shownMediaId;

    return isShownMedia
      ? {
          oid: media.shownMediaId ?? uuidv4(),
          mediaId: media.oid ?? '',
          versionId: media.shownVersion?.oid ?? '',
          sequenceNumber: index,
          keyMessages: prepareKeyMessageForMutation(media.keyMessages ?? []),
          reaction:
            convertedReactionDataEnum(
              media.reaction ?? ReactionDataEnum.NONE
            ) ?? Reaction.NONE,
          mediaTrackingInfoIds: media.trackingInfos ?? [],
        }
      : {
          oid: uuidv4(),
          mediaId: media.oid ?? '',
          versionId: media.latestVersion?.oid ?? '',
          sequenceNumber: index,
          keyMessages: prepareKeyMessageForMutation(media.keyMessages ?? []),
          reaction:
            convertedReactionDataEnum(
              media.reaction ?? ReactionDataEnum.NONE
            ) ?? Reaction.NONE,
          mediaTrackingInfoIds: media.trackingInfos ?? [],
        };
  });
};

export const prepareRequestedGivenPromotionalMaterials = (
  givenPromotionalMaterialsData: GivenPromotionalMaterialData[]
): PromotionalMaterialRequestInput[] => {
  if (!givenPromotionalMaterialsData) {
    return [];
  }

  return givenPromotionalMaterialsData.map((givenPromotionalMaterialData) => ({
    oid: givenPromotionalMaterialData.oid ?? uuidv4(),
    promotionalMaterialId:
      givenPromotionalMaterialData.promotionalMaterial?.oid ?? '',
    quantity: givenPromotionalMaterialData.requestedQuantity ?? 0,
    stockId: givenPromotionalMaterialData.stock?.oid ?? '',
  }));
};

export const prepareRequestedPromotionalMaterials = (
  promotionalMaterials: PromotionalMaterialGroupData[]
): PromotionalMaterialRequestInput[] => {
  if (!promotionalMaterials) {
    return [];
  }

  const result: Array<PromotionalMaterialRequestInput> = [];
  promotionalMaterials.forEach((promotionalMaterial) => {
    if (
      !promotionalMaterial?.batches.length &&
      promotionalMaterial?.requestedQuantity
    ) {
      result.push({
        oid: uuidv4(),
        promotionalMaterialId:
          promotionalMaterial.promotionalMaterial?.oid ?? '',
        quantity: promotionalMaterial?.requestedQuantity,
      });
    }

    promotionalMaterial.batches.forEach(({ physicalStock, virtualStock }) => {
      if (physicalStock.requestedQuantity > 0) {
        result.push({
          oid: uuidv4(),
          promotionalMaterialId:
            promotionalMaterial.promotionalMaterial?.oid ?? '',
          quantity: physicalStock.requestedQuantity ?? 0,
          stockId: physicalStock?.stock?.oid ?? '',
        });
      }
      if (virtualStock.requestedQuantity > 0) {
        result.push({
          oid: uuidv4(),
          promotionalMaterialId:
            promotionalMaterial.promotionalMaterial?.oid ?? '',
          quantity: virtualStock.requestedQuantity ?? 0,
          stockId: virtualStock?.stock?.oid ?? '',
        });
      }
    });
  });

  return result;
};

export const prepareKeyMessageForMutation = (
  keyMessages: KeyMessageData[]
): ActivityShownMediaKeyMessageInput[] => {
  if (!keyMessages) {
    return [];
  }

  return keyMessages.map((keyMessage) => ({
    oid: keyMessage.oid ?? '',
    reaction: convertedReactionDataEnum(keyMessage.reaction) ?? Reaction.NONE,
  }));
};
