import { RestartAlt, SettingsRounded } from '@mui/icons-material';
import {
  Box,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  ToggleButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonFilterData } from '@ysura/common';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterCategory } from '@/components/FilterCategory';
import { FilterCardSkeleton } from '@/components/GlobalSearch/FilterCardSkeleton';
import { countSelectedItemFilters } from '@/components/GlobalSearch/utils';

interface EmploymentFilterCardProps {
  loading?: boolean;
  personFiltersData: Array<PersonFilterData>;
  setPersonFiltersData: (value: Array<PersonFilterData>) => void;
  toggleShowAllEmployments: (value: boolean) => void;
}

export const EmploymentFilterCard = ({
  loading,
  personFiltersData,
  setPersonFiltersData,
  toggleShowAllEmployments,
}: EmploymentFilterCardProps) => {
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    toggleShowAllEmployments(JSON.parse(event.target.value));
  };

  const countSelectedFilters = countSelectedItemFilters(personFiltersData);

  const onFilterDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilterData = [...personFiltersData];
    newFilterData.forEach((filter) => {
      const target = filter.values.find(
        (item) => item.oid === event.target.name
      );
      if (target) {
        target.isSelected = event.target.checked;
      }
    });
    setPersonFiltersData(newFilterData);
  };

  const handleResetFilter = () => {
    const newFilterData = [...personFiltersData];
    newFilterData.forEach((filter) => {
      filter.values.forEach((val) => (val.isSelected = false));
    });
    setPersonFiltersData(newFilterData);
  };

  return (
    <Wrapper>
      <FilterHeader>
        <Typography variant="h5" data-testid="filter-card-wrapper-title">
          {`${t(
            'pages.organizationView.personDataCard.filter'
          )} (${countSelectedFilters})`}
        </Typography>
        <ButtonsWrapper>
          <ToggleButton value="setting">
            <StyleSettingsRounded fontSize="small" />
          </ToggleButton>
          <ToggleButton value="reset" onClick={handleResetFilter}>
            <StyleRestartAlt fontSize="small" />
          </ToggleButton>
        </ButtonsWrapper>
      </FilterHeader>

      <Stack>
        <StyleRadioGroup
          row
          data-testid="filter-card-employment-option"
          defaultValue={false}
          name="employment-buttons-group"
          onChange={handleChange}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t(
              'pages.organizationView.personDataCard.currentOrganization'
            )}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t('pages.organizationView.personDataCard.allOrganization')}
          />
        </StyleRadioGroup>
      </Stack>

      <StyleStack>
        {loading ? (
          <FilterCardSkeleton />
        ) : (
          <StyleStack data-testid="person-filter-category">
            {personFiltersData.map((filter) => (
              <FilterCategory
                key={filter.filterType}
                filterType={filter.filterType}
                title={t(
                  `components.globalSearch.filter.type.${filter.filterType}`
                )}
                values={filter.values}
                onChangeFilters={onFilterDataChange}
              />
            ))}
          </StyleStack>
        )}
      </StyleStack>
    </Wrapper>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  width: '100%',
  height: '100%',
}));

const StyleStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
}));

const FilterHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const StyleRadioGroup = styled(RadioGroup)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyleSettingsRounded = styled(SettingsRounded)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyleRestartAlt = styled(RestartAlt)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
