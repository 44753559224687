import { ActivityTypeBaseTypeDataEnum } from '@ysura/common';

export const validInteractionType = (
  baseType: ActivityTypeBaseTypeDataEnum
) => {
  return [
    ActivityTypeBaseTypeDataEnum.REMOTE,
    ActivityTypeBaseTypeDataEnum.DIRECT_CONTACT,
    ActivityTypeBaseTypeDataEnum.TELEPHONE,
  ].includes(baseType);
};
