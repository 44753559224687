import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  NoItemPlaceholder,
  PersonConsentData,
  PersonData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConsentCard, ConsentDialog } from '@/components/Consent';

type ConsentCardListProps = {
  consentList: Array<PersonConsentData>;
  person: PersonData;
  isInRemoteRoom?: boolean;
  isDigitalConsentActionsEnable?: boolean;
};

export const ConsentCardList = ({
  consentList,
  person,
  isInRemoteRoom = false,
  isDigitalConsentActionsEnable,
}: ConsentCardListProps) => {
  const { t } = useTranslation();

  const [isConsentPreviewOpen, setIsConsentPreviewOpen] = useState(false);
  const [currentConsent, setCurrentConsent] =
    useState<PersonConsentData | null>(null);

  const handleClickConsentCard = (consent: PersonConsentData) => {
    if (!consent) {
      return;
    }
    setCurrentConsent(consent);
    setIsConsentPreviewOpen(true);
  };

  if (!consentList.length) {
    return (
      <NoItemPlaceholder>
        {t('pages.interaction.common.noDataAvailable')}
      </NoItemPlaceholder>
    );
  }

  return (
    <>
      <ConsentItemWrapper>
        {consentList.map((consent) => (
          <ConsentCard
            key={consent?.id}
            consent={consent}
            handleClickCard={() => handleClickConsentCard(consent)}
          />
        ))}
      </ConsentItemWrapper>

      {currentConsent && (
        <ConsentDialog
          isOpen={isConsentPreviewOpen}
          consent={currentConsent}
          person={person}
          isInRemoteRoom={isInRemoteRoom}
          isDigitalConsentActionsEnable={isDigitalConsentActionsEnable}
          handleClose={() => setIsConsentPreviewOpen(false)}
        />
      )}
    </>
  );
};

const ConsentItemWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
