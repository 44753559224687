import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Box,
  IconButton as MuiIconButton,
  Toolbar as MuiToolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import { SearchDialog } from '@/components/GlobalSearch';
import { SearchField } from '@/components/GlobalSearch/components';
import { ImpersonationDialog } from '@/components/ImpersonationDialog';
import { Logo } from '@/components/Logo';
import { HEADER } from '@/config';
import { useOffSetTop } from '@/hooks';
import { cssStyles } from '@/utils/cssStyles';

import { AccountPopover } from './AccountPopover';

type RootStyleProps = {
  isOffset: boolean;
};

export const Header = () => {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false);
  const [isImpersonationDialogOpen, setIsImpersonationDialogOpen] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleClickSearch = () => {
    setIsSearchDialogOpen(true);
  };

  const handleStartSearchMobile = () => {
    setIsMobileSearchActive(true);
    setIsSearchDialogOpen(true);
  };

  const handleChangeSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  const closeSearchDialog = () => {
    setSearchQuery('');
    setIsSearchDialogOpen(false);
  };

  const openImpersonationDialog = () => {
    setIsImpersonationDialogOpen(true);
  };

  const closeImpersonationDialog = () => {
    setIsImpersonationDialogOpen(false);
  };

  return (
    <>
      <Wrapper isOffset={isOffset}>
        <Toolbar>
          <LogoAndSearchWrapper>
            <Logo />

            {/* SearchIcon button is visible for small screens */}
            <IconButton onClick={handleStartSearchMobile}>
              <Search />
            </IconButton>
          </LogoAndSearchWrapper>

          {/* SearchField is visible for large screens */}
          <SearchFieldWrapper>
            <SearchField
              searchQuery={searchQuery}
              onClickSearch={handleClickSearch}
            />
          </SearchFieldWrapper>

          <AccountPopover handleImpersonationClick={openImpersonationDialog} />
        </Toolbar>
      </Wrapper>
      <SearchDialog
        isOpen={isSearchDialogOpen}
        isMobileSearchActive={isMobileSearchActive}
        closeDialog={closeSearchDialog}
        searchQuery={searchQuery}
        changeSearchQuery={handleChangeSearchQuery}
      />
      <ImpersonationDialog
        isOpen={isImpersonationDialogOpen}
        handleDialogClose={closeImpersonationDialog}
      />
    </>
  );
};

const Wrapper = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isOffset',
})<RootStyleProps>(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: '100%',
  },
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  minHeight: '100% !important',

  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const LogoAndSearchWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const SearchFieldWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const Search = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
