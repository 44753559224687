import { ActivityData } from '@ysura/common';

import { EndInteraction } from '@/views/Interaction/EndInteraction';

type EndF2FInteractionDialogProps = {
  isOpen: boolean;
  activity: ActivityData | null;
  closeDialog: VoidFunction;
};

export const EndF2FInteractionDialog = ({
  isOpen,
  activity,
  closeDialog,
}: EndF2FInteractionDialogProps) => {
  return (
    <EndInteraction
      activity={activity}
      isOpen={isOpen}
      testId="end-f2f-interaction-dialog"
      onClose={closeDialog}
    />
  );
};
