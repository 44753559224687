import { Theme } from '@mui/material/styles';

type PaddingProps = {
  isDrawerOpen: boolean;
  isFullScreen: boolean;
  theme: Theme;
};

type HeightProps = {
  drawerBleeding: string;
  isMobile: boolean;
};

export const getWrapperHeight = ({ drawerBleeding, isMobile }: HeightProps) => {
  // if desktop, always return 100% for the wrapper height
  if (!isMobile) {
    return '100%';
  }

  return `calc(100% - ${drawerBleeding})`;
};

export const getWrapperPadding = ({
  isDrawerOpen,
  isFullScreen,
  theme,
}: PaddingProps) => {
  const { spacing } = theme;
  const singleSpace = spacing(1);

  if (isDrawerOpen) {
    return singleSpace;
  }

  if (isFullScreen) {
    return `${spacing(3)} ${singleSpace}`;
  }

  return `${singleSpace} ${singleSpace} ${spacing(2)} ${singleSpace}`;
};
