import { Grid } from '@mui/material';
import { OrganizationData } from '@ysura/common';

import {
  OrganizationBasicDataCard,
  RootOrganizationDataCard,
} from '@/views/OrganizationView';

interface OrganizationDataCardProps {
  isLoading?: boolean;
  organization?: OrganizationData;
}

export const OrganizationDataCard = ({
  isLoading,
  organization,
}: OrganizationDataCardProps) => {
  return (
    <>
      <Grid item>
        <OrganizationBasicDataCard
          isLoading={isLoading}
          organization={organization}
        />
      </Grid>
      <Grid item>
        <RootOrganizationDataCard
          isLoading={isLoading}
          organization={organization}
        />
      </Grid>
    </>
  );
};
