import {
  AttributeTypeDataEnum,
  FacetFilterData,
  FilterItemData,
  FilterItemsData,
  OptionFilterData,
} from '@ysura/common';

import {
  AddressFilterInput,
  OrganizationBaseType,
  OrganizationFilterInput,
  Scope,
  State,
} from '@/services/graphql/types';

export const buildOrganizationFilterQuery = (
  filters: FilterItemsData,
  scope: Scope
): OrganizationFilterInput => {
  const filter: OrganizationFilterInput = {
    scope: { _eq: scope },
    state: { _eq: State.ACTIVE },
    rootOrganization: { state: { _eq: State.ACTIVE } },
  };

  // calculate OrganizationType filter
  const organizationTypes =
    filters
      .find((item) => item.filterType === 'organizationTypes')
      ?.values.filter((value) => !!value.isSelected) || [];

  if (organizationTypes.length > 0) {
    filter.organizationTypes = {
      _and: [
        { baseType: { _eq: OrganizationBaseType.CUSTOMER } },
        {
          _or: organizationTypes.map((value) => {
            return {
              oid: { _eq: value.oid },
            };
          }),
        },
      ],
    };
  } else {
    filter.organizationTypes = {
      baseType: { _eq: OrganizationBaseType.CUSTOMER },
    };
  }

  // calculate City filter
  const postalCodeCities =
    filters
      .find((item) => item.filterType === 'cities')
      ?.values.filter((value) => !!value.isSelected) || [];

  if (postalCodeCities.length > 0) {
    filter.addresses = {
      address: {
        _or: postalCodeCities.map((filteredCity) => {
          return {
            city: { _eq: filteredCity.name },
          };
        }) as AddressFilterInput[],
      },
    };
  }

  const attributes =
    filters
      .find((item: FilterItemData) => item.filterType === 'attributes')
      ?.values.filter((value: FacetFilterData) => !!value.isSelected) || [];

  if (attributes.length > 0) {
    filter.attributes = {
      _and: attributes.map((attribute: FacetFilterData) => {
        return {
          attributeOid: { _eq: attribute.oid },
          value: {
            _or: attribute?.options
              ? attribute?.options
                  .filter((option: OptionFilterData) => option.isSelected)
                  .map((option: OptionFilterData) => {
                    return {
                      value: {
                        _eq:
                          attribute.type === AttributeTypeDataEnum.BOOLEAN
                            ? option?.id
                            : option.oid,
                      },
                    };
                  })
              : [],
          },
        };
      }),
    };
  }

  return filter;
};
