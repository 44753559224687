import { gql } from '@apollo/client';

export const getOrganizationById = gql`
  query getOrganizationById($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      oid
      name
      customerReference
      inClearance
      organizationTypes {
        edges {
          node {
            id
            oid
            name
            baseType
          }
        }
      }
      parentOrganization {
        id
        oid
        name
      }
      rootOrganization {
        id
        oid
        name
        customerReference
        organizationTypes {
          edges {
            node {
              id
              oid
              name
              baseType
            }
          }
        }
        addresses {
          edges {
            node {
              address {
                id
                oid
                name
                customerReference
                addressLine1
                addressLine2
                addressLine3
                postalCode
                city
                country {
                  id
                  oid
                  name
                }
              }
              addressType
            }
          }
        }
        communicationData {
          edges {
            node {
              communicationDataType {
                id
                oid
                name
                baseType
              }
              value
            }
          }
        }
      }
      hierarchyOrganizations {
        edges {
          node {
            id
            oid
            name
            parentOrganization {
              id
              oid
              name
            }
            organizationTypes {
              edges {
                node {
                  id
                  oid
                  name
                  baseType
                }
              }
            }
            addresses {
              edges {
                node {
                  address {
                    id
                    oid
                    name
                    customerReference
                    addressLine1
                    addressLine2
                    addressLine3
                    postalCode
                    city
                    country {
                      id
                      oid
                      name
                    }
                  }
                  addressType
                }
              }
            }
            employments {
              edges {
                node {
                  oid
                  organization {
                    id
                    oid
                    name
                    addresses {
                      edges {
                        node {
                          address {
                            name
                            customerReference
                            addressLine1
                            addressLine2
                            addressLine3
                            postalCode
                            city
                            country {
                              name
                            }
                          }
                          addressType
                        }
                      }
                    }
                  }
                  person {
                    id
                    firstName
                    middleName
                    lastName
                    prefixAcademicTitle {
                      name
                      state
                    }
                    postfixAcademicTitle {
                      name
                      state
                    }
                  }
                }
              }
            }
          }
        }
      }
      addresses {
        edges {
          node {
            address {
              id
              oid
              name
              customerReference
              addressLine1
              addressLine2
              addressLine3
              postalCode
              city
              country {
                id
                oid
                name
              }
            }
            addressType
          }
        }
      }
      communicationData {
        edges {
          node {
            communicationDataType {
              id
              oid
              name
              baseType
            }
            value
          }
        }
      }
      employments {
        edges {
          node {
            oid
            organization {
              name
              addresses {
                edges {
                  node {
                    address {
                      id
                      oid
                      name
                      customerReference
                      addressLine1
                      addressLine2
                      addressLine3
                      postalCode
                      city
                      country {
                        id
                        oid
                        name
                      }
                    }
                    addressType
                  }
                }
              }
            }
            person {
              id
              firstName
              middleName
              lastName
              prefixAcademicTitle {
                name
                state
              }
              postfixAcademicTitle {
                name
                state
              }
            }
          }
        }
      }
    }
  }
`;
