export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const CUSTOM_BREAKPOINTS = {
  MOBILE_LANDSCAPE_MAX_HEIGHT: '420px',
  MOBILE_LANDSCAPE: {
    MOBILE_LANDSCAPE_MAX_HEIGHT: '35px',
  },
};

export const WAITING_ROOM = {
  MOBILE: {
    DRAWER_HEIGHT_PORTRAIT: '355px',
  },
};

export const REMOTE_INTERACTION = {
  DESKTOP: {
    // The content container have padding top and bottom is 40px so the height should be 100% - 80px
    SHARING_CONTAINER_HEIGHT: 'calc(100% - 80px)',
    VIDEO_CONTAINER_FULLSCREEN_WIDTH: '74px',
  },
  MOBILE: {
    AUDIO_VIDEO_CONTROLS_HEIGHT_PORTRAIT: '56px',
    VIDEO_WITH_CONTROLS_HEIGHT_PORTRAIT: '304px',
    VIDEO_HEIGHT_PORTRAIT: '256px',

    AUDIO_VIDEO_CONTROLS_WIDTH_LANDSCAPE: '72px',
    DRAWER_HEIGHT_PORTRAIT: '200px',
    DRAWER_HEIGHT_LANDSCAPE: '35px',
  },
};

export const TELEPHONE_INTERACTION = {
  WAITING_ROOM: {
    MOBILE: {
      DRAWER_HEIGHT_PORTRAIT: `350px`,
    },
  },
  MOBILE: {
    DRAWER_HEIGHT_PORTRAIT: `365px`,
    DRAWER_HEIGHT_LANDSCAPE: '35px',
  },
  DESKTOP: {
    FULLSCREEN_WIDTH: '75px',
  },
};

export const DIALOG = {
  DESKTOP: {
    // The height of a dialog can only reach 40px padding from top and bottom
    MAX_HEIGHT: `calc(100% - 80px)`,
  },
  MOBILE: {
    // The height of a dialog can only reach 16px padding from top and bottom
    MAX_HEIGHT: `calc(100% - 32px)`,
    // Height of button plus padding top bottom
    FLOATING_BUTTON_HEIGHT: '68px',
  },
};

export const GRAPHIC_DIALOG = {
  MAX_WIDTH: 320,
};

export const GLOBAL_SEARCH = {
  HEADER_SEARCH_FIELD: {
    WIDTH_MEDIUM: 696,
    WIDTH_LARGE: 858,
  },
  DIALOG: {
    DESKTOP: {
      /**
       * The width of the search field inside the app header is 563px.
       * The width below is 563px + 2*(16px) of the dialog's side padding.
       */
      WIDTH_MEDIUM: 720,
      WIDTH_LARGE: 890,
      // TODO: should be 20x search result item height (to be done with youtrack.ysura.com/issue/CIP-596)
      FILTER_AREA_WIDTH: '33%',
      RESULT_AREA_WIDTH: '67%',
      MAX_HEIGHT: 'calc(100% - 24px)',
      PADDING_TOP_MEDIUM: 12,
      PADDING_TOP_LARGE: 26,
    },
  },
};

export const TOUCHPOINT_MANAGEMENT = {
  SEARCH_FIELD_HEIGHT_DESKTOP: 87.95,
};
