import { DeliveryTimeFrameData } from '@ysura/common';

import {
  DeliveryTimeFrame,
  DeliveryTimeFrameConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseDeliveryTimeFrameData = (
  deliveryTimeFrame?: DeepPartial<DeliveryTimeFrame>
): DeliveryTimeFrameData => {
  return {
    id: deliveryTimeFrame?.id,
    oid: deliveryTimeFrame?.oid,
    name: deliveryTimeFrame?.name,
  };
};

export const parseDeliveryTimeFrameConnection = (
  deliveryTimeFrames?: DeepPartial<DeliveryTimeFrameConnection>
): Array<DeliveryTimeFrameData> => {
  const rawData = deliveryTimeFrames?.edges ?? [];
  const mappedData = rawData
    .map((edge) => edge?.node ?? null)
    .map((each) => (each ? parseDeliveryTimeFrameData(each) : null));

  return filterNonNull(mappedData);
};
