import {
  PersonData,
  PromotionalMaterialGroupData,
  TemplateData,
} from '@ysura/common';
import Handlebars from 'handlebars';

import {
  getDatePlaceholders,
  getReceiverForSampleRequestPlaceholders,
  getSamplesTablePlaceholder,
  getSignaturePlaceholder,
} from '../placeholderService';

type CompileSampleRequestFormTemplateInput = {
  template: TemplateData | null;
  samples: Array<PromotionalMaterialGroupData>;
  receiver: PersonData | undefined;
  receiverAddress: string;
  receiverAddressExtension: string;
  signature: string;
};

export const compileSampleRequestFormTemplate = ({
  template,
  samples,
  receiver,
  receiverAddress,
  receiverAddressExtension,
  signature,
}: CompileSampleRequestFormTemplateInput): string => {
  if (!template?.document?.content) {
    return '';
  }

  const compileTemplate = Handlebars.compile(template.document.content);

  const templateData = {
    ...getReceiverForSampleRequestPlaceholders({
      receiver,
      receiverAddress,
      receiverAddressExtension,
    }),
    ...getSamplesTablePlaceholder(samples),
    ...getSignaturePlaceholder(signature),
    ...getDatePlaceholders('PP'),
  };

  return compileTemplate(templateData);
};
