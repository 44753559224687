import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GenerateRemoteInteractionCodeMutationVariables = Types.Exact<{
  oid: Types.Scalars['ActivityId']['input'];
  attendeeId?: Types.InputMaybe<Types.Scalars['ActivityAttendeeId']['input']>;
}>;

export type GenerateRemoteInteractionCodeMutation = {
  __typename?: 'Mutation';
  generateRemoteInteractionCode?: string | null;
};

export type GenerateRemoteInteractionCodeMutationFn = Apollo.MutationFunction<
  GenerateRemoteInteractionCodeMutation,
  GenerateRemoteInteractionCodeMutationVariables
>;

/**
 * __useGenerateRemoteInteractionCodeMutation__
 *
 * To run a mutation, you first call `useGenerateRemoteInteractionCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateRemoteInteractionCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateRemoteInteractionCodeMutation, { data, loading, error }] = useGenerateRemoteInteractionCodeMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useGenerateRemoteInteractionCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateRemoteInteractionCodeMutation,
    GenerateRemoteInteractionCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    GenerateRemoteInteractionCodeMutation,
    GenerateRemoteInteractionCodeMutationVariables
  >(Operations.generateRemoteInteractionCode, options);
}
export type GenerateRemoteInteractionCodeMutationHookResult = ReturnType<
  typeof useGenerateRemoteInteractionCodeMutation
>;
export type GenerateRemoteInteractionCodeMutationResult =
  Apollo.MutationResult<GenerateRemoteInteractionCodeMutation>;
export type GenerateRemoteInteractionCodeMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateRemoteInteractionCodeMutation,
    GenerateRemoteInteractionCodeMutationVariables
  >;
