import { ExpandMoreRounded } from '@mui/icons-material';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrganizationData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OrganizationHierarchyDialog,
  OrganizationSimpleDataStaticBlock,
} from '@/components/Organization';
import { OverviewStaticBlock } from '@/components/StaticBlock';

interface RootOrganizationDataStaticBlockProps {
  title: string;
  subTitle?: string;
  isTopRootOrganization?: boolean;
  organization: OrganizationData;
}
export const RootOrganizationDataStaticBlock = ({
  title,
  subTitle,
  isTopRootOrganization,
  organization,
}: RootOrganizationDataStaticBlockProps) => {
  const { t } = useTranslation();
  const [isHierarchyDialogOpen, setIsHierarchyDialogOpen] = useState(false);

  const toggleOrganizationHierarchyDialogOpen = () => {
    setIsHierarchyDialogOpen((prevState) => !prevState);
  };

  const translatedSubtitle = subTitle ? t(subTitle) : '';

  return (
    <OverviewStaticBlock
      title={t(title)}
      subtitle={
        <StyledBox onClick={toggleOrganizationHierarchyDialogOpen}>
          <StyledTypography variant="subtitle2">
            {translatedSubtitle}
          </StyledTypography>
          <IconButton>
            <StyleExpandMoreRounded data-testid="icon-hierarchy" />
          </IconButton>
        </StyledBox>
      }
      testId="root-organization-data-static-block"
    >
      {isTopRootOrganization ? (
        <Typography variant="caption">
          {t('pages.organizationView.sameRootOrganization')}
        </Typography>
      ) : (
        <StyledCard>
          <StyleStack>
            <OrganizationSimpleDataStaticBlock
              organization={organization.rootOrganization}
            />
          </StyleStack>
        </StyledCard>
      )}
      {isHierarchyDialogOpen && (
        <OrganizationHierarchyDialog
          isOpen={isHierarchyDialogOpen}
          organization={organization}
          onClose={toggleOrganizationHierarchyDialogOpen}
        />
      )}
    </OverviewStaticBlock>
  );
};

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyleStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));

const StyleExpandMoreRounded = styled(ExpandMoreRounded)(() => ({
  transform: 'rotate(-90deg)',
}));
