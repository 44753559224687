import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

import { TouchpointSkeletonCard } from './TouchpointSkeletonCard';

export const TouchpointsSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.touchpoints')}
      testId="touchpoint-skeleton-static-block"
    >
      <CardContentWrapper>
        <TouchpointSkeletonCard />
      </CardContentWrapper>
    </OverviewStaticBlock>
  );
};

const CardContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
