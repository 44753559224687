import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { SENTRY, SYSTEM } from '@/config';
import { getApiUrl, getRealm } from '@/utils/hostUtils';

const apiUrl = getApiUrl();

if (SENTRY.ENABLED) {
  Sentry.init({
    dsn: SENTRY.DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: SENTRY.SESSION_REPLAY ? [apiUrl] : [],
        networkCaptureBodies: true,
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    environment: `${SENTRY.ENVIRONMENT}-${getRealm()}`,
    release: `interact-organizer@${SYSTEM.VERSION}`,

    tracesSampleRate: SENTRY.ENVIRONMENT !== 'prod' ? 1.0 : 0.5,
    tracePropagationTargets: ['localhost', apiUrl],

    // We do not capture any non-error sessions
    replaysSessionSampleRate: 0,
    // If session replay is enabled, we capture 100% of error sessions
    replaysOnErrorSampleRate: SENTRY.SESSION_REPLAY ? 1.0 : 0,

    beforeBreadcrumb(breadcrumb, hint) {
      if (!SENTRY.GRAPHQL_BREADCRUMB_ENRICHMENT) {
        return breadcrumb;
      }

      if (breadcrumb.category === 'fetch') {
        const url = hint?.input?.[0];
        const body = hint?.input?.[1]?.body;

        // For GraphQL requests, we add the operation name and variables to the breadcrumb
        if (
          typeof url === 'string' &&
          url?.endsWith('/graphql') &&
          typeof body === 'string' &&
          body.length > 0
        ) {
          const jsonData = JSON.parse(body);

          breadcrumb.data = {
            ...breadcrumb.data,
            operationName: jsonData?.operationName,
            variables: jsonData?.variables,
          };
        }
      }

      return breadcrumb;
    },
  });
}
