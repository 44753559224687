import {
  EntityFilterTypeDataEnum,
  OrganizationFilterData,
  OrganizationFilterValueData,
  OrganizationPreferenceFacetData,
  PersonData,
  PersonFilterData,
  PersonFilterValueData,
  PersonPreferenceFacetData,
} from '@ysura/common';

import {
  convertOrganizationFilterValue,
  convertPersonFilterValue,
} from '@/components/GlobalSearch/utils';
import { ItemsToUpdate } from '@/components/Settings/common';
import { FilterPermissionData } from '@/components/Settings/helper/utils';
import { UpdatePreferenceAction } from '@/services/graphql/types';
import {
  OrganizationClientPermissions,
  PersonClientPermissions,
} from '@/services/user/permissions/ClientPermissions';

export const extractPersonFilters = (
  filters: PersonFilterData[]
): Map<string, PersonFilterValueData[]> => {
  const personTypesValues = filters
    .filter((o) => o.filterType === 'personTypes')
    .flatMap(({ values }) =>
      values.map((t: PersonFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const employeeFunctionValues = filters
    .filter((o) => o.filterType === 'employeeFunctions')
    .flatMap(({ values }) =>
      values.map((t: PersonFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const specialityValues = filters
    .filter((o) => o.filterType === 'specialities')
    .flatMap(({ values }) =>
      values.map((t: PersonFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const specialityGroupValues = filters
    .filter((o) => o.filterType === 'specialityGroups')
    .flatMap(({ values }) =>
      values.map((t: PersonFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const consentTypeValues = filters
    .filter((o) => o.filterType === 'consentTypes')
    .flatMap(({ values }) =>
      values.map((t: PersonFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const attributeValues = filters
    .filter((o) => o.filterType === 'attributes')
    .flatMap(({ values }) =>
      values.map((t: PersonFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const filterNames = {
    personTypes: personTypesValues,
    employeeFunctions: employeeFunctionValues,
    specialities: specialityValues,
    specialityGroups: specialityGroupValues,
    attributes: attributeValues,
    consentTypes: consentTypeValues,
  };

  return new Map(Object.entries(filterNames));
};

export const extractOrganizationFilters = (
  filters: OrganizationFilterData[]
): Map<string, OrganizationFilterValueData[]> => {
  const organizationTypeNames = filters
    .filter((o) => o.filterType === 'organizationTypes')
    .flatMap(({ values }) =>
      values.map((t: OrganizationFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const cityNames = filters
    .filter((o) => o.filterType === 'cities')
    .flatMap(({ values }) =>
      values.map((t: OrganizationFilterValueData) => {
        return {
          id: t.name ?? '',
          oid: t.name ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const attributeNames = filters
    .filter((o) => o.filterType === 'attributes')
    .flatMap(({ values }) =>
      values.map((t: OrganizationFilterValueData) => {
        return {
          id: t.id ?? '',
          oid: t.oid ?? '',
          name: t.name ?? '',
          avatar: t.avatar,
        };
      })
    );

  const filterNames = {
    organizationTypes: organizationTypeNames,
    cities: cityNames,
    attributes: attributeNames,
  };

  return new Map(Object.entries(filterNames));
};

export const checkPersonFilterPermission = (
  personPerm: PersonClientPermissions | undefined
) => {
  let personFilterPermissions: FilterPermissionData = {
    numAvailablePermission: 0,
    permission: [],
  };

  // No permission at all or not permission to read "permission-facets-read"
  if (!personPerm || !personPerm.canReadFacets) {
    return personFilterPermissions;
  }

  if (personPerm.canReadTypeFilter) {
    personFilterPermissions = {
      ...personFilterPermissions,
      numAvailablePermission: ++personFilterPermissions.numAvailablePermission,
      permission: [...personFilterPermissions.permission, 'personTypes'],
    };
  }

  if (personPerm.canReadRoleFilter) {
    personFilterPermissions = {
      ...personFilterPermissions,
      numAvailablePermission: ++personFilterPermissions.numAvailablePermission,
      permission: [...personFilterPermissions.permission, 'employeeFunctions'],
    };
  }

  if (personPerm.canReadSpecialityFilter) {
    personFilterPermissions = {
      ...personFilterPermissions,
      numAvailablePermission: ++personFilterPermissions.numAvailablePermission,
      permission: [...personFilterPermissions.permission, 'specialities'],
    };
  }

  if (personPerm.canReadSpecialityGroupFilter) {
    personFilterPermissions = {
      ...personFilterPermissions,
      numAvailablePermission: ++personFilterPermissions.numAvailablePermission,
      permission: [...personFilterPermissions.permission, 'specialityGroups'],
    };
  }

  if (personPerm.canReadAttributeFilter) {
    personFilterPermissions = {
      ...personFilterPermissions,
      numAvailablePermission: ++personFilterPermissions.numAvailablePermission,
      permission: [...personFilterPermissions.permission, 'attributes'],
    };
  }

  if (personPerm.canReadConsentFilter) {
    personFilterPermissions = {
      ...personFilterPermissions,
      numAvailablePermission: ++personFilterPermissions.numAvailablePermission,
      permission: [...personFilterPermissions.permission, 'consentTypes'],
    };
  }

  return personFilterPermissions;
};

export const checkOrganizationFilterPermission = (
  orgPerm: OrganizationClientPermissions | undefined
) => {
  let orgFilterPermissions: FilterPermissionData = {
    numAvailablePermission: 0,
    permission: [],
  };

  // No permission at all or not permission to read "organization-facets-read"
  if (!orgPerm || !orgPerm.canReadFacets) {
    return orgFilterPermissions;
  }

  if (orgPerm.canReadTypeFilter) {
    orgFilterPermissions = {
      ...orgFilterPermissions,
      numAvailablePermission: ++orgFilterPermissions.numAvailablePermission,
      permission: [...orgFilterPermissions.permission, 'organizationTypes'],
    };
  }

  if (orgPerm.canReadCityFilter) {
    orgFilterPermissions = {
      ...orgFilterPermissions,
      numAvailablePermission: ++orgFilterPermissions.numAvailablePermission,
      permission: [...orgFilterPermissions.permission, 'cities'],
    };
  }

  if (orgPerm.canReadAttributeFilter) {
    orgFilterPermissions = {
      ...orgFilterPermissions,
      numAvailablePermission: ++orgFilterPermissions.numAvailablePermission,
      permission: [...orgFilterPermissions.permission, 'attributes'],
    };
  }

  return orgFilterPermissions;
};

export const prepareSettingsForUpdatePreferenceMutation = (
  person: PersonData | undefined,
  itemsToUpdate: ItemsToUpdate,
  type: EntityFilterTypeDataEnum,
  personFacets?: PersonPreferenceFacetData,
  organizationFacets?: OrganizationPreferenceFacetData
): UpdatePreferenceAction => {
  if (!person) {
    return {};
  }

  // Getting current values filters
  const parsedPersonFilters = convertPersonFilterValue(personFacets);
  const parsedOrganizationFilters =
    convertOrganizationFilterValue(organizationFacets);
  const personFilters = extractPersonFilters(parsedPersonFilters);
  const organizationFilters = extractOrganizationFilters(
    parsedOrganizationFilters
  );

  const personUpdatedFacets = personFacets
    ? {
        personTypes:
          itemsToUpdate.entity === 'personTypes'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : personFilters.get('personTypes')?.map(({ oid }) => oid ?? '') ??
              [],
        specialities:
          itemsToUpdate.entity === 'specialities'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : personFilters.get('specialities')?.map(({ oid }) => oid ?? '') ??
              [],
        specialityGroups:
          itemsToUpdate.entity === 'specialityGroups'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : personFilters
                .get('specialityGroups')
                ?.map(({ oid }) => oid ?? '') ?? [],
        employeeFunctions:
          itemsToUpdate.entity === 'employeeFunctions'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : personFilters
                .get('employeeFunctions')
                ?.map(({ oid }) => oid ?? '') ?? [],
        attributes:
          itemsToUpdate.entity === 'attributes' &&
          type === EntityFilterTypeDataEnum.PERSON
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : personFilters.get('attributes')?.map(({ oid }) => oid ?? '') ??
              [],
        consentTypes:
          itemsToUpdate.entity === 'consentTypes'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : personFilters.get('consentTypes')?.map(({ oid }) => oid ?? '') ??
              [],
      }
    : undefined;

  const organizationUpdatedFacets = organizationFacets
    ? {
        organizationTypes:
          itemsToUpdate.entity === 'organizationTypes'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : organizationFilters
                .get('organizationTypes')
                ?.map(({ oid }) => oid ?? '') ?? [],
        attributes:
          itemsToUpdate.entity === 'attributes' &&
          type === EntityFilterTypeDataEnum.ORGANIZATION
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : organizationFilters
                .get('attributes')
                ?.map(({ oid }) => oid ?? '') ?? [],
        cities:
          itemsToUpdate.entity === 'cities'
            ? itemsToUpdate.selectedItems.map(({ oid }) => oid ?? '')
            : organizationFilters
                .get('cities')
                ?.map(({ name }) => name ?? '') ?? [],
      }
    : undefined;

  return {
    personId: {
      value: person?.oid ?? '',
    },
    facets: {
      value: {
        personFacets: personUpdatedFacets,
        organizationFacets: organizationUpdatedFacets,
      },
    },
  };
};
