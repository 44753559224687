import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverviewCard } from '@ysura/common';

import { ResponsiveSkeleton } from '@/components/Activity/ActivityStyles';

export const ActivitySkeletonCard = () => {
  return (
    <OverviewCard testId="activity-skeleton-card">
      <StyledStack>
        <Header>
          <HeaderTitle>
            <Skeleton variant="circular" width={20} height={20} />
            <Typography component="div" variant="subtitle2">
              <Skeleton width={64} height={22} />
            </Typography>
          </HeaderTitle>

          <Skeleton height={22} width={64} />
        </Header>

        <Typography component="div" variant="subtitle2">
          <ResponsiveSkeleton height={22} customWidth={'200px'} />
        </Typography>

        <Typography variant="caption">
          <ResponsiveSkeleton height={22} customWidth={'160px'} />
        </Typography>

        <TinyStack>
          <Typography variant="caption">
            <Skeleton width={120} height={18} />
          </Typography>
          <Typography variant="caption">
            <Skeleton width={120} height={18} />
          </Typography>
        </TinyStack>

        <Typography variant="caption">
          <Skeleton width={120} height={18} />
        </Typography>
      </StyledStack>
    </OverviewCard>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap-reverse',
  gap: theme.spacing(1),
}));

const HeaderTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const TinyStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));
