import { Theme } from '@mui/material';

type FinalizedPercentageProps = {
  plannedActivities?: number | null;
  finalizedActivities?: number | null;
};

export const getTouchpointPlanPercentage = ({
  plannedActivities,
  finalizedActivities,
}: FinalizedPercentageProps) => {
  if (!plannedActivities || !finalizedActivities) {
    return 0;
  }

  if (finalizedActivities >= plannedActivities) {
    return 100;
  }

  return (finalizedActivities / plannedActivities) * 100;
};

export const getTouchPointColor = (theme: Theme, name?: string) => {
  if (!name) {
    return theme.palette.primary.darker;
  }
  const TOUCHPOINT_COLORS: { [key: string]: string } = {
    visit: theme.palette.primary.darker,
    econtact: theme.palette.primary.darker,
    webinar: theme.palette.primary.main,
    email: theme.palette.primary.light,
    telephone: theme.palette.primary.light,
  };

  return TOUCHPOINT_COLORS[name] ?? theme.palette.primary.darker;
};
