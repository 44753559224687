import {
  PromotionalMaterialActivityTypeData,
  PromotionalMaterialBatchData,
  PromotionalMaterialData,
  PromotionalMaterialRuleData,
  TopicData,
} from '@ysura/common';

import { parseActivityTypeData } from '@/services/graphql/parsers';
import { parseTopicConnection } from '@/services/graphql/parsers/topic/topicParser';
import {
  convertedPromotionalMaterialType,
  convertedStockType,
} from '@/services/graphql/parsers/utils';
import {
  PromotionalMaterial,
  PromotionalMaterialActivityType,
  PromotionalMaterialBatch,
  PromotionalMaterialBatchConnection,
  PromotionalMaterialConnection,
  PromotionalMaterialEndDateRule,
  PromotionalMaterialMaximumQuantityPerActivityRule,
  PromotionalMaterialMaximumQuantityPerPeriodRule,
  PromotionalMaterialPeriodType,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parsePromotionalMaterial = (
  promotionalMaterial?: DeepPartial<PromotionalMaterial>
): PromotionalMaterialData => {
  return {
    id: promotionalMaterial?.id ?? '',
    oid: promotionalMaterial?.oid ?? '',
    type: convertedPromotionalMaterialType(promotionalMaterial?.type),
    name: promotionalMaterial?.name,
    signatureRequired: !!promotionalMaterial?.signatureRequired,
    topics: promotionalMaterial?.topics
      ? parseTopicConnection(promotionalMaterial?.topics)
      : undefined,
    batches: promotionalMaterial?.batches
      ? parseBatchConnection(promotionalMaterial?.batches)
      : undefined,
    rules: promotionalMaterial?.rules
      ? parsePromotionalMaterialRules(promotionalMaterial?.rules)
      : undefined,
    activityTypes:
      promotionalMaterial && promotionalMaterial?.activityTypes
        ? parsePromotionalMaterialActivityTypesData(
            promotionalMaterial.activityTypes
          )
        : undefined,
  };
};

export const parsePromotionalMaterialConnection = (
  promotionalMaterialConnection?: DeepPartial<PromotionalMaterialConnection>
): Array<PromotionalMaterialData> => {
  const rawPromotionalMaterials = promotionalMaterialConnection?.edges ?? [];
  const mappedPromotionalMaterials = rawPromotionalMaterials
    .map((edge) => edge?.node)
    .map((promotionalMaterial) =>
      promotionalMaterial
        ? parsePromotionalMaterial(promotionalMaterial)
        : undefined
    );

  return filterNonNull(mappedPromotionalMaterials);
};

export const parsePromotionalMaterialRules = (
  rules?: (
    | DeepPartial<
        | PromotionalMaterialMaximumQuantityPerActivityRule
        | PromotionalMaterialMaximumQuantityPerPeriodRule
        | PromotionalMaterialEndDateRule
      >
    | undefined
  )[]
): Array<PromotionalMaterialRuleData> => {
  const mappedRules: Array<PromotionalMaterialRuleData> = [];

  rules?.forEach((rule) => {
    if (rule?.__typename === 'PromotionalMaterialEndDateRule') {
      mappedRules.push({
        type: 'endDate',
        endDate: rule.endDate,
      });
    }
    if (
      rule?.__typename === 'PromotionalMaterialMaximumQuantityPerActivityRule'
    ) {
      mappedRules.push({
        type: 'maximumInActivity',
        maximumQuantity: rule.maximumQuantity,
      });
    }
    if (
      rule?.__typename === 'PromotionalMaterialMaximumQuantityPerPeriodRule'
    ) {
      if (rule.period?.type === PromotionalMaterialPeriodType.CURRENT_YEAR) {
        mappedRules.push({
          type: 'maximumInCurrentYear',
          maximumQuantity: rule.maximumQuantity,
        });
      }
      if (rule.period?.type === PromotionalMaterialPeriodType.CUSTOM_INTERVAL) {
        mappedRules.push({
          type: 'maximumInCurrentYear',
          maximumQuantity: rule.maximumQuantity,
          period: {
            startDate: rule.period.startDate,
            period: rule.period.period,
          },
        });
      }
    }
  });

  return mappedRules;
};

const parsePromotionalMaterialActivityTypesData = (
  activityTypes: DeepPartial<Array<PromotionalMaterialActivityType>>
): Array<PromotionalMaterialActivityTypeData> => {
  const parsedData = activityTypes.map((each) =>
    each
      ? {
          activityType: parseActivityTypeData(each.activityType),
          stockTypes: each.stockTypes
            ? filterNonNull(
                each.stockTypes.map((type) => convertedStockType(type))
              )
            : [],
        }
      : null
  );

  return filterNonNull(parsedData);
};
const parseBatchData = (
  batch?: DeepPartial<PromotionalMaterialBatch>
): PromotionalMaterialBatchData => {
  return {
    oid: batch?.oid,
    name: batch?.name ?? undefined,
    expiryDate: batch?.expiryDate ?? undefined,
  };
};

const parseBatchConnection = (
  batches?: DeepPartial<PromotionalMaterialBatchConnection>
): Array<TopicData> => {
  const batchesEdges = batches?.edges ?? [];
  const mapped = batchesEdges
    .map((edge) => edge?.node ?? null)
    .map((each) => (each ? parseBatchData(each) : null));

  return filterNonNull(mapped);
};
