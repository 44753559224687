import { MediaData } from '@ysura/common';
import { useEffect, useState } from 'react';

import { useInteraction } from '@/hooks';
import { useGetMediaByIdLazyQuery } from '@/services/graphql/hooks';
import { parseMediaData } from '@/services/graphql/parsers';

import { MediaSkeletonCard } from '../MediaSkeletonCard';
import { MediaCard, MediaCardProps } from './MediaCard';

interface InteractionMediaCardProps extends MediaCardProps {
  isViewedStatusUpdatedOnOpen?: boolean;
  isSharingAvailable?: boolean;
}

export const InteractionMediaCard = (
  initialProps: InteractionMediaCardProps
) => {
  const {
    media,
    isViewedStatusUpdatedOnOpen,
    isSharingAvailable,
    onChangePresentationDialogState,
  } = initialProps;
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const [loadedMedia, setLoadedMedia] = useState<MediaData | undefined>(media);

  const { setMediaAsViewed, startSharingMedia } = useInteraction();

  const [
    getMediaDetails,
    { loading: isMediaDetailsLoading, data: mediaDetails },
  ] = useGetMediaByIdLazyQuery();

  useEffect(() => {
    if (loadedMedia?.id) {
      if (
        loadedMedia &&
        loadedMedia?.latestVersion?.document?.id &&
        loadedMedia?.latestVersion?.document?.contentLink
      ) {
        setIsMediaLoading(false);
      } else {
        // In this case, we probably come from a replayed cache scenario after a page reload
        // and have a media that is not yet fully loaded.
        getMediaDetails({ variables: { mediaId: loadedMedia?.id } });
      }
    }
  }, [getMediaDetails, loadedMedia, media?.id]);

  useEffect(() => {
    if (mediaDetails) {
      const mediaData = parseMediaData(mediaDetails?.media ?? undefined);

      setLoadedMedia(mediaData);
      setIsMediaLoading(false);
    }
  }, [mediaDetails]);

  const handleClickCard = () => {
    if (loadedMedia) {
      if (isViewedStatusUpdatedOnOpen) {
        setMediaAsViewed(loadedMedia);
      }

      if (isSharingAvailable) {
        startSharingMedia({ media: loadedMedia });
      } else {
        onChangePresentationDialogState?.(true);
      }
    }
  };

  return !isMediaLoading && !isMediaDetailsLoading && loadedMedia ? (
    <MediaCard
      {...initialProps}
      media={loadedMedia}
      onClickCard={handleClickCard}
    />
  ) : (
    <MediaSkeletonCard />
  );
};
