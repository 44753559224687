import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type UpdatePersonConsentItemsMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
  issuerPersonId: Types.Scalars['PersonId']['input'];
  actionAt: Types.Scalars['DateTime']['input'];
  consentItemsValues:
    | Array<Types.PersonConsentItemValueInput>
    | Types.PersonConsentItemValueInput;
}>;

export type UpdatePersonConsentItemsMutation = {
  __typename?: 'Mutation';
  updatePersonConsentItems?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type UpdatePersonConsentItemsMutationFn = Apollo.MutationFunction<
  UpdatePersonConsentItemsMutation,
  UpdatePersonConsentItemsMutationVariables
>;

/**
 * __useUpdatePersonConsentItemsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonConsentItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonConsentItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonConsentItemsMutation, { data, loading, error }] = useUpdatePersonConsentItemsMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      issuerPersonId: // value for 'issuerPersonId'
 *      actionAt: // value for 'actionAt'
 *      consentItemsValues: // value for 'consentItemsValues'
 *   },
 * });
 */
export function useUpdatePersonConsentItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonConsentItemsMutation,
    UpdatePersonConsentItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdatePersonConsentItemsMutation,
    UpdatePersonConsentItemsMutationVariables
  >(Operations.updatePersonConsentItems, options);
}
export type UpdatePersonConsentItemsMutationHookResult = ReturnType<
  typeof useUpdatePersonConsentItemsMutation
>;
export type UpdatePersonConsentItemsMutationResult =
  Apollo.MutationResult<UpdatePersonConsentItemsMutation>;
export type UpdatePersonConsentItemsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePersonConsentItemsMutation,
    UpdatePersonConsentItemsMutationVariables
  >;
