import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type ColumnTitleProps = {
  children: React.ReactNode;
};

export const ColumnTitle = ({ children }: ColumnTitleProps) => {
  return <Wrapper variant="subtitle2">{children}</Wrapper>;
};

const Wrapper = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
