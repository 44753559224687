import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddressData } from '@ysura/common';

import {
  getPostalCodeCityAddress,
  getStreetAddress,
} from '@/services/employment';

type AddressBoxProps = {
  address?: AddressData;
  isTextDisabled?: boolean;
};

export const AddressBox = ({
  address,
  isTextDisabled = false,
}: AddressBoxProps) => {
  const street = getStreetAddress(address);
  const wholeAddress = getPostalCodeCityAddress(address);

  if (!street && !wholeAddress) {
    return null;
  }

  return (
    <StreetBox>
      <MapIconWrapper>
        <StyledLocationOnIcon />
      </MapIconWrapper>
      <Box>
        {street && (
          <StyledTypography
            data-testid="address-box-street"
            variant="caption"
            isDisabled={isTextDisabled}
          >
            {street}
          </StyledTypography>
        )}
        {wholeAddress && (
          <StyledTypography
            isDisabled={isTextDisabled}
            data-testid="address-box-whole-address"
            variant="caption"
          >
            {wholeAddress}
          </StyledTypography>
        )}
      </Box>
    </StreetBox>
  );
};

const StreetBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const MapIconWrapper = styled(Box)`
  display: flex;
`;

const StyledLocationOnIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: 16,
  marginTop: 2, // add margin to make icon look align with content
}));

interface StyledTypographyProps extends TypographyProps {
  isDisabled: boolean;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<StyledTypographyProps>(({ theme, isDisabled }) => ({
  color: isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
  display: 'block',
}));
