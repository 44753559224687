import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Label, MediaData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { MediaThumbnail } from '@/components/Media/MediaThumbnail';

type MediaCardProps = {
  media: MediaData;
  onSelectMedia: (media: MediaData) => void;
  isDisplayViewedLabel?: boolean;
};

export const MediaGridItem = ({
  media,
  onSelectMedia,
  isDisplayViewedLabel = false,
}: MediaCardProps) => {
  const { t } = useTranslation();

  const handleSelectMedia = () => {
    onSelectMedia?.(media);
  };

  return (
    <Card onClick={handleSelectMedia}>
      <CardActionArea>
        <CardContentStyle>
          <MediaThumbnail media={media} />

          <TypographyStyle variant="subtitle2">
            {media.fileName}
          </TypographyStyle>

          {isDisplayViewedLabel && (
            <Box>
              <Label
                variant="ghost"
                color={media.isViewed ? 'success' : 'default'}
              >
                {media.isViewed
                  ? t('components.mediaCard.viewed')
                  : t('components.mediaCard.notViewed')}
              </Label>
            </Box>
          )}
        </CardContentStyle>
      </CardActionArea>
    </Card>
  );
};

const CardContentStyle = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const TypographyStyle = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
