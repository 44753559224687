import { ActivityData } from '@ysura/common';
import { compareAsc } from 'date-fns';

/**
 * Sort the activities by putting first those that do not have a date
 * and sorting the others by start time
 */
export const sortActivities = (activities: Array<ActivityData>) => {
  if (!activities?.length || !Array.isArray(activities)) {
    return [];
  }

  const activitiesWithoutTime = activities.filter(
    (activity) => !activity?.startDateTime
  );

  const sortedActivitiesWithTime = activities
    .filter((activity) => activity?.startDateTime)
    .sort((a, b) =>
      compareAsc(
        new Date(a.startDateTime as string),
        new Date(b.startDateTime as string)
      )
    );

  return [...activitiesWithoutTime, ...sortedActivitiesWithTime];
};
