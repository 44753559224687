import { InteractionDetailPageLayout } from '@/components/InteractionDetailPageLayout';
import { PageHeaderSkeleton } from '@/components/PageHeader';
import { InteractionBackTopLayout } from '@/layouts';
import {
  ConsentSkeletonStaticBlock,
  MediaSkeletonStaticBlock,
  PromotionalMaterialSkeletonStaticBlock,
} from '@/views/Interaction/Overview';

export const F2FInteractionSkeleton = () => {
  return (
    <InteractionBackTopLayout>
      <InteractionDetailPageLayout
        header={<PageHeaderSkeleton />}
        firstColumn={<MediaSkeletonStaticBlock />}
        middleColumn={<ConsentSkeletonStaticBlock />}
        lastColumn={<PromotionalMaterialSkeletonStaticBlock />}
      />
    </InteractionBackTopLayout>
  );
};
