import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  ActivityStatusDataEnum,
  MediaData,
  NoItemPlaceholder,
  ReactionDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { TouchpointMediaCard } from '@/components/Media';

import { ColumnTitle } from './ColumnTitle';
import { DialogColumn } from './DialogColumn';
import { MediaEditAccordion } from './MediaEditAccordion';

type SelectedMediaProps = {
  activity: ActivityData;
  selectedMediaList: Array<MediaData>;
  onRemoveMediaInSelected: (val: MediaData) => void;
  onChangeReaction: (mediaId: string, reaction: ReactionDataEnum) => void;
};

export const SelectedMedia = ({
  activity,
  selectedMediaList,
  onRemoveMediaInSelected,
  onChangeReaction,
}: SelectedMediaProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const mainContent = (
    <>
      {selectedMediaList.length === 0 && (
        <NoItemPlaceholder>
          {t('components.touchpointManagement.noSelectedMedia')}
        </NoItemPlaceholder>
      )}
      {selectedMediaList.length > 0 && (
        <BigGapListWrapper>
          {selectedMediaList.map((media) => (
            <TouchpointMediaCard
              key={media?.id}
              media={media}
              variant="short"
              isDisplayReactions={
                activity.status === ActivityStatusDataEnum.CLOSED
              }
              isMediaClickable={false}
              isDisplayDeleteButton={true}
              isReactionsEditable={
                activity.status === ActivityStatusDataEnum.CLOSED
              }
              onChangeReaction={onChangeReaction}
              onDeleteMedia={() => onRemoveMediaInSelected(media)}
            />
          ))}
        </BigGapListWrapper>
      )}
    </>
  );

  if (isMobile) {
    return (
      <MediaEditAccordion
        title={t('components.touchpointManagement.selectedMedia', {
          filteredCount: selectedMediaList.length,
        })}
      >
        {mainContent}
      </MediaEditAccordion>
    );
  }

  return (
    <DialogColumn>
      <ColumnTitle>
        {t('components.touchpointManagement.selectedMedia', {
          filteredCount: selectedMediaList.length,
        })}
      </ColumnTitle>

      {mainContent}
    </DialogColumn>
  );
};

const BigGapListWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),

  // Add this to make border show up
  padding: '2px',
}));
