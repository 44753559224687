import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Loader } from '@ysura/common';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { DIALOG } from '@/config/layout';

type PreviewDialogProps = {
  isOpen: boolean;
  title: string;
  previewContent: string;
  onClosePreviewDialog: VoidFunction;
  onSubmit: VoidFunction;
  isContentLoading: boolean;
  isSubmitLoading: boolean;
};

export const PreviewDialog = ({
  isOpen,
  title,
  previewContent,
  onClosePreviewDialog,
  onSubmit,
  isContentLoading,
  isSubmitLoading,
}: PreviewDialogProps) => {
  const { t } = useTranslation();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (isOpen && previewContent && iframe) {
      const doc = iframe?.contentWindow?.document;
      doc?.open();
      doc?.write(previewContent);
      doc?.close();
    }
  }, [isOpen, previewContent]);

  return (
    <ActionDialog
      isOpen={isOpen}
      maxWidth={'lg'}
      title={title}
      primaryButtonText={t('components.common.submit')}
      secondaryButtonText={t('components.common.back')}
      isPrimaryButtonDisabled={isSubmitLoading || isContentLoading}
      isLoading={isSubmitLoading}
      onClickPrimaryButton={onSubmit}
      onClickSecondaryButton={onClosePreviewDialog}
    >
      {isContentLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <ContentBox>
        <PreviewForm ref={iframeRef} data-testid="preview-form" />
      </ContentBox>
    </ActionDialog>
  );
};

const PreviewForm = styled('iframe')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: 'none',
  height: '100%',
  width: '100%',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    height: `calc(100% - ${DIALOG.MOBILE.FLOATING_BUTTON_HEIGHT})`,
  },
}));

const LoaderContainer = styled(Box)({
  display: 'grid',
  placeContent: 'center',
  height: '100%',
});
