import { PersonConsentLifecycleStateDataEnum } from '@ysura/common';

import { createEnumMapperFunction } from '@/services/graphql/mappers/utils';
import { PersonConsentLifecycleState } from '@/services/graphql/types';

export const mapperPersonConsentLifecycleStateDataEnum =
  createEnumMapperFunction(
    PersonConsentLifecycleState,
    PersonConsentLifecycleStateDataEnum
  );
