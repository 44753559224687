import { Button as MuiButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaData, NoItemPlaceholder } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaPresentationDialog } from '@/components/Media';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useCurrentUser, useInteraction } from '@/hooks';
import { isUndefOrEmptyArray } from '@/utils/arrays';
import { MediaListDialog } from '@/views/Interaction/ExpandView/Media';
import { MediaCardList } from '@/views/Interaction/Overview/Media';

import { mergePlannedAndViewedMediaAndSort } from './helper';

type MediaStaticBlockProps = {
  media: Array<MediaData> | undefined;
  isInteractionDone?: boolean;
  isReactionEditable?: boolean;
  isDisplayViewedLabel?: boolean;
  isSharingAvailable?: boolean;
  isInRemoteInteraction?: boolean;
};

export const MediaStaticBlock = ({
  media,
  isInteractionDone = false,
  isReactionEditable = false,
  isDisplayViewedLabel = false,
  isSharingAvailable = false,
  isInRemoteInteraction = false,
}: MediaStaticBlockProps) => {
  const { t } = useTranslation();
  const { viewedMedia, startSharingMedia } = useInteraction();
  const { currentUser } = useCurrentUser();

  const [isMediaListDialogOpen, setIsMediaListDialogOpen] = useState(false);
  const [isMediaPresentationDialogOpen, setIsMediaPresentationDialogOpen] =
    useState(false);
  const [presentationMediaWithDocument, setPresentationMediaWithDocument] =
    useState<MediaData | null>(null);

  const handleChangePresentationDialogState = (isOpen: boolean) => {
    setIsMediaPresentationDialogOpen(isOpen);
  };

  const handleChangePresentationMedia = (media: MediaData) => {
    startSharingMedia({ media });
    setPresentationMediaWithDocument(media);
  };

  const viewAllHandler = () => {
    setIsMediaListDialogOpen(true);
  };

  /**
   * For ongoing or planned interactions we show all media but for the summary page
   * we only show viewed media (which was filtered further up).
   */
  const sortedMedia = isInteractionDone
    ? media
    : mergePlannedAndViewedMediaAndSort(media, viewedMedia);

  // This is View all button at top right conner. It render in all place except in remote interaction room and summary page
  const buttonTextViewAll = currentUser?.permissions?.activity
    ?.canEditActivityMedia &&
    !isInteractionDone &&
    !isInRemoteInteraction && (
      <TextButton variant="text" onClick={viewAllHandler}>
        {t('pages.interaction.common.viewAll')}
      </TextButton>
    );

  return (
    <>
      <OverviewStaticBlock
        title={t('pages.interaction.common.mediaColumnTitle')}
        subtitle={buttonTextViewAll}
        spacing={isSharingAvailable ? 'dense' : 'spacious'}
      >
        <MediumStack>
          {isUndefOrEmptyArray(sortedMedia) ? (
            <NoItemPlaceholder>
              {t('components.mediaCard.noMedia')}
            </NoItemPlaceholder>
          ) : (
            <MediaCardList
              mediaList={sortedMedia as Array<MediaData>}
              isMediaClickable={!isReactionEditable}
              isDisplayReactions={isReactionEditable}
              isReactionsEditable={isReactionEditable}
              isDisplayViewedLabel={isDisplayViewedLabel}
              isSharingAvailable={isSharingAvailable}
              onChangeMediaWithDocument={handleChangePresentationMedia}
              onChangePresentationDialogState={
                handleChangePresentationDialogState
              }
            />
          )}

          {/* Button view all at bottom only visible in remote interaction */}
          {isInRemoteInteraction && (
            <Button variant="outlined" onClick={viewAllHandler}>
              {t('pages.interaction.common.viewAll')}
            </Button>
          )}
        </MediumStack>
      </OverviewStaticBlock>

      {!isInteractionDone && (
        <MediaListDialog
          shouldUpdateMediaViewStatus
          isOpen={isMediaListDialogOpen}
          isDisplayViewedLabel={isDisplayViewedLabel}
          isSharingAvailable={isSharingAvailable}
          onClose={() => setIsMediaListDialogOpen(false)}
          onChangeMediaWithDocument={handleChangePresentationMedia}
          onChangePresentationDialogState={handleChangePresentationDialogState}
        />
      )}

      {isMediaPresentationDialogOpen && (
        <MediaPresentationDialog
          isOpen={isMediaPresentationDialogOpen}
          media={presentationMediaWithDocument}
          isInInteraction={true}
          onClose={() => handleChangePresentationDialogState(false)}
        />
      )}
    </>
  );
};

const TextButton = styled(MuiButton)({
  textTransform: 'uppercase',
  paddingTop: 0,
  paddingBottom: 0,
});

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});

const MediumStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));
