import { gql } from '@apollo/client';

export const getDeliveryTimeFrameList = gql`
  query getDeliveryTimeFrameList {
    deliveryTimeFrames(filter: { state: { _eq: ACTIVE } }) {
      edges {
        node {
          id
          oid
          name
        }
      }
    }
  }
`;
