import { gql } from '@apollo/client';

export const updateActivity = gql`
  mutation updateActivity(
    $oid: ActivityId!
    $actions: [UpdateActivityAction!]!
  ) {
    updateActivity(input: { oid: $oid, actions: $actions }) {
      id
      oid
    }
  }
`;
