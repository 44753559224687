import {
  DoubleListItemType,
  EntityFilterTypeDataEnum,
  OrganizationFilterTypeData,
  PersonFilterTypeData,
  PreferenceFilterItemData,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DoubleListDialog } from '@/components/DoubleList';
import { PreferenceFacetItemCard } from '@/components/Settings/common/PreferenceFacetItemCard';
import { FilterTypeData } from '@/components/Settings/helper';
import {
  useGetAttributesQuery,
  useGetConsentTypesQuery,
  useGetEmployeeFunctionsQuery,
  useGetOrganizationTypesQuery,
  useGetPersonTypesQuery,
  useGetPostalCodeCitiesQuery,
  useGetSpecialitiesQuery,
  useGetSpecialityGroupsQuery,
} from '@/services/graphql/hooks/generated';
import {
  parseAttributes,
  parseConsentTypes,
  parseEmployeeFunctionsConnection,
  parseOrganizationTypeConnection,
  parsePersonTypes,
  parsePostalCodeCityConnection,
  parseSpecialities,
  parseSpecialityGroups,
} from '@/services/graphql/parsers';
import { AttributeUsageType } from '@/services/graphql/types';

export type EditSettingDialogProps = {
  isOpen: boolean;
  isUpdating?: boolean;
  aggregate: EntityFilterTypeDataEnum;
  entity: FilterTypeData;
  displayName: string;
  selectedItems: DoubleListItemType<PreferenceFilterItemData>[];
  onCancel: VoidFunction;
  onSave: (itemsToUpdate: ItemsToUpdate) => void;
};

export type ItemsToUpdate = {
  entity: PersonFilterTypeData | OrganizationFilterTypeData;
  selectedItems: PreferenceFilterItemData[];
};

export const EditSettingDialog = ({
  isOpen = true,
  isUpdating = false,
  aggregate,
  entity,
  displayName,
  selectedItems,
  onCancel,
  onSave,
}: EditSettingDialogProps) => {
  const { t } = useTranslation();

  // Query
  const [availableItems, setAvailableItems] = useState<
    DoubleListItemType<PreferenceFilterItemData>[]
  >([]);

  // PersonTypes
  const { loading: isLoadingAllPersonTypes } = useGetPersonTypesQuery({
    skip: entity !== 'personTypes',
    onCompleted: (data) => {
      const parsedPersonTypes = data?.personTypes
        ? parsePersonTypes(data?.personTypes)
        : [];

      //TODO Refactor to a generic function
      const availableParsedItems: DoubleListItemType<PreferenceFilterItemData>[] =
        parsedPersonTypes.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [];

      setAvailableItems(availableParsedItems);
    },
  });

  // EmployeeFunctions
  const { loading: isLoadingAllEmployeeFunctions } =
    useGetEmployeeFunctionsQuery({
      skip: entity !== 'employeeFunctions',
      onCompleted: (data) => {
        const parsedEmployeeFunctions = data?.employeeFunctions
          ? parseEmployeeFunctionsConnection(data?.employeeFunctions)
          : [];

        const availableParsedItems =
          parsedEmployeeFunctions.map(({ id, oid, name }) => {
            return {
              id: id ?? '',
              target: {
                id: id ?? '',
                oid: oid ?? '',
                name: name ?? '',
              },
            };
          }) ?? [];

        setAvailableItems(availableParsedItems);
      },
    });

  // specialityGroup
  const { loading: isLoadingAllSpecialityGroup } = useGetSpecialityGroupsQuery({
    skip: entity !== 'specialityGroups',
    onCompleted: (data) => {
      const parsedSpecialityGroup = data?.specialityGroups
        ? parseSpecialityGroups(data?.specialityGroups)
        : [];

      const availableParsedItems =
        parsedSpecialityGroup.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [];

      setAvailableItems(availableParsedItems);
    },
  });

  // Speciality
  const { loading: isLoadingAllSpecialities } = useGetSpecialitiesQuery({
    skip: entity !== 'specialities',
    onCompleted: (data) => {
      const parsedSpecialities = data?.specialities
        ? parseSpecialities(data?.specialities)
        : [];

      const availableParsedItems =
        parsedSpecialities.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [];

      setAvailableItems(availableParsedItems);
    },
  });

  // Attributes
  const { loading: isLoadingAllAttributes } = useGetAttributesQuery({
    skip: entity !== 'attributes',
    variables: {
      usageType:
        aggregate === EntityFilterTypeDataEnum.PERSON
          ? AttributeUsageType.PERSON
          : AttributeUsageType.ORGANIZATION,
    },
    onCompleted: (data) => {
      const parsedAttributes = data?.attributes
        ? parseAttributes(data?.attributes)
        : [];

      const availableParsedItems =
        parsedAttributes.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [];

      setAvailableItems(availableParsedItems);
    },
  });

  // Consent Type
  const { loading: isLoadingAllConsentTypes } = useGetConsentTypesQuery({
    skip: entity !== 'consentTypes',
    onCompleted: (data) => {
      const parsedConsentTypes = data?.consentTypes
        ? parseConsentTypes(data?.consentTypes)
        : [];

      const availableParsedItems =
        parsedConsentTypes.map(({ id, oid, name }) => {
          return {
            id: id ?? '',
            target: {
              id: id ?? '',
              oid: oid ?? '',
              name: name ?? '',
            },
          };
        }) ?? [];

      setAvailableItems(availableParsedItems);
    },
  });

  // Organization Type
  const { loading: isLoadingAllOrganizationTypes } =
    useGetOrganizationTypesQuery({
      skip: entity !== 'organizationTypes',
      onCompleted: (data) => {
        const parsedOrganizationTypes = data?.organizationTypes
          ? parseOrganizationTypeConnection(data?.organizationTypes)
          : [];

        const availableParsedItems =
          parsedOrganizationTypes.map(({ id, oid, name }) => {
            return {
              id: id ?? '',
              target: {
                id: id ?? '',
                oid: oid ?? '',
                name: name ?? '',
              },
            };
          }) ?? [];

        setAvailableItems(availableParsedItems);
      },
    });

  // Organization Type
  const { loading: isLoadingAllCities } = useGetPostalCodeCitiesQuery({
    skip: entity !== 'cities',
    onCompleted: (data) => {
      const parsedCities = data?.postalCodeCities
        ? parsePostalCodeCityConnection(data?.postalCodeCities)
        : [];

      const availableParsedItems =
        parsedCities
          .filter(
            (item, index, self) =>
              index === self.findIndex((p) => p.city === item.city)
          )
          .map(({ city }) => {
            return {
              id: city ?? '',
              target: {
                id: city ?? '',
                oid: city ?? '',
                name: city ?? '',
              },
            };
          }) ?? [];

      setAvailableItems(availableParsedItems);
    },
  });

  const handleSave = (selectedItems: PreferenceFilterItemData[]) => {
    onSave({
      entity,
      selectedItems,
    });
  };

  return (
    <DoubleListDialog
      isOpen={isOpen}
      isLoading={
        isLoadingAllPersonTypes ||
        isLoadingAllEmployeeFunctions ||
        isLoadingAllSpecialities ||
        isLoadingAllSpecialityGroup ||
        isLoadingAllAttributes ||
        isLoadingAllConsentTypes ||
        isLoadingAllOrganizationTypes ||
        isLoadingAllCities ||
        isUpdating
      }
      title={t(`components.settings.edit.title.${entity}`)}
      titleForAvailableCount={t(
        'components.settings.edit.titleForAvailableCount',
        { entity: displayName }
      )}
      titleForSelectedCount={t(
        'components.settings.edit.titleForSelectedCount',
        { entity: displayName }
      )}
      emptySelectedInfo={t('components.settings.edit.noItemsAdded')}
      testId="person-facets-settings"
      handleSave={handleSave}
      handleCancel={onCancel}
      renderSelectedItem={(facet: PreferenceFilterItemData) => (
        <PreferenceFacetItemCard key={facet.id} name={facet.name} />
      )}
      renderAvailableItem={(
        facet: PreferenceFilterItemData,
        query?: string
      ) => (
        <PreferenceFacetItemCard
          key={facet.id}
          name={facet.name}
          query={query}
        />
      )}
      preSelectedItems={selectedItems}
      initialAvailableItems={availableItems}
      onSearch={(query: string, item: PreferenceFilterItemData) =>
        item.name?.toLowerCase()?.includes(query.toLowerCase())
      }
    />
  );
};
