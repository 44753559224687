import { gql } from '@apollo/client';

export const getPersonById = gql`
  query getPersonById($personId: ID!, $employeesLimit: Int = 1) {
    person(id: $personId) {
      id
      oid
      firstName
      lastName
      prefixAcademicTitle {
        name
        state
      }
      postfixAcademicTitle {
        name
        state
      }
      customerReference
      gender
      birthday
      state
      employments(
        first: $employeesLimit
        filter: { state: { _eq: ACTIVE } }
        orderBy: { sortCode: ASC }
      ) {
        filteredCount
        edges {
          node {
            id
            oid
            sortCode
            function {
              name
            }
            department {
              id
              name
            }
            communicationData {
              edges {
                node {
                  communicationDataType {
                    baseType
                    name
                  }
                  value
                }
              }
            }
            addresses(
              filter: {
                addressType: { _eq: STANDARD }
                address: { state: { _eq: ACTIVE } }
              }
            ) {
              edges {
                node {
                  addressType
                  address {
                    addressLine1
                    addressLine2
                    addressLine3
                    city
                    postalCode
                    state
                  }
                }
              }
            }
            organization {
              id
              oid
              name
              addresses(
                filter: {
                  addressType: { _eq: STANDARD }
                  address: { state: { _eq: ACTIVE } }
                }
              ) {
                edges {
                  node {
                    addressType
                    address {
                      addressLine1
                      addressLine2
                      addressLine3
                      city
                      postalCode
                      state
                    }
                  }
                }
              }
              communicationData {
                edges {
                  node {
                    communicationDataType {
                      baseType
                      name
                    }
                    value
                  }
                }
              }
              rootOrganization {
                id
                oid
                name
              }
            }
          }
        }
      }
      personTypes {
        edges {
          node {
            name
          }
        }
      }
      specialities {
        edges {
          node {
            speciality {
              name
            }
          }
        }
      }
      specialityGroup {
        id
        oid
        name
      }
      promotionalMaterials {
        edges {
          node {
            givenQuantityInCurrentPeriod
            maximumQuantityInCurrentPeriod
            promotionalMaterial {
              id
              oid
            }
          }
        }
      }
      touchPointStatistics {
        touchPointGroup {
          id
          oid
          name
          state
        }
        currentPlan
        currentPlanExecution
      }
      communicationData {
        edges {
          node {
            communicationDataType {
              baseType
              name
            }
            value
          }
        }
      }
    }
  }
`;
