import { gql } from '@apollo/client';

export const generateRemoteInteractionCode = gql`
  mutation generateRemoteInteractionCode(
    $oid: ActivityId!
    $attendeeId: ActivityAttendeeId
  ) {
    generateRemoteInteractionCode(input: { oid: $oid, attendeeId: $attendeeId })
  }
`;
