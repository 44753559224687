import { RecentAccountsData } from '@ysura/common';

import {
  parseOrganizationConnection,
  parsePersonConnection,
} from '@/services/graphql/parsers';
import { RecentAccountHistory } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const parseRecentAccountsData = (
  data: DeepPartial<RecentAccountHistory> | null
): RecentAccountsData => {
  return {
    persons: parsePersonConnection(data?.persons),
    organizations: parseOrganizationConnection(data?.organizations),
  };
};
