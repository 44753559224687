import DeleteIcon from '@mui/icons-material/Delete';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MedicationIcon from '@mui/icons-material/Medication';
import {
  Box,
  Divider,
  IconButton as MuiIconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  IconWrapper,
  Label,
  PromotionalMaterialGroupData,
  PromotionalMaterialTypeDataEnum,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { getRequestedQuantity } from '@/views/Activity/Update/PromotionalMaterials/helper';

import { BatchInfo } from './BatchInfo';
import { InputQuantity } from './InputQuantity';
import { SelectQuantity } from './SelectQuantity';

type SelectedItemProps = {
  isLastItem: boolean;
  isRestrictedEdit?: boolean;
  selectedMaterial: PromotionalMaterialGroupData;
  isSigned: boolean;
  selectedGroupedPromotionalMaterials: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const SelectedItem = ({
  isRestrictedEdit = false,
  isLastItem,
  selectedMaterial,
  isSigned,
  selectedGroupedPromotionalMaterials,
  onChangePromats,
}: SelectedItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isProduct =
    selectedMaterial.promotionalMaterial?.type ===
    PromotionalMaterialTypeDataEnum.PRODUCT;

  const sampleIconBackgroundColor = isProduct
    ? theme.palette.info.dark
    : theme.palette.error.light;

  const labelColor = isSigned ? 'success' : 'warning';
  const labelText = isSigned
    ? t('pages.interaction.common.signatureCollected')
    : t('pages.interaction.common.signatureRequired');

  const totalQuantity = getRequestedQuantity(selectedMaterial);
  const availableQuantity = selectedMaterial.availableQuantityInCurrentActivity;

  // The label is:
  // - If there is a maximum quantity for the period, display e.g. Available: 1/2
  // - If there is not a maximum quantity for the period, display e.g. Available: 1
  const availableLabelContent =
    `${t('pages.interaction.common.available')}: ${availableQuantity ?? '-'}` +
    (selectedMaterial.maximumQuantityInCurrentPeriod
      ? `/${selectedMaterial.maximumQuantityInCurrentPeriod}`
      : '');

  const promotionalMaterial = selectedMaterial.promotionalMaterial;
  const isNotAllowedToRemove =
    (promotionalMaterial?.signatureRequired && isSigned && isRestrictedEdit) ??
    false;

  const handleDeselectMaterial = () => {
    onChangePromats?.(
      selectedGroupedPromotionalMaterials.filter(
        (material) =>
          material.promotionalMaterial?.oid !== promotionalMaterial?.oid
      )
    );
  };

  return (
    <>
      <Wrapper>
        <Row>
          <SampleWrapper>
            <SampleNameWrapper>
              <IconWrapper
                size={20}
                backgroundColor={sampleIconBackgroundColor}
              >
                <IconContainer>
                  {isProduct ? <MedicationIcon /> : <LibraryBooksIcon />}
                </IconContainer>
              </IconWrapper>

              <Typography variant="subtitle2">
                {promotionalMaterial?.name}
              </Typography>
            </SampleNameWrapper>

            {promotionalMaterial?.signatureRequired && isProduct && (
              <Box>
                <Label color={labelColor}>{labelText}</Label>
              </Box>
            )}
          </SampleWrapper>

          <IconButton
            color="error"
            disabled={isNotAllowedToRemove}
            onClick={handleDeselectMaterial}
          >
            <DeleteIcon />
          </IconButton>
        </Row>

        {isProduct &&
          selectedMaterial.batches.length > 0 &&
          selectedMaterial.batches.map((batch, index) => {
            return (
              <BatchBody key={index}>
                <BatchInfo batch={batch} />

                <SelectsWrapper>
                  <SelectQuantity
                    type="physical"
                    materialOid={promotionalMaterial?.oid ?? ''}
                    batch={batch}
                    availableQuantity={availableQuantity}
                    totalQuantity={totalQuantity ?? 0}
                    isDisabled={isNotAllowedToRemove}
                    selectedGroupedPromotionalMaterials={
                      selectedGroupedPromotionalMaterials
                    }
                    onChangePromats={onChangePromats}
                  />

                  <SelectQuantity
                    type="virtual"
                    materialOid={promotionalMaterial?.oid ?? ''}
                    batch={batch}
                    availableQuantity={availableQuantity}
                    totalQuantity={totalQuantity ?? 0}
                    isDisabled={isNotAllowedToRemove}
                    selectedGroupedPromotionalMaterials={
                      selectedGroupedPromotionalMaterials
                    }
                    onChangePromats={onChangePromats}
                  />
                </SelectsWrapper>
              </BatchBody>
            );
          })}

        {!isProduct && (
          <FlexEndRow>
            <InputQuantity
              materialOid={promotionalMaterial?.oid ?? ''}
              quantity={selectedMaterial.requestedQuantity ?? 0}
              promats={selectedGroupedPromotionalMaterials}
              onChangePromats={onChangePromats}
            />
          </FlexEndRow>
        )}

        <Row>
          {isProduct ? <Label>{availableLabelContent}</Label> : <Box />}

          <Typography variant="overline">{`${t(
            'pages.interaction.common.total'
          )}: ${totalQuantity}`}</Typography>
        </Row>
      </Wrapper>

      {!isLastItem && <Divider />}
    </>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const FlexEndRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'row',
});

const SampleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const SampleNameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  svg: {
    color: theme.palette.common.white,
    fontSize: '12px',
  },
}));

const IconButton = styled(MuiIconButton)(({ theme, disabled }) => ({
  color: disabled ? undefined : theme.palette.error.main,
}));

const BatchBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const SelectsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  maxWidth: '50%',

  [theme.breakpoints.down('md')]: {
    display: 'grid',
  },
}));
