import { Box, Skeleton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const TouchpointPlanSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.personView.touchpointPlan')}
      testId="touchpoint-plan-skeleton-static-block"
    >
      <Wrapper>
        <SmallStack>
          <Skeleton variant="circular" width={48} height={48} />
          <Skeleton width={50} height={18} />
        </SmallStack>
        <SmallStack>
          <Skeleton variant="circular" width={48} height={48} />
          <Skeleton width={50} height={18} />
        </SmallStack>
        <SmallStack>
          <Skeleton variant="circular" width={48} height={48} />
          <Skeleton width={50} height={18} />
        </SmallStack>
        <SmallStack>
          <Skeleton variant="circular" width={48} height={48} />
          <Skeleton width={50} height={18} />
        </SmallStack>
        <SmallStack>
          <Skeleton variant="circular" width={48} height={48} />
          <Skeleton width={50} height={18} />
        </SmallStack>
      </Wrapper>
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
