import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

export const CoOrganizerCardListSkeleton = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('pages.activity.coOrganizer.coOrganizerTitle')}
      testId="coOrganizers-list-skeleton"
      subtitle={
        <Typography variant="subtitle2">
          <Skeleton width={120} height={22} />
        </Typography>
      }
    >
      <Wrapper>
        <SmallStack>
          <Skeleton width={200} height={22} />
          <Typography variant="caption">
            <Skeleton width={80} height={18} />
          </Typography>
        </SmallStack>

        <Divider />

        <SmallStack>
          <Skeleton width={64} height={22} />
          <Typography variant="caption">
            <Skeleton width={80} height={18} />
          </Typography>
        </SmallStack>

        <Divider />

        <SmallStack>
          <Skeleton width={200} height={22} />
          <Typography variant="caption">
            <Skeleton width={80} height={18} />
          </Typography>
        </SmallStack>

        <Divider />

        <SmallStack>
          <Skeleton width={64} height={22} />
          <Typography variant="caption">
            <Skeleton width={80} height={18} />
          </Typography>
        </SmallStack>
      </Wrapper>
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  gap: theme.spacing(1),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
