import { AttendeeData } from '@ysura/common';
import { TFunction } from 'i18next';

import { getHcpUrl } from '@/utils/hostUtils';

type HcpUrlProps = {
  roomId: string;
  // hcp aka attendee, undefined for anonymous attendees
  hcp?: AttendeeData;
};

export const getHcpUrlAnon = () => {
  return getHcpUrl();
};

export const getHcpUrlWithDetails = ({ roomId, hcp }: HcpUrlProps) => {
  const hcpUrl = getHcpUrl();

  const combinedQueryParams = `?room=${roomId}${
    hcp ? `&attendee=${hcp.id}` : ''
  }`;

  return encodeURI(`${hcpUrl}${combinedQueryParams}`);
};

export const getAttendeeStatusMessage = (
  attendeeInteractionStep: 'waiting-room' | 'interaction' | undefined | null,
  personFullName: string,
  t: TFunction
) => {
  let attendeeStatusMessage = t(
    'pages.interaction.common.participantWaitingRoomStatus.notJoined',
    {
      fullName: personFullName,
    }
  );
  if (attendeeInteractionStep === 'waiting-room') {
    attendeeStatusMessage = t(
      'pages.interaction.common.participantWaitingRoomStatus.inWaitingRoom',
      {
        fullName: personFullName,
      }
    );
  } else if (attendeeInteractionStep === 'interaction') {
    attendeeStatusMessage = t(
      'pages.interaction.common.participantWaitingRoomStatus.inInteraction',
      {
        fullName: personFullName,
      }
    );
  }

  return attendeeStatusMessage;
};
