import { Grid } from '@mui/material';
import { getPersonFullNameWithTitle, PersonData } from '@ysura/common';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPageLayout } from '@/components/DetailPageLayout';
import { PageHeader, PageHeaderSkeleton } from '@/components/PageHeader';
import {
  PersonDataSkeletonStaticBlock,
  PersonDataStaticBlock,
} from '@/components/PersonData';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { errorConfigQueryLoadingError } from '@/hooks/useNotification';
import {
  useGetActivityTypeListQuery,
  useGetTouchPointGroupListQuery,
} from '@/services/graphql/hooks';
import {
  parseActivityTypeConnection,
  parseTouchpointGroupConnection,
} from '@/services/graphql/parsers';
import { hasAssignActivityTypePermission } from '@/services/permission';

import { CreateActivityDialog } from '../Activity/TouchpointManagement';
import { ConsentSkeletonStaticBlock, ConsentStaticBlock } from './Consents';
import {
  RelatedOrganizationsSkeletonStaticBlock,
  RelatedOrganizationsStaticBlock,
} from './RelatedOrganizations';
import { SamplesSkeletonStaticBlock, SamplesStaticBlock } from './Samples';
import {
  TouchpointPlanStaticBlock,
  TouchpointsHistoryStaticBlock,
  TouchpointsStaticBlock,
} from './Touchpoint';
import { TouchpointsHistorySkeletonStaticBlock } from './Touchpoint/TouchpoinsHistory';
import { TouchpointPlanSkeletonStaticBlock } from './Touchpoint/TouchpointPlan';
import { TouchpointsSkeletonStaticBlock } from './Touchpoint/Touchpoints/TouchpointsSkeletonStaticBlock';

type ActivePersonProps = {
  person: PersonData | null;
  isPageLoading: boolean;
};

export const ActivePerson = ({ person, isPageLoading }: ActivePersonProps) => {
  const [isCreateTouchpointDialogOpen, setIsCreateTouchpointDialogOpen] =
    useState(false);
  const { t } = useTranslation();

  const { data: activityTypeData, loading: activityTypeLoading } =
    useGetActivityTypeListQuery();
  const activityTypeList = parseActivityTypeConnection(
    activityTypeData?.activityTypes
  );
  const errorCfg = useRef(
    errorConfigQueryLoadingError(t('pages.personView.touchpointPlan'))
  );
  const {
    data,
    loading: isLoadingTouchpointGroupList,
    error: touchPointGroupListError,
    refetch,
  } = useGetTouchPointGroupListQuery({
    ...errorCfg.current.context,
    skip: Number(person?.touchPointStatistics?.length) > 0,
  });

  errorCfg.current.bannerOptions.button = {
    label: t('components.error.reload'),
    action: refetch,
  };

  const { currentUser } = useCurrentUser();
  const canCreateActivity =
    currentUser?.permissions?.activity?.canCreateActivity &&
    hasAssignActivityTypePermission(activityTypeList);

  const canReadActivity = currentUser?.permissions?.activity?.canReadActivity;

  const touchpointGroups = parseTouchpointGroupConnection(
    data?.touchPointGroups
  );

  const shouldShowSkeleton =
    isPageLoading ||
    isLoadingTouchpointGroupList ||
    activityTypeLoading ||
    !person;

  const handleCloseCreateTouchpointDialog = () => {
    setIsCreateTouchpointDialogOpen(false);
  };

  const pageHeader =
    canCreateActivity && canReadActivity ? (
      <PageHeader
        title={getPersonFullNameWithTitle(person)}
        pageHeaderType={t('pages.personView.persons')}
        buttonText={t('components.touchpointManagement.createTouchpoint')}
        buttonHandler={() => setIsCreateTouchpointDialogOpen(true)}
        buttonTestId={'create-activity-btn'}
      />
    ) : (
      <PageHeader
        title={getPersonFullNameWithTitle(person)}
        pageHeaderType={t('pages.personView.persons')}
      />
    );

  return (
    <>
      <DetailPageLayout
        headerComp={
          shouldShowSkeleton ? <PageHeaderSkeleton showSubtitle /> : pageHeader
        }
        firstColumnComp={
          <>
            <Grid item>
              {shouldShowSkeleton ? (
                <PersonDataSkeletonStaticBlock
                  title={'components.personDataCard.personData'}
                />
              ) : (
                <PersonDataStaticBlock person={person} />
              )}
            </Grid>
            <Grid item>
              {shouldShowSkeleton ? (
                <RelatedOrganizationsSkeletonStaticBlock />
              ) : (
                <RelatedOrganizationsStaticBlock person={person} />
              )}
            </Grid>
          </>
        }
        middleColumnComp={
          <>
            {/*We show it only if the person is a target person*/}
            {currentUser?.permissions?.person?.canReadTouchpointStatistics &&
              !!person?.touchPointStatistics?.length && (
                <>
                  <Grid item>
                    {shouldShowSkeleton || touchPointGroupListError ? (
                      <TouchpointPlanSkeletonStaticBlock />
                    ) : (
                      <TouchpointPlanStaticBlock
                        person={person}
                        touchpointGroups={touchpointGroups}
                      />
                    )}
                  </Grid>
                </>
              )}
            {canReadActivity && (
              <>
                <Grid item>
                  {shouldShowSkeleton ? (
                    <TouchpointsSkeletonStaticBlock />
                  ) : (
                    <TouchpointsStaticBlock />
                  )}
                </Grid>
                <Grid item>
                  {shouldShowSkeleton ? (
                    <TouchpointsHistorySkeletonStaticBlock />
                  ) : (
                    <TouchpointsHistoryStaticBlock />
                  )}
                </Grid>
              </>
            )}
          </>
        }
        lastColumnComp={
          <>
            <Grid item>
              {shouldShowSkeleton ? (
                <ConsentSkeletonStaticBlock />
              ) : (
                <ConsentStaticBlock person={person} />
              )}
            </Grid>
            {currentUser?.permissions?.person?.canReadSampleDrop && (
              <Grid item>
                {shouldShowSkeleton ? (
                  <SamplesSkeletonStaticBlock />
                ) : (
                  <SamplesStaticBlock person={person} />
                )}
              </Grid>
            )}
          </>
        }
      />

      <CreateActivityDialog
        isOpen={isCreateTouchpointDialogOpen}
        selectedAttendee={person ?? undefined}
        onClose={handleCloseCreateTouchpointDialog}
      />
    </>
  );
};
