import { Box } from '@mui/material';
import { FacetFilterData } from '@ysura/common';
import { ChangeEvent } from 'react';

import { MULTI_ITEM_FILTER_TYPES } from '@/components/GlobalSearch/utils/constants';

import { GenericDisplayCategory } from './GenericDisplayCategory';
import { MultiItemsDisplayCategory } from './MultiItemsDisplayCategory';

export type FilterChangeEvent = (
  event: ChangeEvent<HTMLInputElement>,
  attributeId: string
) => void;

export type FilterCategoryProps = {
  filterType: string;
  title: string;
  values: Array<FacetFilterData>;
  onChangeFilters?: FilterChangeEvent;
};

export const FilterCategory = ({
  filterType,
  title,
  values,
  onChangeFilters,
}: FilterCategoryProps) => {
  return values.length ? (
    <Box>
      {MULTI_ITEM_FILTER_TYPES.includes(filterType) ? (
        <>
          {values.map((value) => (
            <MultiItemsDisplayCategory
              key={value.id}
              title={value.name}
              value={value}
              onChangeFilters={onChangeFilters}
            />
          ))}
        </>
      ) : (
        <GenericDisplayCategory
          title={title}
          values={values}
          onChangeFilters={onChangeFilters}
        />
      )}
    </Box>
  ) : null;
};
