import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  EntityFilterTypeDataEnum,
  FilterItemData,
  FilterItemsData,
  ResetButton,
} from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { FilterCategory } from '@/components/FilterCategory';
import {
  FilterCardSkeleton,
  FilterScopeGroup,
} from '@/components/GlobalSearch';
import { FilterSettingsButton } from '@/components/GlobalSearch/components/FilterSettingsButton';
import {
  countSelectedItemFilters,
  resetSelectedFilterItems,
  updateSelectedFilterItems,
} from '@/components/GlobalSearch/utils';
import { useCurrentUser } from '@/hooks';
import { Scope } from '@/services/graphql/types';

type FilterCardProps = {
  entity: EntityFilterTypeDataEnum;
  filters: FilterItemsData;
  scope: Scope;
  onCloseDialog?: VoidFunction;
  onChangeScope?: (value: Scope) => void;
  onResetFilter?: (resetFilters: FilterItemsData) => void;
  onChangeFilters?: (newFilters: FilterItemsData) => void;
  testId?: string;
  isLoading?: boolean;
};
export const FilterCard = ({
  entity,
  filters,
  scope,
  onCloseDialog,
  onResetFilter,
  onChangeScope,
  onChangeFilters,
  testId = 'filter-card',
  isLoading,
}: FilterCardProps) => {
  // Hooks
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  // Handlers
  const handleChangeFilterData = (
    event: ChangeEvent<HTMLInputElement>,
    attributeId: string
  ) => {
    const newFilterData = updateSelectedFilterItems(
      attributeId,
      event.target.name,
      event.target.checked,
      filters
    );
    onChangeFilters?.(newFilterData);
  };

  const handleConfirmationDialogReset = () => {
    const newFilterData = resetSelectedFilterItems(filters);
    onResetFilter?.(newFilterData);
    setIsConfirmationDialogOpen(false);
  };

  const handleClickResetButton = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleClickSettingButton = () => {
    onCloseDialog?.();
  };

  const handleConfirmationDialogCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleChangeScopeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeScope?.(event.currentTarget.value as Scope);
  };

  const translateFilterTitle = (filterType: string) => {
    return t(`components.globalSearch.filter.type.${filterType}`);
  };

  // Variables
  const countItems = countSelectedItemFilters(filters);

  if (isLoading) {
    return (
      <Wrapper>
        <FilterCardSkeleton />
      </Wrapper>
    );
  }

  return (
    <Wrapper data-testid={testId}>
      <FilterHeader>
        <Text variant="h6" data-testid="filter-card-wrapper-title">
          {`${t('components.globalSearch.filter.filter')} (${countItems})`}
        </Text>

        <ButtonsWrapper>
          <FilterSettingsButton
            entity={entity}
            onClick={handleClickSettingButton}
          />

          <ResetButton
            disableButton={countItems === 0}
            color="inherit"
            testId="filter-reset-button"
            onClick={handleClickResetButton}
          />
        </ButtonsWrapper>
      </FilterHeader>

      <ScrollBox>
        {currentUser?.permissions?.common?.canSwitchSearchScope && (
          <FilterScopeGroup
            scope={scope}
            onChange={handleChangeScopeCheckbox}
          />
        )}

        {filters &&
          filters.map((filter: FilterItemData) => (
            <FilterCategory
              key={filter.filterType}
              filterType={filter.filterType}
              title={translateFilterTitle(filter.filterType)}
              values={filter.values}
              onChangeFilters={handleChangeFilterData}
            />
          ))}
      </ScrollBox>

      <ConfirmationDialog
        testId="filter-card-confirmation-dialog"
        title={t('components.globalSearch.filter.resetFilterSelection')}
        content={t('components.globalSearch.filter.resetFilterMessage')}
        isOpen={isConfirmationDialogOpen}
        primaryButton={{
          text: t('components.globalSearch.filter.reset'),
          onClickHandler: handleConfirmationDialogReset,
        }}
        secondaryButton={{
          text: t('components.globalSearch.filter.cancel'),
          onClickHandler: handleConfirmationDialogCancel,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: '100%',

  padding: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    borderRadius: 'unset',
    padding: 0,
    border: 'none',
    boxShadow: 'none',
  },
}));

const FilterHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
const ScrollBox = styled(Box)(({ theme }) => ({
  paddingLeftL: '5px',
  overflowY: 'auto',
  height: '70vh',
  padding: '0 5px',

  [theme.breakpoints.down('md')]: {
    height: '50vh',
  },
}));
