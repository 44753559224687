import AbcIcon from '@mui/icons-material/Abc';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonIcon from '@mui/icons-material/Person';
import QuizIcon from '@mui/icons-material/Quiz';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export type IconType =
  | 'TRANSLATION'
  | 'TEXT'
  | 'NUMERIC'
  | 'BOOLEAN'
  | 'DATE'
  | 'SINGLE_SELECTION'
  | 'MULTI_SELECTION'
  | 'PERSON'
  | 'ORGANIZATION'
  | 'DOCUMENT'
  | 'SURVEY_INSTANCE'
  | 'USER_PERSON';

export type DisplayAvatarProps = {
  text?: string;
  icon: IconType | string;
};

export const DisplayAvatar = ({ text = '', icon }: DisplayAvatarProps) => {
  const { t } = useTranslation();

  const displayIcon = () => {
    switch (icon) {
      case 'TEXT':
        return <AbcIcon fontSize="small" />;
      case 'NUMERIC':
        return <NumbersIcon fontSize="small" />;
      case 'BOOLEAN':
        return <ToggleOnIcon fontSize="small" />;
      case 'DATE':
        return <DateRangeIcon fontSize="small" />;
      case 'SINGLE_SELECTION':
        return <RadioButtonCheckedIcon fontSize="small" />;
      case 'MULTI_SELECTION':
        return <CheckBoxIcon fontSize="small" />;
      case 'PERSON':
        return <PersonIcon fontSize="small" />;
      case 'ORGANIZATION':
        return <ApartmentIcon fontSize="small" />;
      case 'DOCUMENT':
        return <DescriptionIcon fontSize="small" />;
      case 'SURVEY_INSTANCE':
        return <QuizIcon fontSize="small" />;
      case 'USER_PERSON':
        return <SupportAgentIcon fontSize="small" />;
      case 'TRANSLATION':
        return t(`components.settings.person.${text.toLowerCase()}`);
    }
  };

  return (
    <>
      {icon === 'TRANSLATION' ? (
        <StyledTranslation>{displayIcon()}</StyledTranslation>
      ) : (
        <StyledAvatar>{displayIcon()}</StyledAvatar>
      )}
    </>
  );
};

const StyledTranslation = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey['400'],
  textTransform: 'uppercase',
  borderRadius: '50%',
  fontSize: '1em',
  padding: theme.spacing(0.4),
}));

const StyledAvatar = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
});
