import { Box, styled, Typography } from '@mui/material';
import {
  ActivityStatusDataEnum,
  CommentData,
  NoItemPlaceholder,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { CommentParagraph } from '@/components/Comment';

type CommentAccordionFieldProps = {
  comments: CommentData[];
  status?: ActivityStatusDataEnum;
};

export const CommentsBlock = ({
  comments,
  status,
}: CommentAccordionFieldProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {comments.map((comment: CommentData) => (
        <CommentTypeWrapper key={comment.commentType?.oid}>
          <StyledTypography variant="subtitle2">
            {comment.commentType?.name ?? ''}
          </StyledTypography>
          {/*Max one comment per comment type*/}
          <CommentParagraph comment={comment} />
        </CommentTypeWrapper>
      ))}

      {comments.length === 0 && (
        <NoItemPlaceholder>
          {status === ActivityStatusDataEnum.PLANNED
            ? t('pages.activity.noComment.planned')
            : t('pages.activity.noComment.done')}
        </NoItemPlaceholder>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const CommentTypeWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  paddingBottom: theme.spacing(2),

  ':last-child': {
    borderBottom: '0px',
    paddingBottom: theme.spacing(0),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
