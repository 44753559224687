import { Grid } from '@mui/material';

import { ActivityDetailSkeletonCard } from '@/components/ActivityDetails';
import { AttendeesCardListSkeleton } from '@/components/Attendee/AttendeesCardListSkeleton';
import { CoOrganizerCardListSkeleton } from '@/components/CoOrganizer';
import { DetailPageLayout } from '@/components/DetailPageLayout';
import { MediaSkeletonStaticBlock } from '@/components/Media';
import { PageHeaderSkeleton } from '@/components/PageHeader';
import {
  CommentsSkeletonStaticBlock,
  KeyMessagesSkeletonStaticBlock,
} from '@/views/Activity';
import { TopicsStaticBlockSkeleton } from '@/views/Activity/Read';
import { PromotionalMaterialSkeletonStaticBlock } from '@/views/Interaction/Overview';

export const ActivitySkeleton = () => {
  return (
    <DetailPageLayout
      headerComp={<PageHeaderSkeleton showSubtitle />}
      firstColumnComp={
        <>
          <Grid item>
            <ActivityDetailSkeletonCard />
          </Grid>
          <Grid item>
            <AttendeesCardListSkeleton />
          </Grid>
          <Grid item>
            <CoOrganizerCardListSkeleton />
          </Grid>
        </>
      }
      middleColumnComp={
        <>
          <Grid item>
            <TopicsStaticBlockSkeleton />
          </Grid>
          <Grid item>
            <MediaSkeletonStaticBlock />
          </Grid>
          <Grid item>
            <PromotionalMaterialSkeletonStaticBlock />
          </Grid>
        </>
      }
      lastColumnComp={
        <>
          <Grid item>
            <KeyMessagesSkeletonStaticBlock />
          </Grid>
          <Grid item>
            <CommentsSkeletonStaticBlock />
          </Grid>
        </>
      }
    />
  );
};
