import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetSpecialityGroupsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSpecialityGroupsQuery = {
  __typename?: 'Query';
  specialityGroups: {
    __typename?: 'SpecialityGroupConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SpecialityGroupEdge';
      node?: {
        __typename?: 'SpecialityGroup';
        id: string;
        oid: string;
        name?: string | null;
      } | null;
    }> | null;
  };
};

/**
 * __useGetSpecialityGroupsQuery__
 *
 * To run a query within a React component, call `useGetSpecialityGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecialityGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecialityGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpecialityGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSpecialityGroupsQuery,
    GetSpecialityGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetSpecialityGroupsQuery,
    GetSpecialityGroupsQueryVariables
  >(Operations.getSpecialityGroups, options);
}
export function useGetSpecialityGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpecialityGroupsQuery,
    GetSpecialityGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetSpecialityGroupsQuery,
    GetSpecialityGroupsQueryVariables
  >(Operations.getSpecialityGroups, options);
}
export function useGetSpecialityGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSpecialityGroupsQuery,
    GetSpecialityGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetSpecialityGroupsQuery,
    GetSpecialityGroupsQueryVariables
  >(Operations.getSpecialityGroups, options);
}
export type GetSpecialityGroupsQueryHookResult = ReturnType<
  typeof useGetSpecialityGroupsQuery
>;
export type GetSpecialityGroupsLazyQueryHookResult = ReturnType<
  typeof useGetSpecialityGroupsLazyQuery
>;
export type GetSpecialityGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetSpecialityGroupsSuspenseQuery
>;
export type GetSpecialityGroupsQueryResult = Apollo.QueryResult<
  GetSpecialityGroupsQuery,
  GetSpecialityGroupsQueryVariables
>;
