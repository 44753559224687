import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(0.5),

  color: theme.palette.text.primary,

  '.MuiSvgIcon-root': {
    height: '1.125rem',
    width: '1.125rem',

    fill: theme.palette.text.secondary,
  },
}));
