import { gql } from '@apollo/client';

export const getActivityTypeList = gql`
  query getActivityTypeList {
    activityTypes(
      filter: {
        maxAttendeeCount: { _eq: 1 }
        minAttendeeCount: { _eq: 1 }
        baseType: { _ne: TEMPLATED_EMAIL }
      }
    ) {
      edges {
        node {
          id
          oid
          baseType
          maxAttendeeCount
          minAttendeeCount
          name
          schedulingType
          defaultDuration
          timeIsOptional
          topics(filter: { state: { _eq: ACTIVE } }) {
            edges {
              node {
                id
                oid
                name
              }
            }
          }
          dataPermissions {
            type
          }
        }
      }
    }
  }
`;
