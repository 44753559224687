import { Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  OrganizationCard,
  PersonCard,
  PersonCardSkeleton,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { LinkNoDecoration } from '@/components/LinkTo';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useGetRecentAccountsQuery } from '@/services/graphql/hooks';
import { parseRecentAccountsData } from '@/services/graphql/parsers/recentAcounts';
import { getPersonDetails } from '@/services/helper';
import { getOrganizationDetails } from '@/services/helper/organization';

export const RecentAccountsStaticBlock = () => {
  const { t } = useTranslation();

  const { data, loading } = useGetRecentAccountsQuery();

  const recentAccountsData = parseRecentAccountsData(
    data?.recentAccountHistory ?? null
  );

  const hasItems =
    (recentAccountsData.persons && recentAccountsData?.persons?.length > 0) ||
    (recentAccountsData.organizations &&
      recentAccountsData.organizations?.length > 0);

  return (
    <OverviewStaticBlock
      title={t('pages.homepage.recentAccounts')}
      testId={'recent-accounts-static-block'}
    >
      {loading && (
        <StyledStack>
          <PersonCardSkeleton />
          <PersonCardSkeleton />
          <PersonCardSkeleton />
        </StyledStack>
      )}
      {!loading && hasItems && (
        <>
          <StyledStack>
            {recentAccountsData?.persons?.map((person) => {
              const { id, personName, organizationName, addressName } =
                getPersonDetails(person);

              return (
                <LinkNoDecoration
                  key={id}
                  to={`/person/${id}`}
                  data-testid="person-card"
                >
                  <PersonCard
                    personName={personName}
                    organizationName={organizationName}
                    addressName={addressName}
                  />
                </LinkNoDecoration>
              );
            })}
          </StyledStack>
          {recentAccountsData?.persons?.length && <StyledDivider />}
          <StyledStack>
            {recentAccountsData?.organizations?.map((organization) => {
              const { id, name, rootOrganizationName, type, address } =
                getOrganizationDetails(organization);

              return (
                <LinkNoDecoration
                  key={id}
                  to={`/organization/${id}`}
                  data-testid="organization-card"
                >
                  <OrganizationCard
                    organizationName={name}
                    organizationType={type}
                    rootOrganizationName={rootOrganizationName}
                    addressName={address}
                  />
                </LinkNoDecoration>
              );
            })}
          </StyledStack>
        </>
      )}
    </OverviewStaticBlock>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1),
}));
