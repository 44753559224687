import { PersonConsentData } from '@ysura/common';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { errorConfigBannerDialog } from '@/hooks';
import { useResetPersonConsentMutation } from '@/services/graphql/hooks';

type ResetConsentComponentProps = {
  consent: PersonConsentData;
  onCloseDialog: VoidFunction;
  onConfirmAction: VoidFunction;
};

export const ResetConsentDialog = ({
  consent,
  onCloseDialog,
  onConfirmAction,
}: ResetConsentComponentProps) => {
  const { t } = useTranslation();

  const [resetPersonConsentMutation, { loading }] =
    useResetPersonConsentMutation();

  const handleResetConsent = () => {
    resetPersonConsentMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent?.oid ?? uuidv4(),
      },
      onCompleted: () => {
        onConfirmAction();
      },
      onError: () => {
        onCloseDialog();
      },
    });
  };

  return (
    <ConfirmationDialog
      testId="reset-consent"
      isOpen={true}
      title={t('components.consentDialog.resetConsent.title')}
      content={t('components.consentDialog.resetConsent.text')}
      primaryButton={{
        text: t('components.consentDialog.action.reset'),
        onClickHandler: handleResetConsent,
        isButtonLoading: loading,
      }}
      secondaryButton={{
        text: t('components.common.cancel'),
        onClickHandler: onCloseDialog,
      }}
    />
  );
};
