import { getCrmUiUrl } from '@/utils/hostUtils';

export const redirectBackToCrm = () => {
  localStorage.removeItem('fromCrm');

  const crmUrl = getCrmUiUrl();

  window.location.replace(crmUrl);
};

export const redirectToCrmEditActivity = (id: string | undefined) => {
  if (!id) {
    return;
  }

  localStorage.removeItem('fromCrm');

  const crmUrl = getCrmUiUrl();

  window.location.replace(
    `${crmUrl}/activityEdit?globalEditMode&personUsable=true&activityMode=update&activityId=${id}&force=true`
  );
};
