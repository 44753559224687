import {
  Box,
  Button as MuiButton,
  Dialog as MuiDialog,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useGenerateRemoteInteractionCodeMutation } from '@/services/graphql/hooks';
import { InvitationDialog } from '@/views/Interaction/Remote/Invitation';

import { InteractionInformationRoomInfo } from '../../Common/InteractionInformation';

type CallInfoDialogProps = {
  activity: ActivityData;
  isOpen: boolean;
  closeDialog: VoidFunction;
};

export const CallInfoDialog = ({
  activity,
  isOpen,
  closeDialog,
}: CallInfoDialogProps) => {
  const { t } = useTranslation();
  const [isOpenInvitationDialog, setOpenInvitationDialog] = useState(false);
  const [remoteInteractionCode, setRemoteInteractionCode] = useState<
    string | undefined
  >(undefined);
  const firstAttendee = activity?.attendees?.[0];
  const [
    generateRemoteInteractionCodeMutation,
    { loading: generateCodeLoading },
  ] = useGenerateRemoteInteractionCodeMutation();

  const handleOpenInvitationDialog = () => {
    // close CallInfoDialog first
    closeDialog();

    /**
     * setting a short timeout makes the transition between
     * closing and opening a Dialog nicer
     */
    setTimeout(() => {
      setOpenInvitationDialog(true);
    }, 150);
  };
  const handleGenerateCode = async () => {
    await generateRemoteInteractionCodeMutation({
      variables: {
        oid: activity.oid ?? '',
        attendeeId: firstAttendee?.oid,
      },
      onCompleted({ generateRemoteInteractionCode }) {
        setRemoteInteractionCode(generateRemoteInteractionCode ?? undefined);
      },
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isOpen}
        data-testid="call-info-dialog"
      >
        <ContentWrapper>
          <DialogTitleWrapper>
            <Typography variant="h6">
              {t('pages.interaction.remote.roomInformation')}
            </Typography>
            <Button variant="contained" color="primary" onClick={closeDialog}>
              {t('components.common.close')}
            </Button>
          </DialogTitleWrapper>

          {firstAttendee && activity?.id && (
            <InteractionInformationRoomInfo
              isInRemoteRoom
              attendee={firstAttendee}
              roomId={activity?.id}
              canSendInvitationEmail={
                !!activity?.activityType?.invitationTemplate
              }
              roomCode={remoteInteractionCode}
              isRoomCodeLoading={generateCodeLoading}
              onGenerateCodeAction={handleGenerateCode}
              onOpenInvitationDialog={handleOpenInvitationDialog}
            />
          )}
        </ContentWrapper>
      </Dialog>

      <InvitationDialog
        activity={activity}
        isOpen={isOpenInvitationDialog}
        attendee={firstAttendee}
        onClose={() => setOpenInvitationDialog(false)}
      />
    </>
  );
};

const Dialog = styled(MuiDialog)(() => ({
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      '.MuiDialog-paperFullWidth': {
        maxWidth: '100%',
      },
    },
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(2),
}));

const DialogTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});
