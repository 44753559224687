import { Person, PersonRemoveOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AttendeeData, getPersonFullNameWithTitle } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { TextOrLoader } from '@/components/Button/TextOrLoader';
import { CopyToClipBoardButton } from '@/components/CopyToClipBoard';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';

import {
  getAttendeeStatusMessage,
  getHcpUrlAnon,
  getHcpUrlWithDetails,
} from './helpers';

type InteractionInformationRoomInfoProps = {
  attendee: AttendeeData;
  roomId: string;
  roomCode?: string;
  canSendInvitationEmail: boolean;
  onGenerateCodeAction: VoidFunction;
  isRoomCodeLoading: boolean;
  onOpenInvitationDialog: VoidFunction;
  isInRemoteRoom?: boolean;
};

export const InteractionInformationRoomInfo = ({
  attendee,
  roomId,
  roomCode,
  canSendInvitationEmail,
  onGenerateCodeAction,
  onOpenInvitationDialog,
  isRoomCodeLoading,
  isInRemoteRoom = false,
}: InteractionInformationRoomInfoProps) => {
  const { t } = useTranslation();
  const { getParticipantInteractionStep, removeAttendeeFromInteraction } =
    useInteraction();

  const hcpUrlAnon = getHcpUrlAnon();
  const hcpUrl = getHcpUrlWithDetails({ hcp: attendee, roomId });
  const personFullName = getPersonFullNameWithTitle(attendee.person);

  const attendeeInteractionStep = attendee?.oid
    ? getParticipantInteractionStep(attendee.oid)
    : null;

  const attendeeStatusMessage = getAttendeeStatusMessage(
    attendeeInteractionStep,
    personFullName,
    t
  );

  return (
    <Wrapper data-testid="call-information">
      {personFullName && (
        <BigGapStack>
          {isInRemoteRoom && (
            <SecondaryTypography variant="subtitle2">
              {t('pages.interaction.remote.attendees')}
            </SecondaryTypography>
          )}
          <AttendeeLine>
            <LineBox>
              <Person fontSize="small" />
              <Typography variant="subtitle2">
                {attendeeStatusMessage}
              </Typography>
            </LineBox>
            {attendeeInteractionStep && isInRemoteRoom && (
              <Box>
                <IconButton
                  color="error"
                  onClick={removeAttendeeFromInteraction}
                >
                  <PersonRemoveOutlined />
                </IconButton>
              </Box>
            )}
          </AttendeeLine>
        </BigGapStack>
      )}

      <BigGapStack>
        {isInRemoteRoom && (
          <SecondaryTypography variant="subtitle2">
            {t('pages.interaction.remote.roomInvitation')}
          </SecondaryTypography>
        )}

        <MediumGapStack>
          <TextField
            label={t('pages.interaction.remote.invitationLink')}
            value={hcpUrl}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipBoardButton copyValue={hcpUrl} />
                </InputAdornment>
              ),
            }}
          />
          {roomCode && (
            <>
              <SecondaryTypography variant="subtitle2">
                {t('pages.interaction.remote.roomAccessViaCode')}
              </SecondaryTypography>
              <RoomCodeWrapper>
                <Typography variant="caption">
                  {t('pages.interaction.remote.anonymousLink')}
                </Typography>
                <Typography variant="caption">{hcpUrlAnon}</Typography>
              </RoomCodeWrapper>
              <RoomCodeWrapper>
                <Typography variant="caption">
                  {t('pages.interaction.remote.accessCode')}
                </Typography>
                <Typography variant="caption">{roomCode}</Typography>
              </RoomCodeWrapper>
            </>
          )}
          <ButtonWrapper>
            {!roomCode && (
              <FullWidthButton
                variant={'text'}
                disabled={isRoomCodeLoading}
                onClick={onGenerateCodeAction}
              >
                <TextOrLoader
                  isLoading={isRoomCodeLoading}
                  text={t('pages.interaction.remote.generateCode')}
                />
              </FullWidthButton>
            )}
            {canSendInvitationEmail && (
              <FullWidthButton
                variant={'outlined'}
                onClick={onOpenInvitationDialog}
              >
                {t('pages.interaction.remote.inviteAttendeeViaEmail')}
              </FullWidthButton>
            )}
          </ButtonWrapper>
        </MediumGapStack>
      </BigGapStack>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'row',
    },
}));

const BigGapStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      width: '50%',
    },
}));

const MediumGapStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const AttendeeLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LineBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
}));

const SecondaryTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const RoomCodeWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // width: '100%',
  gap: theme.spacing(1),
}));

const FullWidthButton = styled(Button)`
  width: 100%;
`;
