import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

type DialogColumnProps = {
  children: React.ReactNode;
};

export const DialogColumn = ({ children }: DialogColumnProps) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flex: 1,
  maxHeight: '100%',
  overflow: 'auto',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
