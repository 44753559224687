import { OrganizationData } from '@ysura/common';

import {
  parseCommunicationDataData,
  parseEmploymentConnection,
  parseEntityAddressConnection,
  parseOrganizationTypeConnection,
} from '@/services/graphql/parsers';
import { Organization, OrganizationConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseOrganizationData = (
  organization?: DeepPartial<Organization>
): OrganizationData => {
  return {
    id: organization?.id,
    oid: organization?.oid,
    name: organization?.name ?? undefined,
    customerReference: organization?.customerReference ?? undefined,
    inClearance: organization?.inClearance ?? false,
    addresses: organization?.addresses
      ? parseEntityAddressConnection(organization.addresses)
      : undefined,
    organizationTypes: organization?.organizationTypes
      ? parseOrganizationTypeConnection(organization.organizationTypes)
      : undefined,
    parentOrganization: organization?.parentOrganization
      ? parseOrganizationData(organization.parentOrganization)
      : undefined,
    rootOrganization: organization?.rootOrganization
      ? parseOrganizationData(organization.rootOrganization)
      : undefined,
    hierarchyOrganizations: organization?.hierarchyOrganizations
      ? parseOrganizationConnection(organization.hierarchyOrganizations)
      : undefined,
    communicationData: organization?.communicationData
      ? parseCommunicationDataData(organization.communicationData)
      : undefined,
    employments: organization?.employments
      ? parseEmploymentConnection(organization.employments)
      : undefined,
  };
};

export const parseOrganizationsData = (
  organizations: DeepPartial<Array<Organization>>
): Array<OrganizationData> => {
  const parsedOrganizations = organizations.map((organization) =>
    organization ? parseOrganizationData(organization) : null
  );

  return filterNonNull(parsedOrganizations);
};

export const parseOrganizationConnection = (
  organizationConnection?: DeepPartial<OrganizationConnection>
): Array<OrganizationData> => {
  const rawOrganizations = organizationConnection?.edges ?? [];
  const mappedOrganizations = rawOrganizations.map(
    (edge) => edge?.node ?? null
  );

  return parseOrganizationsData(filterNonNull(mappedOrganizations));
};
