import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';

import { useAuth } from '@/hooks';
import { Observable } from '@/services/graphql/clients/Observable';
import { getApiUrl } from '@/utils/hostUtils';

import { typePolicies } from './typePolicies';

export const errorsObserver = new Observable<MessageEvent>();

export const useWebApiClient = () => {
  const { getToken } = useAuth();

  const httpLink = createHttpLink({
    uri: getApiUrl(),
  });

  const errorLink = onError((error: ErrorResponse) => {
    const { headers } = error.operation.getContext();
    errorsObserver.notify({
      data: {
        error: error,
        errorConfig: headers.errorConfig || {},
      },
    });
  });

  const authLink = setContext((_, { headers }) => {
    const token = getToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const webApiClient = new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies,
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  return {
    webApiClient,
  };
};
