import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetActivityTypePromotionalMaterialsQueryVariables = Types.Exact<{
  oid: Types.Scalars['ActivityTypeId']['input'];
  states?: Types.InputMaybe<Array<Types.State> | Types.State>;
}>;

export type GetActivityTypePromotionalMaterialsQuery = {
  __typename?: 'Query';
  promotionalMaterials: {
    __typename?: 'PromotionalMaterialConnection';
    edges?: Array<{
      __typename?: 'PromotionalMaterialEdge';
      node?: {
        __typename?: 'PromotionalMaterial';
        id: string;
        oid: string;
        signatureRequired?: boolean | null;
        topics: {
          __typename?: 'TopicConnection';
          edges?: Array<{
            __typename?: 'TopicEdge';
            node?: { __typename?: 'Topic'; id: string; oid: string } | null;
          }> | null;
        };
        activityTypes: Array<{
          __typename?: 'PromotionalMaterialActivityType';
          stockTypes: Array<Types.StockType>;
          activityType: {
            __typename?: 'ActivityType';
            id: string;
            oid: string;
          };
        }>;
      } | null;
    }> | null;
  };
};

/**
 * __useGetActivityTypePromotionalMaterialsQuery__
 *
 * To run a query within a React component, call `useGetActivityTypePromotionalMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityTypePromotionalMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityTypePromotionalMaterialsQuery({
 *   variables: {
 *      oid: // value for 'oid'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useGetActivityTypePromotionalMaterialsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityTypePromotionalMaterialsQuery,
    GetActivityTypePromotionalMaterialsQueryVariables
  > &
    (
      | {
          variables: GetActivityTypePromotionalMaterialsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetActivityTypePromotionalMaterialsQuery,
    GetActivityTypePromotionalMaterialsQueryVariables
  >(Operations.getActivityTypePromotionalMaterials, options);
}
export function useGetActivityTypePromotionalMaterialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityTypePromotionalMaterialsQuery,
    GetActivityTypePromotionalMaterialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetActivityTypePromotionalMaterialsQuery,
    GetActivityTypePromotionalMaterialsQueryVariables
  >(Operations.getActivityTypePromotionalMaterials, options);
}
export function useGetActivityTypePromotionalMaterialsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivityTypePromotionalMaterialsQuery,
    GetActivityTypePromotionalMaterialsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetActivityTypePromotionalMaterialsQuery,
    GetActivityTypePromotionalMaterialsQueryVariables
  >(Operations.getActivityTypePromotionalMaterials, options);
}
export type GetActivityTypePromotionalMaterialsQueryHookResult = ReturnType<
  typeof useGetActivityTypePromotionalMaterialsQuery
>;
export type GetActivityTypePromotionalMaterialsLazyQueryHookResult = ReturnType<
  typeof useGetActivityTypePromotionalMaterialsLazyQuery
>;
export type GetActivityTypePromotionalMaterialsSuspenseQueryHookResult =
  ReturnType<typeof useGetActivityTypePromotionalMaterialsSuspenseQuery>;
export type GetActivityTypePromotionalMaterialsQueryResult = Apollo.QueryResult<
  GetActivityTypePromotionalMaterialsQuery,
  GetActivityTypePromotionalMaterialsQueryVariables
>;
