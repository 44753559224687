import { TopicData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';
import { TopicsList } from '@/components/TopicsList';

type TopicsStaticBlockProps = {
  topics: Array<TopicData>;
};

export const TopicsStaticBlock = ({ topics }: TopicsStaticBlockProps) => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock title={t('pages.interaction.common.topicColumnTitle')}>
      <TopicsList topics={topics} />
    </OverviewStaticBlock>
  );
};
