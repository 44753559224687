import { Button, DialogActions } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { useTranslation } from 'react-i18next';

export const MobileCalendarActionBar = ({
  onAccept,
}: PickersActionBarProps) => {
  const { t } = useTranslation();

  return (
    <DialogActions>
      <Button onClick={onAccept}>{t('components.common.done')}</Button>
    </DialogActions>
  );
};
