import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useUpdateActivityState } from '../UpdateActivityState';
import { AllMedia } from './AllMedia';
import { SelectedMedia } from './SelectedMedia';

export const MediaDialogContent = () => {
  const {
    selectedMedia,
    removeMediaFromSelection,
    addMediaToSelection,
    changeMediaReaction,
    currentActivity,
  } = useUpdateActivityState();

  return (
    currentActivity && (
      <Wrapper data-testid="media-dialog-content">
        <SelectedMedia
          activity={currentActivity}
          selectedMediaList={selectedMedia}
          onRemoveMediaInSelected={removeMediaFromSelection}
          onChangeReaction={changeMediaReaction}
        />
        <StyledDivider orientation="vertical" />

        <AllMedia
          selectedMedia={selectedMedia}
          onAddMediaToSelected={addMediaToSelection}
        />
      </Wrapper>
    )
  );
};

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',

  height: '100%',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: 0,
    height: 'auto',
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
