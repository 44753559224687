import { ActivityData, ActivityTypeBaseTypeDataEnum } from '@ysura/common';
import { useEffect } from 'react';

import { useCurrentUser, useInteraction } from '@/hooks';
import { InteractionBackTopLayout } from '@/layouts';
import { isActivityEditable } from '@/services/activities';
import {
  AdhocInteraction,
  F2FInteraction,
  RemoteInteraction,
} from '@/views/Interaction';
import { TelephoneInteraction } from '@/views/Interaction/Telephone';

type InteractionModeProps = {
  activity: ActivityData | null;
  activityId: string;
};

export type InteractionProps = {
  activity: ActivityData;
  canEditActivity?: boolean;
};

export const InteractionMode = ({
  activity,
  activityId,
}: InteractionModeProps) => {
  const { initializeInteraction, interactionStep } = useInteraction();

  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (activity) {
      if (interactionStep !== 'interaction' && interactionStep !== 'summary') {
        initializeInteraction(activity);
      }
    }
  }, [activity, interactionStep, initializeInteraction]);

  const canEditActivity = isActivityEditable(activity, currentUser);

  // Adhoc interactions can be both F2F or remote
  if (activityId === 'adhoc') {
    return <AdhocInteraction />;
  } else if (activity) {
    // BaseType DirectContact => F2F Interaction
    if (
      activity?.activityType?.baseType ===
      ActivityTypeBaseTypeDataEnum.DIRECT_CONTACT
    ) {
      return (
        <InteractionBackTopLayout>
          <F2FInteraction canEditActivity activity={activity} />
        </InteractionBackTopLayout>
      );
    }
    // BaseType Telephone => Telephone Interaction
    if (
      activity?.activityType?.baseType ===
      ActivityTypeBaseTypeDataEnum.TELEPHONE
    ) {
      return (
        <TelephoneInteraction
          canEditActivity={canEditActivity}
          activity={activity}
        />
      );
    }
    // BaseType Remote => Remote Interaction
    if (
      activity?.activityType?.baseType === ActivityTypeBaseTypeDataEnum.REMOTE
    ) {
      return <RemoteInteraction canEditActivity activity={activity} />;
    }
  }

  return null;
};
