import CircleIcon from '@mui/icons-material/Circle';
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

import { ConsentDonutChart } from './ConsentDonutChart';

export const ConsentStatusStaticBlock = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const colors = [
    theme.palette.primary.dark,
    theme.palette.primary.lighter,
    theme.palette.primary.light,
    theme.palette.primary.main,
  ];
  const labels = [
    t('pages.homepage.consentStatusCard.accepted'),
    t('pages.homepage.consentStatusCard.rejected'),
    t('pages.homepage.consentStatusCard.pending'),
    t('pages.homepage.consentStatusCard.notCollected'),
  ];

  return (
    <OverviewStaticBlock
      title={t('pages.homepage.consentStatusCard.consentStatus')}
      testId="consent-status-static-block"
    >
      <StyledStack>
        <ConsentDonutChart labels={labels} colors={colors} />
        <StyledGrid container spacing={1}>
          <Grid item xs={6}>
            <StyledBox>
              <ColorIcon customColor={colors[0]} />
              <Typography variant="caption">{labels[0]}</Typography>
            </StyledBox>
          </Grid>
          <Grid item xs={6}>
            <StyledBox>
              <ColorIcon customColor={colors[1]} />
              <Typography variant="caption">{labels[1]}</Typography>
            </StyledBox>
          </Grid>
          <Grid item xs={6}>
            <StyledBox>
              <ColorIcon customColor={colors[2]} />
              <Typography variant="caption">{labels[2]}</Typography>
            </StyledBox>
          </Grid>
          <Grid item xs={6}>
            <StyledBox>
              <ColorIcon customColor={colors[3]} />
              <Typography variant="caption">{labels[3]}</Typography>
            </StyledBox>
          </Grid>
        </StyledGrid>
      </StyledStack>
    </OverviewStaticBlock>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  borderTop: `1px ${theme.palette.divider} solid`,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ColorIcon = styled(CircleIcon, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor: string }>(({ customColor }) => ({
  color: customColor,
  fontSize: '12px',
}));
