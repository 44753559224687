import { CountryData } from '@ysura/common';

import { Country } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';

export const parseCountryData = (
  country?: DeepPartial<Country>
): CountryData => {
  return {
    id: country?.id,
    oid: country?.oid,
    name: country?.name ?? undefined,
    alpha2Code: country?.alpha2Code ?? undefined,
  };
};
