import {
  ActivityCoOrganizerData,
  OrganizationalUnitData,
  PersonAssignmentData,
  PersonData,
} from '@ysura/common';

export const getCoOrganizerFullName = (person?: PersonData): string => {
  if (!person) {
    return '';
  }

  const { firstName, lastName } = person;

  return `${lastName}, ${firstName}`.trim();
};

export const getCoOrganizerInfo = (
  person?: PersonData,
  organizationalUnit?: OrganizationalUnitData
) => {
  const name = getCoOrganizerFullName(person);
  const unit = organizationalUnit?.name ?? '';

  return {
    name,
    unit,
  };
};

export const convertPersonAssignmentToCoOrganizer = (
  personAssignments: PersonAssignmentData[]
): ActivityCoOrganizerData[] => {
  return personAssignments.reduce(
    (
      current: ActivityCoOrganizerData[],
      personAssignment: PersonAssignmentData
    ) => {
      return [
        ...current,
        {
          id: personAssignment.id,
          oid: personAssignment.oid,
          person: personAssignment?.person,
          organizationalUnit: personAssignment?.organizationalUnit,
        },
      ];
    },
    []
  );
};
