import { Box, Button as MuiButton, Dialog as MuiDialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    height: `calc(100% - ${theme.spacing(10)})`,
    gap: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2),
      height: '100%',
    },
  },
}));

export const Button = styled(MuiButton)({
  textTransform: 'uppercase',
});

export const DialogTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: '0 0 auto',
  padding: `${theme.spacing(3)} ${theme.spacing(3)} 0px`,
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px`,
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
