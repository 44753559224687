import { PersonConsentData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@/components/ConfirmationDialog';
import { errorConfigBannerDialog } from '@/hooks';
import { useResendDoubleOptInMutation } from '@/services/graphql/hooks';

type ResendDoubleOptInComponentProps = {
  consent: PersonConsentData;
  onCloseDialog: VoidFunction;
  onConfirmAction: VoidFunction;
};

export const ResendDoubleOptInDialog = ({
  consent,
  onCloseDialog,
  onConfirmAction,
}: ResendDoubleOptInComponentProps) => {
  const { t } = useTranslation();

  const [resendConsentDoubleOptIn, { loading }] =
    useResendDoubleOptInMutation();

  const handleResendDoubleOptIn = () => {
    resendConsentDoubleOptIn({
      ...errorConfigBannerDialog,
      variables: {
        oid: consent?.oid ?? '',
      },
      onCompleted: () => {
        onConfirmAction();
      },
      onError: () => {
        onCloseDialog();
      },
    });
  };

  return (
    <ConfirmationDialog
      testId="resend-doi-consent"
      isOpen={true}
      title={t('components.consentDialog.resendDoubleOptIn.title')}
      content={t('components.consentDialog.resendDoubleOptIn.text')}
      primaryButton={{
        text: t('components.consentDialog.resendDoubleOptIn.send'),
        onClickHandler: handleResendDoubleOptIn,
        isButtonLoading: loading,
      }}
      secondaryButton={{
        text: t('components.common.cancel'),
        onClickHandler: onCloseDialog,
      }}
    />
  );
};
