import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ConsentItemData, getConsentInputName } from '@ysura/common';
import { FormikValues } from 'formik/dist/types';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type ViewConsentItemData = ConsentItemData & {
  isDisabled?: boolean;
};
type ConsentCommunicationCollectionProps = {
  consentItemsCommunicationData: Array<ViewConsentItemData>;
  formik: FormikValues;
  expandedConsentItems: Array<string>;
  setExpandedConsentItems: (values: string[]) => void;
};
export const ConsentCommunicationCollection = ({
  consentItemsCommunicationData,
  formik,
  expandedConsentItems,
  setExpandedConsentItems,
}: ConsentCommunicationCollectionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleConsentItemCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    consentItem: ConsentItemData
  ) => {
    const consentItems = event.target.checked
      ? [...expandedConsentItems, consentItem?.inputId ?? '']
      : expandedConsentItems.filter((item) => item !== consentItem?.inputId);

    setExpandedConsentItems(consentItems);
  };

  return (
    <Grid container spacing={theme.spacing(2)}>
      {consentItemsCommunicationData.map((consentItem) => {
        const consentItemInputId = consentItem?.inputId ?? '';

        return (
          <Grid key={consentItemInputId} item xs={12}>
            <ConsentItemCheckbox
              control={
                <Checkbox
                  {...formik.getFieldProps(consentItemInputId)}
                  id={consentItemInputId}
                  data-testid={`consent-${consentItemInputId}`}
                  checked={!!formik.values[consentItemInputId]}
                  disabled={consentItem.isDisabled}
                  onChange={(event) => {
                    formik.handleChange(event);
                    handleConsentItemCheckboxChange(event, consentItem);
                  }}
                />
              }
              label={
                <Stack>
                  <Typography variant="caption">
                    {t('components.consentDialog.agreeTo', {
                      consentItem: consentItem?.name,
                    })}
                  </Typography>

                  <ConsentItemDescription variant="caption">
                    {consentItem?.description}
                  </ConsentItemDescription>
                </Stack>
              }
            />

            {consentItemInputId &&
              expandedConsentItems.includes(consentItemInputId) && (
                <Grid container spacing={theme.spacing(2)}>
                  {consentItem?.consentInputFields?.map((consentInput) => {
                    const consentInputName = getConsentInputName(
                      consentItem,
                      consentInput
                    );
                    const error =
                      !!formik.errors[consentInputName] &&
                      formik.touched[consentInputName];

                    return (
                      <Grid key={consentInputName} item md={6} xs={12}>
                        <Wrapper>
                          <StyleFormHelperText>
                            {consentInput.description}
                          </StyleFormHelperText>

                          <InputField
                            {...formik.getFieldProps(consentInputName)}
                            fullWidth
                            autoComplete="off"
                            id={consentInputName}
                            data-testid={`consent-${consentInputName}`}
                            label={consentInput?.label}
                            error={error}
                            disabled={consentItem.isDisabled}
                            helperText={
                              error && t(formik.errors[consentInputName] ?? '')
                            }
                            required={consentInput?.isMandatory}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Wrapper>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
          </Grid>
        );
      })}
    </Grid>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const ConsentItemCheckbox = styled(FormControlLabel)({
  position: 'relative',
});

const ConsentItemDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  whiteSpace: 'pre-line',
}));

const InputField = styled(TextField)(({ theme }) => ({
  '.MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
  },
}));

const StyleFormHelperText = styled(FormHelperText)(() => ({
  whiteSpace: 'pre-line',
}));
