import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type CollectPaperPersonConsentMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
  consentTypeId: Types.Scalars['ConsentTypeId']['input'];
  issuerPersonId: Types.Scalars['PersonId']['input'];
  signerPersonId: Types.Scalars['PersonId']['input'];
  actionAt: Types.Scalars['DateTime']['input'];
  consentItemsValues:
    | Array<Types.PersonConsentItemValueInput>
    | Types.PersonConsentItemValueInput;
  formIdentifier: Types.Scalars['String']['input'];
}>;

export type CollectPaperPersonConsentMutation = {
  __typename?: 'Mutation';
  collectPaperPersonConsent?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type CollectPaperPersonConsentMutationFn = Apollo.MutationFunction<
  CollectPaperPersonConsentMutation,
  CollectPaperPersonConsentMutationVariables
>;

/**
 * __useCollectPaperPersonConsentMutation__
 *
 * To run a mutation, you first call `useCollectPaperPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectPaperPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectPaperPersonConsentMutation, { data, loading, error }] = useCollectPaperPersonConsentMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      consentTypeId: // value for 'consentTypeId'
 *      issuerPersonId: // value for 'issuerPersonId'
 *      signerPersonId: // value for 'signerPersonId'
 *      actionAt: // value for 'actionAt'
 *      consentItemsValues: // value for 'consentItemsValues'
 *      formIdentifier: // value for 'formIdentifier'
 *   },
 * });
 */
export function useCollectPaperPersonConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CollectPaperPersonConsentMutation,
    CollectPaperPersonConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CollectPaperPersonConsentMutation,
    CollectPaperPersonConsentMutationVariables
  >(Operations.collectPaperPersonConsent, options);
}
export type CollectPaperPersonConsentMutationHookResult = ReturnType<
  typeof useCollectPaperPersonConsentMutation
>;
export type CollectPaperPersonConsentMutationResult =
  Apollo.MutationResult<CollectPaperPersonConsentMutation>;
export type CollectPaperPersonConsentMutationOptions =
  Apollo.BaseMutationOptions<
    CollectPaperPersonConsentMutation,
    CollectPaperPersonConsentMutationVariables
  >;
