import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, ActivityStatusDataEnum } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';
import { useInteraction } from '@/hooks';

import { PromatButtons } from './PromatButtons';
import { PromotionalMaterialCardList } from './PromotionalMaterialCardList';

type PromotionalMaterialStaticBlockProps = {
  activity: ActivityData | null;
  isInRemoteRoom?: boolean;
  hasActionButton?: boolean;
};

export const PromotionalMaterialStaticBlock = ({
  activity,
  isInRemoteRoom = false,
  hasActionButton = true,
}: PromotionalMaterialStaticBlockProps) => {
  const { t } = useTranslation();
  const {
    interactionStep,
    promotionalMaterialGroups,
    setPromotionalMaterialGroups,
    signedSampleRequestForm,
  } = useInteraction();

  if (!activity) {
    return null;
  }

  const firstAttendee = activity.attendees?.[0]?.person;
  const isDoneTouchpoint = activity.status === ActivityStatusDataEnum.CLOSED;
  // either the signed form is already in the activity or it's still in the interaction state
  const isSigned =
    !!activity.sampleRequestFormDocument || !!signedSampleRequestForm;

  return (
    <OverviewStaticBlock
      title={t('pages.interaction.common.promatColumnTitle')}
      spacing={isInRemoteRoom ? 'dense' : 'spacious'}
    >
      <PromotionalMaterialWrapper>
        <PromotionalMaterialCardList
          promotionalMaterialGroups={promotionalMaterialGroups}
          isDoneTouchpoint={activity.status === ActivityStatusDataEnum.CLOSED}
          isSigned={isSigned}
        />

        {hasActionButton && firstAttendee && setPromotionalMaterialGroups && (
          <PromatButtons
            activity={activity}
            isAllowedToSign={interactionStep !== 'summary'}
            isAllowToViewSignedForm={
              interactionStep === 'summary' || isDoneTouchpoint
            }
            isRemoteInteraction={isInRemoteRoom}
            promats={promotionalMaterialGroups}
            onChangePromats={setPromotionalMaterialGroups}
          />
        )}
      </PromotionalMaterialWrapper>
    </OverviewStaticBlock>
  );
};

export const PromotionalMaterialWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
