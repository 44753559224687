import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetOrganizationByPersonIdQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID']['input'];
  employeesLimit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetOrganizationByPersonIdQuery = {
  __typename?: 'Query';
  person?: {
    __typename?: 'Person';
    id: string;
    employments: {
      __typename?: 'EmploymentConnection';
      filteredCount: number;
      edges?: Array<{
        __typename?: 'EmploymentEdge';
        node?: {
          __typename?: 'Employment';
          id: string;
          oid: string;
          sortCode?: number | null;
          function?: {
            __typename?: 'EmployeeFunction';
            name?: string | null;
          } | null;
          department?: {
            __typename?: 'EmployeeDepartment';
            id: string;
            name?: string | null;
          } | null;
          communicationData: {
            __typename?: 'CommunicationDataConnection';
            edges?: Array<{
              __typename?: 'CommunicationDataEdge';
              node?: {
                __typename?: 'CommunicationData';
                value?: string | null;
                communicationDataType: {
                  __typename?: 'CommunicationDataType';
                  baseType?: Types.CommunicationDataBaseType | null;
                  name?: string | null;
                };
              } | null;
            }> | null;
          };
          addresses: {
            __typename?: 'EntityAddressConnection';
            edges?: Array<{
              __typename?: 'EntityAddressEdge';
              node?: {
                __typename?: 'EntityAddress';
                addressType: Types.AddressType;
                address: {
                  __typename?: 'Address';
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  addressLine3?: string | null;
                  city: string;
                  postalCode: string;
                  state: Types.State;
                };
              } | null;
            }> | null;
          };
          organization: {
            __typename?: 'Organization';
            id: string;
            oid: string;
            name?: string | null;
            addresses: {
              __typename?: 'EntityAddressConnection';
              edges?: Array<{
                __typename?: 'EntityAddressEdge';
                node?: {
                  __typename?: 'EntityAddress';
                  addressType: Types.AddressType;
                  address: {
                    __typename?: 'Address';
                    addressLine1?: string | null;
                    addressLine2?: string | null;
                    addressLine3?: string | null;
                    city: string;
                    postalCode: string;
                    state: Types.State;
                  };
                } | null;
              }> | null;
            };
            communicationData: {
              __typename?: 'CommunicationDataConnection';
              edges?: Array<{
                __typename?: 'CommunicationDataEdge';
                node?: {
                  __typename?: 'CommunicationData';
                  value?: string | null;
                  communicationDataType: {
                    __typename?: 'CommunicationDataType';
                    baseType?: Types.CommunicationDataBaseType | null;
                    name?: string | null;
                  };
                } | null;
              }> | null;
            };
            rootOrganization?: {
              __typename?: 'Organization';
              id: string;
              oid: string;
              name?: string | null;
            } | null;
          };
        } | null;
      }> | null;
    };
  } | null;
};

/**
 * __useGetOrganizationByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      employeesLimit: // value for 'employeesLimit'
 *   },
 * });
 */
export function useGetOrganizationByPersonIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationByPersonIdQuery,
    GetOrganizationByPersonIdQueryVariables
  > &
    (
      | { variables: GetOrganizationByPersonIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetOrganizationByPersonIdQuery,
    GetOrganizationByPersonIdQueryVariables
  >(Operations.getOrganizationByPersonId, options);
}
export function useGetOrganizationByPersonIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationByPersonIdQuery,
    GetOrganizationByPersonIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetOrganizationByPersonIdQuery,
    GetOrganizationByPersonIdQueryVariables
  >(Operations.getOrganizationByPersonId, options);
}
export function useGetOrganizationByPersonIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrganizationByPersonIdQuery,
    GetOrganizationByPersonIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetOrganizationByPersonIdQuery,
    GetOrganizationByPersonIdQueryVariables
  >(Operations.getOrganizationByPersonId, options);
}
export type GetOrganizationByPersonIdQueryHookResult = ReturnType<
  typeof useGetOrganizationByPersonIdQuery
>;
export type GetOrganizationByPersonIdLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationByPersonIdLazyQuery
>;
export type GetOrganizationByPersonIdSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationByPersonIdSuspenseQuery
>;
export type GetOrganizationByPersonIdQueryResult = Apollo.QueryResult<
  GetOrganizationByPersonIdQuery,
  GetOrganizationByPersonIdQueryVariables
>;
