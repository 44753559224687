import { gql } from '@apollo/client';

export const getOrganizationByPersonId = gql`
  query getOrganizationByPersonId($personId: ID!, $employeesLimit: Int = 1) {
    person(id: $personId) {
      id
      employments(
        first: $employeesLimit
        filter: { state: { _eq: ACTIVE } }
        orderBy: { sortCode: ASC }
      ) {
        filteredCount
        edges {
          node {
            id
            oid
            sortCode
            function {
              name
            }
            department {
              id
              name
            }
            communicationData {
              edges {
                node {
                  communicationDataType {
                    baseType
                    name
                  }
                  value
                }
              }
            }
            addresses(
              filter: {
                addressType: { _eq: STANDARD }
                address: { state: { _eq: ACTIVE } }
              }
            ) {
              edges {
                node {
                  addressType
                  address {
                    addressLine1
                    addressLine2
                    addressLine3
                    city
                    postalCode
                    state
                  }
                }
              }
            }
            organization {
              id
              oid
              name
              addresses(
                filter: {
                  addressType: { _eq: STANDARD }
                  address: { state: { _eq: ACTIVE } }
                }
              ) {
                edges {
                  node {
                    addressType
                    address {
                      addressLine1
                      addressLine2
                      addressLine3
                      city
                      postalCode
                      state
                    }
                  }
                }
              }
              communicationData {
                edges {
                  node {
                    communicationDataType {
                      baseType
                      name
                    }
                    value
                  }
                }
              }
              rootOrganization {
                id
                oid
                name
              }
            }
          }
        }
      }
    }
  }
`;
