import CallEndIcon from '@mui/icons-material/CallEnd';
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonData, RoundButton } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { VideoControlsMobileKebabPopover } from '@/components/Interaction';
import { CUSTOM_BREAKPOINTS, REMOTE_INTERACTION } from '@/config/layout';
import { useInteraction } from '@/hooks';
import {
  ToggleCameraButton,
  ToggleMicButton,
} from '@/views/Interaction/Remote/Buttons';

type VideoControlsMobileProps = {
  isOrganizer: boolean;
  openSettingsDialog: VoidFunction;
  openCallInfoDialog: VoidFunction;
  openEndInteractionDialog: VoidFunction;
  attendee?: PersonData;
};

export const VideoControlsMobile = ({
  isOrganizer,
  openSettingsDialog,
  openCallInfoDialog,
  openEndInteractionDialog,
}: VideoControlsMobileProps) => {
  const { isAnyContentShared } = useInteraction();
  const { t } = useTranslation();

  const handleLeaveInteraction = () => {
    openEndInteractionDialog();
  };

  const renderSeeMoreButton = () => {
    return isOrganizer ? (
      <VideoControlsMobileKebabPopover
        menuEntries={[
          {
            entry: t('pages.interaction.remote.settings'),
            icon: <SettingsIcon />,
            onClick: openSettingsDialog,
          },
          {
            entry: t('pages.interaction.remote.roomInformation'),
            icon: <InfoIcon />,
            onClick: openCallInfoDialog,
          },
        ]}
      />
    ) : (
      <RoundButton
        size="medium"
        variant="secondary"
        onClick={openSettingsDialog}
      >
        <SettingsIcon />
      </RoundButton>
    );
  };

  const renderEndCallButton = () => {
    return (
      <RoundButton
        testId="end-call-button"
        size="medium"
        variant="danger"
        disabled={isOrganizer && isAnyContentShared}
        onClick={handleLeaveInteraction}
      >
        <CallEndIcon />
      </RoundButton>
    );
  };

  return (
    <>
      <Container data-testid="video-controls-mobile">
        <ButtonWrapper>
          <ToggleCameraButton size="medium" />
          <ToggleMicButton size="medium" />
          {renderSeeMoreButton()}
        </ButtonWrapper>

        <ButtonWrapper>{renderEndCallButton()}</ButtonWrapper>
      </Container>
    </>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[800],

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
      alignItems: 'center',

      height: '100%',
      width: REMOTE_INTERACTION.MOBILE.AUDIO_VIDEO_CONTROLS_WIDTH_LANDSCAPE,
      padding: `${theme.spacing(2)} 0`,
    },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      flexDirection: 'column',
    },
}));
