import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import merge from 'lodash/merge';
// eslint-disable-next-line import/default
import Chart from 'react-apexcharts';

import { BaseOptionChart } from '@/components/Chart';

type ConsentDonutChartProps = {
  labels: Array<string>;
  colors: Array<string>;
};

//TODO: this is only fake data
export const ConsentDonutChart = ({
  labels,
  colors,
}: ConsentDonutChartProps) => {
  const chartOptions = merge(BaseOptionChart(), {
    colors,
    fill: {
      colors,
    },
    labels,
    stroke: {
      width: 0,
      curve: 'smooth',
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        size: 200,
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: '85%',

          labels: {
            total: {
              showAlways: true,
              fontSize: '1rem',
              fontWeight: 600,
              offsetY: -10,
            },
            value: {
              fontSize: '2rem',
              fontWeight: 700,
              offsetY: 8,
            },
          },
        },
      },
    },
    tooltip: {
      fillSeriesColor: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  });

  return (
    <Wrapper data-testid="consent-chart">
      <Chart
        type="donut"
        series={[217, 109, 80, 137]}
        labels={labels}
        options={chartOptions}
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
