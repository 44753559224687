import { UserData } from '@ysura/common';

import {
  parseDataPermissions,
  parsePersonData,
} from '@/services/graphql/parsers';
import { convertedState } from '@/services/graphql/parsers/utils';
import { User, UserConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseUserData = (user?: DeepPartial<User>): UserData => {
  return {
    id: user?.id ?? undefined,
    oid: user?.oid ?? undefined,
    username: user?.username ?? undefined,
    email: user?.email ?? undefined,
    state: convertedState(user?.state),
    person: user?.person ? parsePersonData(user.person) : undefined,
    preferenceId: user?.preference?.oid,
    allClientPermissions: filterNonNull(user?.allClientPermissions ?? []),
    allDataPermissions: parseDataPermissions(user?.allDataPermissions ?? []),
  };
};

export const parseUserConnection = (
  userConnection?: DeepPartial<UserConnection>
): Array<UserData> => {
  const rawUsers = userConnection?.edges ?? [];
  const mappedUsers = rawUsers
    .map((edge) => edge?.node ?? null)
    .map((user) => (user ? parseUserData(user) : null));

  return filterNonNull(mappedUsers);
};
