import { MediaData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { MediaContextProvider } from '@/hooks/state';

import { MediaListDialogContent } from './MediaListDialogContent';

type MediaDialogProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  isSharingAvailable?: boolean;
  shouldUpdateMediaViewStatus?: boolean;
  isDisplayViewedLabel?: boolean;
  onChangePresentationDialogState?: (val: boolean) => void;
  onChangeMediaWithDocument?: (media: MediaData) => void;
};

export const MediaListDialog = ({
  isOpen,
  onClose,
  isSharingAvailable = false,
  shouldUpdateMediaViewStatus = false,
  isDisplayViewedLabel = false,
  onChangeMediaWithDocument,
  onChangePresentationDialogState,
}: MediaDialogProps) => {
  const { t } = useTranslation();
  const handleOpenMediaContent = (media: MediaData) => {
    onChangePresentationDialogState?.(true);
    onChangeMediaWithDocument?.(media);
  };

  return (
    <ActionDialog
      isOpen={isOpen}
      maxWidth="lg"
      testId="media-dialog"
      title={t('pages.interaction.common.listOfMedia')}
      primaryButtonText={t('components.common.close')}
      onClickPrimaryButton={onClose}
    >
      <MediaContextProvider>
        <MediaListDialogContent
          isSharingAvailable={isSharingAvailable}
          isDisplayViewedLabel={isDisplayViewedLabel}
          shouldUpdateMediaViewStatus={shouldUpdateMediaViewStatus}
          onOpenMediaContent={handleOpenMediaContent}
        />
      </MediaContextProvider>
    </ActionDialog>
  );
};
