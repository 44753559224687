import { gql } from '@apollo/client';

export const getCurrentUserPreference = gql`
  query getCurrentUserPreference {
    currentUser {
      oid
      preference {
        oid
        facets {
          personFacets {
            personTypes {
              id
              oid
              name
            }
            specialities {
              id
              oid
              name
            }
            specialityGroups {
              id
              oid
              name
            }
            employeeFunctions {
              id
              oid
              name
            }
            attributes {
              id
              oid
              name
              type
              options {
                id
                oid
                value
              }
            }
            consentTypes {
              id
              oid
              name
              digitalSignatureProcessEnabled
              paperProcessEnabled
              manualProcessEnabled
              doubleOptInDigitalEnabled
              doubleOptInPaperEnabled
              doubleOptInManualEnabled
              consentItems {
                edges {
                  node {
                    oid
                    name
                  }
                }
              }
            }
          }
          organizationFacets {
            organizationTypes {
              id
              oid
              name
            }
            cities
            attributes {
              id
              oid
              name
              type
              options {
                id
                oid
                value
              }
            }
          }
        }
      }
    }
  }
`;
