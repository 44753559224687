import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, MediaData, NoItemPlaceholder } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';
import {
  InteractionMediaCard,
  MediaPresentationDialog,
} from '@/components/Media';
import { useCurrentUser, useInteraction } from '@/hooks';
import { isUndefOrEmptyArray } from '@/utils/arrays';
import { MediaListDialog } from '@/views/Interaction/ExpandView/Media';

import { mergePlannedAndViewedMediaAndSort } from './helper';

type MediaAccordionProps = {
  activity: ActivityData | null;
  isViewedStatusUpdatedOnOpen?: boolean;
};

export const MediaAccordionBlock = ({
  activity,
  isViewedStatusUpdatedOnOpen = true,
}: MediaAccordionProps) => {
  const [isMediaExpandViewOpen, setIsMediaExpandViewOpen] = useState(false);

  const [isMediaPresentationDialogOpen, setIsMediaPresentationDialogOpen] =
    useState(false);
  const [presentationMediaWithDocument, setPresentationMediaWithDocument] =
    useState<MediaData | null>(null);

  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { viewedMedia, interactionStep } = useInteraction();

  const sortedMedia = mergePlannedAndViewedMediaAndSort(
    activity?.media,
    viewedMedia
  );

  const isWaitingRoomActive = interactionStep === 'waiting-room';

  const viewAllHandler = () => {
    setIsMediaExpandViewOpen(true);
  };

  const handleChangePresentationDialogState = (val: boolean) => {
    setIsMediaPresentationDialogOpen(val);
  };

  const handleChangePresentationMedia = (media: MediaData) => {
    setPresentationMediaWithDocument(media);
  };

  return (
    <>
      <OverviewAccordionBlock
        title={t('pages.interaction.common.mediaColumnTitle')}
        viewAllHandler={
          currentUser?.permissions?.activity?.canEditActivityMedia
            ? viewAllHandler
            : undefined
        }
        numberOfItems={sortedMedia?.length ?? 0}
      >
        {isUndefOrEmptyArray(sortedMedia) ? (
          <NoItemPlaceholder>
            {t('components.mediaCard.noMedia')}
          </NoItemPlaceholder>
        ) : (
          <MediaCardList>
            {sortedMedia?.map((media) => (
              <InteractionMediaCard
                key={media?.id}
                isDisplayViewedLabel={!isWaitingRoomActive}
                isSharingAvailable={!isWaitingRoomActive}
                isViewed={!!media?.isViewed}
                media={media}
                variant="long"
                isDisplayReactions={false}
                isViewedStatusUpdatedOnOpen={isViewedStatusUpdatedOnOpen}
                onChangeMediaWithDocument={handleChangePresentationMedia}
                onChangePresentationDialogState={
                  handleChangePresentationDialogState
                }
              />
            ))}
          </MediaCardList>
        )}
      </OverviewAccordionBlock>

      <MediaListDialog
        isSharingAvailable={!isWaitingRoomActive}
        isOpen={isMediaExpandViewOpen}
        shouldUpdateMediaViewStatus={!isWaitingRoomActive}
        onClose={() => setIsMediaExpandViewOpen(false)}
        onChangeMediaWithDocument={handleChangePresentationMedia}
        onChangePresentationDialogState={handleChangePresentationDialogState}
      />

      {isMediaPresentationDialogOpen && (
        <MediaPresentationDialog
          isOpen={isMediaPresentationDialogOpen}
          isInInteraction={!isWaitingRoomActive}
          media={presentationMediaWithDocument}
          onClose={() => handleChangePresentationDialogState(false)}
        />
      )}
    </>
  );
};

const MediaCardList = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
