import { Box, Card, TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { add, sub } from 'date-fns';

export interface CalendarCardProps {
  selectedDate: Date;
  onChangeDate: (date: Date | null) => void;
}

export const CalendarCard = ({
  selectedDate,
  onChangeDate,
}: CalendarCardProps) => {
  return (
    <Box>
      <Card>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          value={selectedDate}
          minDate={sub(new Date(), { years: 6 })}
          maxDate={add(new Date(), { years: 5 })}
          renderInput={(params) => <TextField {...params} />}
          onChange={onChangeDate}
        />
      </Card>
    </Box>
  );
};
