import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder, PromotionalMaterialGroupData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { SelectedItem } from './SelectedItem';
import { SelectedItemSkeleton } from './SelectedItemSkeleton';

type SelectedItemListProps = {
  isRestrictedEdit?: boolean;
  selectedPromotionalMaterials: Array<PromotionalMaterialGroupData>;
  isLoading?: boolean;
  isSigned: boolean;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const SelectedItemList = ({
  isRestrictedEdit = false,
  selectedPromotionalMaterials,
  isLoading,
  isSigned,
  onChangePromats,
}: SelectedItemListProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <SelectedMaterialsWrapper>
        <SelectedItemSkeleton />
      </SelectedMaterialsWrapper>
    );
  }

  if (!selectedPromotionalMaterials?.length) {
    return (
      <NoItemPlaceholder>
        {t('pages.interaction.expand.noPromatsSelected')}
      </NoItemPlaceholder>
    );
  }

  return (
    <SelectedMaterialsWrapper>
      {selectedPromotionalMaterials.map((selectedMaterial, index) => (
        <SelectedItem
          key={selectedMaterial.promotionalMaterial?.oid}
          selectedMaterial={selectedMaterial}
          isRestrictedEdit={isRestrictedEdit}
          isLastItem={index === selectedPromotionalMaterials.length - 1}
          isSigned={isSigned}
          selectedGroupedPromotionalMaterials={selectedPromotionalMaterials}
          onChangePromats={onChangePromats}
        />
      ))}
    </SelectedMaterialsWrapper>
  );
};

const SelectedMaterialsWrapper = styled(Box)(({ theme }) => ({
  borderRadius: Number(theme.shape.borderRadius) / 2,
  boxShadow: theme.customShadows.z8,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));
