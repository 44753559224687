import { TopicData } from '@ysura/common';
import { SyntheticEvent } from 'react';

import { TopicsSelect } from '@/views/Activity/Shared/Topics';

import { useUpdateActivityState } from '../UpdateActivityState';

export const TopicsDialogContent = () => {
  const { selectedTopics, availableTopics, changeTopics } =
    useUpdateActivityState();

  const handleChangeTopics = (
    e: SyntheticEvent<Element, Event>,
    value: Array<TopicData>
  ) => {
    changeTopics?.(value);
  };

  return (
    <TopicsSelect
      available={availableTopics}
      selected={selectedTopics}
      isDisabled={false}
      onChange={handleChangeTopics}
    />
  );
};
