import { styled } from '@mui/material/styles';
import {
  NoItemPlaceholder,
  PromotionalMaterialGroupData,
  PromotionalMaterialTypeDataEnum,
  TabPanel as MinimalTabPanel,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PromotionalMaterialList } from './PromotionalMaterialList';
import { PromotionalMaterialListSkeleton } from './PromotionalMaterialListSkeleton';

type PromotionalMaterialTabPanelsProps = {
  tabIndex: number;
  isLoading: boolean;
  isFiltered: boolean;
  isSigned: boolean;
  isRestrictedEdit?: boolean;
  promotionalMaterialGroups: Array<PromotionalMaterialGroupData>;
  onChangePromats: (
    changedPromats: Array<PromotionalMaterialGroupData>
  ) => void;
};

export const PromotionalMaterialTabPanels = ({
  tabIndex,
  isLoading,
  isFiltered,
  isSigned,
  isRestrictedEdit = false,
  promotionalMaterialGroups,
  onChangePromats,
}: PromotionalMaterialTabPanelsProps) => {
  const { t } = useTranslation();

  const productPromats: Array<PromotionalMaterialGroupData> = [];
  const nonProductPromats: Array<PromotionalMaterialGroupData> = [];

  promotionalMaterialGroups?.forEach((promat) => {
    if (
      promat.promotionalMaterial?.type ===
      PromotionalMaterialTypeDataEnum.PRODUCT
    ) {
      productPromats.push(promat);
    } else {
      nonProductPromats.push(promat);
    }
  });

  if (isLoading) {
    return (
      <TabPanel value={tabIndex} index={0}>
        <PromotionalMaterialListSkeleton />
      </TabPanel>
    );
  }

  if (tabIndex === 0) {
    return (
      <TabPanel value={tabIndex} index={0}>
        {productPromats.length === 0 && isFiltered && (
          <FullHeightPlaceholder>
            {t('pages.interaction.expand.noSearchResults')}
          </FullHeightPlaceholder>
        )}
        {productPromats.length === 0 && !isFiltered && (
          <FullHeightPlaceholder>
            {t('pages.interaction.expand.noAvailableMaterial')}
          </FullHeightPlaceholder>
        )}
        {productPromats.length > 0 && (
          <PromotionalMaterialList
            isLoading={isLoading}
            isRestrictedEdit={isRestrictedEdit}
            isSigned={isSigned}
            promotionalMaterialList={productPromats}
            promotionalMaterialGroups={promotionalMaterialGroups}
            onChangePromats={onChangePromats}
          />
        )}
      </TabPanel>
    );
  }

  return (
    <TabPanel value={tabIndex} index={1}>
      {nonProductPromats.length === 0 && isFiltered && (
        <FullHeightPlaceholder>
          {t('pages.interaction.expand.noSearchResults')}
        </FullHeightPlaceholder>
      )}
      {nonProductPromats.length === 0 && !isFiltered && (
        <FullHeightPlaceholder>
          {t('pages.interaction.expand.noAvailableMaterial')}
        </FullHeightPlaceholder>
      )}
      {nonProductPromats.length > 0 && (
        <PromotionalMaterialList
          isLoading={isLoading}
          isRestrictedEdit={isRestrictedEdit}
          isSigned={isSigned}
          promotionalMaterialList={nonProductPromats}
          promotionalMaterialGroups={promotionalMaterialGroups}
          onChangePromats={onChangePromats}
        />
      )}
    </TabPanel>
  );
};

const TabPanel = styled(MinimalTabPanel)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingBottom: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',

  // Add padding right to see the border of the cards
  paddingRight: '1px',
}));

const FullHeightPlaceholder = styled(NoItemPlaceholder)({
  flexGrow: 1,
});
