import { PersonConsentLifecycleStateDataEnum } from '@ysura/common';

export const getLifecycleLabelColor = (
  lifecycleState: PersonConsentLifecycleStateDataEnum | undefined
) => {
  switch (lifecycleState) {
    case PersonConsentLifecycleStateDataEnum.ACCEPTED:
      return 'success';
    case PersonConsentLifecycleStateDataEnum.REJECTED:
      return 'error';
    case PersonConsentLifecycleStateDataEnum.PENDING_DOUBLE_OPT_IN:
    case PersonConsentLifecycleStateDataEnum.PENDING_PAPER:
      return 'warning';
    default:
      return 'default';
  }
};
