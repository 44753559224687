import { Box, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData, getPersonFullNameWithTitle } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/PageHeader';

type InactiveActivityProps = {
  activity: ActivityData;
};

export const InactiveActivity = ({ activity }: InactiveActivityProps) => {
  const { t } = useTranslation();

  const personTitle = getPersonFullNameWithTitle(
    activity.attendees?.[0]?.person
  );

  return (
    <InactiveActivityWrapper>
      <PageHeader
        isPageInactive={true}
        title={`${activity.activityType?.name} - ${personTitle}`}
        subTitle={personTitle}
        pageHeaderType={t('pages.personView.persons')}
      />
      <Card>
        <InactiveActivityCardContent>
          <Typography variant="h3">
            {t('pages.activity.activityIsInactive')}
          </Typography>
        </InactiveActivityCardContent>
      </Card>
    </InactiveActivityWrapper>
  );
};

const InactiveActivityWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));

const InactiveActivityCardContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.grey[300],
}));
