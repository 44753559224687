import { MutationFunctionOptions, MutationHookOptions } from '@apollo/client';

import {
  UpdatePreferenceMutation,
  UpdatePreferenceMutationVariables,
  useUpdatePreferenceMutation as useUpdatePreferenceMutationApollo,
} from '../generated';

type UpdatePreferenceVariables = Pick<
  UpdatePreferenceMutationVariables,
  'oid' | 'actions'
>;

/**
 * __useUpdatePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreference, { data, loading, error }] = useUpdatePreferenceMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      actions: // values of the actions for each field of a preference.
 *   },
 * });
 */
export function useUpdatePreferenceMutation(
  baseOptions?: MutationHookOptions<
    UpdatePreferenceMutation,
    UpdatePreferenceMutationVariables
  >
) {
  const [updatePreference, status] = useUpdatePreferenceMutationApollo({
    // We invalidate all queries related to preference to refresh the data
    refetchQueries: ['getPreferences', 'getCurrentUserPreference'],

    ...baseOptions,
  });

  const doMutate = async (
    options: MutationFunctionOptions<
      UpdatePreferenceMutation,
      UpdatePreferenceVariables
    >
  ) => {
    const defaultVariables = {
      // placeholders, have to be overwritten when calling the mutate function
      oid: '',
      actions: [],
    };

    return updatePreference({
      ...options,
      variables: { ...defaultVariables, ...(options?.variables ?? {}) },
    });
  };

  return [doMutate, status] as const;
}
