import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type AddRecentAccountMutationVariables = Types.Exact<{
  oid: Types.Scalars['UUID']['input'];
  type: Types.RecentAccountType;
}>;

export type AddRecentAccountMutation = {
  __typename?: 'Mutation';
  addRecentAccount?: boolean | null;
};

export type AddRecentAccountMutationFn = Apollo.MutationFunction<
  AddRecentAccountMutation,
  AddRecentAccountMutationVariables
>;

/**
 * __useAddRecentAccountMutation__
 *
 * To run a mutation, you first call `useAddRecentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecentAccountMutation, { data, loading, error }] = useAddRecentAccountMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddRecentAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRecentAccountMutation,
    AddRecentAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddRecentAccountMutation,
    AddRecentAccountMutationVariables
  >(Operations.addRecentAccount, options);
}
export type AddRecentAccountMutationHookResult = ReturnType<
  typeof useAddRecentAccountMutation
>;
export type AddRecentAccountMutationResult =
  Apollo.MutationResult<AddRecentAccountMutation>;
export type AddRecentAccountMutationOptions = Apollo.BaseMutationOptions<
  AddRecentAccountMutation,
  AddRecentAccountMutationVariables
>;
