import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder } from '@ysura/common';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ActivityDetailCardList } from '@/components/ActivityDetails';
import { OverviewStaticBlock } from '@/components/StaticBlock';
import { errorConfigQueryLoadingError } from '@/hooks/useNotification';
import { useGetTouchpointListByPersonIdQuery } from '@/services/graphql/hooks';
import { parseActivityConnection } from '@/services/graphql/parsers';
import { ActivityConnection, ActivityStatus } from '@/services/graphql/types';

import { TouchpointSkeletonCard } from './TouchpointSkeletonCard';

export const TouchpointsStaticBlock = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const errorCfg = useRef(
    errorConfigQueryLoadingError(t('components.error.touchpoints'))
  );

  const { loading, data, error, refetch } = useGetTouchpointListByPersonIdQuery(
    {
      ...errorCfg.current.context,
      variables: {
        personId: id,
        status: ActivityStatus.PLANNED,
      },
    }
  );

  errorCfg.current.bannerOptions.button = {
    label: t('components.error.reload'),
    action: refetch,
  };

  const activities = parseActivityConnection(
    data?.person?.activities as ActivityConnection
  );

  return (
    <OverviewStaticBlock
      title={t('pages.personView.touchpoints')}
      testId="touchpoint-static-block"
    >
      <CardContentWrapper>
        {loading || error ? (
          <TouchpointSkeletonCard />
        ) : (
          <>
            {!data?.person?.activities.edges ||
            data?.person?.activities.edges?.length === 0 ? (
              <NoItemPlaceholder>
                {t('pages.personView.noTouchpoint')}
              </NoItemPlaceholder>
            ) : null}

            <ActivityDetailCardList activities={activities} />
          </>
        )}
      </CardContentWrapper>
    </OverviewStaticBlock>
  );
};

const CardContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
