import { Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  ActivityStatusDataEnum,
  getPersonFullNameWithTitle,
  OverviewCard,
} from '@ysura/common';
import { useNavigate } from 'react-router-dom';

import {
  ActivityDate,
  ActivityStatusDataBlock,
} from '@/components/ActivityDetails';
import {
  ActivityTypeStack,
  CardHeaderStack,
} from '@/components/ActivityDetails/ActivityDetailStyles';
import { AddressBox } from '@/components/Address';
import { ActivityIcon } from '@/components/Icon/ActivityIcon';

type ActivityCardProps = {
  activity: ActivityData;
};

export const ActivityCard = ({ activity = {} }: ActivityCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isTouchpointClosed = activity?.status === ActivityStatusDataEnum.CLOSED;
  const textColor = isTouchpointClosed
    ? theme.palette.text.disabled
    : theme.palette.text.primary;

  const handleClickActivity = () => {
    navigate(`/touchpoint/${activity.id}`);
  };

  return (
    <OverviewCard testId="activity-card" handleClickCard={handleClickActivity}>
      <StyledStack>
        <CardHeaderStack>
          <ActivityTypeStack>
            <ActivityIcon baseType={activity?.activityType?.baseType} />
            <Typography variant="subtitle2">
              {activity.activityType?.name}
            </Typography>
          </ActivityTypeStack>
          <ActivityStatusDataBlock activity={activity} />
        </CardHeaderStack>

        <ActivityDate activity={activity} />

        {activity?.attendees?.length === 1 && (
          <>
            <Typography variant="subtitle2" color={textColor}>
              {getPersonFullNameWithTitle(activity?.attendees?.[0]?.person)}
            </Typography>
          </>
        )}

        <Typography variant="subtitle2" color={textColor}>
          {activity?.organization?.name}
        </Typography>

        <AddressBox
          address={activity.organization?.addresses?.[0]}
          isTextDisabled={isTouchpointClosed}
        />
      </StyledStack>
    </OverviewCard>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
