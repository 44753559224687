import { gql } from '@apollo/client';

export const getSampleRequestFormDocument = gql`
  query getSampleRequestFormDocument {
    templates(
      filter: { type: { _eq: SAMPLE_REQUEST_FORM }, state: { _eq: ACTIVE } }
      first: 1
    ) {
      filteredCount
      edges {
        node {
          id
          oid
          document {
            id
            oid
            name
            contentLink
            mediaType
          }
        }
      }
    }
  }
`;
