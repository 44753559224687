import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PersonFilterData } from '@ysura/common';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialog } from '@/components/ActionDialog';
import { FilterCategory } from '@/components/FilterCategory';
import { FilterCardSkeleton } from '@/components/GlobalSearch/FilterCardSkeleton';
import {
  countSelectedItemFilters,
  parsePersonFilters,
} from '@/components/GlobalSearch/utils';
import { useGetCurrentUserPreferenceQuery } from '@/services/graphql/hooks';
import { parsePersonPreference } from '@/services/graphql/parsers';

interface MobileEmploymentFilterCardProps {
  isOpen: boolean;
  onClose: (
    personFiltersData: PersonFilterData[],
    showAllEmployments: boolean
  ) => void;
  filtersData: PersonFilterData[];
  onCancel: VoidFunction;
}

export const MobileEmploymentFilterCard = ({
  isOpen,
  onClose,
  filtersData,
  onCancel,
}: MobileEmploymentFilterCardProps) => {
  const { t } = useTranslation();
  const [showAllEmployments, setShowAllEmployments] = useState(false);
  const [personFiltersData, setPersonFiltersData] =
    useState<PersonFilterData[]>(filtersData);

  const { loading } = useGetCurrentUserPreferenceQuery({
    skip: !isOpen || Boolean(personFiltersData.length),
    onCompleted: (data) => {
      const personFacets = parsePersonPreference(
        data.currentUser?.preference?.facets?.personFacets
      );
      setPersonFiltersData(parsePersonFilters(personFacets));
    },
  });

  const handleSetPersonFilterQuery = () => {
    onClose(personFiltersData, showAllEmployments);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowAllEmployments(JSON.parse(event.target.value));
  };

  const onFilterDataChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilterData = [...personFiltersData];
    newFilterData.forEach((filter) => {
      const target = filter.values.find(
        (item) => item.oid === event.target.name
      );
      if (target) {
        target.isSelected = event.target.checked;
      }
    });
    setPersonFiltersData(newFilterData);
  };

  const count = countSelectedItemFilters(personFiltersData);

  return (
    <ActionDialog
      testId={'mobile-filter-card'}
      isOpen={isOpen}
      isCloseButtonEnable={true}
      isLoading={loading}
      primaryButtonText={t('pages.organizationView.personDataCard.filterApply')}
      title={`${t('pages.organizationView.personDataCard.filter')} (${count})`}
      onClickPrimaryButton={handleSetPersonFilterQuery}
      onClickCloseButton={onCancel}
    >
      <Stack>
        <StyleRadioGroup
          row
          data-testid="filter-card-employment-option"
          defaultValue={false}
          name="employment-buttons-group"
          onChange={handleChange}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t(
              'pages.organizationView.personDataCard.currentOrganization'
            )}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t('pages.organizationView.personDataCard.allOrganization')}
          />
        </StyleRadioGroup>
      </Stack>

      <StyleStack>
        {loading ? (
          <FilterCardSkeleton />
        ) : (
          <StyleStack data-testid="person-filter-category">
            {personFiltersData.map((filter) => (
              <FilterCategory
                key={filter.filterType}
                filterType={filter.filterType}
                title={t(
                  `components.globalSearch.filter.type.${filter.filterType}`
                )}
                values={filter.values}
                onChangeFilters={onFilterDataChange}
              />
            ))}
          </StyleStack>
        )}
      </StyleStack>
    </ActionDialog>
  );
};

const StyleRadioGroup = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
}));

const StyleStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: '100%',
  overflowY: 'auto',
}));
