import { gql } from '@apollo/client';

export const getSpecialities = gql`
  query getSpecialities {
    specialities(filter: { state: { _eq: ACTIVE } }) {
      totalCount
      edges {
        node {
          id
          oid
          name
        }
      }
    }
  }
`;
