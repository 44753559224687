import { Card, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder, OrganizationData, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { OrganizationSimpleDataStaticBlock } from '@/components/Organization';

import { OrganizationHierarchyPersonCardList } from './OrganizationHierarchyPersonCardList';

interface OrganizationHierarchyPersonCardProps {
  fetchMorePersons: VoidFunction;
  hasMore: boolean;
  total: number;
  organization?: OrganizationData;
  persons?: Array<PersonData>;
}
export const OrganizationHierarchyPersonCard = ({
  fetchMorePersons,
  hasMore,
  total,
  organization,
  persons,
}: OrganizationHierarchyPersonCardProps) => {
  const { t } = useTranslation();
  if (!organization) {
    return (
      <ParentStack>
        <NoItemPlaceholder>
          {t('pages.organizationView.noSelectedOrganization')}
        </NoItemPlaceholder>
      </ParentStack>
    );
  }

  return (
    <ParentStack data-testid="organization-hierarchy-persons">
      <StyleStack>
        <StyledTypography variant="subtitle2">
          {t('pages.organizationView.selectedOrganization')}
        </StyledTypography>
        <StyledCard>
          <OrganizationSimpleDataStaticBlock organization={organization} />
        </StyledCard>
      </StyleStack>
      <PersonStack>
        <DisableTypography variant="subtitle1">
          {`${t(
            'pages.organizationView.personDataCard.personData'
          )} (${total})`}
        </DisableTypography>
        <OrganizationHierarchyPersonCardList
          persons={persons}
          fetchMorePersons={fetchMorePersons}
          hasMore={hasMore}
        />
      </PersonStack>
    </ParentStack>
  );
};

const ParentStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(1),
  height: '100%',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.text.primary,
}));

const StyleStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}));

const DisableTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  wordBreak: 'break-all',
}));

const PersonStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: '100%',
  overflow: 'hidden',
}));
