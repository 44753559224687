import {
  Box,
  Divider,
  List as MuiList,
  ListItem as MuiListItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { OverviewStaticBlock } from '@/components/StaticBlock';

interface PersonDataSkeletonStaticBlockProps {
  title?: string;
}

export const PersonDataSkeletonStaticBlock = ({
  title,
}: PersonDataSkeletonStaticBlockProps) => {
  const { t } = useTranslation();
  const translatedTitle = title ? t(title) : '';

  return (
    <OverviewStaticBlock
      title={translatedTitle}
      subtitle={
        <Typography variant="subtitle2">
          <Skeleton width={120} />
        </Typography>
      }
      testId="person-data-skeleton"
    >
      <Wrapper>
        <SmallStack>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>
          <Skeleton width={64} height={22} />
        </SmallStack>

        <SmallStack>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>
          <SmallBox>
            <Skeleton width={64} height={22} />
            <Skeleton width={64} height={22} />
          </SmallBox>
        </SmallStack>

        <SmallStack>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>
          <Skeleton width={64} height={22} />
        </SmallStack>

        <Box>
          <Typography variant="caption">
            <Skeleton width={80} />
          </Typography>

          <List>
            <ListItem>
              <TinyStack>
                <Typography variant="caption">
                  <Skeleton width={80} />
                </Typography>
                <Typography variant="caption">
                  <Skeleton width={160} />
                </Typography>
              </TinyStack>

              <Skeleton variant="circular" width={36} height={36} />
            </ListItem>

            <Divider />

            <ListItem>
              <TinyStack>
                <Typography variant="caption">
                  <Skeleton width={80} />
                </Typography>
                <Typography variant="caption">
                  <Skeleton width={160} />
                </Typography>
              </TinyStack>

              <Skeleton variant="circular" width={40} height={40} />
            </ListItem>
          </List>
        </Box>
      </Wrapper>
    </OverviewStaticBlock>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

const TinyStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const List = styled(MuiList)`
  padding: 0;
`;

const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),

  ':last-child': {
    paddingBottom: 0,
  },
}));
