import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NoItemPlaceholder } from '@ysura/common';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DisplaySearchResultCardSkeleton } from '@/components/GlobalSearch/DisplaySearchResultCardSkeleton';

type DisplaySearchResultsProps = {
  itemsLength: number;
  hasMore: boolean;
  fetchMore: VoidFunction;
  filteredCount: number;
  children?: ReactNode;
  closeDialog: VoidFunction;
  searchQuery: string;
  loading: boolean;
  testId?: string;
};

export const DisplaySearchResults = ({
  itemsLength,
  hasMore,
  fetchMore,
  filteredCount,
  children,
  loading,
  testId = 'display-search-results',
}: DisplaySearchResultsProps) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <DisplayResultWrapper>
        <DisplaySearchResultCardSkeleton />
        <DisplaySearchResultCardSkeleton />
        <DisplaySearchResultCardSkeleton />
        <DisplaySearchResultCardSkeleton />
      </DisplayResultWrapper>
    );
  }

  return (
    <Wrapper data-testid={testId} id="global-search-dialog">
      {itemsLength > 0 && (
        <InfiniteScroll
          dataLength={itemsLength ?? 0}
          hasMore={hasMore}
          next={fetchMore}
          scrollThreshold={0.7}
          scrollableTarget="global-search-dialog"
          loader={<DisplaySearchResultCardSkeleton />}
          endMessage={
            <EndMessageWrapper>
              <EndMessage
                data-testid="display-search-results-end"
                variant="body2"
              >
                {`${filteredCount} ${t('components.globalSearch.results')}`}
              </EndMessage>
            </EndMessageWrapper>
          }
        >
          <SearchResultsCardsWrapper>{children}</SearchResultsCardsWrapper>
        </InfiniteScroll>
      )}

      {!itemsLength && (
        <NoItemPlaceholder
          subMessage={t('components.globalSearch.noResultLong')}
        >
          {t('components.globalSearch.noResult')}
        </NoItemPlaceholder>
      )}
    </Wrapper>
  );
};

const DisplayResultWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflow: 'auto',
  height: '75vh',
}));

const SearchResultsCardsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  // added padding in order for the card border to be visible
  padding: 2,
}));

const EndMessageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(2),
}));

const EndMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
