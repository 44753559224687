import { Loader } from '@ysura/common';

import { InteractionLayout } from '@/layouts';

export const RemoteInteractionSkeleton = () => {
  return (
    <InteractionLayout>
      <Loader />
    </InteractionLayout>
  );
};
