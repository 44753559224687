import { useTranslation } from 'react-i18next';

import { MediaSkeletonCard } from '@/components/Media';

import { OverviewStaticBlock } from '../StaticBlock';

export const MediaSkeletonStaticBlock = () => {
  const { t } = useTranslation();

  return (
    <OverviewStaticBlock
      title={t('components.mediaCard.media')}
      testId="media-skeleton-static-block"
    >
      <MediaSkeletonCard />
    </OverviewStaticBlock>
  );
};
