import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataPermissionTypeDataEnum, MediaData } from '@ysura/common';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SortMenu } from '@/components/SortMenu';
import { CUSTOM_BREAKPOINTS } from '@/config/layout';
import { useInteraction } from '@/hooks';
import { useMediaContext } from '@/hooks/state';
import { useGetTouchpointMediaListLazyQuery } from '@/services/graphql/hooks';
import {
  parseMediaConnection,
  parseTopicConnection,
} from '@/services/graphql/parsers';
import { OrderBy } from '@/services/graphql/types';
import {
  LayoutSwitch,
  MediaGrid,
  MediaList,
  MediaNumberText,
} from '@/views/Interaction/ExpandView/Media';
import { applyViewedStatus } from '@/views/Interaction/Overview/Media/helper';

type MediaDialogContentProps = {
  isSharingAvailable: boolean;
  shouldUpdateMediaViewStatus: boolean;
  isDisplayViewedLabel?: boolean;
  onOpenMediaContent(media: MediaData): void;
};

export const MediaListDialogContent = ({
  isSharingAvailable,
  shouldUpdateMediaViewStatus,
  onOpenMediaContent,
  isDisplayViewedLabel = false,
}: MediaDialogContentProps) => {
  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const isInLandscapeScreen = useMediaQuery(
    `@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`
  );
  const isSmallScreen =
    useMediaQuery(theme.breakpoints.down('sm')) || isInLandscapeScreen;

  const { startSharingMedia, viewedMedia, setMediaAsViewed } = useInteraction();

  const {
    mediaLayout,
    mediaOrder,
    mediaQueryString,
    changeMediaOrder,
    changeMediaQueryString,
  } = useMediaContext();
  const { activityId } = useParams();

  // States
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  // Graphql query
  const [searchMedias, { data: searchData, loading }] =
    useGetTouchpointMediaListLazyQuery();

  // Effect
  useEffect(() => {
    const mediaQuerySplitArray = debouncedSearchQuery
      .trim()
      .split(' ')
      .filter((query) => query);
    const mediaQueryFilter = `%${mediaQuerySplitArray.join('%')}%`;

    searchMedias({
      variables: {
        id: activityId ?? '',
        nameFilter: mediaQueryString ? { _like: mediaQueryFilter } : null,
        orderByName: mediaOrder,
      },
    });
  }, [
    searchMedias,
    debouncedSearchQuery,
    mediaOrder,
    activityId,
    mediaQueryString,
  ]);

  // Handlers
  const handleDebounce = (value: string) => {
    setDebouncedSearchQuery(value);
  };

  const handleOpenMediaContent = (media: MediaData) => {
    if (isSharingAvailable) {
      startSharingMedia({ media });
    } else {
      onOpenMediaContent(media);
    }

    if (shouldUpdateMediaViewStatus) {
      media.id && setMediaAsViewed?.(media);
    }
  };

  const handleChangeMediaOrder = (order: OrderBy) => {
    changeMediaOrder?.(order);
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    changeMediaQueryString?.(event.target.value);
    debouncedFunction(event.target?.value);
  };

  const handleClearSearchQuery = () => {
    changeMediaQueryString?.('');
    setDebouncedSearchQuery('');
  };

  // Memo
  const debouncedFunction = useMemo(() => debounce(handleDebounce, 500), []);

  // Variables
  const parsedMedia = searchData?.activity?.activityType?.medias
    ? parseMediaConnection(searchData?.activity?.activityType?.medias)
    : null;
  const parsedTopics = searchData?.activity?.activityType?.topics
    ? parseTopicConnection(searchData?.activity?.activityType?.topics)
    : [];

  const parsedMediaData =
    parsedMedia?.medias.filter(
      (each) =>
        each.permissions?.includes(DataPermissionTypeDataEnum.ASSIGN_MEDIA) &&
        !!each.topics?.some(
          (t) => !!parsedTopics.find((at) => at.oid === t.oid)
        )
    ) ?? [];
  const filteredCount = parsedMediaData?.length ?? 0;
  const medias = applyViewedStatus(parsedMediaData, viewedMedia);

  return (
    <DialogContentStyle>
      <InteractionContainer>
        <StyledFlexSpaceBetween>
          <TextField
            fullWidth={isSmallScreen}
            placeholder={t('pages.interaction.common.search') + '...'}
            size="small"
            value={mediaQueryString}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIconStyle />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ClearButton variant="text" onClick={handleClearSearchQuery}>
                    {mediaQueryString ? t('components.common.clear') : ''}
                  </ClearButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeSearchQuery}
          />

          {!isSmallScreen && <LayoutSwitch isSingleIcon={false} />}
        </StyledFlexSpaceBetween>

        <FlexSpaceBetween>
          <MediaNumberText isLoading={loading} filteredCount={filteredCount} />

          <Flex>
            {isSmallScreen && <LayoutSwitch isSingleIcon />}

            {(mediaLayout === 'grid' || isSmallScreen) && (
              <SortContainer>
                {!isSmallScreen && (
                  <Typography variant="subtitle1">
                    {t('pages.interaction.common.sortBy')}:
                  </Typography>
                )}

                <SortMenu
                  order={mediaOrder}
                  onChangeOrder={handleChangeMediaOrder}
                />
              </SortContainer>
            )}
          </Flex>
        </FlexSpaceBetween>
      </InteractionContainer>

      {mediaLayout === 'grid' && (
        <MainContent>
          <MediaGrid
            isLoading={loading}
            isDisplayViewedLabel={isDisplayViewedLabel}
            mediaData={medias}
            onOpenMediaContent={handleOpenMediaContent}
          />
        </MainContent>
      )}

      {mediaLayout === 'list' && (
        <MediaList
          isLoading={loading}
          mediaData={medias}
          onOpenMediaContent={handleOpenMediaContent}
        />
      )}
    </DialogContentStyle>
  );
};

const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
  height: '100%',

  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      gap: theme.spacing(2),
    },
}));

const FlexSpaceBetween = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

//TODO: Hide Search Input is just temporary solution, revival this after 26 June
const StyledFlexSpaceBetween = styled(FlexSpaceBetween)({
  [`@media only screen and (max-height: ${CUSTOM_BREAKPOINTS.MOBILE_LANDSCAPE_MAX_HEIGHT})`]:
    {
      display: 'none',
    },
});

const Flex = styled(Box)({
  display: 'flex',
});

const InteractionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SearchIconStyle = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: 20,
  height: 20,
}));

const SortContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const MainContent = styled(Box)({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '1px',
  height: '100%',
});

const ClearButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
}));
