import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type RevokePersonConsentMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
  issuerPersonId: Types.Scalars['PersonId']['input'];
  actionAt: Types.Scalars['DateTime']['input'];
}>;

export type RevokePersonConsentMutation = {
  __typename?: 'Mutation';
  revokePersonConsent?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type RevokePersonConsentMutationFn = Apollo.MutationFunction<
  RevokePersonConsentMutation,
  RevokePersonConsentMutationVariables
>;

/**
 * __useRevokePersonConsentMutation__
 *
 * To run a mutation, you first call `useRevokePersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokePersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokePersonConsentMutation, { data, loading, error }] = useRevokePersonConsentMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      issuerPersonId: // value for 'issuerPersonId'
 *      actionAt: // value for 'actionAt'
 *   },
 * });
 */
export function useRevokePersonConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokePersonConsentMutation,
    RevokePersonConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RevokePersonConsentMutation,
    RevokePersonConsentMutationVariables
  >(Operations.revokePersonConsent, options);
}
export type RevokePersonConsentMutationHookResult = ReturnType<
  typeof useRevokePersonConsentMutation
>;
export type RevokePersonConsentMutationResult =
  Apollo.MutationResult<RevokePersonConsentMutation>;
export type RevokePersonConsentMutationOptions = Apollo.BaseMutationOptions<
  RevokePersonConsentMutation,
  RevokePersonConsentMutationVariables
>;
