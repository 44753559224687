import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useMediaContext } from '@/hooks/state';

type LayoutSwitchProps = {
  isSingleIcon: boolean;
};

export const LayoutSwitch = ({ isSingleIcon }: LayoutSwitchProps) => {
  const { mediaLayout, changeMediaLayout } = useMediaContext();

  return isSingleIcon ? (
    <IconButtonStyle
      isActive={true}
      onClick={() =>
        changeMediaLayout?.(mediaLayout === 'list' ? 'grid' : 'list')
      }
    >
      {mediaLayout === 'list' ? (
        <ViewListRoundedIcon />
      ) : (
        <ViewModuleRoundedIcon />
      )}
    </IconButtonStyle>
  ) : (
    <Box>
      <IconButtonStyle
        isActive={mediaLayout === 'list'}
        onClick={() => changeMediaLayout?.('list')}
      >
        <ViewListRoundedIcon />
      </IconButtonStyle>

      <IconButtonStyle
        isActive={mediaLayout === 'grid'}
        onClick={() => changeMediaLayout?.('grid')}
      >
        <ViewModuleRoundedIcon />
      </IconButtonStyle>
    </Box>
  );
};

const IconButtonStyle = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.text.disabled,
}));
