import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  IconButton,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { ConfigTabPanelProps, TabsPanel } from '@ysura/common';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActivitySearchTab,
  OrganizationSearchTab,
  PersonSearchTab,
} from '@/components/GlobalSearch';
import { GLOBAL_SEARCH } from '@/config/layout';
import { getScrollbarWidth } from '@/utils/scrollbar';

type SearchDialogProps = {
  isOpen: boolean;
  isMobileSearchActive: boolean;
  searchQuery: string;
  closeDialog: VoidFunction;
  changeSearchQuery: (value: string) => void;
};

export const SearchDialog = ({
  isOpen,
  isMobileSearchActive,
  searchQuery,
  closeDialog,
  changeSearchQuery,
}: SearchDialogProps) => {
  const { t } = useTranslation();

  // State setters
  const [showMobileFilterCard] = useState(false);

  // Variables
  const scrollbarWidth = getScrollbarWidth();

  // Handlers
  const handleClickBackdrop = () => {
    if (!isMobileSearchActive) {
      closeDialog();
    }
  };

  const handleClickClose = () => {
    closeDialog();
  };

  const personSearchTab: ReactNode = (
    <PersonSearchTab
      isOpen={isOpen}
      isMobileSearchActive={isMobileSearchActive}
      searchQuery={searchQuery}
      changeSearchQuery={changeSearchQuery}
      closeDialog={closeDialog}
    />
  );

  const organizationSearchTab: ReactNode = (
    <OrganizationSearchTab
      isOpen={isOpen}
      isMobileSearchActive={isMobileSearchActive}
      searchQuery={searchQuery}
      changeSearchQuery={changeSearchQuery}
      closeDialog={closeDialog}
    />
  );

  // Defines tabs: Person, Organization and Activity
  const tabs: ConfigTabPanelProps[] = [
    {
      index: 0,
      label: t('components.globalSearch.tabs.person'),
      children: personSearchTab,
      visible: true,
    },
    {
      index: 1,
      label: t('components.globalSearch.tabs.organization'),
      children: organizationSearchTab,
      visible: true,
    },
    {
      index: 2,
      label: t('components.globalSearch.tabs.activity'),
      children: <ActivitySearchTab />,
      visible: false, //Not define yet
    },
  ];

  return (
    <Dialog
      open={isOpen}
      isMobileSearchActive={isMobileSearchActive}
      scrollbarWidth={scrollbarWidth}
      data-testid="search-dialog"
      onBackdropClick={handleClickBackdrop}
    >
      <DialogHeader
        isMobileSearchActive={isMobileSearchActive}
        showMobileFilterCard={showMobileFilterCard}
        data-testid="header-search-dialog"
      >
        {isMobileSearchActive && (
          <IconButton onClick={handleClickClose}>
            <IconClose />
          </IconButton>
        )}
      </DialogHeader>

      <DialogContent data-testid="content-search-dialog">
        <TabsPanel tabs={tabs} />
      </DialogContent>
    </Dialog>
  );
};

type DialogProps = {
  scrollbarWidth: number;
  isMobileSearchActive: boolean;
};

type MarginCalcProps = DialogProps & {
  theme: Theme;
};

const getDialogRightMargin = ({
  scrollbarWidth,
  isMobileSearchActive,
  theme,
}: MarginCalcProps) => {
  // There should be empty space on the side of the dialog if is a small screen device
  if (isMobileSearchActive) {
    return theme.spacing(2);
  }

  /**
   * If scrollbar width is 15 (default scrollbar width), we
   * should add marginRight so the search field inside the dialog
   * doesn't "jump" compared to the search field inside the header.
   */
  if (scrollbarWidth === 15) {
    return scrollbarWidth;
  }

  // In all other cases, we do not need the right margin.
  return 0;
};

const Dialog = styled(MuiDialog, {
  shouldForwardProp: (prop) =>
    !(prop === 'scrollbarWidth' || prop === 'isMobileSearchActive'),
})<DialogProps>(({ theme, scrollbarWidth, isMobileSearchActive }) => ({
  '& .MuiDialog-container': {
    // Makes the dialog be positioned on the top of the page.
    alignItems: 'flex-start',
  },
  '& .MuiDialog-paper': {
    fullWidth: true,
    borderRadius: 8,
    maxWidth: 'none',
    width: isMobileSearchActive
      ? '100%'
      : GLOBAL_SEARCH.DIALOG.DESKTOP.WIDTH_LARGE,
    maxHeight: GLOBAL_SEARCH.DIALOG.DESKTOP.MAX_HEIGHT,

    margin: isMobileSearchActive ? theme.spacing(2) : 0,
    marginTop: 0,
    marginRight: getDialogRightMargin({
      scrollbarWidth,
      isMobileSearchActive,
      theme,
    }),

    [theme.breakpoints.down('lg')]: {
      width: GLOBAL_SEARCH.DIALOG.DESKTOP.WIDTH_MEDIUM,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

type HeaderStyleProps = {
  isMobileSearchActive: boolean;
  showMobileFilterCard: boolean;
};

const DialogHeader = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'isMobileSearchActive' && prop !== 'showMobileFilterCard',
})<HeaderStyleProps>(
  ({ theme, isMobileSearchActive, showMobileFilterCard }) => ({
    display: showMobileFilterCard ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: theme.spacing(1),
    paddingRight: isMobileSearchActive ? theme.spacing(1) : theme.spacing(2),

    /**
     * Specific paddingTop is needed so the search field inside the
     * header is in the same line as the search field in the dialog.
     */
    paddingTop: GLOBAL_SEARCH.DIALOG.DESKTOP.PADDING_TOP_LARGE,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
    },
  })
);

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0),
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(-2),
  },
}));

const IconClose = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
