import {
  CommunicationDataBaseTypeDataEnum,
  CommunicationDataData,
  ConsentTypeData,
  GenderDataEnum,
  PersonData,
} from '@ysura/common';
import md5 from 'md5';

export const getReceiverPlaceholders = (receiver?: PersonData) => {
  return {
    RECEIVER_ID: receiver?.oid ?? '',
    RECEIVER_ACADEMIC_TITLE: receiver?.prefixAcademicTitle ?? '',
    RECEIVER_FIRST_NAME: receiver?.firstName ?? '',
    RECEIVER_LAST_NAME: receiver?.lastName ?? '',
    RECEIVER_GENDER: receiver?.gender ?? '',
    RECEIVER_IS_FEMALE: GenderDataEnum.FEMALE ? 'true' : 'false',
    RECEIVER_IS_MALE: GenderDataEnum.MALE ? 'true' : 'false',
    RECEIVER_CUSTOMER_NUMBER: receiver?.customerReference ?? '',
    RECEIVER_CUSTOMER_NUMBER_MD5: receiver?.customerReference
      ? md5(receiver.customerReference)
      : '',
    RECEIVER_EMAIL_ADDRESS: findFirstCommunicationDataOfType(
      receiver?.communicationData ?? [],
      CommunicationDataBaseTypeDataEnum.EMAIL
    ),
    RECEIVER_FAX_NUMBER: findFirstCommunicationDataOfType(
      receiver?.communicationData ?? [],
      CommunicationDataBaseTypeDataEnum.FAX
    ),
    RECEIVER_PHONE_NUMBER: findFirstCommunicationDataOfType(
      receiver?.communicationData ?? [],
      CommunicationDataBaseTypeDataEnum.PHONE
    ),
    RECEIVER_CELLPHONE_NUMBER: findFirstCommunicationDataOfType(
      receiver?.communicationData ?? [],
      CommunicationDataBaseTypeDataEnum.PHONE
    ),
    RECEIVER_TYPE: receiver?.types?.length ? receiver?.types[0] : '',
    RECEIVER_SPECIALITY: receiver?.specialities?.length
      ? receiver?.specialities[0]
      : '',
  };
  // those 3 cannot be mapped for now as the parser converts specialities to string
  // RECEIVER_HF_SPECIALITIES: 'RECEIVER_HF_SPECIALITIES',
  // RECEIVER_ZF_SPECIALITIES: 'RECEIVER_ZF_SPECIALITIES',
  // RECEIVER_SP_SPECIALITIES: 'RECEIVER_SP_SPECIALITIES',
};

export const getSenderPlaceholders = (sender?: PersonData) => {
  return {
    SENDER_ID: sender?.oid ?? '',
    SENDER_ACADEMIC_TITLE: sender?.prefixAcademicTitle ?? '',
    SENDER_FIRST_NAME: sender?.firstName ?? '',
    SENDER_LAST_NAME: sender?.lastName ?? '',
    SENDER_GENDER: sender?.gender ?? '',
    SENDER_IS_FEMALE: GenderDataEnum.FEMALE ? 'true' : 'false',
    SENDER_IS_MALE: GenderDataEnum.MALE ? 'true' : 'false',
    SENDER_CUSTOMER_NUMBER: sender?.customerReference ?? '',
    SENDER_CUSTOMER_NUMBER_MD5: sender?.customerReference
      ? md5(sender.customerReference)
      : '',
    SENDER_EMAIL_ADDRESS: findFirstCommunicationDataOfType(
      sender?.communicationData ?? [],
      CommunicationDataBaseTypeDataEnum.EMAIL
    ),
    SENDER_CELLPHONE_NUMBER: findFirstCommunicationDataOfType(
      sender?.communicationData ?? [],
      CommunicationDataBaseTypeDataEnum.PHONE
    ),
  };
};

export const getConsentTypePlaceholders = (consentType?: ConsentTypeData) => {
  return {
    CONSENT_TYPE_NAME: consentType?.name ?? '',
    CONSENT_TYPE_DESCRIPTION: consentType?.description ?? '',
  };
};
const findFirstCommunicationDataOfType = (
  data: Array<CommunicationDataData>,
  baseType: CommunicationDataBaseTypeDataEnum
): string => {
  return (
    data.find((item) => item.communicationDataType?.baseType == baseType)
      ?.value ?? ''
  );
};
