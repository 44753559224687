import { ActivityData } from '@ysura/common';

import { useInteraction } from '@/hooks';
import { EndInteraction } from '@/views/Interaction/EndInteraction';

type EndTelephoneInteractionDialogProps = {
  isOpen: boolean;
  activity: ActivityData | null;
  closeDialog: VoidFunction;
};

export const EndTelephoneInteractionDialog = ({
  isOpen,
  activity,
  closeDialog,
}: EndTelephoneInteractionDialogProps) => {
  // Hooks
  const { organizerCloseSession } = useInteraction();

  const handleEndInteraction = () => {
    organizerCloseSession();
  };

  return (
    <EndInteraction
      activity={activity}
      isOpen={isOpen}
      testId="end-telehpone-interaction-dialog"
      onUpdateAndSaveAndExit={handleEndInteraction}
      onEndWithoutSaving={handleEndInteraction}
      onClose={closeDialog}
    />
  );
};
