export const cutMediaDescription = (text: string | undefined) => {
  if (!text) {
    return '';
  }

  if (text.length <= 140) {
    return text;
  }

  return `${text.slice(0, 140).trim()}...`;
};
