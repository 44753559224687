import { DataPermissionTypeDataEnum, UserData } from '@ysura/common';

import { MediaDataPermissions } from '@/services/user/permissions/DataPermissions';
import { hasDataPermission } from '@/services/user/permissions/helper';

export const getMediaDataPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): MediaDataPermissions => {
  return {
    canAddMediaTracking: hasDataPermission(
      apiUserData,
      isAdmin,
      DataPermissionTypeDataEnum.ADD_MEDIA_TRACKING_INFO
    ),
  };
};
