import { F2FInteractionSkeleton } from '@/views/Interaction/F2F/F2FInteractionSkeleton';
import { RemoteInteractionSkeleton } from '@/views/Interaction/Remote/RemoteInteractionSkeleton';

type InteractionPageSkeletonProps = {
  interactionType: string;
};

export const InteractionPageSkeleton = ({
  interactionType,
}: InteractionPageSkeletonProps) => {
  if (interactionType === 'f2f') {
    return <F2FInteractionSkeleton />;
  }

  return <RemoteInteractionSkeleton />;
};
