import {
  ActivityStatusDataEnum,
  ActivityTypeSchedulingDataEnum,
} from '@ysura/common';

import { useFormatDate } from '@/hooks';

export const useFormatActivityDate = (
  startDate?: string | null,
  startDateTime?: string | null,
  endDate?: string | null,
  endDateTime?: string | null,
  status?: ActivityStatusDataEnum,
  schedulingType?: ActivityTypeSchedulingDataEnum,
  hideTimeForClosedActivity?: boolean
): string => {
  const { formatDate } = useFormatDate();

  // maybe activity type supports time but the activity was saved without time or time should not be shown for closed status
  const hasNoTime =
    !startDateTime ||
    (status === ActivityStatusDataEnum.CLOSED && hideTimeForClosedActivity);

  const formatOnlyStartDate = () => {
    if (
      schedulingType === ActivityTypeSchedulingDataEnum.START_DATE ||
      (schedulingType === ActivityTypeSchedulingDataEnum.START_DATE_TIME &&
        hasNoTime)
    ) {
      const localStartDate = startDate
        ? `${formatDate(new Date(startDate), 'PP')}`
        : '';

      return `${localStartDate}`;
    } else {
      return '';
    }
  };

  const formatOnlyStartDateAndTime = () => {
    if (schedulingType === ActivityTypeSchedulingDataEnum.START_DATE_TIME) {
      const localStartDate = startDateTime
        ? `${formatDate(new Date(startDateTime), 'PP')}`
        : '';
      const localStartTime = startDateTime
        ? `${formatDate(new Date(startDateTime), 'p')}`
        : '';

      return `${localStartDate} ${localStartTime}`;
    } else {
      return '';
    }
  };

  const formatStartAndEndDate = () => {
    if (
      schedulingType === ActivityTypeSchedulingDataEnum.START_AND_END_DATE ||
      (schedulingType ===
        ActivityTypeSchedulingDataEnum.START_AND_END_DATE_TIME &&
        hasNoTime)
    ) {
      const localStartDate = startDate
        ? `${formatDate(new Date(startDate), 'PP')}`
        : '';
      const localEndDate = endDate
        ? `${formatDate(new Date(endDate), 'PP')}`
        : '';

      return `${localStartDate} - ${localEndDate}`;
    } else {
      return '';
    }
  };

  const formatStartAndEndDateAndTime = () => {
    if (
      schedulingType === ActivityTypeSchedulingDataEnum.START_AND_END_DATE_TIME
    ) {
      const localStartDate = startDateTime
        ? `${formatDate(new Date(startDateTime), 'PP')}`
        : '';
      const localEndDate = endDateTime
        ? `${formatDate(new Date(endDateTime), 'PP')}`
        : '';
      const localStartTime = startDateTime
        ? `${formatDate(new Date(startDateTime), 'p')}`
        : '';
      const localEndTime = endDateTime
        ? `${formatDate(new Date(endDateTime), 'p')}`
        : '';

      return localStartDate == localEndDate
        ? `${localStartDate} ( ${localStartTime} - ${localEndTime} )`
        : `${localStartDate} ${localStartTime} - ${localEndDate} ${localEndTime}`;
    } else {
      return '';
    }
  };

  return formatOnlyStartDate()
    ? formatOnlyStartDate()
    : formatOnlyStartDateAndTime()
    ? formatOnlyStartDateAndTime()
    : formatStartAndEndDate()
    ? formatStartAndEndDate()
    : formatStartAndEndDateAndTime()
    ? formatStartAndEndDateAndTime()
    : '';
};
