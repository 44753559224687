import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MediaData } from '@ysura/common';

import { InteractionMediaCard } from '@/components/Media';

type MediaCardListProps = {
  mediaList: Array<MediaData>;
  isSharingAvailable?: boolean;
  isDisplayReactions?: boolean;
  isReactionsEditable?: boolean;
  isMediaClickable?: boolean;
  isDisplayViewedLabel?: boolean;
  onChangePresentationDialogState?: (val: boolean) => void;
  onChangeMediaWithDocument?: (media: MediaData) => void;
};

export const MediaCardList = ({
  mediaList,
  isSharingAvailable = false,
  isDisplayReactions = false,
  isReactionsEditable = false,
  isMediaClickable = true,
  isDisplayViewedLabel = false,
  onChangePresentationDialogState,
  onChangeMediaWithDocument,
}: MediaCardListProps) => {
  return (
    <MediaItemWrapper>
      {mediaList?.map((media) => (
        <InteractionMediaCard
          key={media?.id}
          isViewedStatusUpdatedOnOpen
          variant="long"
          isDisplayViewedLabel={isDisplayViewedLabel}
          isMediaClickable={isMediaClickable}
          isViewed={!!media?.isViewed}
          media={media}
          isDisplayReactions={isDisplayReactions}
          isReactionsEditable={isReactionsEditable}
          isSharingAvailable={isSharingAvailable}
          onChangePresentationDialogState={onChangePresentationDialogState}
          onChangeMediaWithDocument={onChangeMediaWithDocument}
        />
      ))}
    </MediaItemWrapper>
  );
};

const MediaItemWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
