import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ConsentInputFieldData,
  ConsentItemData,
  ConsentItemStateChangeDataEnum,
  ConsentValue,
  CountryData,
  getConsentInputName,
  getValidationSchema,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type CoOrganizerConsentCommunicationCollectionProps = {
  consentItemsCommunicationData: Array<ConsentItemData>;
  consentValues: ConsentValue;
  defaultCountry?: CountryData;
  disable?: boolean;
};
export const CoOrganizerConsentCommunicationCollection = ({
  consentItemsCommunicationData,
  consentValues,
  defaultCountry,
  disable,
}: CoOrganizerConsentCommunicationCollectionProps) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [expandedConsentItems, setExpandedConsentItems] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    const updatedExpandedConsentItems = consentItemsCommunicationData
      .map((consentItem) => {
        if (
          consentItem?.defaultItemState ===
            ConsentItemStateChangeDataEnum.TURN_ON ||
          consentValues?.[consentItem.inputId ?? '']
        ) {
          return consentItem.inputId ?? '';
        }

        return '';
      })
      .filter(Boolean);

    const schema: Record<string, Yup.AnySchema> = getValidationSchema(
      consentItemsCommunicationData,
      updatedExpandedConsentItems,
      defaultCountry
    );

    const validate = async (field: string, value: string | boolean) => {
      await schema[field]
        ?.validate(value)
        .then(() => {
          delete errors[field];
        })
        .catch((err: Yup.ValidationError) => {
          errors[field] = err.errors[0];
          setErrors(errors);
        });
    };

    Object.keys(consentValues).forEach((field: string) => {
      validate(field, consentValues?.[field]);
    });

    setExpandedConsentItems([...updatedExpandedConsentItems]);
  }, [consentItemsCommunicationData, consentValues, defaultCountry, errors]);

  return (
    <>
      {consentItemsCommunicationData.map((consentItem) => {
        const consentItemInputId = consentItem?.inputId ?? '';

        return (
          <ConsentItemGrid key={consentItemInputId} item xs={12}>
            <ConsentItemCheckbox
              control={
                <Checkbox
                  id={consentItemInputId}
                  disabled={disable}
                  data-testid={`consent-${consentItemInputId}`}
                  checked={!!consentValues?.[consentItemInputId]}
                />
              }
              label={
                <ConsentItemLabel>
                  <Typography variant="caption">
                    {t('components.consentDialog.agreeTo', {
                      consentItem: consentItem?.name,
                    })}
                  </Typography>

                  <ConsentItemDescription variant="caption">
                    {consentItem?.description}
                  </ConsentItemDescription>
                </ConsentItemLabel>
              }
            />

            {consentItemInputId &&
              expandedConsentItems.includes(consentItemInputId) && (
                <Grid container spacing={{ md: 3, xs: 2 }}>
                  {consentItem?.consentInputFields?.map(
                    (consentInput: ConsentInputFieldData) => {
                      const consentInputName = getConsentInputName(
                        consentItem,
                        consentInput
                      );

                      const errorText = errors[consentInputName];

                      return (
                        <ConsentInputWrapper
                          key={consentInputName}
                          item
                          md={6}
                          xs={12}
                        >
                          <StyleFormHelperText>
                            {consentInput.description}
                          </StyleFormHelperText>

                          <InputField
                            fullWidth
                            disabled={disable}
                            id={consentInputName}
                            data-testid={`consent-${consentInputName}`}
                            label={consentInput?.label}
                            error={!!errorText}
                            helperText={t(errorText)}
                            required={consentInput?.isMandatory}
                            value={consentValues?.[consentInputName]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </ConsentInputWrapper>
                      );
                    }
                  )}
                </Grid>
              )}
          </ConsentItemGrid>
        );
      })}
    </>
  );
};

const ConsentItemGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ConsentItemCheckbox = styled(FormControlLabel)({
  width: 'fit-content',
  alignItems: 'flex-start',
});

const ConsentItemLabel = styled(Box)({
  position: 'relative',
});

const ConsentItemDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'block',
  whiteSpace: 'pre-line',
}));

const ConsentInputWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const InputField = styled(TextField)(({ theme }) => ({
  '.MuiInputLabel-asterisk': {
    color: theme.palette.error.main,
  },
}));

const StyleFormHelperText = styled(FormHelperText)(() => ({
  whiteSpace: 'pre-line',
}));
