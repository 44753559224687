import { UserData } from '@ysura/common';

import { ActivityClientPermissions } from '@/services/user/permissions/ClientPermissions';
import { hasClientPermission } from '@/services/user/permissions/helper';

export const getActivityPermissions = (
  apiUserData?: UserData,
  isAdmin?: boolean
): ActivityClientPermissions => {
  return {
    canCreateActivity: hasClientPermission(
      apiUserData,
      isAdmin,
      'activity-edit'
    ),
    canEditActivity: hasClientPermission(apiUserData, isAdmin, 'activity-edit'),
    canSealActivity: hasClientPermission(apiUserData, isAdmin, 'activity-seal'),
    canReadActivity: hasClientPermission(apiUserData, isAdmin, 'activity-read'),
    canEditActivityMedia: hasClientPermission(
      apiUserData,
      isAdmin,
      'activity-media-edit'
    ),
    canReadActivityComment: hasClientPermission(
      apiUserData,
      isAdmin,
      'activity-comments-read'
    ),
    canEditActivityComment: hasClientPermission(
      apiUserData,
      isAdmin,
      'activity-comments-edit'
    ),
    canUseRemoteInteraction: hasClientPermission(
      apiUserData,
      isAdmin,
      'remoteInteraction-use'
    ),
    canUseRemoteInteractionScreenShare: hasClientPermission(
      apiUserData,
      isAdmin,
      'remoteInteraction-screenSharing-use'
    ),
    canUseF2FInteraction: hasClientPermission(
      apiUserData,
      isAdmin,
      'directContactInteraction-use'
    ),
    canUseTelephoneInteraction: hasClientPermission(
      apiUserData,
      isAdmin,
      'telephoneInteraction-use'
    ),
    canUseTelephoneInteractionScreenShare: hasClientPermission(
      apiUserData,
      isAdmin,
      'telephoneInteraction-screenSharing-use'
    ),
  };
};
