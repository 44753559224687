import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type CollectDigitalPersonConsentMutationVariables = Types.Exact<{
  oid: Types.Scalars['PersonConsentId']['input'];
  consentTypeId: Types.Scalars['ConsentTypeId']['input'];
  issuerPersonId: Types.Scalars['PersonId']['input'];
  signerPersonId: Types.Scalars['PersonId']['input'];
  actionAt: Types.Scalars['DateTime']['input'];
  content: Types.Scalars['String']['input'];
}>;

export type CollectDigitalPersonConsentMutation = {
  __typename?: 'Mutation';
  collectDigitalPersonConsent?: {
    __typename?: 'PersonConsent';
    id: string;
    oid?: string | null;
  } | null;
};

export type CollectDigitalPersonConsentMutationFn = Apollo.MutationFunction<
  CollectDigitalPersonConsentMutation,
  CollectDigitalPersonConsentMutationVariables
>;

/**
 * __useCollectDigitalPersonConsentMutation__
 *
 * To run a mutation, you first call `useCollectDigitalPersonConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollectDigitalPersonConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collectDigitalPersonConsentMutation, { data, loading, error }] = useCollectDigitalPersonConsentMutation({
 *   variables: {
 *      oid: // value for 'oid'
 *      consentTypeId: // value for 'consentTypeId'
 *      issuerPersonId: // value for 'issuerPersonId'
 *      signerPersonId: // value for 'signerPersonId'
 *      actionAt: // value for 'actionAt'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCollectDigitalPersonConsentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CollectDigitalPersonConsentMutation,
    CollectDigitalPersonConsentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CollectDigitalPersonConsentMutation,
    CollectDigitalPersonConsentMutationVariables
  >(Operations.collectDigitalPersonConsent, options);
}
export type CollectDigitalPersonConsentMutationHookResult = ReturnType<
  typeof useCollectDigitalPersonConsentMutation
>;
export type CollectDigitalPersonConsentMutationResult =
  Apollo.MutationResult<CollectDigitalPersonConsentMutation>;
export type CollectDigitalPersonConsentMutationOptions =
  Apollo.BaseMutationOptions<
    CollectDigitalPersonConsentMutation,
    CollectDigitalPersonConsentMutationVariables
  >;
