import { PostalCodeCityData } from '@ysura/common';

import {
  PostalCodeCity,
  PostalCodeCityConnection,
} from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parsePostalCodeCityData = (
  postalCodeCity: DeepPartial<PostalCodeCity>
): PostalCodeCityData => {
  return {
    id: postalCodeCity.id ?? '',
    oid: postalCodeCity.oid ?? '',
    customerReference: postalCodeCity.customerReference ?? '',
    postalCode: postalCodeCity.postalCode ?? '',
    city: postalCodeCity.city ?? '',
  };
};

export const parsePostalCodeCityConnection = (
  postalCodeCityConnection: DeepPartial<PostalCodeCityConnection>
): Array<PostalCodeCityData> => {
  const rawCities = postalCodeCityConnection?.edges ?? [];

  const parsedCities = rawCities
    .map((edge) => edge?.node ?? null)
    .map((city) => (city ? parsePostalCodeCityData(city) : null));

  return filterNonNull(parsedCities);
};
