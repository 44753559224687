import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';

import { ActivityDetailCard } from './ActivityDetailCard';

type ActivityDetailCardListProps = {
  activities: Array<ActivityData>;
};

export const ActivityDetailCardList = ({
  activities,
}: ActivityDetailCardListProps) => {
  return (
    <Wrapper>
      {activities.map((activity) => (
        <ActivityDetailCard
          key={activity.oid}
          isLink
          activity={activity}
          variant={'dense'}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));
