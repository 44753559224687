// eslint-disable-next-line no-unused-vars
type Fn<T> = (a: T) => void;

export class Observable<T> {
  private observers: Fn<T>[];
  constructor() {
    this.observers = [];
  }
  subscribe(func: Fn<T>) {
    this.observers.push(func);
  }

  unsubscribe(func: Fn<T>) {
    this.observers = this.observers.filter((observer) => observer !== func);
  }

  notify(response: object) {
    this.observers.forEach((observer) => observer(<T>response));
  }
}
