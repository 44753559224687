import { TouchPointStatisticsData } from '@ysura/common';

import { Person, PersonTouchPointStatistics } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

export const parseTouchPointStatisticData = (
  person?: DeepPartial<Person> | null
): Array<TouchPointStatisticsData> => {
  return filterNonNull(person?.touchPointStatistics).map(
    (touchPointStatistic: DeepPartial<PersonTouchPointStatistics>) => {
      return {
        touchPointGroup: {
          id: touchPointStatistic.touchPointGroup?.id,
          oid: touchPointStatistic.touchPointGroup?.oid,
          name: touchPointStatistic.touchPointGroup?.name?.toLowerCase(),
          state: touchPointStatistic.touchPointGroup?.state,
        },
        currentPlan: touchPointStatistic.currentPlan ?? undefined,
        currentPlanExecution:
          touchPointStatistic.currentPlanExecution ?? undefined,
      };
    }
  );
};
