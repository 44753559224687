import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { ActivityData } from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityCard } from '@/views/CalendarActivities/ActivityCard';

type ResponsiveCardListProps = {
  activityList: Array<ActivityData>;
};

export const ResponsiveCardList = ({
  activityList,
}: ResponsiveCardListProps) => {
  const { t } = useTranslation();
  const isMediaQueryMatched = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [isShowFullActivities, setIsShowFullActivities] = useState(false);

  const handleClickButton = () => {
    setIsShowFullActivities((prev) => !prev);
  };

  // In mobile, default will show only 2 activity, when click button <Show more> will
  // show all activities
  const mobileActivityList: Array<ActivityData> = isShowFullActivities
    ? activityList
    : activityList.slice(0, 2);

  return !isMediaQueryMatched || activityList.length <= 2 ? (
    <>
      {activityList.map((activity) => (
        <ActivityCard key={activity.id} activity={activity} />
      ))}
    </>
  ) : (
    <>
      <MobileCardListContainer>
        {mobileActivityList.map((activity) => (
          <ActivityCard key={activity.id} activity={activity} />
        ))}
      </MobileCardListContainer>
      <Button variant="outlined" onClick={handleClickButton}>
        <Typography variant="button" textTransform={'uppercase'}>
          {isShowFullActivities
            ? t('pages.homepage.touchpointList.showLess')
            : t('pages.homepage.touchpointList.showMore')}
        </Typography>
      </Button>
    </>
  );
};

const MobileCardListContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1),
}));
