import MedicationIcon from '@mui/icons-material/Medication';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  IconWrapper,
  Label,
  PersonPromotionalMaterialData,
} from '@ysura/common';
import { useTranslation } from 'react-i18next';

type PromotionalMaterialCardProps = {
  personPromotionalMaterial: PersonPromotionalMaterialData;
  isLastSample: boolean;
};

export const PromotionalMaterial = ({
  personPromotionalMaterial,
  isLastSample,
}: PromotionalMaterialCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    promotionalMaterial,
    maximumQuantityInCurrentPeriod,
    givenQuantityInCurrentPeriod,
  } = personPromotionalMaterial;

  const availableQuantity =
    (maximumQuantityInCurrentPeriod ?? 0) - (givenQuantityInCurrentPeriod ?? 0);
  const isMaxAmountReached =
    !!maximumQuantityInCurrentPeriod && !availableQuantity;
  const labelColor = isMaxAmountReached ? 'error' : 'default';
  const availableLabelContent = maximumQuantityInCurrentPeriod
    ? `${t('pages.personView.available')}: ${availableQuantity}` +
      `/${maximumQuantityInCurrentPeriod}`
    : '';

  return (
    <>
      <Wrapper isDisabled={isMaxAmountReached} data-testid="promotional-sample">
        <TitleLine>
          <IconWrapper
            isDisabled={isMaxAmountReached}
            size={20}
            backgroundColor={theme.palette.info.dark}
          >
            <Icon />
          </IconWrapper>
          <Typography variant="subtitle2">
            {promotionalMaterial.name}
          </Typography>
        </TitleLine>

        {maximumQuantityInCurrentPeriod && (
          <Box>
            <Label color={labelColor}>{availableLabelContent}</Label>
          </Box>
        )}
      </Wrapper>

      {!isLastSample && <Divider />}
    </>
  );
};

type DisabledProps = {
  isDisabled: boolean;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<DisabledProps>(({ theme, isDisabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  color: isDisabled ? theme.palette.text.disabled : 'inherit',
}));

const TitleLine = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const Icon = styled(MedicationIcon)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.common.white,
}));
