import { NetworkStatus } from '@apollo/client';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  EntityFilterTypeDataEnum,
  FilterItemsData,
  OrganizationData,
} from '@ysura/common';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import {
  DesktopContentSearchCard,
  DisplaySearchResults,
  FilterCard,
  FilterInputSearch,
  MobileSearchCard,
} from '@/components/GlobalSearch';
import { OrganizationSearchResultCard } from '@/components/GlobalSearch/organization/';
import {
  countSelectedItemFilters,
  NUMBER_OF_ORGANIZATIONS_TO_SEARCH,
  parseOrganizationFilters,
} from '@/components/GlobalSearch/utils';
import { errorConfigBannerDialog } from '@/hooks';
import { buildOrganizationFilterQuery } from '@/services/graphql/helpers';
import {
  useGetCurrentUserPreferenceQuery,
  useGetSearchedOrganizationsLazyQuery,
} from '@/services/graphql/hooks';
import {
  parseOrganizationConnection,
  parseOrganizationPreference,
} from '@/services/graphql/parsers';
import { Scope } from '@/services/graphql/types';

type OrganizationSearchTabProps = {
  isOpen: boolean;
  isMobileSearchActive: boolean;
  searchQuery: string;
  closeDialog: VoidFunction;
  changeSearchQuery: (value: string) => void;
};

export const OrganizationSearchTab = ({
  isOpen,
  isMobileSearchActive,
  searchQuery,
  closeDialog,
  changeSearchQuery,
}: OrganizationSearchTabProps) => {
  // Hooks
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [organizationFiltersData, setOrganizationFiltersData] =
    useState<FilterItemsData>([]);

  const [filterScope, setFilterScope] = useState<Scope>(Scope.TERRITORY);

  // States
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(
    searchQuery ?? ''
  );
  const [showMobileFilterCard, setShowMobileFilterCard] = useState(false);

  // Graphql queries
  const [
    searchOrganizations,
    {
      data: searchData,
      fetchMore: searchFetchMore,
      called: searchCalled,
      refetch: searchRefetch,
      networkStatus,
    },
  ] = useGetSearchedOrganizationsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const { loading: isPreferenceLoading } = useGetCurrentUserPreferenceQuery({
    ...errorConfigBannerDialog,
    onCompleted: (data) => {
      const organizationFacets = parseOrganizationPreference(
        data.currentUser?.preference?.facets?.organizationFacets
      );
      setOrganizationFiltersData?.(
        parseOrganizationFilters(organizationFacets)
      );
    },
  });

  // Handlers
  const handleFetchMoreOrganizations = () => {
    const endCursor = searchData?.organizations?.pageInfo?.endCursor ?? '';

    searchFetchMore({
      variables: { after: endCursor },
    });
  };

  const handleClickFilterCard = () => {
    setShowMobileFilterCard((showMobileFilterCard) => !showMobileFilterCard);
  };

  const handleClearSearchQuery = () => {
    changeSearchQuery('');
    setDebouncedSearchQuery('');
  };

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    changeSearchQuery(event.target?.value);
    debouncedFunction(event.target?.value);
  };

  const handleDebounce = (value: string) => {
    setDebouncedSearchQuery(value);
  };

  const handleChangeOrganizationFilter = (value: FilterItemsData) => {
    setOrganizationFiltersData(value);
  };

  const handleChangeFilterScope = (value: Scope) => {
    setFilterScope(value);
  };

  // Memo
  const debouncedFunction = useMemo(() => debounce(handleDebounce, 500), []);

  // Use effect
  useEffect(() => {
    if (isOpen) {
      const organizationFilterQuery = buildOrganizationFilterQuery(
        organizationFiltersData,
        filterScope
      );

      if (searchCalled) {
        searchRefetch({
          search: debouncedSearchQuery,
          first: NUMBER_OF_ORGANIZATIONS_TO_SEARCH,
          filter: organizationFilterQuery,
        });
      } else {
        searchOrganizations({
          variables: {
            search: debouncedSearchQuery,
            first: NUMBER_OF_ORGANIZATIONS_TO_SEARCH,
            filter: organizationFilterQuery,
          },
        });
      }
    }
  }, [
    searchOrganizations,
    debouncedSearchQuery,
    isOpen,
    organizationFiltersData,
    filterScope,
    searchCalled,
    searchRefetch,
  ]);

  // Variables
  const filteredCount = searchData?.organizations?.filteredCount ?? -1;
  const hasMore = searchData?.organizations?.pageInfo?.hasNextPage ?? false;
  const selectedOrganizationFilter = countSelectedItemFilters(
    organizationFiltersData
  );
  const isLoading =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  const organizations = parseOrganizationConnection(searchData?.organizations);

  // Sub-Components
  const organizationInputSearch = (
    <FilterInputSearch
      searchQuery={searchQuery}
      isMobileSearchActive={isMobileSearchActive}
      changeSearchQuery={changeSearchQuery}
      testId="organization-filter-input-search"
      onClearSearchQuery={handleClearSearchQuery}
      onChangeSearchQuery={handleChangeSearchQuery}
    />
  );

  const filterCard = (
    <FilterCard
      entity={EntityFilterTypeDataEnum.ORGANIZATION}
      filters={organizationFiltersData}
      scope={filterScope}
      testId="organization-filter-card"
      isLoading={isPreferenceLoading}
      onChangeFilters={handleChangeOrganizationFilter}
      onChangeScope={handleChangeFilterScope}
      onResetFilter={handleChangeOrganizationFilter}
      onCloseDialog={closeDialog}
    />
  );

  return (
    <Box data-testid="organization-search-tab">
      {organizationInputSearch}
      {showMobileFilterCard && isSmallScreen ? (
        <MobileSearchCard
          filterCard={filterCard}
          onClickFilterCard={handleClickFilterCard}
        />
      ) : (
        <DesktopContentSearchCard
          filterCard={filterCard}
          filteredCount={filteredCount}
          counterSelectedItems={selectedOrganizationFilter}
          testId="default-content-organization-search-tab"
          onClickFilterCard={handleClickFilterCard}
        >
          {organizations && (
            <DisplaySearchResults
              itemsLength={organizations.length}
              filteredCount={filteredCount}
              closeDialog={closeDialog}
              searchQuery={debouncedSearchQuery}
              fetchMore={handleFetchMoreOrganizations}
              hasMore={hasMore}
              loading={isLoading}
              testId="display-organization-search-results"
            >
              {organizations.map((organization: OrganizationData) => (
                <OrganizationSearchResultCard
                  key={organization?.id}
                  organization={organization}
                  searchQuery={searchQuery}
                  closeDialog={closeDialog}
                />
              ))}
            </DisplaySearchResults>
          )}
        </DesktopContentSearchCard>
      )}
    </Box>
  );
};
