import { Box, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getPersonFullNameWithTitle, PersonData } from '@ysura/common';
import { useTranslation } from 'react-i18next';

import { PageHeader } from '@/components/PageHeader';

type InactivePersonProps = {
  person: PersonData;
};

export const InactivePerson = ({ person }: InactivePersonProps) => {
  const { t } = useTranslation();

  const personTitle = getPersonFullNameWithTitle(person);

  return (
    <InactivePersonWrapper>
      <PageHeader
        isPageInactive={true}
        title={personTitle}
        pageHeaderType={t('pages.personView.persons')}
      />
      <Card>
        <InactivePersonCardContent>
          <Typography variant="h3">
            {t('pages.personView.personIsInactive')}
          </Typography>
        </InactivePersonCardContent>
      </Card>
    </InactivePersonWrapper>
  );
};

const InactivePersonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(2),
  },
}));

const InactivePersonCardContent = styled(CardContent)(({ theme }) => ({
  // TODO: Delete height
  height: '200px',
  backgroundColor: theme.palette.grey[500_8],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.grey[500_32],
}));
