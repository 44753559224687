import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PromotionalMaterialSkeletonCard = () => {
  return (
    <PromatContentWrapper data-testid="promotional-material-skeleton-card">
      <TitleLine>
        <Skeleton variant="circular" width={20} height={20} />
        <Typography variant="subtitle2">
          <Skeleton width={200} height={22} />
        </Typography>
      </TitleLine>
      <SmallStack>
        <SmallBox>
          <Skeleton width={160} height={18} />
          <Skeleton width={160} height={18} />
        </SmallBox>
        <BoxLine>
          <Skeleton width={80} height={18} />
          <Skeleton width={120} height={18} />
        </BoxLine>
      </SmallStack>
    </PromatContentWrapper>
  );
};

const PromatContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const TitleLine = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

const SmallStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(0.5),
}));

const BoxLine = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

const SmallBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
