import * as Apollo from '@apollo/client';

import * as Operations from '../../queries';
import type * as Types from '../../types/types';

const defaultOptions = {} as const;
export type GetPromotionalMaterialsTopicsQueryVariables = Types.Exact<{
  oids?: Types.InputMaybe<
    | Array<Types.Scalars['PromotionalMaterialId']['input']>
    | Types.Scalars['PromotionalMaterialId']['input']
  >;
}>;

export type GetPromotionalMaterialsTopicsQuery = {
  __typename?: 'Query';
  promotionalMaterials: {
    __typename?: 'PromotionalMaterialConnection';
    edges?: Array<{
      __typename?: 'PromotionalMaterialEdge';
      node?: {
        __typename?: 'PromotionalMaterial';
        id: string;
        oid: string;
        name: string;
        topics: {
          __typename?: 'TopicConnection';
          edges?: Array<{
            __typename?: 'TopicEdge';
            node?: { __typename?: 'Topic'; id: string; oid: string } | null;
          }> | null;
        };
      } | null;
    }> | null;
  };
};

/**
 * __useGetPromotionalMaterialsTopicsQuery__
 *
 * To run a query within a React component, call `useGetPromotionalMaterialsTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionalMaterialsTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionalMaterialsTopicsQuery({
 *   variables: {
 *      oids: // value for 'oids'
 *   },
 * });
 */
export function useGetPromotionalMaterialsTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPromotionalMaterialsTopicsQuery,
    GetPromotionalMaterialsTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPromotionalMaterialsTopicsQuery,
    GetPromotionalMaterialsTopicsQueryVariables
  >(Operations.getPromotionalMaterialsTopics, options);
}
export function useGetPromotionalMaterialsTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPromotionalMaterialsTopicsQuery,
    GetPromotionalMaterialsTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPromotionalMaterialsTopicsQuery,
    GetPromotionalMaterialsTopicsQueryVariables
  >(Operations.getPromotionalMaterialsTopics, options);
}
export function useGetPromotionalMaterialsTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPromotionalMaterialsTopicsQuery,
    GetPromotionalMaterialsTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    GetPromotionalMaterialsTopicsQuery,
    GetPromotionalMaterialsTopicsQueryVariables
  >(Operations.getPromotionalMaterialsTopics, options);
}
export type GetPromotionalMaterialsTopicsQueryHookResult = ReturnType<
  typeof useGetPromotionalMaterialsTopicsQuery
>;
export type GetPromotionalMaterialsTopicsLazyQueryHookResult = ReturnType<
  typeof useGetPromotionalMaterialsTopicsLazyQuery
>;
export type GetPromotionalMaterialsTopicsSuspenseQueryHookResult = ReturnType<
  typeof useGetPromotionalMaterialsTopicsSuspenseQuery
>;
export type GetPromotionalMaterialsTopicsQueryResult = Apollo.QueryResult<
  GetPromotionalMaterialsTopicsQuery,
  GetPromotionalMaterialsTopicsQueryVariables
>;
