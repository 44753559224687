import { Delete, Lock } from '@mui/icons-material';
import { Card, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  ActivityStatusDataEnum,
  ActivityTypeBaseTypeDataEnum,
  CommentData,
  getPersonFullNameWithTitle,
  KebabMenuOption,
} from '@ysura/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActivityDetailCard } from '@/components/ActivityDetails';
import { AttendeesCardList } from '@/components/Attendee';
import { CommentsStaticBlock } from '@/components/Comment';
import { CoOrganizerCardList } from '@/components/CoOrganizer';
import { DetailPageLayout } from '@/components/DetailPageLayout';
import { LinkNoDecoration } from '@/components/LinkTo';
import { MediaStaticBlock } from '@/components/Media';
import {
  OrganizationDataStaticBlock,
  OrganizationItem,
} from '@/components/Organization';
import { PageHeader } from '@/components/PageHeader';
import {
  errorConfigBannerDialog,
  useCurrentUser,
  useNotification,
} from '@/hooks';
import {
  prepareCommentsForMutation,
  useUpdateActivityMutation,
} from '@/services/graphql/hooks';
import { State } from '@/services/graphql/types';
import {
  PromotionalMaterialsStaticBlock,
  TopicsStaticBlock,
} from '@/views/Activity/Read';
import { clearPersistentLocalStorage } from '@/views/Interaction/helpers';

import { KeyMessagesStaticBlock } from './KeyMessages';
import {
  ActivityConfirmationDialog,
  EditActivityInformationDialog,
} from './TouchpointManagement';

type ActiveActivityProps = {
  activity: ActivityData;
  canEditActivity?: boolean;
};

export const ActiveActivity = ({
  activity,
  canEditActivity = false,
}: ActiveActivityProps) => {
  const [isEditActivityInfoDialogOpen, setIsEditActivityInfoDialogOpen] =
    useState(false);
  const [isDeleteActivityDialogOpen, setIsDeleteActivityDialogOpen] =
    useState(false);
  const [isLockActivityDialogOpen, setIsLockActivityDialogOpen] =
    useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { toast } = useNotification();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { currentUser } = useCurrentUser();
  const [updateActivityMutation, { loading: isMutationLoading }] =
    useUpdateActivityMutation();

  const { attendees, activityType, status } = activity;

  const isActivityPlanned = status === ActivityStatusDataEnum.PLANNED;
  const person = attendees?.[0]?.person;
  const organization = activity.organization;
  const isSinglePersonActivity =
    activityType?.maxAttendeeCount == 1 && activityType.maxAttendeeCount == 1;
  const personTitle = getPersonFullNameWithTitle(person);
  const pageTitle = isSinglePersonActivity
    ? `${activityType?.name} - ${personTitle}`
    : `${activityType?.name} - ${organization?.name}`;
  const isCurrentUserTheOrganizer =
    activity?.organizer?.oid === currentUser?.person?.oid;

  const isAllowedToUseInteraction =
    (activityType?.baseType === ActivityTypeBaseTypeDataEnum.REMOTE &&
      currentUser?.permissions?.activity.canUseRemoteInteraction) ||
    (activityType?.baseType === ActivityTypeBaseTypeDataEnum.DIRECT_CONTACT &&
      currentUser?.permissions?.activity.canUseF2FInteraction) ||
    (activityType?.baseType === ActivityTypeBaseTypeDataEnum.TELEPHONE &&
      currentUser?.permissions?.activity.canUseTelephoneInteraction);

  const shouldShowStartInteractionButton =
    isActivityPlanned &&
    isCurrentUserTheOrganizer &&
    !activity.sealed &&
    isAllowedToUseInteraction;

  const isCurrentUserCoOrganizerAndRemoteInteraction =
    activity?.coOrganizers?.find(
      (it) => it.person?.oid === currentUser?.person?.oid
    ) && activityType?.baseType === ActivityTypeBaseTypeDataEnum.REMOTE;

  const canEditCoOrganizer =
    activityType?.allowCoOrganizers &&
    isCurrentUserTheOrganizer &&
    canEditActivity;

  const interactionModeHandler = () => {
    const interactionType =
      activityType?.baseType === ActivityTypeBaseTypeDataEnum.REMOTE
        ? 'remote'
        : activityType?.baseType === ActivityTypeBaseTypeDataEnum.TELEPHONE
        ? 'telephone'
        : 'f2f';

    if (activity?.id) {
      // Clear the persistant local storage if the activityId is different
      // to prevent stale data in local storage.
      clearPersistentLocalStorage(activity.id);

      navigate(`/interaction/${activity.id}/${interactionType}`);
    }
  };

  const handleOpenEditActivityInfoDialog = () => {
    setIsEditActivityInfoDialogOpen(true);
  };

  const handleCloseEditActivityInfoDialog = () => {
    setIsEditActivityInfoDialogOpen(false);
  };

  const handleSubmitDeleteActivity = () => {
    updateActivityMutation({
      variables: {
        oid: activity.oid ?? '',
        actions: [
          {
            state: {
              value: State.DELETED,
            },
          },
        ],
      },
      onCompleted: () => {
        toast?.({
          message: t('components.touchpointManagement.success.delete'),
          type: 'success',
        });

        handleCloseDeleteActivityDialog();
        navigate('/');
      },
      onError: () => {
        handleCloseDeleteActivityDialog();
      },
    });
  };

  const handleLockActivity = () => {
    if (activity.oid) {
      const activityId = activity.oid ?? '';
      updateActivityMutation({
        variables: {
          oid: activityId,
          actions: {
            sealed: {
              value: true,
            },
          },
        },
        onCompleted: () => {
          toast?.({
            message: t('components.touchpointManagement.success.lock'),
            type: 'success',
          });
          handleCloseLockActivityDialog();
          navigate(`/touchpoint/${activity.id}`);
        },
        onError: () => {
          handleCloseLockActivityDialog();
        },
      });
    }
  };

  const handleSaveComments = (
    comments: CommentData[],
    closeDialog: VoidFunction
  ) => {
    const activityOid = activity.oid ?? '';
    updateActivityMutation({
      ...errorConfigBannerDialog,
      variables: {
        oid: activityOid,
        actions: [
          {
            comments: {
              value: prepareCommentsForMutation(comments, activityOid),
            },
          },
        ],
      },
      onCompleted: () => {
        closeDialog();
      },
    });
  };

  const handleCloseDeleteActivityDialog = () => {
    setIsDeleteActivityDialogOpen(false);
  };

  const handleDeleteActivity = () => {
    setIsDeleteActivityDialogOpen(true);
  };

  const handleOpenLockActivityDialog = () => {
    setIsLockActivityDialogOpen(true);
  };

  const handleCloseLockActivityDialog = () => {
    setIsLockActivityDialogOpen(false);
  };

  const kebabEntries: Array<KebabMenuOption> = [
    {
      entry: 'Delete',
      icon: <Delete />,
      colorType: 'error',
      onClick: handleDeleteActivity,
    },
    {
      entry: 'Lock',
      icon: <Lock />,
      hidden: !currentUser?.permissions?.activity?.canSealActivity,
      onClick: handleOpenLockActivityDialog,
    },
  ];

  const hasAttendee = (activity: ActivityData) => {
    const hasAttendee = (activity?.attendees?.length ?? 0) > 0;
    const hasAttendeeConfigured =
      (activity.activityType?.maxAttendeeCount ?? 0) > 0;

    return hasAttendee && hasAttendeeConfigured;
  };

  const hasCoOrganizer = (activity: ActivityData) => {
    return !!activity?.coOrganizers?.length;
  };

  const label =
    currentUser?.permissions?.activity?.canUseRemoteInteraction &&
    isCurrentUserCoOrganizerAndRemoteInteraction
      ? t('pages.interaction.common.joinInteraction')
      : shouldShowStartInteractionButton
      ? t('pages.personView.startInteractionMode')
      : undefined;

  return (
    <>
      <DetailPageLayout
        headerComp={
          <PageHeader
            title={pageTitle}
            titleIcon={activity.sealed && <Lock />}
            subTitle={personTitle}
            buttonText={label}
            buttonHandler={
              shouldShowStartInteractionButton ||
              isCurrentUserCoOrganizerAndRemoteInteraction
                ? interactionModeHandler
                : undefined
            }
            kebabMenuEntries={canEditActivity ? kebabEntries : undefined}
          />
        }
        firstColumnComp={
          <>
            <Grid item>
              <ActivityDetailCard
                canEditActivity
                openEditActivityInfoDialog={handleOpenEditActivityInfoDialog}
                activity={activity}
                isLink={false}
                variant={isMobile ? 'dense' : 'spacious'}
              />
            </Grid>
            <Grid item>
              {organization && (
                <OrganizationDataStaticBlock
                  title={'pages.activity.organization'}
                >
                  <Wrapper>
                    <LinkNoDecoration to={`/organization/${organization.id}`}>
                      <OrganizationItem organization={organization} />
                    </LinkNoDecoration>
                  </Wrapper>
                </OrganizationDataStaticBlock>
              )}
            </Grid>
            <Grid item>
              {hasAttendee(activity) && (
                <AttendeesCardList
                  title={`${t('pages.personView.attendeeCount', {
                    count: activity?.attendees?.length,
                  })}`}
                  attendees={attendees}
                />
              )}
            </Grid>
            <Grid item>
              {(hasCoOrganizer(activity) || canEditCoOrganizer) && (
                <CoOrganizerCardList
                  title={`${t('pages.activity.coOrganizer.coOrganizerCount', {
                    count: activity?.coOrganizers?.length,
                  })}`}
                  canEditCoOrganizer={canEditCoOrganizer}
                  activity={activity}
                />
              )}
            </Grid>
          </>
        }
        middleColumnComp={
          <>
            <Grid item>
              <TopicsStaticBlock canEditActivity activity={activity} />
            </Grid>
            <Grid item>
              <MediaStaticBlock canEditActivity activity={activity} />
            </Grid>
            {currentUser?.permissions?.person?.canReadSampleDrop && (
              <Grid item>
                <PromotionalMaterialsStaticBlock
                  canEditActivity
                  activity={activity}
                />
              </Grid>
            )}
          </>
        }
        lastColumnComp={
          <>
            <Grid item>
              <KeyMessagesStaticBlock activity={activity} />
            </Grid>
            <Grid item>
              {currentUser?.permissions?.activity?.canReadActivityComment && (
                <CommentsStaticBlock
                  canEditActivity
                  commentTypes={activity.activityType?.commentTypes}
                  sourceComments={activity.comments ?? []}
                  status={activity.status}
                  isLoading={isMutationLoading}
                  save={handleSaveComments}
                />
              )}
            </Grid>
          </>
        }
      />

      {canEditActivity && (
        <>
          <EditActivityInformationDialog
            activity={activity}
            isOpen={isEditActivityInfoDialogOpen}
            onClose={handleCloseEditActivityInfoDialog}
          />
          <ActivityConfirmationDialog
            isOpen={isDeleteActivityDialogOpen}
            text={t('components.touchpointManagement.warning.delete')}
            submitButtonText={t('components.common.delete')}
            submitHandler={handleSubmitDeleteActivity}
            closeDialogHandler={handleCloseDeleteActivityDialog}
            isLoading={isMutationLoading}
          />
          <ActivityConfirmationDialog
            isOpen={isLockActivityDialogOpen}
            text={t('components.touchpointManagement.warning.lock')}
            submitButtonText={t('components.common.lock')}
            submitHandler={handleLockActivity}
            closeDialogHandler={handleCloseLockActivityDialog}
            isLoading={isMutationLoading}
          />
        </>
      )}
    </>
  );
};

const Wrapper = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));
