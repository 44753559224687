import { Skeleton } from '@mui/material';
import { Image, MediaData } from '@ysura/common';

import placeholderImage from '@/assets/placeholderImage.svg';
import { DocumentType, useGetDocument } from '@/services/api';
import { useGetDocumentByIdQuery } from '@/services/graphql/hooks';
import { parseDocumentData } from '@/services/graphql/parsers';

type MediaThumbnailProps = {
  media: MediaData;
  width?: number;
  height?: number;
};

export const MediaThumbnail = ({
  media,
  width,
  height,
}: MediaThumbnailProps) => {
  const { data: documentData, loading: isDocumentLoading } =
    useGetDocumentByIdQuery({
      variables: {
        documentId: media.thumbnailDocument?.id ?? '',
      },
      fetchPolicy: 'network-only',
      skip: !media.thumbnailDocument?.id,
    });

  const document = documentData?.document
    ? parseDocumentData(documentData.document)
    : null;

  const { data, isLoading, error } = useGetDocument(document);

  const imageSize = () => {
    const result: { width?: number; height?: number } = {
      height: 120,
    };

    if (width) {
      result.width = width;
    }

    if (height) {
      result.height = height;
    }

    return result;
  };

  if (
    !document?.contentLink ||
    error ||
    (data &&
      data.mediaType !== DocumentType.IMAGE_JPG &&
      data.mediaType !== DocumentType.IMAGE_PNG)
  ) {
    return (
      <Image
        sx={{
          width: imageSize().width,
          height: imageSize().height,
        }}
        data-testid="media-card-thumbnail"
        alt={media?.name}
        src={placeholderImage}
      />
    );
  }

  if (isLoading || isDocumentLoading || !data) {
    return (
      <Skeleton data-testid="media-card-thumbnail-skeleton" {...imageSize()} />
    );
  }

  return (
    <Image
      data-testid="media-card-thumbnail"
      sx={{
        width: imageSize().width,
        height: imageSize().height,
      }}
      alt={media?.name}
      src={URL.createObjectURL(new Blob([data.data], { type: data.mediaType }))}
    />
  );
};
