import { gql } from '@apollo/client';

export const addActivity = gql`
  mutation addActivity(
    $oid: ActivityId!
    $organizerId: PersonId!
    $organizationId: OrganizationId!
    $attendees: [ActivityAttendeeInput!]!
    $coOrganizers: [ActivityCoOrganizerInput!]!
    $startDate: DateTime
    $endDate: DateTime
    $discussedTopics: [TopicId!]!
    $activityTypeId: ActivityTypeId!
    $requestedPromotionalMaterials: [PromotionalMaterialRequestInput!]!
    $state: State!
    $status: ActivityStatus!
    $media: [ActivityShownMediaInput!]!
    $comments: [CommentInput!]!
    $callDetails: [ActivityCallDetailInput!]!
    $confirmed: Boolean
  ) {
    addActivity(
      input: {
        oid: $oid
        organizerId: $organizerId
        organizationId: $organizationId
        attendees: $attendees
        coOrganizers: $coOrganizers
        startDate: $startDate
        endDate: $endDate
        discussedTopics: $discussedTopics
        activityTypeId: $activityTypeId
        requestedPromotionalMaterials: $requestedPromotionalMaterials
        state: $state
        status: $status
        media: $media
        comments: $comments
        callDetails: $callDetails
        confirmed: $confirmed
      }
    ) {
      id
      oid
    }
  }
`;
