import { InventoryData } from '@ysura/common';

import { Inventory, InventoryConnection } from '@/services/graphql/types';
import { DeepPartial } from '@/types/data';
import { filterNonNull } from '@/utils/dataHelpers/filters';

import { parseStockConnection } from './stockParser';

export const parseInventoryConnection = (
  inventoryConnection: DeepPartial<InventoryConnection>
): Array<InventoryData> => {
  const inventories =
    inventoryConnection.edges?.filter((inventoryEdge) => inventoryEdge?.node) ??
    [];
  const parsedInventories = inventories.map((inventoryEdge) =>
    inventoryEdge?.node ? parseInventory(inventoryEdge.node) : null
  );

  return filterNonNull(parsedInventories);
};

export const parseInventory = (
  inventory: DeepPartial<Inventory>
): InventoryData => {
  return {
    id: inventory.id ?? '',
    oid: inventory.oid ?? '',
    stocks: inventory.stocks ? parseStockConnection(inventory.stocks) : [],
  };
};
