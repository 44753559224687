import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ActivityData,
  getPersonFullNameWithTitle,
  NoItemPlaceholder,
} from '@ysura/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';
import { PersonDataContent } from '@/components/PersonData';
import { useInteraction } from '@/hooks';

type PersonDataAccordionProps = {
  activity: ActivityData | null;
  isLoading?: boolean;
};

export const PersonDataAccordionBlock = ({
  activity,
  isLoading,
}: PersonDataAccordionProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { isOwnScreenShared } = useInteraction();
  const firstAttendee = activity?.attendees?.[0]?.person;

  const handleAccordionToggle = () => {
    if (isLoading) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (isOwnScreenShared) {
      setExpanded(false);
    }
  }, [isOwnScreenShared]);

  return (
    <OverviewAccordionBlock
      expanded={expanded}
      title={t('pages.interaction.remote.personalData')}
      onChange={handleAccordionToggle}
    >
      {firstAttendee && (
        <Wrapper data-testid="person-data-static-block">
          <TitleWrapper>
            <Title variant="subtitle2">
              {getPersonFullNameWithTitle(firstAttendee)}
            </Title>
            {firstAttendee.customerReference && (
              <SubTitle variant="overline">
                {firstAttendee.customerReference}
              </SubTitle>
            )}
          </TitleWrapper>
          <PersonDataContent isShowPersonTitle={false} person={firstAttendee} />
        </Wrapper>
      )}
      {!firstAttendee && (
        <NoItemPlaceholder>
          {t('pages.interaction.common.noDataAvailable')}
        </NoItemPlaceholder>
      )}
    </OverviewAccordionBlock>
  );
};

const Wrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}));

const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));
